import { useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import FormsService from "../../services/forms-service";
import { SectionHeader } from "../ui/molecules/SectionHeader";
import { PageTitle } from "components/ui/atoms/PageTitle";
import { FaPlus, FaSearch, FaTrashAlt } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { Pagination } from "components/ui/atoms/Pagination";
import useUser from "../../hooks/useUser";
import { CreateFormSection } from "./components/CreateFormSection";
import { FormListItem } from "./components/FormListItem";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import SuggestedStep from "components/ui/molecules/SuggestedStep";
import Utils from "utils/utils";
import Config from "config";
import { SkeletonList } from "components/ui/atoms/skeletons/SkeletonList";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { useOutletContext } from "react-router-dom";
import { ButtonDanger } from "components/ui/atoms/ButtonDanger";

const Forms = () => {
  const { setPageTitle, ref } = useOutletContext();

  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { user, userLanguage, checkIfLPhasAccessToSection } = useUser();
  const [forms, setForms] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [showCreateFormSection, setShowCreateFormSection] = useState(false);
  const [filterName, setFilterName] = useState("");

  const getQuestionnaireForms = (name = undefined) => {
    setLoading(true);

    return FormsService.getQuestionnairesForms({
      rowsPerPage: pageSize,
      page: currentPage,
      name: name ?? filterName,
    })
      .then((response) => {
        const { forms, pagination } = response.data.data;
        setForms(forms);

        if (pagination.current - 1 !== currentPage) {
          setCurrentPage(pagination.current);
        }

        if (pagination.total !== totalPages) {
          setTotalPages(pagination.pages);
        }
      })
      .catch(() => {
        addToast(t("error_retrieving_forms"), {
          appearance: "error",
          autoDismiss: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showSectionHeader = () => {
    let sections = [];
    sections = user?.investor?.permissions?.sections || [];

    return sections.includes("questionnaires_engine");
  };

  useImperativeHandle(ref, () => ({
    showCreateForm: () => handleCreateForm(),
  }));

  useEffect(() => {
    if (!user.lp || (user.lp && checkIfLPhasAccessToSection("questionnaires"))) {
      getQuestionnaireForms();
    } else {
      setLoading(false);
    }
  }, [currentPage, pageSize]);

  useEffect(() => {
    setTimeout(() => {
      ReactTooltip.rebuild();
    }, 500);
  }, [forms]);

  useEffect(() => {
    setPageTitle(showSectionHeader() ? "my_forms" : "questionnaires_engine");
  }, []);

  const [showSuggestedStep, setShowSuggestedStep] = useState(false);

  const handleCreateForm = () => {
    if (
      forms?.length ||
      !!user?.onboarding_tasks.includes("create_questionnaire") ||
      !!user?.investor?.onboarding_tasks?.includes("create_questionnaire") ||
      Utils.checkIfPreviousStepsArCompleted(
        user?.onboarding_tasks,
        user?.investor?.onboarding_tasks,
        "create_questionnaire",
      )
    ) {
      setShowCreateFormSection(true);
    } else {
      setShowSuggestedStep(true);
    }
  };

  const handleClearFilterAndSubmit = (e) => {
    e.preventDefault();
    setFilterName(""); // Ensuring the state is cleared
    getQuestionnaireForms(""); // Calling getDealflow with the cleared filter name
  };

  return (
    <>
      {showSuggestedStep && (
        <SuggestedStep
          action={"create_questionnaire"}
          showSuggestedStep={!!showSuggestedStep}
          setShowSuggestedStep={setShowSuggestedStep}
          continueAction={() => {
            setShowSuggestedStep(false);
            setShowCreateFormSection(true);
          }}
          continueText={t("create_form")}
        />
      )}
      {showSectionHeader() && !user.lp && (
        <>
          {!loading && forms.length === 0 && (
            <div className="my-10">
              <img
                src={`${Config.GUIDE_STEPS_BACKGROUND_IMAGES_BUCKET}questionnaires_engine_${userLanguage}.png`}
                className="w-auto"
              />
            </div>
          )}
          <div className="flex justify-end mt-4">
              <form onSubmit={(evt) => {
                evt.preventDefault();
                getQuestionnaireForms();
              }} className="flex gap-2 w-1/4">
                <input
                  className={
                    "shadow-soft-white border border-gray-lines focus:border-main bg-input w-full py-2 px-7 relative z-10 text-left text-xs font-normal rounded-2xl placeholder-gray cursor-pointer transition-all duration-200 outline-none hover:border-main hover:outline-none hover:shadow-inner focus:outline-none focus:shadow-focus active:outline-none"
                  }
                  placeholder={t("form_example")}
                  onChange={(e) => {
                    setFilterName(e.currentTarget.value);
                  }}
                  value={filterName}
                />
                <ButtonSecondary
                  type="submit"
                  iconComponent={<FaSearch className="h-4" />}
                />
                {filterName && (<ButtonDanger
                  type="button"
                  onClick={handleClearFilterAndSubmit}
                  iconComponent={<FaTrashAlt className="h-4" />}
                />)}
              </form>
            </div>
          <div className="py-4">
            {!loading && forms.length > 0 && (
              <>
                <div className="grid gap-8 grid-cols-2 2xl:grid-cols-3">
                  {forms.map((form) => (
                    <FormListItem key={form._id} form={form} />
                  ))}
                </div>

                <div className="mt-4">
                  <Pagination
                    paginateOptions={[12, 24, 50]}
                    currentPage={currentPage}
                    setCurrentPage={(page) => setCurrentPage(page)}
                    perPage={pageSize}
                    pages={totalPages}
                    setPerPage={(value) => setPageSize(value[0].id)}
                  />
                </div>
              </>
            )}
          </div>
        </>
      )}
      {user.lp && (
        <div className="my-6">
          <NoDataInfo title={t("access_forbidden")} />
        </div>
      )}
      {showCreateFormSection && (
        <CreateFormSection
          onClose={() => setShowCreateFormSection(false)}
          forms={forms.map((form) => ({ id: form._id, value: form.name }))}
        />
      )}

      {loading && <div className="grid grid-cols-2 2xl:grid-cols-3 gap-4"><SkeletonList length={6} style="rounded-xl h-72"/></div>}
    </>
  );
};

export default Forms;
