/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { GoogleButton } from "components/ui/atoms/GoogleButton";
import { Loader } from "components/ui/molecules/Loader";
import EmailsList from "./EmailsList";
import EmailDetail from "./EmailDetail";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { TfiReload } from "react-icons/tfi";
import { useTranslation } from "react-i18next";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";

const API_KEY = "AIzaSyDDXutZvDTGFliGz9_-PG1IwnXERSVu5jU";
const CLIENT_ID = "690938764456-ac8b32rplj2tfk4f43grc7iuh36udi8i.apps.googleusercontent.com";
const PAGE_SIZE = 10;

const GoogleView = ({ deal, sincronize }) => {
  const [emails, setEmails] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [prevPageToken, setPrevPageToken] = useState("");
  const [nextPageToken, setNextPageToken] = useState("");
  const [showEmailDetail, setShowEmailDetail] = useState(false);
  const [emailData, setEmailData] = useState({});
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [paginationTokens, setPaginationTokens] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    const initClient = async () => {
      if (!gapi.auth2.getAuthInstance()) {
        await gapi.client.init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest"],
          scope:
            "https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.compose https://www.googleapis.com/auth/gmail.send",
        });
      }
      const authInstance = gapi.auth2.getAuthInstance();

      if (authInstance.isSignedIn.get()) {
        setIsAuthenticated(true);

        const basicProfile = authInstance.currentUser.get().getBasicProfile();
        const _email = basicProfile.getEmail();

        if (_email) {
          getEmails();
        } else {
          setEmails([]);
        }
      } else {
        handleLoginFailure();
        setIsAuthenticated(false);
      }
    };

    gapi.load("client:auth2", initClient);
  }, []);

  const getEmails = async (pageToken = "") => {
    try {
      setLoading(true);
      let query = null;
      if (deal.main_contact_email) {
        const emailsFounders = deal?.founders?.map((founder) => founder.email) || [];
        const otherEmails = deal?.other_emails?.map((email) => email) || [];
        const data = [...emailsFounders, ...otherEmails, deal?.main_contact_email];
        const dataSet = new Set(data);
        const newData = [...dataSet];

        const queryFromTo = newData
          .map((item) => {
            return `to:${item}||from:${item}`;
          })
          .join("||");

        query = `${queryFromTo}||subject:"${deal.name}"`;
      } else {
        query = `subject:"${deal.name}"`;
      }

      const response = await gapi.client.gmail.users.messages.list({
        userId: "me",
        maxResults: PAGE_SIZE,
        pageToken,
        q: query,
      });

      const messages = response?.result?.messages;

      let emails = [];
      if (messages) {
        emails = await Promise.all(
          messages.map(async (message) => {
            const email = await gapi.client.gmail.users.messages.get({
              userId: "me",
              id: message.id,
              format: "full",
            });
            return email.result;
          }),
        );
      }

      const { nextPageToken } = response.result;
      if (nextPageToken) {
        setHasNextPage(true);
        if (!paginationTokens.includes(nextPageToken)) {
          setPaginationTokens([...paginationTokens, nextPageToken]);
        }
      } else {
        setHasNextPage(false);
      }
      setEmails(emails);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (sincronize) {
      getEmails();
    }
  }, [sincronize]);

  const handleLoginFailure = (response) => {
    setLoading(false);
  };

  const getEmailContent = async (emailId) => {
    try {
      const response = await gapi.client.gmail.users.messages.get({
        userId: "me",
        id: emailId,
        format: "full",
      });

      const email = response.result;
      setEmailData(email);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="my-6">
      {emails.length ? (
        <div className="w-full">
          <EmailsList
            emails={emails || []}
            getEmailContent={getEmailContent}
            showEmailDetail={setShowEmailDetail}
          />
          {(currentPage !== 0 || paginationTokens?.length > 0) && (
            <div className="flex justify-end my-4">
              {currentPage !== 0 && (
                <ButtonSecondary
                  onClick={() => {
                    getEmails(currentPage - 1 ? paginationTokens[currentPage - 1] : "");
                    setCurrentPage(currentPage - 1);
                  }}
                  iconComponent={<GrFormPrevious />}
                  className="w-4 h-4"
                  marginRight={2}
                />
              )}
              {paginationTokens.length && paginationTokens.length > currentPage && (
                <ButtonSecondary
                  onClick={() => {
                    getEmails(paginationTokens[currentPage]);
                    setCurrentPage(currentPage + 1);
                  }}
                  iconComponent={<GrFormNext className="w-4 h-4" />}
                />
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="my-10">
          <NoDataInfo
            title={t(isAuthenticated ? "no_emails_found" : "email_synchronization_not_enabled")}
          />
        </div>
      )}

      {showEmailDetail && (
        <EmailDetail onClose={() => setShowEmailDetail(false)} email={emailData} />
      )}

      {loading && <Loader />}
    </div>
  );
};

export default GoogleView;
