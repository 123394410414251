import { PopoverMenu } from "components/ui/atoms/PopoverMenu";
import useUser from "../../../hooks/useUser";
import { RemindersMenu } from "components/ui/atoms/RemindersMenu";
import { HelpMenu } from "components/ui/atoms/HelpMenu";
import { OnboardingStepsMenu } from "components/ui/atoms/OnboardingMenu";
import { FaAlignLeft, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { ThemeOperations } from "redux/theme";
import { useEffect } from "react";

const Header = () => {
  const { isDemoAccount, user } = useUser();

  const theme = useSelector((state) => state.theme);

  const dispatch = useDispatch();

  const isExpanded = theme.sidebar_expanded;

  useEffect(() => {
    console.log(theme);
  }, [theme]);

  const toggleSidebar = () => {
    console.log({ ...theme, sidebar_expanded: !isExpanded });
    dispatch(ThemeOperations.setTheme({ ...theme, sidebar_expanded: !isExpanded }));
  };

  return (
    <header
      className={`p-3 h-16 pr-10 w-full flex justify-end items-center bg-gray-100 border border-b-gray-200 ${isDemoAccount && "mt-12"}`}
    >
      <div className="mr-auto hover:bg-gray-200 hover:cursor-pointer rounded p-2" role="button" onClick={toggleSidebar}>
        {isExpanded ? <FaAlignLeft/> : <FaTimes />}
      </div>
      <div className="flex items-center" id="header-options">

        <OnboardingStepsMenu />
        <HelpMenu />
        {!user.lp && <RemindersMenu />}
        <PopoverMenu />
      </div>
    </header>
  );
};

export default Header;
