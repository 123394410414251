import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import ScrollContainer from "react-indiana-drag-scroll";
import Avatar from "react-avatar";
import { FaCalculator } from "react-icons/fa";
import { EvaluationsUtils } from "utils/evaluations-utils";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import { Card } from "components/ui/atoms/Card";
import Config from "config";

export const EvaluationsMatrix = ({
  evaluations = [],
  showDiscardedStartups = false,
  scaleToTen = true,
  loading = false,
  height = "20rem",
}) => {
  const { t } = useTranslation();
  const [startups, setStartups] = useState([]);
  const [judges, setJudges] = useState([]);

  const getJudgeEvaluation = (judge, startup) => {
    return evaluations.find((evaluation) => {
      return evaluation.judge?._id === judge._id && evaluation.deal?._id === startup._id;
    });
  };

  const getStartupAverage = (startup) => {
    let total = 0;
    let count = 0;

    for (let i = 0; i < evaluations.length; i++) {
      const evaluation = evaluations[i];
      if (evaluation.deal?._id === startup._id) {
        total += evaluation.general_score;
        count++;
      }
    }

    return total / count || null;
  };

  const renderScore = (score) => {
    const isEmpty = score === null || score === undefined;
    const percentage = score * (scaleToTen ? 10 : 1);
    const color = score ? EvaluationsUtils.getScoreColor(percentage) : "transparent";

    return (
      <div
        style={{
          backgroundColor: color,
          width: "3rem",
          height: "3rem",
        }}
        className="flex justify-center items-center text-sm font-bold"
      >
        {isEmpty && <span className="text-gray-200 font-normal">{t("no_data")}</span>}
        {!isEmpty && <span>{score.toFixed(2)}</span>}
      </div>
    );
  };

  useEffect(() => {
    setStartups(EvaluationsUtils.getStartupsFromEvaluations(evaluations, showDiscardedStartups));
    setJudges(EvaluationsUtils.getJudgesFromEvaluations(evaluations));
    setTimeout(() => ReactTooltip.rebuild(), 1000);
  }, [evaluations, showDiscardedStartups]);

  return (
    <>
      <div className={"text-center text-main"}>{t("startups")}</div>

      <div className="flex flex-row">
        <div className={"-rotate-90 origin-top-left w-6 relative top-[175px] text-main"}>
          {t("judges")}
        </div>

        <ScrollContainer
          hideScrollbars={false}
          style={{ maxHeight: height, minHeight: 200 }}
          className="relative overflow-auto shadow-md w-full border"
        >
          {loading && (
            <div className={"flex justify-center items-center h-full text-main"}>
              {t("loading")}...
            </div>
          )}

          <table className="border-collapse">
            <tbody>
              <tr>
                <td>&nbsp;</td>
                {startups.map((startup) => (
                  <td
                    data-tip={startup.name}
                    key={`startup_${startup._id}`}
                    className="border border-gray-200 sticky top-0 bg-white border-t-0 z-10"
                  >
                    <Avatar name={startup.name} color="#e0e6f2" fgColor="#4d70b3" size="3rem" />
                  </td>
                ))}
              </tr>

              <tr>
                <td
                  data-tip={t("average_score")}
                  className="border border-gray-200 sticky left-0 bg-white border-t-0 relative"
                >
                  <div className={"bg-main flex items-center justify-center h-[3rem]"}>
                    <FaCalculator className="text-white" />
                  </div>
                </td>

                {startups.map((startup) => (
                  <td key={startup._id} className="border border-gray-200 bg-white">
                    {renderScore(getStartupAverage(startup))}
                  </td>
                ))}
              </tr>

              {judges.map((judge) => (
                <tr key={`judge_${judge._id}`}>
                  <td className="border border-gray-200 sticky left-0 bg-white border-l-0">
                    <div data-tip={judge.judge}>
                      <Avatar name={judge.judge} color="#e0e6f2" fgColor="#4d70b3" size="3rem" />
                    </div>
                  </td>

                  {startups.map((startup) => (
                    <td
                      key={`startup_judge_${startup._id}_${judge._id}`}
                      className="border border-gray-200 bg-white"
                    >
                      {renderScore(getJudgeEvaluation(judge, startup)?.general_score)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </ScrollContainer>
      </div>
    </>
  );
};
