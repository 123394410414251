import axios from "axios/index";
import Config from "../config";

const MilestonesService = {
  getMilestoneSections: async(dealId) =>
    await axios.get(`${Config.MILESTONES_API_URL}sections?deal=${dealId}`),

  createMilestoneSection: async(data) =>
    await axios.post(`${Config.MILESTONES_API_URL}sections`, data),

  getMilestoneGoals: async(dealId) =>
    await axios.get(`${Config.MILESTONES_API_URL}goals?deal=${dealId}`),

  createMilestoneGoal: async(data) => await axios.post(`${Config.MILESTONES_API_URL}goals`, data),

  deleteMilestoneGoal: async(goalId) =>
    await axios.delete(`${Config.MILESTONES_API_URL}goals/${goalId}`),

  deleteMilestoneSection: async(sectionId, newSectionId, purge) =>
    await axios.delete(
      `${Config.MILESTONES_API_URL}sections/${sectionId}?destination=${newSectionId}&purge=${purge}`,
    ),

  updateMilestoneSection: async(sectionId, data) =>
    await axios.patch(`${Config.MILESTONES_API_URL}sections/${sectionId}`, data),

  updateMilestoneGoal: async(goalId, data) =>
    await axios.patch(`${Config.MILESTONES_API_URL}goals/${goalId}`, data),
};
export default MilestonesService;
