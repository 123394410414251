/* eslint-disable space-before-function-paren */
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import { SectionHeader } from "components/ui/molecules/SectionHeader";
import { PageTitle } from "../../../ui/atoms/PageTitle";
import { getPathSegment } from "utils/get-path-segment";

const InvestorScope = () => {
  const { t } = useTranslation();

  const [section, setSection] = useState(getPathSegment(location.href, 2));

  const SECTIONS = [
    { route: "personal-information", title: "personal_information", id: "personal-information" },
    { route: "settings", title: "settings", id: "settings" },
    { route: "security", title: "security", id: "security" },
    { route: "email", title: "email", id: "email" },
  ];

  return (
    <>
      <PageTitle title={t("my_account")} />

      <SectionHeader
        sectionTitles={SECTIONS.map((sec) => t(sec))}
        sectionKeys={SECTIONS}
        section={section}
        setSection={(sec) => {
          setSection(sec);
        }}
        navigate
      />
      <div className="flex flex-col w-full my-6"><Outlet /></div>
    </>
  );
};

export default InvestorScope;
