import React from "react";
import Config from "config";

export function ProgressBar({
  bgNotCompleted = "bg-gray-lines",
  value,
  percentage = true,
  inverted = false,
  valueText = "",
  textColor = "text-main",
  shadow = "shadow-basic",
  textSize = null,
}) {
  const bgColor = "#61D8BD";

  /* if (value >= 0 && value <= 24) {
    bgColor = inverted ? '#4D70B3' : 'rgba(190, 218, 255)'
  } else if (value >= 25 && value <= 49) {
    bgColor = inverted ? 'rgba(105, 137, 207)' : 'rgba(161, 190, 255)'
  } else if (value >= 50 && value < 75) {
    bgColor = inverted ? 'rgba(133, 163, 235)' : 'rgba(133, 163, 235)'
  } else if (value >= 75 && value <= 99) {
    bgColor = inverted ? 'rgba(161, 190, 255)' : 'rgba(105, 137, 207)'
  } else {
    bgColor = inverted ? 'rgba(190, 218, 255)' : '#4D70B3'
  } */

  return (
    <div className="flex w-full items-end justify-start h-5">
      <div className="w-full flex flex-col items-end">
        <div className={`font-medium ${textColor} ${textSize && textSize}`}>
          {percentage ? `${value}%` : valueText || value}
        </div>
        <div className="w-full flex flex-col items-center relative">
          <div className={`w-full ${bgNotCompleted} h-2 rounded-xl`} />
          <div className="w-full absolute">
            <div
              className={`h-2 rounded-xl ${shadow && shadow}`}
              style={{
                width: `${value}%`,
                background: bgColor,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
