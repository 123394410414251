import { FaExpandAlt, FaEye, FaShareAlt } from "react-icons/fa";
import { BiTargetLock } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { Card } from "components/ui/atoms/Card";
import Avatar from "react-avatar";
import Config from "config";
import { ButtonCardMain } from "components/ui/atoms/ButtonCardMain";
import { useSwal } from "hooks/useSwal";
import { ButtonCardSecondary } from "components/ui/atoms/ButtonCardSecondary";
import { DealflowInvestmentThesisMatch } from "components/dealflow/DealflowInvestmentThesisMatch";
import { useEffect, useState } from "react";
import Utils from "utils/utils";
import useUser from "hooks/useUser";

export const IRMSuggestedStartup = ({
  contact,
  dealflow,
  setDealToShare,
  setDealToResume,
  investmentThesis = {},
}) => {
  const { t } = useTranslation();
  const { alert } = useSwal();

  const [criteriaChecks, setCriteriaChecks] = useState({});

  const { user } = useUser();
  useEffect(() => {
    setCriteriaChecks(Utils.checkInvestmentCriteriaMatch(dealflow, investmentThesis));
  }, []);

  return (
    <>
      <Card
        wrapperClassName="transition-transform"
        containerClassName="w-full flex flex-col h-full"
      >
        <div className="flex h-full pb-2 mb-2">
          <div className="flex flex-row justify-around w-full">
            <div className="flex flex-col w-3/5 h-full min-w-0 text-sm">
              <h5 className={"uppercase font-bold text-sm leading-none text-main truncate mb-1"}>
                {dealflow.name}
              </h5>
              {contact?.shared_dealflow?.includes(dealflow._id) && (
                <div className="flex flex-row mb-2">
                  <div className="w-auto">
                    <span
                      className={
                        "inline-block mr-2 mt-1 font-semibold text-xxs px-2 py-0.5 rounded-md border"
                      }
                    >
                      {t("shared_startup")}
                    </span>
                  </div>
                </div>
              )}
              <div className="flex flex-row line-clamp-3">{dealflow.brand_statement}</div>
            </div>
            <div className="flex items-center justify-end w-2/5 mr-5 ">
              <div className="info-card-container-img">
                <Avatar
                  src={dealflow.logo}
                  name={dealflow.name}
                  alt={dealflow.name}
                  maxInitials={3}
                  className={`h-full object-contain ${dealflow.logo ? "w-full" : ""}`}
                  color="#e0e6f2"
                  fgColor="#4d70b3"
                  round="5"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between py-2 border-t border-gray-lines">
          <div className="flex flex-col overflow-hidden">
            <label className="text-xs">{t("sector")}</label>
            <span className="text-xs font-bold">
              {dealflow.sector ? t(dealflow.sector.key) : "-"}
            </span>
          </div>
          <div className="flex flex-col overflow-hidden">
            <label className="text-xs">{t("business_model")}</label>
            <span className="text-xs font-bold">
              {dealflow.business_model ? t(dealflow.business_model.key) : "-"}
            </span>
          </div>
          <div className="flex flex-col overflow-hidden">
            <label className="text-xs">{t("investment_stage")}</label>
            <span className="text-xs font-bold">
              {dealflow.investment_stage ? t(dealflow.investment_stage.key) : "-"}
            </span>
          </div>
          <div className="flex flex-col overflow-hidden">
            <label className="text-xs">{t("valuation")}</label>
            <span className="text-xs font-bold">
              {dealflow.valuation !== null && dealflow.valuation_unit
                ? `${dealflow.valuation} ${dealflow.valuation_unit.toUpperCase()}`
                : "-"}
            </span>
          </div>
          <div className="flex flex-col overflow-hidden">
            <label className="text-xs">{t("investment_desired")}</label>
            <span className="text-xs font-bold">
              {dealflow.investment_desired !== null && dealflow.investment_desired_unit
                ? `${dealflow.investment_desired} ${dealflow.investment_desired_unit.toUpperCase()}`
                : "-"}
            </span>
          </div>
        </div>
        <div className="flex items-center py-2 border-t border-gray-lines">
          <div className="mr-2" data-tip={t("investment_thesis")}>
            <BiTargetLock className={"text-main h-5 w-5"} />
          </div>
          <div className="flex-1">
            <DealflowInvestmentThesisMatch criteriaChecks={criteriaChecks} />
          </div>
        </div>
        <div className={"flex justify-end mt-2 pt-2 border-t border-separator"}>
          <ButtonCardSecondary
            onClick={() => setDealToResume(dealflow)}
            text={t("see_resume")}
            marginRight={2}
            iconComponent={<FaExpandAlt className="inline-block w-5 h-4 mr-2" />}
          />
          {!user.lp && (
            <ButtonCardMain
              onClick={
                contact.contact_email && contact.language
                  ? () => setDealToShare(dealflow)
                  : () => alert({ text: t("email_and_language_required_to_share_deal") })
              }
              text={t("share")}
              iconComponent={<FaShareAlt className="inline-block w-5 h-4 mr-2" />}
            />
          )}
        </div>
      </Card>
    </>
  );
};
