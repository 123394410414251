import { useEffect, useRef, useState } from "react";
import { PageTitle } from "../ui/atoms/PageTitle";
import { useTranslation } from "react-i18next";
import { SectionHeader } from "../ui/molecules/SectionHeader";
import { FaFilter, FaPlus, FaPlusCircle, FaSort } from "react-icons/fa";
import { Loader } from "../ui/molecules/Loader";
import { useToasts } from "react-toast-notifications";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { DealResumeSection } from "./DealResumeSection";
import { MiniHelp } from "components/ui/atoms/MiniHelp";
import useUser from "hooks/useUser";
import { ShareDealSection } from "components/ui/molecules/ShareDealSection";
import ContactsService from "services/contacts-service";
import { useSwal } from "hooks/useSwal";
import { DealflowService } from "services/dealflow-service";
import DocumentsService from "services/documents-service";
import MembersService from "services/members-service";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import { Outlet } from "react-router-dom";
import { getPathSegment } from "utils/get-path-segment";

export const DealflowListView = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [section, setSection] = useState(
    getPathSegment(location.href, 2) === null ? "pool" : getPathSegment(location.href, 2)
  );
  const sectionRef = useRef();
  const [dealToResume, setDealToResume] = useState(null);
  const [dealToShare, setDealToShare] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [members, setMembers] = useState([]);
  const [filtersCount, setFiltersCount] = useState(null);
  const [orderBySelected, setOrderBySelected] = useState(false);
  const [loading, setLoading] = useState(false);

  const { confirm } = useSwal();

  const { user, reloadUserInfo, hasAccessToModule, checkIfLPhasAccessToSection } = useUser();

  const sections = [
    { id: "pool", route: "", title: "pool" },
    { id: "archived_startups", route: "archived", title: "archived" },
    { id: "tags", route: "tags", title: "tags" },
    { id: "custom_fields", route: "custom_fields", title: "custom_fields" },
    { id: "main_form", route: "main_form", title: "main_form" },
    hasAccessToModule("members") && {
      id: "shared_with_network",
      title: "shared_with_network",
      route: "shared_with_network",
    },
  ];

  const downloadDocument = async (documentId) => {
    setLoading(true);
    DocumentsService.getDocument(documentId)
      .then((response) => {
        if (response?.data?.data?.url) {
          window.open(response.data.data.url, "_blank");
        }
      })
      .catch((error) => {
        addToast(
          error?.response?.data?.msg_key ? t(error.response.data.msg_key) : t("error_occurred_downloading_document"),
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getContactsList = async () => {
    try {
      setLoading(true);
      const res = await ContactsService.getContactsList();
      setContacts(res.data.data);
    } catch (error) {
      addToast(t("error_occurred_retrieving_contacts"), { appearance: "error", autoDismiss: true });
    } finally {
      setLoading(false);
    }
  };

  const getMembers = async () => {
    try {
      setLoading(true);
      const res = await MembersService.getMembers({ rowsPerPage: 999 });
      setMembers(res.data.data.members);
    } catch (error) {
      // addToast(t('error_occurred_retrieving_members'), { appearance: 'error', autoDismiss: true })
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getContactsList();
    if (hasAccessToModule("members")) {
      getMembers();
    }
  }, []);

  const shareDeal = async (section, selectedContacts, data) => {
    confirm({}).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await DealflowService.shareDealflow(dealToShare._id, {
            section,
            ...data,
            contacts: selectedContacts.map((contact) => ({
              _id: contact._id,
              name: contact.contact || contact.company,
              email: contact.contact_email,
              language: contact.language,
            })),
          });
          addToast(t("deal_shared_successfully"), { appearance: "success", autoDismiss: true });
          setDealToShare(null);
          reloadUserInfo();
        } catch (error) {
          console.error(error);
          addToast(t("error_occurred_sharing_deal"), { appearance: "error", autoDismiss: true });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const handleChangeSection = (_section) => {
    setFiltersCount(null);
    setSection(_section);
  };

  return (
    <>
      <div className="flex items-start">
        <div className="flex-1">
          <PageTitle title={t("dealflow")} />
        </div>
        {section === "pool" && !user.lp && (
          <>
            {!user?.help?.add_startup_to_dealflow && <MiniHelp item={"add_startup_to_dealflow"} />}
            <ButtonMain
              onClick={() => sectionRef.current?.addStartup()}
              text={t("add_startup")}
              iconComponent={<FaPlus className="inline-block mr-2" />}
              marginRight="2"
            />
          </>
        )}

        {["pool"].includes(section) && (!user.lp || (user.lp && checkIfLPhasAccessToSection("dealflow"))) && (
          <div className="flex gap-2">
            <ButtonSecondary
              iconComponent={<FaFilter className="h-4" />}
              onClick={() => {
                section === "pool" && sectionRef.current?.showFilters();
              }}
              badgeContent={filtersCount > 0 ? filtersCount : null}
              badgeBgColor={"red"}
            />
            {hasAccessToModule("members") && (
              <ButtonSecondary
                iconComponent={<FaSort className="h-4" />}
                onClick={() => {
                  section === "pool" && sectionRef.current?.showOrderby();
                }}
                badgeContent={orderBySelected ? 1 : null}
                badgeBgColor={"red"}
              />
            )}
          </div>
        )}
        {["interesting"].includes(section) && (!user.lp || (user.lp && checkIfLPhasAccessToSection("dealflow"))) && (
          <div>
            <ButtonSecondary
              iconComponent={<FaFilter className="h-4" />}
              onClick={() => {
                section === "interesting" && sectionRef.current?.showFilters();
              }}
              badgeContent={filtersCount > 0 ? filtersCount : null}
              badgeBgColor={"red"}
            />
          </div>
        )}
        {["shared_with_network"].includes(section) &&
          (!user.lp || (user.lp && checkIfLPhasAccessToSection("dealflow"))) && (
            <div>
              <ButtonSecondary
                iconComponent={<FaFilter className="h-4" />}
                onClick={() => {
                  section === "shared_with_network" && sectionRef.current?.showFilters();
                }}
                badgeContent={filtersCount > 0 ? filtersCount : null}
                badgeBgColor={"red"}
              />
            </div>
          )}
        {["archived"].includes(section) && (!user.lp || (user.lp && checkIfLPhasAccessToSection("dealflow"))) && (
          <div>
            <ButtonSecondary
              iconComponent={<FaFilter className="h-4" />}
              onClick={() => {
                section === "archived" && sectionRef.current?.showFilters();
              }}
              badgeContent={filtersCount > 0 ? filtersCount : null}
              badgeBgColor={"red"}
            />
          </div>
        )}
        {section === "tags" && !user.lp && (
          <div>
            <ButtonMain
              iconComponent={<FaPlusCircle className="inline-block mr-3" />}
              text={t("create_tag")}
              onClick={() => sectionRef.current?.handleCreateTag()}
            />
          </div>
        )}
        {section === "custom_fields" && !user.lp && (
          <div>
            <ButtonMain
              iconComponent={<FaPlusCircle className="inline-block mr-3" />}
              text={t("create_custom_field")}
              onClick={() => sectionRef?.current?.handleCreateCustomField()}
            />
          </div>
        )}
      </div>
      {user.lp && !checkIfLPhasAccessToSection("dealflow") && (
        <div className="my-6">
          <NoDataInfo title={t("access_forbidden")} />
        </div>
      )}
      {(!user.lp || (user.lp && checkIfLPhasAccessToSection("dealflow"))) && (
        <>
          <SectionHeader
            section={section}
            setSection={handleChangeSection}
            sectionKeys={sections}
            sectionTitles={sections.map((section) => t(section))}
            showFilters={false}
            setShowFilters={null}
            navigate
          />

          {dealToShare && (
            <ShareDealSection
              onClose={() => {
                setDealToShare(null);
              }}
              onSubmit={shareDeal}
              irm={false}
              contacts={contacts}
              members={members}
              initialValues={user.share_dealflow_preferences || {}}
            />
          )}
          {dealToResume && (
            <DealResumeSection
              deal={dealToResume || {}}
              onClose={() => {
                setDealToResume(null);
              }}
              section={section}
              downloadDocument={downloadDocument}
            />
          )}
        </>
      )}

      <Outlet context={{ ref: sectionRef, setDealToResume, setDealToShare, setFiltersCount, setOrderBySelected }} />

      {loading && <Loader />}
    </>
  );
};
