import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { RoutesLinks } from "components/routes-links";
import { useToasts } from "react-toast-notifications";
import { Loader } from "components/ui/molecules/Loader";
import SupportService from "services/support-service";
import { SharedEventFooter } from "components/events/components/event/components/SharedEventFooter";
import { FaExclamationCircle, FaEye } from "react-icons/fa";
import moment from "moment";
import { Card } from "components/ui/atoms/Card";
import Config from "config";
import { useSwal } from "hooks/useSwal";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import { ButtonCardMain } from "components/ui/atoms/ButtonCardMain";
import Unauthorized from "components/ui/objects/Unauthorized";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { DealflowService } from "services/dealflow-service";

export const RequestedInformation = () => {
  const { t } = useTranslation();

  const queryParams = new URLSearchParams(location.search);
  const [accessToken, setAccessToken] = useState(localStorage.getItem("X-RI-Token"));

  const wrapperElement = useRef(null);
  const { addToast } = useToasts();
const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [requestedInfo, setRequestedInfo] = useState(null);
  const { confirm } = useSwal();
  const [authorized, setAuthorized] = useState(false);
  const [error, setError] = useState(false);

  const checkAccess = async () => {
    try {
      setLoading(true);
      const result = await DealflowService.getRequestedAdditionalInformation(accessToken);
      setRequestedInfo(result.data.data);
      setAuthorized(true);
      setError(false);
    } catch (error) {
      addToast(
        t(
          error?.response?.status === 403
            ? "forbidden_access_to_form"
            : "error_occurred_checking_access",
        ),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (accessToken) {
      localStorage.setItem("X-RI-Token", accessToken);
      checkAccess();
    } else {
      setAuthorized(false);
    }
  }, [accessToken]);

  if (!authorized && !loading)
    return (
      <Unauthorized
        loading={loading}
        onRequestAccess={setAccessToken}
        description={t("access_control_description_requested_information")}
        buttonText={t("access_requested_information")}
      />
    );

  return (
    <div className="flex flex-col min-h-screen" ref={wrapperElement}>
      {loading && <Loader />}
      <section className="px-8 my-8 flex-1">
        {authorized && (
          <>
            <div className="flex justify-between border-b border-gray-lines mb-6">
              <div>
                <h1 className={`text-main text-4xl`}>{t("requested_information")}</h1>
              </div>
              <div>
                <ButtonSecondary
                  text={t("exit")}
                  onClick={() => {
                    localStorage.removeItem("X-RI-Token");
                    setAccessToken(null);
                  }}
                />
              </div>
            </div>
            <div>
              {(!requestedInfo ||
                (!requestedInfo.kpis_automated && requestedInfo.forms.length === 0)) && (
                <NoDataInfo title={t("no_requested_information")} />
              )}
              {requestedInfo &&
                (requestedInfo.kpis_automated || requestedInfo.forms.length !== 0) && (
                  <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4">
                    {requestedInfo.kpis_automated && (
                      <Card key={"kpis"}>
                        <h5
                          className={`uppercase font-bold text-sm leading-none text-main truncate mb-1`}
                        >
                          {t("kpis")}
                        </h5>
                        <div className="text-xs mt-2">
                          {!requestedInfo.kpis_automated.updated_at &&
                            t("information_not_provided")}
                          {requestedInfo.kpis_automated.updated_at &&
                            t("last_updated_date", {
                              date: moment(requestedInfo.kpis_automated.updated_at).format(
                                "YYYY-MM-DD",
                              ),
                            })}
                        </div>
                        <div className={`flex justify-end mt-4 pt-2 border-t border-separator`}>
                          <ButtonCardMain
                            onClick={() =>
                              navigate(
                                `${RoutesLinks.REQUESTED_INFORMATION_LINK}/kpis?deal=${accessToken}`,
                              )
                            }
                            text={t("see_details")}
                            iconComponent={<FaEye className="inline-block w-6 h-3" />}
                          />
                        </div>
                      </Card>
                    )}
                    {requestedInfo.forms.length !== 0 &&
                      requestedInfo.forms.map((form) => {
                        return (
                          <Card key={form._id}>
                            <h5
                              className={`uppercase font-bold text-sm leading-none text-main truncate mb-1`}
                            >
                              {form.name}
                            </h5>
                            <div className="text-xs mt-2">
                              {!form.application && t("information_not_provided")}
                              {form.application &&
                                t("last_updated_date", {
                                  date: moment(form.date).format("YYYY-MM-DD"),
                                })}
                            </div>

                            <div className={`flex justify-end mt-4 pt-2 border-t border-separator`}>
                              <ButtonCardMain
                                onClick={() =>
                                  navigate(
                                    `${RoutesLinks.REQUESTED_INFORMATION_LINK}/forms/${form._id}?deal=${accessToken}${form.application ? `&application=${form.application}` : ""}`,
                                  )
                                }
                                text={t("see_details")}
                                iconComponent={<FaEye className="inline-block w-6 h-3" />}
                              />
                            </div>
                          </Card>
                        );
                      })}
                  </div>
                )}
            </div>
          </>
        )}
        {(!authorized || error) && !loading && (
          <Card
            wrapperClassName={`bg-main mt-24 max-w-screen-sm mx-auto`}
            containerClassName={`text-center text-lg my-6 text-main`}
          >
            <div>
              <FaExclamationCircle className="inline-block mb-6" size={70} />
            </div>
            <p>{t(error ? "error_occurred_retrieving_information" : "forbidden_access")}</p>
          </Card>
        )}
      </section>
      <SharedEventFooter />
    </div>
  );
};
