import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useToasts } from "react-toast-notifications";
import { SidePanel } from "components/ui/objects/SidePanel";
import { Input } from "components/ui/atoms/Input";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { useEffect } from "react";
import { omit } from "lodash";

const MembersSearcher = ({ onFilter, onClose, filters = {} }) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const { register, handleSubmit, trigger, errors, setValue, reset } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string(),
      }),
      { abortEarly: false },
    ),
    criteriaMode: "all",
    reValidateMode: "all",
    mode: "onChange",
  });

  const filter = (values) => {
    onFilter &&
      onFilter(values.name ? { ...filters, name: values.name } : { ...omit(filters, "name") });
    onClose && onClose();
  };

  const resetFilters = () => {
    reset();
    filter({ ...omit(filters, "name") });
  };

  const onInvalid = (error) => {
    console.error(error);
    addToast(t("form_field_error"), { appearance: "error", autoDismiss: true });
  };

  useEffect(() => {
    setValue("name", filters.name);
    trigger("name");
  }, [filters]);

  return (
    <SidePanel title={t("search_members")} onClose={() => onClose && onClose()} width={"1/3"}>
      <form className="flex flex-col w-full" onSubmit={handleSubmit(filter, onInvalid)}>
        <div>
          <Input
            reference={register}
            name="name"
            label={t("name")}
            placeholder={t("name")}
            error={errors.name}
          />
        </div>

        <div className={"flex justify-end mt-2 pt-2 border-t border-separator"}>
          <ButtonSecondary
            text={t("clear")}
            onClick={() => resetFilters()}
            type="button"
            marginRight={2}
          />
          <ButtonMain text={t("search")} type="submit" />
        </div>
      </form>
    </SidePanel>
  );
};

export default MembersSearcher;
