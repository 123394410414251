import { useTranslation } from "react-i18next";
import { SidePanel } from "components/ui/objects/SidePanel";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import Select from "components/ui/atoms/Select";
import { useState } from "react";
import { useToasts } from "react-toast-notifications";

export const ExchangeCardSection = ({ onClose, stages = [], exchangeCard, currentStage }) => {
  const { t } = useTranslation();

  const [selectedStage, setSelectedStage] = useState(null);
  const { addToast } = useToasts();

  const getSelectedStage = () => {
    const stage = stages.find((stage) => stage._id === selectedStage);

    return [{ id: stage._id, value: stage.name }];
  };

  return (
    <SidePanel width={"2/5"} onClose={onClose} title={t("move_startup")}>
      <Select
        label={t("stage")}
        required={true}
        isClearable={false}
        placeholder={t("select_option")}
        initialValues={selectedStage ? getSelectedStage() : []}
        items={stages
          .filter((stage) => stage._id !== currentStage._id)
          .map((stage) => ({ id: stage._id, value: stage.name }))}
        onSelect={(selected) => {
          setSelectedStage(selected.length ? selected[0].id : null);
        }}
      />
      <div className={"flex justify-end mt-2 pt-2 border-t border-separator"}>
        <ButtonMain
          onClick={async() => {
            if (selectedStage) {
              exchangeCard(stages.find((stage) => selectedStage === stage._id));
            } else {
              addToast(t("stage_required"), {
                appearance: "error",
                autoDismiss: true,
              });
            }
          }}
          text={t("move_startup")}
        />
      </div>
    </SidePanel>
  );
};
