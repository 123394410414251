import { useTranslation } from "react-i18next";
import { PageTitle } from "components/ui/atoms/PageTitle";
import { SectionHeader } from "components/ui/molecules/SectionHeader";
import MyLandBot from "components/ui/atoms/MyLandBot";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useUser from "hooks/useUser";

const ChatWithUs = ({ section, urls, sections }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleRedirect = (url) => navigate(url);
  const { userLanguage } = useUser();

  const platform = useSelector((state) => state.app.platform);

  return (
    <>
      <div className="flex mb-6">
        <div className="flex-1">
          <PageTitle removeMargin={true} showBack={false} title={t("help")} />
        </div>
      </div>
      <SectionHeader
        section={section}
        setSection={(sec) => {
          handleRedirect(urls[sec]);
        }}
        sectionKeys={[]}
        sectionTitles={sections.map((s) => t(s))}
      />
      <MyLandBot url={platform.chat[userLanguage]} />
    </>
  );
};

export default ChatWithUs;
