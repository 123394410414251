import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { DealflowService } from "../../services/dealflow-service";
import { Loader } from "../ui/molecules/Loader";
import { PageTitle } from "../ui/atoms/PageTitle";
import { FaEdit, FaPaperPlane, FaPlus } from "react-icons/fa";
import { AiOutlineMore } from "react-icons/ai";
import { HiAnnotation } from "react-icons/hi";
import { ManageTagsModal } from "../tags/ManageTagsModal";
import { useSwal } from "../../hooks/useSwal";
import DealflowActions from "./DealFlowActions";
import { SectionHeader } from "../ui/molecules/SectionHeader";
import { RoutesLinks } from "../routes-links";
import Select from "../ui/atoms/Select";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import RegisterInvestmentSection from "./RegisterInvestmentSection";
import RejectDealSection from "./RejectDealSection";
import MarkStartupAsFailedSection from "./MarkStartupAsFailedSection";
import RegisterExitSection from "./RegisterExitSection";
import DeleteDealSection from "./DeleteDealSection";
import ReminderSection from "components/reminders/ReminderSection";
import RemindersOperations from "redux/reminders/reminders-operations";
import { useDispatch } from "react-redux";
import NotesContainer from "components/ui/objects/NotesContainer";
import useUser from "hooks/useUser";
import ContactsService from "services/contacts-service";
import { ShareDealSection } from "components/ui/molecules/ShareDealSection";
import { ButtonCardSecondary } from "components/ui/atoms/ButtonCardSecondary";
import RemindersService from "services/reminders-service";
import NotesService from "services/notes-service";
import ReportsService from "services/reports-service";
import KrainService from "services/krain-service";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { SidePanel } from "components/ui/objects/SidePanel";
import { RichEditor } from "components/ui/objects/RichEditor";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Input } from "components/ui/atoms/Input";
import { sendMessage as sendMessageOutlook } from "services/outlook-service";
import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import MigrationSection from "components/ui/molecules/MigrationSection";
import DocumentsService from "services/documents-service";
import MembersService from "services/members-service";
import { lastValueFrom } from "rxjs";

import DrawNotes from "components/project/components/general-information/components/draw-notes";
import VoiceNotes from "components/project/components/general-information/components/voice-notes";

import { DealOwner } from "./DealOwners";
import { Nuclia } from "@nuclia/core";
import { ContextActions, contextOperations } from "redux/context";
import Config from "config";
import { getPathSegment } from "utils/get-path-segment";
import TagsService from "services/tags-service";

const API_KEY = "AIzaSyDDXutZvDTGFliGz9_-PG1IwnXERSVu5jU";
const CLIENT_ID = "690938764456-ac8b32rplj2tfk4f43grc7iuh36udi8i.apps.googleusercontent.com";

export const DealflowView = () => {
  const msal = useMsal();
  const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(msal.instance, {
    account: msal.instance.getActiveAccount(),
    scopes: ["user.read", "mail.read", "mail.send"],
    interactionType: "none",
  });

  const pageRef = useRef();
  const { user, reloadUserInfo, checkIfLPhasAccessToSection, hasAccessToModule } = useUser();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { dealId } = useParams();
  const { alert, confirm } = useSwal();
  const isAuthenticated = useIsAuthenticated();
  const [emailGoogle, setEmailGoogle] = useState("");
  const [isAuthenticatedOutlook, setIsAuthenticatedOutlook] = useState(false);
  const [openSendEmail, setOpenSendEmail] = useState(false);
  const [isAuthenticatedGoogle, setIsAuthenticatedGoogle] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingReminders, setLoadingReminders] = useState(false);
  const [deal, setDeal] = useState(null);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [showDealflowActions, setShowDealflowActions] = useState(false);
  const [showRegisterInvestmentSection, setShowRegisterInvestmentSection] = useState(false);
  const [showRejectDealSection, setShowRejectDealSection] = useState(false);
  const [showMarkAsFailedSection, setShowMarkAsFailedSection] = useState(false);
  const [showRegisterExitSection, setShowRegisterExitSection] = useState(false);
  const [showDeleteDealSection, setShowDeleteDealSection] = useState(false);
  const [investments, setInvestments] = useState([]);
  const [section, setSection] = useState(getPathSegment(location.href, 3) ?? "general_information");
  const [sections, setSections] = useState([]);
  const [showReminderSection, setShowReminderSection] = useState(false);
  const [reminderToEdit, setReminderToEdit] = useState(null);
  const [showNotesContainer, setShowNotesContainer] = useState(false);
  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [showShareDealSection, setShowShareDealSection] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [members, setMembers] = useState([]);
  const [tags, setTags] = useState([]);

  const [showDealOwnersModal, setShowDealOwnersModal] = useState(false);

  const [emailsContact, setEmailsContact] = useState([]);
  const [sendOtherEmail, setSendOtherEmail] = useState(false);
  const [dealflow, setDealflow] = useState([]);
  const [showMigrationSection, setShowMigrationSection] = useState(false);
  const [noteToMigrate, setNoteToMigrate] = useState(null);
  const [documentToMigrate, setDocumentToMigrate] = useState(null);
  const [reloadDocuments, setReloadDocuments] = useState(false);

  const [showNoteInProfile, setShowNoteInProfile] = useState(false);

  const [showVoiceModal, setShowVoiceModal] = useState(false);
  const [showDrawerModal, setShowDrawerModal] = useState(false);

  const TextNoteSchema = Yup.object().shape({
    subject: Yup.string().required(t("required_field")),
    content: Yup.string().required(t("required_field")),
    emails: Yup.array().required(t("required_field")),
  });

  const { register, handleSubmit, errors, setValue, getValues, trigger } = useForm({
    mode: "all",
    criteriaMode: "all",
    reValidateMode: "all",
    resolver: yupResolver(TextNoteSchema),
  });

  const _setValue = (name, value) => {
    setValue(name, value, { shouldValidate: true });
    trigger(name);
  };

  useEffect(() => {
    register("content");
    register("emails");
  }, []);

  function makeBody(to, from, subject, message) {
    const str = [
      'Content-Type: text/html; charset="UTF-8"\n',
      "MIME-Version: 1.0\n",
      "Content-Transfer-Encoding: 7bit\n",
      "to: ",
      to,
      "\n",
      "from: ",
      from,
      "\n",
      "subject: ",
      subject,
      "\n\n",
      message,
    ].join("");

    // Encode the string to base64
    const encodedMail = btoa(unescape(encodeURIComponent(str)))
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, ""); // Remove padding for URL-safe base64

    return encodedMail;
  }

  async function sendMessageGoogle({ subject, content, emails }) {
    const emailPromises = emails.map(async (email) => {
      const raw = makeBody(email, emailGoogle, subject, content);

      console.log(raw);
      // eslint-disable-next-line no-undef
      return gapi.client.gmail.users.messages.send({
        userId: "me",
        resource: {
          raw,
        },
      });
    });
    return Promise.all(emailPromises);
  }

  const onSubmit = async () => {
    const subject = getValues("subject");
    const content = getValues("content");
    const emails = getValues("emails");
    setLoading(true);
    if (isAuthenticatedOutlook) {
      sendMessageOutlook(authProvider, { subject, content, emails })
        .then(() => {
          addToast("Correo enviado con éxito", {
            appearance: "success",
            autoDismiss: true,
          });
        })
        .catch((error) => {
          addToast(
            error.response && error.response.data.msg_key
              ? t(error.response.data.msg_key)
              : "Ha ocurrido un error al enviar el email",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        })
        .finally(() => {
          setLoading(false);
          setOpenSendEmail(false);
        });
    } else {
      sendMessageGoogle({ subject, content, emails })
        .then(() => {
          addToast("Correo enviado con éxito", {
            appearance: "success",
            autoDismiss: true,
          });
        })
        .catch((error) => {
          console.log(error);
          addToast(
            error.response && error.response.data.msg_key
              ? t(error.response.data.msg_key)
              : "Ha ocurrido un error al enviar el email",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        })
        .finally(() => {
          setValue("subject", "");
          setValue("content", "");
          setValue("emails", []);
          setLoading(false);
          setOpenSendEmail(false);
        });
    }
  };

  const setRemindersOperations = (reminders) => dispatch(RemindersOperations.setReminders(reminders));

  const setTotalReminders = (total) => dispatch(RemindersOperations.setTotalReminders(total));

  const [evaluation, setEvaluation] = useState(null);

  const getDeal = async () => {
    try {
      setLoading(true);
      const result = await DealflowService.getDealComplete(dealId);
      if (result.data.data) {
        setDeal(result.data.data);
        const dealt = result.data.data;
        setNotes(dealt.notes);
        if (dealt?.main_contact_email) {
          const emailsFounders = dealt?.founders?.map((founder) => founder.email) || [];
          const otherEmails = dealt?.other_emails?.map((email) => email) || [];
          const data = [...emailsFounders, ...otherEmails, dealt?.main_contact_email];
          const dataSet = new Set(data);
          const newData = [...dataSet];
          const mapEmails = newData.map((item, index) => ({
            id: index + 1,
            value: item,
          }));
          setEmailsContact(mapEmails);
        }
        dispatch(contextOperations.handleSetContext({ name: dealt.name, id: dealt._id }));
      } else {
        addToast(t("error_occurred_retrieving_dealflow"), {
          appearance: "error",
          autoDismiss: true,
        });
        navigate(RoutesLinks.DEALFLOW_LINK);
      }
    } catch (error) {
      console.error(error);
      addToast(t("error_occurred_retrieving_dealflow"), { appearance: "error", autoDismiss: true });
      navigate(-1);
    } finally {
      setLoading(false);
    }
  };

  const getInvestments = async () => {
    try {
      setLoading(true);
      const result = await DealflowService.getInvestments(dealId);
      setInvestments(result.data.data);
    } catch (error) {
      addToast(t("error_occurred_retrieving_dealflow"), { appearance: "error", autoDismiss: true });
    } finally {
      setLoading(false);
    }
  };

  const updateDealTags = (tags) => {
    setLoading(true);
    return DealflowService.updateDealTags(dealId, tags)
      .then(() => getDeal())
      .catch(() => {
        addToast(t("error_occurred_updating_tags"), { appearance: "error", autoDismiss: true });
      })
      .finally(() => {
        setLoading(false);
        setShowTagsModal(false);
      });
  };

  const deleteDeal = (data) => {
    confirm({ text: t("delete_deal_description") }).then((isConfirmed) => {
      if (isConfirmed) {
        setLoading(true);
        DealflowService.deleteDeal(dealId, data)
          .then(() => {
            addToast(t("deal_deleted"), { appearance: "success", autoDismiss: true });
            navigate(-1);
          })
          .catch(() => {
            addToast(t("error_occurred"), { appearance: "error", autoDismiss: true });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  };

  const deleteReminder = (reminderId) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await RemindersService.deleteReminder(reminderId);
          getReminders();
          getRemindersResume();
          addToast(t("reminder_deleted_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
        } catch (error) {
          addToast(t("error_occurred_deleting_reminder"), {
            appearance: "error",
            autoDismiss: true,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const markReminderAsCompleted = (reminder) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await RemindersService.updateReminder(reminder._id, { completed: true });
          getReminders();
          getRemindersResume();
          addToast(t("reminder_marked_as_completed_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
        } catch (error) {
          addToast(t("error_occurred_marking_reminder_as_completed"), {
            appearance: "error",
            autoDismiss: true,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const downloadKpisReport = () => {
    setLoading(true);
    ReportsService.generateKpisReport(deal._id)
      .catch(() => {
        addToast(t("error_occurred"), { appearance: "error", autoDismiss: true });
      })
      .finally(() => {
        setLoading(false);
        setShowDealflowActions(false);
      });
  };

  const downloadStartupProfile = () => {
    setLoading(true);
    // if no specific report, use the default
    const reportId = user.investor.reports
      ? user.investor.reports.startup_profile
        ? user.investor.reports.startup_profile
        : Config.ENV === "qa"
        ? "663376b43fbb4f5239751601"
        : "65defb0315bc6537ec0b453f"
      : Config.ENV === "qa"
      ? "663376b43fbb4f5239751601"
      : "65defb0315bc6537ec0b453f";

    ReportsService.generateStartupProfileReport(reportId, deal._id, deal.name)
      .catch(() => {
        addToast(t("error_occurred"), { appearance: "error", autoDismiss: true });
      })
      .finally(() => {
        setLoading(false);
        setShowDealflowActions(false);
      });
  };

  const nuclia = new Nuclia({
    backend: "https://nuclia.cloud/api",
    zone: "europe-1",
    knowledgeBox: "8ef5c196-3143-4aae-8f1c-f44bfc8412e5",
  });

  const generateStartupProfile = async () => {
    const consolidatedProfile = {};

    try {
      setLoading(true);

      const firstQuery = lastValueFrom(
        nuclia.knowledgeBox.ask(
          `
        What is the EBIT in 2024 and 2025 and Sales & Marketing (S&M) Expenses in 2024 for the startup ${deal.name}? Please provide the information in JSON format only and in english.

        {
          "EBIT_2024": "",
          "EBIT_2025": "",
          "S&M_Expenses_2024": ""
        }
    `,
          [],
          [],
          {
            generative_model: "chatgpt4o",
          }
        )
      );

      const secondQuery = lastValueFrom(
        nuclia.knowledgeBox.ask(
          `
      I am conducting a research study on the startup ${deal.name}. Please provide the following information in JSON format only, without any additional text and in english:
    
      {
        "CEO":"",
        "Description": "",
        "Business_Model": "",
        "Clients": ""
      }
    `,
          [],
          [],
          {
            generative_model: "chatgpt4o",
          }
        )
      );

      const results = await Promise.all([firstQuery, secondQuery]);

      results.forEach((result) => {
        const jsonResponse = result.text.match(/```json([\s\S]*?)```/)[1].trim();
        const parsedResult = JSON.parse(jsonResponse);
        Object.assign(consolidatedProfile, parsedResult);
      });

      console.log(consolidatedProfile);
      // addToast(t('investment_registered_successfully'), { appearance: 'success', autoDismiss: true })
      await ReportsService.generateMemoReport(deal, consolidatedProfile);
    } catch (error) {
      console.error("Error generating startup profile:", error);
    } finally {
      setLoading(false);
    }
  };

  const haveManagedProject = () => deal?.managed_project && Object.keys(deal?.managed_project || {}).length > 0;

  const getDealflow = () =>
    DealflowService.getDealflow({
      rowsPerPage: 999999,
      pool: "true",
      populate: "false",
      boards: "false",
      milestones: "false",
      files: "false",
      reminders: "false",
      annotations: "false",
    }).then((response) => {
      setDealflow(response.data.data.dealflow);
    });

  useEffect(() => {
    if (deal) {
      // getKiotaGammaEvaluation()
      getDealflow();
      const _sections = [{ id: "general_information", route: "", title: "general_information" }];

      if (!user.lp) {
        _sections.push({ id: "kpis", route: "kpis", title: "kpis" });
        _sections.push({ id: "documents", route: "documents", title: "documents" });
        _sections.push({ id: "reports", route: "reports", title: "reports" });
        _sections.push({ id: "evaluations", route: "evaluations", title: "evaluations" });
        _sections.push({ id: "milestones", route: "milestones", title: "milestones" });
        _sections.push({ id: "tracking", route: "tracking", title: "tracking" });
        _sections.push({ id: "questionnaires", route: "questionnaires", title: "questionnaires" });
        _sections.push({ id: "investors_matching", route: "investors_matching", title: "investors_matching" });

        if (hasAccessToModule("members")) {
          _sections.push({ id: "members", route: "members", title: "members" });
        }
      } else if (user.lp && checkIfLPhasAccessToSection("documentation")) {
        _sections.push({ id: "documents", route: "documents", title: "documents" });
      }

      // _sections.push('our_analysis')

      setSections(_sections);
      if (!section) {
        setSection(_sections[0]);
      }
    }

    return () => {
      dispatch(ContextActions.handleRemoveContext());
    };
  }, [deal]);

  const getReminders = async () => {
    try {
      setLoadingReminders(true);
      const result = await RemindersService.getReminders({
        deal: dealId,
        page: 0,
        rowsPerPage: 9999,
      });
      setDeal({ ...deal, reminders: result.data.data.reminders });
    } catch (error) {
      addToast(t("error_occurred_retrieving_reminders"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoadingReminders(false);
    }
  };

  const getRemindersResume = async () => {
    try {
      const result = await RemindersService.getRemindersResume();
      if (result.data.data) {
        setRemindersOperations(result.data.data.reminders);
        setTotalReminders(result.data.data.total);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getNotes = async () => {
    try {
      setLoading(true);
      const result = await NotesService.getNotes({ deal: dealId });
      setDeal({ ...deal, notes: result.data.data });
    } catch (error) {
      addToast(t("error_occurred_retrieving_notes"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const getTags = () => {
    TagsService.getTags("dealflow")
      .then((response) => {
        setTags(response.data.data);
      })
      .catch(() => {
        addToast(t("error_occurred_retrieving_tags"), {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const createNote = async (title, type, content) => {
    try {
      setLoading(true);
      await NotesService.createNote({
        user: user._id,
        title,
        content,
        type,
        deal: dealId,
      });
      getNotes();
    } catch (error) {
      addToast(t("error_occurred_creating_note"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const updateNote = async (title, content) => {
    try {
      setLoading(true);
      await NotesService.updateNote(selectedNote._id, { title, content });
      setSelectedNote(null);
      getNotes();
    } catch (error) {
      addToast(t("error_occurred_updating_note"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteNote = async (noteId) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await NotesService.deleteNote(noteId);
          getNotes();
        } catch (error) {
          addToast(t("error_occurred_deleting_note"), {
            appearance: "error",
            autoDismiss: true,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const getNote = async (noteId) => {
    try {
      setLoading(true);
      const result = await NotesService.getNote(noteId);
      setSelectedNote(result.data.data);
      setShowNotesContainer(true);
    } catch (error) {
      addToast(t("error_occurred_retrieving_note"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const getNoteFromProfile = async (noteId) => {
    try {
      setLoading(true);
      const result = await NotesService.getNote(noteId);
      setSelectedNote(result.data.data);
      if (result.data.data.type === "draw") {
        setShowDrawerModal(true);
      } else if (result.data.data.type === "text") {
        setShowNotesContainer(true);
      } else if (result.data.data.type === "audio") {
        setShowVoiceModal(true);
      }
    } catch (error) {
      addToast(t("error_occurred_retrieving_note"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const getContactsList = async () => {
    try {
      setLoading(true);
      const res = await ContactsService.getContactsList();
      setContacts(res.data.data);
    } catch (error) {
      addToast(t("error_occurred_retrieving_contacts"), { appearance: "error", autoDismiss: true });
    } finally {
      setLoading(false);
    }
  };

  const getMembers = async () => {
    try {
      setLoading(true);
      const res = await MembersService.getMembers({ rowsPerPage: 999 });
      setMembers(res.data.data.members);
    } catch (error) {
      // addToast(t("error_occurred_retrieving_members"), { appearance: "error", autoDismiss: true });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (!user.lp || (user.lp && checkIfLPhasAccessToSection("dealflow"))) {
      getDeal();
      getContactsList();
      getTags();
      if (hasAccessToModule("members")) {
        getMembers();
      }
    } else {
      navigate(RoutesLinks.DASHBOARD_LINK);
      addToast(t("forbidden_access"), { appearance: "error", autoDismiss: true });
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (showNotesContainer) {
      getNotes();
    }
  }, [showNotesContainer]);

  useEffect(() => {
    const initClient = async () => {
      // eslint-disable-next-line no-undef
      if (!gapi.auth2.getAuthInstance()) {
        // eslint-disable-next-line no-undef
        await gapi.client.init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest"],
          scope:
            "https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.compose https://www.googleapis.com/auth/gmail.send",
        });
      }

      // eslint-disable-next-line no-undef
      const authInstance = gapi.auth2.getAuthInstance();

      if (authInstance.isSignedIn.get()) {
        const basicProfile = authInstance.currentUser.get().getBasicProfile();
        setEmailGoogle(basicProfile.getEmail());
        setIsAuthenticatedGoogle(true);
      } else {
        setIsAuthenticatedGoogle(false);
      }
    };

    try {
      // eslint-disable-next-line no-undef
      gapi.load("client:auth2", initClient);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    setIsAuthenticatedOutlook(isAuthenticated);
  }, []);

  if (!deal) {
    return null;
  }

  const registerInvestment = async (values) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          if (values.is_own_investment) {
            delete values.shareholder;
          }
          await DealflowService.registerInvestment(dealId, {
            ...values,
            sector: deal?.sector?._id,
          });
          addToast(t("investment_registered_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
          setShowRegisterInvestmentSection(false);
          getDeal();
          getInvestments();
        } catch (error) {
          console.error(error);
          addToast(t("error_occurred_registering_investment"), {
            appearance: "error",
            autoDismiss: true,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const rejectDeal = async (values) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await DealflowService.rejectDeal(dealId, values);
          addToast(t("startup_rejected_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
          setShowRejectDealSection(false);
          getDeal();
        } catch (error) {
          addToast(t("error_occurred_rejecting_startup"), {
            appearance: "error",
            autoDismiss: true,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const archiveDeal = async (values) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await DealflowService.archiveDeal(dealId, values);
          addToast(t("startup_archived_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
          getDeal();
        } catch (error) {
          addToast(t("error_occurred_archiving_startup"), {
            appearance: "error",
            autoDismiss: true,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const unarchiveDeal = async (values) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await DealflowService.unarchiveDeal(dealId, values);
          addToast(t("startup_unarchived_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
          getDeal();
        } catch (error) {
          addToast(t("error_occurred_unarchiving_startup"), {
            appearance: "error",
            autoDismiss: true,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const markDealAsFailed = async (values) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await DealflowService.markAsFailed(dealId, values);
          addToast(t("startup_marked_as_failed_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
          setShowMarkAsFailedSection(false);
          getDeal();
        } catch (error) {
          addToast(t("error_occurred_marking_startup_as_failed"), {
            appearance: "error",
            autoDismiss: true,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const registerExit = async (values) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await DealflowService.registerExit(dealId, values);
          addToast(t("exit_registered_successfully"), { appearance: "success", autoDismiss: true });
          setShowRegisterExitSection(false);
          getDeal();
        } catch (error) {
          addToast(t("error_occurred_registering_exit"), {
            appearance: "error",
            autoDismiss: true,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const markDealAsInvested = async (values) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await DealflowService.markAsInvested(dealId);
          addToast(t("startup_marked_as_invested_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
          setShowDealflowActions(false);
          getDeal();
        } catch (error) {
          addToast(t("error_occurred_marking_startup_as_invested"), {
            appearance: "error",
            autoDismiss: true,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const markDealAsInteresting = async (interesting = true) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await DealflowService.markAsInteresting(dealId, interesting);
          addToast(
            t(
              interesting
                ? "startup_marked_as_interesting_successfully"
                : "startup_unmarked_as_interesting_successfully"
            ),
            { appearance: "success", autoDismiss: true }
          );
          setShowDealflowActions(false);
          getDeal();
        } catch (error) {
          addToast(
            t(
              interesting
                ? "error_occurred_marking_startup_as_interesting"
                : "error_occurred_unmarking_startup_as_interesting"
            ),
            { appearance: "error", autoDismiss: true }
          );
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const shareDeal = async (section, selectedContacts, data) => {
    confirm({}).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await DealflowService.shareDealflow(deal._id, {
            section,
            ...data,
            contacts: selectedContacts.map((contact) => ({
              _id: contact._id,
              name: contact.contact || contact.company,
              email: contact.contact_email,
              language: contact.language,
            })),
          });
          addToast(t("deal_shared_successfully"), { appearance: "success", autoDismiss: true });
          setShowShareDealSection(null);
          reloadUserInfo();
        } catch (error) {
          console.error(error);
          addToast(t("error_occurred_sharing_deal"), { appearance: "error", autoDismiss: true });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const handleSendOtherEmail = () => {
    setSendOtherEmail(!sendOtherEmail);
  };

  const migrateNote = async (dealToMigrateId) => {
    try {
      setLoading(true);
      await NotesService.updateNote(noteToMigrate, { deal: dealToMigrateId });
      addToast(t("note_migrated_successfully"), { appearance: "success", autoDismiss: true });
      setNoteToMigrate(null);
      setShowMigrationSection(false);
      getNotes();
    } catch (error) {
      console.error(error);
      addToast(t("error_occurred_migrating_note"), { appearance: "error", autoDismiss: true });
    } finally {
      setLoading(false);
    }
  };

  const migrateDocument = async (dealToMigrateId) => {
    try {
      setLoading(true);
      if (documentToMigrate.category !== "dc_other_documents") {
        confirm({
          title: t("are_you_sure"),
          text: t("migrate_document_key_category_explanation"),
        }).then(async (isConfirmed) => {
          if (isConfirmed) {
            await DocumentsService.migrateDocument(documentToMigrate._id, dealToMigrateId);
            addToast(t("document_migrated_successfully"), {
              appearance: "success",
              autoDismiss: true,
            });
            setDocumentToMigrate(null);
            setShowMigrationSection(false);
            getDeal();
            setReloadDocuments(true);
          }
        });
      } else {
        await DocumentsService.migrateDocument(documentToMigrate._id, dealToMigrateId);
        addToast(t("document_migrated_successfully"), { appearance: "success", autoDismiss: true });
        setDocumentToMigrate(null);
        setShowMigrationSection(false);
        setReloadDocuments(true);
      }
    } catch (error) {
      addToast(t("error_occurred_migrating_document"), { appearance: "error", autoDismiss: true });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseTextModal = () => {
    setSelectedNote(null);
  };

  const handleCloseDrawerModal = () => {
    setShowDrawerModal(false);
    setSelectedNote(null);
  };

  const handleCloseVoiceModal = () => {
    setShowVoiceModal(false);
    setSelectedNote(null);
  };

  return (
    <>
      {showVoiceModal && (
        <VoiceNotes
          editable={true}
          showModal={showVoiceModal}
          onSubmit={(title, type, content) => {
            setShowVoiceModal(false);
            createNote(title, type, content);
          }}
          initialValues={selectedNote}
          handleClose={handleCloseVoiceModal}
        />
      )}

      {showDealOwnersModal && (
        <DealOwner
          deal={deal}
          handleClose={() => setShowDealOwnersModal(false)}
          setLoading={setLoading}
          getDeal={getDeal}
        />
      )}
      {showDrawerModal && (
        <DrawNotes
          editable={true}
          showModal={showDrawerModal}
          onSubmit={(title, content) => {
            setShowDrawerModal(false);
            if (selectedNote) {
              updateNote(title, content);
            } else {
              createNote(title, "draw", content);
            }
          }}
          handleClose={handleCloseDrawerModal}
          initialValues={{
            title: selectedNote ? selectedNote.title : "",
            content: selectedNote ? selectedNote.content : "",
          }}
        />
      )}
      {showMigrationSection && (
        <MigrationSection
          onClose={() => {
            setShowMigrationSection(false);
            setNoteToMigrate(null);
            setDocumentToMigrate(null);
          }}
          migrationType={noteToMigrate ? "notes" : "documents"}
          dealflow={dealflow}
          onSubmit={(dealToMigrateId) => {
            if (noteToMigrate) {
              migrateNote(dealToMigrateId);
            } else {
              migrateDocument(dealToMigrateId);
            }
          }}
        />
      )}

      {showShareDealSection && (
        <ShareDealSection
          onClose={() => {
            setShowShareDealSection(null);
          }}
          onSubmit={shareDeal}
          irm={false}
          members={members}
          contacts={contacts}
          initialValues={user.share_dealflow_preferences || {}}
        />
      )}
      {showNotesContainer && (
        <NotesContainer
          zIndex="20"
          listTitle={t("notes")}
          setFiltersButtons={false}
          setShowModal={setShowNotesContainer}
          notes={notes}
          createNote={createNote}
          updateNote={updateNote}
          deleteNote={deleteNote}
          getNote={getNote}
          selectedNote={selectedNote}
          setSelectedNote={setSelectedNote}
          onClose={() => {
            setSelectedNote(null);
            setShowNotesContainer(false);
          }}
          setShowMigrationSection={(noteId) => {
            setShowNotesContainer(false);
            setShowMigrationSection(true);
            setNoteToMigrate(noteId);
          }}
        />
      )}
      <div className="flex items-start">
        <div className="flex-1">
          <PageTitle title={deal?.name} showBack={true} />
        </div>

        {section === "our_analysis" && (
          <ButtonSecondary
            iconComponent={<FaPlus className="inline-block mr-2" />}
            marginRight="2"
            text={t("request_evaluation")}
            onClick={() => pageRef?.current?.showRequestEvaluationSection()}
          />
        )}
        {section === "documents" && !user.lp && (
          <>
            <ButtonSecondary
              iconComponent={<FaPlus className="inline-block mr-2" />}
              marginRight="2"
              text={t("upload_document")}
              onClick={() => pageRef?.current?.uploadDocument()}
            />
            <ButtonSecondary
              iconComponent={<FaPlus className="inline-block mr-2" />}
              marginRight={"2"}
              text={t("create_folder")}
              onClick={() => pageRef?.current?.createFolder()}
            />
          </>
        )}

        {section === "general_information" && (
          <>
            {!user.lp && (
              <div className="mr-2" data-tip={t("edit")}>
                <ButtonCardSecondary
                  onClick={() => navigate(`${RoutesLinks.DEALFLOW_LINK}${dealId}/edit`)}
                  iconComponent={<FaEdit className="inline-block w-4 h-4" />}
                />
              </div>
            )}
            {(!user.lp || (user.lp && checkIfLPhasAccessToSection("annotations"))) && (
              <div className="mr-2" data-tip={t("annotations")}>
                <ButtonCardSecondary
                  onClick={() => setShowNotesContainer(true)}
                  iconComponent={<HiAnnotation className="inline-block w-4 h-4" />}
                />
              </div>
            )}
          </>
        )}

        {section === "kpis" && (
          <>
            <div className="mr-2" data-tip={t("edit")}>
              <ButtonCardSecondary
                onClick={() => setShowNotesContainer(true)}
                iconComponent={<HiAnnotation className="inline-block w-4 h-4" />}
              />
            </div>
            <div className="mr-2" data-tip={t("annotations")}>
              <ButtonSecondary
                iconComponent={<FaPaperPlane className="inline-block mr-2" />}
                text={t("request_kpis")}
                onClick={
                  deal.main_contact_email
                    ? () => pageRef.current.requestKpis()
                    : () => alert({ text: t("deal_contact_email_required_to_request_kpis") })
                }
              />
            </div>
          </>
        )}

        {!user.lp && (
          <ButtonMain
            onClick={() => setShowDealflowActions(true)}
            text={t("actions")}
            iconComponent={<AiOutlineMore className="inline-block w-4 h-4" />}
          />
        )}
      </div>

      <SectionHeader
        sectionTitles={sections.map((section) => t(section))}
        sectionKeys={sections}
        section={section}
        setSection={(s) => setSection(s)}
        setShowFilters={() => pageRef?.current?.showFilters()}
        navigate
      />

      {deal && (
        <Outlet
          context={{
            ref: pageRef,
            deal,
            loading,
            setLoading,
            getDeal,
            deleteReminder,
            setReminderToEdit,
            markReminderAsCompleted,
            setSection,
            deleteNote,
            getNote,
            setShowMigrationSection,
            setShowNotesContainer,
            setDocumentToMigrate,
            reloadDocuments,
            setReloadDocuments,
          }}
        />
      )}

      <ManageTagsModal
        category="dealflow"
        currentTags={deal.tags || []}
        tags={tags}
        handleCloseModal={() => setShowTagsModal(false)}
        onSubmit={(tags) => updateDealTags(tags)}
        showModal={showTagsModal}
      />

      {showDealflowActions && (
        <DealflowActions
          interesting={deal.interesting}
          invested={deal.invested}
          rejected={deal.rejected}
          archived={deal.archived}
          exited={deal.exited}
          failed={deal.failed}
          committed={deal.committed}
          onClose={() => {
            setShowDealflowActions(false);
          }}
          showTags={() => setShowTagsModal(true)}
          deleteDeal={() => {
            setShowDealflowActions(false);
            setShowDeleteDealSection(true);
          }}
          registerInvestment={() => {
            setShowDealflowActions(false);
            setShowRegisterInvestmentSection(true);
          }}
          markAsFailed={() => {
            setShowDealflowActions(false);
            setShowMarkAsFailedSection(true);
          }}
          rejectDeal={() => {
            setShowDealflowActions(false);
            setShowRejectDealSection(true);
          }}
          archiveDeal={() => {
            setShowDealflowActions(false);
            archiveDeal();
          }}
          unarchiveDeal={() => {
            setShowDealflowActions(false);
            unarchiveDeal();
          }}
          markAsInvested={markDealAsInvested}
          markAsInteresting={markDealAsInteresting}
          registerExit={() => {
            setShowDealflowActions(false);
            setShowRegisterExitSection(true);
          }}
          contact={() => {
            if (isAuthenticatedGoogle || isAuthenticatedOutlook) {
              setOpenSendEmail(true);
            } else {
              alert({
                text: "No hay cuentas sincronizadas en tu cuenta, ve a tu cuenta y vincula con las opciones disponibles (Goolge, Outlook) para que puedas acceder a esta opción",
              });
            }
          }}
          downloadKpisReport={downloadKpisReport}
          downloadStartupProfile={downloadStartupProfile}
          generateStartupProfile={generateStartupProfile}
          addReminder={() => setShowReminderSection(true)}
          setShowShareDealSection={() => setShowShareDealSection(true)}
          setShowDealOwnersModal={setShowDealOwnersModal}
        />
      )}

      {showDeleteDealSection && (
        <DeleteDealSection onClose={() => setShowDeleteDealSection(false)} deleteDeal={deleteDeal} />
      )}

      {(showReminderSection || reminderToEdit) && (
        <ReminderSection
          onClose={() => {
            setReminderToEdit(null);
            setShowReminderSection(false);
          }}
          afterSubmit={() => {
            setReminderToEdit(null);
            setShowReminderSection(false);
            getReminders();
            getRemindersResume();
          }}
          dealId={dealId}
          initialValues={reminderToEdit}
        />
      )}
      {showRegisterInvestmentSection && (
        <RegisterInvestmentSection
          onClose={() => setShowRegisterInvestmentSection(false)}
          registerInvestment={registerInvestment}
          investments={investments}
        />
      )}

      {showRejectDealSection && (
        <RejectDealSection onClose={() => setShowRejectDealSection(false)} rejectDeal={rejectDeal} />
      )}

      {showMarkAsFailedSection && (
        <MarkStartupAsFailedSection
          onClose={() => setShowMarkAsFailedSection(false)}
          markStartupAsFailed={markDealAsFailed}
        />
      )}

      {showRegisterExitSection && (
        <RegisterExitSection onClose={() => setShowRegisterExitSection(false)} registerInvestment={registerExit} />
      )}

      {openSendEmail && (
        <SidePanel
          width="1/2"
          onClose={() => {
            setValue("subject", "");
            setValue("content", "");
            setValue("emails", []);
            setOpenSendEmail(false);
          }}
          title="Enviar correo"
        >
          <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <div>
              {(!deal.main_contact_email &&
                !deal.founders.some((item) => item?.email?.length) &&
                !deal?.other_emails?.length) ||
              sendOtherEmail ? (
                <>
                  {!deal.main_contact_email &&
                  !deal.founders.some((item) => item?.email?.length) &&
                  !deal?.other_emails?.length ? (
                    <p className="my-2 text-xs">
                      Esta startup no tiene ningún email asociado, ¿a que email deseas enviar el mensaje?
                    </p>
                  ) : null}
                  <Input
                    reference={register}
                    id="email"
                    name="email"
                    placeholder={t("email")}
                    label={t("email")}
                    required={true}
                    error={errors.email}
                    onChange={(e) => {
                      setValue("emails", [e.target.value]);
                    }}
                  />
                  {deal.main_contact_email ||
                  deal.founders.some((item) => item?.email?.length) ||
                  deal?.other_emails?.length ? (
                    <p className="text-xs text-right">
                      Click
                      <span className="mx-1 cursor-pointer text-red" onClick={handleSendOtherEmail}>
                        aquí
                      </span>
                      volver para enviar a los correos registrados
                    </p>
                  ) : null}
                </>
              ) : null}

              {(deal.main_contact_email ||
                deal.founders.some((item) => item?.email?.length) ||
                deal?.other_emails?.length) &&
              !sendOtherEmail ? (
                <>
                  <Select
                    name="emails"
                    multiSelect
                    label={t("emails")}
                    reference={register}
                    isClearable={false}
                    placeholder={t("select_option")}
                    initialValues={[]}
                    items={emailsContact}
                    onSelect={(selected) => {
                      setValue(
                        "emails",
                        selected.map((item) => item.value)
                      );
                    }}
                  />
                  <p className="text-xs text-right">
                    Click
                    <span className="mx-1 cursor-pointer text-red" onClick={handleSendOtherEmail}>
                      aquí
                    </span>
                    para enviar a otro correo
                  </p>
                </>
              ) : null}

              <Input
                reference={register}
                id="subject"
                name="subject"
                placeholder={t("subject")}
                label={t("subject")}
                required={true}
                error={errors.subject}
              />
            </div>
            <div className="mt-6">
              <RichEditor
                id={"content"}
                reference={register}
                name="content"
                placeholder={`${t("write_comment")}`}
                onChange={(value) => _setValue("content", value === "<p><br></p>" ? "" : value)}
                toolbarClassName="content"
                value={getValues().content || ""}
                error={errors.content}
              />
            </div>

            <div className={"flex justify-end mt-4 border-t pt-2 border-separator"}>
              <ButtonMain type="submit" text={t("send")} />
            </div>
          </form>
        </SidePanel>
      )}

      {(loading || loadingReminders) && <Loader />}
    </>
  );
};
