import moment from "moment";
import Lang from "lang";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import FormsService from "../../../../services/forms-service";
import { Loader } from "components/ui/molecules/Loader";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import useUser from "../../../../hooks/useUser";
import { SectionHeader } from "components/ui/molecules/SectionHeader";
import { FormStatistics } from "./FormStatistics";
import { ShareAndEmbedForm } from "../ShareAndEmbedForm";
import { FormContentSection } from "../FormContentSection";
import { FormResultsSection } from "../FormResultsSection";
import { Alert } from "../../../ui/molecules/Alert";
import { FormPreview } from "../FormPreview";
import { FormSettingsSection } from "../FormSettingsSection";
import { RoutesLinks } from "../../../routes-links";
import { PageTitle } from "../../../ui/atoms/PageTitle";
import { FaEye, FaFileExport, FaPaperPlane } from "react-icons/fa";
import { useSwal } from "../../../../hooks/useSwal";
import { Modal } from "../../../ui/molecules/Modal";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { FormSaveConfirmationModal } from "../FormSaveConfirmationModal";
import PipelineManagersService from "services/pipeline-managers-service";
import { FormRedFlags } from "./FormRedFlags";
import { MiniHelp } from "components/ui/atoms/MiniHelp";
import { isEmpty } from "lodash";
import { StatisticsService } from "services/statistics-service";
import { DealflowService } from "services/dealflow-service";

const QuestionnairesForm = () => {
  const dateFormat = "MMM, D";
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { formId, templateId } = useParams();
  const { user, reloadUserInfo } = useUser();
  const { confirm, alert } = useSwal();
  const navigate = useNavigate();
  const [isTemplate, setIsTemplate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingProcessQuestionnaire, setLoadingProcessQuestionnaire] = useState(false);
  const [questionnaire, setQuestionnaire] = useState(null);
  const [answersEvolutions, setAnswersEvolutions] = useState(null);
  const [section, setSection] = useState("");
  const SECTIONS = [
    { id: "overview", route: "", title: "overview" },
    { id: "content", route: "content", title: "content" },
    { id: "red_flags", route: "red_flags", title: "red_flags" },
    { id: "results", route: "results", title: "results" },
    { id: "share_and_integrate", route: "share_and_integrate", title: "share_and_integrate" },
    { id: "settings", route: "settings", title: "settings" },
  ];

  const [formStatistics, setFormStatistics] = useState({});
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showSaveConfirmationModal, setShowSaveConfirmationModal] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [keyQuestions, setKeyQuestions] = useState({});
  const [requiredQuestions, setRequiredQuestions] = useState({});
  const [boards, setBoards] = useState([]);
  const [assignStartupsToPipelineManager, setAssignStartupsToPipelineManager] = useState(false);
  const [pipelineManagerBoard, setPipelineManagerBoard] = useState(null);
  const [pipelineManagerStage, setPipelineManagerStage] = useState(null);
  const [name, setName] = useState("");
  const [formBanner, setFormBanner] = useState(null);
  const [formDescription, setFormDescription] = useState("");
  const [formFavicon, setFormFavicon] = useState(null);
  const [formTitle, setFormTitle] = useState("");
  const [formNotificationEmail, setFormNotificationEmail] = useState("");
  const [formEndedDescription, setFormEndedDescription] = useState("");
  const [languages, setLanguages] = useState([]);
  const [sendNotificationEmail, setSendNotificationEmail] = useState(false);
  const [customEndedMessage, setCustomEndedMessage] = useState(false);
  const [formBackgroundColor, setFormBackgroundColor] = useState("#fff");
  const [formFontColor, setFormFontColor] = useState("");
  const [formButtonFontColor, setFormButtonFontColor] = useState("");
  const [preScoreThreshold, setPreScoreThreshold] = useState(null);
  const [formButtonColor, setFormButtonColor] = useState("");
  const [visibilityMode, setVisibilityMode] = useState("multiple_page");
  const [showInvestorsPercentage, setShowInvestorsPercentage] = useState(false);
  const [activeTemplate, setActiveTemplate] = useState(false);
  const [category, setCategory] = useState("");
  const [estimatedDuration, setEstimatedDuration] = useState(0);
  const [templateLogo, setTemplateLogo] = useState(null);
  const [investorName, setInvestorName] = useState("");
  const [questions, setQuestions] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState({});
  const [redFlagsEnabled, setRedFlagsEnabled] = useState({});
  const [redFlags, setRedFlags] = useState({});
  const { userLanguage } = useUser();
  const [dealflow, setDealflow] = useState([]);
  const [marieLiteRecipient, setMarieLiteRecipient] = useState("");
  const [marieProRecipient, setMarieProRecipient] = useState("");
  const [marieRecipient, setMarieRecipient] = useState("");
  const [marieType, setMarieType] = useState("");
  const [isMarieLite, setIsMarieLite] = useState(false);
  const [isMarieEntrepreneur, setIsMarieEntrepreneur] = useState(false);
  const [isMariePro, setIsMariePro] = useState(false);
  const [marieClient, setMarieClient] = useState("");
  const [formAsMain, setFormAsMain] = useState(false);

  const location = useLocation();

  const getDealflow = () => DealflowService.getDealflow({
    rowsPerPage: 999999,
    pool: "true",
    populate: "false",
    boards: "false",
    milestones: "false",
    files: "false",
    reminders: "false",
    annotations: "false",
  }).then((response) => {
    setDealflow(response.data.data.dealflow);
  });

  const getQuestionnairesAnswerEvolution = () => FormsService.getQuestionnaireAnswers(questionnaire._id, {
    page: 0,
    rowsPerPage: 999999,
    dateFrom: moment().subtract(30, "days").toDate().toISOString(),
  })
    .then((result) => {
      const { applications } = result.data;
      if (applications.length) {
        const grouped = applications.reduce((r, a) => {
          const date = moment(a.createdAt).format(dateFormat, Lang.getCurrentLang().code);
          r[date] = r[date] || [];
          r[date].push(a);
          return r;
        }, Object.create(null));

        const answers = Array(31)
          .fill(null)
          .map((v, i) => {
            const date = moment()
              .subtract(30 - i, "days")
              .format(dateFormat, Lang.getCurrentLang().code);

            return {
              x: date,
              y: (grouped[date] || []).length,
            };
          });

        setAnswersEvolutions([
          {
            id: "answers",
            data: answers,
          },
        ]);
      }
    })
    .catch(() => {
      addToast(t("error_occurred_retrieving_latest_startups"), {
        appearance: "error",
        autoDismiss: true,
      });
    });

  const getQuestionnaireStats = () => {
    setLoading(true);
    StatisticsService.getQuestionnaireStats(questionnaire._id)
      .then((result) => {
        setFormStatistics(result.data.data);
      })
      .catch(() => {
        addToast(t("error_getting_form_stats"), {
          appearance: "error",
          autoDismiss: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getForm = async() => {
    setLoading(true);
    return FormsService.getQuestionnairesForm(formId)
      .then((response) => {
        setQuestionnaire(response.data.data);
      })
      .catch(() => {
        addToast(t("error_retrieving_form"), {
          appearance: "error",
          autoDismiss: true,
        });
        navigate(RoutesLinks.QE_FORMS_LINK);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getTemplate = () => {
    setLoading(true);
    FormsService.getQuestionnairesTemplate(templateId)
      .then((response) => {
        setQuestionnaire(response.data.data);
      })
      .catch(() => {
        addToast(t("error_retrieving_template"), {
          appearance: "error",
          autoDismiss: true,
        });
        navigate(RoutesLinks.QE_TEMPLATES_LINK);
      })
      .finally(() => {
        setLoading(false);
        setIsTemplate(true);
      });
  };

  const parseQuestions = (selectedQuestions) => {
    const parsedQuestions = [];
    selectedQuestions.forEach((q, index) => {
      const parsedQuestion = {};
      parsedQuestion.question_id = q._id;
      parsedQuestion.required = !!requiredQuestions[q._id];
      if (!index) {
        parsedQuestion.first_question = true;
      }
      if (selectedQuestions.length > index + 1) {
        parsedQuestion.next_question = { question_id: selectedQuestions[index + 1]._id };
      }
      parsedQuestions.push(parsedQuestion);
    });

    return parsedQuestions;
  };

  const checkCurrentQuestionErrors = () => {
    Object.keys(redFlagsEnabled).forEach((questionId) => {
      // if (redFlagsEnabled[questionId]) {
      // }
    });
  };

  const parseRedFlags = () => {
    const parsedRedFlags = {};
    Object.keys(redFlagsEnabled).forEach((redFlag) => {
      if (redFlagsEnabled[redFlag]) {
        parsedRedFlags[redFlag] = redFlags[redFlag];
      }
    });
    return parsedRedFlags;
  };

  const saveForm = async(publish) => {
    if (!name) {
      alert({ text: t("form_name_required") });
      return;
    }
    if (!selectedQuestions.length) {
      alert({ text: t("questions_required") });
      return;
    } else if (
      assignStartupsToPipelineManager &&
      (!pipelineManagerBoard || !pipelineManagerStage)
    ) {
      alert({ text: t("assign_startups_automation_incomplete") });
      return;
    } else if (sendNotificationEmail && !formNotificationEmail) {
      alert({ text: t("application_notification_email_required") });
      return;
    } else if (customEndedMessage && !formEndedDescription) {
      alert({ text: t("custom_application_ended_message_required") });
      return;
    } else if (!languages.length) {
      alert({ text: t("form_should_contains_at_least_one_language") });
      return;
    } /* else if (checkErrorsInRedFlags()) {
      alert({ text: t('error_configuring_red_flags') })
    } */

    let data = {
      key_questions: { ...keyQuestions },
      questions: parseQuestions(selectedQuestions),
      assign_startups_to_pipeline_manager: assignStartupsToPipelineManager,
      pipeline_manager_board: pipelineManagerBoard,
      pipeline_manager_stage: pipelineManagerStage,
      name,
      title: formTitle,
      description: formDescription,
      banner: formBanner,
      favicon: formFavicon,
      notification_email: formNotificationEmail,
      send_notification_email: !!sendNotificationEmail,
      custom_ended_message: !!customEndedMessage,
      form_ended_description: formEndedDescription,
      pre_score_threshold: preScoreThreshold,
      background_color: formBackgroundColor,
      font_color: formFontColor,
      button_font_color: formButtonFontColor,
      button_color: formButtonColor,
      visibility_mode: visibilityMode,
      show_investors_percentage: showInvestorsPercentage,
      set_as_main: formAsMain,
      languages,
      marie_entrepreneur: isMarieEntrepreneur,
      marie_recipient: marieRecipient,
      marie_type: marieType,
      marie_client: marieClient,
      red_flags: parseRedFlags(),
    };

    if (!publish) {
      data = {
        draft: {
          ...questionnaire.draft,
          ...data,
        },
      };
    } else {
      data = {
        published: true,
        publish_date: new Date(),
        draft: null,
        ...data,
      };
    }

    try {
      setLoading(true);
      await FormsService.updateQuestionnairesForm(questionnaire._id, data);
      reloadUserInfo();
      addToast(t("form_updated_successfully"), {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      addToast(t("error_occurred_updating_form"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
      setShowSaveConfirmationModal(false);
      publish && !questionnaire?.publish_date && setSection("overview");
      getForm();
    }
  };

  useEffect(() => {
    if (templateId && templateId !== "wizard") {
      getTemplate();
    }
  }, [templateId]);

  useEffect(() => {
    if (formId) {
      getForm();
    }
  }, [formId]);

  useEffect(() => {
    setSection(location.state?.section ? location.state?.section : "overview");
  }, []);

  const getPipelineManagers = async() => {
    try {
      setLoading(true);
      const result = await PipelineManagersService.getPipelineManagers();
      setBoards(result.data.data);
    } catch (error) {
      console.error(error);
      addToast(
        error.response && error.response.data.msg_key
          ? error.response.data.msg_key
          : t("error_occurred_retrieving_boards"),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const processQuestions = (questions, searchTerm) => {
    const groupedQuestions = {};
    const addToTag = (question, tag) => {
      if (!groupedQuestions[tag]) {
        groupedQuestions[tag] = [question];
      } else {
        groupedQuestions[tag].push(question);
      }
    };
    const matchSearchTerm = (question) => (
        `${question.title?.es}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
        `${question.title?.en}`.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const _questions = searchTerm ? questions.filter((q) => matchSearchTerm(q)) : questions;

    _questions.forEach((question) => {
      if (question.tag) {
        addToTag(question, question.tag);
      } else {
        addToTag(question, "other_questions");
      }
    });

    return groupedQuestions;
  };

  const getQuestionnaireQuestions = () => {
    setLoading(true);

    FormsService.getQuestionnairesQuestions()
      .then((response) => {
        setQuestions(response.data.data);
        setFilteredQuestions(processQuestions(response.data.data));
      })
      .catch(() => {
        navigate(RoutesLinks.QE_FORMS_LINK);
        addToast(t("error_getting_questions"), {
          appearance: "error",
          autoDismiss: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (questionnaire) {
      getQuestionnaireQuestions();
      getQuestionnaireStats();
      getQuestionnairesAnswerEvolution();
      getPipelineManagers();
      getDealflow();
    }
  }, [user, questionnaire]);

  useEffect(() => {
    if (questionnaire) {
      const _model = questionnaire.draft
        ? { ...questionnaire, ...questionnaire.draft }
        : questionnaire;

      setName(_model.name);
      setFormBanner(_model.banner);
      setFormDescription(_model.description);
      setAssignStartupsToPipelineManager(_model.assign_startups_to_pipeline_manager);
      setPipelineManagerBoard(_model.pipeline_manager_board);
      setPipelineManagerStage(_model.pipeline_manager_stage);
      setFormFavicon(_model.favicon);
      setFormTitle(_model.title);
      setMarieLiteRecipient(_model.marie_lite_recipient ? questionnaire.marie_lite_recipient : "");
      setMarieProRecipient(_model.marie_pro_recipient ? questionnaire.marie_pro_recipient : "");
      setMarieClient(_model.marie_client ? questionnaire.marie_client : "");
      setIsMarieLite(_model.marie_lite ? questionnaire.marie_lite : false);
      setIsMarieEntrepreneur(_model.marie_entrepreneur ? questionnaire.marie_entrepreneur : false);
      setIsMariePro(_model.marie_pro ? questionnaire.marie_pro : false);
      setMarieRecipient(_model.marie_recipiend ? questionnaire.marie_recipient : "");
      setMarieType(_model.marie_type ? questionnaire.marie_type : "");

      setFormNotificationEmail(_model.notification_email ? questionnaire.notification_email : "");
      setFormEndedDescription(_model.form_ended_description || "");
      setLanguages(_model.languages && _model.languages.length ? _model.languages : ["en", "es"]);
      setSendNotificationEmail(_model.send_notification_email);
      setFormAsMain(_model.set_as_main);
      setCustomEndedMessage(_model.custom_ended_message);
      setFormBackgroundColor(_model.background_color || "#FFFFFF");
      setFormFontColor(_model.font_color || "#000000");
      setFormButtonFontColor(_model.button_font_color || "#FFFFFF");
      setFormButtonColor(_model.button_color || "#4D70B3");
      setVisibilityMode(_model.visibility_mode || "multiple_page");
      setShowInvestorsPercentage(_model.show_investors_percentage);
      setPreScoreThreshold(_model.pre_score_threshold);
      setRedFlags(
        (questionnaire.draft ? questionnaire.draft.red_flags : questionnaire.red_flags) || {},
      );
      const initialEnabledRedFlags = {};

      if (questionnaire.draft) {
        if (questionnaire.draft.red_flags) {
          Object.keys(questionnaire.draft.red_flags).forEach((key) => {
            initialEnabledRedFlags[key] = true;
          });
        }
      } else {
        if (questionnaire.red_flags) {
          Object.keys(questionnaire.red_flags).forEach((key) => {
            initialEnabledRedFlags[key] = true;
          });
        }
      }
      setRedFlagsEnabled(initialEnabledRedFlags);
      if (isEmpty(keyQuestions)) {
        setKeyQuestions(
          (questionnaire.draft ? questionnaire.draft.key_questions : questionnaire.key_questions) ||
            {},
        );
      }
    }
  }, [questionnaire]);

  useEffect(() => {
    setLoadingProcessQuestionnaire(true);
    if (questionnaire) {
      const tmpSelectedQuestions = [];
      const tmpRequiredQuestions = {};
      const _questions = questionnaire.draft
        ? questionnaire.draft.questions
        : questionnaire.questions;

      _questions?.forEach((q) => {
        tmpSelectedQuestions.push({
          _id: q.question_id._id,
          title: q.question_id.title,
          type: q.question_id.type,
          file_accepted_extensions: q.question_id.file_accepted_extensions,
          questionsGroup: q.question_id.questionsGroup,
        });
        tmpRequiredQuestions[q.question_id._id] = q.required;
      });

      setSelectedQuestions(tmpSelectedQuestions);
      setRequiredQuestions(tmpRequiredQuestions);
    }
    setLoadingProcessQuestionnaire(false);
  }, [questionnaire]);

  const exportResponses = () => confirm({
    title: t("export_responses"),
    text: t("export_responses_explanation"),
  }).then((isConfirmed) => {
    if (isConfirmed) {
      setLoading(true);
      return FormsService.exportResponses(formId)
        .then((result) => {
          if (result.data.data && result.data.data !== "") {
            window.open(result.data.data, "_blank");
          }
        })
        .catch((error) => {
          if (error.response) {
            addToast(t("error_occurred_exporting_responses"), {
              appearance: "error",
              autoDismiss: true,
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  });

  const confirmSaveTemplate = () => confirm().then((isConfirmed) => {
    if (isConfirmed) {
      saveForm(true);
    }
  });

  return (
    <>
      <div className="flex items-start">
        <div className="flex-1">
          <PageTitle showBack={true} title={t(formId ? "form_details" : "new_form")} />
        </div>
        {!user?.help?.create_questionnaire && <MiniHelp item={"create_questionnaire"} />}
        {section === "results" && formStatistics && formStatistics.responses !== 0 && (
          <ButtonSecondary
            marginRight={2}
            iconComponent={<FaFileExport className="inline-block mr-2" />}
            text={t("export_responses")}
            onClick={exportResponses}
          />
        )}
        {questionnaire && (questionnaire.published || questionnaire.draft) && (
          <ButtonSecondary
            iconComponent={<FaEye className="inline-block mr-2" />}
            marginRight="2"
            text={t("preview")}
            onClick={() => setShowPreviewModal(true)}
          />
        )}

        <ButtonMain
          iconComponent={<FaPaperPlane className="inline-block mr-2" />}
          text={t("save")}
          onClick={() => setShowSaveConfirmationModal(true)}
        />
      </div>

      <FormSaveConfirmationModal
        show={showSaveConfirmationModal}
        onClose={() => setShowSaveConfirmationModal(false)}
        onSave={saveForm}
      />

      <SectionHeader
        section={section}
        setSection={(s) => setSection(s)}
        sectionKeys={SECTIONS}
        sectionTitles={SECTIONS.map((s) => t(s))}
      />

      {questionnaire &&
        !!questionnaire.draft &&
        !["content", "results", "settings"].includes(section) && (
          <Alert style="warning" text={t("form_draft_warning_2")} />
      )}

      {section === "overview" && (
        <>
          <FormStatistics
            statistics={formStatistics}
            answersEvolutions={answersEvolutions}
            showAnswerEvolution={true}
          />

          {questionnaire?.published && <ShareAndEmbedForm questionnaire={questionnaire} />}
          {/* <FormPreviewQuestionnairesForm
          questionnaire={questionnaire}
          draft={!!questionnaire?.draft}
        />
          </> */}
        </>
      )}

      {section === "content" && (
        <FormContentSection
          processQuestions={processQuestions}
          filteredQuestions={filteredQuestions}
          setFilteredQuestions={setFilteredQuestions}
          questions={questions}
          questionnaire={questionnaire}
          selectedQuestions={selectedQuestions}
          setSelectedQuestions={setSelectedQuestions}
          keyQuestions={keyQuestions}
          setKeyQuestions={setKeyQuestions}
          requiredQuestions={requiredQuestions}
          setRequiredQuestions={setRequiredQuestions}
          targetRole={questionnaire.target_role}
        />
      )}

      {section === "red_flags" && (
        <FormRedFlags
          lang={userLanguage}
          questionnaire={questionnaire}
          redFlagsEnabled={redFlagsEnabled}
          setRedFlagsEnabled={setRedFlagsEnabled}
          redFlags={redFlags}
          setRedFlags={setRedFlags}
        />
      )}

      {section === "results" && (
        <FormResultsSection
          dealflow={dealflow}
          questionnaire={questionnaire}
          answersEvolutions={answersEvolutions}
          formStatistics={formStatistics}
        />
      )}

      {section === "share_and_integrate" && (
        <ShareAndEmbedForm questionnaire={questionnaire} showTitle={false} />
      )}

      {section === "settings" && (
        <FormSettingsSection
          marieClient={marieClient}
          setMarieClient={setMarieClient}
          templateId={templateId}
          getForm={getForm}
          questionnaire={questionnaire}
          boards={boards}
          assignStartupsToPipelineManager={assignStartupsToPipelineManager}
          setAssignStartupsToPipelineManager={setAssignStartupsToPipelineManager}
          pipelineManagerBoard={pipelineManagerBoard}
          setPipelineManagerBoard={setPipelineManagerBoard}
          pipelineManagerStage={pipelineManagerStage}
          setPipelineManagerStage={setPipelineManagerStage}
          name={name}
          setName={setName}
          formBanner={formBanner}
          setFormBanner={setFormBanner}
          formDescription={formDescription}
          setFormDescription={setFormDescription}
          formFavicon={formFavicon}
          setFormFavicon={setFormFavicon}
          formTitle={formTitle}
          setFormTitle={setFormTitle}
          formNotificationEmail={formNotificationEmail}
          isMarieLite={isMarieLite}
          isMariePro={isMariePro}
          isMarieEntrepreneur={isMarieEntrepreneur}
          marieType={marieType}
          marieRecipient={marieRecipient}
          setMarieRecipient={setMarieRecipient}
          setMarieType={setMarieType}
          setIsMarieLite={setIsMarieLite}
          setIsMariePro={setIsMariePro}
          setIsMarieEntrepreneur={setIsMarieEntrepreneur}
          marieLiteRecipient={marieLiteRecipient}
          marieProRecipient={marieProRecipient}
          setMarieLiteRecipient={setMarieLiteRecipient}
          setMarieProRecipient={setMarieProRecipient}
          setFormNotificationEmail={setFormNotificationEmail}
          formEndedDescription={formEndedDescription}
          setFormEndedDescription={setFormEndedDescription}
          languages={languages}
          setLanguages={setLanguages}
          sendNotificationEmail={sendNotificationEmail}
          setSendNotificationEmail={setSendNotificationEmail}
          customEndedMessage={customEndedMessage}
          setCustomEndedMessage={setCustomEndedMessage}
          formBackgroundColor={formBackgroundColor}
          setFormBackgroundColor={setFormBackgroundColor}
          formFontColor={formFontColor}
          setFormFontColor={setFormFontColor}
          formButtonFontColor={formButtonFontColor}
          setFormButtonFontColor={setFormButtonFontColor}
          formButtonColor={formButtonColor}
          setFormButtonColor={setFormButtonColor}
          visibilityMode={visibilityMode}
          setVisibilityMode={setVisibilityMode}
          showInvestorsPercentage={showInvestorsPercentage}
          setShowInvestorsPercentage={setShowInvestorsPercentage}
          activeTemplate={activeTemplate}
          setActiveTemplate={setActiveTemplate}
          category={category}
          setCategory={setCategory}
          estimatedDuration={estimatedDuration}
          setEstimatedDuration={setEstimatedDuration}
          templateLogo={templateLogo}
          setTemplateLogo={setTemplateLogo}
          investorName={investorName}
          setInvestorName={setInvestorName}
          preScoreThreshold={preScoreThreshold}
          setPreScoreThreshold={setPreScoreThreshold}
          setFormAsMain={setFormAsMain}
          formAsMain={formAsMain}
        />
      )}

      {showPreviewModal && (
        <Modal
          fixedWidth={"w-full p-6"}
          showModal={showPreviewModal}
          onClose={() => setShowPreviewModal(false)}
          showCloseModal={true}
          closeOnClickOutside={true}
        >
          <div className="w-70width">
            <FormPreview draft={!!questionnaire?.draft} questionnaire={questionnaire} />
          </div>
        </Modal>
      )}

      {(loading || loadingProcessQuestionnaire) && <Loader />}
    </>
  );
};

export default QuestionnairesForm;
