import { useNavigate } from "react-router-dom";
import { RoutesLinks } from "components/routes-links";
import { useEffect } from "react";
import Support from "./Support";
import Resources from "./Resources";
import ChatWithUs from "./ChatWithUs";
import Feedback from "./Feedback";
import { useSelector } from "react-redux";

export const Help = ({ section }) => {
  const navigate = useNavigate();

  const handleRedirect = (url) => navigate(url);
  const platform = useSelector((state) => state.app.platform);

  const SECTIONS =
    !platform.cobranding && !platform.white_label
      ? ["help_menu_resources", "chat_with_us", "give_us_feedback", "support"]
      : ["help_menu_resources", "give_us_feedback", "support"];

  const URLS = {
    help_menu_resources: RoutesLinks.RESOURCES_LINK,
    chat_with_us: RoutesLinks.CHAT_LINK,
    give_us_feedback: RoutesLinks.FEEDBACK_LINK,
    support: RoutesLinks.SUPPORT_LINK,
  };

  const COMPONENTS = {
    help_menu_resources: <Resources urls={URLS} sections={SECTIONS} section={section} />,
    chat_with_us: <ChatWithUs urls={URLS} sections={SECTIONS} section={section} />,
    give_us_feedback: <Feedback urls={URLS} sections={SECTIONS} section={section} />,
    support: <Support urls={URLS} sections={SECTIONS} section={section} />,
  };

  useEffect(() => {
    if (!SECTIONS.includes(section)) { handleRedirect(RoutesLinks.DASHBOARD_LINK); }
  }, [section]);

  return <>{COMPONENTS[section] || null}</>;
};
