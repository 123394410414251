import Person from "assets/images/person.png";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ButtonCardMain } from "components/ui/atoms/ButtonCardMain";
import { Card } from "components/ui/atoms/Card";
import { ButtonCardSecondary } from "../atoms/ButtonCardSecondary";
import { FaPaperPlane } from "react-icons/fa";

export function MemberCard(props) {
  const { t } = useTranslation();

  return (
    <Card>
      <div className="flex flex-col w-full pr-4">
        <div className="text-xs flex">
          <div>
            <img
              src={props.member.user?.image || Person}
              alt={`${props.member.user?.name} ${props.member.user?.last_name}`}
              className={
                "w-16 h-16 mx-1 object-contain | rounded-full | flex justify-center items-center"
              }
            />
          </div>

          <div className={"flex flex-col ml-4"}>
            <div className="text-xs  mt-2 font-semibold text-left px-2">
              {`${props.member.user?.name} ${props.member.user?.last_name}`}
            </div>
            <div className="text-xs  mt-2 text-left px-2">{props.member.user?.email}</div>
            <div>
              <div className="grid grid-cols-2 gap-4 px-2">
                <div>
                  <div className="w-full text-xs font-semibold mt-4">{t("invited")}</div>
                  <div className="w-full text-xs">
                    {moment(props.member.createdAt).format("YYYY-MM-DD HH:MM")}
                  </div>
                </div>
                <div>
                  <div className="w-full  text-xs font-semibold mt-4">{t("registered")}</div>
                  <div className="w-fulltext-xs">
                    {props.member.activation_date
                      ? moment(props.member.activation_date).format("YYYY-MM-DD HH:MM")
                      : "-"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"flex justify-end mt-2 pt-2 border-t border-separator"}>
        {!props.member.activation_date && (
          <div className="mr-2">
            <ButtonCardSecondary
              text={t("send_activation_code")}
              onClick={() => props.sendActivationCode(props.member._id)}
              iconComponent={<FaPaperPlane className="inline-block w-4 h-4 mr-2" />}
            />
          </div>
        )}
        <ButtonCardMain
          type="button"
          text={t("see_details")}
          onClick={() => props.seeDetails(props.member)}
        />
      </div>
    </Card>
  );
}
