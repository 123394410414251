import moment from "moment";
import { FaCalendar, FaEye, FaTrash, FaUser, FaExchangeAlt } from "react-icons/fa";
import { TbWriting } from "react-icons/tb";
import { MdKeyboardVoice, MdTextFields } from "react-icons/md";
import { useTranslation } from "react-i18next";
import Config from "config";
import { ButtonDanger } from "../atoms/ButtonDanger";
import { ButtonCardMain } from "../atoms/ButtonCardMain";
import { Card } from "../atoms/Card";
import { ButtonCardSecondary } from "../atoms/ButtonCardSecondary";
import useUser from "hooks/useUser";

export function BasicCardNotes({ deleteNote, note, getNote, setShowMigrationSection = null }) {
  const { user } = useUser();
  const { t } = useTranslation();
  return (
    <Card wrapperClassName="h-full">
      <div className="flex flex-col justify-between h-full p-2 items-between">
        <div
          className={`text-left leading-none flex flex-col ${note.type === "text" && "justify-center"} items-start h-full px-1 pb-2`}
        >
          <div className="h-auto mb-1 text-lg break-all">
            {note.type === "audio" && (
              <MdKeyboardVoice className={"inline mb-1 text-lg text-main mr-1"} />
            )}
            {note.type === "text" && (
              <MdTextFields className={"inline mb-1 text-lg text-main mr-1"} />
            )}
            {note.type === "draw" && <TbWriting className={"inline mb-1 text-lg text-main mr-1"} />}
            {note.title}
          </div>
          {note.type === "text" && note.content.length > 2 && (
            <div className="w-full">
              <div
                className="w-full mb-2 ml-1 text-sm line-clamp-2"
                dangerouslySetInnerHTML={{ __html: note.content }}
              />
            </div>
          )}
        </div>
        <div className="flex items-center justify-between w-full px-1 pt-1 border-t border-gray-lines">
          <span className="text-xxxs text-gray">
            <FaCalendar className="inline-block mr-1" />
            {moment(note.createdAt).format("YYYY-MM-DD")}
          </span>
        </div>
        <div className="flex w-full px-1 pt-1 border-t border-gray-lines">
          <span className="text-xxxs text-gray">
            <FaUser className="inline-block mr-1" />
            {note.user?.name || note?.shared_deal_person_name || t("no_data")}
          </span>
        </div>
        <div className={"flex justify-end border-t border-separator pt-2 mt-4"}>
          {note.deal && setShowMigrationSection && !user.lp && (
            <ButtonCardSecondary
              type="button"
              onClick={() => setShowMigrationSection(note._id)}
              iconComponent={<FaExchangeAlt className="inline-block w-5 h-4 mr-1" />}
              marginRight={2}
              text={t("migrate")}
            />
          )}
          {deleteNote && !user.lp && (
            <ButtonDanger
              type="button"
              onClick={() => deleteNote(note._id)}
              iconComponent={<FaTrash className="inline-block w-5 h-4 mr-1" />}
              marginRight={2}
              text={t("delete")}
            />
          )}
          <ButtonCardMain
            type="button"
            text={t("see")}
            onClick={() => getNote(note._id)}
            iconComponent={<FaEye className="inline-block w-5 h-4 mr-1" />}
          />
        </div>
      </div>
    </Card>
  );
}
