import { TextArea } from "components/ui/atoms/TextArea";
import { useTranslation } from "react-i18next";

export const QuestionTypeTextArea = ({ currentQuestion, onChange, value }) => {
  const { t } = useTranslation();

  return (
    <>
      <TextArea
        maxLength={currentQuestion.question_id.max_length}
        key={currentQuestion.question_id._id}
        value={value || ""}
        onChange={(e) => onChange(e.target.value)}
        type="text"
        placeholder={t("type_answer")}
        row={8}
        cols={8}
        style={{ resize: "none" }}
      />
      {[null, undefined].includes(currentQuestion.question_id.max_length) && (
        <div className="flex justify-end text-xs">{`${value?.length || 0} ${t("characters")}`}</div>
      )}
      {![null, undefined].includes(currentQuestion.question_id.max_length) && (
        <div className="flex justify-end text-xs">
          {`${value?.length || 0} / ${currentQuestion.question_id.max_length} ${t("characters")}`}
        </div>
      )}
    </>
  );
};
