import { useSwal } from "hooks/useSwal";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { FaEyeSlash } from "react-icons/fa";
import useUser from "hooks/useUser";
import { useToasts } from "react-toast-notifications";
import { Modal } from "../molecules/Modal";
import { useState } from "react";
import { ButtonCardSecondary } from "./ButtonCardSecondary";
import ResourcesItems from "assets/json/resources.json";
import { FiVideo } from "react-icons/fi";
import AccountService from "services/account-service";

export function MiniHelp({ item }) {
  const { t } = useTranslation();
  const { confirm } = useSwal();
  const { user, userLanguage, reloadUserInfo } = useUser();
  const { addToast } = useToasts();

  const [showVideo, setShowVideo] = useState(false);

  const hideMiniHelpItem = async() => {
    confirm({ text: t("mini_help_warning_description") }).then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          const help = { ...user.help } || {};
          help[item] = moment();
          await AccountService.updateHelp(help);
          reloadUserInfo();
        } catch (error) {
          console.error(error);
          addToast(t("error_occurred_hidding_help"));
        }
      }
    });
  };

  const handleShowresource = () => {
    setShowVideo(true);
  };

  return (
    <>
      {showVideo && (
        <Modal
          showModal={!!showVideo}
          showCloseModal={true}
          onClose={() => setShowVideo(null)}
          fixedWidth={"w-1/2"}
        >
          <div className="py-10">
            <video width="auto" height="auto" controls autoPlay>
              <source
                src={
                  ResourcesItems.find(
                    (resource) =>
                      resource.type === "video" &&
                      resource.id === item &&
                      resource.lang === userLanguage,
                  ).url
                }
                type="video/mp4"
              />
              <source
                src={
                  ResourcesItems.find(
                    (resource) =>
                      resource.type === "video" &&
                      resource.id === item &&
                      resource.lang === userLanguage,
                  ).url
                }
                type="video/ogg"
              />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className={"flex justify-end mt-4 border-t border-separator pt-2"}>
            <ButtonCardSecondary
              onClick={hideMiniHelpItem}
              text={t("hide_forever")}
              iconComponent={<FaEyeSlash className="inline-block w-6 h-4" />}
            />
          </div>
        </Modal>
      )}
      <div className="p-1 mr-4 text-sm relative">
        <span className="text-sm hover:font-bold cursor-pointer" onClick={handleShowresource}>
          <FiVideo className="inline mr-1 w-5 h-5" />
          {t("mini_help")}
        </span>
      </div>
    </>
  );
}
