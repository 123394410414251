import useUser from "hooks/useUser";
import AccountService from "services/account-service";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { ButtonMain } from "../atoms/ButtonMain";
import { Modal } from "components/ui/molecules/Modal";
import { Loader } from "./Loader";
import { ButtonSecondary } from "../atoms/ButtonSecondary";
import { MdSlowMotionVideo } from "react-icons/md";
import { AiFillThunderbolt } from "react-icons/ai";
import { RoutesLinks } from "components/routes-links";
import { useNavigate } from "react-router-dom";

export function SetupProcessModal() {
  const { reloadUserInfo } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleSetup = async(setupType) => {
    try {
      setLoading(true);
      await AccountService.handleSetup(setupType);
      await reloadUserInfo();
      navigate(RoutesLinks.DEALFLOW_LINK);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Modal showModal={true} showCloseModal={false} fixedWidth="w-1/2">
        <div className="text-lg font-bold mb-4">{t("setup_process")}</div>
        <div>
          <div className="p-2">
            <div className="flex flex-row">
              <div>
                <div className="w-full flex justify-center mr-2">
                  <AiFillThunderbolt className="w-6 h-6" />
                  {/* <img src={ProductUpdates[0].img[userLanguage]} className='w-1/3'/> */}
                </div>
              </div>
              <div>
                <div className="font-semibold text-xs">{t("quick_setup")}</div>
                <div className="text-xxs">{t("quick_setup_description")}</div>
              </div>
            </div>
            <div className="flex justify-end mt-2">
              <ButtonMain text={t("select_this")} onClick={() => handleSetup("quick_setup")} />
            </div>
          </div>
          <div className="h-auto p-2 border-t border-gray-lines">
            <div className="flex flex-row">
              <div>
                <div className="w-full flex justify-center mr-2">
                  <MdSlowMotionVideo className="w-6 h-6" />
                </div>
              </div>
              <div>
                <div className="font-semibold text-xs">{t("manual_setup")}</div>
                <div className="text-xxs">{t("manual_setup_description")}</div>
              </div>
            </div>
            <div className="flex justify-end mt-2">
              <ButtonSecondary
                text={t("select_this")}
                onClick={() => handleSetup("manual_setup")}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
