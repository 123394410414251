import axios from "axios/index";
import Config from "../config";

const PipelineManagersService = {
  createPipelineManager: async(name, columns = []) =>
    axios.post(`${Config.PIPELINE_MANAGERS_API_URL}private`, { name, columns }),

  getPipelineManagers: async() => {
    const params = new URLSearchParams();

    return axios.get(`${Config.PIPELINE_MANAGERS_API_URL}private?${params.toString()}`);
  },

  getPipelineManager: async(boardId) =>
    axios.get(`${Config.PIPELINE_MANAGERS_API_URL}private/${boardId}`),

  deletePipelineManager: async(boardId) =>
    axios.delete(`${Config.PIPELINE_MANAGERS_API_URL}private/${boardId}`),

  updatePipelineManager: async(boardId, data) =>
    axios.patch(`${Config.PIPELINE_MANAGERS_API_URL}private/${boardId}`, data),

  addStage: async(boardId, data) =>
    axios.post(`${Config.PIPELINE_MANAGERS_API_URL}private/${boardId}/stages/`, data),

  deleteStage: async(boardId, columnId) =>
    axios.delete(`${Config.PIPELINE_MANAGERS_API_URL}private/${boardId}/stages/${columnId}`),

  updateStage: async(boardId, columnId, data) =>
    axios.patch(`${Config.PIPELINE_MANAGERS_API_URL}private/${boardId}/stages/${columnId}`, data),

  deleteItem: async(boardId, cardId) =>
    axios.delete(`${Config.PIPELINE_MANAGERS_API_URL}private/${boardId}/items/${cardId}`),

  addItem: async(boardId, card) =>
    axios.post(`${Config.PIPELINE_MANAGERS_API_URL}private/${boardId}/items`, card),

  moveItem: async(boardId, cardId, data) =>
    axios.post(`${Config.PIPELINE_MANAGERS_API_URL}private/${boardId}/items/${cardId}/move`, data),

  sortItem: async(boardId, cardId, data) =>
    axios.post(`${Config.PIPELINE_MANAGERS_API_URL}private/${boardId}/items/${cardId}/sort`, data),

  updateItem: (boardId, cardId, data) =>
    axios.patch(`${Config.PIPELINE_MANAGERS_API_URL}private/${boardId}/items/${cardId}`, data),

  getSharedBoard: async(boardId) =>
    axios.get(`${Config.PIPELINE_MANAGERS_API_URL}shared/${boardId}`),

  getDealRecords: async(boardId, dealId) =>
    axios.get(`${Config.PIPELINE_MANAGERS_API_URL}private/${boardId}/records/deals/${dealId}`),

  getStageRecords: async(boardId, stageId) =>
    axios.get(`${Config.PIPELINE_MANAGERS_API_URL}private/${boardId}/records/stages/${stageId}`),

  exportStage: async(boardId, columnId, fields, language) =>
    axios.post(`${Config.PIPELINE_MANAGERS_API_URL}private/${boardId}/stages/${columnId}/export`, {
      fields,
      language,
    }),
};

export default PipelineManagersService;
