import React, { useState, useEffect } from "react";
import { Loader } from "components/ui/molecules/Loader";
import EmailsList from "./EmailsListOutlook";
import EmailDetail from "./EmailDetailOutlook";
import { useTranslation } from "react-i18next";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import { getMails } from "services/outlook-service";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";

const GoogleView = ({ deal, sincronize }) => {
  const msal = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [emails, setEmails] = useState([]);
  const [showEmailDetail, setShowEmailDetail] = useState(false);
  const [emailData, setEmailData] = useState({});
  const [emailPagesData, setEmailPagesData] = useState([]);
  const [emailFiltersData, setEmailFiltersData] = useState([]);
  const [nextUrlPage, setNextUrlPage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const { t } = useTranslation();

  const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(msal.instance, {
    account: msal.instance.getActiveAccount(),
    scopes: ["user.read", "mail.read", "mail.send"],
    interactionType: "none",
  });

  const getEmails = async (query = {}) => {
    try {
      setLoading(true);
      const data = await getMails(authProvider, emailFiltersData, query);
      if ("@odata.nextLink" in data) {
        setNextUrlPage(data["@odata.nextLink"]);
      } else {
        setNextUrlPage(null);
      }

      setEmailPagesData((prevArray) => [
        ...prevArray,
        {
          ...data.queryPage,
        },
      ]);

      setEmails(data.value);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const filtersEmails = () => {
    const emailsFounders =
      deal?.founders?.filter((founder) => founder.email).map((founder) => founder.email) || [];
    const otherEmails = deal?.other_emails?.map((email) => email) || [];
    const mainContact = deal?.main_contact_email ? [deal?.main_contact_email] : [];
    const dataEmails = [...emailsFounders, ...otherEmails, ...mainContact];
    if (dataEmails.length) {
      const dataSet = new Set(dataEmails);
      const newData = [...dataSet];
      setEmailFiltersData(newData);
    }
  };

  useEffect(() => {
    filtersEmails();
  }, [deal]);

  useEffect(() => {
    if (emailFiltersData.length) {
      getEmails();
    }
  }, [emailFiltersData]);

  useEffect(() => {
    if (sincronize) {
      setCurrentPage(0);
      setEmailPagesData([]);
      setNextUrlPage(null);
      if (emailFiltersData.length) {
        getEmails();
      }
    }
  }, [sincronize]);

  useEffect(() => {
    const query = emailPagesData[currentPage];
    if (query) {
      getEmails(query);
    }
  }, [currentPage]);

  const getEmailContent = async (email) => {
    setEmailData(email);
  };

  return (
    <div className="my-6">
      {emails.length ? (
        <div className="w-full">
          <EmailsList
            emails={emails || []}
            mainEmail={deal.main_contact_email}
            getEmailContent={getEmailContent}
            showEmailDetail={setShowEmailDetail}
          />
          {(currentPage !== 0 || emailPagesData?.length > 0) && (
            <div className="flex justify-end my-4">
              {currentPage !== 0 && (
                <ButtonSecondary
                  onClick={() => {
                    setCurrentPage(currentPage - 1);
                  }}
                  iconComponent={<GrFormPrevious />}
                  className="w-4 h-4"
                  marginRight={2}
                />
              )}
              {nextUrlPage && (
                <ButtonSecondary
                  onClick={() => {
                    getEmails({
                      $url: nextUrlPage,
                    });
                    setCurrentPage(currentPage + 1);
                  }}
                  iconComponent={<GrFormNext className="w-4 h-4" />}
                />
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="my-10">
          <NoDataInfo
            title={t(isAuthenticated ? "no_emails_found" : "email_synchronization_not_enabled")}
          />
        </div>
      )}

      {showEmailDetail && (
        <EmailDetail onClose={() => setShowEmailDetail(false)} email={emailData} />
      )}

      {loading && <Loader />}
    </div>
  );
};

export default GoogleView;
