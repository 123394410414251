import React from "react";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import Config from "config";

const configuration = {
  auth: {
    clientId: Config.OUTLOOK_CLIENT_ID,
    redirectUri: Config.OUTLOOK_REDIRECT_URL,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

const pca = new PublicClientApplication(configuration);
const accounts = pca.getAllAccounts();
if (accounts && accounts.length > 0) {
  pca.setActiveAccount(accounts[0]);
}
pca.addEventCallback((event) => {
  if (event.eventType === "msal:loginSuccess" && event.payload) {
    const authResult = event.payload;
    pca.setActiveAccount(authResult.account);
  }
});

const MsalProviderWrapper = ({ children }) => <MsalProvider instance={pca}>{children}</MsalProvider>;

export default MsalProviderWrapper;
