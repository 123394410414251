import { useTranslation } from "react-i18next";
import { SidePanel } from "components/ui/objects/SidePanel";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Config from "config";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import Select from "components/ui/atoms/Select";
import RejectionReasons from "assets/json/rejection-reasons.json";
import { TextArea } from "components/ui/atoms/TextArea";
import { CurrencyInput } from "components/ui/atoms/CurrencyInput";
import { Input } from "components/ui/atoms/Input";
import FormUtils from "utils/form-utils";
import moment from "moment";
import { Switch } from "components/ui/atoms/Switch";
import { useToasts } from "react-toast-notifications";
import { initial } from "lodash";

/**
 * @param onClose
 * @returns {JSX.Element}
 * @constructor
 */
const RegisterInvestmentSection = ({ onClose, registerInvestment, initialValues, investments }) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const [isOwnInvestment, setIsOwnInvestment] = useState(true);

  const { register, handleSubmit, errors, setValue, trigger, watch, getValues } = useForm({
    mode: "onChange",
    criteriaMode: "all",
    reValidateMode: "all",
    resolver: yupResolver(
      Yup.object().shape({
        investment: Yup.number().required(t("required_field")).min(1, t("investment_min_required")),
        shares: Yup.number()
          .required(t("required_field"))
          .min(0, t("shares_min_required"))
          .max(100, t("shares_max_required")),
        shareholder: Yup.string().nullable(),
        unit: Yup.string().required(t("required_field")),
        date: Yup.string()
          .required(t("required_field"))
          .transform((value) => FormUtils.parseDateFromString(value, "YYYY-MM-DD HH:mm")),
        comments: Yup.string().nullable(),
      }),
      { abortEarly: false },
    ),
  });

  useEffect(() => {
    register("unit");
  }, [register]);

  useEffect(() => {
    if (!initialValues) {
      setValue("unit", "k");
      setValue("investment", 1);
      setValue("shares", 0);
      setValue("date", moment().format("YYYY-MM-DD"));
    } else {
      setValue("unit", initialValues.unit || "k");
      setValue("comments", initialValues.comments || "");
      setValue("investment", initialValues.investment ? parseInt(initialValues.investment) : 0);
      setValue("shares", initialValues.shares ? parseInt(initialValues.shares) : 0);
      setValue(
        "date",
        initialValues.date
          ? moment(initialValues.date).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
      );
      setIsOwnInvestment(initialValues.is_own_investment);
      if (initialValues.shareholder) {
        setValue("shareholder", initialValues.shareholder);
      }
    }
    trigger();
  }, [initialValues]);

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, { shouldValidate: true, shouldTouch: true, ...config });
    trigger(name);
  };

  return (
    <>
      <SidePanel width={"1/4"} onClose={onClose} title={t("register_investment")}>
        <div className="mt-4 flex flex-col w-full">
          <div className="w-full mr-4">
            <CurrencyInput
              nameInput={"investment"}
              nameSelect={"unit"}
              reference={register}
              placeholder={t("investment")}
              label={t("investment")}
              error={errors.investment}
              watch={watch}
              setValue={_setValue}
            />
          </div>
          <div className="mt-2 w-full">
            <Input
              reference={register}
              name="shares"
              placeholder={t("shares")}
              label={`${t("shares")} (%)`}
              type="shares"
              error={errors.shares}
            />
          </div>
          <div className="mt-2 w-full">
            <Input
              reference={register}
              name="date"
              placeholder={t("date")}
              label={t("date")}
              type="date"
              error={errors.date}
            />
          </div>
          <div className="w-full px-1 text-xs mt-2 mb-2 font-semibold">
            {t("are_you_the_shareholder")}
          </div>
          <div className="mb-2">
            <Switch
              checked={isOwnInvestment}
              onChange={(checked) => setIsOwnInvestment(checked)}
              text={isOwnInvestment ? t("yes") : t("no")}
            />
          </div>
          {!isOwnInvestment && (
            <div className="mt-2 w-full">
              <Input
                reference={register}
                name="shareholder"
                placeholder={t("shareholder")}
                label={t("shareholder")}
                type="string"
                error={errors.shareholder}
              />
            </div>
          )}
          <div className="mt-2 w-full">
            <TextArea
              reference={register}
              name="comments"
              error={errors.comments}
              label={t("rejected_reasons")}
              maxLength={200}
              placeholder={t("comments")}
            />
          </div>
          <div className={`flex justify-end mt-2 pt-2 border-t border-separator`}>
            <ButtonMain
              onClick={async () => {
                const validation = await trigger();
                if (validation) {
                  if (!isOwnInvestment && !getValues().shareholder) {
                    addToast(t("shareholder_required"), { appearance: "error", autoDismiss: true });
                  } else {
                    const shares = parseInt(getValues().shares);

                    const restOfShares = investments
                      .filter((investment) =>
                        initialValues ? investment._id !== initialValues._id : true,
                      )
                      .reduce((acc, investment) => acc + (investment.shares || 0), 0);

                    if (restOfShares + (shares || 0) > 100) {
                      addToast(t("investments_shares_overload"), {
                        appearance: "error",
                        autoDismiss: true,
                      });
                    } else {
                      registerInvestment({ ...getValues(), is_own_investment: isOwnInvestment });
                    }
                  }
                } else {
                  addToast(t("form_field_error"), { appearance: "error", autoDismiss: true });
                }
              }}
              text={t("register_investment")}
            />
          </div>
        </div>
      </SidePanel>
    </>
  );
};

export default RegisterInvestmentSection;
