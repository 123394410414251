import Language from "./language";
import DealflowViewMode from "./DealflowViewMode";
import StartupProfile from "./StartupProfile";
import useUser from "hooks/useUser";

const Settings = () => {
  const { user, checkIfLPhasAccessToSection } = useUser();
  return (
    <>
      <Language />
      {(!user.lp || (user.lp && checkIfLPhasAccessToSection("dealflow"))) && (
        <>
          <DealflowViewMode />
          <StartupProfile />
        </>
      )}
    </>
  );
};

export default Settings;
