import FormAnswersSection from "components/qe-forms/components/forms-form/FormAnswersSection";
import { ButtonCardMain } from "components/ui/atoms/ButtonCardMain";
import { Card } from "components/ui/atoms/Card";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import useUser from "hooks/useUser";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEye } from "react-icons/fa";
import { useOutletContext } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import DocumentsService from "services/documents-service";
import EvaluationProcessesManagerService from "services/evaluation-processes-manager-service";
import FormsService from "services/forms-service";
import FormUtils from "utils/form-utils";

const ApplicationInformationView = () => {
  const [answerToExpand, setAnswerToExpand] = useState(null);
  const [selectedForm, setSelectedForm] = useState(null);
  const [info, setInfo] = useState(null);
  const [redFlags, setRedFlags] = useState(null);

  const { userLanguage } = useUser();

  const { deal, event, setShowDealInfo, setLoading, startupId } = useOutletContext();

  const { t } = useTranslation();

  const { addToast } = useToasts();

  const hasInformationToShow = () => {
    const formsInDeal = deal.forms ? deal.forms.map((form) => form.form) : [];
    const events = event.source_information.map((source) => source);
    const forms = events.filter((event) => formsInDeal.includes(event._id));
    return !!forms.length || event.show_general_information;
  };

  const getSourceInformation = () => {
    const formsInDeal = deal?.forms?.map((form) => form.form) || [];
    const events = event.source_information.map((source) => source);
    return events
      .filter((event) => formsInDeal.includes(event._id))
      .map((source, index) => (
        <Card key={source.name}>
          <h5 className={"uppercase font-bold text-sm leading-none text-main truncate mb-1"}>{source.name}</h5>

          <div className={"flex justify-end mt-4 pt-2 border-t border-separator"}>
            <ButtonCardMain
              onClick={() => setSelectedForm(source._id)}
              text={t("see_answers")}
              iconComponent={<FaEye className="inline-block w-6 h-3" />}
            />
          </div>
        </Card>
      ));
  };

  const getQuestionnaire = async (formId) => {
    setLoading(true);
    return FormsService.getQuestionnairesForm(formId)
      .then((response) => {
        setRedFlags(response.data.data.red_flags || {});
      })
      .catch(() => {
        addToast(t("error_retrieving_form"), {
          appearance: "error",
          autoDismiss: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getForm = async () => {
    try {
      setLoading(true);
      const result = await EvaluationProcessesManagerService.getDealFromEvaluationProcess(selectedForm, startupId);
      setInfo(result.data.data);
      getQuestionnaire(selectedForm);
    } catch (error) {
      console.error(error);
      addToast(t("error_occurred_retrieving_information"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const downloadDocument = async (documentId) => {
    try {
      setLoading(true);
      let result = null;
      result = await DocumentsService.getDocumentForForm(documentId);
      if (result?.data?.data?.url) {
        window.open(result.data.data.url, "_blank");
      }
    } catch (error) {
      addToast(t(error?.response?.data?.msg_key || "error_occurred_downloading_document"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedForm) {
      getForm();
    }
  }, [selectedForm]);

  useEffect(() => {
    if (info?.application) {
      const parsedQuestions = FormUtils.getParsedQuestions(
        info.application.questions.map((q) => ({ question_id: q.question }))
      );
      const answers = FormUtils.getParsedAnswers(
        parsedQuestions,
        [info],
        t,
        userLanguage,
        downloadDocument,
        false,
        redFlags
      );

      setAnswerToExpand({ answers: answers[0], questions: parsedQuestions });
    }
  }, [info, redFlags]);

  return (
    <>
      {hasInformationToShow() && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 my-10">
          {event.show_general_information && (
            <Card key={"general_information"}>
              <h5 className={"uppercase font-bold text-sm leading-none text-main truncate mb-1"}>
                {t("general_information")}
              </h5>

              <div className={"flex justify-end mt-4 pt-2 border-t border-separator"}>
                <ButtonCardMain
                  onClick={() => setShowDealInfo(true)}
                  text={t("see_details")}
                  iconComponent={<FaEye className="inline-block w-6 h-3" />}
                />
              </div>
            </Card>
          )}
          {deal && getSourceInformation()}
        </div>
      )}
      {!hasInformationToShow() && (
        <div className="my-10">
          <NoDataInfo
            title={t("shared_judge_no_information_to_show")}
            textOne={t("shared_judge_no_information_to_show_text_one")}
          />
        </div>
      )}
      {answerToExpand && (
        <FormAnswersSection
          rawAnswer={info}
          redFlags={redFlags}
          answer={answerToExpand}
          onClose={() => {
            setInfo(null);
            setAnswerToExpand(null);
            setSelectedForm(null);
          }}
        />
      )}
    </>
  );
};

export default ApplicationInformationView;
