import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";
import { MdDragIndicator } from "react-icons/md";
import ReactTooltip from "react-tooltip";

import { PopoverTrelloList } from "components/ui/atoms/PopoverTrelloList";

const DealflowTableColumn = ({
  key,
  ListTitle,
  children,
  deleteColumn,
  column,
  createColumn,
  newColumn = false,
  index,
  actions,
}) => {
  const { t } = useTranslation();

  const headerRef = useRef(null);
  const [columnWidth, setColumnWidth] = useState(250);

  useEffect(() => {
    if (headerRef.current) {
      setColumnWidth(headerRef.current.offsetWidth);
    }
  }, []);

  const updateWidth = useCallback((newWidth) => {
    setColumnWidth(newWidth);
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <div>
      {!newColumn ? (
        <div
          id={key}
          className={` ${!index && "rounded-l-2xl"} border border-gray-lines ${actions ? "w-40" : "min-w-40 "} bg-white flex flex-col w-fit-content`}
        >
          {actions ? (
            <>
              <div
                width={columnWidth}
                className={
                  "flex  justify-between items-center border-b border-gray-lines px-2 pb-2 min-h-max"
                }
              >
                <h6 className="p-4 pl-6 text-base font-semibold">{ListTitle}</h6>
                {!actions && (
                  <PopoverTrelloList
                    items={deleteColumn ? ["delete_column"] : []}
                    methods={{
                      delete_column: deleteColumn ? () => deleteColumn(column) : null,
                    }}
                  />
                )}
              </div>
              <div className="flex-1">
                <div className="flex flex-col h-full">{children}</div>
              </div>
            </>
          ) : (
            <>
              <ResizableHeader
                width={columnWidth}
                updateWidth={updateWidth}
                className={
                  "flex  justify-between items-center border-b border-gray-lines px-2 pb-2 min-h-max"
                }
              >
                <h6 className="p-4 pl-6 text-base font-semibold">{ListTitle}</h6>
                <PopoverTrelloList
                  items={deleteColumn ? ["delete_column"] : []}
                  methods={{
                    delete_column: deleteColumn ? () => deleteColumn(column) : null,
                  }}
                />
              </ResizableHeader>
              <div className="flex-1" style={{ width: `${columnWidth}px` }}>
                <div className="flex flex-col h-full">{children}</div>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="h-auto mr-4 w-60">
          <button
            onClick={createColumn}
            className={
              "border border-border-buttons-secondary text-xxs text-gray-dark flex items-center  outline-none w-60 py-4 pl-6 rounded-r-2xl shadow-hover hover:shadow-inner bg-white focus:outline-none"
            }
          >
            <FaPlus className={"inline-block text-main mr-2"} />
            <span className="block text-xs font-medium">{t("add_column")}</span>
          </button>
        </div>
      )}
    </div>
  );
};

const ResizableHeader = ({ children, width: initialWidth, updateWidth, ...props }) => {
  const [width, setWidth] = useState(initialWidth); // Initial column width
  const ref = useRef(null);

  const startResizing = useCallback((mouseDownEvent) => {
    const startWidth = ref.current.offsetWidth;
    const startX = mouseDownEvent.pageX;

    const onMouseMove = (mouseMoveEvent) => {
      const currentX = mouseMoveEvent.pageX;
      const newWidth = startWidth + (currentX - startX);
      updateWidth(newWidth);
      setWidth(newWidth);
    };

    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  }, []);

  return (
    <div
      {...props}
      ref={ref}
      style={{
        width: `${width}px`,
        position: "relative",
        background: "primary",
        maxHeight: "60px",
      }}
    >
      <div
        className="drag-handle"
        style={{
          position: "absolute",
          backgroundColor: "gray !important",
          top: 5,
          left: 5,
          cursor: "grab",
        }}
      >
        <MdDragIndicator />
      </div>

      {children}
      <div
        style={{
          width: "4px",
          height: "100%",
          background: "gray",
          position: "absolute",
          right: 0,
          cursor: "col-resize",
          top: 0,
          ":hover": {
            background: "red",
          },
        }}
        onMouseDown={startResizing}
      />
    </div>
  );
};
export default DealflowTableColumn;
