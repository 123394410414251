import { useTranslation } from "react-i18next";

import { useEffect, useState } from "react";
import { Input } from "components/ui/atoms/Input";
import { useToasts } from "react-toast-notifications";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Loader } from "components/ui/molecules/Loader";
import { FaTrash } from "react-icons/fa";
import Avatar from "react-avatar";
import InputFile from "components/ui/atoms/input-files";
import FormUtils from "utils/form-utils";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import Config from "config";
import { ButtonDanger } from "components/ui/atoms/ButtonDanger";
import Select from "components/ui/atoms/Select";
import Countries from "assets/json/countries.json";
import Utils from "utils/utils";
import useUser from "hooks/useUser";

const GeneralInvestorForm = ({
  investorType,
  onSubmit,
  isWizard = true,
  onDelete,
  initialValues = {},
}) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);

  const [logo, setLogo] = useState(null);
  const LANGUAGES = [
    { id: "es", value: t("es") },
    { id: "en", value: t("en") },
  ];

  const { user } = useUser();

  const countries = Utils.sortByKey(
    Countries.map((item) => ({
      ...item,
      id: item._id,
      value: `${t(item.key)}`,
    })),
    "value",
  );

  const InvestorSchema = Yup.object().shape({
    company: Yup.string().required(t("required_field")),
    company_web: Yup.string()
      .transform((value) => (!value ? null : value))
      .matches(FormUtils.URL_REGEX, t("incorrect_format"))
      .nullable(),
    company_linkedin: Yup.string()
      .transform((value) => (!value ? null : value))
      .matches(FormUtils.URL_REGEX, t("incorrect_format"))
      .nullable(),
    contact: Yup.string(),
    contact_email: Yup.string().email(t("incorrect_format")),
    contact_phone_number: Yup.string(),
    contact_linkedin: Yup.string()
      .transform((value) => (!value ? null : value))
      .matches(FormUtils.URL_REGEX, t("incorrect_format"))
      .nullable(),
    language: Yup.string(),
  });

  const { register, errors, trigger, getValues, reset, setValue, watch } = useForm({
    resolver: yupResolver(InvestorSchema),
    criteriaMode: "all",
    reValidateMode: "all",
    mode: "onChange",
  });

  useEffect(() => {
    register("language");
    register("country");
  }, [register]);

  useEffect(() => {
    if (isWizard) {
      reset();
    }
  }, [investorType, isWizard]);

  const onSelectLogo = async (file) => {
    const fileSizeMb = (file?.size || 0) / 1024 / 1024;

    if (fileSizeMb > 5) {
      addToast(t("error_file_size", { max_size: 5 }), {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }

    setLoading(true);
    setLogo(file ? await FormUtils.fileToBase64(file) : null);

    setTimeout(() => setLoading(false), 500);
  };

  useEffect(() => {
    if (initialValues && initialValues.company_logo) {
      setLogo(initialValues.company_logo);
    }
  }, [initialValues]);

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, config);
    trigger(name);
  };

  useEffect(() => {
    if (initialValues) {
      Object.keys(initialValues).forEach((key) => {
        _setValue(key, initialValues[key]);
      });
    }
  }, [initialValues]);

  return (
    <div>
      {logo ? (
        <>
          <div className="text-xs font-medium text-black">{t(`${investorType}_logo`)}</div>
          <div className="mt-2 flex flex-col justify-center w-32 mb-6">
            <Avatar
              src={logo}
              size="80"
              round={true}
              color="#e0e6f2"
              fgColor="#4d70b3"
              className="mx-auto my-0"
            />
            <ButtonDanger
              type="button"
              iconComponent={<FaTrash className="inline-block mr-2" />}
              text={t("remove")}
              onClick={() => onSelectLogo(null)}
              marginTop={2}
            />
          </div>
        </>
      ) : (
        <div className="w-full mb-4">
          <InputFile
            label={t(`${investorType}_logo_label`)}
            placeholder={t("company_logo_placeholder")}
            error={errors.logo}
            setSelectedFile={(file) => onSelectLogo(file)}
            accept="image/*"
            height="120px"
            padding="18px"
          />
        </div>
      )}
      <form className="w-full">
        <div>
          <Input
            reference={register}
            id="company"
            type="text"
            name="company"
            label={t("name")}
            placeholder={t(`${investorType}_name_placeholder`)}
            error={errors.company}
            required={true}
          />
          <Input
            name="company_web"
            reference={register}
            placeholder={t("general_investor_web_placeholder")}
            label={t("general_investor_web_label")}
            error={errors.company_web}
          />
          <Input
            name="company_linkedin"
            reference={register}
            placeholder={t("general_investor_linkedin_placeholder")}
            label={t("general_investor_linkedin_label")}
            error={errors.company_linkedin}
          />
        </div>
        <div>
          <Input
            reference={register}
            id="contact"
            type="text"
            name="contact"
            label={t("general_investor_main_contact_label")}
            placeholder={t("general_investor_main_contact_placeholder")}
            error={errors.contact}
          />
        </div>
        <div>
          <Input
            reference={register}
            id="contact_email"
            type="email"
            name="contact_email"
            label={t("general_investor_main_contact_email_label")}
            placeholder={t("general_investor_main_contact_email_placeholder")}
            error={errors.contact_email}
          />
        </div>
        <div>
          <Input
            name="contact_linkedin"
            reference={register}
            placeholder={t("general_investor_main_contact_linkedin_placeholder")}
            label={t("general_investor_main_contact_linkedin_label")}
            error={errors.contact_linkedin}
          />
          <Input
            reference={register}
            id="contact_phone_number"
            type="text"
            name="contact_phone_number"
            label={`${t("general_investor_main_contact_phone_number_label")}:`}
            placeholder={t("general_investor_main_contact_phone_number_placeholder")}
            error={errors.contact_phone_number}
          />
        </div>
        <Select
          label={t("country")}
          name={"country"}
          placeholder={t("select_option")}
          items={countries}
          error={errors.country}
          multiSelect={false}
          isClearable={true}
          initialValues={countries.filter((i) => watch("country")?.includes(i.id)) || []}
          onSelect={(selection) =>
            _setValue(
              "country",
              selection.map((item) => item.id),
            )
          }
        />
        <Select
          name="language"
          reference={register}
          label={t("contact_language")}
          required={false}
          isClearable={false}
          placeholder={t("select_option")}
          error={errors.language}
          initialValues={LANGUAGES.filter((i) => watch("language")?.includes(i.id)) || []}
          items={LANGUAGES}
          onSelect={(selected) => {
            _setValue("language", selected.length ? selected[0].id : null);
          }}
        />
        {!user.lp && (
          <div className={"flex justify-end mt-2 pt-2 border-t border-separator"}>
            {!isWizard && (
              <ButtonDanger
                type="button"
                onClick={onDelete}
                text={t("delete_contact")}
                marginRight={2}
              />
            )}
            <ButtonMain
              type="button"
              onClick={async () => {
                const validation = await trigger();
                if (validation)
                  onSubmit({ ...getValues(), company_logo: logo, investor_type: investorType });
                else {
                  addToast(t("form_field_error"), { appearance: "error", autoDismiss: true });
                }
              }}
              text={t("save")}
            />
          </div>
        )}
      </form>
      {loading && <Loader />}
    </div>
  );
};

export default GeneralInvestorForm;
