import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { Modal } from "components/ui/molecules/Modal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCompress, FaDownload, FaExpand } from "react-icons/fa";

// Necesario para renderizar correctamente documentos pdf
import "./pdf-css/AnnotationLayer.css";
import "./pdf-css/TextLayer.css";

const DocumentPreview = ({ previewUrl, handleClose, downloadDocument, document, refetch }) => {
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = async() => {
    await refetch();
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      {previewUrl && (
        <Modal showModal={true} onClose={handleClose} showCloseModal={true} title={document.name}>
          <div className="mt-4 pb-8">
            <div className="flex gap-2">
              <ButtonMain
                text={t("download")}
                onClick={downloadDocument}
                iconComponent={<FaDownload className="inline-block mr-3" />}
              />
              <ButtonMain
                onClick={toggleExpand}
                text={!isExpanded ? t("expand") : t("contract")}
                iconComponent={
                  !isExpanded ? (
                    <FaExpand className="inline-block mr-3" />
                  ) : (
                    <FaCompress className="inline-block mr-3" />
                  )
                }
              />
            </div>
            <br />
            <div
              style={
                isExpanded ? { width: "95vw", height: "85vh" } : { width: "70vw", height: "70vh" }
              }
              className="overflow-y-scroll"
            >
              <DocViewer
                documents={[{ uri: previewUrl }]}
                pluginRenderers={DocViewerRenderers}
                prefetchMethod="GET"
                config={{
                  header: {
                    disableHeader: true,
                  },
                  pdfVerticalScrollByDefault: true,
                }}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default DocumentPreview;
