import { useTranslation } from "react-i18next";
import { PageTitle } from "components/ui/atoms/PageTitle";
import { SectionHeader } from "components/ui/molecules/SectionHeader";
import { useNavigate } from "react-router-dom";
import useUser from "hooks/useUser";
import { useSelector } from "react-redux";

const Feedback = ({ section, urls, sections }) => {
  const { t } = useTranslation();
const navigate = useNavigate();
  const { userLanguage } = useUser();

  const platform = useSelector((state) => state.app.platform);

  const handleRedirect = (url) => navigate(url);

  return (
    <>
      <div className="flex mb-6">
        <div className="flex-1">
          <PageTitle removeMargin={true} showBack={false} title={t("integrations")} />
        </div>
      </div>
      <SectionHeader
        section={section}
        setSection={(sec) => {
          handleRedirect(urls[sec]);
        }}
        sectionKeys={sections}
        sectionTitles={sections.map((s) => t(s))}
      />
      <div className="h-screen my-6">
        <iframe
          src={platform.feedback[userLanguage]}
          width="100%"
          height="100%"
          className="rounded-lg"
        ></iframe>
      </div>
    </>
  );
};

export default Feedback;
