import { FaFire, FaMoneyBillAlt, FaPiggyBank, FaRoad, FaUserSlash, FaMoneyBill, FaUserCheck } from "react-icons/fa";
import { Trans, useTranslation } from "react-i18next";
import { Card } from "../../ui/atoms/Card";
import LineChart from "../../ui/molecules/charts/LineChart";
import { ResponsiveBar } from "@nivo/bar";
import BarChart from "../../ui/molecules/charts/BarChart";
import Utils from "../../../utils/utils";
import useUser from "../../../hooks/useUser";
import { isEmpty } from "lodash";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import moment from "moment";
import { Loader } from "components/ui/molecules/Loader";
import useCurrency from "hooks/useCurrency";
import { useOutletContext } from "react-router-dom";
import { useImperativeHandle } from "react";
import { DealflowService } from "services/dealflow-service";
import { useToasts } from "react-toast-notifications";
import { useSwal } from "hooks/useSwal";

export const StartupKPIs = () => {
  const { deal, loading, ref, setLoading } = useOutletContext();

  const { t } = useTranslation();
  const { userLanguage } = useUser();

  const { currency } = useCurrency();

  const { addToast } = useToasts();

  const { confirm } = useSwal();

  const requestKPIs = () => {
    confirm({}).then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await DealflowService.requestKPIs(deal._id);
          addToast(t("kpis_requested_successfully"), { appearance: "success", autoDismiss: true });
        } catch (error) {
          addToast(t("error_occurred_requesting_kpis"), { appearance: "error", autoDismiss: true });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  useImperativeHandle(ref, () => ({
    requestKpis: () => requestKPIs(true),
  }));

  const gainLossProps = {
    padding: 0.2,
    labelTextColor: "inherit:darker(1.4)",
    labelSkipWidth: 16,
    labelSkipHeight: 16,
    indexBy: "month",
    enableGridX: true,
    enableGridY: true,
    valueFormat: (value) => `${Math.abs(value)}${currency}`,
    axisTop: {
      tickSize: 0,
      tickPadding: 12,
    },
    axisLeft: null,
    axisRight: {
      format: (v) => `${Math.abs(v)}${currency}`,
    },
    markers: [
      {
        axis: "y",
        value: 0,
        lineStyle: { strokeOpacity: 0 },
        textStyle: { fill: "#2ebca6" },
        legend: t("gain"),
        legendPosition: "top-left",
        legendOrientation: "vertical",
        legendOffsetY: 30,
      },
      {
        axis: "y",
        value: 0,
        lineStyle: { stroke: "#f47560", strokeWidth: 1 },
        textStyle: { fill: "#e25c3b" },
        legend: t("loss"),
        legendPosition: "bottom-left",
        legendOrientation: "vertical",
        legendOffsetY: 30,
      },
    ],
    height: 350,
    keys: [t("gain"), t("loss")],
    colors: ["#61cdbb", "#f47560"],
  };

  const getKPILatestYear = (obj) => {
    const years = Object.keys(obj || {});

    if (years.length > 0) {
      return obj[years.sort((a, b) => b - a)[0]];
    }

    return {};
  };

  const getKPILatestYearAvg = (obj, latestAmount = 12) => {
    const latestYear = getKPILatestYear(obj);
    const months = Object.values(latestYear)
      .filter(Boolean)
      .sort((a, b) => b - a)
      .slice(0, latestAmount);

    let sum = 0;

    for (let i = 0; i < months.length; i++) {
      sum += months[i] || 0;
    }

    return sum ? sum / months.length : 0;
  };

  const getKPILatestMonthValue = (obj) => {
    const latestYear = getKPILatestYear(obj);
    const months = Object.keys(latestYear);

    if (months.length > 0) {
      return latestYear[months.sort((a, b) => b - a)[0]];
    }

    return null;
  };

  const getRunway = () => {
    const spending = deal.economic_spending;
    const resources = deal.economic_resources;

    if ([null, undefined].includes(spending) || [null, undefined].includes(resources)) {
      return null;
    } else if (!spending || !resources) {
      return 0;
    }

    const resourcesValue = getKPILatestMonthValue(resources);
    const spendingAVG = getKPILatestYearAvg(spending);

    return resourcesValue !== null ? (resourcesValue / spendingAVG).toFixed() : null;
  };

  const getBurnRate = () => {
    const spending = deal.economic_spending;
    const resources = deal.economic_resources;

    if ([null, undefined].includes(spending) || [null, undefined].includes(resources)) {
      return null;
    } else if (!spending || !resources) {
      return 0;
    }

    const resourcesValue = getKPILatestMonthValue(resources);
    const spendingAVG = getKPILatestYearAvg(spending, 3);

    if (!resourcesValue || !spendingAVG) {
      return null;
    }

    return ((spendingAVG * 100) / resourcesValue).toFixed(2);
  };

  const getMRR = () => {
    const income = deal.economic_income;

    if ([null, undefined].includes(income)) {
      return null;
    } else if (!income) {
      return 0;
    }

    const mrr = getKPILatestYearAvg(income, 4);
    return mrr ? mrr.toFixed() : null;
  };

  const getARR = () => {
    const income = deal.economic_income;

    if ([null, undefined].includes(income)) {
      return null;
    } else if (!income) {
      return 0;
    }

    const latestYear = getKPILatestYear(income);
    const months = Object.keys(latestYear);
    const arr = months.reduce((acc, month) => acc + latestYear[month], 0);

    return arr ? arr.toFixed() : null;
  };

  const getChurnRate = () => {
    const rate = deal.churn_rate;

    if ([null, undefined].includes(rate)) {
      return null;
    } else if (!rate) {
      return 0;
    }
    return getKPILatestMonthValue(rate)?.toFixed() || null;
  };

  const getGMV = () => {
    const gmv = deal.gmv;

    if ([null, undefined].includes(gmv)) {
      return null;
    } else if (!gmv) {
      return 0;
    }

    return getKPILatestMonthValue(gmv)?.toFixed() || null;
  };

  const getChartEvolution = (year) => {
    if (!year || !Object.keys(year).length) {
      return Array.from({ length: 12 }, () => 0).map((_, i) => ({
        x: Utils.getMonthName(userLanguage, i, true),
        y: 0,
      }));
    }

    const latestYear = getKPILatestYear(year);

    return Object.keys(latestYear)
      .filter((month) => Boolean(latestYear[month]))
      .sort((a, b) => a - b)
      .map((month) => ({
        x: Utils.getMonthName(userLanguage, month - 1, true),
        y: latestYear[month],
      }));
  };

  const getGainLossEvolution = () => {
    const spending = deal.economic_spending;
    const income = deal.economic_income;

    if (isEmpty(spending) || isEmpty(income)) {
      return <NoDataInfo title={t("no_data_provided")} />;
    }

    const gain = getKPILatestYear(income);
    const loss = getKPILatestYear(spending);

    return (
      <ResponsiveBar
        {...gainLossProps}
        data={Array(12)
          .fill(0)
          .map((_, index) => ({
            [t("gain")]: gain[index + 1] || 0,
            [t("loss")]: -loss[index + 1] || 0,
            month: Utils.getMonthName(userLanguage, index, true),
          }))}
      />
    );
  };

  const getNPSEvolution = () => {
    const nps = deal.nps;

    if (!nps || !Object.keys(nps).length) {
      return <NoDataInfo title={t("no_data_provided")} />;
    }

    const latestYear = getKPILatestYear(nps);
    const months = Object.keys(latestYear);

    return (
      <BarChart
        keys={[t("nps_short")]}
        data={months.map((month) => ({
          [t("nps_short")]: latestYear[month] || 0,
          indexed: Utils.getMonthName(userLanguage, month - 1, true),
        }))}
      />
    );
  };

  const renderCard = (title, value, icon) => (
    <Card wrapperClassName="relative" containerClassName="h-full">
      <div className="text-white flex items-center absolute rounded-full py-4 px-4 shadow-xl bg-lightBlue-600 left-4 -top-6">
        {icon}
      </div>
      <div className="pt-6 flex flex-col h-full">
        <div>
          <p className="text-lg font-semibold my-2">{title}</p>
        </div>
        <div className="flex-1 flex font-bold justify-center items-end text-5xl">
          <div>{value}</div>
        </div>
      </div>
    </Card>
  );

  if (!deal) {
    return null;
  }

  const getKPISUpdateDate = (founderDate, investorDate) => {
    if (founderDate && investorDate) {
      const momentFounderDate = moment(founderDate);
      const momentInvestorDate = moment(investorDate);
      if (momentFounderDate.isAfter(momentInvestorDate)) {
        return momentFounderDate.format("YYYY-MM-DD");
      } else {
        return momentInvestorDate.format("YYYY-MM-DD");
      }
    } else if (founderDate) {
      return moment(founderDate).format("YYYY-MM-DD");
    } else if (investorDate) {
      return moment(investorDate).format("YYYY-MM-DD");
    }
  };

  const getKPISUpdateRole = (founderDate, investorDate) => {
    if (founderDate && investorDate) {
      const momentFounderDate = moment(founderDate);
      const momentInvestorDate = moment(investorDate);
      if (momentFounderDate.isAfter(momentInvestorDate)) {
        return t("founder");
      } else {
        return t("investor");
      }
    } else if (founderDate) {
      return t("founder");
    } else if (investorDate) {
      return t("investor");
    }
  };

  const getKPISUpdateLegend = () => {
    if (deal.updated_founder_kpis || deal.updated_investor_kpis) {
      return (
        <Trans
          i18nKey="kpis_update_date"
          values={{
            date: getKPISUpdateDate(deal.updated_founder_kpis, deal.updated_investor_kpis),
            role: getKPISUpdateRole(deal.updated_founder_kpis, deal.updated_investor_kpis),
          }}
          components={{ bold: <strong /> }}
        />
      );
    } else {
      return t("kpis_not_filled");
    }
  };

  return (
    <>
      {loading && <Loader />}
      {/*! deal.kpis_automated && (
        <Card wrapperClassName='my-6'>
          <NoDataInfo title={t('founders_has_no_access_to_kpis')} />
          <div className='flex justify-center'>
            <ButtonMain text={t('request_kpis')} onClick={deal.main_contact_email ? () => grantAccess() : () => alert({ text: t('main_contact_email_required') })} />
          </div>
        </Card>
      ) */}
      <>
        <div className="w-full flex justify-end text-gray text-sm mt-4 whitespace-pre-wrap">
          {getKPISUpdateLegend()}
        </div>
        <div className="grid grid-cols-1 gap-x-4 gap-y-9 md:grid-cols-2 xl:grid-cols-4 my-10">
          {renderCard(
            <>
              {t("runway")} ({t(getRunway() > 12 ? "years" : "months")})
            </>,
            getRunway() !== null ? (
              getRunway() > 12 ? (
                Math.round(getRunway() / 12)
              ) : (
                getRunway()
              )
            ) : (
              <div className="text-center">
                <span className="text-sm text-gray-300 text-center">{t("no_data_provided")}</span>
              </div>
            ),
            <FaRoad className="w-6 h-6" />,
          )}

          {renderCard(
            <>
              {t("burn_rate")} ({t("monthly")})
            </>,
            getBurnRate() !== null ? (
              `${getBurnRate()}%`
            ) : (
              <div className="text-center">
                <span className="text-sm text-gray-300 text-center">{t("no_data_provided")}</span>
              </div>
            ),
            <FaFire className="w-6 h-6" />,
          )}

          {renderCard(
            t("mrr_short"),
            getMRR() !== null ? (
              `${Utils.nFormatter(getMRR())}${currency}`
            ) : (
              <div className="text-center">
                <span className="text-sm text-gray-300 text-center">{t("no_data_provided")}</span>
              </div>
            ),
            <FaMoneyBill className="w-6 h-6" />,
          )}

          {renderCard(
            t("arr_short"),
            getARR() !== null ? (
              `${Utils.nFormatter(getARR())}${currency}`
            ) : (
              <div className="text-center">
                <span className="text-sm text-gray-300 text-center">{t("no_data_provided")}</span>
              </div>
            ),
            <FaMoneyBill className="w-6 h-6" />,
          )}

          {renderCard(
            t("churn_rate"),
            getChurnRate() !== null ? (
              `${getChurnRate()}%`
            ) : (
              <div className="text-center">
                <span className="text-sm text-gray-300 text-center">{t("no_data_provided")}</span>
              </div>
            ),
            <FaUserSlash className="w-6 h-6" />,
          )}

          {renderCard(
            t("ltv"),
            deal.ltv ? (
              `${deal.ltv}%`
            ) : (
              <div className="text-center">
                <span className="text-sm text-gray-300 text-center">{t("no_data_provided")}</span>
              </div>
            ),
            <FaPiggyBank className="w-6 h-6" />,
          )}

          {renderCard(
            t("cac"),
            deal.cac ? (
              `${deal.cac}${currency}`
            ) : (
              <div className="text-center">
                <span className="text-sm text-gray-300 text-center">{t("no_data_provided")}</span>
              </div>
            ),
            <FaUserCheck className="w-6 h-6" />,
          )}

          {renderCard(
            t("gmv"),
            getGMV() !== null ? (
              `${getGMV()}${currency}`
            ) : (
              <div className="text-center">
                <span className="text-sm text-gray-300 text-center">{t("no_data_provided")}</span>
              </div>
            ),
            <FaMoneyBillAlt className="w-6 h-6" />,
          )}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
          <Card>
            <p className="text-xl font-semibold my-2">{t("mrr_evolution")}</p>

            <div className="w-full h-96 mt-6">
              {isEmpty(deal.economic_income) ? (
                <NoDataInfo title={t("no_data_provided")} />
              ) : (
                <LineChart
                  yFormat=">-.0f"
                  data={[
                    {
                      id: "economic_income",
                      data: getChartEvolution(deal.economic_income),
                    },
                  ]}
                />
              )}
            </div>
          </Card>
          <Card>
            <p className="text-xl font-semibold my-2">{t("churn_rate_evolution")}</p>

            <div className="w-full h-96 mt-6">
              {isEmpty(deal.churn_rate) ? (
                <NoDataInfo title={t("no_data_provided")} />
              ) : (
                <LineChart
                  yFormat=">-.0f"
                  data={[
                    {
                      id: "churn_rate",
                      data: getChartEvolution(deal.churn_rate),
                    },
                  ]}
                />
              )}
            </div>
          </Card>
        </div>

        <Card>
          <p className="text-xl font-semibold my-2">{t("loss_gain_evolution")}</p>

          <div className="h-[370px] flex items-center max-w-full">{getGainLossEvolution()}</div>
        </Card>

        <Card wrapperClassName="mt-4">
          <p className="text-xl font-semibold my-2">{t("nps_evolution")}</p>
          <div className="h-[370px] flex items-center max-w-full">{getNPSEvolution()}</div>
        </Card>

        {/* <div className='grid grid-cols-1 md:grid-cols-2 gap-4 my-4'>
          <div>
            <Card>
              <p className='text-xl font-semibold mb-6'>{t('social_media')}</p>
              <div className='grid grid-cols-3 gap-4'>
                <div className='flex justify-center flex-row'>
                  <div className='flex flex-col items-center'>
                    <img
                      src={Linkedin}
                      alt='Arrow icon'
                      className='w-14'
                    />
                    <div className='font-bold text-xl flex justify-center mt-2'>
                      20.5K
                    </div>
                    <div>
                      {t('followers')}
                    </div>
                  </div>
                </div>
                <div className='flex justify-center flex-row'>
                  <div className='flex flex-col items-center'>
                    <img
                      src={Twitter}
                      alt='Arrow icon'
                      className='w-14'
                    />
                    <div className='font-bold text-xl flex justify-center mt-2'>
                      20.5K
                    </div>
                    <div>
                      {t('followers')}
                    </div>
                  </div>
                </div>
                <div className='flex justify-center flex-row'>
                  <div className='flex flex-col items-center'>
                    <img
                      src={Instagram}
                      alt='Arrow icon'
                      className='w-14'
                    />
                    <div className='font-bold text-xl flex justify-center mt-2'>
                      20.5K
                    </div>
                    <div>
                      {t('followers')}
                    </div>
                  </div>
                </div>
                <div className='flex justify-center flex-row'>
                  <div className='flex flex-col items-center'>
                    <img
                      src={Youtube}
                      alt='Arrow icon'
                      className='w-14'
                    />
                    <div className='font-bold text-xl flex justify-center mt-2'>
                      20.5K
                    </div>
                    <div>
                      {t('followers')}
                    </div>
                  </div>
                </div>
                <div className='flex justify-center flex-row'>
                  <div className='flex flex-col items-center'>
                    <img
                      src={Tiktok}
                      alt='Arrow icon'
                      className='w-14'
                    />
                    <div className='font-bold text-xl flex justify-center mt-2'>
                      20.5K
                    </div>
                    <div>
                      {t('followers')}
                    </div>
                  </div>
                </div>
                <div className='flex justify-center flex-row'>
                  <div className='flex flex-col items-center'>
                    <img
                      src={Facebook}
                      alt='Arrow icon'
                      className='w-14'
                    />
                    <div className='font-bold text-xl flex justify-center mt-2'>
                      20.5K
                    </div>
                    <div>
                      {t('likes')}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div>

            <Card>
              <p className='text-xl font-semibold mb-6'>{t('apps')}</p>
              <div className='grid grid-cols-2 gap-4'>
                <div className='flex justify-center flex-row'>
                  <div className='flex flex-col items-center'>
                    <img
                      src={PlayStore}
                      alt='Arrow icon'
                      className='w-24'
                    />
                    <div className='font-bold text-xl flex justify-center mt-2'>
                      <FaStar className='inline-block w-6 h-6 mr-2 text-yellow-600'/> 4.4
                      <FaUser className='inline-block w-6 h-6 mx-2 text-gray'/> 4.4K
                      <FaDownload className='inline-block w-6 h-6 mx-2'/> 4K
                    </div>
                  </div>
                </div>
                <div className='flex justify-center flex-row'>
                  <div className='flex flex-col items-center'>
                    <img
                      src={AppleStore}
                      alt='Arrow icon'
                      className='w-20'
                    />
                    <div className='font-bold text-xl flex justify-center mt-8'>
                      <FaStar className='inline-block w-6 h-6 mr-2 text-yellow-600'/> 4.4
                      <FaUser className='inline-block w-6 h-6 mx-2 text-gray'/> 4.4K
                      <FaDownload className='inline-block w-6 h-6 mx-2'/> 4K
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div> */}
      </>
    </>
  );
};
