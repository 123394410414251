/* eslint-disable indent */
import React from "react";
import { GoogleLogin } from "react-google-login";
import { useTranslation } from "react-i18next";
import { Spinner } from "./Spinner";
import { useSwal } from "hooks/useSwal";

export function GoogleButton({
  isActiveLoginOutlook = false,
  clientId,
  onSuccess,
  onFailure,
  cookiePolicy,
  text,
  loadingGoogleLogin,
  disabled,
}) {
  const { t } = useTranslation();
  const { alert } = useSwal();
  const handleClickLoginWithOutlook = () => {
    alert({
      text: "Ya tiene una cuenta de Outlook sincronizada por favor deshabilite la sincronización e intente nuevamente",
    });
  };

  return (
    <>
      {isActiveLoginOutlook && (
        <>
          <button
            type="button"
            onClick={handleClickLoginWithOutlook}
            style={{
              backgroundColor: "rgb(255, 255, 255)",
              display: "inline-flex",
              alignItems: "center",
              color: "rgba(0, 0, 0, 0.54)",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px",
              padding: "0px",
              borderRadius: "2px",
              border: "1px solid transparent",
              fontSize: "14px",
              fontWeight: "500",
              fontFamily: "Roboto, sans-serif",
            }}
            className="google-button inline-flex justify-center | w-full | py-0.5 px-7 | text-center text-sm font-semibold bg-bg-buttons-seconday border-border-buttons-secondary text-text-buttons-secondary font-sans | rounded-2xl  opacity-100 outline-none focus:outline-none | placeholder-gray | cursor-pointer transition-all duration-200 | shadow-hover hover:shadow-inner | disabled:cursor-default"
          >
            <div
              style={{
                marginRight: "10px",
                background: "rgb(255, 255, 255)",
                padding: "10px",
                borderRadius: "2px",
              }}
            >
              <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
                <g fill="#000" fillRule="evenodd">
                  <path
                    d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
                    fill="#EA4335"
                  ></path>
                  <path
                    d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
                    fill="#4285F4"
                  ></path>
                  <path
                    d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
                    fill="#FBBC05"
                  ></path>
                  <path
                    d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
                    fill="#34A853"
                  ></path>
                  <path fill="none" d="M0 0h18v18H0z"></path>
                </g>
              </svg>
            </div>
            <span
              style={{
                marginRight: "10px",
                background: "rgb(255, 255, 255)",
                padding: "10px",
                borderRadius: "2px",
              }}
            >
              <span style={{ fontWeight: "500 !important" }}>{text}</span>
            </span>
          </button>
        </>
      )}
      {/** Google Login active */}
      {!isActiveLoginOutlook && (
        <GoogleLogin
          clientId={clientId}
          buttonText={t("login_google_button")}
          onSuccess={onSuccess}
          onFailure={onFailure}
          cookiePolicy={cookiePolicy}
          disabled={disabled}
          className={
            "google-button inline-flex justify-center | w-full | py-0.5 px-7 | text-center text-sm font-semibold bg-bg-buttons-seconday border-border-buttons-secondary text-text-buttons-secondary font-sans | rounded-2xl  opacity-100 outline-none focus:outline-none | placeholder-gray | cursor-pointer transition-all duration-200 | shadow-hover hover:shadow-inner | disabled:cursor-default"
          }
        >
          {loadingGoogleLogin ? (
            <div className="flex items-center">
              <span className="mr-4">{text}</span>
              <span>
                <Spinner />
              </span>
            </div>
          ) : (
            <span>{text}</span>
          )}
        </GoogleLogin>
      )}
    </>
  );
}
