import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChecksOperations } from "../../redux/checks";
import { Navigate, useNavigate } from "react-router-dom";
import { RoutesLinks } from "../routes-links";
import FirstStep from "./components/first-step";
import SecondStep from "./components/second-step";
import ThirdStep from "./components/third-step";
import useUser from "hooks/useUser";
import AccountService from "services/account-service";

const OnBoarding = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const checked = useSelector((state) => state.checks.checked);
  const stage = useSelector((state) => state.checks.stage);
  const { reloadUserInfo } = useUser();

  const { token } = auth;

  const navigate = useNavigate();
  const handleRedirect = (url) => navigate(url);

  const nextStep = () => {
    if (stage === "ON_BOARDING_FIRST_STEP") {
      // dispatch(ChecksOperations.setStage('ON_BOARDING_SECOND_STEP'))
      dispatch(ChecksOperations.setStage("ON_BOARDING_THIRD_STEP"));
    } else if (stage === "ON_BOARDING_SECOND_STEP") {
      dispatch(ChecksOperations.setStage("ON_BOARDING_THIRD_STEP"));
    }
  };

  const previousStep = () => {
    if (stage === "ON_BOARDING_SECOND_STEP") {
      dispatch(ChecksOperations.setStage("ON_BOARDING_FIRST_STEP"));
    } else if (stage === "ON_BOARDING_THIRD_STEP") {
      // dispatch(ChecksOperations.setStage('ON_BOARDING_SECOND_STEP'))
      dispatch(ChecksOperations.setStage("ON_BOARDING_FIRST_STEP"));
    }
  };

  const finishOnBoarding = async() => {
    try {
      await AccountService.completeWelcomeSteps();
      reloadUserInfo();
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(ChecksOperations.setStage("ON_BOARDING_FINISHED"));
      handleRedirect(RoutesLinks.DASHBOARD_LINK);
    }
  };

  if (!token) {
    return <Navigate to={RoutesLinks.LOGIN_LINK} />;
  } else if (checked) {
    return <Navigate to={RoutesLinks.DASHBOARD_LINK} />;
  } else {
    return (
      <section>
        {stage === "ON_BOARDING_FIRST_STEP" && <FirstStep nextStep={nextStep} />}
        {stage === "ON_BOARDING_SECOND_STEP" && (
          <SecondStep nextStep={nextStep} previousStep={previousStep} />
        )}
        {stage === "ON_BOARDING_THIRD_STEP" && (
          <ThirdStep nextStep={finishOnBoarding} previousStep={previousStep} />
        )}
      </section>
    );
  }
};

export default OnBoarding;
