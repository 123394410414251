import { useTranslation } from "react-i18next";
import { Likert } from "components/ui/molecules/Likert";
import Config from "config";

export const QuestionTypeLikert = ({ currentQuestion, onChange, value, language }) => {
  const { t } = useTranslation();

  return (
    <div className="px-4">
      {!currentQuestion.required && (
        <div
          className={"text-main text-xs font-bold cursor-pointer mb-2"}
          onClick={() => onChange(null)}
        >
          {t("reset")}
        </div>
      )}
      <Likert
        key={currentQuestion.question_id._id}
        onChange={(val) => {
          onChange(value === val ? null : val);
        }}
        id={currentQuestion.question_id._id}
        disabled={false}
        value={value}
        options={currentQuestion.question_id.options.map((option, index) => ({
          id: index,
          value: option[language],
        }))}
      />
    </div>
  );
};
