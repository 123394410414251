import { useTranslation } from "react-i18next";
import { SidePanel } from "components/ui/objects/SidePanel";
import { useEffect, useState } from "react";

import Select from "components/ui/atoms/Select";
import { Switch } from "components/ui/atoms/Switch";
import Config from "config";
import { Card } from "../atoms/Card";
import { ButtonMain } from "../atoms/ButtonMain";
import { useToasts } from "react-toast-notifications";
import { RoutesLinks } from "components/routes-links";
import { useNavigate } from "react-router-dom";
import { SectionHeader } from "./SectionHeader";
import useUser from "hooks/useUser";

export const ShareDealSection = ({
  onSubmit,
  onClose,
  irm = true,
  contacts = [],
  initialValues = {},
  members = [],
}) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const { hasAccessToModule } = useUser();
  const [name, setName] = useState(true);
  const [logo, setLogo] = useState(true);
  const [brandStatement, setBrandStatement] = useState(true);
  const [investmentStage, setInvestmentStage] = useState(true);
  const [businessModel, setBusinessModel] = useState(true);
  const [sector, setSector] = useState(true);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [personContact, setPersonContact] = useState(false);
  const [emailContact, setEmailContact] = useState(false);
  const [phoneContact, setPhoneContact] = useState(false);
  const [socialNetworks, setSocialNetworks] = useState(false);
  const [web, setWeb] = useState(false);
  const [country, setCountry] = useState(false);
  const [projectStage, setProjectStage] = useState(false);
  const [problem, setProblem] = useState(false);
  const [solution, setSolution] = useState(false);
  const [businessModelDescription, setBusinessModelDescription] = useState(false);
  const [market, setMarket] = useState(false);
  const [minimumTicket, setMinimumTicket] = useState(false);
  const [investmentDesired, setInvestmentDesired] = useState(false);
  const [valuation, setValuation] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (initialValues.hasOwnProperty("name")) {
      setName(initialValues.name);
    } else {
      setName(true);
    }
    if (initialValues.hasOwnProperty("logo")) {
      setLogo(initialValues.logo);
    } else {
      setLogo(true);
    }
    if (initialValues.hasOwnProperty("brand_statement")) {
      setBrandStatement(initialValues.brand_statement);
    } else {
      setBrandStatement(true);
    }
    if (initialValues.hasOwnProperty("investment_stage")) {
      setInvestmentStage(initialValues.investment_stage);
    } else {
      setInvestmentStage(true);
    }
    if (initialValues.hasOwnProperty("business_model")) {
      setBusinessModel(initialValues.business_model);
    } else {
      setBusinessModel(true);
    }
    if (initialValues.hasOwnProperty("sector")) {
      setSector(initialValues.sector);
    } else {
      setSector(true);
    }
    if (initialValues.hasOwnProperty("social_networks")) {
      setSocialNetworks(initialValues.social_networks);
    } else {
      setSocialNetworks(true);
    }
    if (initialValues.hasOwnProperty("web")) {
      setWeb(initialValues.web);
    } else {
      setWeb(true);
    }
    if (initialValues.hasOwnProperty("country")) {
      setCountry(initialValues.country);
    } else {
      setCountry(true);
    }
    if (initialValues.hasOwnProperty("project_stage")) {
      setProjectStage(initialValues.project_stage);
    } else {
      setProjectStage(true);
    }
    if (initialValues.hasOwnProperty("problem")) {
      setProblem(initialValues.problem);
    } else {
      setProblem(true);
    }
    if (initialValues.hasOwnProperty("solution")) {
      setSolution(initialValues.solution);
    } else {
      setSolution(true);
    }
    if (initialValues.hasOwnProperty("business_model_description")) {
      setBusinessModelDescription(initialValues.business_model_description);
    } else {
      setBusinessModelDescription(true);
    }
    if (initialValues.hasOwnProperty("market")) {
      setMarket(initialValues.market);
    } else {
      setMarket(true);
    }
    if (initialValues.hasOwnProperty("contact_person")) {
      setPersonContact(initialValues.contact_person);
    } else {
      setPersonContact(true);
    }
    if (initialValues.hasOwnProperty("contact_email")) {
      setEmailContact(initialValues.contact_email);
    } else {
      setEmailContact(true);
    }
    if (initialValues.hasOwnProperty("contact_phone")) {
      setPhoneContact(initialValues.contact_phone);
    } else {
      setPhoneContact(true);
    }
    if (initialValues.hasOwnProperty("minimum_ticket")) {
      setMinimumTicket(initialValues.minimum_ticket);
    } else {
      setMinimumTicket(true);
    }
    if (initialValues.hasOwnProperty("investment_desired")) {
      setInvestmentDesired(initialValues.investment_desired);
    } else {
      setInvestmentDesired(true);
    }
    if (initialValues.hasOwnProperty("valuation")) {
      setValuation(initialValues.valuation);
    } else {
      setValuation(true);
    }
  }, [initialValues]);
  /* const LANGUAGES = [
    { id: 'es', value: t('es') },
    { id: 'en', value: t('en') }
  ]

  /*const ShareDealSchema = Yup.object().shape({
    language: Yup.string().required(t('required_field'))
  })

  /*const { register, errors, setValue, trigger, watch, getValues } = useForm({
    resolver: yupResolver(ShareDealSchema),
    criteriaMode: 'all',
    reValidateMode: 'all',
    mode: 'onChange'
  })

  useEffect(() => {
    register('language')
  }, [register])

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, config)
    trigger(name)
  }

  useEffect(() => {
    _setValue('language', 'en')
  }, [])

  const getSelectableInitialValues = (id, collection, lonely) => {
    if (!id) {
      return lonely ? null : []
    }

    if (Array.isArray(id)) {
      return collection.filter(i => id.includes(i.id))
    }

    const item = collection.find(i => i.id === id)
    return item ? lonely ? item : [item] : lonely ? null : []
  } */

  const handleSelectFinancialInformation = (allSelected) => {
    setMinimumTicket(!allSelected);
    setInvestmentDesired(!allSelected);
    setValuation(!allSelected);
  };

  const handleSelectContactInformation = (allSelected) => {
    setPersonContact(!allSelected);
    setEmailContact(!allSelected);
    setPhoneContact(!allSelected);
  };

  const handleSelectGeneralInformation = (allSelected) => {
    setSocialNetworks(!allSelected);
    setWeb(!allSelected);
    setCountry(!allSelected);
    setProjectStage(!allSelected);
    setProblem(!allSelected);
    setSolution(!allSelected);
    setBusinessModelDescription(!allSelected);
    setMarket(!allSelected);
  };

  const getContactName = (contact) => {
    if (!contact.language || !contact.contact_email) {
      if (contact.company) {
        if (contact.contact) {
          return `${contact.company} (${contact.contact}) (${t("contact_without_enough_information_to_share_deal")})`;
        } else {
          return `${contact.company} (${t("contact_without_enough_information_to_share_deal")})`;
        }
      } else {
        return `${contact.contact} (${t("contact_without_enough_information_to_share_deal")})`;
      }
    } else {
      if (contact.company) {
        if (contact.contact) {
          return `${contact.company} (${contact.contact})`;
        } else {
          return `${contact.company}`;
        }
      } else {
        return `${contact.contact}`;
      }
    }
  };
  const [section, setSection] = useState("contacts");

  useEffect(() => {
    setSelectedContacts([]);
  }, [section]);

  return (
    <>
      <SidePanel onClose={onClose} title={t("share_startup")} width="1/3">
        {!irm && (
          <>
            <SectionHeader
              showFilters={false}
              showAdd={false}
              showSort={false}
              showUpload={false}
              sectionTitles={hasAccessToModule("members") ? [t("contacts"), t("members")] : [t("contacts")]}
              sectionKeys={
                hasAccessToModule("members")
                  ? ["contacts", "members"].map((_section) => ({ title: _section, id: _section, route: _section }))
                  : ["contacts"].map((_section) => ({ title: _section, id: _section, route: _section }))
              }
              section={section}
              setSection={setSection}
            />
            {section === "contacts" && (
              <>
                {contacts.length === 0 && (
                  <div className="flex items-center flex-col mt-4">
                    {t("share_deal_no_contacts_found")}
                    <div className="my-4">
                      <ButtonMain text={t("register_contact")} onClick={() => navigate(RoutesLinks.CONTACTS_LINK)} />
                    </div>
                  </div>
                )}
                {contacts.length !== 0 && (
                  <div className="mt-4">
                    <div
                      className="flex justify-end text-xs cursor-pointer hover:font-semibold mb-2"
                      onClick={() => {
                        if (
                          contacts.filter((contact) => contact.language && contact.contact_email).length ===
                          selectedContacts.length
                        ) {
                          setSelectedContacts([]);
                        } else {
                          setSelectedContacts(
                            contacts
                              .filter((contact) => contact.language && contact.contact_email)
                              .map((contact) => ({
                                ...contact,
                                id: contact._id,
                                value: getContactName(contact),
                                disabled: !contact.language || !contact.contact_email,
                              }))
                          );
                        }
                      }}
                    >
                      {contacts.filter((contact) => contact.language && contact.contact_email).length ===
                      selectedContacts.length
                        ? t("deselect_all_contacts")
                        : t("select_all_contacts")}
                    </div>
                    <Select
                      name="contact"
                      multiSelect={true}
                      label={t("contact_to_share_with")}
                      required={true}
                      isClearable={false}
                      placeholder={t("select_option")}
                      initialValues={selectedContacts}
                      items={contacts.map((contact) => ({
                        ...contact,
                        id: contact._id,
                        value: getContactName(contact),
                        disabled: !contact.language || !contact.contact_email,
                      }))}
                      onSelect={(selected) => {
                        setSelectedContacts(selected);
                      }}
                    />
                  </div>
                )}
              </>
            )}
            {section === "members" && (
              <>
                {members.length === 0 && (
                  <div className="flex items-center flex-col mt-4">
                    {t("share_deal_no_members_found")}
                    <div className="my-4">
                      <ButtonMain text={t("add_member")} onClick={() => navigate(RoutesLinks.MEMBERS_LINK)} />
                    </div>
                  </div>
                )}
                {members.length !== 0 && (
                  <div className="mt-4">
                    <div
                      className="flex justify-end text-xs cursor-pointer hover:font-semibold mb-2"
                      onClick={() => {
                        if (members.length === selectedContacts.length) {
                          setSelectedContacts([]);
                        } else {
                          setSelectedContacts(
                            members.map((member) => ({
                              ...member,
                              id: member._id,
                              value: `${member.user.name} ${member.user.last_name}`,
                            }))
                          );
                        }
                      }}
                    >
                      {members.length === selectedContacts.length ? t("deselect_all_members") : t("select_all_members")}
                    </div>
                    <Select
                      name="member"
                      multiSelect={true}
                      isClearable={false}
                      label={t("contact_to_share_with")}
                      required={true}
                      placeholder={t("select_option")}
                      initialValues={selectedContacts}
                      items={members.map((member) => ({
                        ...member,
                        id: member._id,
                        value: `${member.user.name} ${member.user.last_name}`,
                      }))}
                      onSelect={(selected) => {
                        setSelectedContacts(selected);
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
        {(irm || (selectedContacts.length !== 0 && section === "contacts")) && (
          <>
            <div className="mb-6">
              <Card wrapperClassName={"text-xs border-2 border-dotted border-border-buttons-secondary"}>
                {t("share_dealflow_explanation_contact")}
              </Card>
            </div>
            <div className={"text-main mb-4 border-b border-main flex justify-between"}>
              <div className="text-lg font-semibold">{t("general_information")}</div>
              <div>
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    handleSelectGeneralInformation(
                      socialNetworks &&
                        web &&
                        country &&
                        projectStage &&
                        problem &&
                        solution &&
                        businessModelDescription &&
                        market
                    )
                  }
                >
                  {t(
                    socialNetworks &&
                      web &&
                      country &&
                      projectStage &&
                      problem &&
                      solution &&
                      businessModelDescription &&
                      market
                      ? "deselect_all"
                      : "select_all"
                  )}
                </div>
              </div>
            </div>
            <div className="mb-4">
              <Switch
                checked={name}
                onChange={async (checked) => {
                  setName(checked);
                }}
                text={t("name")}
                disabled={true}
                tooltip={t("information_required_to_share")}
              />
            </div>
            <div className="mb-4">
              <Switch
                checked={logo}
                onChange={async (checked) => {
                  setLogo(checked);
                }}
                text={t("logo")}
                disabled={true}
                tooltip={t("information_required_to_share")}
              />
            </div>
            <div className="mb-4">
              <Switch
                checked={brandStatement}
                onChange={async (checked) => {
                  setBrandStatement(checked);
                }}
                text={t("brand_statement")}
                disabled={true}
                tooltip={t("information_required_to_share")}
              />
            </div>
            <div className="mb-4">
              <Switch
                checked={investmentStage}
                onChange={async (checked) => {
                  setInvestmentStage(checked);
                }}
                text={t("investment_stage")}
                disabled={true}
                tooltip={t("information_required_to_share")}
              />
            </div>
            <div className="mb-4">
              <Switch
                checked={businessModel}
                onChange={async (checked) => {
                  setBusinessModel(checked);
                }}
                text={t("business_model")}
                disabled={true}
                tooltip={t("information_required_to_share")}
              />
            </div>
            <div className="mb-4">
              <Switch
                checked={sector}
                onChange={async (checked) => {
                  setSector(checked);
                }}
                text={t("sector")}
                disabled={true}
                tooltip={t("information_required_to_share")}
              />
            </div>
            <div className="mb-4">
              <Switch
                checked={socialNetworks}
                onChange={async (checked) => {
                  setSocialNetworks(checked);
                }}
                text={t("social_networks")}
              />
            </div>
            <div className="mb-4">
              <Switch
                checked={web}
                onChange={async (checked) => {
                  setWeb(checked);
                }}
                text={t("web")}
              />
            </div>
            <div className="mb-4">
              <Switch
                checked={country}
                onChange={async (checked) => {
                  setCountry(checked);
                }}
                text={t("country")}
              />
            </div>
            <div className="mb-4">
              <Switch
                checked={projectStage}
                onChange={async (checked) => {
                  setProjectStage(checked);
                }}
                text={t("project_stage")}
              />
            </div>
            <div className="mb-4">
              <Switch
                checked={problem}
                onChange={async (checked) => {
                  setProblem(checked);
                }}
                text={t("problem")}
              />
            </div>
            <div className="mb-4">
              <Switch
                checked={solution}
                onChange={async (checked) => {
                  setSolution(checked);
                }}
                text={t("solution")}
              />
            </div>
            <div className="mb-4">
              <Switch
                checked={businessModelDescription}
                onChange={async (checked) => {
                  setBusinessModelDescription(checked);
                }}
                text={t("business_model_description")}
              />
            </div>
            <div className="mb-4">
              <Switch
                checked={market}
                onChange={async (checked) => {
                  setMarket(checked);
                }}
                text={t("market")}
              />
            </div>
            <div className={"text-main mb-4 border-b border-main flex justify-between"}>
              <div className="text-lg font-semibold">{t("contact_information")}</div>
              <div>
                <div
                  className="cursor-pointer"
                  onClick={() => handleSelectContactInformation(personContact && emailContact && phoneContact)}
                >
                  {t(personContact && emailContact && phoneContact ? "deselect_all" : "select_all")}
                </div>
              </div>
            </div>
            <div className="mb-4">
              <Switch
                checked={personContact}
                onChange={async (checked) => {
                  setPersonContact(checked);
                }}
                text={t("contact_person")}
              />
            </div>
            <div className="mb-4">
              <Switch
                checked={emailContact}
                onChange={async (checked) => {
                  setEmailContact(checked);
                }}
                text={t("contact_email")}
              />
            </div>
            <div className="mb-4">
              <Switch
                checked={phoneContact}
                onChange={async (checked) => {
                  setPhoneContact(checked);
                }}
                text={t("main_contact_phone")}
              />
            </div>
            <div className={"text-main mb-4 border-b border-main flex justify-between"}>
              <div className="text-lg font-semibold">{t("financial_information")}</div>
              <div>
                <div
                  className="cursor-pointer"
                  onClick={() => handleSelectFinancialInformation(minimumTicket && investmentDesired && valuation)}
                >
                  {t(minimumTicket && investmentDesired && valuation ? "deselect_all" : "select_all")}
                </div>
              </div>
            </div>
            <div className="mb-4">
              <Switch
                checked={minimumTicket}
                onChange={async (checked) => {
                  setMinimumTicket(checked);
                }}
                text={t("minimum_ticket")}
              />
            </div>
            <div className="mb-4">
              <Switch
                checked={investmentDesired}
                onChange={async (checked) => {
                  setInvestmentDesired(checked);
                }}
                text={t("investment_desired")}
              />
            </div>
            <div className="mb-4">
              <Switch
                checked={valuation}
                onChange={async (checked) => {
                  setValuation(checked);
                }}
                text={t("valuation")}
              />
            </div>
            <div className={"flex justify-end mt-2 pt-2 border-t border-separator"}>
              <ButtonMain
                onClick={async () => {
                  if (!irm && selectedContacts.length === 0) {
                    addToast(t("contact_is_required"), { appearance: "error", autoDismiss: true });
                  } else {
                    // const validation = await trigger()
                    // if (validation) {
                    if (irm) {
                      onSubmit(section, {
                        // ...getValues(),
                        name,
                        logo,
                        brand_statement: brandStatement,
                        sector,
                        contact_person: personContact,
                        contact_email: emailContact,
                        contact_phone: phoneContact,
                        social_networks: socialNetworks,
                        web,
                        country,
                        project_stage: projectStage,
                        investment_stage: investmentStage,
                        business_model: businessModel,
                        problem,
                        solution,
                        business_model_description: businessModelDescription,
                        market,
                        minimum_ticket: minimumTicket,
                        investment_desired: investmentDesired,
                        valuation,
                      });
                    } else {
                      onSubmit(section, selectedContacts, {
                        // ...getValues(),
                        contact_person: personContact,
                        contact_email: emailContact,
                        contact_phone: phoneContact,
                        social_networks: socialNetworks,
                        web,
                        country,
                        project_stage: projectStage,
                        investment_stage: investmentStage,
                        business_model: businessModel,
                        problem,
                        solution,
                        business_model_description: businessModelDescription,
                        market,
                        minimum_ticket: minimumTicket,
                        investment_desired: investmentDesired,
                        valuation,
                      });
                    }
                    // } else {
                    //   addToast(t('form_field_error'), { appearance: 'error', autoDismiss: true })
                    // }
                  }
                }}
                text={t("share_deal")}
              />
            </div>
          </>
        )}
        {selectedContacts.length !== 0 && section === "members" && (
          <div className={"flex justify-end mt-2 pt-2 border-t border-separator"}>
            <ButtonMain
              onClick={async () => {
                onSubmit(section, selectedContacts, {});
              }}
              text={t("share_deal")}
            />
          </div>
        )}
      </SidePanel>
    </>
  );
};
