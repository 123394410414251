import { useTranslation } from "react-i18next";
import { Likert } from "components/ui/molecules/Likert";

export const LikertScore = ({ identifier, value, onChange }) => {
  const { t } = useTranslation();

  const options = [
    { id: "1", value: t("red") },
    { id: "2", value: t("orange") },
    { id: "3", value: t("green") },
  ];

  return (
    <div className="mb-2">
      <Likert id={identifier} value={value} options={options} onChange={onChange} />
    </div>
  );
};
