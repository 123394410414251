import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "components/ui/molecules/Modal";

import { Input } from "components/ui/atoms/Input";
import { FaCheck } from "react-icons/fa";
import { saturateColor } from "utils/utils";

import Config from "config";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { ButtonMain } from "components/ui/atoms/ButtonMain";

const CreateTagModal = ({ showModal, handleCloseModal, onSubmit, tag }) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const colorPallete = [
    "#212020",
    "#F1C40F",
    "#E74C3C",
    "#1ABC9C",
    "#1e72bd",
    "#8E44AD",
    "#00CC77",
    "#cc00aa",
    "#CD5C5C",
    "#FFD133",
    "#94FEF4",
    "#FF05E8",
    "#8BAD0D",
    "#0DA1AD",
    "#458199",
    "#CAD888",
    "#F38343",
    "#98A2AB",
    "#61A8E3",
  ];
  const [color, setColor] = useState("");

  useEffect(() => {
    if (tag) {
      setName(tag.name);
      const selectedColor = colorPallete.find((c) => c === tag.color);
      if (selectedColor) {
        setColor(selectedColor);
      } else {
        setColor(colorPallete[0]);
      }
    }
  }, [tag]);

  return (
    <Modal
      showCloseModal={true}
      onClose={handleCloseModal}
      showModal={showModal}
      paddingBottom="4"
      paddingTop="6"
      px="6"
      width="auto"
    >
      <h2 className={"font-semibold text-main"}>{tag ? t("edit_tag") : t("create_tag")}</h2>

      <div className="mt-4">
        <Input
          id="name"
          type="text"
          name="name"
          label={`${t("name")}*`}
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder={t("introduce_tag_name")}
          maxLength={50}
        />
      </div>
      <div>
        <label className="text-xs font-medium">{t("select_color")}</label>
        <div>
          {colorPallete.map((c) => (
            <div
              key={c}
              className="relative inline-block mt-4 mr-4 transition-all duration-200 scale-100 rounded-md cursor-pointer hover:scale-110"
              onClick={() => setColor(c)}
              style={{
                width: "20px",
                height: "20px",
                backgroundColor: c,
              }}
            >
              {c === color && (
                <FaCheck
                  className="absolute inline-block"
                  style={{
                    top: "3px",
                    left: "2px",
                    color: saturateColor(c, 0.7),
                  }}
                />
              )}
            </div>
          ))}
        </div>
      </div>

      <div className={"flex justify-end mt-4 border-t pt-2 border-separator"}>
        <ButtonMain
          text={tag ? t("update_tag") : t("create_tag")}
          disabled={!name || !color}
          onClick={() => onSubmit(name, color)}
        />
      </div>
    </Modal>
  );
};

export default CreateTagModal;
