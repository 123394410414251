import { Trans, useTranslation } from "react-i18next";
import { SidePanel } from "components/ui/objects/SidePanel";
import { QuestionsUtils } from "utils/questions-utils";
import TranslateUtils from "utils/translate-utils";
import useUser from "hooks/useUser";
import { FaFlag } from "react-icons/fa";
import { useEffect, useState } from "react";

const FormAnswersSection = ({ onClose, answer, redFlags, shared, rawAnswers, rawAnswer = null }) => {
  const { t } = useTranslation();
  const { userLanguage } = useUser();

  const [currentRedFlags, setCurrentRedFlags] = useState([]);

  useEffect(() => {
    if (!shared) {
      if (rawAnswer || rawAnswers) {
        const _rawAnswer = rawAnswer || rawAnswers.find((ra) => ra._id === answer.answers._id);
        const parsedRedFlags = [];

        for (const question of _rawAnswer.application.questions.filter((q) =>
          ["select", "multiselect", "boolean", "likert_scale", "number"].includes(q.question.type)
        )) {
          const redFlag = QuestionsUtils.checkIfRedFlag(
            question.question,
            _rawAnswer.application.questions.find((q) => q._id === question._id).answer,
            null,
            redFlags
          );
          if (redFlag) {
            parsedRedFlags.push(question.question._id);
          }
        }
        for (const question of _rawAnswer.application.questions.filter((q) =>
          ["questions_group"].includes(q.question.type)
        )) {
          question.question.questionsGroup.forEach((qg, index) => {
            const redFlag = QuestionsUtils.checkIfRedFlag(
              qg,
              _rawAnswer.application.questions.find((q) => q._id === question._id).answer,
              index,
              redFlags
            );
            if (redFlag) {
              parsedRedFlags.push(qg._id);
            }
          });
        }
        setCurrentRedFlags(parsedRedFlags);
      }
    }
  }, [rawAnswer, rawAnswers, answer, shared, redFlags]);

  return (
    <>
      <SidePanel onClose={onClose} title={t("detailed_answer")} width={"1/3"} showExpand={true}>
        {!shared && currentRedFlags.length !== 0 && (
          <div className="border border-red rounded-lg p-4 text-xs mb-2">
            <Trans
              i18nKey="startup_contains_red_flags"
              values={{ redFlags: currentRedFlags.length }}
              components={{ bold: <strong /> }}
            />
          </div>
        )}
        <p className="mb-2 font-semibold">{`${t("application_date")}: ${new Date(
          rawAnswer.updatedAt
        ).toLocaleDateString("en-GB")}`}</p>
        {answer.questions.map((question, index) => (
          <>
            {!["separator"].includes(question.question.type) && (
              <div key={index} className="flex flex-row border-b border-gray-lines py-4">
                {QuestionsUtils.getIcon(question.question.type, true)}

                <div className="flex-1 flex flex-row ml-2">
                  <div className="w-full flex-1 mr-2 font-semibold text-base">
                    <div className={`${!shared && currentRedFlags.includes(question.question._id) && "text-red"} flex`}>
                      {TranslateUtils.getTextByLang(userLanguage, question.question.title)}
                      {!shared && currentRedFlags.includes(question.question._id) && <FaFlag className="ml-2" />}
                    </div>
                    <div className="text-xxs text-gray">{t(question.question.type)}</div>
                    {question.question.type !== "image_selector" && (
                      <div className="text-xxs text-gray font-normal text-ellipsis w-full">
                        {question.question.options?.length > 0 &&
                          question.question.options.slice(0, 15).map((option, index) => (
                            <span key={index}>
                              {index > 0 && ", "}
                              {TranslateUtils.getTextByLang(userLanguage, option)}
                            </span>
                          ))}
                        {question.question.options?.length > 15 && (
                          <span className="ml-1">{`(+ ${question.question.options.length - 15})`}</span>
                        )}
                      </div>
                    )}
                    <div className="text-sm">{answer.answers[question.question._id]}</div>
                  </div>
                </div>
              </div>
            )}
          </>
        ))}
      </SidePanel>
    </>
  );
};

export default FormAnswersSection;
