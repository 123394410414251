import axios from "axios/index";
import Config from "../config";

const AccountService = {
  getUser: async() => axios.get(`${Config.ACCOUNT_API_URL}clients/users`),

  completeWelcomeSteps: async() =>
    axios.patch(`${Config.ACCOUNT_API_URL}clients/users/welcome-steps`),

  updateTours: async(data) => axios.patch(`${Config.ACCOUNT_API_URL}clients/users/tours`, data),

  accomplishProductUpdates: async() =>
    axios.post(`${Config.ACCOUNT_API_URL}clients/users/product-updates`),

  updateHelp: async(data) => axios.patch(`${Config.ACCOUNT_API_URL}clients/users/help`, data),

  handleSetup: async(setupType) =>
    axios.post(`${Config.ACCOUNT_API_URL}clients/setup`, { setup_type: setupType }),

  updateCompanyInfo: async(data) => axios.patch(`${Config.ACCOUNT_API_URL}clients/info`, data),

  updateEmailsLanguage: async(data) =>
    axios.patch(`${Config.ACCOUNT_API_URL}clients/emails-language`, data),

  updateCurrency: async(data) => axios.patch(`${Config.ACCOUNT_API_URL}clients/currency`, data),

  updateDealflowPreferences: async(data) =>
    axios.patch(`${Config.ACCOUNT_API_URL}clients/dealflow-preferences`, data),

  updatePersonalInfo: async(data) =>
    axios.patch(`${Config.ACCOUNT_API_URL}clients/users/info`, data),

  updateDealflowVisibility: async(data) =>
    axios.patch(`${Config.ACCOUNT_API_URL}clients/users/settings/dealflow-visibility`, data),

  updateLanguage: async(data) =>
    axios.patch(`${Config.ACCOUNT_API_URL}clients/users/settings/language`, data),

  updateStartupProfile: async(data) =>
    axios.patch(`${Config.ACCOUNT_API_URL}clients/users/settings/startup-profile`, data),

  getInvestorChildren: async(filters) => {
    const query = new URLSearchParams(filters);
    return axios.get(`${Config.ACCOUNT_API_URL}clients/children?${query}`);
  },

  createInvestorChildren: async(data) => axios.post(`${Config.ACCOUNT_API_URL}clients/children`, data),

  deleteInvestorChildren: async(data) => axios.delete(`${Config.ACCOUNT_API_URL}clients/children`, { params: data }),
};
export default AccountService;
