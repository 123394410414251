import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import PipelineManagersService from "services/pipeline-managers-service";
import { useNavigate } from "react-router-dom";
import { RoutesLinks } from "components/routes-links";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";

import { isEmpty } from "lodash";
import { useSwal } from "hooks/useSwal";
import Config from "config";
import { PageTitle } from "components/ui/atoms/PageTitle";
import { FaPlus, FaSearch } from "react-icons/fa";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { Modal } from "components/ui/molecules/Modal";
import BoardSection from "components/pipeline-manager/components/BoardSection";
import useUser from "hooks/useUser";
import SuggestedStep from "components/ui/molecules/SuggestedStep";
import Utils from "utils/utils";
import { MiniHelp } from "components/ui/atoms/MiniHelp";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { SkeletonList } from "components/ui/atoms/skeletons/SkeletonList";
import { BoardViewCard } from "./board-view-card";

const Boards = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(true);
  const [creating, setCreating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [updating, setUpdating] = useState(false);
  const { confirm } = useSwal();
  const [boards, setBoards] = useState([]);
  const [showUsedBoardError, setShowUsedBoardError] = useState(false);
  const [boardInEvents, setBoardInEvents] = useState([]);
  const { user, reloadUserInfo, userLanguage, checkIfLPhasAccessToSection } = useUser();
  const navigate = useNavigate();
  const handleRedirect = (url) => navigate(url);
  const [filterName, setFilterName] = useState("");

  const getPipelineManagers = useCallback(async() => {
    try {
      setLoading(true);

      const result = await PipelineManagersService.getPipelineManagers();
      if (!user.lp) {
        setBoards(result.data.data);
      } else {
        setBoards(
          result.data.data.filter((board) =>
            user.lp_permissions.permitted_boards.includes(board._id),
          ),
        );
      }
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key
          ? error.response.data.msg_key
          : t("error_occurred_retrieving_boards"),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
    } finally {
      setLoading(false);
    }
  }, [setBoards]);

  useEffect(() => {
    if (!user.lp || (user.lp && checkIfLPhasAccessToSection("pipeline_manager"))) {
      getPipelineManagers();
    } else {
      setLoading(false);
    }
  }, []);

  const confirmCreate = async({ name }) => {
    try {
      setCreating(true);
      setShowBoardSection(false);
      const result = await PipelineManagersService.createPipelineManager(name, []);
      // getPipelineManagers()
      reloadUserInfo();
      addToast(t("board_created_successfully"), {
        appearance: "success",
        autoDismiss: true,
      });
      handleRedirect(`${RoutesLinks.PIPELINE_MANAGER_LINK}boards/${result.data.data._id}`);
    } catch (error) {
      console.error(error);
      addToast(
        error.response && error.response.data.msg_key
          ? error.response.data.msg_key
          : t("error_occurred_creating_board"),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
    } finally {
      setCreating(false);
    }
  };

  const confirmUpdate = ({ name }) => {
    confirm({
      text: t("update_board_text"),
    }).then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          setUpdating(true);
          setShowBoardSection(false);
          await PipelineManagersService.updatePipelineManager(boardToEdit._id, { name });
          getPipelineManagers();
          addToast(t("board_updated_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
        } catch (error) {
          console.error(error);
          addToast(
            error.response && error.response.data.msg_key
              ? error.response.data.msg_key
              : t("error_occurred_creating_board"),
            {
              appearance: "error",
              autoDismiss: true,
            },
          );
        } finally {
          setBoardToEdit({});
          setUpdating(false);
        }
      }
    });
  };

  const deletePipelineManager = (board) => {
    confirm({
      text: t("delete_board_text"),
      icon: "warning",
    }).then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          setDeleting(true);
          await PipelineManagersService.deletePipelineManager(board._id);
          getPipelineManagers();
          addToast(t("board_deleted_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
        } catch (error) {
          if (error.response.status === 403) {
            setShowUsedBoardError(true);
            setBoardInEvents(error.response.data.extra.events);
          } else {
            addToast(
              error.response
                ? error.response.data.msg_key
                  ? t(error.response.data.msg_key)
                  : t("error_occurred")
                : t("error_occurred_deleting_board"),
              {
                appearance: "error",
                autoDismiss: true,
              },
            );
          }
        } finally {
          setDeleting(false);
        }
      }
    });
  };

  const [showBoardSection, setShowBoardSection] = useState(false);

  const [boardToEdit, setBoardToEdit] = useState({});

  const handleEditBoard = (board) => {
    setShowBoardSection(true);
    setBoardToEdit({ name: board.name, _id: board._id });
  };

  const [showSuggestedStep, setShowSuggestedStep] = useState(false);

  const handlecreatePipelineManager = () => {
    if (
      boards?.length ||
      !!user?.onboarding_tasks.includes("create_pipeline_manager") ||
      !!user?.investor?.onboarding_tasks?.includes("create_pipeline_manager") ||
      Utils.checkIfPreviousStepsArCompleted(
        user?.onboarding_tasks,
        user?.investor?.onboarding_tasks,
        "create_pipeline_manager",
      )
    ) {
      setShowBoardSection(true);
    } else {
      setShowSuggestedStep(true);
    }
  };

  // Filter board
  const handleFilterBoards = () => {
    const _boards = boards.map((board) => ({
      ...board,
      featured: filterName.length
        ? board.columns.some((column) =>
          column.cards.some((card) => card.dealflow.name.toLowerCase().includes(filterName.toLowerCase())),
        )
        : false,
    }));
    setBoards(_boards);
  };

  return (
    <>
      {showSuggestedStep && (
        <SuggestedStep
          action={"create_pipeline_manager"}
          showSuggestedStep={!!showSuggestedStep}
          setShowSuggestedStep={setShowSuggestedStep}
          continueAction={() => {
            setShowSuggestedStep(false);
            setShowBoardSection(true);
          }}
          continueText={t("create_board")}
        />
      )}
      {showUsedBoardError && (
        <Modal
          showCloseModal={true}
          showModal={showUsedBoardError}
          paddingBottom="2"
          paddingTop="6"
          onClose={() => {
            setShowUsedBoardError(false);
            setBoardInEvents([]);
          }}
        >
          <h2 className="font-semibold text-xl mt-4">{t("error_occurred_deleting_board")}</h2>
          <p className="font-medium text-sm text-gray-dark">
            {t("board_is_being_used_in_some_automations")}
          </p>
          <p className="font-medium text-sm text-gray-dark mt-6 mb-4">
            {t("delete_board_automation_previously")}
          </p>
          <div className="text-sm  text-justify font-normal w-full px-10 mb-6">
            <ul className="list-disc">
              {boardInEvents.map((q, index) => (
                  <li key={index} className="mb-1">
                    {q}
                  </li>
              ))}
            </ul>
          </div>
        </Modal>
      )}

      <div className="flex items-start">
        <div className="flex-1">
          <PageTitle title={t("pipeline_manager")} />
        </div>
        <div className="flex gap-2 mr-4">
          <input
            className={
              "shadow-soft-white border border-gray-lines focus:border-main bg-input w-full py-2 px-7 relative z-10 text-left text-xs font-normal rounded-2xl placeholder-gray cursor-pointer transition-all duration-200 outline-none hover:border-main hover:outline-none hover:shadow-inner focus:outline-none focus:shadow-focus active:outline-none"
            }
            onChange={(e) => {
              setFilterName(e.currentTarget.value);
            }}
            value={filterName}
          />
          <ButtonSecondary
            iconComponent={<FaSearch className="h-4" />}
            onClick={handleFilterBoards}
          />
        </div>
        {!user?.help?.configure_pipeline_manager && !user.lp && (
          <MiniHelp item={"configure_pipeline_manager"} />
        )}
        {!user.lp && (
          <ButtonMain
            iconComponent={<FaPlus className="inline-block mr-2" />}
            text={t("create_board")}
            onClick={handlecreatePipelineManager}
          />
        )}
      </div>

      {(loading || creating || deleting || updating) && <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4 mt-6"><SkeletonList length={5} style="rounded-xl h-96"/></div>}
      {showBoardSection && (
        <BoardSection
          onSubmit={isEmpty(boardToEdit) ? confirmCreate : confirmUpdate}
          showModal={showBoardSection}
          handleCloseModal={() => {
            setShowBoardSection(false);
            setBoardToEdit({});
          }}
          initialValues={boardToEdit}
        />
      )}
      {boards.length !== 0 && (
        <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4 mt-6">
          {boards.map((board, index) => (
              <BoardViewCard key={index} board={board} deletePipelineManager={deletePipelineManager} handleEditBoard={handleEditBoard} handleRedirect={handleRedirect} user={user}/>
          ))}
        </div>
      )}
      {boards.length === 0 &&
        (!user.lp || (user.lp && checkIfLPhasAccessToSection("pipeline_manager"))) &&
        !loading && (
          <>
            <div className="my-10">
              <img
                src={`${Config.GUIDE_STEPS_BACKGROUND_IMAGES_BUCKET}pipeline_manager_${userLanguage}.png`}
                className="w-auto"
              />
            </div>
          </>
      )}
      {user.lp && !checkIfLPhasAccessToSection("pipeline_manager") && (
        <div className="my-6">
          <NoDataInfo title={t("access_forbidden")} />
        </div>
      )}
    </>
  );
};

export default Boards;
