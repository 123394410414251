import { Loader } from "components/ui/molecules/Loader";
import { useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { CreateCustomFieldForm } from "./components/CreateCustomFieldForm";
import { Card } from "components/ui/atoms/Card";
import { ButtonDanger } from "components/ui/atoms/ButtonDanger";
import { FaAsterisk, FaEdit, FaExclamationCircle, FaTrash } from "react-icons/fa";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import ReactTooltip from "react-tooltip";
import { useToasts } from "react-toast-notifications";
import { useSwal } from "hooks/useSwal";
import { SidePanel } from "components/ui/objects/SidePanel";
import { QuestionsUtils } from "utils/questions-utils";
import Config from "config";

import useUser from "hooks/useUser";
import { CustomFieldsService } from "services/custom-fields-service";
import { useOutletContext } from "react-router-dom";

export const CustomFieldsDealFlow = (props) => {
  const { ref } = useOutletContext();

  const { t } = useTranslation();
  const { confirm } = useSwal();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(true);
  const [customFields, setCustomFields] = useState([]);
  const [customFieldToEdit, setCustomFieldToEdit] = useState(null);
  const [showCustomFieldsForm, setShowCustomFieldsForm] = useState(false);
  const { user, userLanguage } = useUser();
  const getCustomFields = () => {
    setLoading(true);

    CustomFieldsService.getCustomFields()
      .then((response) => {
        setCustomFields(response.data.data);
        ReactTooltip.rebuild();
      })
      .catch((error) => {
        addToast(
          error.response && error.response.data.msg_key
            ? error.response.data.msg_key
            : t("error_occurred_retrieving_custom_fields"),
          {
            appearance: "error",
            autoDismiss: true,
          },
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useImperativeHandle(ref, () => ({
    handleCreateCustomField: () => {
      setCustomFieldToEdit(null);
      setShowCustomFieldsForm(true);
    },
  }));

  const onModalSubmit = (data) => {
    if (customFieldToEdit) {
      CustomFieldsService.updateCustomField(customFieldToEdit._id, data).then((response) => {
        setCustomFieldToEdit(null);
        setShowCustomFieldsForm(false);
        getCustomFields();
      });
    } else {
      CustomFieldsService.createCustomField(data).then((response) => {
        setCustomFieldToEdit(null);
        setShowCustomFieldsForm(false);
        getCustomFields();
      });
    }
  };

  const deleteCustomField = (id) => {
    confirm({
      text: t("delete_custom_field_text"),
      icon: "warning",
    }).then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await CustomFieldsService.deleteCustomField(id);
          getCustomFields();
          addToast(t("custom_field_deleted_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
        } catch (error) {
          addToast(
            error.response && error.response.data.msg_key
              ? error.response.data.msg_key
              : t("error_occurred_deleting_custom_field"),
            {
              appearance: "error",
              autoDismiss: true,
            },
          );
        } finally {
          setCustomFieldToEdit(null);
          setLoading(false);
        }
      }
    });
  };

  const handleEditCustomField = (customField) => {
    setCustomFieldToEdit(customField);
    setShowCustomFieldsForm(true);
  };

  useEffect(() => {
    getCustomFields();
  }, []);

  return (
    <>
      {customFields.length !== 0 && (
        <div className="grid grid-cols-2 gap-4 mb-4 my-10">
          {customFields.map((customField) => (
            <Card key={customField._id}>
              <div className="flex flex-col">
                <div className="flex flex-row">
                  {QuestionsUtils.getIcon(customField.type, false)}
                  <div className="flex-1 flex flex-row ml-2">
                    <div className="w-full flex-1 mr-2 font-semibold text-sm">
                      {customField.name}
                      {customField.important && (
                        <FaExclamationCircle
                          className="inline ml-2"
                          data-tip={t("important_field")}
                        />
                      )}
                      {customField.required && (
                        <FaAsterisk className="inline ml-2" data-tip={t("required_field")} />
                      )}
                      <div className="text-xxs text-gray">{t(customField.type)}</div>
                      <div className="text-xxs text-gray font-normal text-ellipsis w-full">
                        {["select", "multiselect"].includes(customField.type) &&
                          customField.options?.length > 0 &&
                          customField.options.map((option, index) => (
                            <span key={index}>
                              {index > 0 && ", "}
                              {option.value}
                            </span>
                          ))}
                      </div>
                    </div>
                    <div className="flex flex-row justify-center items-start">
                      {!user.lp && (
                        <div className="mr-2" data-tip={t("delete")}>
                          <ButtonDanger
                            onClick={() => deleteCustomField(customField._id)}
                            iconComponent={<FaTrash className="inline" />}
                          />
                        </div>
                      )}

                      {!user.lp && (
                        <div data-tip={t("edit")}>
                          <ButtonSecondary
                            onClick={() => handleEditCustomField(customField)}
                            iconComponent={<FaEdit className={"inline"} />}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          ))}
        </div>
      )}
      {customFields.length === 0 && !loading && (
        <div className="my-10">
          <img
            src={`${Config.GUIDE_STEPS_BACKGROUND_IMAGES_BUCKET}dealflow_custom_fields_${userLanguage}.png`}
            className="w-auto"
          />
        </div>
      )}
      {showCustomFieldsForm && (
        <SidePanel
          onClose={() => setShowCustomFieldsForm(false)}
          title={customFieldToEdit ? t("update_custom_field") : t("create_custom_field")}
          width="1/4"
        >
          <CreateCustomFieldForm customField={customFieldToEdit} onSubmit={onModalSubmit} />
          {/* <pre>
                {JSON.stringify(customFieldToEdit, null, 2)}
              </pre> */}
        </SidePanel>
      )}
      {loading && <Loader />}
    </>
  );
};
