import { useTranslation } from "react-i18next";
import { RoutesLinks } from "components/routes-links";
import { useNavigate } from "react-router-dom";
import { useTour } from "hooks/useTour";
import useUser from "./useUser";
import { useSwal } from "./useSwal";

export function useOnboardingSteps() {
  const { user } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const tour = useTour("quick_tour");

  const { alert } = useSwal();

  const ONBOARDING_TASKS = {
    default: [
      {
        title: t("onboarding_task_activate_account"),
        completed: true,
        section: "ot_general",
        id: "activate_account",
      },
      {
        title: t("onboarding_task_take_tour"),
        description: t("onboarding_task_take_tour_description"),
        completed: !!user?.onboarding_tasks?.includes("take_quick_tour"),
        onClick: () => tour.start(),
        section: "ot_general",
        id: "take_quick_tour",
      },
      {
        title: t("onboarding_task_configure_investment_thesis"),
        description: t("onboarding_task_configure_investment_thesis_description"),
        completed:
          !!user?.onboarding_tasks?.includes("configure_investment_thesis") ||
          !!user?.investor?.onboarding_tasks?.includes("configure_investment_thesis"),
        onClick: () => navigate(RoutesLinks.COMPANY_INVESTMENT_THESIS_LINK),
        section: "ot_dealflow_caption",
        id: "configure_investment_thesis",
      },
      {
        title: t("onboarding_task_create_pipeline_manager"),
        description: t("onboarding_task_create_pipeline_manager_description"),
        completed:
          !!user?.onboarding_tasks?.includes("create_pipeline_manager") ||
          !!user?.investor?.onboarding_tasks?.includes("create_pipeline_manager"),
        onClick: () => navigate(`${RoutesLinks.PIPELINE_MANAGER_LINK}boards`),
        section: "ot_dealflow_management",
        id: "create_pipeline_manager",
      },
      {
        title: t("onboarding_task_add_startup_to_pool"),
        description: t("onboarding_task_add_startup_to_pool_description"),
        completed:
          !!user?.onboarding_tasks?.includes("add_startup_to_pool") ||
          !!user?.investor?.onboarding_tasks?.includes("add_startup_to_pool"),
        onClick: () => navigate(`${RoutesLinks.DEALFLOW_LINK}`),
        section: "ot_dealflow_management",
        id: "add_startup_to_pool",
      },
      {
        title: t("onboarding_task_create_questionnaire"),
        description: t("onboarding_task_create_questionnaire_description"),
        completed:
          !!user?.onboarding_tasks?.includes("create_questionnaire") ||
          !!user?.investor?.onboarding_tasks?.includes("create_questionnaire"),
        onClick: () => navigate(RoutesLinks.QE_FORMS_LINK),
        section: "ot_dealflow_management",
        id: "create_questionnaire",
      },
      {
        title: t("onboarding_task_publish_questionnaire"),
        description: t("onboarding_task_publish_questionnaire_description"),
        completed:
          !!user?.onboarding_tasks?.includes("publish_questionnaire") ||
          !!user?.investor?.onboarding_tasks?.includes("publish_questionnaire"),
        onClick: () => {
          if (user?.investor?.onboarding_tasks?.includes("create_questionnaire")) {
            navigate(RoutesLinks.QE_FORMS_LINK);
          } else {
            alert({ text: t("onboarding_task_publish_questionnaire_pending_tasks") });
          }
        },
        section: "ot_dealflow_management",
        id: "publish_questionnaire",
      },
      {
        title: t("onboarding_task_create_score_card"),
        description: t("onboarding_task_create_score_card_description"),
        completed:
          !!user?.onboarding_tasks?.includes("create_score_card") ||
          !!user?.investor?.onboarding_tasks?.includes("create_score_card"),
        onClick: () => navigate(RoutesLinks.SCORE_CARDS_LINK),
        section: "ot_dealflow_analysis",
        id: "create_score_card",
      },
      {
        title: t("onboarding_task_create_evaluation_process"),
        description: t("onboarding_task_create_evaluation_process_description"),
        completed:
          !!user?.onboarding_tasks?.includes("create_evaluation_process") ||
          !!user?.investor?.onboarding_tasks?.includes("create_evaluation_process"),
        onClick: () => navigate(RoutesLinks.EVALUATION_PROCESSES_LINK),
        section: "ot_dealflow_analysis",
        id: "create_evaluation_process",
      },
      {
        title: t("onboarding_task_add_judge_to_evaluation_process"),
        description: t("onboarding_task_add_judge_to_evaluation_process_description"),
        completed:
          !!user?.onboarding_tasks?.includes("add_judge_to_evaluation_process") ||
          !!user?.investor?.onboarding_tasks?.includes("add_judge_to_evaluation_process"),
        onClick: () => {
          if (
            (user?.onboarding_tasks?.includes("add_startup_to_pool") ||
              user?.investor?.onboarding_tasks?.includes("add_startup_to_pool")) &&
            (user?.onboarding_tasks?.includes("create_evaluation_process") ||
              user?.investor?.onboarding_tasks?.includes("create_evaluation_process"))
          ) {
            navigate(RoutesLinks.EVALUATION_PROCESSES_LINK);
          } else {
            alert({ text: t("onboarding_task_add_judge_to_evaluation_process_pending_tasks") });
          }
        },
        section: "ot_dealflow_analysis",
        id: "add_judge_to_evaluation_process",
      },
      {
        title: t("onboarding_task_add_startup_to_evaluation_process"),
        description: t("onboarding_task_add_startup_to_evaluation_process_description"),
        completed:
          !!user?.onboarding_tasks?.includes("add_startup_to_evaluation_process") ||
          !!user?.investor?.onboarding_tasks?.includes("add_startup_to_evaluation_process"),
        onClick: () => {
          if (
            (user?.onboarding_tasks?.includes("add_startup_to_pool") ||
              user?.investor?.onboarding_tasks?.includes("add_startup_to_pool")) &&
            (user?.onboarding_tasks?.includes("create_evaluation_process") ||
              user?.investor?.onboarding_tasks?.includes("create_evaluation_process")) &&
            (user?.onboarding_tasks?.includes("add_judge_to_evaluation_process") ||
              user?.investor?.onboarding_tasks?.includes("add_judge_to_evaluation_process"))
          ) {
            navigate(RoutesLinks.EVALUATION_PROCESSES_LINK);
          } else {
            alert({ text: t("onboarding_task_add_startup_to_evaluation_process_pending_tasks") });
          }
        },
        section: "ot_dealflow_analysis",
        id: "add_startup_to_evaluation_process",
      },
      {
        title: t("onboarding_task_evaluate_startup"),
        description: t("onboarding_task_evaluate_startup_description"),
        completed:
          !!user?.onboarding_tasks?.includes("evaluate_startup") ||
          !!user?.investor?.onboarding_tasks?.includes("evaluate_startup"),
        onClick: () => {
          if (
            (user?.onboarding_tasks?.includes("add_startup_to_pool") ||
              user?.investor?.onboarding_tasks?.includes("add_startup_to_pool")) &&
            (user?.onboarding_tasks?.includes("create_score_card") ||
              user?.investor?.onboarding_tasks?.includes("create_score_card")) &&
            (user?.onboarding_tasks?.includes("create_evaluation_process") ||
              user?.investor?.onboarding_tasks?.includes("create_evaluation_process")) &&
            (user?.onboarding_tasks?.includes("add_judge_to_evaluation_process") ||
              user?.investor?.onboarding_tasks?.includes("add_judge_to_evaluation_process")) &&
            (user?.onboarding_tasks?.includes("add_startup_to_evaluation_process") ||
              user?.investor?.onboarding_tasks?.includes("add_startup_to_evaluation_process"))
          ) {
            navigate(RoutesLinks.EVALUATION_PROCESSES_LINK);
          } else {
            alert({ text: t("onboarding_task_evaluate_startup_pending_tasks") });
          }
        },
        section: "ot_dealflow_analysis",
        id: "evaluate_startup",
      },
      {
        title: t("onboarding_task_create_first_automation"),
        description: t("onboarding_task_create_first_automation_description"),
        completed:
          !!user?.onboarding_tasks?.includes("create_automation") ||
          !!user?.investor?.onboarding_tasks?.includes("create_automation"),
        onClick: () => {
          if (
            user?.onboarding_tasks?.includes("create_pipeline_manager") ||
            user?.investor?.onboarding_tasks?.includes("create_pipeline_manager")
          ) {
            navigate(`${RoutesLinks.PIPELINE_MANAGER_LINK}boards`);
          } else {
            alert({ text: t("onboarding_task_automations_pending_tasks") });
          }
        },
        section: "ot_automations",
        id: "create_automation",
      },
    ],
  };

  const getTasks = (section) => {
    if (!user) {
      return [];
    }

    return !section
      ? ONBOARDING_TASKS.default
      : ONBOARDING_TASKS.default.filter((task) => task.section === section);
  };

  return {
    getTasks: (section) => getTasks(section),
    getTaskProgress: () => {
      const tasks = getTasks();
      return Math.round((tasks.filter((t) => t.completed).length / tasks.length) * 100);
    },
  };
}
