/* eslint-disable quotes */
import { useEffect, useState } from "react";
import { Card } from "components/ui/atoms/Card";
import { DataGrid } from "components/ui/objects/DataGrid";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
import { FaEye, FaShareAlt } from "react-icons/fa";
import { Loader } from "components/ui/molecules/Loader";
import { useNavigate, useOutletContext } from "react-router-dom";
import { RoutesLinks } from "components/routes-links";
import { useSwal } from "../../hooks/useSwal";
import { DealflowService } from "services/dealflow-service";
import { isEqual } from "lodash";
import useUser from "hooks/useUser";

const DealflowInterestedView = () => {
  const { deal } = useOutletContext();

  const { addToast } = useToasts();
  const { confirm } = useSwal();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useUser();

  const isMember = !!user.member;

  const [members, setMembers] = useState([]);
  const [interestedCount, setInterestedCount] = useState(0);
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 9999,
    totalPages: 0,
    totalDocs: 0,
  });
  const [loading, setLoading] = useState(false);

  const handleShareMember = async(member) => {
    try {
      confirm().then(async() => {
        setLoading(true);
        await DealflowService.shareDealflow(deal._id, {
          section: "members",
          contacts: [
            {
              _id: member._id,
            },
          ],
        });
        await getMembers();
        addToast(t("deal_shared_successfully"), {
          appearance: "success",
          autoDismiss: true,
        });
      });
    } catch (error) {
      addToast(
        error?.response?.data?.msg_key
          ? t(error.response.data.msg_key)
          : t("error_occurred_sharing_deal"),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
    } finally {
      setLoading(false);
    }
  };

  // Borra el deal específico
  const handleStopSharingMember = async(member) => {
    try {
      confirm().then(async() => {
        setLoading(true);
        await DealflowService.deleteDeal(member.dealflow._id);
        await getMembers();
        addToast(t("deal_shared_stoped_successfully"), {
          appearance: "success",
          autoDismiss: true,
        });
      });
    } catch (error) {
      addToast(
        error?.response?.data?.msg_key
          ? t(error.response.data.msg_key)
          : t("error_occurred_sharing_deal"),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const getMembers = async() => {
    try {
      setLoading(true);
      const result = await DealflowService.getDealMembers(deal._id);
      const { members, pagination: responsePagination, interestedCount } = result.data.data;

      if (responsePagination) {
        const newPagination = {
          ...pagination,
          page:
            responsePagination.current >= responsePagination.pages
              ? responsePagination.pages - 1
              : responsePagination.current - 1,
          totalPages: responsePagination.pages,
          totalDocs: responsePagination.totalDocs,
        };

        if (!isEqual(newPagination, pagination)) {
          setPagination(newPagination);
        }

        setInterestedCount(interestedCount);
      }
      setMembers(members);
    } catch (error) {
      // addToast(t('error_occurred_retrieving_members'), { appearance: 'error', autoDismiss: true })
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMembers();
  }, [pagination]);

  const headerClass = "text-main font-bold text-sm border-b border-main text-left bg-white";

  return (
    <>
      {loading && <Loader />}
      {!loading && members.length <= 0 && (
        <div className="my-6">
          {isMember ? (
            <p className="text-xl">{t("no_shared_members")}</p>
          ) : (
            <p className="text-xl">{t("no_members")}</p>
          )}
        </div>
      )}
      {!loading && members.length > 0 && (
        <>
          <h3 className="mt-6">{t("interested_members_count", { count: interestedCount })}</h3>
          <div className="mt-6">
            <Card>
              <DataGrid
                agPagination={true}
                actionsHeaderClassName={headerClass}
                data={members.map((d) => ({ ...d, id: d._id }))}
                headers={[
                  {
                    key: "member",
                    title: t("member"),
                    headerClassName: headerClass,
                    render: (key, member) => <>{member.user?.name}</>,
                  },
                  {
                    key: "member_email",
                    title: t("email"),
                    headerClassName: headerClass,
                    render: (key, member) => <>{member.user?.email}</>,
                  },
                  {
                    key: "shared",
                    title: t("shared"),
                    headerClassName: headerClass,
                    render: (key, member) => <>{member.dealflow ? t("yes") : t("no")}</>,
                  },
                  {
                    key: "member_interested",
                    title: t("its_interested"),
                    headerClassName: headerClass,
                    render: (key, member) => <>{member.dealflow.interesting ? t("yes") : t("no")}</>,
                  },
                ]}
                actions={[
                  {
                    id: "details",
                    tip: t("see_member"),
                    onClick: (member) => {
                      navigate(`${RoutesLinks.MEMBERS_LINK}${member._id}`);
                    },
                    show: (document) => true,
                    buttonProps: {
                      iconComponent: <FaEye />,
                    },
                  },
                  {
                    id: "share",
                    tip: t("share"),
                    show: (member) => !member.dealflow,
                    onClick: (member) => handleShareMember(member),
                    buttonProps: {
                      iconComponent: <FaShareAlt />,
                    },
                  },
                  {
                    id: "stop_sharing",
                    tip: t("stop_sharing"),
                    show: (member) => member.dealflow,
                    onClick: (member) => handleStopSharingMember(member),
                    buttonProps: {
                      iconComponent: <FaShareAlt />,
                    },
                  },
                ]}
              />
              {/**
             <Pagination
                paginateOptions={[12, 24, 36]}
                currentPage={pagination.page}
                setCurrentPage={(page) => setPagination({ ...pagination, page })}
                perPage={pagination.rowsPerPage}
                pages={pagination.totalPages}
                setPerPage={(value) => setPagination({ ...pagination, page: 0, rowsPerPage: value[0].id })}
              />
              */}
            </Card>
          </div>
        </>
      )}
    </>
  );
};

export default DealflowInterestedView;
