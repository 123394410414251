import Config from "config";

const FilterBadge = ({ count }) => (
    <div className="absolute flex items-center justify-center w-6 h-6 text-white bg-yellow-400 rounded-full -right-1 -top-1">
      {count}
    </div>
);

/**
 * @param onClick
 * @param type
 * @param marginRight
 * @param marginLeft
 * @param icon
 * @param iconComponent
 * @param text
 * @param disabled
 * @returns {JSX.Element}
 * @constructor
 */
export function ButtonSecondary({
  onClick,
  type,
  marginRight = "0",
  marginLeft = "0",
  marginTop = "0",
  icon,
  width = "auto",
  iconComponent,
  text,
  disabled,
  badgeContent,
  badgeIcon,
  badgeBgColor,
}) {
  return (
    <div className="relative inline-block">
      <button
        onClick={onClick}
        type={type}
        disabled={disabled}
        className={`text-center block w-${width} mr-${marginRight} ml-${marginLeft} py-2 px-4 mt-${marginTop}
            text-xs font-semibold text-text-buttons-secondary placeholder-gray bg-bg-buttons-secondary rounded-2xl 
            shadow-soft-white cursor-pointer transition-all duration-500 ease-in-out border border-border-buttons-secondary
            hover:bg-buttons-secondary-hover hover:text-buttons-secondary hover:shadow-hover
            focus:outline-none hover:shadow-inner`}
      >
        {icon && <img src={icon} alt="Icon" className={"inline | mr-2 | w-auto"} />}
        {iconComponent && iconComponent}
        {text}
      </button>
      {/** Updated style and added badgeBgColor to handle multiple background colors */}
      {badgeContent && (
        <div
          className={`absolute flex items-center justify-center w-6 h-6 text-white rounded-full -right-2 -top-2 ${!badgeBgColor && "bg-yellow-400"}`}
          style={{ backgroundColor: badgeBgColor }}
        >
          {badgeIcon ? <img src={badgeIcon} alt="Badge Icon" /> : badgeContent}
        </div>
      )}
    </div>
  );
}
