import { useEffect, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import { useTranslation } from "react-i18next";
import "components/pipeline-manager/components/board/pipeline.css";
import { useToasts } from "react-toast-notifications";
import { Loader } from "components/ui/molecules/Loader";
import { useSwal } from "hooks/useSwal";
import DealflowTableColumn from "./DealflowTableColumn";
import { findIndex } from "lodash";
import { FaExpandAlt, FaEye, FaShareAlt } from "react-icons/fa";
import Config from "config";
import DealflowTableColumnSection from "./DealflowTableColumnSection";
import { DealflowCustomTableService } from "services/dealflow-custom-table-service";
import BasicFields from "assets/json/basic-fields.json";
import Avatar from "react-avatar";

const DealflowTable = ({
  dealflow = [],
  columns = [],
  setDealToResume,
  goToProfile,
  getCustomTable,
  customFields = [],
  setDealToShare,
}) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { confirm } = useSwal();
  const [loading, setLoading] = useState(false);
  const [showColumnSection, setShowColumnSection] = useState(false);
  const [isSortingColumns, setIsSortingColumns] = useState(null);
  const [updatedSort, setUpdatedSort] = useState([]);
  const [updatedElement, setUpdatedElement] = useState(null);
  const [currentFieldsIds, setCurrentFieldsIds] = useState([]);

  const BASIC_FIELDS = BasicFields.map((basifField) => ({ id: basifField, value: t(basifField) }));

  useEffect(() => {
    if (columns.length) {
      setCurrentFieldsIds(
        columns.map((column) =>
          column.general_field ? column.general_field : column.custom_field._id,
        ),
      );
    }
  }, [columns]);
  const deleteColumn = (column) => {
    confirm({
      title: t("are_you_sure"),
    }).then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await DealflowCustomTableService.deleteColumn(column.id);
          getCustomTable();
          addToast(t("column_deleted_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
        } catch (error) {
          addToast(
            error.response
              ? error.response.data.msg_key
                ? t(error.response.data.msg_key)
                : t("error_occurred")
              : t("error_occurred_deleting_stage"),
            {
              appearance: "error",
              autoDismiss: true,
            },
          );
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const createColumn = () => {
    setShowColumnSection(true);
  };

  const confirmCreateColumn = async({ field: fieldId }) => {
    try {
      setLoading(true);
      await DealflowCustomTableService.addColumn(
        fieldId,
        !BASIC_FIELDS.find((field) => field.id === fieldId),
      );
      getCustomTable();
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key
          ? error.response.data.msg_key
          : t("error_occurred_creating_column"),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
    } finally {
      setLoading(false);
      setShowColumnSection(false);
    }
  };

  useEffect(() => {
    const orderColumns = async(newIndex) => {
      try {
        setLoading(true);
        await DealflowCustomTableService.updateColumn(updatedElement._id, {
          order: newIndex,
        });
        getCustomTable();
      } catch (error) {
        addToast(
          error.response && error.response.data.msg_key
            ? error.response.data.msg_key
            : t("error_occurred_updating_column"),
          {
            appearance: "error",
            autoDismiss: true,
          },
        );
      } finally {
        setLoading(false);
      }
    };

    if (isSortingColumns === false) {
      setIsSortingColumns(null);
      const newIndex = findIndex(updatedSort, (column) => column._id === updatedElement._id);
      const oldIndex = findIndex(columns, (column) => column._id === updatedElement._id);
      if (newIndex !== oldIndex) {
        orderColumns(newIndex);
      }
      setUpdatedElement(null);
    } else if (isSortingColumns) {
      setUpdatedElement(updatedSort[findIndex(updatedSort, (column) => column.chosen === true)]);
    }
  }, [isSortingColumns]);

  const getColumnValue = (column, item) => {
    if (column.general_field) {
      if (column.general_field === "name") {
        return (
          <>
            <Avatar
              src={item.logo}
              size="20"
              round={true}
              color="#e0e6f2"
              fgColor="#4d70b3"
              alt={t("logo")}
              className="mr-2"
            />
            {item[column.general_field]}
          </>
        );
      }
      if (
        [
          "sector",
          "business_model",
          "project_stage",
          "country_incorporation",
          "investment_stage",
        ].includes(column.general_field)
      ) {
        return item[column.general_field] ? t(item[column.general_field].key) : "";
      }
      return item[column.general_field] || "";
    } else if (column.customFieldId) {
      const cf = item.custom_fields?.find(
        (customField) => customField.custom_field._id === column.customFieldId,
      );
      if (["short_answer", "long_answer"].includes(cf?.custom_field?.type)) {
        return cf?.value;
      } else if (["select", "multiselect"].includes(cf?.custom_field?.type)) {
        return cf.options
          ?.map((option) => cf?.custom_field?.options.find((o) => o._id === option)?.value)
          .join(", ");
      }
    }
    return "";
  };

  return (
    <>
      <div className="h-full w-full mt-6">
        <div className="h-full w-full overflow-x-auto overflow-y-auto pb-6">
          <div className="flex items-start">
            <ReactSortable
              handle=".drag-handle" // Use the handle class
              onStart={() => setIsSortingColumns(true)}
              onEnd={() => setIsSortingColumns(false)}
              list={columns}
              setList={setUpdatedSort}
              group="root"
              animation={150}
              className="flex items-start"
              chosenClass="sortable-choosen-column"
            >
              {columns.length !== 0 &&
                columns
                  .map((column) => ({
                    name: column.custom_field
                      ? column.custom_field.name
                      : t(column.general_field),
                    general_field: column.general_field,
                    customFieldId: column.custom_field?._id,
                    id: column._id,
                  }))
                  .map((column, indexColumn) => (
                    <DealflowTableColumn
                      index={indexColumn}
                      key={column.id}
                      column={column}
                      ListTitle={column.name}
                      deleteColumn={!column.hasOwnProperty("deleteable") ? deleteColumn : null}
                      actions={column.actions}
                    >
                      {dealflow.map((item, indexRow) => (
                          <div
                            key={`column_${indexColumn}_row_${indexRow}`}
                            className={`px-2 my-2 items-center ${indexRow < dealflow.length - 1 && "border-b border-gray-lines"}`}
                          >
                            <div
                              className={`py-3 overflow-hidden text-ellipsis whitespace-nowrap ${column.general_field === "name" && "cursor-pointer font-bold"}`}
                              onClick={
                                column.general_field === "name" ? () => goToProfile(item) : null
                              }
                            >
                              {getColumnValue(column, item) || <div>&nbsp;</div>}
                            </div>
                          </div>
                      ))}
                    </DealflowTableColumn>
                  ))}
            </ReactSortable>
            {columns.length !== 0 && (
              <DealflowTableColumn
                key={"actions"}
                ListTitle={t("actions")}
                index={dealflow.length + 1}
                actions={true}
              >
                {dealflow.map((item, indexRow) => (
                    <div
                      key={`column_actions_row_${indexRow}`}
                      className={`px-2 my-2 items-center ${indexRow < dealflow.length - 1 && "border-b border-gray-lines"}`}
                    >
                      <div
                        className={"flex flex-row items-center justify-center"}
                        style={{ paddingBottom: "14px" }}
                      >
                        <button
                          onClick={() => setDealToShare(item)}
                          className={"p-2 border text-text-buttons-secondary bg-bg-buttons-secondary rounded-2xl border-border-buttons-secondary"}
                        >
                          {" "}
                          <FaShareAlt className="h-4" />
                        </button>
                        <button
                          onClick={() => setDealToResume(item)}
                          className={"p-2 border text-text-buttons-secondary bg-bg-buttons-secondary rounded-2xl border-border-buttons-secondary ml-2"}
                        >
                          {" "}
                          <FaExpandAlt className="h-4" />
                        </button>
                        <button
                          onClick={() => goToProfile(item)}
                          className={"p-2 border text-text-buttons-secondary bg-bg-buttons-secondary rounded-2xl border-border-buttons-secondary ml-2"}
                        >
                          {" "}
                          <FaEye className="h-4" />
                        </button>
                      </div>
                    </div>
                ))}
              </DealflowTableColumn>
            )}
            <DealflowTableColumn key={"new_column"} newColumn={true} createColumn={createColumn} />
          </div>
        </div>
      </div>

      {showColumnSection && (
        <DealflowTableColumnSection
          fields={[
            ...BASIC_FIELDS,
            ...customFields.map((customField) => ({ id: customField._id, value: `${customField.name} (${t("custom_field")})` })),
          ]
            .filter((field) => !currentFieldsIds.includes(field.id))
            .sort((a, b) => {
              const nameA = a.value.toUpperCase(); // ignore upper and lowercase
              const nameB = b.value.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }

              // names must be equal
              return 0;
            })}
          onClose={() => {
            setShowColumnSection(false);
          }}
          onSubmit={confirmCreateColumn}
        />
      )}

      {loading && <Loader />}
    </>
  );
};

export default DealflowTable;
