import { ThemeActionsTypes } from "./theme-actions-types";

const defaultTheme = {
  sidebar_expanded: true,
};

const ThemeActions = {
  setTheme: (theme) => ({
    type: ThemeActionsTypes.SET_THEME,
    payload: theme,
  }),

  clearTheme: () => ({
    type: ThemeActionsTypes.CLEAR_THEME,
    payload: defaultTheme,
  }),
};

export default ThemeActions;
