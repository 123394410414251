import { RoutesLinks } from "components/routes-links";

export const checkUrl = (item, url, user) => {
  if (item.url === url) { return true; }
  switch (item.section) {
    case "dealflow":
      return (url.includes("/applications") && !url.includes("/applications-filter")) ||
               (url.includes("/dealflow") && !url.startsWith("/evaluations"));
    case "pipeline_manager":
      return url.includes("/pipeline-manager/templates") || url.includes("/pipeline-manager/boards");
    case "questionnaires_engine":
      return url.includes("/questionnaires-engine");
    case "preferences":
      return url.includes("/settings");
    case "reports":
      return url.includes("/reports");
    case "evaluations":
      return url.includes("/evaluations") || url.includes("score-cards");
    case "support":
      return url.includes("/support");
    case "score_cards":
      return url.includes("/score-cards");
    case "events":
      return url.includes("/events") || (url.includes("kmz-form") && user);
    case "reporting":
      return url.includes("reporting") && user;
    case "portfolio":
      return url.includes("portfolio") && user;
    case "documentation":
      return url.includes("documentation") && user;
    case "questionnaires_engine_forms":
      return url === RoutesLinks.DASHBOARD_LINK || url.includes(RoutesLinks.QE_FORMS_LINK);
    case "investors_relations_manager":
      return url.includes("irm") && user;
    case "members":
      return url === RoutesLinks.MEMBERS_LINK || url.includes(RoutesLinks.MEMBERS_LINK);
    default:
      return false;
  }
};
