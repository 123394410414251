import { useState, useEffect, useRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Pagination } from "components/ui/atoms/Pagination";
import { RoutesLinks } from "components/routes-links";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";

import { FaRocket, FaClipboardList, FaGavel, FaEye, FaInfoCircle, FaSearch, FaTrashAlt } from "react-icons/fa";
import { GiPodium } from "react-icons/gi";
import { SectionHeader } from "components/ui/molecules/SectionHeader";
import { Card } from "components/ui/atoms/Card";
import { isEqual } from "lodash";
import Config from "config";
import ReactTooltip from "react-tooltip";
import { ButtonCardMain } from "components/ui/atoms/ButtonCardMain";
import EvaluationProcessWizard from "./EvaluationProcessWizard";
import useUser from "hooks/useUser";
import SuggestedStep from "components/ui/molecules/SuggestedStep";
import EvaluationProcessesManagerService from "services/evaluation-processes-manager-service";
import CreateEvaluationProcessTypeSelector from "./components/create-evaluation-process-type-selector";
import { SkeletonList } from "components/ui/atoms/skeletons/SkeletonList";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { ButtonDanger } from "components/ui/atoms/ButtonDanger";

const EvaluationProcesses = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState("active");

  const containerRef = useRef();

  const [events, setEvents] = useState([]);
  const [sort] = useState("-createdAt");
  const [showCreateTypeSelector, setShowCreateTypeSelector] = useState(false);
  const [showEvaluationProcessWizard, setShowEvaluationProcessWizard] = useState(false);
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 10,
    totalPages: 0,
    totalDocs: 0,
  });
  const [filterName, setFilterName] = useState("");

  const { setPageTitle, ref } = useOutletContext();
  const getEvents = (archived = false, name = null) => {
    setLoading(true);
    EvaluationProcessesManagerService.getEvaluationProcesses({
      page: pagination.page,
      rowsPerPage: pagination.rowsPerPage,
      sortBy: sort,
      archived,
      name: name ?? (!filterName || filterName.length === 0) ? null : filterName,
    })
      .then((result) => {
        setEvents(result.data.data.events);
        if (result.data.data.pagination) {
          const newPagination = {
            ...pagination,
            totalPages: result.data.data.pagination.pages,
            totalDocs: result.data.data.pagination.totalDocs,
          };

          if (!isEqual(newPagination, pagination)) {
            setPagination(newPagination);
          }
        }
      })
      .catch((error) => {
        addToast(t("error_occurred_retrieving_events"), {
          appearance: "error",
          autoDismiss: true,
        });
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useImperativeHandle(ref, () => ({
    showCreateTypeSelector: () => setShowCreateTypeSelector(true),
  }));

  useEffect(() => {
    getEvents();
    if (containerRef.current && containerRef.current.scrollIntoView) {
      containerRef.current.scrollIntoView();
    }
  }, [pagination.page, pagination.rowsPerPage]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [events]);

  useEffect(() => {
    if (section === "active") {
      getEvents();
    } else if (section === "archived") {
      getEvents(true);
    }
  }, [section]);

  useEffect(() => {
    setPageTitle(t("evaluation_processes"));
  }, []);

  const handleClearFilterAndSubmit = (e) => {
    e.preventDefault();
    setFilterName(""); // Ensuring the state is cleared
    getEvents(section === "archived", null); // Calling getDealflow with the cleared filter name
  };

  const [showSuggestedStep, setShowSuggestedStep] = useState(false);
  const { user, userLanguage } = useUser();

  return (
    <div ref={containerRef}>
      {showSuggestedStep && (
        <SuggestedStep
          action={"create_evaluation_process"}
          showSuggestedStep={!!showSuggestedStep}
          setShowSuggestedStep={setShowSuggestedStep}
          continueAction={() => {
            setShowSuggestedStep(false);
            setShowEvaluationProcessWizard(true);
          }}
          continueText={t("create_evaluation_process")}
        />
      )}
      {showEvaluationProcessWizard && (
        <EvaluationProcessWizard
          getEvents={getEvents}
          onClose={() => setShowEvaluationProcessWizard(false)}
        />
      )}
      {!user.lp && (
        <>
          <SectionHeader
            section={section}
            setSection={setSection}
            sectionKeys={[{ id: "active", title: "active" }, { id: "archived", title: "archived" }]}
            sectionTitles={[t("active"), t("archived")]}
          />
          <div className="flex mt-4">
            <div className="flex-1">
            </div>
            <form className="flex gap-2 w-96" onSubmit={(e) => {
              e.preventDefault();
              getEvents(section === "archived");
            }}>
              <input
                className={
                  "shadow-soft-white border border-gray-lines focus:border-main bg-input w-full py-2 px-7 relative z-10 text-left text-xs font-normal rounded-2xl placeholder-gray cursor-pointer transition-all duration-200 outline-none hover:border-main hover:outline-none hover:shadow-inner focus:outline-none focus:shadow-focus active:outline-none"
                }

                placeholder={t("evaluation_process_example")}
                onChange={(e) => {
                  setFilterName(e.currentTarget.value);
                }}
                value={filterName}
              />
              <ButtonSecondary
                type="submit"
                iconComponent={<FaSearch className="h-4" />}
              />
              {filterName && (<ButtonDanger
                type="button"
                onClick={handleClearFilterAndSubmit}
                iconComponent={<FaTrashAlt className="h-4" />}
              />)}
            </form>
          </div>
          {events.length === 0 && !loading && (
            <div className="my-8">
              <img
                src={`${Config.GUIDE_STEPS_BACKGROUND_IMAGES_BUCKET}evaluation_processes_${userLanguage}.png`}
                className="w-auto"
              />
            </div>
          )}
          {!loading && events.length > 0 && (
            <>
              <div className="grid grid-cols-1 gap-8 mt-6 xl:grid-cols-2 2xl:grid-cols-3">
                {events.map((event, index) => (
                  <Card key={index} investor={event.investor._id !== user.investor._id ? event.investor : null} containerClassName="relative overflow-hidden">
                    {event.archived && (
                      <div
                        style={{ top: 10, right: -55 }}
                        className={
                          "absolute m-4 bg-white border border-border-buttons-secondary text-main text-sm rotate-45 px-12 z-10 font-semibold"
                        }
                      >
                        {t("archived")}
                      </div>
                    )}

                    <div className="flex justify-between">
                      <div className="relative flex-1">
                        <div className={"w-full px-1 font-bold text-mainflex flex-col"}>
                          <div>{event.name}</div>
                          <div
                            className={
                              "text-xxs mr-1 text-black my-2 border-t border-gray-lines pt-2"
                            }
                          >
                            <span data-tip={t(event.managed_entity || "startups")}>
                              <FaRocket className="inline-block w-5 h-5 mr-1" />
                            </span>
                            {event.stats.startupsCount}
                            <span data-tip={t("judges")}>
                              <FaGavel className="inline-block w-4 h-4 ml-4 mr-1" />
                            </span>
                            {event.stats.judgesCount}

                            <span data-tip={t("evaluations")}>
                              <GiPodium className="inline-block w-5 h-5 ml-4 mr-1" />
                            </span>
                            {event.stats.evaluations}
                            <span
                              data-tip={t(
                                event.score_card ? "scorecard_assigned" : "scorecard_not_assigned",
                              )}
                              className="font-semibold"
                            >
                              <FaClipboardList
                                className={`inline-block w-5 h-5 ml-4 ${event.score_card ? "text-black" : "text-gray-lines"} `}
                              />
                            </span>
                            <span
                              data-tip={t(
                                event.show_general_information || event?.source_information?.length
                                  ? "source_information_assigned"
                                  : "source_information_not_assigned",
                              )}
                              className="font-semibold"
                            >
                              <FaInfoCircle
                                className={`inline-block w-5 h-5 ml-2 ${event.show_general_information || event?.source_information?.length ? "text-black" : "text-gray-lines"} `}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={"flex justify-end pt-2 border-t border-separator"}>
                      <ButtonCardMain
                        onClick={() =>
                          navigate(`${RoutesLinks.EVALUATION_PROCESSES_LINK}${event._id}`)
                        }
                        text={t("see_details")}
                        iconComponent={<FaEye className="inline-block w-5 h-4 mr-2" />}
                      />
                    </div>
                  </Card>
                ))}
              </div>

              <Pagination
                currentPage={pagination.page}
                setCurrentPage={(page) => {
                  setPagination({
                    ...pagination,
                    page,
                  });
                }}
                perPage={pagination.rowsPerPage}
                pages={pagination.totalPages}
                setPerPage={(value) => {
                  setPagination({
                    ...pagination,
                    page: 0,
                    rowsPerPage: value[0].id,
                  });
                }}
              />
            </>
          )}
        </>
      )}

      {showCreateTypeSelector && (
        <CreateEvaluationProcessTypeSelector
          onClose={() => setShowCreateTypeSelector(false)}
          getEvaluationProcesses={getEvents}
          setShowEvaluationProcessWizard={() => setShowEvaluationProcessWizard(true)}
        />
      )}
      {loading && <div className="grid grid-cols-1 gap-8 mt-6 xl:grid-cols-2 2xl:grid-cols-3 mt-6"><SkeletonList length={7} style="rounded-xl h-40"/></div>}
      {user.lp && (
        <div className="my-6">
          <NoDataInfo title={t("access_forbidden")} />
        </div>
      )}
    </div>
  );
};

export default EvaluationProcesses;
