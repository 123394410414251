import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { MiniHelp } from "components/ui/atoms/MiniHelp";
import { PageTitle } from "components/ui/atoms/PageTitle";
import { SectionHeader } from "components/ui/molecules/SectionHeader";
import useUser from "hooks/useUser";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";
import { Outlet } from "react-router-dom";
import { getPathSegment } from "utils/get-path-segment";

const EvaluationsView = () => {
  const pageRef = useRef(null);

  const [pageTitle, setPageTitle] = useState();
  const [section, setSection] = useState(getPathSegment(location.href, 2));

  const SECTIONS = [{ id: "evaluation-processes", title: "evaluation_processes", route: "evaluation-processes" }, { id: "score-cards", title: "score_cards", route: "score-cards" }];

  const { user } = useUser();

  const { t } = useTranslation();

  return (
    <>
    <div className="flex items-start">
    <div className="flex-1">
      <PageTitle title={pageTitle} />
    </div>
    {section === "evaluation-processes" && (
        <>
        {!user?.help?.create_evaluation_process && !user.lp && (
        <MiniHelp item={"create_evaluation_process"} />
        )}
        {!user.lp && (
        <ButtonMain
            iconComponent={<FaPlus className="inline-block mr-2" />}
            text={t("create_evaluation_process")}
            onClick={() => pageRef.current?.showCreateTypeSelector()}
        />
        )}
        </>
    )}
    {section === "score-cards" && (
        <>
            {!user?.help?.create_scorecard && <MiniHelp item={"create_scorecard"} />}

            <ButtonMain
            iconComponent={<FaPlus className="inline-block mr-2" />}
            text={t("create_score_card")}
            onClick={() => pageRef.current?.showCreateScoreCard()}
            />
        </>
    )}
  </div>
    <SectionHeader
        section={section}
        setSection={(sec) => {
          setSection(sec);
        }}
        sectionKeys={SECTIONS}
        navigate
    />
    <Outlet context={{ setPageTitle, ref: pageRef }}/>
  </>
  );
};

export default EvaluationsView;
