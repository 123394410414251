const ScoreCardUtils = {
  getCriteriaParents: (criteria) => {
    const childrenIds = criteria.filter((c) => c.parent).map((children) => children._id);

    return criteria.filter((c) => !childrenIds.includes(c._id));
  },

  hasChildren: (currentCriteria, criteria, isId = false) => {
    if (!Array.isArray(criteria)) {
      return false;
    }

    return isId
      ? !!criteria.filter((c) => c.parent === currentCriteria).length
      : !!criteria.filter((c) => c.parent === currentCriteria._id).length;
  },

  getChildren: (parent, criteria) => criteria.filter((c) => c.parent === parent._id),

  getParentScore: (parent, criteria, scores) => {
    const children = ScoreCardUtils.getChildren(parent, criteria);
    let score = 0;

    if (children.length) {
      children.forEach((child) => {
        score += (scores[child._id] * child.weigth) / 100;
      });
    } else {
      score = scores[parent._id];
    }

    return Math.round(score * 100) / 100;
  },

  mergeEvaluations: (data) => {
    const criteriaSums = {};
    let generalScoreSum = 0;
    const count = data.length;
    const createdAt = [];

    data.forEach(evaluation => {
      // Sum the general scores
      generalScoreSum += evaluation.general_score;

      // Sum the criteria scores
      for (const [criteriaId, score] of Object.entries(evaluation.criterias_score)) {
        if (!criteriaSums[criteriaId]) {
          criteriaSums[criteriaId] = 0;
        }
        criteriaSums[criteriaId] += score;
      }

      createdAt.push(evaluation.createdAt);
    });

    // Calculate the average general score
    const avgGeneralScore = generalScoreSum / count;

    // Calculate the average criteria scores
    const avgCriteriaScores = {};
    for (const [criteriaId, scoreSum] of Object.entries(criteriaSums)) {
      avgCriteriaScores[criteriaId] = scoreSum / count;
    }

    return {
      general_score: avgGeneralScore,
      criterias_score: avgCriteriaScores,
      createdAt,
    };
  },
};

export default ScoreCardUtils;
