import React, { useEffect, useState } from "react";
import { SidePanel } from "components/ui/objects/SidePanel";
import moment from "moment";
const EmailDetail = ({ email, onClose }) => {
  const [emailData, setEmailData] = useState({});
  useEffect(() => {
    setEmailData(email);
  }, []);

  return (
    <SidePanel
      width="1/2"
      onClose={() => {
        setEmailData({});
        onClose();
      }}
      title={emailData.subject}
    >
      <div className="mb-6">
        <p>
          <strong>From:</strong> {emailData?.email || ""}
        </p>
        <p>
          <strong>Para:</strong> {emailData?.to || ""}
        </p>
        <p>
          <strong>Date:</strong> {moment(emailData?.sentDateTime).format("DD/MM/YYYY") || ""}
        </p>
      </div>
      <div dangerouslySetInnerHTML={{ __html: emailData?.body?.content }} />
    </SidePanel>
  );
};

export default EmailDetail;
