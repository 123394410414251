import React, { useEffect, useState, useImperativeHandle } from "react";
import TagsService from "../../services/tags-service";
import ReactTooltip from "react-tooltip";
import { Card } from "../ui/atoms/Card";
import { FaEdit, FaProjectDiagram, FaTrash } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { useSwal } from "../../hooks/useSwal";
import CreateTagModal from "./CreateTagModal";
import Config from "config";
import { ButtonDanger } from "components/ui/atoms/ButtonDanger";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import useUser from "hooks/useUser";
import { SkeletonList } from "components/ui/atoms/skeletons/SkeletonList";
import { useOutletContext } from "react-router-dom";

export const TagListView = ({ category }) => {
  const { ref } = useOutletContext();

  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { confirm } = useSwal();
  const [loading, setLoading] = useState(true);
  const [tagToEdit, setTagToEdit] = useState(null);
  const [showTagModal, setShowTagModal] = useState(false);
  const [tags, setTags] = useState([]);

  const { user, userLanguage } = useUser();
  const getTags = () => {
    setLoading(true);

    TagsService.getTags(category)
      .then((response) => {
        setTags(response.data.data);
        ReactTooltip.rebuild();
      })
      .catch((error) => {
        addToast(
          error.response && error.response.data.msg_key
            ? error.response.data.msg_key
            : t("error_occurred_retrieving_tags"),
          {
            appearance: "error",
            autoDismiss: true,
          },
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useImperativeHandle(ref, () => ({
    handleCreateTag: () => setShowTagModal(true),
  }));

  const confirmCreate = async(name, color) => {
    try {
      setShowTagModal(false);
      setLoading(true);
      await TagsService.createTag(category, name, color);
      getTags();
      addToast(t("tag_created_successfully"), {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key
          ? error.response.data.msg_key
          : t("error_occurred_creating_tag"),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
    } finally {
      setTagToEdit(null);
      setLoading(false);
    }
  };

  const confirmUpdate = async(name, color) => {
    confirm({
      text: t("update_tag_text"),
      icon: "warning",
    }).then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          setShowTagModal(false);
          setLoading(true);
          await TagsService.updateTag(tagToEdit._id, { name, color });
          getTags();
          addToast(t("tag_updated_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
        } catch (error) {
          addToast(
            error.response && error.response.data.msg_key
              ? error.response.data.msg_key
              : t("error_occurred_updating_tag"),
            {
              appearance: "error",
              autoDismiss: true,
            },
          );
        } finally {
          setTagToEdit(null);
          setLoading(false);
        }
      }
    });
  };

  const deleteTag = (tagId) => {
    confirm({
      text: t(`delete_tag_${category}_text`),
      icon: "warning",
    }).then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          setShowTagModal(false);
          setLoading(true);
          await TagsService.deleteTag(tagId);
          getTags();
          addToast(t("tag_deleted_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
        } catch (error) {
          addToast(
            error.response && error.response.data.msg_key
              ? error.response.data.msg_key
              : t("error_occurred_deleting_tag"),
            {
              appearance: "error",
              autoDismiss: true,
            },
          );
        } finally {
          setTagToEdit(null);
          setLoading(false);
        }
      }
    });
  };

  const onModalSubmit = (name, color) => {
    tagToEdit ? confirmUpdate(name, color) : confirmCreate(name, color);
  };

  const handleEditTag = (tag) => {
    setShowTagModal(true);
    setTagToEdit({ ...tag });
  };

  useEffect(() => {
    category && getTags();
  }, [category]);

  return (
    <>
      {showTagModal && (
        <CreateTagModal
          tag={tagToEdit}
          showModal={showTagModal}
          handleCloseModal={() => {
            setShowTagModal(false);
            setTagToEdit(null);
          }}
          onSubmit={onModalSubmit}
        />
      )}

      {tags.length !== 0 && (
        <div className="grid grid-cols-3 gap-4 mb-4 my-10">
          {tags.map((tag) => (
            <Card key={tag._id} containerClassName="flex items-center">
              <div style={{ backgroundColor: tag.color }} className="rounded-full w-8 h-8 mr-2" />

              <div className="flex-1 font-semibold">{tag.name}</div>

              {user.investor._id !== tag.investor._id && (<div className="flex gap-2">
                <FaProjectDiagram className={"text-main"} />
                <span className="text-xxs">{tag.investor.company_name}</span>
              </div>
              )}

              <div className="flex ml-2">
                {!user.lp && (
                  <div className="mr-2" data-tip={t("delete")}>
                    <ButtonDanger
                      onClick={() => deleteTag(tag._id)}
                      iconComponent={<FaTrash className="inline" />}
                    />
                  </div>
                )}

                {!user.lp && (
                  <div data-tip={t("edit")}>
                    <ButtonSecondary
                      onClick={() => handleEditTag(tag)}
                      iconComponent={<FaEdit className={"inline"} />}
                    />
                  </div>
                )}
              </div>
            </Card>
          ))}
        </div>
      )}

      {tags.length === 0 && !loading && (
        <div className="my-10">
          <img
            src={`${Config.GUIDE_STEPS_BACKGROUND_IMAGES_BUCKET}${category}_tags_${userLanguage}.png`}
            className="w-auto"
          />
        </div>
      )}

      {loading && <div className="grid grid-cols-3 gap-4 mb-4 my-10"><SkeletonList length={8} style="rounded-xl h-12"/></div>}
    </>
  );
};
