import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import Icon from "styles/images/outlook.png";
import { useTranslation } from "react-i18next";
import { useSwal } from "hooks/useSwal";
export const OutlookButton = ({
  isActiveLoginGoogle,
  onSuccess,
  onFailure = () => {},
  onLogout,
}) => {
  const msal = useMsal();
  const { t } = useTranslation();
  const { alert } = useSwal();
  const isAuthenticated = useIsAuthenticated();

  const signIn = async () => {
    if (isActiveLoginGoogle) {
      alert({
        text: "Ya tiene una cuenta de Google sincronizada por favor deshabilite la sincronización e intente nuevamente",
      });
    } else {
      try {
        const data = await msal.instance.loginPopup({
          scopes: [
            "openid",
            "email",
            "offline_access",
            "profile",
            "user.read",
            "mail.read",
            "mail.send",
          ],
          prompt: "select_account",
        });
        onSuccess(data);
      } catch (err) {
        onFailure(err);
      }
    }
  };

  return (
    <>
      {!isAuthenticated && (
        <button
          type="button"
          onClick={signIn}
          style={{
            backgroundColor: "rgb(255, 255, 255)",
            display: "inline-flex",
            alignItems: "center",
            color: "rgba(0, 0, 0, 0.54)",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px",
            padding: "0px",
            borderRadius: "2px",
            border: "1px solid transparent",
            fontSize: "14px",
            fontWeight: "500",
            fontFamily: "Roboto, sans-serif",
          }}
          className="google-button inline-flex justify-center | w-full | py-0.5 px-8 | text-center text-sm font-semibold bg-bg-buttons-seconday border-border-buttons-secondary text-text-buttons-secondary font-sans | rounded-2xl  opacity-100 outline-none focus:outline-none | placeholder-gray | cursor-pointer transition-all duration-200 | shadow-hover hover:shadow-inner | disabled:cursor-default"
        >
          <div>
            <img src={Icon} width="25px" alt="Icon" className={"inline | mr-2"} />
          </div>
          <span style={{ padding: "10px 16px 10px 0px", fontWeight: "500" }}>
            {t("enable_email_synchronization")}
          </span>
        </button>
      )}
      {isAuthenticated && (
        <button
          type="button"
          onClick={onLogout}
          style={{
            backgroundColor: "rgb(255, 255, 255)",
            display: "inline-flex",
            alignItems: "center",
            color: "rgba(0, 0, 0, 0.54)",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px",
            padding: "0px",
            borderRadius: "2px",
            border: "1px solid transparent",
            fontSize: "14px",
            fontWeight: "500",
          }}
          className="text-center block w-auto mr-0 ml-0 py-2 px-4 mt-0
            text-xs font-semibold text-text-buttons-secondary placeholder-gray bg-bg-buttons-secondary rounded-2xl
            shadow-soft-white cursor-pointer transition-all duration-500 ease-in-out border border-border-buttons-secondary
            hover:bg-buttons-secondary-hover hover:text-buttons-secondary hover:shadow-hover
            focus:outline-none hover:shadow-inner"
        >
          {t("disable_email_synchronization")}
        </button>
      )}
    </>
  );
};
