import { useState, useEffect, useImperativeHandle, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Pagination } from "components/ui/atoms/Pagination";
import { isEqual } from "lodash";
import { RoutesLinks } from "components/routes-links";
import { ScoreCardBox } from "components/ui/molecules/ScoreCardBox";
import Config from "config";
import SuggestedStep from "components/ui/molecules/SuggestedStep";
import Utils from "utils/utils";
import useUser from "hooks/useUser";
import ScoreCardsService from "services/score-cards-service";
import CreateTypeSelector from "./components/create-type-selector";
import { SkeletonList } from "components/ui/atoms/skeletons/SkeletonList";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { FaSearch, FaTrashAlt } from "react-icons/fa";
import { ButtonDanger } from "components/ui/atoms/ButtonDanger";

const ScoreCards = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [scoreCards, setScoreCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sort] = useState("-createdAt");
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 6,
    totalPages: 0,
    totalDocs: 0,
  });

  const [filterName, setFilterName] = useState("");

  const { ref, setPageTitle } = useOutletContext();

  const containerRef = useRef();

  const [showCreateTypeSelector, setShowCreateTypeSelector] = useState(false);

  const getScoreCards = async(name = null) => {
    try {
      setLoading(true);

      const queryParams = {
        page: pagination.page,
        rowsPerPage: pagination.rowsPerPage,
        sortBy: sort,
        name: name ?? filterName,
      };

      const result = await ScoreCardsService.getScoreCards(queryParams);

      setScoreCards(result.data.data.score_cards);
      if (result.data.data.pagination) {
        const newPagination = {
          ...pagination,
          totalPages: result.data.data.pagination.pages,
          totalDocs: result.data.data.pagination.totalDocs,
        };

        if (!isEqual(newPagination, pagination)) {
          setPagination(newPagination);
        }
      }
    } catch (error) {
      addToast(t("error_occurred_retrieving_score_cards"), {
        appearance: "error",
        autoDismiss: true,
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getScoreCards();
    if (containerRef.current.scrollIntoView) { containerRef.current.scrollIntoView(); }
  }, [pagination.page, pagination.rowsPerPage, sort]);

  const [showSuggestedStep, setShowSuggestedStep] = useState(false);
  const { user, userLanguage } = useUser();

  const handleCreateScoreCard = () => {
    if (
      scoreCards?.length ||
      !!user?.onboarding_tasks.includes("create_score_card") ||
      !!user?.investor?.onboarding_tasks?.includes("create_score_card") ||
      Utils.checkIfPreviousStepsArCompleted(
        user?.onboarding_tasks,
        user?.investor?.onboarding_tasks,
        "create_score_card",
      )
    ) {
      // navigate(`${RoutesLinks.SCORE_CARDS_LINK}wizard`)
      setShowCreateTypeSelector(true);
    } else {
      setShowSuggestedStep(true);
    }
  };

  useImperativeHandle(ref, () => ({
    showCreateScoreCard: () => handleCreateScoreCard(),
  }));

  useEffect(() => {
    setPageTitle(t("score_cards"));
  }, []);

  const handleClearFilterAndSubmit = (e) => {
    e.preventDefault();
    setFilterName(""); // Ensuring the state is cleared
    getScoreCards(""); // Calling getDealflow with the cleared filter name
  };

  return (
    <div ref={containerRef}>
      {showSuggestedStep && (
        <SuggestedStep
          action={"create_score_card"}
          showSuggestedStep={!!showSuggestedStep}
          setShowSuggestedStep={setShowSuggestedStep}
          continueAction={() => {
            setShowSuggestedStep(false);
            setShowCreateTypeSelector(true);
            // navigate(`${RoutesLinks.SCORE_CARDS_LINK}wizard`)
          }}
          continueText={t("create_score_card")}
        />
      )}

        <div className="flex mt-4">
          <div className="flex-1">
          </div>
          <form className="flex gap-2" onSubmit={(e) => {
            e.preventDefault();
            getScoreCards();
          }}>
            <input
              className={
                "shadow-soft-white border border-gray-lines focus:border-main bg-input w-full py-2 px-7 relative z-10 text-left text-xs font-normal rounded-2xl placeholder-gray cursor-pointer transition-all duration-200 outline-none hover:border-main hover:outline-none hover:shadow-inner focus:outline-none focus:shadow-focus active:outline-none"
              }

              placeholder={t("scorecard_example")}
              onChange={(e) => {
                setFilterName(e.currentTarget.value);
              }}
              value={filterName}
            />
            <ButtonSecondary
              type="submit"
              iconComponent={<FaSearch className="h-4" />}
            />
            {filterName && (<ButtonDanger
              type="button"
              onClick={handleClearFilterAndSubmit}
              iconComponent={<FaTrashAlt className="h-4" />}
            />)}
          </form>
        </div>
      {!loading && scoreCards.length > 0 && (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4">
            {scoreCards.map((scoreCard, index) => (
              <ScoreCardBox
                key={index}
                onClick={() => navigate(`${RoutesLinks.SCORE_CARDS_LINK}${scoreCard._id}`)}
                criteria={scoreCard.criteria}
                name={scoreCard.name}
                description={scoreCard.description}
                generalScore={scoreCard.general_score}
                generalScoreScale={scoreCard.general_score_scale}
                investor={user.investor._id !== scoreCard.investor._id ? scoreCard.investor : null}
              />
            ))}
          </div>

          <Pagination
            currentPage={pagination.page}
            setCurrentPage={(page) => {
              setPagination({
                ...pagination,
                page,
              });
            }}
            perPage={pagination.rowsPerPage}
            pages={pagination.totalPages}
            setPerPage={(value) => {
              setPagination({
                ...pagination,
                page: 0,
                rowsPerPage: value[0].id,
              });
            }}
          />
        </>
      )}

      {!loading && !pagination.totalDocs && (
        <div className="my-10">
          <img
            src={`${Config.GUIDE_STEPS_BACKGROUND_IMAGES_BUCKET}scorecards_${userLanguage}.png`}
            className="w-auto"
          />
        </div>
      )}

      {loading && <div className=" grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4 mt-6"><SkeletonList length={6} style="rounded-xl h-72"/></div>}

      {showCreateTypeSelector && (
        <CreateTypeSelector
          onClose={() => setShowCreateTypeSelector(false)}
          getScoreCards={() => getScoreCards()}
        />
      )}
    </div>
  );
};

export default ScoreCards;
