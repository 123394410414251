import { useOnboardingSteps } from "hooks/useOnboardingSteps";
import useUser from "hooks/useUser";
import { useDispatch } from "react-redux";
import { AiOutlineCheckCircle } from "react-icons/ai";
import OnboardingOperations from "redux/onboarding/onboarding-operations";
import { useTranslation } from "react-i18next";

export const OnboardingStepsMenu = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const onboardingSteps = useOnboardingSteps();

  const { user } = useUser();

  return (
    <div style={{ border: "0px" }} className="inline bg-gray-100 hover:bg-gray-200 border focus:outline-none  hover:shadow-inner w-8 h-8 rounded-full" data-tip={t("sidebar_getting_started")}>
      {true | (onboardingSteps.getTaskProgress() !== 100) && !user.lp && (
        <button
          id={"onboarding-button"}
          onClick={() => dispatch(OnboardingOperations.handleOnboardingTasksPannel(true))}
          className={
            "h-full w-full flex"
          }
        >
          <AiOutlineCheckCircle className={"text-text-buttons-secondary w-7 m-auto"} />
        </button>
      )}
    </div>
  );
};
