import { useTranslation } from "react-i18next";

import { ResponsiveSwarmPlot } from "@nivo/swarmplot";
import { max } from "lodash";
import Utils from "utils/utils";
import { FaExclamationTriangle } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { useEffect } from "react";
import Config from "config";

const ROBUSTNESS_SAMPLE_DATA = [
  {
    service: "Main",
    model: "Master",
    algorithm: "Random Forest",
    description: "The main model",
    total_dataset: 59.597,
    usable_sample: 23.518,
    training_sample: 18.912,
    validation_sample: 4.606,
    data_points: 245.856,
    training_time: 68.64,
    running_time: 0.41,
    auc_growth_model: 0.798,
    model_prediction: 0.408,
  },
  {
    service: "Robustness",
    model: "Robustness_Sampling",
    algorithm: "Random Forest",
    description: "The random sample : 37.075",
    total_dataset: 59.597,
    usable_sample: 17.18,
    training_sample: 12.574,
    validation_sample: 4.606,
    data_points: 163.462,
    training_time: 45.69,
    running_time: 0.067,
    auc_growth_model: 0.7965,
    model_prediction: 0.428,
  },
  {
    service: "Robustness",
    model: "Robustness_Sampling",
    algorithm: "Random Forest",
    description: "The random sample : 5.78",
    total_dataset: 59.597,
    usable_sample: 6.584,
    training_sample: 1.978,
    validation_sample: 4.606,
    data_points: 25.714,
    training_time: 6.81,
    running_time: 0.015,
    auc_growth_model: 0.7862,
    model_prediction: 0.408,
  },
  {
    service: "Robustness",
    model: "Robustness_Sampling",
    algorithm: "Random Forest",
    description: "The random sample : 21.188",
    total_dataset: 59.597,
    usable_sample: 11.984,
    training_sample: 7.378,
    validation_sample: 4.606,
    data_points: 95.914,
    training_time: 24.59,
    running_time: 0.042,
    auc_growth_model: 0.7946,
    model_prediction: 0.488,
  },
  {
    service: "Robustness",
    model: "Robustness_Sampling",
    algorithm: "Random Forest",
    description: "The random sample : 16.471",
    total_dataset: 59.597,
    usable_sample: 10.24,
    training_sample: 5.634,
    validation_sample: 4.606,
    data_points: 73.242,
    training_time: 18.95,
    running_time: 0.035,
    auc_growth_model: 0.7888,
    model_prediction: 0.318,
  },
  {
    service: "Robustness",
    model: "Robustness_Sampling",
    algorithm: "Random Forest",
    description: "The random sample : 45.841",
    total_dataset: 59.597,
    usable_sample: 20.434,
    training_sample: 15.828,
    validation_sample: 4.606,
    data_points: 205.764,
    training_time: 59.83,
    running_time: 0.079,
    auc_growth_model: 0.7981,
    model_prediction: 0.398,
  },
  {
    service: "Robustness",
    model: "Robustness_Sampling",
    algorithm: "Random Forest",
    description: "The random sample : 35.443",
    total_dataset: 59.597,
    usable_sample: 16.838,
    training_sample: 12.232,
    validation_sample: 4.606,
    data_points: 159.016,
    training_time: 44.72,
    running_time: 0.062,
    auc_growth_model: 0.7975,
    model_prediction: 0.378,
  },
  {
    service: "Robustness",
    model: "Robustness_Sampling",
    algorithm: "Random Forest",
    description: "The random sample : 25.654",
    total_dataset: 59.597,
    usable_sample: 13.354,
    training_sample: 8.748,
    validation_sample: 4.606,
    data_points: 113.724,
    training_time: 29.86,
    running_time: 0.049,
    auc_growth_model: 0.7927,
    model_prediction: 0.464,
  },
  {
    service: "Robustness",
    model: "Robustness_Sampling",
    algorithm: "Random Forest",
    description: "The random sample : 10.815",
    total_dataset: 59.597,
    usable_sample: 8.296,
    training_sample: 3.69,
    validation_sample: 4.606,
    data_points: 47.97,
    training_time: 11.9,
    running_time: 0.026,
    auc_growth_model: 0.7863,
    model_prediction: 0.532,
  },
  {
    service: "Robustness",
    model: "Robustness_Sampling",
    algorithm: "Random Forest",
    description: "The random sample : 20.826",
    total_dataset: 59.597,
    usable_sample: 11.718,
    training_sample: 7.112,
    validation_sample: 4.606,
    data_points: 92.456,
    training_time: 24.11,
    running_time: 0.04,
    auc_growth_model: 0.7941,
    model_prediction: 0.39,
  },
  {
    service: "Robustness",
    model: "Robustness_Sampling",
    algorithm: "Random Forest",
    description: "The random sample : 49.72",
    total_dataset: 59.597,
    usable_sample: 21.792,
    training_sample: 17.186,
    validation_sample: 4.606,
    data_points: 223.418,
    training_time: 61.89,
    running_time: 0.085,
    auc_growth_model: 0.798,
    model_prediction: 0.362,
  },
  {
    service: "Robustness",
    model: "Robustness_Sampling",
    algorithm: "Random Forest",
    description: "The random sample : 30.506",
    total_dataset: 59.597,
    usable_sample: 15.222,
    training_sample: 10.616,
    validation_sample: 4.606,
    data_points: 138.008,
    training_time: 36.5,
    running_time: 0.053,
    auc_growth_model: 0.7921,
    model_prediction: 0.37,
  },
  {
    service: "Robustness",
    model: "Robustness_Sampling",
    algorithm: "Random Forest",
    description: "The random sample : 27.275",
    total_dataset: 59.597,
    usable_sample: 13.938,
    training_sample: 9.332,
    validation_sample: 4.606,
    data_points: 121.316,
    training_time: 32.25,
    running_time: 0.051,
    auc_growth_model: 0.7941,
    model_prediction: 0.34,
  },
  {
    service: "Robustness",
    model: "Robustness_Sampling",
    algorithm: "Random Forest",
    description: "The random sample : 20.057",
    total_dataset: 59.597,
    usable_sample: 11.698,
    training_sample: 7.092,
    validation_sample: 4.606,
    data_points: 92.196,
    training_time: 23.53,
    running_time: 0.041,
    auc_growth_model: 0.7965,
    model_prediction: 0.482,
  },
  {
    service: "Robustness",
    model: "Robustness_Sampling",
    algorithm: "Random Forest",
    description: "The random sample : 15.557",
    total_dataset: 59.597,
    usable_sample: 9.928,
    training_sample: 5.322,
    validation_sample: 4.606,
    data_points: 69.186,
    training_time: 17.75,
    running_time: 0.033,
    auc_growth_model: 0.7923,
    model_prediction: 0.404,
  },
  {
    service: "Robustness",
    model: "Robustness_Sampling",
    algorithm: "Random Forest",
    description: "The random sample : 8.169",
    total_dataset: 59.597,
    usable_sample: 7.394,
    training_sample: 2.788,
    validation_sample: 4.606,
    data_points: 36.244,
    training_time: 8.83,
    running_time: 0.019,
    auc_growth_model: 0.7858,
    model_prediction: 0.41,
  },
  {
    service: "Robustness",
    model: "Robustness_drop_2_variables",
    algorithm: "Robustness_drop_3_variables",
    description:
      "Model_1_and_2 : year*last_investment_round_pre_money_valuation ; Model_3 : funding_type*country_enc*total_funding_amount",
    total_dataset: 59.597,
    usable_sample: 23.518,
    training_sample: 18.912,
    validation_sample: 4.606,
    data_points: 226.944,
    training_time: 75.09,
    running_time: 0.096,
    auc_growth_model: 0.7954,
    model_prediction: 0.524,
  },
  {
    service: "Robustness",
    model: "Robustness_drop_2_variables",
    algorithm: "Robustness_drop_3_variables",
    description:
      "Model_1_and_2 : month*country_enc ; Model_3 : country_enc*last_investment_round_lead_investors*succes_ratio",
    total_dataset: 59.597,
    usable_sample: 23.518,
    training_sample: 18.912,
    validation_sample: 4.606,
    data_points: 226.944,
    training_time: 69.75,
    running_time: 0.078,
    auc_growth_model: 0.7979,
    model_prediction: 0.4,
  },
  {
    service: "Robustness",
    model: "Robustness_drop_4_variables",
    algorithm: "Robustness_drop_2_variables",
    description:
      "Model_1_and_2 : indus*day*last_investment_round_lead_investors*funding_rounds ; Model_3 : funding_rounds*indus",
    total_dataset: 59.597,
    usable_sample: 23.518,
    training_sample: 18.912,
    validation_sample: 4.606,
    data_points: 226.944,
    training_time: 57.05,
    running_time: 0.076,
    auc_growth_model: 0.7281,
    model_prediction: 0.598,
  },
  {
    service: "Robustness",
    model: "Robustness_drop_4_variables",
    algorithm: "Robustness_drop_1_variables",
    description:
      "Model_1_and_2 : funding_type*day*last_investment_round_investors*indus ; Model_3 : funding_rounds",
    total_dataset: 59.597,
    usable_sample: 23.518,
    training_sample: 18.912,
    validation_sample: 4.606,
    data_points: 226.944,
    training_time: 53.72,
    running_time: 0.074,
    auc_growth_model: 0.715,
    model_prediction: 0.59,
  },
  {
    service: "Robustness",
    model: "Robustness_drop_2_variables",
    algorithm: "Robustness_drop_3_variables",
    description:
      "Model_1_and_2 : year*last_investment_round_pre_money_valuation ; Model_3 : funding_type*country_enc*total_funding_amount",
    total_dataset: 59.597,
    usable_sample: 23.518,
    training_sample: 18.912,
    validation_sample: 4.606,
    data_points: 226.944,
    training_time: 66.64,
    running_time: 0.094,
    auc_growth_model: 0.7954,
    model_prediction: 0.524,
  },
  {
    service: "Robustness",
    model: "Robustness_drop_2_variables",
    algorithm: "Robustness_drop_3_variables",
    description:
      "Model_1_and_2 : month*country_enc ; Model_3 : country_enc*last_investment_round_lead_investors*succes_ratio",
    total_dataset: 59.597,
    usable_sample: 23.518,
    training_sample: 18.912,
    validation_sample: 4.606,
    data_points: 226.944,
    training_time: 62.17,
    running_time: 0.076,
    auc_growth_model: 0.7979,
    model_prediction: 0.4,
  },
  {
    service: "Robustness",
    model: "Robustness_drop_4_variables",
    algorithm: "Robustness_drop_2_variables",
    description:
      "Model_1_and_2 : indus*day*last_investment_round_lead_investors*funding_rounds ; Model_3 : funding_rounds*indus",
    total_dataset: 59.597,
    usable_sample: 23.518,
    training_sample: 18.912,
    validation_sample: 4.606,
    data_points: 226.944,
    training_time: 55.7,
    running_time: 0.079,
    auc_growth_model: 0.7281,
    model_prediction: 0.598,
  },
  {
    service: "Robustness",
    model: "Robustness_drop_4_variables",
    algorithm: "Robustness_drop_1_variables",
    description:
      "Model_1_and_2 : funding_type*day*last_investment_round_investors*indus ; Model_3 : funding_rounds",
    total_dataset: 59.597,
    usable_sample: 23.518,
    training_sample: 18.912,
    validation_sample: 4.606,
    data_points: 226.944,
    training_time: 54.03,
    running_time: 0.076,
    auc_growth_model: 0.715,
    model_prediction: 0.59,
  },
  {
    service: "Robustness",
    model: "Robustness_drop_2_variables",
    algorithm: "Robustness_drop_3_variables",
    description:
      "Model_1_and_2 : year*last_investment_round_pre_money_valuation ; Model_3 : funding_type*country_enc*total_funding_amount",
    total_dataset: 59.597,
    usable_sample: 23.518,
    training_sample: 18.912,
    validation_sample: 4.606,
    data_points: 226.944,
    training_time: 66.05,
    running_time: 0.091,
    auc_growth_model: 0.7954,
    model_prediction: 0.524,
  },
  {
    service: "Robustness",
    model: "Robustness_drop_2_variables",
    algorithm: "Robustness_drop_3_variables",
    description:
      "Model_1_and_2 : month*country_enc ; Model_3 : country_enc*last_investment_round_lead_investors*succes_ratio",
    total_dataset: 59.597,
    usable_sample: 23.518,
    training_sample: 18.912,
    validation_sample: 4.606,
    data_points: 226.944,
    training_time: 71.5,
    running_time: 0.078,
    auc_growth_model: 0.7979,
    model_prediction: 0.4,
  },
  {
    service: "Robustness",
    model: "Robustness_drop_4_variables",
    algorithm: "Robustness_drop_2_variables",
    description:
      "Model_1_and_2 : indus*day*last_investment_round_lead_investors*funding_rounds ; Model_3 : funding_rounds*indus",
    total_dataset: 59.597,
    usable_sample: 23.518,
    training_sample: 18.912,
    validation_sample: 4.606,
    data_points: 226.944,
    training_time: 56.41,
    running_time: 0.076,
    auc_growth_model: 0.7281,
    model_prediction: 0.598,
  },
  {
    service: "Robustness",
    model: "Robustness_drop_4_variables",
    algorithm: "Robustness_drop_1_variables",
    description:
      "Model_1_and_2 : funding_type*day*last_investment_round_investors*indus ; Model_3 : funding_rounds",
    total_dataset: 59.597,
    usable_sample: 23.518,
    training_sample: 18.912,
    validation_sample: 4.606,
    data_points: 226.944,
    training_time: 56.97,
    running_time: 0.077,
    auc_growth_model: 0.715,
    model_prediction: 0.59,
  },
  {
    service: "Robustness",
    model: "Robustness_drop_2_variables",
    algorithm: "Robustness_drop_3_variables",
    description:
      "Model_1_and_2 : year*last_investment_round_pre_money_valuation ; Model_3 : funding_type*country_enc*total_funding_amount",
    total_dataset: 59.597,
    usable_sample: 23.518,
    training_sample: 18.912,
    validation_sample: 4.606,
    data_points: 226.944,
    training_time: 72.55,
    running_time: 0.093,
    auc_growth_model: 0.7954,
    model_prediction: 0.524,
  },
  {
    service: "Robustness",
    model: "Robustness_drop_2_variables",
    algorithm: "Robustness_drop_3_variables",
    description:
      "Model_1_and_2 : month*country_enc ; Model_3 : country_enc*last_investment_round_lead_investors*succes_ratio",
    total_dataset: 59.597,
    usable_sample: 23.518,
    training_sample: 18.912,
    validation_sample: 4.606,
    data_points: 226.944,
    training_time: 66.64,
    running_time: 0.078,
    auc_growth_model: 0.7979,
    model_prediction: 0.4,
  },
  {
    service: "Robustness",
    model: "Robustness_drop_4_variables",
    algorithm: "Robustness_drop_2_variables",
    description:
      "Model_1_and_2 : indus*day*last_investment_round_lead_investors*funding_rounds ; Model_3 : funding_rounds*indus",
    total_dataset: 59.597,
    usable_sample: 23.518,
    training_sample: 18.912,
    validation_sample: 4.606,
    data_points: 226.944,
    training_time: 58.14,
    running_time: 0.075,
    auc_growth_model: 0.7281,
    model_prediction: 0.598,
  },
  {
    service: "Robustness",
    model: "Robustness Algorithm",
    algorithm: "pcaNNet",
    description: "The algorithm name : Neural_Networks_with_Feature_Extraction",
    total_dataset: 59.597,
    usable_sample: 23.518,
    training_sample: 18.912,
    validation_sample: 4.606,
    data_points: 245.856,
    training_time: 5.03,
    running_time: 0.004,
    auc_growth_model: 0.7836,
    model_prediction: 0.2686,
  },
  {
    service: "Robustness",
    model: "Robustness Algorithm",
    algorithm: "simpls",
    description: "The algorithm name : Partial_Least_Squares",
    total_dataset: 59.597,
    usable_sample: 23.518,
    training_sample: 18.912,
    validation_sample: 4.606,
    data_points: 245.856,
    training_time: 1.67,
    running_time: 0.002,
    auc_growth_model: 0.7836,
    model_prediction: 0.5152,
  },
  {
    service: "Robustness",
    model: "Robustness Algorithm",
    algorithm: "svmLinear",
    description: "The algorithm name : Support_Vector_Machines",
    total_dataset: 59.597,
    usable_sample: 23.518,
    training_sample: 18.912,
    validation_sample: 4.606,
    data_points: 245.856,
    training_time: 63.14,
    running_time: 0.004,
    auc_growth_model: 0.7793,
    model_prediction: 0.2137,
  },
  {
    service: "Robustness",
    model: "Robustness Algorithm",
    algorithm: "knn",
    description: "The algorithm name : k_Nearest_Neighbors",
    total_dataset: 59.597,
    usable_sample: 23.518,
    training_sample: 18.912,
    validation_sample: 4.606,
    data_points: 245.856,
    training_time: 2.53,
    running_time: 0.005,
    auc_growth_model: 0.6948,
    model_prediction: 0.0002,
  },
  {
    service: "Robustness",
    model: "Robustness Algorithm",
    algorithm: "nnet",
    description: "The algorithm name : Neural_Network",
    total_dataset: 59.597,
    usable_sample: 23.518,
    training_sample: 18.912,
    validation_sample: 4.606,
    data_points: 245.856,
    training_time: 6.02,
    running_time: 0.004,
    auc_growth_model: 0.6707,
    model_prediction: 0.7181,
  },
  {
    service: "Robustness",
    model: "Robustness Algorithm",
    algorithm: "mlpML",
    description: "The algorithm name : Multi_Layer_Perceptron",
    total_dataset: 59.597,
    usable_sample: 23.518,
    training_sample: 18.912,
    validation_sample: 4.606,
    data_points: 245.856,
    training_time: 11.79,
    running_time: 0.016,
    auc_growth_model: 0.6707,
    model_prediction: 0.5053,
  },
  {
    service: "Robustness",
    model: "Robustness Algorithm",
    algorithm: "gamSpline",
    description: "The algorithm name : Generalized_Additive_Model",
    total_dataset: 59.597,
    usable_sample: 23.518,
    training_sample: 18.912,
    validation_sample: 4.606,
    data_points: 245.856,
    training_time: 3.99,
    running_time: 0.106,
    auc_growth_model: 0.7857,
    model_prediction: 0.0277,
  },
  {
    service: "Submodels",
    model: "Country",
    algorithm: "Random Forest",
    description: "Spain",
    total_dataset: 1.047,
    usable_sample: 0.45,
    training_sample: 0.37,
    validation_sample: 0.08,
    data_points: 4.81,
    training_time: 1.36,
    running_time: 0.005,
    auc_growth_model: 0.6867,
    model_prediction: 0.406,
  },
];

export const RobustnessComparisonItemGraph = ({
  title,
  description,
  group,
  dataToPlot,
  hasEvaluation = false,
  error = false,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <div>
      <div className={`flex font-semibold mt-4 text-sm mb-2 text-main border-b border-gray-lines`}>
        {title}
        {hasEvaluation && (!dataToPlot || error) && (
          <FaExclamationTriangle
            className={`ml-2 ${error ? "text-red" : "text-orange-400"}`}
            data-tip={t(error ? "evaluation_could_not_be_completed" : "pending_evaluation_result")}
          />
        )}
      </div>
      <div className="my-2 text-xs">{description}</div>
      <div className={"my-2 h-72"}>
        <ResponsiveSwarmPlot
          annotations={[
            {
              type: "circle",
              match: { id: 0 },
              noteX: 20,
              noteY: 40,
              offset: 4,
              note: "Main",
            },
          ]}
          axisTop={
            group !== "pre_valuation"
              ? {
                  orient: "top",
                  tickSize: 10,
                  tickPadding: 5,
                  tickRotation: 0,
                }
              : null
          }
          axisLeft={null}
          axisRight={null}
          data={
            dataToPlot
              ? dataToPlot.map((d, index) => {
                  return {
                    id: index,
                    group,
                    value:
                      group !== "pre_valuation"
                        ? Math.round(d.model_prediction * 100, 2)
                        : d.model_prediction,
                    volume: index ? 6 : 10,
                    algorithm: d.algorithm,
                    data_points: d.data_points,
                    total_dataset: d.total_dataset,
                    training_sample: d.training_sample,
                    usable_sample: d.usable_sample,
                    validation_sample: d.validation_sample,
                    training_time: d.training_time,
                    running_time: d.running_time,
                    variable_importance: d.variable_importance,
                  };
                })
              : ROBUSTNESS_SAMPLE_DATA.map((d, index) => {
                  return {
                    id: index,
                    group,
                    value:
                      group !== "pre_valuation"
                        ? Math.round(d.model_prediction * 100, 2)
                        : d.model_prediction,
                    volume: index ? 6 : 10,
                    algorithm: d.algorithm,
                    data_points: d.data_points,
                    total_dataset: d.total_dataset,
                    training_sample: d.training_sample,
                    usable_sample: d.usable_sample,
                    validation_sample: d.validation_sample,
                    training_time: d.training_time,
                    running_time: d.running_time,
                    variable_importance: d.variable_importance,
                  };
                })
          }
          groups={[group]}
          identity="id"
          value="value"
          colors={{ scheme: dataToPlot ? "blues" : "greys" }}
          colorBy="id"
          valueFormat="$.2f"
          valueScale={{
            type: "linear",
            min: 0,
            max:
              group !== "pre_valuation"
                ? 100
                : dataToPlot
                  ? max(dataToPlot.map((d) => d.model_prediction))
                  : 1,
            reverse: false,
          }}
          size={{
            key: "volume",
            values: [4, 20],
            sizes: [6, 20],
          }}
          layout="horizontal"
          forceStrength={4}
          simulationIterations={100}
          borderColor={{
            from: "color",
            modifiers: [
              ["darker", 0.6],
              ["opacity", 0.2],
            ],
          }}
          margin={{ top: 60, bottom: 60, right: 60, left: 60 }}
          axisBottom={
            group !== "pre_valuation"
              ? {
                  orient: "bottom",
                  tickSize: 10,
                  tickPadding: 5,
                  tickRotation: 0,
                  legendPosition: "middle",
                  legendOffset: 60,
                }
              : null
          }
          tooltip={function (data) {
            return dataToPlot ? (
              <div className={"bg-white rounded-xl p-4 max-w-sm text-xs"}>
                <div className={"mb-1"}>
                  <span className="font-semibold">{`${t("algorithm")}: `}</span>{" "}
                  <span className="ml-1">{data.data.algorithm}</span>
                </div>
                <div className={"mb-1"}>
                  <span className="font-semibold">{`${t("data_points")}: `}</span>{" "}
                  <span className="ml-1">{data.data.data_points}</span>
                </div>
                <div className={"mb-1"}>
                  <span className="font-semibold">{`${t("prediction")}: `}</span>{" "}
                  <span className="ml-1">
                    {group !== "pre_valuation"
                      ? data.data.value
                      : Utils.moneyFormatter(data.data.value)}
                  </span>
                </div>
                <div className={"mb-1"}>
                  <span className="font-semibold">{`${t("total_dataset")}: `}</span>{" "}
                  <span className="ml-1">{data.data.total_dataset}</span>
                </div>
                <div className={"mb-1"}>
                  <span className="font-semibold">{`${t("training_sample")}: `}</span>{" "}
                  <span className="ml-1">{data.data.training_sample}</span>
                </div>
                <div className={"mb-1"}>
                  <span className="font-semibold">{`${t("usable_sample")}: `}</span>{" "}
                  <span className="ml-1">{data.data.usable_sample}</span>
                </div>
                <div className={"mb-1"}>
                  <span className="font-semibold">{`${t("validation_sample")}: `}</span>{" "}
                  <span className="ml-1">{data.data.validation_sample}</span>
                </div>
                <div className={"mb-1"}>
                  <span className="font-semibold">{`${t("training_time")}: `}</span>{" "}
                  <span className="ml-1">{data.data.training_time}</span>
                </div>
                <div className={"mb-1"}>
                  <span className="font-semibold">{`${t("running_time")}: `}</span>{" "}
                  <span className="ml-1">{data.data.running_time}</span>
                </div>
                <div className={"mb-1 font-semibold"}>{t("most_highlighted_variables")}</div>
                {data.data.variable_importance && (
                  <ul className="list-disc ml-8">
                    {data.data.variable_importance.slice(0, 5).map((variable, index) => {
                      return <li key={index}>{variable}</li>;
                    })}
                  </ul>
                )}
              </div>
            ) : null;
          }}
        />
      </div>
    </div>
  );
};
