import { RoutesLinks } from "components/routes-links";
import { ButtonCardMain } from "components/ui/atoms/ButtonCardMain";
import { Card } from "components/ui/atoms/Card";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import { PopoverBoards } from "components/ui/atoms/PopoverBoards";
import { PieChart } from "components/ui/molecules/charts/PieChart";
import moment from "moment";
import { useTranslation } from "react-i18next";

export const BoardViewCard = ({ board, handleEditBoard, deletePipelineManager, handleRedirect, user }) => {
  const { t } = useTranslation();

  let cards = 0;
  for (const column of board.columns) {
    cards += column.cards.length;
  }

  const redirect = () =>
    handleRedirect(
      `${RoutesLinks.PIPELINE_MANAGER_LINK}boards/${board._id}`,
    );

  return (
  <Card borderColor={board.featured ? "yellow-500" : null} investor={user.investor._id !== board.investor._id ? board.investor : null}>
    <div className="my-2 flex justify-start items-start space-x-4 relative h-full">
      <div className="flex flex-col w-full h-full">
        <>
          <div className="flex flex-col">
            <div className="flex flex-row justify-between">
              <div className={"flex text-main font-bold"}>{board.name}</div>
              <div className="flex justify-end relative cursor-pointer">
                <PopoverBoards
                  items={
                    board.deleteable
                      ? ["edit_board_name", "delete"]
                      : ["edit_board_name"]
                  }
                  methods={{
                    edit_board_name: () => {
                      handleEditBoard(board);
                    },
                    delete: board.deleteable
                      ? () => deletePipelineManager(board)
                      : null,
                  }}
                />
              </div>
            </div>
            {cards !== 0 ? (
              <div className="w-full h-72 mb-6">
                <PieChart
                  legend={false}
                  data={board.columns.map((column) => ({
                    id: column.name,
                    label: column.name,
                    value: column.cards.length,
                  }))}
                />
              </div>
            ) : (
              <div className="w-full h-72 mb-6">
                <NoDataInfo
                  title={t(`empty_board_${board.entity_to_manage || "startups"}`)}
                />
              </div>
            )}
            <div className="text-xxs text-justify font-normal w-full mt-2">
              <span className="font-semibold">{`${t(
                board.entity_to_manage || "startups",
              )}:`}</span>{" "}
              <span>{cards}</span>
              <span className="font-semibold ml-4">{`${t("stages")}:`}</span>{" "}
              <span>{board.columns.length}</span>
            </div>
            <div className="text-xxs text-justify font-normal w-full mt-1">
              <span className="font-semibold">{`${t("created")}:`}</span>{" "}
              <span>{`${moment(board.createdAt).format("YYYY-MM-DD")}`}</span>
              <span className="font-semibold ml-4">{`${t("last_update_board")}:`}</span>{" "}
              <span>{`${moment(board.updatedAt).format("YYYY-MM-DD")}`}</span>
            </div>
            <div className={"pt-2 mt-4 border-t border-separator flex justify-end"}>
              <ButtonCardMain
                text={t("see_board")}
                onClick={redirect}
              />
            </div>
          </div>
        </>
      </div>
    </div>
  </Card>
  );
};
