import { FaExclamationTriangle, FaTimesCircle } from "react-icons/fa";
import { FaInfoCircle } from "react-icons/fa";
import Config from "config";

/**
 * @param text
 * @param style {'info', 'warning', 'error'}
 * @returns {JSX.Element}
 * @constructor
 */
export const Alert = ({ text, style, bgColor = "bg-gray-100" }) => {
  const borderColor = {
    info: `border-border-alert-info`,
    warning: "border-orange-400",
    error: "border-red-400",
  };
  return (
    <div className={`border-l-4 ${borderColor[style]} ${bgColor} p-4 my-3 flex gap-4 items-center`}>
      {style === "warning" && (
        <FaExclamationTriangle className="text-orange-400 inline-block relative top-[2px]" />
      )}
      {style === "error" && <FaTimesCircle className="text-red-400 inline-block" />}
      {style === "info" && <FaInfoCircle className={`text-text-alert-info inline-block`} />}
      {text}
    </div>
  );
};
