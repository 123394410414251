import NotesContainer from "components/ui/objects/NotesContainer";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import NotesService from "services/notes-service";

const BoardDealNotes = ({ deal, setLoading, user, setDeal, setShowMigrationSection }) => {
  const { addToast } = useToasts();
  const [showNotesContainer, setShowNotesContainer] = useState(false);
  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [noteToMigrate, setNoteToMigrate] = useState(null);
  const { t } = useTranslation();

  const getNotes = async() => {
    try {
      setLoading(true);
      const result = await NotesService.getNotes({ deal });
      setNotes(result.data.data);
    } catch (error) {
      addToast(t("error_occurred_retrieving_notes"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const createNote = async(title, type, content) => {
    try {
      setLoading(true);
      await NotesService.createNote({
        user: user._id,
        title,
        content,
        type,
        deal,
      });
      getNotes();
    } catch (error) {
      addToast(t("error_occurred_creating_note"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const updateNote = async(title, content) => {
    try {
      setLoading(true);
      await NotesService.updateNote(selectedNote._id, { title, content });
      setSelectedNote(null);
      getNotes();
    } catch (error) {
      addToast(t("error_occurred_updating_note"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteNote = async(noteId) => {
    confirm().then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await NotesService.deleteNote(noteId);
          getNotes();
        } catch (error) {
          addToast(t("error_occurred_deleting_note"), {
            appearance: "error",
            autoDismiss: true,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const getNote = async(noteId) => {
    try {
      setLoading(true);
      const result = await NotesService.getNote(noteId);
      setSelectedNote(result.data.data);
    } catch (error) {
      addToast(t("error_occurred_retrieving_note"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (deal) {
      setShowNotesContainer(true);
      getNotes();
    }
  }, [deal]);

  return (
    <>
      {showNotesContainer && (
        <NotesContainer
          zIndex="20"
          listTitle={t("notes")}
          setFiltersButtons={false}
          setShowModal={setShowNotesContainer}
          notes={notes}
          createNote={createNote}
          updateNote={updateNote}
          deleteNote={deleteNote}
          getNote={getNote}
          selectedNote={selectedNote}
          setSelectedNote={setSelectedNote}
          onClose={() => {
            setSelectedNote(null);
            setDeal(null);
            setShowNotesContainer(false);
          }}
          setShowMigrationSection={(noteId) => {
            setShowNotesContainer(false);
            setShowMigrationSection(true);
            setNoteToMigrate(noteId);
          }}
        />
      )}
    </>
  );
};

export default BoardDealNotes;
