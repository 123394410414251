import { useTranslation } from "react-i18next";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import Config from "config";
import FirstItem from "assets/images/onboarding_second_step_first_item.png";
import SecondItem from "assets/images/onboarding_second_step_second_item.png";
import ThirdItem from "assets/images/onboarding_second_step_third_item.png";
import FourthItem from "assets/images/onboarding_second_step_fourth_item.png";
import FifthItem from "assets/images/onboarding_second_step_fifth_item.png";
import SixthItem from "assets/images/onboarding_second_step_sixth_item.png";

import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";

const SecondStep = ({ nextStep, previousStep }) => {
  const { t } = useTranslation();

  return (
    <div className="bg-bgNewOnboardingOne bg-cover bg-no-repeat bg-center min-w-screen min-h-screen relative flex justify-center align-center">
      <div className="flex items-center text-center w-1/3 p-8">
        <div className="flex flex-col justify-center items-center bg-white p-6 rounded-lg w-full">
          <h1 className={`text-secondary mb-8`}>{t("onboarding_second_step_title")}</h1>
          <div className="px-10 w-full">
            <div className="grid grid-cols-2 gap-8 w-full mb-10">
              <div className="flex items-center flex-col">
                <img src={SecondItem} alt="Test" className="w-10 mb-2" />
                <p className="text-xs sm:text-sm font-bold">
                  {t("onboarding_second_step_second_item")}
                </p>
              </div>
              <div className="flex items-center flex-col">
                <img src={FirstItem} alt="Test" className="w-10 mb-2" />
                <p className="text-xs sm:text-sm font-bold">
                  {t("onboarding_second_step_first_item")}
                </p>
              </div>
              <div className="flex items-center flex-col">
                <img src={ThirdItem} alt="Test" className="w-10 mb-2" />
                <p className="text-xs sm:text-sm font-bold">
                  {t("onboarding_second_step_third_item")}
                </p>
              </div>
              <div className="flex items-center flex-col">
                <img src={FourthItem} alt="Test" className="w-10 mb-2" />
                <p className="text-xs sm:text-sm font-bold">
                  {t("onboarding_second_step_fourth_item")}
                </p>
              </div>
              <div className="flex items-center flex-col">
                <img src={FifthItem} alt="Test" className="w-10 mb-2" />
                <p className="text-xs sm:text-sm font-bold">
                  {t("onboarding_second_step_fifth_item")}
                </p>
              </div>
              <div className="flex items-center flex-col">
                <img src={SixthItem} alt="Test" className="w-10 mb-2" />
                <p className="text-xs sm:text-sm font-bold">
                  {t("onboarding_second_step_sixth_item")}
                </p>
              </div>
            </div>
          </div>
          <div className={"w-full flex justify-between"}>
            <div className="flex items-center justify-center mr-3 font-bold">2/3</div>
            <div className="flex">
              <ButtonSecondary
                onClick={previousStep}
                type="button"
                text={t("go_back")}
                marginRight={2}
              />
              <ButtonMain onClick={nextStep} type="button" text={t("next")} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondStep;
