import { RoutesLinks } from "components/routes-links";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { MiniHelp } from "components/ui/atoms/MiniHelp";
import { PageTitle } from "components/ui/atoms/PageTitle";
import { SectionHeader } from "components/ui/molecules/SectionHeader";
import useUser from "hooks/useUser";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaFilter, FaPlus } from "react-icons/fa";
import { Outlet, useNavigate } from "react-router-dom";
import { getPathSegment } from "utils/get-path-segment";

const QuestionnairesEngineView = () => {
  const { user } = useUser();

  const { t } = useTranslation();

  const pageRef = useRef();

  const [section, setSection] = useState(getPathSegment(location.href, 2));
  const [sections, setSections] = useState([{ id: "forms", title: "forms", route: "forms" }, { id: "questions", title: "questions", route: "questions" }, { id: "templates", title: "templates", route: "templates" }]);
  const [pageTitle, setPageTitle] = useState();
  const [filtersCount, setFiltersCount] = useState(0);

  const navigate = useNavigate();

  return (
        <>
        <div className="flex items-start">
          <div className="flex-1">
            {pageTitle && <PageTitle title={t(pageTitle)} />}
          </div>
          {section === "forms" && (
            <>
            {!user?.help?.create_questionnaire && !user.lp && (
                <MiniHelp item={"create_questionnaire"} />
            )}

            {!user.lp && (
              <ButtonMain
                iconComponent={<FaPlus className="inline-block mr-2" />}
                text={t("create_form")}
                onClick={() => pageRef.current.showCreateForm()}
              />
            )}
            </>
          )}
          {section === "questions" && (
            <>
              {!user?.help?.create_question && <MiniHelp item={"create_question"} />}
              <ButtonMain
                iconComponent={<FaPlus className="inline-block mr-2" />}
                marginRight="2"
                text={t("create_question")}
                onClick={() => navigate(`${RoutesLinks.QE_QUESTIONS_WIZARD_LINK}`)}
              />

              <div>
                <ButtonSecondary
                  iconComponent={<FaFilter className="h-4" />}
                  onClick={() => pageRef.current?.showFilters()}
                  badgeBgColor={"red"}
                  badgeContent={filtersCount > 0 ? filtersCount : null}
                />
              </div>
            </>
          )}

        </div>
          <SectionHeader
            sectionTitles={sections.map((section) => t(section))}
            sectionKeys={sections}
            section={section}
            setSection={(section) => setSection(section)}
            navigate
          />
        <Outlet context={{ setPageTitle, ref: pageRef, setFiltersCount }}/>
        </>
  );
};

export default QuestionnairesEngineView;
