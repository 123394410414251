import { useTranslation } from "react-i18next";
import { Input } from "components/ui/atoms/Input";
import { RichEditor } from "components/ui/objects/RichEditor";
import { Switch } from "components/ui/atoms/Switch";
import { Card } from "components/ui/atoms/Card";
import { TfiLayoutSlider } from "react-icons/tfi";
import { MdFormatAlignJustify } from "react-icons/md";
import Spanish from "styles/images/spanish.svg";
import English from "styles/images/english.jpg";
import { FaQuestionCircle } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { useEffect, useState } from "react";
import { ButtonCardMain } from "components/ui/atoms/ButtonCardMain";
import Select from "components/ui/atoms/Select";
import useUser from "hooks/useUser";

import FormsService from "services/forms-service";
import { useSwal } from "hooks/useSwal";

const marieTypes = [
  { value: "marie_lite", id: "marie_lite" },
  { value: "marie_pro", id: "marie_pro" },
  { value: "marie_test", id: "marie_test" },
  { value: "marie_academic", id: "marie_academic" },
];

export const FormGeneralInformation = ({
  isMarieLite,
  isMarieEntrepreneur,
  isMariePro,
  setIsMarieLite,
  setIsMarieEntrepreneur,
  setIsMariePro,
  marieLiteRecipient,
  marieProRecipient,
  setMarieLiteRecipient,
  marieClient,
  setMarieClient,
  setMarieProRecipient,
  name,
  setName,
  formNotificationEmail,
  setFormNotificationEmail,
  formEndedDescription,
  setFormEndedDescription,
  sendNotificationEmail,
  setSendNotificationEmail,
  customEndedMessage,
  setCustomEndedMessage,
  visibilityMode,
  setVisibilityMode,
  showInvestorsPercentage,
  setShowInvestorsPercentage,
  languages = [],
  setLanguages,
  assignStartupsToPipelineManager,
  setAssignStartupsToPipelineManager,
  pipelineManagerBoard,
  setPipelineManagerBoard,
  pipelineManagerStage,
  setPipelineManagerStage,
  stages,
  boards,
  onSelectBoard,
  onSelectStage,
  boardInitialValues,
  stageInitialValues,
  preScoreThreshold,
  setPreScoreThreshold,
  formAsMain,
  setFormAsMain,
  formId,
  marieType,
  marieRecipient,
  setMarieRecipient,
  setMarieType,
}) => {
  const { t } = useTranslation();
  const { user } = useUser();

  const { confirm } = useSwal();
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const [emailInputs, setEmailInputs] = useState(formNotificationEmail.split(";"));

  const handleEmailChange = (index, event) => {
    const newEmailInputs = [...emailInputs];
    newEmailInputs[index] = event.target.value;
    setEmailInputs(newEmailInputs);
    setFormNotificationEmail(newEmailInputs.join(";"));
  };

  const addEmailInput = () => {
    setEmailInputs([...emailInputs, ""]);
  };

  const removeEmailInput = (index) => {
    const newEmailInputs = emailInputs.filter((_, i) => i !== index);
    setEmailInputs(newEmailInputs);
    setFormNotificationEmail(newEmailInputs.join(";"));
  };

  const handleSetAsMain = (checked) => {
    if (checked) {
      // find if theres already a main form
      FormsService.getQuestionnairesForms({ set_as_main: true }).then((response) => {
        const foundMainForms = response.data.data.forms.filter((form) => form.set_as_main);
        if (foundMainForms.length && foundMainForms[0]._id !== formId) {
          confirm({
            text: t("main_form_already_found"),
            icon: "warning",
          }).then(async(isConfirmed) => {
            if (isConfirmed) {
              FormsService.updateQuestionnairesForm(foundMainForms[0]._id, {
                set_as_main: false,
              }).then((response) => {});
              setFormAsMain(true);
            } else {
              setFormAsMain(false);
            }
          });
        } else {
          setFormAsMain(true);
        }
      });
    } else {
      setFormAsMain(false);
    }
  };

  return (
    <>
      <div className="w-full px-1 mt-6 text-xs font-semibold">{t("configure_form_name")}</div>
      <div className="my-2">
        <Input
          id="name"
          type="text"
          name="name"
          value={name}
          placeholder={t("name")}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>

      <div className="w-full px-1 pt-4 my-2 text-xs font-semibold border-t">
        {t("configure_form_visibility_mode")}
      </div>
      <div className="grid grid-cols-1 gap-4 mb-6 md:grid-cols-2">
        <div
          className={`text-xs ${visibilityMode === "single_page" && "rounded-2xl border-2 border-border-buttons-secondary"}`}
        >
          <Card wrapperClassName="h-full">
            <div className="flex flex-row">
              <div className="px-2">
                <MdFormatAlignJustify />
              </div>
              <div className="flex flex-col">
                <div className="text-xs font-semibold">{t("single_page_form")}</div>
                <div className="my-2 text-xs">{t("single_page_form_description")}</div>
                <div className="text-xxxs">{t("single_page_form_warning")}</div>
              </div>
            </div>
            {visibilityMode !== "single_page" && (
              <div className="flex justify-end">
                <ButtonCardMain
                  text={t("select_option")}
                  onClick={() => setVisibilityMode("single_page")}
                />
              </div>
            )}
            {visibilityMode === "single_page" && (
              <div
                className={"font-bold text-xs flex justify-end border-t border-separator pt-2 mt-2"}
              >
                {t("selected_option")}
              </div>
            )}
          </Card>
        </div>
        <div
          className={`text-xs ${visibilityMode === "multiple_page" && "rounded-2xl border-2 border-border-buttons-secondary"}`}
        >
          <Card wrapperClassName="h-full">
            <div className="flex flex-row">
              <div className="px-2">
                <TfiLayoutSlider />
              </div>
              <div className="flex flex-col">
                <div className="text-xs font-semibold">{t("multiple_page_form")}</div>
                <div className="my-2 text-xs">{t("multiple_page_form_description")}</div>
              </div>
            </div>
            {visibilityMode !== "multiple_page" && (
              <div className="flex justify-end">
                <ButtonCardMain
                  text={t("select_option")}
                  onClick={() => setVisibilityMode("multiple_page")}
                />
              </div>
            )}
            {visibilityMode === "multiple_page" && (
              <div
                className={"font-bold text-xs flex justify-end border-t border-separator pt-2 mt-2"}
              >
                {t("selected_option")}
              </div>
            )}
          </Card>
        </div>
      </div>

      {/* TODO: MOSTRAR ESTO SOLAMENTE PARA EL CLIENTE ESPECÍFICO Y PONER EL TEXTO HARCODEADO COMO TRADUCCIONES */}
      {user.investor._id === "65c6216207c8bd1a99abf0a1" && (
        <>
          <div className="w-full px-1 pt-4 mt-4 mb-2 text-xs font-semibold border-t">
            {t("marie_entrepreneur")}
          </div>
          <Switch
            checked={isMarieEntrepreneur}
            onChange={(checked) => setIsMarieEntrepreneur(checked)}
            text={isMarieEntrepreneur ? t("yes") : t("no")}
          />
          <div className="mt-4">
            <Select
              label={t("type")}
              items={marieTypes}
              initialValues={marieType ? [marieTypes.find((type) => type.id === marieType)] : []}
              onSelect={(v) => {
                setMarieType(v[0].id);
              }}
            />
          </div>
          {!isMarieEntrepreneur && (
            <>
              <div className="w-full px-1 mt-4 mb-2 text-xs font-semibold">{t("email")}</div>
              <Input
                value={marieRecipient}
                onChange={(e) => setMarieRecipient(e.target.value)}
                name="marie_recipient"
                placeholder={"Email"}
              />
                  <div className="w-full px-1 mt-4 mb-2 text-xs font-semibold">{t("customer name")}</div>
              <Input
                value={marieClient}
                onChange={(e) => setMarieClient(e.target.value)}
                name="marie_client"
                placeholder={"client"}
              />
          </>
          )}
        </>
      )}
      {/* TODO: HASTA AQUÍ */}

      {/* <div className="w-full px-1 mb-2 text-xs font-semibold">{t('would_you_like_to_include_questions_investors_percentage')}</div>
      <Switch
        checked={showInvestorsPercentage}
        onChange={(checked) => setShowInvestorsPercentage(checked)}
        text={showInvestorsPercentage ? t('yes') : t('no')}
      /> */}
      <>
        <div>
          <div className="w-full px-1 pt-4 mt-4 mb-2 text-xs font-semibold border-t">
            {t("would_you_like_to_add_startups_automatically_to_a_pipeline_manager")}
          </div>
          <div className="w-full mt-2 mb-4 text-xs">
            {t("add_startups_to_pipeline_manager_automatically_description")}
          </div>
          <Switch
            checked={assignStartupsToPipelineManager}
            onChange={(checked) => {
              setAssignStartupsToPipelineManager(checked);
              onSelectBoard(null);
              onSelectStage(null);
            }}
            text={
              assignStartupsToPipelineManager
                ? t("yes_assign_startups_automatically")
                : t("dont_assign_startups_automatically")
            }
          />
          {assignStartupsToPipelineManager && (
            <div>
              <div className="w-full px-1 mt-4 mb-2 text-xs font-semibold">
                {t("what_board_do_you_want_to_use")}
              </div>
              <Select
                name="pipeline_manager_board"
                label={t("board")}
                isClearable={false}
                initialValues={boardInitialValues}
                onSelect={(selection) => {
                  onSelectBoard(selection[0]?.id);
                  onSelectStage(null);
                }}
                items={boards.map((board) => ({
                  value: board.columns.length
                    ? board.name
                    : `${board.name} (${t("empty_board_disabled")})`,
                  id: board._id,
                }))}
              />
            </div>
          )}
          {pipelineManagerBoard && (
            <div>
              <div className="w-full px-1 mt-4 mb-2 text-xs font-semibold">
                {t("what_stage_do_you_want_to_use")}
              </div>
              <Select
                isClearable={false}
                name="pipeline_manager_stage"
                label={t("stage")}
                initialValues={stageInitialValues}
                onSelect={(selection) => onSelectStage(selection[0]?.id)}
                items={stages}
              />
            </div>
          )}
        </div>
        {![undefined, null].includes(preScoreThreshold) && (
          <>
            <div className="w-full px-1 pt-4 mt-4 mb-2 text-xs font-semibold border-t">
              {t("would_you_like_to_configure_pre_scoring_threshold")}
            </div>
            <Input
              value={preScoreThreshold}
              onChange={(e) => setPreScoreThreshold(e.target.value)}
              name="preScoreThreshold"
              placeholder={t("pre_score_threshold_placeholder")}
            />
          </>
        )}
        <div className="w-full px-1 pt-4 mt-4 mb-2 text-xs font-semibold border-t">
          {t("would_you_like_to_send_application_notification_email")}
        </div>
        <Switch
          checked={sendNotificationEmail}
          onChange={(checked) => setSendNotificationEmail(checked)}
          text={sendNotificationEmail ? t("yes") : t("no")}
        />
        {sendNotificationEmail && (
          <>
            <div className="w-full px-1 mt-4 mb-2 text-xs font-semibold">
              {t("configure_application_notification_email")}
            </div>
            {emailInputs.map((email, index) => (
              <div key={index} className="flex items-center mb-2 gap-4">
                <Input
                  value={email}
                  onChange={(e) => handleEmailChange(index, e)}
                  name={`application_notification_email_${index}`}
                  placeholder={t("application_notification_email_description")}
                  className="mr-2"
                />
                {index > 0 && (
                  <button className="text-sm" type="button" onClick={() => removeEmailInput(index)}>
                    {t("remove")}
                  </button>
                )}
              </div>
            ))}
            <button type="button" onClick={addEmailInput} className="text-blue-500">
              {t("add_email")}
            </button>
          </>
        )}
        <div className="w-full px-1 pt-4 my-6 mb-2 text-xs font-semibold border-t">
          {t("would_you_like_to_customize_the_ended_application_message")}
        </div>
        <Switch
          checked={customEndedMessage}
          onChange={(checked) => setCustomEndedMessage(checked)}
          text={customEndedMessage ? t("yes") : t("no")}
        />

        {customEndedMessage && (
          <div>
            <div className="w-full px-1 my-6 mb-2 text-xs font-semibold">
              {t("application_form_ended_label")}
            </div>
            <RichEditor
              name="application_form_ended_description"
              placeholder={t("application_form_ended_description")}
              className="mb-4"
              value={formEndedDescription}
              toolbarClassName="application_form_ended_description"
              onChange={(value) => {
                setFormEndedDescription(value === "<p><br></p>" ? "" : value);
              }}
              // TODO: TAKE A LOOK TO FUTHER BUMS FOR react-quill LIBRARY TO SOLVE CLEAN ISSUE
            />
          </div>
        )}
        {/* <div className="w-full px-1 pt-4 my-6 mb-2 text-xs font-semibold border-t">{t('would_you_like_to_customize_the_ended_application_message')}</div>
        <Switch
          checked={customEndedMessage}
          onChange={(checked) => setCustomEndedMessage(checked)}
          text={customEndedMessage ? t('yes') : t('no')}
        /> */}
        <div className="w-full px-1 pt-4 my-6 mb-2 text-xs font-semibold border-t">
          {t("would_you_like_to_mark_form_as_main")}
        </div>
        <Switch
          checked={formAsMain}
          onChange={(checked) => handleSetAsMain(checked)}
          text={formAsMain ? t("yes") : t("no")}
        />
      </>
      <div className="flex w-full px-1 pt-4 my-6 mb-2 text-xs font-semibold border-t">
        {t("configure_languages_form")}
        <FaQuestionCircle
          className="ml-0.5 cursor-pointer"
          data-tip={t("configure_languages_form_help")}
        />
      </div>
      <div className="flex flex-row mb-3">
        <div
          onClick={() =>
            languages.includes("es")
              ? setLanguages(languages.filter((language) => language !== "es"))
              : setLanguages([...languages, "es"])
          }
          className={`w-8 p-1 hover:shadow-inner rounded-md cursor-pointer bg-white ${languages.includes("es") ? "border shadow-inner border-main" : "shadow-soft-white"}`}
        >
          <img src={Spanish} alt="Spanish" />
        </div>

        <div
          onClick={() =>
            languages.includes("en")
              ? setLanguages(languages.filter((language) => language !== "en"))
              : setLanguages([...languages, "en"])
          }
          className={`bg-white w-8 p-1 hover:shadow-inner ml-2 rounded-md cursor-pointer ${languages.includes("en") ? "border shadow-inner border-main" : "shadow-soft-white"}`}
        >
          <img src={English} alt="English" style={{ height: 13 }} />
        </div>
      </div>
    </>
  );
};
