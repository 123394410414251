import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

export function useSwal() {
  const { t } = useTranslation();

  const defaultOptions = {
    reverseButtons: true,
    icon: "info",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    confirmButtonText: t("accept"),
    cancelButtonText: t("cancel"),
    heightAuto: false,
    buttonsStyling: false,
    cancelButtonClass:
      "border hover:border-border-buttons-secondary border-border-buttons-secondary bg-bg-buttons-secondary shadow-soft-white text-text-buttons-secondary block w-auto my-5 py-2 px-7 | text-center text-sm font-medium  placeholder-gray | rounded-2xl  | cursor-pointer transition-all duration-500 ease-in-out | hover:shadow-inner focus:outline-none",
    confirmButtonClass:
      "bg-bg-buttons-main shadow-soft-white text-text-buttons-main block w-auto my-5 py-2 px-7 | text-center text-sm font-medium  placeholder-gray | rounded-2xl | cursor-pointer transition-all duration-500 ease-in-out |  hover:border-none hover:shadow-inner focus:outline-none",
    padding: "1rem",
    customClass: {
      popup: "rounded-2xl p-4 font-sans",
      actions: "justify-between",
      icon: "border-main text-main shadow-soft-white rounded-full",
      title: "text-black text-2xl",
    },
  };

  return {
    /**
     * @param options{SweetAlertOptions}? Swal options to override defaults
     * @returns {Promise<boolean>}
     */
    confirm: (options = {}) =>
      Swal.fire({
        ...defaultOptions,
        title: t("are_you_sure"),
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: t("yes"),
        cancelButtonText: t("cancel"),
        ...options,
      }).then((result) => result.isConfirmed),

    /**
     * @param options{SweetAlertOptions}? Swal options to override defaults
     * @returns {Promise<SweetAlertResult<Awaited<any>>>}
     */
    alert: (options = {}) =>
      Swal.fire({
        ...defaultOptions,
        cancelButtonClass: "invisible",
        ...options,
      }),
  };
}
