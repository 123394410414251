export function TrafficLight({ identifier, onClick, disabled = false, value, ...inputProps }) {
  return (
    <div key={identifier}>
      <div className="flex">
        <div
          className={`rounded-full cursor-pointer w-8 h-8 mr-2 border border-red ${!disabled && "hover:bg-red"} ${value === 0 && "bg-red"}`}
          onClick={() => (disabled ? null : onClick(0))}
        />
        <div
          className={`rounded-full cursor-pointer w-8 h-8 mr-2 border border-orange-400 ${!disabled && "hover:bg-orange-400"} ${value === 5 && "bg-orange-400"}`}
          onClick={() => (disabled ? null : onClick(5))}
        />
        <div
          className={`rounded-full cursor-pointer w-8 h-8 mr-2 border border-green ${!disabled && "hover:bg-green"} ${value === 10 && "bg-green"}`}
          onClick={() => (disabled ? null : onClick(10))}
        />
      </div>
    </div>
  );
}
