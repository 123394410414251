import { FaChevronDown, FaExclamationTriangle, FaStar } from "react-icons/fa";
import { BsSpeedometer2 } from "react-icons/bs";
import { TfiTarget } from "react-icons/tfi";
import { TagListDots } from "components/tags/TagListDots";
import { useTranslation } from "react-i18next";
import Utils from "utils/utils";
import { BiSelectMultiple } from "react-icons/bi";

export const BoardCardCollapsedHeader = ({
  preScorings,
  dealId,
  tags,
  title,
  imageComponent,
  onClick,
  startupToSearch = null,
  stageDate = null,
  hasSomeHardFilterUnmatched = null,
  hasReferral = false,
  multipleApply,
}) => {
  const { t } = useTranslation();
  return (
    <div className="relative flex flex-col w-full" onClick={() => onClick && onClick()}>
      <div>
        <div className="flex items-center">
          {imageComponent}
          <div>
            <span
              className={`text-xs font-medium ${startupToSearch && dealId === startupToSearch && "bg-yellow-400"}`}
            >
              {title}
            </span>
            {hasSomeHardFilterUnmatched && (
              <FaExclamationTriangle
                className="inline w-3 h-3 ml-1 text-red"
                data-tip={t("deal_does_not_match_some_hard_filter")}
              />
            )}
            {stageDate && (
              <div className="flex flex-row items-center justify-center mb-2 text-xxs">
                <TfiTarget className="w-4 h-4 mr-1" data-tip={t("stage_date_tooltip")} />
                {`${t("stage_date_label")}:`}
                <span className="ml-1 font-bold">
                  {Utils.getDifferenceBetweenDates(stageDate, t)}
                </span>
              </div>
            )}
            {preScorings &&
              preScorings.length > 0 &&
              preScorings.filter((ps) => ps.score !== null && ps.score !== undefined).length !==
                0 && (
                <div className="flex flex-row items-center mb-2 text-xxs justify-left">
                  <BsSpeedometer2 className="w-4 h-4 mr-1" data-tip={t("pre_score")} />
                  {`${t("pre_score")}:`}
                  <span className="ml-1 font-bold">
                    {preScorings.length > 1
                      ? `${preScorings[preScorings.length - 1].score} (+${preScorings.length - 1})`
                      : preScorings[preScorings.length - 1].score}
                  </span>
                </div>
              )}
          </div>
        </div>
      </div>

      <div className="absolute right-2 top-1">
        <span className={"text-sm text-center text-main w-5 h-5"}>
          <FaChevronDown />
        </span>
      </div>

      <div className="ml-2 flex gap-2 items-center">
        <TagListDots tags={tags} className="inline" />
        {hasReferral && <FaStar className="inline w-4 text-xs" />}
        {multipleApply > 1 && (
          <BiSelectMultiple
            data-tip={t("startup_applied_multiple_tooltip", { count: multipleApply })}
            className="inline w-4 text-xs text-[#E20606]"
          />
        )}
      </div>
    </div>
  );
};
