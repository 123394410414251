import { RoutesLinks } from "components/routes-links";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { Card } from "components/ui/atoms/Card";
import { Input } from "components/ui/atoms/Input";
import Select from "components/ui/atoms/Select";
import { SidePanel } from "components/ui/objects/SidePanel";
import { useSwal } from "hooks/useSwal";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaRegClone, FaRegFile } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import EvaluationProcessesManagerService from "services/evaluation-processes-manager-service";

const CreateEvaluationProcessTypeSelector = ({
  onClose,
  getEvaluationProcesses,
  setShowEvaluationProcessWizard,
}) => {
  const { t } = useTranslation();

const navigate = useNavigate();
  const { confirm } = useSwal();
  const { addToast } = useToasts();

  const [clone, setClone] = useState(false);

  const [formItems, setFormItems] = useState([]);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    register("event");
    getEvents();
  }, []);

  const getEvents = (archived = false) => {
    EvaluationProcessesManagerService.getEvaluationProcesses({
      page: 0,
      rowsPerPage: 9999,
      sortBy: "-createdAt",
      archived: false,
    })
      .then((result) => {
        console.log(result);
        setFormItems(
          result.data.data.events.map((event) => ({ id: event._id, value: event.name })),
        );
      })
      .catch((error) => {
        addToast(t("error_occurred_retrieving_events"), {
          appearance: "error",
          autoDismiss: true,
        });
        console.error(error);
      });
  };

  const onSubmit = (data) => {
    confirm({
      text: t("clone_event_confirmation"),
      icon: "success",
    }).then(async(isConfirmed) => {
      if (isConfirmed) {
        const eventData = {
          name: data.name,
        };

        try {
          await EvaluationProcessesManagerService.cloneEvaluationProcess(data.event.id, eventData);
          addToast(t("event_created_succesfully"), {
            appearance: "success",
            autoDismiss: true,
          });
          getEvaluationProcesses();
          onClose();
        } catch (error) {
          addToast(t("error_creating_evaluation_process"), {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }
    });
  };

  return (
    <SidePanel width={"1/4"} onClose={onClose} title={t("create_event")}>
      <div className={"flex flex-col max-h-full"}>
        {!clone && (
          <>
            <Card wrapperClassName="my-2">
              <h3>{t("create_event_blank")}</h3>
              <p className="text-sm">{t("create_event_blank_description")}</p>
              <div className="flex items-center justify-center mt-8">
                <FaRegFile size={60} />
              </div>
              <ButtonSecondary
                width={"full"}
                marginTop={4}
                text={t("select_this")}
                onClick={setShowEvaluationProcessWizard}
              />
            </Card>
            <Card wrapperClassName="my-2">
              <h3>{t("clone_event")}</h3>
              <p className="text-sm">{t("clone_event_description")}</p>
              <div className="flex items-center justify-center mt-8">
                <FaRegClone size={60} />
              </div>
              <ButtonSecondary
                width={"full"}
                marginTop={4}
                text={t("select_this")}
                onClick={() => setClone(true)}
              />
            </Card>
          </>
        )}
        {clone && (
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
            <p className="mb-8 text-sm">{t("clone_event_description")}</p>
            <Input reference={register} name="name" label={t("event_name")} error={errors.name} />

            <div className="my-3">
              <Select
                name="event"
                label={t("select_event")}
                multiSelect={false}
                isClearable={false}
                items={formItems}
                initialValues={[]}
                onSelect={(val) => {
                  setValue("event", val[0]);
                }}
              />
            </div>
            <div className="flex gap-2 mt-4 justify-end">
              <ButtonSecondary
                onClick={() => setClone(false)}
                type={"button"}
                text={t("back")}
              ></ButtonSecondary>
              <ButtonMain text={t("create_evaluation_process")} />
            </div>
          </form>
        )}
      </div>
    </SidePanel>
  );
};

export default CreateEvaluationProcessTypeSelector;
