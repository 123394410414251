import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { SectionHeader } from "components/ui/molecules/SectionHeader";
import { useToasts } from "react-toast-notifications";
import { useParams, useLocation, useNavigate, Outlet } from "react-router-dom";

import { RoutesLinks } from "components/routes-links";
import { Loader } from "components/ui/molecules/Loader";
import NotesContainer from "components/ui/objects/NotesContainer";
import { isEmpty } from "lodash";
import useUser from "hooks/useUser";
import { useSwal } from "hooks/useSwal";
import { FaPlus } from "react-icons/fa";
import { PageTitle } from "components/ui/atoms/PageTitle";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { TbNotes } from "react-icons/tb";
import { DealflowService } from "services/dealflow-service";
import { DealResumeSection } from "components/dealflow/DealResumeSection";
import NotesService from "services/notes-service";
import DocumentsService from "services/documents-service";
import EvaluationProcessesManagerService from "services/evaluation-processes-manager-service";
import MigrationSection from "components/ui/molecules/MigrationSection";
import { AiOutlineMore } from "react-icons/ai";
import { getPathSegment } from "utils/get-path-segment";

const Application = () => {
  const location = useLocation();

  const { t } = useTranslation();
  const { eventId, startupId } = useParams();
  const { addToast } = useToasts();
  const { user } = useUser();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState(getPathSegment(window.location.href, 6) ?? "information");
  const [event, setEvent] = useState(null);
  const [showNotes, setShowNotes] = useState(false);
  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [loadingGetNotes, setLoadingGetNotes] = useState(true);
  const [loadingCreateNote, setLoadingCreateNote] = useState(false);
  const [loadingUpdateNote, setLoadingUpdateNote] = useState(false);
  const [loadingDeleteNote, setLoadingDeleteNote] = useState(false);
  const [selectedJudge, setSelectedJudge] = useState(null);
  const [loadingGetNote, setLoadingGetNote] = useState(false);
  const [evaluation, setEvaluation] = useState(null);
  const { confirm } = useSwal();
  const [info, setInfo] = useState(null);
  const [answerToExpand, setAnswerToExpand] = useState(null);
  const [deal, setDeal] = useState(null);
  const [showDealInfo, setShowDealInfo] = useState(false);
  const [redFlags, setRedFlags] = useState({});
  const [dealflow, setDealflow] = useState([]);
  const [showMigrationSection, setShowMigrationSection] = useState(false);
  const [noteToMigrate, setNoteToMigrate] = useState(null);
  const [documentToMigrate, setDocumentToMigrate] = useState(null);

  const pageRef = useRef();

  const getDealflow = () => DealflowService.getDealflow({
    rowsPerPage: 999999,
    pool: "true",
    populate: "false",
    boards: "false",
    milestones: "false",
    files: "false",
    reminders: "false",
    annotations: "false",
  }).then((response) => {
    setDealflow(response.data.data.dealflow);
  });

  const getEvent = async() => {
    try {
      setLoading(true);
      const result = await EvaluationProcessesManagerService.getEvaluationProcess(eventId);
      if (!isEmpty(result.data.data)) {
        getSharedDeal();
        setEvent(result.data.data);
      } else {
        navigate(RoutesLinks.EVALUATION_PROCESSES_LINK);
      }
    } catch (error) {
      addToast(t("error_occurred_retrieving_event"), {
        appearance: "error",
        autoDismiss: true,
      });
      navigate(RoutesLinks.EVALUATION_PROCESSES_LINK);
    } finally {
      setLoading(false);
    }
  };

  const getSharedDeal = async() => {
    try {
      setLoading(true);
      const result = await DealflowService.getSharedDeal(startupId);
      if (result.data) {
        setDeal(result.data.data);
      } else {
        addToast(t("error_occurred_retrieving_information"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } catch (error) {
      addToast(t("error_occurred_retrieving_information"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const getNotes = async() => {
    try {
      setLoadingGetNotes(true);
      const result = await NotesService.getNotes({ deal: startupId });
      setNotes(result.data.data);
    } catch (error) {
      addToast(t("error_occurred_retrieving_notes"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoadingGetNotes(false);
    }
  };

  const createNote = async(title, type, content) => {
    try {
      setLoadingCreateNote(true);
      await NotesService.createNote({
        event: eventId,
        deal: startupId,
        user: user._id,
        title,
        content,
        type,
        judge: selectedJudge ? selectedJudge.id : null,
      });
      getNotes();
    } catch (error) {
      addToast(t("error_occurred_creating_note"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoadingCreateNote(false);
    }
  };

  const updateNote = async(title, content) => {
    try {
      setLoadingUpdateNote(true);
      await NotesService.updateNote(selectedNote._id, { title, content });
      setSelectedNote(null);
      getNotes();
    } catch (error) {
      addToast(t("error_occurred_updating_note"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoadingUpdateNote(false);
    }
  };

  const deleteNote = async(noteId) => {
    confirm().then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoadingDeleteNote(true);
          await NotesService.deleteNote(noteId);
          getNotes();
        } catch (error) {
          addToast(t("error_occurred_deleting_note"), {
            appearance: "error",
            autoDismiss: true,
          });
        } finally {
          setLoadingDeleteNote(false);
        }
      }
    });
  };

  const getNote = async(noteId) => {
    try {
      setLoadingGetNote(true);
      const result = await NotesService.getNote(noteId);
      setSelectedNote(result.data.data);
    } catch (error) {
      addToast(t("error_occurred_retrieving_note"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoadingGetNote(false);
    }
  };

  useEffect(() => {
    getEvent();
    getNotes();
    getDealflow();
  }, []);

  useEffect(() => {
    if (showNotes) {
      getNotes();
    }
  }, [showNotes]);

  const downloadGeneralDocument = async(documentId) => {
    try {
      setLoading(true);
      let result = null;
      result = await DocumentsService.getDocument(documentId);
      if (result?.data?.data?.url) {
        window.open(result.data.data.url, "_blank");
      }
    } catch (error) {
      addToast(t(error?.response?.data?.msg_key || "error_occurred_downloading_document"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const migrateNote = async(dealToMigrateId) => {
    try {
      setLoading(true);
      await NotesService.updateNote(noteToMigrate, { deal: dealToMigrateId });
      addToast(t("note_migrated_successfully"), { appearance: "success", autoDismiss: true });
      setNoteToMigrate(null);
      setShowMigrationSection(false);
      setNotes([]);
    } catch (error) {
      addToast(t("error_occurred_migrating_note"), { appearance: "error", autoDismiss: true });
    } finally {
      setLoading(false);
    }
  };

  const migrateDocument = async(dealToMigrateId) => {
    if (documentToMigrate.category !== "dc_other_documents") {
      confirm({
        title: t("are_you_sure"),
        text: t("migrate_document_key_category_explanation"),
      }).then(async(isConfirmed) => {
        if (isConfirmed) {
          try {
            setLoading(true);
            await DocumentsService.migrateDocument(documentToMigrate._id, dealToMigrateId);
            addToast(t("document_migrated_successfully"), {
              appearance: "success",
              autoDismiss: true,
            });
            setDocumentToMigrate(null);
            setShowMigrationSection(false);
            // setReloadDocuments(true);
          } catch (error) {
            addToast(t("error_occurred_migrating_document"), {
              appearance: "error",
              autoDismiss: true,
            });
          } finally {
            setLoading(false);
          }
        }
      });
    } else {
      try {
        setLoading(true);
        await DocumentsService.migrateDocument(documentToMigrate._id, dealToMigrateId);
        addToast(t("document_migrated_successfully"), { appearance: "success", autoDismiss: true });
        setDocumentToMigrate(null);
        setShowMigrationSection(false);
        // setReloadDocuments(true);
      } catch (error) {
        addToast(t("error_occurred_migrating_document"), {
          appearance: "error",
          autoDismiss: true,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {showMigrationSection && (
        <MigrationSection
          onClose={() => {
            setShowMigrationSection(false);
            setNoteToMigrate(null);
            setDocumentToMigrate(null);
          }}
          migrationType={noteToMigrate ? "notes" : "documents"}
          dealflow={dealflow}
          onSubmit={(dealToMigrateId) => {
            if (noteToMigrate) {
              migrateNote(dealToMigrateId);
            } else {
              migrateDocument(dealToMigrateId);
            }
          }}
        />
      )}
      {showDealInfo && (
        <DealResumeSection
          deal={deal || {}}
          onClose={() => {
            setShowDealInfo(null);
          }}
          downloadDocument={downloadGeneralDocument}
        />
      )}
      {showNotes && (
        <NotesContainer
          zIndex="10"
          listTitle={t("annotations")}
          setFiltersButtons={false}
          setShowModal={setShowNotes}
          notes={notes}
          createNote={createNote}
          updateNote={updateNote}
          deleteNote={deleteNote}
          getNote={getNote}
          selectedNote={selectedNote}
          setSelectedNote={setSelectedNote}
          onClose={() => {
            setSelectedNote(null);
            setShowNotes(false);
          }}
          setShowMigrationSection={(noteId) => {
            setShowNotes(false);
            setShowMigrationSection(true);
            setNoteToMigrate(noteId);
          }}
        />
      )}

      <div className="flex items-start">
        <div className="flex-1">
          <PageTitle
            title={`${t("startup")}: ${
              deal && deal.name ? deal.name : t("startup_name_not_provided")
            }`}
            showBack={true}
            onBackClick={() => {
              if (location.state && location.state.from) {
                navigate(location.state.from, location.state.pagination);
              } else {
                console.log(evaluation);
                navigate(`${RoutesLinks.EVALUATION_PROCESSES_LINK}${eventId}`);
              }
            }}
          />
        </div>

        {section === "evaluations" && (
          <ButtonSecondary
          iconComponent={<AiOutlineMore className="inline-block w-4 h-4 mr-2" />}
            marginRight="2"
            text={t("actions")}
            onClick={() => pageRef.current?.showEvaluationActions()}
          />
        )}

        {section === "documents" && !deal?.discarded && (
          <>
            <ButtonSecondary
              iconComponent={<FaPlus className="inline-block mr-2" />}
              marginRight="2"
              text={t("upload_document")}
              onClick={() => pageRef?.current?.uploadDocument()}
            />
            <ButtonSecondary
              iconComponent={<FaPlus className="inline-block mr-2" />}
              marginRight={"2"}
              text={t("create_folder")}
              onClick={() => pageRef?.current?.createFolder()}
            />
          </>
        )}
        {!deal?.discarded && (
          <ButtonSecondary
            onClick={() => setShowNotes(true)}
            text={t("annotations")}
            iconComponent={<TbNotes className="inline-block mr-3 w-4 h-4" />}
          />
        )}
      </div>

      {(loading ||
        loadingGetNotes ||
        loadingCreateNote ||
        loadingGetNote ||
        loadingDeleteNote ||
        loadingUpdateNote) && <Loader />}

      <SectionHeader
        section={section}
        setSection={(section) => {
          setSection(section);
          setSelectedJudge(null);
          setEvaluation(null);
        }}
        navigate
        sectionKeys={["information", "documents", "evaluations"].map((section) => ({ title: section, route: section, id: section }))}
        sectionTitles={[t("information"), t("documents"), t("evaluations")]}
      />
      {deal && event && (<Outlet context={{ deal, event, setShowDealInfo, setLoading, eventId, startupId, ref: pageRef }} />)}
    </>
  );
};

export default Application;
