export const EvaluationsUtils = {
  getStartupsFromEvaluations: (evaluations, includeDiscarded = true) => {
    const startups = [];
    const sortedEvaluations = [...evaluations].sort((a, b) => b.general_score - a.general_score);

    for (let i = 0; i < sortedEvaluations.length; i++) {
      const evaluation = sortedEvaluations[i];
      if (evaluation.deal && !startups.find((s) => s._id === evaluation.deal._id)) {
        /* if (evaluation.startup.discarded && !includeDiscarded) {
          continue
        } */

        startups.push(evaluation.deal);
      }
    }

    return startups;
  },

  getJudgesFromEvaluations: (evaluations) => {
    const judges = [];

    for (let i = 0; i < evaluations.length; i++) {
      const evaluation = evaluations[i];
      if (evaluation.judge && !judges.find((j) => j._id === evaluation.judge._id)) {
        judges.push(evaluation.judge);
      }
    }

    return judges;
  },

  getScoreColor: (percentage) => {
    const redToGreenPalette = ["#c43939", "#d7482f", "#d7752f", "#d79c2f", "#d1d72f", "#60b72d"];

    return redToGreenPalette[Math.round(((redToGreenPalette.length - 1) * percentage) / 100)];
  },
};
