import Shepherd from "shepherd.js";
import { Trans, useTranslation } from "react-i18next";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { renderToStaticMarkup } from "react-dom/server";
import { TourStep } from "components/ui/atoms/TourStep";
import useUser from "./useUser";
import WelcomeImg from "assets/images/tour_welcome.png";
import DashboardImg from "assets/images/tour_dashboard.jpg";
import DealflowImg from "assets/images/tour_dealflow.jpg";
import PipelineManagerImg from "assets/images/tour_pipeline_manager.png";
import IrmImg from "assets/images/tour_irm.png";
import EvaluationsImg from "assets/images/tour_evaluations.jpg";
import DocumentationImg from "assets/images/tour_documentation.jpg";
import QuestionnairesImg from "assets/images/tour_questionnaires.jpg";
import RemindersImg from "assets/images/tour_reminders.png";
import AccountImg from "assets/images/tour_account.png";
import HelpImg from "assets/images/tour_help.jpg";
import GettingStartedImg from "assets/images/tour_getting_started.png";
import ResumeImg from "assets/images/tour_resume.png";
import OnboardingOperations from "redux/onboarding/onboarding-operations";

import AccountService from "services/account-service";

/**
 * Hook for use tour
 * @param tourTag {'quick_tour' | 'register_startup_tour'}
 * @param skip {string | string[] | null=}
 * @returns {Tour}
 */
export function useTour(tourTag) {
  const { user, reloadUserInfo } = useUser();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const platform = useSelector((state) => state.app.platform);

  const generatePendingSteps = (tour) => {
    const currentStepElement = tour.currentStep.el;
    const header = currentStepElement.querySelector(".shepherd-footer");
    const stepsContainer = document.createElement("div");
    stepsContainer.className = "w-full flex items-center";

    const stepsInfo = document.createElement("div");

    stepsInfo.className = "font-semibold";
    stepsInfo.innerText = `${tour.steps.indexOf(tour.currentStep)} / ${tour.steps.length - 2}`;

    stepsContainer.appendChild(stepsInfo);

    header.insertBefore(stepsContainer, currentStepElement.querySelector(".shepherd-button"));
  };

  // const generateProgressBar = (tour) => {
  //   const currentStepElement = tour.currentStep.el;
  //   const header = currentStepElement.querySelector(".shepherd-footer");
  //   const progressBarContainer = document.createElement("div");

  //   const progress = document.createElement("div");
  //   const innerBar = document.createElement("span");
  //   const progressPercentage = ((tour.steps.indexOf(tour.currentStep) + 1) / tour.steps.length) * 100 + "%";

  //   progress.className = "relative bg-gray-200 overflow-hidden";
  //   progress.style.margin = "28px 8px 0 0";
  //   progress.style.borderRadius = "9px";
  //   progress.style.height = "9px";
  //   progress.style.flex = "1";

  //   innerBar.className = "absolute left-0 bottom-0 h-full bg-main";
  //   innerBar.style.width = progressPercentage;

  //   progressBarContainer.className = "w-full flex justify-center items-center rc-slider px-4";
  //   progress.appendChild(innerBar);
  //   progressBarContainer.appendChild(progress);

  //   header.insertBefore(progressBarContainer, currentStepElement.querySelector(".shepherd-button"));
  // };

  const generateButton = (text, callback, className = "", back, cancel) => ({
    text,
    label: text,
    action: callback,
    classes:
      "text-center text-sm font-semibold " +
      ` ${!back && !cancel ? "bg-bg-buttons-main text-text-buttons-main" : "bg-bg-buttons-secondary text-text-buttons-secondary  border border-border-buttons-secondary "} rounded-2xl cursor-pointer hover:shadow-inner ` +
      className,
  });

  // const generateActions = (actions) => ({
  //   when: {
  //     show() {
  //       const content = this.el.querySelector(".shepherd-content");
  //       let textWrapper = content.querySelector(".shepherd-text");

  //       if (!textWrapper) {
  //         textWrapper = document.createElement("div");
  //         textWrapper.className = "shepherd-text";
  //         content.insertBefore(textWrapper, content.querySelector(".shepherd-footer"));
  //       }

  //       const wrapper = document.createElement("div");

  //       actions.forEach((action) => {
  //         const buttonWrapper = document.createElement("div");

  //         buttonWrapper.innerHTML = renderToStaticMarkup(
  //           <div className="my-2 p-4">
  //             <ButtonMain text={action.text} className="block my-2 text-xs" width={"full"} />
  //           </div>,
  //         );

  //         buttonWrapper.addEventListener("click", () => {
  //           this.complete();
  //           action.onClick();
  //         });

  //         wrapper.appendChild(buttonWrapper);
  //       });

  //       wrapper.className = "border-b border-gray-lines";
  //       textWrapper.appendChild(wrapper);

  //       generatePendingSteps(this.tour);
  //     },
  //   },
  // });

  const generateAttachment = (selector, anchor) => ({
    attachTo: { element: selector, on: anchor },
    popperOptions: {
      modifiers: [
        {
          name: "offset",
          options: { offset: [0, 20] },
        },
      ],
    },
  });

  const saveUserTourDoneFlag = () => {
    const tours = { ...user.tours };
    tours[tourTag] = moment();

    AccountService.updateTours(tours).then((result) => {
      reloadUserInfo();
    });
  };

  const cancelButton = generateButton(
    t("skip_tour"),
    function() {
      saveUserTourDoneFlag();
      this.complete();
      dispatch(OnboardingOperations.handleOnboardingTasksPannel(true));
    },
    null,
    false,
    true,
  );

  const discoverButton = generateButton(
    t("tour_discover_button"),
    function() {
      saveUserTourDoneFlag();
      this.complete();
      dispatch(OnboardingOperations.handleOnboardingTasksPannel(true));
    },
    null,
    true,
  );

  const backButton = generateButton(
    t("back"),
    function() {
      this.back();
    },
    null,
    true,
  );
  const startButton = generateButton(
    t("start_tour"),
    function() {
      this.next();
    },
    null,
    false,
  );

  const nextButton = generateButton(
    t("next"),
    function() {
      this.next();
    },
    null,
    false,
  );

  const completeButton = generateButton(
    t("tour_finish_button"),
    function() {
      saveUserTourDoneFlag();
      this.complete();
      dispatch(OnboardingOperations.handleOnboardingTasksPannel(true));
    },
    null,
    false,
  );

  /**
   * @type {{[tourTag: string] : Step[]}}
   */
  const steps = {
    quick_tour: [
      // common steps
      {
        id: "welcome",
        title: t("welcome_tour"),
        text: renderToStaticMarkup(
          <TourStep
            content={
              <Trans
                i18nKey="welcome_description_tour"
                values={{ platform_name: platform?.name || "" }}
              />
            }
            img={WelcomeImg}
          />,
        ),
      },
      {
        id: "dashboard",
        ...generateAttachment("#sidebar-menu-item-1", "right"),
        title: t("dashboard"),
        text: renderToStaticMarkup(
          <TourStep content={t("dashboard_description_tour")} img={DashboardImg} />,
        ),
      },
      // investor (venture_capitals)
      {
        id: "dealflow",
        ...generateAttachment("#sidebar-menu-item-2", "right"),
        title: t("dealflow"),
        text: renderToStaticMarkup(
          <TourStep content={t("dealflow_description_tour")} img={DealflowImg} />,
        ),
      },
      {
        id: "pipeline_manager",
        ...generateAttachment("#sidebar-menu-item-3", "right"),
        title: t("pipeline_manager"),
        text: renderToStaticMarkup(
          <TourStep content={t("pipeline_manager_description_tour")} img={PipelineManagerImg} />,
        ),
      },
      {
        id: "irm",
        ...generateAttachment("#sidebar-menu-item-4", "right"),
        title: t("investors_relations_manager"),
        text: renderToStaticMarkup(<TourStep content={t("irm_description_tour")} img={IrmImg} />),
      },
      {
        id: "evaluations",
        ...generateAttachment("#sidebar-menu-item-5", "right"),
        title: t("evaluations"),
        text: renderToStaticMarkup(
          <TourStep content={t("evaluations_description_tour")} img={EvaluationsImg} />,
        ),
      },
      {
        id: "documentation",
        ...generateAttachment("#sidebar-menu-item-6", "right"),
        title: t("documentation"),
        text: renderToStaticMarkup(
          <TourStep content={t("documentation_description_tour")} img={DocumentationImg} />,
        ),
      },
      {
        id: "questionnaires-engine",
        ...generateAttachment("#sidebar-menu-item-7", "right"),
        title: t("questionnaires_engine"),
        text: renderToStaticMarkup(
          <TourStep
            content={t("questionnaires_engine_description_tour")}
            img={QuestionnairesImg}
          />,
        ),
      },
      {
        id: "reminders",
        ...generateAttachment("#reminders-button", "bottom"),
        title: t("reminders"),
        text: renderToStaticMarkup(
          <TourStep content={t("reminders_description_tour")} img={RemindersImg} />,
        ),
      },
      {
        id: "account",
        ...generateAttachment("#account-button", "bottom"),
        title: t("account"),
        text: renderToStaticMarkup(
          <TourStep content={t("account_description_tour")} img={AccountImg} />,
        ),
      },
      {
        id: "help",
        ...generateAttachment("#help-button", "bottom"),
        title: t("help"),
        text: renderToStaticMarkup(<TourStep content={t("help_description_tour")} img={HelpImg} />),
      },
      {
        id: "getting_started",
        ...generateAttachment("#getting_started_button", "top"),
        title: t("sidebar_getting_started"),
        text: renderToStaticMarkup(
          <TourStep content={t("getting_started_description_tour")} img={GettingStartedImg} />,
        ),
      },
      {
        id: "resume",
        title: t("tour_resume_title"),
        text: renderToStaticMarkup(
          <TourStep content={t("tour_resume_description")} img={ResumeImg} resume={true} />,
        ),
      },
    ],
  };

  return new Shepherd.Tour({
    useModalOverlay: true,
    defaultStepOptions: {
      scrollTo: {
        behavior: "smooth",
        block: "center",
      },
      buttons: [cancelButton, startButton, backButton, discoverButton, nextButton],
      cancelIcon: { enabled: false, label: t("skip_tour") },
      arrow: true,
      canClickTarget: false,
      modalOverlayOpeningPadding: 8,
      modalOverlayOpeningRadius: 8,
      when: {
        // show () { this.tour.steps.indexOf(this.tour.currentStep) && generateProgressBar(this.tour) },
        show() {
          this.tour.steps.indexOf(this.tour.currentStep) !== 0 &&
            this.tour.steps.indexOf(this.tour.currentStep) !== this.tour.steps.length - 1 &&
            generatePendingSteps(this.tour);
        },
      },
    },
    exitOnEsc: false,
    tourName: tourTag,
    keyboardNavigation: false,
    steps: steps[tourTag]
      // remove unused and skipped steps for correctly render progressbar
      .filter((step) => (step.showOn ? step.showOn() : true))
      // change step buttons
      .map((step, index, steps) => {
        // first step
        if (index === 0) {
          return { ...step, buttons: [cancelButton, startButton] };
        }

        if (index > 0 && index < steps.length - 1) {
          return { ...step, buttons: [backButton, nextButton] };
        }

        // last step
        if (index === steps.length - 1) {
          return { ...step, buttons: [discoverButton, completeButton] };
        }

        return step;
      }),
  });
}
