import { InvestorInvestmentThesis } from "components/investment-thesis/InvestorInvestmentThesis";
import { useOutletContext } from "react-router-dom";

const MemberDealflowPreferences = () => {
  const { member } = useOutletContext();

  return <InvestorInvestmentThesis thesis={member.user?.investment_thesis || {}} member />;
};

export default MemberDealflowPreferences;
