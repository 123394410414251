import { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
import { Loader } from "components/ui/molecules/Loader";
import { Pagination } from "components/ui/atoms/Pagination";
import ContactsService from "../../services/contacts-service";

import { useSwal } from "hooks/useSwal";
import Config from "config";
import ReactTooltip from "react-tooltip";
import IRMInvestorCard from "./IRMInvestorCard";
import useUser from "hooks/useUser";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";

const InvestorsList = ({
  currentPage,
  setCurrentPage,
  filters,
  irm = true,
  kiota = false,
  startup,
  setSelectedContact,
  setShowShareDealSection,
}) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { confirm } = useSwal();

  const sortContacts = (contacts) => {
    contacts.sort((a, b) => {
      // Si a no tiene la propiedad hard_filters_valid o su valor es false,
      // lo colocamos antes que b
      if (!a.hasOwnProperty("hard_filters_valid") || !a.hard_filters_valid) { return 1; }

      // Si b no tiene la propiedad hard_filters_valid o su valor es false,
      // lo colocamos después que a
      if (!b.hasOwnProperty("hard_filters_valid") || !b.hard_filters_valid) { return -1; }

      // Si ambos objetos tienen la propiedad hard_filters_valid y su valor es true,
      // los dejamos en el mismo orden
      return 0;
    });

    return contacts;
  };

  const checksHardFilters = (contact) => {
    const HARD_FILTERS_KEYS = [
      "business_models_hard_filter",
      "countries_hard_filter",
      "investment_stages_hard_filter",
      "project_stages_hard_filter",
      "sectors_hard_filter",
    ];

    let valid = true;

    HARD_FILTERS_KEYS.every((key) => {
      if (contact.checks[key] && !contact.checks[key.replace("_hard_filter", "")]) {
        valid = false;
        return false;
      }
      return true;
    });

    return valid;
  };

  const getChecks = (contact) => {
    const checks = {};

    if (!irm && filters) {
      if (filters.ticket) {
        checks.minimum_ticket =
          !contact.investment_thesis.minimum_ticket_raw ||
          contact.investment_thesis.minimum_ticket_raw <=
            filters.ticket * (filters.ticket_unit === "k" ? 1000 : 1000000);
        checks.maximum_ticket =
          !contact.investment_thesis.maximum_ticket_raw ||
          contact.investment_thesis.maximum_ticket_raw >=
            filters.ticket * (filters.ticket_unit === "k" ? 1000 : 1000000);
        checks.maximum_ticket_hard_filter = contact.investment_thesis.maximum_ticket_hard_filter;
        checks.minimum_ticket_hard_filter = contact.investment_thesis.minimum_ticket_hard_filter;
      }

      if (filters.valuation) {
        checks.minimum_valuation =
          !contact.investment_thesis.minimum_valuation_raw ||
          contact.investment_thesis.minimum_valuation_raw <=
            filters.valuation * (filters.valuation_unit === "k" ? 1000 : 1000000);
        checks.maximum_valuation =
          !contact.investment_thesis.maximum_valuation_raw ||
          contact.investment_thesis.maximum_valuation_raw >=
            filters.valuation * (filters.valuation_unit === "k" ? 1000 : 1000000);
        checks.maximum_valuation_hard_filter =
          contact.investment_thesis.maximum_valuation_hard_filter;
        checks.minimum_valuation_hard_filter =
          contact.investment_thesis.minimum_valuation_hard_filter;
      }

      if (filters.countries) {
        const countryMatched = contact.investment_thesis?.countries?.find(
          (country) => country._id.toString() === filters.countries[0],
        );
        checks.countries_hard_filter = contact.investment_thesis.countries_hard_filter;
        checks.countries = !!countryMatched;
      }

      if (filters.sectors) {
        const sectorMatched = contact.investment_thesis?.sectors?.find(
          (sector) => sector._id.toString() === filters.sectors[0],
        );
        checks.sectors_hard_filter = contact.investment_thesis.sectors_hard_filter;
        checks.sectors = !!sectorMatched;
      }

      if (filters.business_models) {
        const businessModelMatched = contact.investment_thesis?.business_models?.find(
          (businessModel) => businessModel._id.toString() === filters.business_models[0],
        );
        checks.business_models_hard_filter = contact.investment_thesis.business_models_hard_filter;
        checks.business_models = !!businessModelMatched;
      }

      if (filters.project_stages) {
        const projectStageMatched = contact.investment_thesis?.project_stages?.find(
          (projectStage) => projectStage._id.toString() === filters.project_stages[0],
        );
        checks.project_stages_hard_filter = contact.investment_thesis.project_stages_hard_filter;
        checks.project_stages = !!projectStageMatched;
      }

      if (filters.investment_stages) {
        const investmentStageMatched = contact.investment_thesis?.investment_stages?.find(
          (investmentStage) => investmentStage._id.toString() === filters.investment_stages[0],
        );
        checks.investment_stages_hard_filter =
          contact.investment_thesis.investment_stages_hard_filter;
        checks.investment_stages = !!investmentStageMatched;
      }
    }

    return checks;
  };
  const processContacts = (contacts) => {
    contacts.forEach((contact) => {
      contact.checks = getChecks(contact);
      contact.hard_filters_valid = checksHardFilters(contact);
    });

    return sortContacts(contacts);
  };

  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [totalInvestors, setTotalInvestors] = useState(0);
  const [investors, setInvestors] = useState([]);
  const { userLanguage, user } = useUser();
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const addToMyContacts = (investorId) => {
    confirm({ text: t("add_investor_to_contacts_explanation") }).then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await ContactsService.addInvestorToContacts(investorId);
          addToast(t("investor_added_successfully_to_contacts"), {
            appearance: "success",
            autoDismiss: true,
          });
        } catch (error) {
          addToast(
            error?.response?.data?.msg_key
              ? t(error.response.data.msg_key)
              : t("error_occurred_adding_investor"),
            {
              appearance: "error",
              autoDismiss: true,
            },
          );
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const getInvestors = () => {
    setLoading(true);

    const params = {
      ...filters,
      rowsPerPage: pageSize,
      page: currentPage,
      kiota,
    };

    ContactsService.getContacts(params)
      .then((response) => {
        const { investors, pagination } = response.data.data;
        if (irm) {
          setInvestors(investors);
        } else {
          setInvestors(processContacts(investors));
        }

        if (pagination.current - 1 !== currentPage) {
          setCurrentPage(pagination.current);
        }

        if (pagination.total !== totalPages) {
          setTotalPages(pagination.pages);
        }

        if (pagination.totalDocs !== totalInvestors) {
          setTotalInvestors(pagination.totalDocs);
        }
      })
      .catch((error) => {
        console.error(error);
        addToast(t("error_occurred_retrieving_information"), {
          appearance: "error",
          autoDismiss: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getInvestors();
  }, [currentPage, pageSize, filters, kiota]);

  return (
    <>
      {loading && <Loader />}

      {!loading && investors.length === 0 && (
        <div className="my-10">
          <img
            src={`${Config.GUIDE_STEPS_BACKGROUND_IMAGES_BUCKET}irm_${userLanguage}.png`}
            className="w-auto"
          />
        </div>
      )}
      {investors.length !== 0 && (
        <div className="my-6">
          {!irm && (
            <div className="flex justify-end mt-8">
              <ButtonSecondary
                type="button"
                text={t("share_with_massive_contacts")}
                onClick={() => setShowShareDealSection(true)}
                marginRight={2}
              />
            </div>
          )}
          <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4">
            {investors.map((investor) => (
                <IRMInvestorCard
                  investor={investor}
                  irm={irm}
                  kiota={kiota}
                  key={investor._id}
                  addToMyContacts={addToMyContacts}
                  startup={startup}
                  setSelectedContact={setSelectedContact}
                  filters={filters}
                  user={user}
                />
            ))}
          </div>
          <Pagination
            showRowsPerPage={true}
            paginateOptions={[12, 24, 36]}
            currentPage={currentPage}
            setCurrentPage={(page) => setCurrentPage(page)}
            perPage={pageSize}
            pages={totalPages}
            setPerPage={(value) => setPageSize(value[0].id)}
          />
        </div>
      )}
    </>
  );
};

export default InvestorsList;
