/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { GoogleButton } from "components/ui/atoms/GoogleButton";
import { Loader } from "components/ui/molecules/Loader";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { TfiReload } from "react-icons/tfi";
import { useTranslation } from "react-i18next";
import GoogleView from "./google-view";
import OutlookView from "./outlook-view";
import { SidePanel } from "components/ui/objects/SidePanel";
import { OutlookButton } from "components/ui/atoms/OutlookButton";
import { useIsAuthenticated } from "@azure/msal-react";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import { useOutletContext } from "react-router-dom";

const API_KEY = "AIzaSyDDXutZvDTGFliGz9_-PG1IwnXERSVu5jU";
const CLIENT_ID = "690938764456-ac8b32rplj2tfk4f43grc7iuh36udi8i.apps.googleusercontent.com";

const EmailsView = () => {
  const { deal } = useOutletContext();

  const isAuthenticated = useIsAuthenticated();
  const [isAuthenticatedGoogle, setIsAuthenticatedGoogle] = useState(false);
  const [isAuthenticatedOutlook, setIsAuthenticatedOutlook] = useState(false);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [sincronizeAccount, setLoadingSincronizeAccount] = useState(false);
  const [sincronizeGoogle, setLoadingSincronizeGoogle] = useState(false);
  const [sincronizeOutlook, setLoadingSincronizeOutlook] = useState(false);

  useEffect(() => {
    const initClient = async() => {
      if (!gapi.auth2.getAuthInstance()) {
        await gapi.client.init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest"],
          scope:
            "https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.compose https://www.googleapis.com/auth/gmail.send",
        });
      }
      const authInstance = gapi.auth2.getAuthInstance();

      if (authInstance.isSignedIn.get()) {
        setIsAuthenticatedGoogle(true);
      } else {
        setIsAuthenticatedGoogle(false);
      }
      setLoading(false);
    };

    gapi.load("client:auth2", initClient);
  }, []);

  useEffect(() => {
    setIsAuthenticatedOutlook(isAuthenticated);
  }, []);

  const handleLoginSuccess = async() => {
    setIsAuthenticatedGoogle(true);
    setLoadingSincronizeAccount(false);
  };

  const handleLoginFailure = (response) => {
    setLoading(false);
  };

  const handleSetSincronizePanel = () => {
    setLoadingSincronizeAccount(true);
    if (isAuthenticatedGoogle) {
      setLoadingSincronizeGoogle(true);
      setTimeout(() => {
        setLoadingSincronizeGoogle(false);
      }, 1000);
    } else {
      setLoadingSincronizeOutlook(true);
      setTimeout(() => {
        setLoadingSincronizeOutlook(false);
      }, 1000);
    }
  };

  const handleLoginSuccessOutlook = (data) => {
    setIsAuthenticatedOutlook(true);
    setLoadingSincronizeAccount(false);
  };

  return (
    <div className="my-6">
      <div className={"flex justify-between items-center mb-6 border-b border-main"}>
        <h3 className={"font-medium text-main font-bold mb-4"}>{t("email")}</h3>
        <div>
          <ButtonSecondary
            onClick={handleSetSincronizePanel}
            text={t("synchronize_emails")}
            iconComponent={<TfiReload className="inline mr-1" />}
          />
        </div>
      </div>
      {isAuthenticatedGoogle && <GoogleView deal={deal} sincronize={sincronizeGoogle}></GoogleView>}

      {isAuthenticatedOutlook && (
        <OutlookView deal={deal} sincronize={sincronizeOutlook}></OutlookView>
      )}
      {sincronizeAccount && !(isAuthenticatedGoogle || isAuthenticatedOutlook) && (
        <SidePanel
          width="1/3"
          onClose={() => {
            setLoadingSincronizeAccount(false);
          }}
          title="Sincronizar"
        >
          <div className="mb-6">
            <GoogleButton
              isActiveLoginOutlook={isAuthenticatedOutlook}
              text={t("enable_email_synchronization")}
              clientId={CLIENT_ID}
              onSuccess={handleLoginSuccess}
              onFailure={handleLoginFailure}
              cookiePolicy={"single_host_origin"}
            />
          </div>

          <OutlookButton
            onSuccess={handleLoginSuccessOutlook}
            isActiveLoginGoogle={isAuthenticatedGoogle}
          />
        </SidePanel>
      )}
      {!isAuthenticatedOutlook && !isAuthenticatedGoogle && (
        <div className="my-10">
          <NoDataInfo title={t("email_synchronization_not_enabled")} />
        </div>
      )}
      {loading && <Loader />}
    </div>
  );
};

export default EmailsView;
