import { RoutesLinks } from "../../routes-links";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { FaExclamationTriangle } from "react-icons/fa";
import useUser from "../../../hooks/useUser";
import { ButtonCardMain } from "components/ui/atoms/ButtonCardMain";
import { Card } from "components/ui/atoms/Card";

export const FormListItem = ({ form }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const navigate = useNavigate();

  const getQuestionsNumber = () => {
    if (form.published) {
      return form.questions.length;
    }

    if (form.draft && form.draft.questions) {
      return form.draft.questions.length;
    } else {
      return form.questions.length;
    }
  };

  return (
    <Card investor={user.investor._id !== form.investor._id ? form.investor : null}>
      <div key={form._id} className="flex flex-col">
        <div className="flex-1">
          <h3 className="flex gap-2 items-center mb-2 border-b border-gray-lines pb-2 font-bold">
            {!!form.draft && (
              <span data-tip={t("form_draft_warning")}>
                <FaExclamationTriangle className="text-orange-400 inline-block relative -top-[2px]" />
              </span>
            )}
            {form.name}
          </h3>

          <div className="flex flex-col text-sm">
            <div className="pr-2 mr-2">
              {getQuestionsNumber() || 0}{" "}
              {t(getQuestionsNumber() > 2 || getQuestionsNumber() === 0 ? "questions" : "question")}
            </div>
            <div className="pr-2 mr-2">
              {t("state")}:{" "}
              {form.published
                ? t("published")
                : form.draft
                  ? t("draft")
                  : getQuestionsNumber()
                    ? t("form_disabled")
                    : t("form_with_no_content")}
            </div>
            {form.publish_date && (
              <div>
                {t("publish_date")}: {moment(form.publish_date).format("YYYY-MM-DD")}
              </div>
            )}
            {(form.draft || form.published) && (
              <div>
                {t("last_update_date")}: {moment(form.updatedAt).format("YYYY-MM-DD")}
              </div>
            )}
          </div>
          <div className="text-gray mt-2 pt-2 border-t border-gray-lines">
            {form.statistics?.responses === 0 && t("no_responses")}
            {form.statistics?.responses === 1 && t("one_response")}
            {form.statistics?.responses > 1 &&
              t("multiple_responses", { count: form.statistics?.responses })}
            {form.last_response && (
              <span className="inline-block ml-2 italic">
                ({t("last_response")}: {moment(form.last_response?.createdAt).format("DD/MM/YYYY")})
              </span>
            )}
          </div>
        </div>

        <div className={"pt-2 mt-2 border-t border-separator flex justify-end"}>
          <ButtonCardMain
            text={t("view_form")}
            onClick={() => navigate(`${RoutesLinks.QE_FORMS_LINK}/${form._id}`)}
          />
        </div>
      </div>
    </Card>
  );
};
