import { useTranslation } from "react-i18next";
import { SidePanel } from "components/ui/objects/SidePanel";
import { useEffect } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Config from "config";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import Select from "components/ui/atoms/Select";
import RejectionReasons from "assets/json/rejection-reasons.json";
import { TextArea } from "components/ui/atoms/TextArea";

/**
 * @param onClose
 * @returns {JSX.Element}
 * @constructor
 */
const RejectDealSeaction = ({ onClose, rejectDeal }) => {
  const { t } = useTranslation();

  const rejectionReasons = RejectionReasons.map((rejectionReason) => {
    return { id: rejectionReason.id, value: t(rejectionReason.key) };
  });

  const { register, handleSubmit, errors, setValue, trigger, watch } = useForm({
    mode: "onChange",
    criteriaMode: "all",
    reValidateMode: "all",
    resolver: yupResolver(
      Yup.object().shape({
        rejection_reason: Yup.string().required(t("required_field")),
        rejection_comments: Yup.string().nullable(),
      }),
      { abortEarly: false },
    ),
  });

  useEffect(() => {
    register("rejection_reason");
  }, [register]);

  useEffect(() => {
    setValue("rejection_reason", rejectionReasons[0].id);
    trigger();
  }, []);

  return (
    <>
      <SidePanel width={"1/4"} onClose={onClose} title={t("reject_startup")}>
        <p className={`font-medium mb-6 text-sm text-main border-b border-main`}>
          {t("rejected_reason_title")}
        </p>
        <div className={"text-sm"}>{t("rejected_reason_subtitle")}</div>

        <div className="mt-4 flex flex-col w-full">
          <form className="w-full flex flex-col" onSubmit={handleSubmit(rejectDeal)}>
            <div className="w-full mr-4">
              <Select
                isClearable={false}
                items={rejectionReasons}
                initialValues={
                  watch("rejection_reason")
                    ? rejectionReasons.filter((item) => item.id === watch("rejection_reason"))
                    : []
                }
                onSelect={(selected) => {
                  setValue("rejection_reason", selected.length ? selected[0].id : null);
                  trigger();
                }}
                id="rejection_reason"
                error={errors.rejection_reason}
                name="rejection_reason"
                reference={register}
              />
            </div>
            <div className="mt-2 w-full">
              <TextArea
                reference={register}
                name="rejection_comments"
                error={errors.rejection_comments}
                label={t("comments")}
                maxLength={200}
                placeholder={t("comments")}
              />
            </div>
            <div className={`flex justify-end mt-2 pt-2 border-t border-separator`}>
              <ButtonMain type={"submit"} text={t("reject_startup")} />
            </div>
          </form>
        </div>
      </SidePanel>
    </>
  );
};

export default RejectDealSeaction;
