import React, { useEffect, useRef, useState } from "react";
import LogoDefault from "styles/images/logo_default.png";
import { useTranslation } from "react-i18next";
import { TagList } from "components/tags/TagList";
import {
  FaChevronUp,
  FaCog,
  FaExchangeAlt,
  FaExclamationTriangle,
  FaExpandAlt,
  FaEye,
  FaShareAlt,
  FaTrash,
  FaTags,
} from "react-icons/fa";
import { AiOutlineUser } from "react-icons/ai";
import { BsClockHistory, BsSpeedometer2 } from "react-icons/bs";
import { HiAnnotation } from "react-icons/hi";
import { TbLayoutDashboard } from "react-icons/tb";
import { TfiTarget } from "react-icons/tfi";
import { BoardCardCollapsedHeader } from "components/pipeline-manager/components/cards/BoardCardCollapsedHeader";
import { ButtonDanger } from "components/ui/atoms/ButtonDanger";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { RoutesLinks } from "components/routes-links";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Utils from "utils/utils";
import { DealflowInvestmentThesisMatch } from "components/dealflow/DealflowInvestmentThesisMatch";

const PipelineDealflowTypeCard = ({
  card,
  editable,
  dealflow,
  deleteCard,
  getForm,
  getDeal,
  getRecord,
  startupToSearch,
  setDealToGetNotes,
  stageDate,
  boardDate,
  userIdentity,
  setDealflowToShare,
  setDealToExchange,
  currentStage,
  setCurrentStage,
  updateDealTags,
  tags,
  getHardFilters,
  user,
  checkIfLPhasAccessToSection,
  setItemHeight,
  setShowTagsModal,
  setDealToUpdateTags,
}) => {
  const { t } = useTranslation();
  const [expandInformation, setExpandInformation] = useState(false);
  const navigate = useNavigate();
  const cardRef = useRef(null);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [expandInformation]);

  useEffect(() => {
    if (cardRef.current) {
      console.log(cardRef.current.getBoundingClientRect().height);
      setItemHeight(cardRef.current.getBoundingClientRect().height);
    }
  }, [expandInformation]);

  const [criteriaChecks, setCriteriaChecks] = useState({});
  const [hardFilters, setHardFilters] = useState([]);

  const hasSomeHardFilterUnmatched = () => {
    if (hardFilters.length) {
      let unmatchedHardFilter = false;

      hardFilters.every((hardFilter) => {
        if (criteriaChecks.hasOwnProperty(hardFilter) && !criteriaChecks[hardFilter]) {
          unmatchedHardFilter = true;
          return false;
        }

        return true;
      });

      if (unmatchedHardFilter) {
        ReactTooltip.rebuild();
      }
      return unmatchedHardFilter;
    } else {
      return false;
    }
  };

  useEffect(() => {
    try {
      setHardFilters(getHardFilters());
      setCriteriaChecks(Utils.checkInvestmentCriteriaMatch(dealflow, user.investor?.investment_thesis));
    } catch (e) {
      console.error(e);
    }
  }, []);

  return (
    <div
    ref={cardRef}
      className={`px-2 py-3 my-2 rounded-2xl ${
        startupToSearch && dealflow?._id === startupToSearch ? "bg-yellow-400" : "bg-white"
      } flex flex-row justify-between ${
        editable && "cursor-move hover:shadow-inner"
      } relative border-gray-lines border`}
    >
      {!expandInformation && (
        <BoardCardCollapsedHeader
          preScorings={dealflow?.pre_scorings}
          dealId={dealflow?._id}
          stageDate={stageDate}
          startupToSearch={startupToSearch}
          onClick={() => setExpandInformation(true)}
          tags={dealflow?.tags || []}
          title={dealflow?.name}
          imageComponent={
            <img
              src={dealflow?.logo || LogoDefault}
              alt={dealflow?.name}
              className="object-contain w-8 h-8 mr-2 cursor-move"
            />
          }
          hasSomeHardFilterUnmatched={hasSomeHardFilterUnmatched()}
          hasReferral={dealflow?.referrals && dealflow?.referrals.length > 0}
          multipleApply={dealflow?.forms ? dealflow?.forms?.filter((form) => !form.automated).length : 0}
        />
      )}

      {expandInformation && (
        <div className="justify-around w-full ">
          <div className="flex w-full">
            <div className="flex items-start justify-start h-auto pb-1">
              <div className="flex items-center justify-center w-16 h-16 mr-2">
                <img
                  src={dealflow?.logo || LogoDefault}
                  alt={dealflow?.name}
                  className="object-contain mr-2 w-14 h-14"
                />
              </div>
            </div>

            <div className="relative flex w-full cursor-pointer" onClick={() => setExpandInformation(false)}>
              <div className="flex flex-col justify-between w-full h-full pb-4 ">
                <div className="flex flex-row justify-between">
                  <div>
                    <h5
                      className={`uppercase font-bold text-xxs mt-4 leading-none break-all text-left ${
                        startupToSearch && dealflow._id === startupToSearch && "bg-yellow-400"
                      }`}
                    >
                      {dealflow?.name}
                      {hasSomeHardFilterUnmatched() && (
                        <FaExclamationTriangle
                          className="inline ml-1 text-red"
                          data-tip={t("deal_does_not_match_some_hard_filter")}
                        />
                      )}
                    </h5>
                  </div>
                  <span className={"text-sm text-center text-main w-5 h-5 mr-0.5"}>
                    <FaChevronUp />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-2 text-xs">
            {boardDate && (
              <div className="flex mb-1" data-tip={t("board_date_tooltip")}>
                <TbLayoutDashboard className="w-4 h-4 mr-1" />
                {`${t("board_date_label")}:`}{" "}
                <span className="ml-1 font-bold">{Utils.getDifferenceBetweenDates(boardDate, t)}</span>
              </div>
            )}
            {stageDate && (
              <div className="flex mb-1" data-tip={t("stage_date_tooltip")}>
                <TfiTarget className="w-4 h-4 mr-1" />
                {`${t("stage_date_label")}:`}{" "}
                <span className="ml-1 font-bold">{Utils.getDifferenceBetweenDates(stageDate, t)}</span>
              </div>
            )}
            {userIdentity && (
              <div data-tip={t("stage_user_tooltip")} className="flex mb-1">
                <AiOutlineUser className="w-4 h-4 mr-1" />
                {`${t("stage_user_label")}:`} <span className="ml-1 font-bold">{userIdentity.name}</span>
              </div>
            )}
            {dealflow?.pre_scorings &&
              dealflow?.pre_scorings.length > 0 &&
              dealflow?.pre_scorings.filter((ps) => ps.score !== null && ps.score !== undefined).length !== 0 && (
                <div className="flex flex-row items-center mb-2 text-xxs justify-left">
                  <BsSpeedometer2 className="w-4 h-4 mr-1" data-tip={t("pre_score")} />
                  {`${t("pre_score")}:`}
                  <span className="ml-1 font-bold">
                    {dealflow?.pre_scorings.length > 1
                      ? `${dealflow?.pre_scorings[dealflow?.pre_scorings.length - 1].score} (+${
                          dealflow?.pre_scorings.length - 1
                        })`
                      : dealflow?.pre_scorings[dealflow?.pre_scorings.length - 1].score}
                  </span>
                </div>
            )}
          </div>
          <div className="pb-2 border-t border-gray-lines">
            <div className="my-1 text-xs font-bold">{t("investment_thesis_match")}</div>
            <DealflowInvestmentThesisMatch criteriaChecks={criteriaChecks} pipelineManager={true} />
          </div>
          {dealflow?.tags?.length > 0 && (
            <div className="pb-2 border-t border-gray-lines">
              <TagList tags={dealflow?.tags || []} />
            </div>
          )}

          {(dealflow?.forms && dealflow?.forms.length > 0) && (!user.lp || (user.lp && checkIfLPhasAccessToSection("questionnaires"))) && (
            <div className="pb-2 border-t border-gray-lines">
              <div className="my-1 text-xs font-bold">{t("questionnaires")}</div>
              {dealflow.forms.map((form, i) => (
                  <span
                    data-tip={`${t("application_date")}: ${new Date(form.date).toLocaleDateString("en-GB")}`}
                    key={`${form.form._id}-${i}`}
                    className={
                      "inline-block mr-2 mt-1 font-semibold text-xxs bg-bg-buttons-secondary px-2 py-0.5 rounded-md border border-border-buttons-secondary text-text-buttons-secondary cursor-pointer hover:shadow-inner"
                    }
                    onClick={() => getForm(form.form._id, dealflow?._id, form.application)}
                  >
                    {form.form.name}
                    {editable && form.automated && (
                      <FaCog className="inline ml-2" data-tip={t("form_assigned_by_automation")} />
                    )}
                  </span>
              ))}
            </div>
          )}

          {editable &&
            dealflow?.events.length > 0 &&
            (!user.lp || (user.lp && checkIfLPhasAccessToSection("evaluations"))) && (
              <div className="pb-2 border-t border-gray-lines">
                <div className="my-1 text-xs font-bold">{t("evaluation_processes")}</div>
                {dealflow?.events.map((event, i) => (
                    <span
                      key={`${event.event._id}-${i}`}
                      className={
                        "inline-block mr-2 mt-1 font-semibold text-xxs bg-bg-buttons-secondary px-2 py-0.5 rounded-md border border-border-buttons-secondary text-text-buttons-secondary cursor-pointer hover:shadow-inner"
                      }
                      onClick={() =>
                        navigate(
                          `${RoutesLinks.EVALUATION_PROCESSES_LINK}${event.event._id}/startups/${dealflow?._id}`,
                        )
                      }
                    >
                      {event.event.name}
                    </span>
                ))}
              </div>
          )}

          {dealflow?.referrals && (
            <div className="pb-2 border-t border-gray-lines">
              <div className="my-1 text-xs font-bold">{t("referrals")}</div>
              {dealflow?.referrals.map((referral, i) => (
                  <span
                    key={`${referral._id}-${i}`}
                    className={
                      "inline-block mr-2 mt-1 font-semibold text-xxs bg-bg-buttons-secondary px-2 py-0.5 rounded-md border border-border-buttons-secondary text-text-buttons-secondary hover:shadow-inner"
                    }
                  >
                    {referral.referred}
                  </span>
              ))}
            </div>
          )}
          {dealflow.owners && (
            <div className="pb-2 border-t border-gray-lines">
              <div className="my-1 text-xs font-bold">{t("owners")}</div>
              <div className="flex gap-2">
                {dealflow.owners.map((owner) => (
                    <span
                      key={owner._id}
                      className="inline-block mt-1 font-semibold text-xxs bg-bg-buttons-secondary px-2 py-0.5 rounded-md border border-border-buttons-secondary text-text-buttons-secondary hover:shadow-inner"
                    >{`${owner.name} ${owner.last_name}`}</span>
                ))}
              </div>
            </div>
          )}
          {dealflow.added_by && (
            <div className='pb-2 border-t border-gray-lines'>
              <div className="my-1 text-xs font-bold">
                  {t("added_by")}
              </div>
              <span className='inline-block mr-2 mt-1 font-semibold text-xxs bg-bg-buttons-secondary px-2 py-0.5 rounded-md border border-border-buttons-secondary text-text-buttons-secondary hover:shadow-inner'>
                  {`${dealflow.added_by.name} ${dealflow.added_by.last_name}`}
                </span>
            </div>
          )}
          {(dealflow?.forms && dealflow.forms[0]) && (
              <div className='pb-2 border-t border-gray-lines'>
                <div className="my-1 text-xs font-bold">
                  {t("added_by_form")}
                </div>
              <span onClick={() => navigate(`${RoutesLinks.DEALFLOW_LINK}${dealflow._id}/questionnaires`)} role='button' className='inline-block mt-1 font-semibold text-xxs bg-bg-buttons-secondary px-2 py-0.5 rounded-md border border-border-buttons-secondary text-text-buttons-secondary hover:shadow-inner'>
                  {`${dealflow.forms[0].form.name}`}
                </span>
            </div>
          )}
          {editable && (
            <div className={"border-t border-separator py-2"}>
              <ButtonSecondary
                text={t("manage_tags")}
                width={"full"}
                iconComponent={<FaTags className="inline w-3 h-3 mr-2" />}
                onClick={
                  () => { setShowTagsModal(true); setDealToUpdateTags(dealflow); }
                }
              />
              {!user.lp && (
                <ButtonSecondary
                  text={t("share")}
                  iconComponent={<FaShareAlt className="inline w-3 h-3 mr-2" />}
                  onClick={() => setDealflowToShare(dealflow._id)}
                  width={"full"}
                  marginTop={2}
                />
              )}
              <ButtonSecondary
                text={t("see_profile_resume")}
                iconComponent={<FaExpandAlt className="inline mr-2" />}
                onClick={() => getDeal(dealflow._id)}
                width={"full"}
                marginTop={2}
              />
              {(!user.lp || (user.lp && checkIfLPhasAccessToSection("dealflow"))) && (
                <ButtonSecondary
                  text={t("go_to_profile")}
                  iconComponent={<FaEye className="inline mr-2" />}
                  onClick={() => navigate(`${RoutesLinks.DEALFLOW_LINK}${dealflow._id}`)}
                  width={"full"}
                  marginTop={2}
                />
              )}
              {(!user.lp || (user.lp && checkIfLPhasAccessToSection("annotations"))) && (
                <ButtonSecondary
                  text={t("annotations")}
                  iconComponent={<HiAnnotation className="inline w-4 h-4 mr-2" />}
                  onClick={() => setDealToGetNotes(dealflow._id)}
                  width={"full"}
                  marginTop={2}
                />
              )}
              {!user.lp && (
                <ButtonSecondary
                  text={t("move")}
                  iconComponent={<FaExchangeAlt className="inline w-4 h-4 mr-2" />}
                  onClick={() => {
                    setDealToExchange(card);
                    setCurrentStage(currentStage);
                  }}
                  width={"full"}
                  marginTop={2}
                />
              )}
              {stageDate && (
                <ButtonSecondary
                  text={t("see_record")}
                  iconComponent={<BsClockHistory className="inline mr-2" />}
                  onClick={() => getRecord(dealflow._id)}
                  width={"full"}
                  marginTop={2}
                />
              )}
              {!user.lp && (
                <ButtonDanger
                  text={t("delete_startup")}
                  iconComponent={<FaTrash className="inline mr-2" />}
                  onClick={() => deleteCard()}
                  width={"full"}
                  marginTop={2}
                />
              )}
            </div>
          )}
        </div>
      )}
      {/* <ManageTagsModal
        category="dealflow"
        currentTags={dealflow?.tags || []}
        tags={fetchedTags}
        handleCloseModal={() => setShowTagsModal(false)}
        onSubmit={(tags) => updateDealTags(dealflow?._id, tags) && setShowTagsModal(false)}
        showModal={showTagsModal}
        tags={tags}
      /> */}
    </div>
  );
};

export default PipelineDealflowTypeCard;
