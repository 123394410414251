import { LPCard } from "components/ui/molecules/LPCard";
import { useEffect, useImperativeHandle, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useSwal } from "hooks/useSwal";
import { useTranslation } from "react-i18next";
import LPSection from "./LPSection";
import { Loader } from "components/ui/molecules/Loader";
import LPService from "services/lp-service";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import PipelineManagersService from "services/pipeline-managers-service";
import { useOutletContext } from "react-router-dom";

export const CompanyLPs = () => {
  const [lps, setLPs] = useState([]);
  const [lp, setLP] = useState(null);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const { confirm } = useSwal();
  const [showLPSection, setShowLPSection] = useState(false);

  const [boards, setBoards] = useState([]);

  const { ref, investor, isChildren } = useOutletContext();

  useImperativeHandle(ref, () => ({
    showLPSection: () => setShowLPSection(true),
  }));

  useEffect(() => {
    const getPipelineManagers = async() => {
      try {
        setLoading(true);

        const result = await PipelineManagersService.getPipelineManagers({ rowsPerPage: 1000 });
        setBoards(result.data.data);
      } catch (error) {
        console.error(error);
        addToast(
          error.response && error.response.data.msg_key
            ? error.response.data.msg_key
            : t("error_occurred_retrieving_boards"),
          {
            appearance: "error",
            autoDismiss: true,
          },
        );
      } finally {
        setLoading(false);
      }
    };

    getPipelineManagers();
  }, []);

  const getLPs = async() => {
    try {
      setLoading(true);
      const result = await LPService.getLPs(isChildren ? investor._id : null);
      setLPs(result.data.data);
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t("error_occurred"),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const saveLP = async(values) => {
    confirm({
      icon: "info",
    }).then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          if (!lp) {
            await LPService.createLP(values);
            addToast(t("lp_created"), {
              appearance: "success",
              autoDismiss: true,
            });
          } else {
            await LPService.updateLP(
              lp._id,
              values.name,
              values.last_name,
              values.email,
              values.language,
              values.lp_permissions,
            );
            addToast(t("lp_updated"), {
              appearance: "success",
              autoDismiss: true,
            });
          }
          setShowLPSection(false);
          setLP(null);
          getLPs();
        } catch (error) {
          console.error(error);
          addToast(
            error.response
              ? error.response.data.msg_key
                ? t(error.response.data.msg_key)
                : t(lp ? "error_occurred_updating_lp" : "error_occurred_creating_lp")
              : t(lp ? "error_occurred_updating_lp" : "error_occurred_creating_lp"),
            {
              appearance: "error",
              autoDismiss: true,
            },
          );
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const removeLP = (lp) => {
    confirm({
      text: t("revert_notification"),
      icon: "warning",
    }).then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await LPService.removeLP(lp._id);
          addToast(t("lp_deleted_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
          setLP(null);
          setShowLPSection(false);
          getLPs();
        } catch (error) {
          addToast(
            error.response && error.response.data.msg_key
              ? t(error.response.data.msg_key)
              : t("error_occurred_deleting_lp"),
            {
              appearance: "error",
              autoDismiss: true,
            },
          );
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const sendActivationCode = (lp) => {
    confirm({
      icon: "info",
    }).then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await LPService.sendActivationCode(lp._id, isChildren ? investor._id : null);
          addToast(t("activation_code_sent_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
          setLP(null);
          setShowLPSection(false);
          getLPs();
        } catch (error) {
          addToast(
            error.response && error.response.data.msg_key
              ? t(error.response.data.msg_key)
              : t("error_occurred_sending_activation_code"),
            {
              appearance: "error",
              autoDismiss: true,
            },
          );
        } finally {
          setLoading(false);
        }
      }
    });
  };

  useEffect(() => {
    getLPs();
  }, []);

  const editLP = (lp) => {
    setLP(lp);
    setShowLPSection(true);
  };

  return (
    <>
      {loading && <Loader />}
      {showLPSection && (
        <LPSection
          onClose={() => {
            setShowLPSection(false);
            setLP(null);
          }}
          lp={lp}
          onSubmit={saveLP}
          removeLP={removeLP}
          sendActivationCode={sendActivationCode}
          boards={boards}
          investor={isChildren ? investor : null}
        />
      )}
      {lps.length !== 0 && (
        <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-4 my-10">
          {lps.map((lp, index) => <LPCard key={index} lp={lp} editLP={editLP} />)}
        </div>
      )}
      {lps.length === 0 && (
        <div className="my-6">
          <NoDataInfo title={t("lps_not_found")} />
        </div>
      )}
    </>
  );
};
