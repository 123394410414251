import InvestorScope from "components/dashboard/components/investor-scope";
import useUser from "hooks/useUser";
import Config from "config";
import { Trans, useTranslation } from "react-i18next";

const Dashboard = () => {
  const { user } = useUser();
  const { t } = useTranslation();

  return user.lp ? (
    <>
      <div className="my-10">
        <div className="text-center">
          <h1 className="text-3xl font-bold mb-4 text-main">
            <Trans i18nKey="lp_access_title" values={{ client: user.investor.company_name }} />
          </h1>
          <div className="text-xl text-main">{t("lp_access_description")}</div>
        </div>
        <div className="mt-10 flex justify-center">
          <img
            src={
              "https://guide-steps-background-images.s3.eu-west-1.amazonaws.com/Dashboard-V2.png"
            }
            className="w-full lg:w-1/2"
          />
        </div>
      </div>
    </>
  ) : (
    <InvestorScope />
  );
};

export default Dashboard;
