import React, { useEffect, useState } from "react";
import moment from "moment";
import { FaEye } from "react-icons/fa";
import { Card } from "components/ui/atoms/Card";
import { DataGrid } from "components/ui/objects/DataGrid";
import { getUser } from "services/outlook-service";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import { useTranslation } from "react-i18next";

const EmailsList = ({ emails, getEmailContent, showEmailDetail, mainEmail }) => {
  if (!emails || !emails.length) {
    return null;
  }
  const [emailOutlook, setUserEmailOutlook] = useState("");
  const { t } = useTranslation();
  const isAuthenticated = useIsAuthenticated();
  const msal = useMsal();
  const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(msal.instance, {
    account: msal.instance.getActiveAccount(),
    scopes: ["user.read", "mail.read", "mail.send"],
    interactionType: "none",
  });

  useEffect(() => {
    if (isAuthenticated) {
      getUser(authProvider).then(({ mail }) => {
        setUserEmailOutlook(mail);
      });
    }
  }, []);

  return (
    <>
      {emailOutlook && (
        <Card>
          <DataGrid
            data={emails.map((email) => {
              const from = email?.from?.emailAddress?.address || "-";

              const to = email?.toRecipients[0]?.emailAddress?.address || "-";

              const subject = email.subject ? email.subject : "-";

              return {
                ...email,
                id: email.id,
                email: from,
                labelTypeEmail: email?.isDraft
                  ? t("draft_email")
                  : from !== emailOutlook
                    ? t("received_email")
                    : t("sent_email"),
                subject,
                date: moment(email.sentDateTime).format("DD/MM/YYYY"),
                to,
              };
            })}
            headers={[
              { key: "email", title: "" },
              { key: "labelTypeEmail", title: "" },
              { key: "subject", title: "" },
              { key: "date", title: "" },
            ]}
            actions={[
              {
                id: "see",
                buttonProps: {
                  iconComponent: <FaEye className={"inline"} />,
                },
                onClick: (email) => {
                  getEmailContent(email);
                  showEmailDetail(true);
                },
              },
            ]}
          />
        </Card>
      )}
    </>
  );
};

export default EmailsList;
