import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AccountService from "../../../../services/account-service";
import { useToasts } from "react-toast-notifications";
import useUser from "../../../../hooks/useUser";
import { Loader } from "../../../ui/molecules/Loader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FormUtils from "../../../../utils/form-utils";
import Avatar from "react-avatar";
import { FaTrash } from "react-icons/fa";
import InputFile from "../../../ui/atoms/input-files";
import { Input } from "../../../ui/atoms/Input";
import Select from "../../../ui/atoms/Select";
import { Autocomplete } from "../../../ui/atoms/Autocomplete";
import Genders from "../../../../assets/json/genders.json";
import Nationalities from "../../../../assets/json/nationalities.json";
import { TextArea } from "../../../ui/atoms/TextArea";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { ButtonDanger } from "components/ui/atoms/ButtonDanger";
import SkillsKnowledges from "assets/json/skills-knowledges.json";

const AccountPersonalInfo = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { user, reloadUserInfo } = useUser();
  const [loading, setLoading] = useState(false);
  const [genders, setGenders] = useState([]);
  const [nationalities, setNationalities] = useState([]);

  const [skillsAndKnowledge, setSkillsAndKnowledge] = useState([]);

  const { register, handleSubmit, trigger, errors, setValue, getValues } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string().required(t("required_field")),
        last_name: Yup.string().required(t("required_field")),
        image: Yup.string().nullable(),
        phone_number: Yup.string().nullable(),
        nationality: Yup.string().nullable(),
        gender: Yup.string().nullable(),
        self_description: Yup.string().nullable(),
        date_of_birth: Yup.string()
          .nullable()
          .transform((value) => FormUtils.parseDateFromString(value, "YYYY-MM-DD HH:mm")),
      }),
      { abortEarly: false },
    ),
    criteriaMode: "all",
    reValidateMode: "all",
    mode: "onChange",
  });

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, config);
    trigger(name);
  };

  const onSelectImage = async(file) => {
    const fileSizeMb = (file?.size || 0) / 1024 / 1024;

    if (fileSizeMb > 5) {
      addToast(t("error_file_size", { max_size: 5 }), {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }

    setLoading(true);

    _setValue("image", file ? await FormUtils.fileToBase64(file) : null, { shouldValidate: true });

    setTimeout(() => setLoading(false), 500);
  };

  const updatePersonalInfo = (values) => {
    setLoading(true);
    AccountService.updatePersonalInfo({ ...values, skills_and_knowledge: skillsAndKnowledge })
      .then(() => reloadUserInfo())
      .then(() => {
        addToast(t("personal_info_updated_successfully"), {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        addToast(
          error.response && error.response.data.msg
            ? error.response.data.msg
            : t("error_occurred_updating_personal_info"),
          {
            appearance: "error",
            autoDismiss: true,
          },
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onInvalid = () => {
    addToast(t("form_field_error"), { appearance: "error", autoDismiss: true });
  };

  const getNationalityInitialValue = () => {
    const item = nationalities.find((n) => n.id === getValues().nationality);

    if (item) {
      return item;
    }

    return null;
  };

  useEffect(() => {
    register("image");
    register("nationality");
    register("gender");
  }, [register]);

  useEffect(() => {
    if (user) {
      if (user.date_of_birth) {
        _setValue(
          "date_of_birth",
          FormUtils.getDateInputValueFromDate(new Date(user.date_of_birth)),
        );
      }

      setSkillsAndKnowledge(user?.skills_and_knowledge?.map((opt) => opt._id) || []);
      _setValue("name", user.name);
      _setValue("last_name", user.last_name);
      _setValue("email", user.email);
      _setValue("phone_number", user.phone_number);
      _setValue("gender", user.gender);
      _setValue("self_description", user.self_description);
      _setValue("nationality", user.nationality?._id);
      _setValue("image", user.image, { shouldValidate: true });
    }
  }, [user]);

  useEffect(() => {
    setGenders(Genders.map((g) => ({ ...g, value: t(g.value) })));
    setNationalities(Nationalities.map((n) => ({ id: n._id, name: t(n.name) })));
  }, []);

  const skillsAndKnowledgeOptions = SkillsKnowledges.map((option) => ({ id: option._id, name: t(option.key) }));

  return (
    <>
      <div className="flex flex-row justify-center w-full">
        <div className="w-1/3 xl:w-1/6">
          {getValues().image ? (
            <>
              <div className="text-xs font-medium text-black">{t("account_image")}</div>
              <div className="flex flex-col justify-center w-32 mt-2">
                <Avatar
                  src={getValues().image}
                  size="100"
                  round={true}
                  color="#e0e6f2"
                  fgColor="#4d70b3"
                  className="object-contain mx-auto my-0"
                />
                <ButtonDanger
                  type="button"
                  iconComponent={<FaTrash className="inline-block mr-2" />}
                  text={t("remove")}
                  onClick={() => onSelectImage(null)}
                />
              </div>
            </>
          ) : (
            <div className="w-40">
              <InputFile
                label={t("account_image")}
                placeholder={t("select_account_image")}
                error={errors.image}
                setSelectedFile={(file) => onSelectImage(file)}
                accept="image/*"
                height="120px"
                padding="18px"
              />
            </div>
          )}
        </div>
        <div className="flex items-center w-2/3 mt-4 lg:w-5/6">
          <form
            className="flex flex-col w-full"
            onSubmit={handleSubmit(updatePersonalInfo, onInvalid)}
          >
            <div className="grid grid-cols-2 gap-4">
              <Input
                reference={register}
                name="name"
                placeholder={t("introduce_name")}
                label={t("name")}
                required={true}
                error={errors.name}
              />
              <Input
                name="last_name"
                placeholder={t("introduce_last_name")}
                label={t("last_name")}
                required={true}
                reference={register}
                error={errors.last_name}
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <Input
                name="email"
                placeholder={t("introduce_email")}
                label={t("email")}
                required={true}
                disabled={true}
                reference={register}
                error={errors.email}
              />
              <Input
                type="text"
                name="phone_number"
                placeholder={t("introduce_number")}
                label={t("phone_number")}
                reference={register}
                error={errors.phone_number}
              />
            </div>

            <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
              <Input
                type="date"
                name="date_of_birth"
                placeholder={t("date_of_birth")}
                label={t("date_of_birth")}
                reference={register}
              />

              <Select
                name="gender"
                label={t("gender")}
                reference={register}
                placeholder={t("select_gender")}
                items={genders}
                onSelect={(selection) =>
                  _setValue("gender", selection.length ? selection[0].id : null)
                }
                initialValues={
                  getValues().gender ? [genders.find((g) => g.id === getValues().gender)] : []
                }
              />

              <Autocomplete
                name="nationality"
                label={t("nationality")}
                placeholder={t("search_nationality")}
                options={nationalities}
                onSelect={(selected) => _setValue("nationality", selected?.id || null)}
                initialValues={getNationalityInitialValue()}
              />
            </div>

            <TextArea
              reference={register}
              name="self_description"
              error={errors.self_description}
              label={t("self_description")}
              maxLength={200}
              placeholder={t("introduce_self_description")}
            />

            <div className="flex justify-end mt-4">
              <ButtonMain width={"full lg:w-1/2"} text={t("save")} type={"submit"} />
            </div>
          </form>
        </div>
      </div>

      {loading && <Loader />}
    </>
  );
};

export default AccountPersonalInfo;
