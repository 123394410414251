import axios from "axios/index";
import Config from "../config";

const TeamService = {
  getTeammates: async(query) => axios.get(`${Config.TEAM_API_URL}teammates`, { params: query }),

  createTeammate: async(teammate) => axios.post(`${Config.TEAM_API_URL}teammates`, teammate),

  removeTeammate: async(teammateId, childrenId) =>
    axios.delete(`${Config.TEAM_API_URL}teammates/${teammateId}`, { params: { investor: childrenId } }),

  updateTeammate: async(teammateId, name, lastName, email, language) =>
    axios.patch(`${Config.TEAM_API_URL}teammates/${teammateId}`, {
      name,
      last_name: lastName,
      email,
      language,
    }),

  sendActivationCode: async(teammateId, childrenId) =>
    axios.post(`${Config.TEAM_API_URL}teammates/${teammateId}/send-activation-code`, { investor: childrenId }),
};
export default TeamService;
