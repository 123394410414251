import { Trans, useTranslation } from "react-i18next";
import { FaInfoCircle, FaTimes } from "react-icons/fa";
import Select from "components/ui/atoms/Select";
import Config from "config";
import TranslateUtils from "utils/translate-utils";
import { Card } from "components/ui/atoms/Card";

export const FormKeyQuestions = ({
  lang,
  keyQuestions,
  setKeyQuestions,
  selectedExpandedQuestions,
}) => {
  const { t } = useTranslation();

  const keyQuestionsKeys = [
    { key: "cif", type: "short_answer" },
    { key: "startup_name", type: "short_answer" },
    { key: "sector", type: "select" },
    { key: "business_model", type: "select" },
    { key: "country", type: "select" },
    { key: "project_stage", type: "select" },
    { key: "investment_stage", type: "select" },
    { key: "main_contact_email", type: "email" },
    { key: "startup_logo", type: "file_image" },
    { key: "brand_statement", type: "long_answer" },
    { key: "problem", type: "long_answer" },
    { key: "solution", type: "long_answer" },
    { key: "market", type: "long_answer" },
    { key: "business_description", type: "long_answer" },
    { key: "city", type: "short_answer" },
    { key: "startup_deck", type: "file" },
    { key: "one_pager", type: "file" },
    { key: "financials", type: "file" },
    { key: "business_plan", type: "file" },
    { key: "kpis", type: "file" },
    { key: "web", type: "url" },
    { key: "linkedin", type: "url" },
    { key: "crunchbase", type: "url" },
    /* { key: 'facebook', type: 'url' },
    { key: 'twitter', type: 'url' },
    { key: 'instagram', type: 'url' },
    { key: 'youtube', type: 'url' },
    { key: 'tiktok', type: 'url' }, */
    { key: "main_contact_person", type: "short_answer" },
    { key: "main_contact_phone", type: "phone" },
    { key: "investment_desired", type: "financial" },
    { key: "valuation", type: "financial" },
    { key: "minimum_ticket", type: "financial" },
    { key: "compromised_amount", type: "financial" },
    { key: "referrals", type: "short_answer" },
  ];

  const onSelectKeyQuestion = (key, questionId) => {
    setKeyQuestions({
      ...keyQuestions,
      [key]: questionId,
    });
  };

  const getKeyQuestionInitialValue = (key) => {
    const question = selectedExpandedQuestions.find((q) => q._id === keyQuestions[key]);
    if (!question) {
      return [];
    }

    return [
      {
        id: question._id,
        value: TranslateUtils.getTextByLang(lang, question.title),
      },
    ];
  };
  return (
    <>
      <div className="w-full flex flex-row my-6">
        <div className="w-full">
          <div className="mb-6">
            <Card wrapperClassName={"border-2 border-dotted border-border-buttons-secondary"}>
              <div className="font-semibold">
                <FaInfoCircle className="inline mr-2 w-5 h-5" />
                {t("what_are_key_questions")}
              </div>
              <div className="mt-2">
                <Trans
                  i18nKey="key_questions_explanation"
                  components={{ br: <br />, bold: <strong /> }}
                />
              </div>
            </Card>
          </div>
          {keyQuestionsKeys.map(({ key, type }) => (
            <div className="border-b py-1" key={key}>
              <Select
                key={key}
                name={key}
                initialValues={getKeyQuestionInitialValue(key)}
                onSelect={(value) => onSelectKeyQuestion(key, value.length ? value[0].id : null)}
                reset={keyQuestions[key] === null}
                setReset={(reset) => reset && onSelectKeyQuestion(key, null)}
                isClearable={true}
                noOptionsText={t("no_options_key_questions", { type: t(type) })}
                items={selectedExpandedQuestions
                  .filter((question) => type === "file_image"
                    ? (question.type === "file" &&
                          question?.file_accepted_extensions?.includes("image/*")) ||
                          question.type === "file_image"
                    : type !== "file"
                      ? question.type === type
                      : question.type === "file_excel" ||
                          question.type === "file_pdf" ||
                          question.type === "file")
                  .map((q) => ({
                    id: q._id,
                    value: TranslateUtils.getTextByLang(lang, q.title),
                  }))}
                label={
                  <>
                    {t(key)}
                    {/* {keyQuestions[key] && (
                      <span
                        data-tip={t('reset')}
                        className={`hover:underline text-main relative -top-[1px]`}
                        onClick={(e) => {
                          e.stopPropagation()
                          onSelectKeyQuestion(key, null)
                        }}
                      >
                        <FaTimes className="cursor-pointer inline-block ml-1" />
                      </span>
                    )} */}
                    <br />
                    <span className="text-gray">{t(type)}</span>
                  </>
                }
              />
              {key === "cif" && (
                <div className="text-xs font-semibold flex mb-6">
                  <FaInfoCircle className="mr-2" />
                  {t("question_cif_explanation")}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
