import { Card } from "components/ui/atoms/Card";
import Avatar from "react-avatar";
import { useTranslation } from "react-i18next";
import { FaEnvelope, FaPhone, FaUser } from "react-icons/fa";
import { useOutletContext } from "react-router-dom";

const MemberView = () => {
  const { member } = useOutletContext();

  const { t } = useTranslation();

  return (
        <>
          <div className="flex my-4">
            <div className="flex-1 w-full">
              <Card wrapperClassName="h-full" containerClassName="flex flex-col h-full">
                <div className="flex">
                  <div className="mr-4">
                    <Avatar
                      src={member.user?.image}
                      alt={member.user?.name}
                      color="#e0e6f2"
                      fgColor="#4d70b3"
                      size="100px"
                      round={false}
                      className={"avatar-startup"}
                    />
                  </div>
                  <div>
                    <h1 className={"text-4xl text-main mb-2 font-bold"}>{member.user?.name}</h1>
                    <div>
                      <p className="text-xs">
                        {member.user?.self_description ? (
                          <pre className="font-sans whitespace-pre-wrap">
                            {member.user.self_description}
                          </pre>
                        ) : (
                          <span className="text-gray font-normal text-xs">
                            {t("description_not_provided")}
                          </span>
                        )}
                      </p>
                    </div>
                    {!member.activation_date && (
                      <div>
                        <p className="text-xs font-semibold">{t("member_not_registered")}</p>
                      </div>
                    )}
                  </div>
                </div>

                {member && (
                  <div className="flex-1 border-t border-gray-lines pt-2 flex text-xs mt-2">
                    <FaUser className={"text-main mr-1"} data-tip={t("name")} />
                    {member.user?.name || "-"}
                    <FaEnvelope className={"text-main ml-4 mr-1"} data-tip={t("email")} />
                    {member.user?.email || "-"}
                    <FaPhone className={"text-main ml-4 mr-1"} data-tip={t("phone_number")} />
                    {member.user?.phone_number || "-"}
                  </div>
                )}
              </Card>
            </div>
          </div>
          {/* <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4 my-10">
            <div className="relative bg-white py-6 px-6 rounded-2xl border border-gray-lines my-4 shadow-soft-white h-56">
              <div className=" text-white flex items-center absolute rounded-full py-4 px-4 shadow-xl bg-lightBlue-600 left-4 -top-6">
                <FaRocket className="inline-block w-6 h-6" />
              </div>
              <div className="pt-6 flex flex-col justify-between h-full">
                <div>
                  <p className="text-xl font-semibold my-2">{t('startups_invested')}</p>
                </div>
                <div className="flex-1 flex font-bold justify-center items-center text-7xl">
                  <div>{member.startups_invested || '-'}</div>
                </div>
              </div>
            </div>

            <div className="relative bg-white py-6 px-6 rounded-2xl border border-gray-lines my-4 shadow-soft-white h-56">
              <div className="text-white flex items-center absolute rounded-full py-4 px-4 shadow-xl bg-pink-600 left-4 -top-6">
                <FaEuroSign className="inline-block w-6 h-6" />
              </div>
              <div className="pt-6 flex flex-col justify-between h-full">
                <div>
                  <p className="text-xl font-semibold my-2">{t('amount_invested')}</p>
                </div>
                <div className="flex-1 flex font-bold justify-center items-center text-7xl">
                  <div>{member.amount_invested || '-'}</div>
                </div>
              </div>
            </div>

            <div className="relative bg-white py-6 px-6 rounded-2xl border border-gray-lines my-4 shadow-soft-white h-56">
              <div className="text-white flex items-center absolute rounded-full py-4 px-4 shadow-xl bg-yellow-400 left-4 -top-6">
                <TbCircleHalf className="inline-block w-6 h-6" />
              </div>
              <div className="pt-6 flex flex-col justify-between h-full">
                <div>
                  <p className="text-xl font-semibold my-2">{t('avg_ticket')}</p>
                </div>

                <div className="flex-1 flex font-bold justify-center items-center text-7xl">
                <div>{member.avg_ticket || '-'}</div>
                </div>
              </div>
            </div>

            <div className="relative bg-white py-6 px-6 rounded-2xl border border-gray-lines my-4 shadow-soft-white h-56">
              <div className="text-white flex items-center absolute rounded-full py-4 px-4 shadow-xl bg-green left-4 -top-6">
                <BsBarChartSteps className="inline-block w-6 h-6" />
              </div>

              <div className="pt-6 flex flex-col justify-between h-full">
                <p className="text-xl font-semibold my-2">{t('investments_commited')}</p>
                <div className="flex-1 flex font-bold justify-center items-center text-7xl">
                <div>{member.investments_commited || '-'}</div>
                </div>
              </div>
            </div>
          </div> */}
        </>
  );
};

export default MemberView;
