import React, { useState, useEffect } from "react";
import { GoogleButton } from "components/ui/atoms/GoogleButton";
import { useTranslation } from "react-i18next";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { useSwal } from "hooks/useSwal";
import { OutlookButton } from "components/ui/atoms/OutlookButton";
import { getUser } from "services/outlook-service";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
const API_KEY = "AIzaSyDDXutZvDTGFliGz9_-PG1IwnXERSVu5jU";
const CLIENT_ID = "690938764456-ac8b32rplj2tfk4f43grc7iuh36udi8i.apps.googleusercontent.com";

const EmailSyncView = () => {
  const { t } = useTranslation();
  const { confirm } = useSwal();
  const msal = useMsal();
  const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(msal.instance, {
    account: msal.instance.getActiveAccount(),
    scopes: ["user.read", "mail.read", "mail.send"],
    interactionType: "none",
  });

  const [isAuthenticatedGoogle, setIsAuthenticatedGoogle] = useState(false);
  const [isAuthenticatedOutlook, setIsAuthenticatedOutlook] = useState(
    useIsAuthenticated() || false,
  );
  const [userEmailGoogle, setUserEmailGoogle] = useState("");
  const [userEmailOutlook, setUserEmailOutlook] = useState("");

  useEffect(() => {
    const handleLoadGoogleAuth = async () => {
      if (!gapi.auth2.getAuthInstance()) {
        await gapi.client.init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest"],
          scope:
            "https://www.googleapis.com/auth/gmail.readonly profile email https://www.googleapis.com/auth/gmail.compose https://www.googleapis.com/auth/gmail.send",
        });
      }

      const authInstance = gapi.auth2.getAuthInstance();

      if (authInstance.isSignedIn.get()) {
        const basicProfile = authInstance.currentUser.get().getBasicProfile();
        const _email = basicProfile.getEmail();
        setIsAuthenticatedGoogle(true);
        setUserEmailGoogle(_email);
      } else {
        handleLoginFailureGoogle();
        setIsAuthenticatedGoogle(false);
      }
    };

    gapi.load("client:auth2", handleLoadGoogleAuth);
  }, []);

  useEffect(() => {
    if (isAuthenticatedOutlook) {
      getUser(authProvider).then((dataUser) => {
        setIsAuthenticatedOutlook(true);
        const email = dataUser?.mail;
        setUserEmailOutlook(email);
      });
    }
  }, []);

  const handleLoginSuccessGoogle = (response) => {
    setIsAuthenticatedGoogle(true);

    // Recupera el email del usuario del objeto de respuesta
    const email = response?.profileObj?.email;
    setUserEmailGoogle(email);
  };

  const handleLoginFailureGoogle = (response) => {
    console.error("Error al iniciar sesión", response);
  };

  const handleLogoutGoogle = () => {
    confirm({}).then(async (isConfirmed) => {
      if (isConfirmed) {
        const authInstance = window.gapi.auth2.getAuthInstance();
        authInstance.signOut().then(() => {
          setIsAuthenticatedGoogle(false);
          setUserEmailGoogle("");
        });
      }
    });
  };

  const handleLoginSuccessOutlook = (data) => {
    setIsAuthenticatedOutlook(true);
    const email = data?.account?.username;
    setUserEmailOutlook(email);
  };

  const handleLogoutOutlook = async () => {
    confirm({}).then(async (isConfirmed) => {
      if (isConfirmed) {
        await msal.instance.logoutRedirect({
          onRedirectNavigate: () => {
            // Return false to stop navigation after local logout
            return false;
          },
        });
        setIsAuthenticatedOutlook(false);
        setUserEmailOutlook("");
      }
    });
  };

  return (
    <div>
      <h3 className={"border-b font-medium text-main font-bold mb-4 border-main"}>
        {t("synchronization")}
      </h3>
      <div className=" sm:flex-row items-start sm:items-center justify-start sm:justify-between">
        <div className="flex flex-row sm:flex-row items-start sm:items-center justify-start sm:justify-between mt-3">
          <div>
            {!isAuthenticatedGoogle && (
              <small className="text-gray-dark font-light">
                {t("enable_email_synchronization")} (Google)
              </small>
            )}
            {isAuthenticatedGoogle && (
              <>
                <small className="text-gray-dark font-light">{t("connected_with_email")}</small>
                <small className={"text-main font-semibold ml-1"}>{userEmailGoogle}</small>
              </>
            )}
          </div>
          <div>
            {isAuthenticatedGoogle ? (
              <ButtonSecondary
                onClick={() => handleLogoutGoogle()}
                text={t("disable_email_synchronization")}
              />
            ) : (
              <GoogleButton
                isActiveLoginOutlook={isAuthenticatedOutlook}
                text={t("enable_email_synchronization")}
                clientId={CLIENT_ID}
                onSuccess={handleLoginSuccessGoogle}
                onFailure={handleLoginFailureGoogle}
                cookiePolicy={"single_host_origin"}
              />
            )}
          </div>
        </div>
        <div className="flex flex-row sm:flex-row items-start sm:items-center justify-start sm:justify-between mt-3">
          <div>
            {!isAuthenticatedOutlook && (
              <small className="text-gray-dark font-light">
                {t("enable_email_synchronization")} (Outlook)
              </small>
            )}
            {isAuthenticatedOutlook && (
              <>
                <small className="text-gray-dark font-light">{t("connected_with_email")}</small>
                <small className={"text-main font-semibold ml-1"}>{userEmailOutlook}</small>
              </>
            )}
          </div>
          <div>
            <OutlookButton
              isActiveLoginGoogle={isAuthenticatedGoogle}
              onSuccess={handleLoginSuccessOutlook}
              onLogout={handleLogoutOutlook}
            ></OutlookButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailSyncView;
