import Config from "config";
/**
 *
 * @param content {string}
 * @param img {string}
 * @returns {JSX.Element}
 * @constructor
 */
export const TourStep = ({ content, img, resume = false }) => {
  return (
    <div className="border-b border-gray-lines pb-2 -mt-3">
      <div className="flex flex-row items-start">
        <div className={`flex flex-col justify-start text-left ${img ? "mb-6" : ""}`}>
          {content && <p className={"text-main"}>{content}</p>}
        </div>
      </div>

      {img && (
        <div className="w-full flex justify-center">
          <img src={img} alt="tour image" className={resume ? "w-1/2" : "w-full"} />
        </div>
      )}
    </div>
  );
};
