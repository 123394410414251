import { useTranslation } from "react-i18next";
import Select from "components/ui/atoms/Select";
import TranslateUtils from "utils/translate-utils";

export const QuestionTypeSelect = ({ currentQuestion, onChange, value, language }) => {
  const { t } = useTranslation();
  const showFilter = currentQuestion.question_id.options.length > 2;

  const getSelectInitialValues = () => {
    if (value === null || !Array.isArray(value)) return [];

    return value
      ? value
          .filter((answ) => answ !== null)
          .map((answ) => ({
            id: answ,
            value: TranslateUtils.getTextByLang(
              language,
              currentQuestion.question_id.options[answ],
            ),
          }))
      : [];
  };

  return (
    <Select
      className="questionnaire-select"
      id={currentQuestion.question_id._id}
      name={currentQuestion.question_id._id}
      key={currentQuestion.question_id._id}
      showFilter={showFilter}
      multiSelect={currentQuestion.question_id.type === "multiselect"}
      label={
        currentQuestion.question_id.options.length > 2
          ? currentQuestion.question_id.required
            ? t("select_answer")
            : t("optional_select_answer")
          : t("select_answer")
      }
      isClearable={!currentQuestion.required}
      placeholder={t("select_answer")}
      onSelect={(opts) => {
        onChange(opts.length ? opts.map((o) => o.id) : null);
      }}
      initialValues={getSelectInitialValues()}
      items={currentQuestion.question_id.options.map((option, index) => ({
        id: index,
        value: TranslateUtils.getTextByLang(language, option),
      }))}
    />
  );
};
