import axios from "axios/index";
import Config from "../config";
const moment = require("moment");

const ReportsService = {
  generateKpisReport: async (dealId) =>
    new Promise((resolve, reject) => {
      axios
        .post(
          `${Config.REPORTS_API_URL}kpis`,
          { deal: dealId },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "kpis-report.pdf");
          document.body.appendChild(link);
          link.click();
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    }),

  generateStartupProfileReport: async (reportId, dealId, dealName) =>
    new Promise((resolve, reject) => {
      axios
        .post(
          `${Config.REPORTS_API_URL}${reportId}`,
          { deal_id: dealId },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${dealName}_${moment().format("YYYY-MM-DD")}.pdf`);
          document.body.appendChild(link);
          link.click();
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    }),

  generateDealEvaluationsReport: async (dealId, evaluationProcessId) =>
    new Promise((resolve, reject) => {
      axios
        .post(
          `${Config.REPORTS_API_URL}evaluations`,
          { deal: dealId, evaluation_process: evaluationProcessId },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "evaluation-report.pdf");
          document.body.appendChild(link);
          link.click();
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    }),

  // generateEvaluationReport: async(judge, deal, evaluationProcess) => new Promise((resolve, reject) => {
  //   axios
  //     .post(`${Config.REPORTS_API_URL}evaluation`, { deal: deal._id, judge: judge.id, evaluation_process: evaluationProcess._id }, {
  //       responseType: "blob",
  //     })
  //     .then((response) => {
  //       const url = URL.createObjectURL(new Blob([response.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", `${evaluationProcess.name.replace(" ", "-")}-${judge.value.replace(" ", "-")}-${deal.name}-evaluation-report.pdf`);
  //       document.body.appendChild(link);
  //       link.click();
  //       resolve();
  //     })
  //     .catch((error) => {
  //       reject(error);
  //     });
  // }),

  // generateEvaluationsReport: async(evaluationProcessId) => new Promise((resolve, reject) => {
  //   axios
  //     .post(`${Config.REPORTS_API_URL}evaluations`, { evaluation_process: evaluationProcessId }, {
  //       responseType: "blob",
  //     })
  //     .then((response) => {
  //       const url = URL.createObjectURL(new Blob([response.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", "evaluation-report.pdf");
  //       document.body.appendChild(link);
  //       link.click();
  //       resolve();
  //     })
  //     .catch((error) => {
  //       reject(error);
  //     });
  // }),
  // generateExternalEvaluation: async(judge, deal, evaluationProcess) => new Promise((resolve, reject) => {
  //   axios
  //     .post(`${Config.REPORTS_API_URL}external-evaluation/`, { judge: judge._id, deal: deal._id, evaluation_process: evaluationProcess._id }, {
  //       responseType: "blob",
  //     })
  //     .then((response) => {
  //       const url = URL.createObjectURL(new Blob([response.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", "kpis-report.pdf");
  //       document.body.appendChild(link);
  //       link.click();
  //       resolve();
  //     })
  //     .catch((error) => {
  //       reject(error);
  //     });
  // }),
  // generateMemoReport: async(deal, data, clientId) =>
  //   new Promise((resolve, reject) => {
  //     axios
  //       .post(`${Config.REPORTS_API_URL}create-word/`, { data, dealName: deal.name, dealId: deal._id, clientId }, {
  //         responseType: "document/json",
  //       })
  //       .then((response) => {
  //         window.open(response.data);

  //         resolve();
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   }),

  // generateDealEvaluationsReport: async(dealId, evaluationProcessId) =>
  //   new Promise((resolve, reject) => {
  //     axios
  //       .post(
  //         `${Config.REPORTS_API_URL}evaluations`,
  //         { deal: dealId, evaluation_process: evaluationProcessId },
  //         {
  //           responseType: "blob",
  //         },
  //       )
  //       .then((response) => {
  //         const url = URL.createObjectURL(new Blob([response.data]));
  //         const link = document.createElement("a");
  //         link.href = url;
  //         link.setAttribute("download", "evaluation-report.pdf");
  //         document.body.appendChild(link);
  //         link.click();
  //         resolve();
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   }),

  generateEvaluationReport: async (judges, deal, evaluationProcess) =>
    new Promise((resolve, reject) => {
      axios
        .post(
          `${Config.REPORTS_API_URL}startup-evaluation `,
          { deal: deal._id, judges: judges.map((judge) => judge.id), evaluation_process: evaluationProcess._id },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${evaluationProcess.name.replace(" ", "-")}-${
              judges.length === 1 ? judges[0].value.replace(" ", "-") : "multi"
            }-${deal.name}-evaluation-report.pdf`
          );
          document.body.appendChild(link);
          link.click();
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    }),

  sendEvaluationReport: async (judges, deal, evaluationProcess, template) =>
    new Promise((resolve, reject) => {
      axios
        .post(
          `${Config.REPORTS_API_URL}evaluation/send`,
          {
            deal: deal._id,
            judges: judges.map((judge) => judge.id),
            evaluation_process: evaluationProcess._id,
            template,
          },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          // const url = URL.createObjectURL(new Blob([response.data]));
          // const link = document.createElement("a");
          // link.href = url;
          // link.setAttribute(
          //   "download",
          //     `${evaluationProcess.name.replace(" ", "-")}-${judges.length === 1 ? judges[0].value.replace(" ", "-") : "multi"}-${deal.name}-evaluation-report.pdf`,
          // );
          // document.body.appendChild(link);
          // link.click();
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    }),

  generateEvaluationsReport: async (evaluationProcessId) =>
    new Promise((resolve, reject) => {
      axios
        .post(
          `${Config.REPORTS_API_URL}evaluations`,
          { evaluation_process: evaluationProcessId },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "evaluation-report.pdf");
          document.body.appendChild(link);
          link.click();
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    }),

  generateExternalEvaluation: async (judge, deal, evaluationProcess) =>
    new Promise((resolve, reject) => {
      axios
        .post(
          `${Config.REPORTS_API_URL}external-evaluation/`,
          { judge: judge._id, deal: deal._id, evaluation_process: evaluationProcess._id },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${evaluationProcess.name.replace(" ", "-")}-${judge.judge.replace(" ", "-")}-${
              deal.name
            }-evaluation-report.pdf`
          );
          document.body.appendChild(link);
          link.click();
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    }),
  generateMemoReport: async (deal, data, clientId) =>
    new Promise((resolve, reject) => {
      axios
        .post(
          `${Config.REPORTS_API_URL}create-word/`,
          { data, dealName: deal.name, dealId: deal._id, clientId },
          {
            responseType: "document/json",
          }
        )
        .then((response) => {
          window.open(response.data);

          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    }),
  getReports: async (params) => {
    let query = "";
    if (params.sortBy) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}sortBy=${params.sortBy}`;
    }

    if (params.hasOwnProperty("page")) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}page=${params.page}`;
    }

    if (params.rowsPerPage) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}rowsPerPage=${params.rowsPerPage}`;
    }

    if (params.startup) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}startup=${params.startup}`;
    }

    return axios.get(`${Config.REPORTS_API_URL}${query}`);
  },

  getReport: async (reportId) => await axios.get(`${Config.REPORTS_API_URL}${reportId}`),

  getReportPreview: async (reportId) => await axios.get(`${Config.REPORTS_API_URL}${reportId}/preview`),

  deleteReport: async (reportId) => await axios.delete(`${Config.REPORTS_API_URL}${reportId}`),
};
export default ReportsService;
