import ReactDOM from "react-dom";
import { FaTimes } from "react-icons/fa";
import { IoExpand, IoContract } from "react-icons/io5";
import Config from "config";
import { useState, useRef, useEffect } from "react";

export const SidePanel = ({
  children,
  onClose,
  title,
  width,
  showExpand = false,
  closeOnClickOutside = true,
}) => {
  const [expand, setExpand] = useState(false);

  const panelRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Ignore clicks inside the panel or its children
      if (panelRef.current === event.target) {
        onClose && onClose();
      }
    };

    if (closeOnClickOutside) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      if (closeOnClickOutside) {
        document.removeEventListener("click", handleClickOutside);
      }
    };
  }, [onClose, closeOnClickOutside]);

  const handleClick = (event) => {
    // event.stopPropagation()
  };

  return ReactDOM.createPortal(
    <div
      className="fixed top-0 left-0 z-50 w-screen h-screen bg-gray-opacity side-panel"
      onClick={handleClick}
      ref={panelRef}
    >
      <div
        className={`fixed top-0 right-0 z-100 h-screen mb-16 shadow-soft-white overflow-auto overscroll-y-auto transition ${
          width && !expand && `w-${width}`
        } ${expand && "w-10/12"}`}
        style={{ animation: "appearFromRight 0.3s ease-in-out", backgroundColor: "#F8F8F9" }}
      >
        <div
          className={`flex px-4 py-3 bg-main text-white text-lg items-center flex justify-between`}
        >
          <div onClick={() => onClose && onClose()}>
            <FaTimes className="relative inline mr-4 -mt-1 cursor-pointer" />
            {title}
          </div>
          {showExpand && (
            <div>
              {expand ? (
                <IoContract
                  className="relative inline mr-4 -mt-1 cursor-pointer"
                  onClick={() => setExpand(!expand)}
                />
              ) : (
                <IoExpand
                  className="relative inline mr-4 -mt-1 cursor-pointer"
                  onClick={() => setExpand(!expand)}
                />
              )}
            </div>
          )}
        </div>
        <div className="p-4 pt-6">{children}</div>
      </div>
    </div>,
    document.body,
  );
};
