import { UserActionsTypes } from "./user-actions-types";

const UserActions = {
  setToken: (token) => ({
    type: UserActionsTypes.SET_TOKEN,
    payload: token,
  }),

  setApiKey: (apiKey) => ({
    type: UserActionsTypes.SET_API_KEY,
    payload: apiKey,
  }),

  setUserInfo: (data) => ({
    type: UserActionsTypes.SET_USER_INFO,
    payload: { user_info: data },
  }),

  setLoginEntries: (loginEntries) => ({
    type: UserActionsTypes.SET_LOGIN_ENTRIES,
    payload: { data: loginEntries },
  }),

  cleanUserData: () => ({
    type: UserActionsTypes.RESET,
  }),
};

export default UserActions;
