import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import InvestorsList from "components/irm/InvestorsList";
import { Alert } from "components/ui/molecules/Alert";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import { ShareDealSection } from "components/ui/molecules/ShareDealSection";
import useUser from "hooks/useUser";
import { useSwal } from "hooks/useSwal";
import { DealflowService } from "services/dealflow-service";
import useCurrency from "hooks/useCurrency";
import { useOutletContext } from "react-router-dom";

export const InvestorsMatching = () => {
  const { deal, setShowShareDealSection } = useOutletContext();

  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(true);
  const [investorsCurrentPage, setInvestorsCurrentPage] = useState(0);
  const [selectedContact, setSelectedContact] = useState(null);

  const { currency } = useCurrency();

  const { confirm } = useSwal();
  const { user, reloadUserInfo } = useUser();

  if (!deal) {
    return null;
  }

  const shareDeal = async(section, data) => {
    confirm({}).then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await DealflowService.shareDealflow(deal._id, {
            section,
            ...data,
            contacts: [
              {
                _id: selectedContact._id,
                name: selectedContact.contact || selectedContact.company,
                email: selectedContact.contact_email,
                language: selectedContact.language,
              },
            ],
          });
          addToast(t("deal_shared_successfully"), { appearance: "success", autoDismiss: true });
          setSelectedContact(null);
          reloadUserInfo();
        } catch (error) {
          console.error(error);
          addToast(t("error_occurred_sharing_deal"), { appearance: "error", autoDismiss: true });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  return (
    <>
      {selectedContact && (
        <ShareDealSection
          onClose={() => {
            setSelectedContact(null);
          }}
          onSubmit={shareDeal}
          initialValues={user.share_dealflow_preferences || {}}
        />
      )}
      <div className="mx-4 my-6">
        {(deal.sector ||
          deal.country_incorporation ||
          deal.project_stage ||
          deal.investment_stage ||
          deal.business_model ||
          deal.valuation ||
          deal.minimum_ticket) && (
          <>
            <Alert
              bgColor={"bg-white"}
              style={"info"}
              text={
                <div>
                  <p className={"font-medium text-base text-main"}>
                    {`${t("investors_matching_criteria")}:`}
                  </p>
                  <ul className="my-2">
                    {deal.sector && (
                      <li>
                        <span className="font-semibold">{`${t("sector")}: `}</span>
                        <span>{t(deal.sector.key)}</span>
                      </li>
                    )}
                    {deal.country_incorporation && (
                      <li>
                        <span className="font-semibold">{`${t("incorporation_country")}: `}</span>
                        <span>{t(deal.country_incorporation.key)}</span>
                      </li>
                    )}
                    {deal.project_stage && (
                      <li>
                        <span className="font-semibold">{`${t("project_stage")}: `}</span>
                        <span>{t(deal.project_stage.key)}</span>
                      </li>
                    )}
                    {deal.investment_stage && (
                      <li>
                        <span className="font-semibold">{`${t("investment_stage")}: `}</span>
                        <span>{t(deal.investment_stage.key)}</span>
                      </li>
                    )}
                    {deal.business_model && (
                      <li>
                        <span className="font-semibold">{`${t("business_model")}: `}</span>
                        <span>{t(deal.business_model.key)}</span>
                      </li>
                    )}
                    {deal.valuation && (
                      <li>
                        <span className="font-semibold">{`${t("valuation")}: `}</span>
                        <span>{`${deal.valuation} ${t(deal.valuation_unit)} ${currency}`}</span>
                      </li>
                    )}
                    {deal.minimum_ticket && (
                      <li>
                        <span className="font-semibold">{`${t("minimum_ticket")}: `}</span>
                        <span>{`${deal.minimum_ticket} ${t(deal.minimum_ticket_unit)} ${currency}`}</span>
                      </li>
                    )}
                  </ul>
                </div>
              }
            />
            <InvestorsList
              setShowShareDealSection={setShowShareDealSection}
              startup={deal}
              filters={{
                sectors: deal.sector ? [deal.sector._id] : null,
                countries: deal.country_incorporation
                  ? [deal.country_incorporation._id]
                  : null,
                project_stages: deal.project_stage ? [deal.project_stage._id] : null,
                investment_stages: deal.investment_stage ? [deal.investment_stage._id] : null,
                business_models: deal.business_model ? [deal.business_model._id] : null,
                valuation: ![null, undefined].includes(deal.valuation)
                  ? deal.valuation
                  : null,
                ticket: ![null, undefined].includes(deal.minimum_ticket)
                  ? deal.minimum_ticket
                  : null,
                valuation_unit: deal.valuation_unit || "k",
                ticket_unit: deal.minimum_ticket_unit || "k",
              }}
              loading={loading}
              setLoading={setLoading}
              currentPage={investorsCurrentPage}
              setCurrentPage={setInvestorsCurrentPage}
              irm={false}
              setSelectedContact={setSelectedContact}
            />
          </>
        )}
        {!deal.sector &&
          !deal.country_incorporation &&
          !deal.project_stage &&
          !deal.investment_stage &&
          !deal.business_model &&
          !deal.valuation &&
          !deal.minimum_ticket && (
            <NoDataInfo
              title={t("insufficient_data")}
              textOne={t("insufficient_data_for_investors_matching")}
            />
        )}
      </div>
    </>
  );
};
