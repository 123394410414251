import { useTranslation } from "react-i18next";
import Config from "config";

export function PoweredText() {
  const { t } = useTranslation();

  return (
    <div className="sm:w-3/4 bottom-0 | text-center border-gray-lines hover:underline cursor-pointer">
      <a
        href="https://www.kiota.com"
        target="_blank"
        className="block left-0 | text-gray font-normal text-xxs"
        rel="noreferrer"
      >
        {t("powered_by_kiota")}
      </a>
      {Config.images?.banner && (
        <img
          style={{
            marginTop: "20px",
          }}
          src={Config.images.banner}
          height={200}
          width={1000}
        />
      )}
    </div>
  );
}
