import { SectionHeader } from "../../ui/molecules/SectionHeader";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FormsService from "../../../services/forms-service";
import { useToasts } from "react-toast-notifications";
import { Loader } from "../../ui/molecules/Loader";

import { FaEye, FaEyeSlash, FaTrash } from "react-icons/fa";
import { Alert } from "../../ui/molecules/Alert";
import { FormGeneralInformation } from "./forms-form/FormGeneralInformation";
import { FormAppearance } from "./forms-form/FormAppearance";
import { RoutesLinks } from "../../routes-links";
import FormUtils from "../../../utils/form-utils";
import { useSwal } from "../../../hooks/useSwal";
import { useNavigate } from "react-router-dom";
import { ButtonDanger } from "components/ui/atoms/ButtonDanger";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";

export const FormSettingsSection = ({
  marieClient,
  setMarieClient,
  templateId,
  questionnaire,
  getForm,
  onSave,
  boards,
  assignStartupsToPipelineManager,
  setAssignStartupsToPipelineManager,
  pipelineManagerBoard,
  setPipelineManagerBoard,
  pipelineManagerStage,
  setPipelineManagerStage,
  name,
  setName,
  formBanner,
  setFormBanner,
  formDescription,
  setFormDescription,
  formFavicon,
  setFormFavicon,
  formTitle,
  setFormTitle,
  formNotificationEmail,
  setFormNotificationEmail,
  formEndedDescription,
  setFormEndedDescription,
  languages,
  setLanguages,
  sendNotificationEmail,
  setSendNotificationEmail,
  customEndedMessage,
  setCustomEndedMessage,
  formBackgroundColor,
  setFormBackgroundColor,
  formFontColor,
  setFormFontColor,
  formButtonFontColor,
  setFormButtonFontColor,
  formButtonColor,
  setFormButtonColor,
  visibilityMode,
  setVisibilityMode,
  showInvestorsPercentage,
  setShowInvestorsPercentage,
  activeTemplate,
  setActiveTemplate,
  category,
  setCategory,
  estimatedDuration,
  setEstimatedDuration,
  templateLogo,
  setTemplateLogo,
  investorName,
  setInvestorName,
  preScoreThreshold,
  setPreScoreThreshold,
  isMarieLite,
  isMariePro,
  setIsMarieLite,
  setIsMarieEntrepreneur,
  isMarieEntrepreneur,
  setIsMariePro,
  marieLiteRecipient,
  setMarieLiteRecipient,
  marieProRecipient,
  setMarieProRecipient,
  marieType,
  marieRecipient,
  setMarieRecipient,
  setMarieType,
  setFormAsMain,
  formAsMain,
}) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { alert, confirm } = useSwal();
  const navigate = useNavigate();
  const [section, setSection] = useState("general");
  const [sections] = useState(["general", "appearance"].map((sec) => ({ id: sec, title: sec, route: sec })));
  const [loading, setLoading] = useState(false);

  const [stages, setStages] = useState([]);
  const [boardInitialValues, setBoardInitialValues] = useState([]);
  const [stageInitialValues, setStageInitialValues] = useState([]);

  useEffect(() => {
    if (pipelineManagerBoard) {
      onSelectBoard(pipelineManagerBoard);
    }
    if (pipelineManagerStage) {
      const board = boards.find((board) => board._id === pipelineManagerBoard);
      if (board) {
        const _stages = board.columns.map((stage) => ({ id: stage._id, value: stage.name }));
        setStageInitialValues(_stages.filter((st) => st.id === pipelineManagerStage));
      }
    }
  }, []);

  const onSelectImage = async (field, file) => {
    const fileSizeMb = (file?.size || 0) / 1024 / 1024;

    if (fileSizeMb > 5) {
      addToast(t("error_file_size", { max_size: 5 }), {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }

    setLoading(true);
    if (field === "template_logo") {
      setTemplateLogo(file ? await FormUtils.fileToBase64(file) : null);
    } else if (field === "form_banner") {
      setFormBanner(file ? await FormUtils.fileToBase64(file) : null);
    } else if (field === "form_favicon") {
      setFormFavicon(file ? await FormUtils.fileToBase64(file) : null);
    }
    setTimeout(() => setLoading(false), 500);
  };

  const disableForm = async () => {
    confirm({
      title: t("are_you_sure"),
      icon: "warning",
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        await FormsService.updateQuestionnairesForm(questionnaire._id, { published: false })
          .then(() => {
            addToast(t("form_disabled_successfully"), {
              appearance: "success",
              autoDismiss: true,
            });
            getForm();
          })
          .catch((error) => {
            addToast(
              error.response && error.response.data.msg_key
                ? t(error.response.data.msg_key)
                : t("error_occurred_disabling_form"),
              {
                appearance: "error",
                autoDismiss: true,
              }
            );
          });
      }
    });
  };

  const enableForm = async () => {
    confirm({
      title: t("are_you_sure"),
      icon: "warning",
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        await FormsService.updateQuestionnairesForm(questionnaire._id, { published: true })
          .then(() => {
            addToast(t("form_enabled_successfully"), {
              appearance: "success",
              autoDismiss: true,
            });
            getForm();
            onSave && onSave(true);
          })
          .catch((error) => {
            console.error(error);
            addToast(
              error.response && error.response.data.msg_key
                ? t(error.response.data.msg_key)
                : t("error_occurred_enabling_form"),
              {
                appearance: "error",
                autoDismiss: true,
              }
            );
          });
      }
    });
  };

  const discardDraft = async () => {
    confirm({
      title: t("are_you_sure"),
      icon: "warning",
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await FormsService.updateQuestionnairesForm(questionnaire._id, { draft: null }).then(() => {
            addToast(t("form_updated_successfully"), {
              appearance: "success",
              autoDismiss: true,
            });
            getForm();
          });
        } catch (error) {
          addToast(t("error_occurred_updating_form"), {
            appearance: "error",
            autoDismiss: true,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const deleteForm = () => {
    confirm({
      title: t("are_you_sure"),
      text: t(questionnaire.published ? "delete_published_form_warning" : "revert_notification"),
      icon: "warning",
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          await FormsService.deleteQuestionnairesForm(questionnaire._id);
          addToast(t("form_deleted_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
          navigate(RoutesLinks.QE_FORMS_LINK);
        } catch (error) {
          addToast(
            error.response
              ? error.response.data.msg_key
                ? t(error.response.data.msg_key)
                : t("error_occurred")
              : t("error_occurred"),
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        }
      }
    });
  };

  const checkValues = () => {
    if (sendNotificationEmail && !formNotificationEmail) {
      addToast(t("application_notification_email_required"), {
        appearance: "error",
        autoDismiss: true,
      });
      return false;
    } else if (customEndedMessage && !formEndedDescription) {
      addToast(t("custom_application_ended_message_required"), {
        appearance: "error",
        autoDismiss: true,
      });
      return false;
    } else if (!languages.length) {
      addToast(t("form_should_contains_at_least_one_language"), {
        appearance: "error",
        autoDismiss: true,
      });
      return false;
    }

    return true;
  };

  const onSelectBoard = (boardId) => {
    const board = boards.find((board) => board._id === boardId);

    if (board) {
      setBoardInitialValues([{ id: board._id, value: board.name }]);
      setPipelineManagerBoard(boardId);
      setStages(board.columns.map((stage) => ({ id: stage._id, value: stage.name })));
    } else {
      setBoardInitialValues([]);
      setStages([]);
      setPipelineManagerBoard(null);
    }
  };

  const onSelectStage = (stageId) => {
    const stage = stages.find((stage) => stage.id === stageId);
    if (stage) {
      setStageInitialValues([stage]);
      setPipelineManagerStage(stageId);
    } else {
      setStageInitialValues([]);
      setPipelineManagerStage(null);
    }
  };

  return (
    <>
      <SectionHeader
        section={section}
        setSection={setSection}
        sectionKeys={sections}
        sectionTitles={sections.map((s) => t(s))}
      />

      {!!questionnaire?.draft && <Alert style="warning" text={t("form_draft_warning_2")} />}

      {section === "general" && (
        <>
          <FormGeneralInformation
            marieClient={marieClient}
            setMarieClient={setMarieClient}
            isMarieLite={isMarieLite}
            isMariePro={isMariePro}
            setIsMarieLite={setIsMarieLite}
            setIsMarieEntrepreneur={setIsMarieEntrepreneur}
            isMarieEntrepreneur={isMarieEntrepreneur}
            setIsMariePro={setIsMariePro}
            marieProRecipient={marieProRecipient}
            setMarieProRecipient={setMarieProRecipient}
            marieLiteRecipient={marieLiteRecipient}
            setMarieLiteRecipient={setMarieLiteRecipient}
            setMarieRecipient={setMarieRecipient}
            setMarieType={setMarieType}
            marieType={marieType}
            marieRecipient={marieRecipient}
            name={name}
            setName={setName}
            formNotificationEmail={formNotificationEmail}
            setFormNotificationEmail={setFormNotificationEmail}
            setFormEndedDescription={setFormEndedDescription}
            formEndedDescription={formEndedDescription}
            sendNotificationEmail={sendNotificationEmail}
            setSendNotificationEmail={setSendNotificationEmail}
            customEndedMessage={customEndedMessage}
            setCustomEndedMessage={setCustomEndedMessage}
            visibilityMode={visibilityMode}
            setVisibilityMode={setVisibilityMode}
            showInvestorsPercentage={showInvestorsPercentage}
            setShowInvestorsPercentage={setShowInvestorsPercentage}
            languages={languages}
            setLanguages={setLanguages}
            assignStartupsToPipelineManager={assignStartupsToPipelineManager}
            setAssignStartupsToPipelineManager={setAssignStartupsToPipelineManager}
            pipelineManagerBoard={pipelineManagerBoard}
            setPipelineManagerBoard={setPipelineManagerBoard}
            pipelineManagerStage={pipelineManagerStage}
            setPipelineManagerStage={setPipelineManagerStage}
            stages={stages}
            boards={boards}
            onSelectBoard={onSelectBoard}
            onSelectStage={onSelectStage}
            boardInitialValues={boardInitialValues}
            stageInitialValues={stageInitialValues}
            preScoreThreshold={preScoreThreshold}
            setPreScoreThreshold={setPreScoreThreshold}
            setFormAsMain={setFormAsMain}
            formAsMain={formAsMain}
            formId={questionnaire._id}
          />
        </>
      )}

      {section === "appearance" && (
        <FormAppearance
          formBanner={formBanner}
          onSelectImage={onSelectImage}
          formTitle={formTitle}
          setFormTitle={setFormTitle}
          formDescription={formDescription}
          setFormDescription={setFormDescription}
          formFavicon={formFavicon}
          formBackgroundColor={formBackgroundColor}
          setFormBackgroundColor={setFormBackgroundColor}
          formFontColor={formFontColor}
          setFormFontColor={setFormFontColor}
          formButtonColor={formButtonColor}
          setFormButtonColor={setFormButtonColor}
          formButtonFontColor={formButtonFontColor}
          setFormButtonFontColor={setFormButtonFontColor}
        />
      )}

      <div className="flex justify-end pb-8 mt-4">
        <ButtonDanger
          onClick={deleteForm}
          text={t("delete_form")}
          iconComponent={<FaTrash className="inline mr-3" />}
          marginRight={2}
        />

        {!questionnaire?.published && (
          <ButtonSecondary
            onClick={enableForm}
            text={t("enable_form")}
            iconComponent={<FaEye className="inline mr-3" />}
            marginRight={2}
          />
        )}

        {questionnaire?.published && (
          <ButtonSecondary
            onClick={disableForm}
            text={t("disable_form")}
            iconComponent={<FaEyeSlash className="inline mr-3" />}
            marginRight={2}
          />
        )}

        {!!questionnaire?.draft && (
          <ButtonSecondary
            onClick={discardDraft}
            text={t("discard_draft")}
            iconComponent={<FaEyeSlash className="inline mr-3" />}
            marginRight={2}
          />
        )}
      </div>

      {loading && <Loader />}
    </>
  );
};
