import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { FaGripHorizontal, FaListAlt, FaSearch, FaTrashAlt } from "react-icons/fa";
import { Pagination } from "../ui/atoms/Pagination";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { DealflowService } from "../../services/dealflow-service";
import { DealflowPoolListItem } from "./DealflowPoolListItem";
import { usePagination } from "../../hooks/usePagination";
import { DealflowFilters } from "./DealflowFilters";
import Config from "config";
import CreateStartupForm from "components/edit-startup/components/CreateStartupForm";
import { SidePanel } from "components/ui/objects/SidePanel";
import PipelineManagersService from "services/pipeline-managers-service";
import { useSwal } from "hooks/useSwal";
import useUser from "hooks/useUser";
import { RoutesLinks } from "components/routes-links";
import { useNavigate, useOutletContext } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Utils from "utils/utils";
import DealflowTable from "./DealflowTable";
import SuggestedStep from "components/ui/molecules/SuggestedStep";
import { CustomFieldsService } from "services/custom-fields-service";
import { DealflowCustomTableService } from "services/dealflow-custom-table-service";
import { countAppliedFilters } from "utils/countAppliedFilters";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { SkeletonList } from "components/ui/atoms/skeletons/SkeletonList";
import { ButtonDanger } from "components/ui/atoms/ButtonDanger";
import Select from "components/ui/atoms/Select";

export const ArchivedStartups = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [dealflow, setDealflow] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingBoards, setLoadingBoards] = useState(false);
  const { user, reloadUserInfo, userLanguage } = useUser();

  const [showDealSection, setShowDealSection] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({});
  const [filterName, setFilterName] = useState();
  const [boards, setBoards] = useState([]);
  const { confirm, alert } = useSwal();
  const [viewMode, setViewMode] = useState(user.preferences.dealflow_visibility_mode || "grid");
  const [columns, setColumns] = useState([]);
  const [loadingCustomTable, setLoadingCustomTable] = useState(false);
  const navigate = useNavigate();
  const [customFields, setCustomFields] = useState([]);
  const [loadingCustomFields, setLoadingCustomFields] = useState(false);
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);

  const { setDealToResume, setDealToShare, setFiltersCount, ref } = useOutletContext();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const { setTotalPages, totalPages, setPage, page, rowsPerPage, setRowsPerPage } =
      usePagination(12);

  const getDealflow = (name = undefined) => {
    setLoading(true);
    return DealflowService.getDealflow({
      ...filters,
      name: name ?? filterName,
      rowsPerPage,
      page,
      sortBy: "-createdAt",
      pool: "true",
      archived: "true",
      reminders: "true",
    })
      .then((result) => {
        const { dealflow, pagination } = result.data.data;
        setDealflow(dealflow);
        setTotalPages(pagination.pages);
      })
      .catch(() => {
        addToast(t("error_occurred_retrieving_dealflow"), {
          appearance: "error",
          autoDismiss: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSetFilters = (_filters) => {
    setFiltersCount(countAppliedFilters(_filters));
    setFilters(_filters);
  };

  const getCustomFields = () => {
    setLoadingCustomFields(true);
    CustomFieldsService.getCustomFields()
      .then((response) => {
        setCustomFields(response.data.data);
        ReactTooltip.rebuild();
      })
      .catch((error) => {
        addToast(
          error.response && error.response.data.msg_key
            ? error.response.data.msg_key
            : t("error_occurred_retrieving_custom_fields"),
          {
            appearance: "error",
            autoDismiss: true,
          },
        );
      })
      .finally(() => {
        setLoadingCustomFields(false);
      });
  };

  const getPipelineManagers = async() => {
    try {
      setLoadingBoards(true);
      const result = await PipelineManagersService.getPipelineManagers();
      setBoards(result.data.data);
    } catch (error) {
      console.error(error);
      addToast(
        error.response && error.response.data.msg_key
          ? error.response.data.msg_key
          : t("error_occurred_retrieving_boards"),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
    } finally {
      setLoadingBoards(false);
    }
  };

  const getCustomTable = async() => {
    try {
      setLoadingCustomTable(true);
      const result = await DealflowCustomTableService.getCustomTable();
      setColumns(result.data.data.columns);
    } catch (error) {
      console.error(error);
      addToast(
        error.response && error.response.data.msg_key
          ? error.response.data.msg_key
          : t("error_occurred_retrieving_custom_table_columns"),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
    } finally {
      setLoadingCustomTable(false);
    }
  };

  const [showSuggestedStep, setShowSuggestedStep] = useState(false);

  const showAddStartupModal = () => {
    if (
      dealflow?.length ||
        !!user?.onboarding_tasks.includes("add_startup_to_pool") ||
        !!user?.investor?.onboarding_tasks?.includes("add_startup_to_pool") ||
        Utils.checkIfPreviousStepsArCompleted(
          user?.onboarding_tasks,
          user?.investor?.onboarding_tasks || [],
          "add_startup_to_pool",
        )
    ) {
      setShowDealSection(true);
    } else {
      setShowSuggestedStep(true);
    }
  };

  const addDeal = (data) => {
    if (
      data.assign_startup_to_pipeline_manager &&
        (!data.pipeline_manager_board || !data.pipeline_manager_stage)
    ) {
      alert({ text: t("assign_startup_to_pipeline_manager_incomplete") });
      return;
    }
    confirm({}).then(async(isConfirmed) => {
      if (isConfirmed) {
        setLoading(true);
        try {
          await DealflowService.addDeal(data);
          getDealflow();
          addToast(t("startup_added_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
          reloadUserInfo();
          setShowDealSection(false);
        } catch (error) {
          console.error(error);
          addToast(t(error?.response?.data?.msg_key || "error_adding_startup"), {
            appearance: "error",
            autoDismiss: true,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  useImperativeHandle(
    ref,
    () => ({
      addStartup() {
        showAddStartupModal();
      },
      showFilters() {
        setShowFilters(true);
      },
    }),
    [],
  );

  useEffect(() => {
    getPipelineManagers();
    getCustomTable();
    getCustomFields();
  }, []);

  useEffect(() => {
    getDealflow();
  }, [page, rowsPerPage, filters]);

  const handleClearFilterAndSubmit = (e) => {
    e.preventDefault();
    setFilterName(""); // Ensuring the state is cleared
    getDealflow(""); // Calling getDealflow with the cleared filter name
  };

  return (
      <>
        {showSuggestedStep && (
          <SuggestedStep
            action={"add_startup_to_pool"}
            showSuggestedStep={!!showSuggestedStep}
            setShowSuggestedStep={setShowSuggestedStep}
            continueAction={() => {
              setShowSuggestedStep(false);
              setShowDealSection(true);
            }}
            continueText={t("add_startup")}
          />
        )}
        {!loading && dealflow.length === 0 && (
          <div className="mb-10 mt-6">
            <div className="flex justify-end mb-4">
            <form className="flex gap-2" onSubmit={(e) => {
              e.preventDefault();
              getDealflow();
            }}>
                <input
                  className={
                    "shadow-soft-white border border-gray-lines focus:border-main bg-input w-full py-2 px-7 relative z-10 text-left text-xs font-normal rounded-2xl placeholder-gray cursor-pointer transition-all duration-200 outline-none hover:border-main hover:outline-none hover:shadow-inner focus:outline-none focus:shadow-focus active:outline-none"
                  }

                  placeholder={t("dealflow_example")}
                  onChange={(e) => {
                    setFilterName(e.currentTarget.value);
                  }}
                  value={filterName}
                />
                <ButtonSecondary
                  type="submit"
                  iconComponent={<FaSearch className="h-4" />}
                />
                {filterName && (<ButtonDanger
                  type="button"
                  onClick={handleClearFilterAndSubmit}
                  iconComponent={<FaTrashAlt className="h-4" />}
                />)}
              </form>
            </div>
            <img
              src={`${Config.GUIDE_STEPS_BACKGROUND_IMAGES_BUCKET}dealflow_pool_${userLanguage}.png`}
              className="w-auto"
            />
          </div>
        )}

        {!loading && dealflow.length > 0 && (
          <div className="mt-6">
            <div className="flex justify-end mb-4 gap-4">
            <form className="flex gap-2" onSubmit={(e) => {
              e.preventDefault();
              getDealflow();
            }}>
                <input
                  className={
                    "shadow-soft-white border border-gray-lines focus:border-main bg-input w-full py-2 px-7 relative z-10 text-left text-xs font-normal rounded-2xl placeholder-gray cursor-pointer transition-all duration-200 outline-none hover:border-main hover:outline-none hover:shadow-inner focus:outline-none focus:shadow-focus active:outline-none"
                  }

                  placeholder={t("dealflow_example")}
                  onChange={(e) => {
                    setFilterName(e.currentTarget.value);
                  }}
                  value={filterName}
                />
                <ButtonSecondary
                  type="submit"
                  iconComponent={<FaSearch className="h-4" />}
                />
                {filterName && (<ButtonDanger
                  type="button"
                  onClick={handleClearFilterAndSubmit}
                  iconComponent={<FaTrashAlt className="h-4" />}
                />)}
              </form>
              <div className="flex">
                <div
                  className={`mr-1 w-10 flex items-center justify-center p-1 ${viewMode === "list" ? "shadow-inner border-2 border-main" : "border border-border-buttons-secondary"} bg-white hover:shadow-inner mr-2 rounded-lg cursor-pointer`}
                  onClick={() => setViewMode("list")}
                >
                  <FaListAlt className="inline" />
                </div>
                <div
                  className={`mr-1 w-10 flex items-center justify-center p-1 ${viewMode === "grid" ? "shadow-inner border-2 border-main" : "border border-border-buttons-secondary"} bg-white hover:shadow-inner mr-2 rounded-lg cursor-pointer`}
                  onClick={() => setViewMode("grid")}
                >
                  <FaGripHorizontal className="inline" />
                </div>
              </div>
            </div>
            {viewMode === "grid" && (
              <div className="grid grid-cols-1 lg:grid-cols-2  2xl:grid-cols-2 gap-4">
                {dealflow.map((dealflow) => (
                  <DealflowPoolListItem
                    key={dealflow._id}
                    dealflow={dealflow}
                    setDealToResume={setDealToResume}
                    setDealToShare={setDealToShare}
                    showAdditionalInfo={showAdditionalInfo}
                    setShowAdditionalInfo={setShowAdditionalInfo}
                  />
                ))}
              </div>
            )}

            {viewMode === "list" && (
              <>
                <DealflowTable
                  columns={columns}
                  dealflow={dealflow}
                  setDealToShare={setDealToShare}
                  setDealToResume={setDealToResume}
                  goToProfile={(deal) => navigate(`${RoutesLinks.DEALFLOW_LINK}${deal._id}`)}
                  getCustomTable={getCustomTable}
                  customFields={customFields}
                />
              </>
            )}

            <Pagination
              showRowsPerPage={true}
              paginateOptions={[12, 24, 36]}
              currentPage={page}
              setCurrentPage={(page) => setPage(page)}
              perPage={rowsPerPage}
              pages={totalPages}
              setPerPage={(value) => setRowsPerPage(value[0].id)}
            />
          </div>
        )}

        {showDealSection && (
          <SidePanel onClose={() => setShowDealSection(false)} title={t("add_startup")} width="1/4">
            <CreateStartupForm onSubmit={addDeal} boards={boards} />
          </SidePanel>
        )}

        {showFilters && (
          <DealflowFilters
            onClose={() => setShowFilters(false)}
            onFilter={handleSetFilters}
            filters={filters}
          />
        )}

        {(loading || loadingBoards || loadingCustomTable || loadingCustomFields) && <div className="grid grid-cols-1 lg:grid-cols-2  2xl:grid-cols-2 gap-4 mt-6"><SkeletonList length={6} style="rounded-xl h-72"/></div>}
      </>
  );
};
