import { FaArrowRight, FaCheckCircle } from "react-icons/fa";
import { BsCircle } from "react-icons/bs";
import Config from "config";

export function ProgressTask({ title, description, completed, onClick }) {
  return (
    <div
      onClick={() => onClick && !completed && onClick()}
      className={
        (onClick && !completed ? "cursor-pointer " : "") +
        (completed ? "text-main" : !onClick ? "text-gray-600 " : "text-gray hover:text-main") +
        " my-4 w-full"
      }
    >
      <div className={`w-full text-base ${completed && "font-bold"} justify-between flex`}>
        <div>
          {!completed && <BsCircle className="inline-block mr-2" />}
          {completed && <FaCheckCircle className="inline-block mr-2" />}
          {title}
        </div>
        <div>
          <FaArrowRight />
        </div>
      </div>
      <div className="pl-5 text-sm font-light">{description}</div>
    </div>
  );
}
