import { useEffect } from "react";
import { SidePanel } from "../ui/objects/SidePanel";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useToasts } from "react-toast-notifications";
import { Input } from "../ui/atoms/Input";
import Select from "../ui/atoms/Select";
import { Autocomplete } from "../ui/atoms/Autocomplete";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import Config from "config";
import useUser from "hooks/useUser";

export const RemindersFilters = ({
  onFilter,
  onClose,
  deals = [],
  members = [],
  lps = [],
  filters = {},
}) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { hasAccessToModule } = useUser();

  const ReminderTypes = [
    { value: t("call"), id: "call" },
    { value: t("meeting"), id: "meeting" },
    { value: t("task"), id: "task" },
    { value: t("deadline"), id: "deadline" },
    { value: t("email"), id: "email" },
    { value: t("other"), id: "other" },
  ];

  const { register, handleSubmit, trigger, errors, setValue, reset, watch } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        reminder_type: Yup.string().nullable(),
        deal: Yup.string().nullable(),
        lp: Yup.string().nullable(),
        member: Yup.string().nullable(),
        min_date: Yup.string().nullable(),
        max_date: Yup.string().nullable(),
      }),
      { abortEarly: false },
    ),
    criteriaMode: "all",
    reValidateMode: "all",
    mode: "onChange",
  });

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, config);
    trigger(name);
  };

  const filter = ({ clearFilters, ...filterValues }) => {
    let _filters = {};

    if (!clearFilters) {
      _filters = Object.keys(filterValues).reduce((acc, key) => {
        if (filterValues[key]) {
          acc[key] = filterValues[key];
        }
        return acc;
      }, {});
    }

    onFilter && onFilter(_filters);
    onClose && onClose();
  };

  const resetFilters = () => {
    reset();
    filter({ clearFilters: true });
  };

  const onInvalid = () => {
    addToast(t("form_field_error"), { appearance: "error", autoDismiss: true });
  };

  useEffect(() => {
    register("reminder_type");
    register("deal");
    register("lp");
    register("member");
    register("min_date");
    register("max_date");
  }, [register]);

  useEffect(() => {
    if (filters) {
      Object.entries(filters).forEach(([key, value]) => {
        _setValue(key, value, { shouldValidate: true });
      });
    }
  }, [filters, register]);

  return (
    <SidePanel title={t("filter_reminders")} onClose={() => onClose && onClose()} width="1/4">
      <form className="flex flex-col w-full" onSubmit={handleSubmit(filter, onInvalid)}>
        <div className="mt-2">
          <Autocomplete
            name="deal"
            placeholder={t("choose_or_type_startup")}
            label={t("startup")}
            options={deals}
            error={errors.deal}
            initialValues={watch("deal") ? deals.find((item) => item.id === watch("deal")) : null}
            onSelect={(deal) => _setValue("deal", deal?.id || null)}
          />
          {hasAccessToModule("members") && (
            <Autocomplete
              name="member"
              placeholder={t("choose_or_type_member")}
              label={t("member")}
              options={members}
              error={errors.member}
              initialValues={
                watch("member") ? members.find((item) => item.id === watch("member")) : null
              }
              onSelect={(member) => _setValue("member", member?.id || null)}
            />
          )}
          <Autocomplete
            name="lp"
            placeholder={t("choose_or_type_lp")}
            label={t("lp")}
            options={lps}
            error={errors.lp}
            initialValues={watch("lp") ? lps.find((item) => item.id === watch("lp")) : null}
            onSelect={(lp) => _setValue("lp", lp?.id || null)}
          />
          <Select
            name="reminder_type"
            reference={register}
            label={t("reminder_type")}
            placeholder={t("select_reminder_type")}
            items={ReminderTypes}
            error={errors.reminder_type}
            onSelect={(selected) =>
              _setValue("reminder_type", selected?.length ? selected[0].id : null)
            }
            initialValues={
              watch("reminder_type")
                ? ReminderTypes.filter((item) => item.id === watch("reminder_type")).map(
                  (item) => ({ id: item.id, value: item.value }),
                )
                : []
            }
          />
        </div>

        <div className={"flex justify-end mt-2 pt-2 border-t border-separator"}>
          <ButtonSecondary
            text={t("clear_filters")}
            onClick={() => resetFilters()}
            type="button"
            marginRight={2}
          />
          <ButtonMain text={t("filter")} type="submit" />
        </div>
      </form>
    </SidePanel>
  );
};
