import axios from "axios/index";
import Config from "../config";

export const InvestmentsService = {
  registerInvestment: async(data) => axios.post(Config.INVESTMENTS_API_URL, data),
  getInvestments: async(query) => axios.post(`${Config.INVESTMENTS_API_URL}search`, query),
  updateInvestment: async(dealId, data) =>
    axios.patch(`${Config.INVESTMENTS_API_URL}${dealId}`, data),
  deleteInvestment: async(dealId, data) => axios.delete(`${Config.INVESTMENTS_API_URL}${dealId}`),
};
