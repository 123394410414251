import ProductUpdates from "assets/json/product-updates.json";
import useUser from "hooks/useUser";
import AccountService from "services/account-service";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { ButtonMain } from "../atoms/ButtonMain";
import { Modal } from "components/ui/molecules/Modal";
import { Loader } from "./Loader";

export function ProductUpdatesModal() {
  const { reloadUserInfo, userLanguage } = useUser();
  const { t } = useTranslation();
  const [productUpdateIndex, setProductUpdateIndex] = useState(0);

  const [loading, setLoading] = useState(false);

  const accomplishProductUpdates = async () => {
    try {
      setLoading(true);
      await AccountService.accomplishProductUpdates();
      await reloadUserInfo();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Modal
        showModal={true}
        showCloseModal={true}
        fixedWidth="w-1/2"
        onClose={accomplishProductUpdates}
      >
        <div className="text-lg font-bold">
          {ProductUpdates[productUpdateIndex].title[userLanguage]}
        </div>
        <div className="text-sm mb-6">
          {ProductUpdates[productUpdateIndex].description[userLanguage]}
        </div>
        <div className="flex justify-center w-full">
          <div className="w-1/2 h-auto">
            <img src={ProductUpdates[productUpdateIndex].img[userLanguage]} />
          </div>
        </div>
        <div className="flex justify-end">
          {ProductUpdates.length > 1 && productUpdateIndex < ProductUpdates.length - 1 && (
            <ButtonMain
              text={t("next")}
              onClick={() => setProductUpdateIndex(productUpdateIndex + 1)}
            />
          )}
          {ProductUpdates.length > 1 && productUpdateIndex > 0 && (
            <ButtonMain
              text={t("previous")}
              onClick={() => setProductUpdateIndex(productUpdateIndex - 1)}
              marginRight={2}
            />
          )}
          {(ProductUpdates.length === 1 ||
            (ProductUpdates.length > 1 && productUpdateIndex === ProductUpdates.length - 1)) && (
            <ButtonMain text={t("finish")} onClick={accomplishProductUpdates} />
          )}
        </div>
      </Modal>
    </>
  );
}
