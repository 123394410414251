import { useTranslation } from "react-i18next";
import { FaCheck, FaTimes } from "react-icons/fa";

export const DealflowInvestmentThesisMatch = ({ criteriaChecks, pipelineManager }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div
        className={`text-xs mt-2 grid ${pipelineManager ? "grid-cols-1" : "grid-cols-3"} gap-1 w-full`}
      >
        {/*<div className={`flex ${!criteriaChecks.hasOwnProperty('minimum_ticket') ? 'text-black' : criteriaChecks.minimum_ticket ? 'text-green' : 'text-red'}`}>
                <span className={'font-semibold mr-1'}>
                    {`${t('minimum_ticket')}`}
                </span>
                <span>
                    {!criteriaChecks.hasOwnProperty('minimum_ticket') ? '-' : criteriaChecks.minimum_ticket ? <FaCheck /> : <FaTimes />}
                </span>
                </div>*/}
        <div
          className={`flex ${!criteriaChecks.hasOwnProperty("maximum_ticket") ? "text-black" : criteriaChecks.maximum_ticket ? "text-green" : "text-red"}`}
        >
          <span className={"font-semibold mr-1"}>{`${t("maximum_ticket")}`}</span>
          <span>
            {!criteriaChecks.hasOwnProperty("maximum_ticket") ? (
              "-"
            ) : criteriaChecks.maximum_ticket ? (
              <FaCheck />
            ) : (
              <FaTimes />
            )}
          </span>
        </div>
        <div
          className={`flex ${!criteriaChecks.hasOwnProperty("minimum_valuation") ? "text-black" : criteriaChecks.minimum_valuation ? "text-green" : "text-red"}`}
        >
          <span className={"font-semibold mr-1"}>{`${t("minimum_valuation")}`}</span>
          <span>
            {!criteriaChecks.hasOwnProperty("minimum_valuation") ? (
              "-"
            ) : criteriaChecks.minimum_valuation ? (
              <FaCheck />
            ) : (
              <FaTimes />
            )}
          </span>
        </div>
        <div
          className={`flex ${!criteriaChecks.hasOwnProperty("maximum_valuation") ? "text-black" : criteriaChecks.maximum_valuation ? "text-green" : "text-red"}`}
        >
          <span className={"font-semibold mr-1"}>{`${t("maximum_valuation")}`}</span>
          <span>
            {!criteriaChecks.hasOwnProperty("maximum_valuation") ? (
              "-"
            ) : criteriaChecks.maximum_valuation ? (
              <FaCheck />
            ) : (
              <FaTimes />
            )}
          </span>
        </div>
        <div
          className={`flex ${!criteriaChecks.hasOwnProperty("sector") ? "text-black" : criteriaChecks.sector ? "text-green" : "text-red"}`}
        >
          <span className={"font-semibold mr-1"}>{`${t("sector")}`}</span>
          <span>
            {!criteriaChecks.hasOwnProperty("sector") ? (
              "-"
            ) : criteriaChecks.sector ? (
              <FaCheck />
            ) : (
              <FaTimes />
            )}
          </span>
        </div>
        <div
          className={`flex ${!criteriaChecks.hasOwnProperty("country") ? "text-black" : criteriaChecks.country ? "text-green" : "text-red"}`}
        >
          <span className={"font-semibold mr-1"}>{`${t("country")}`}</span>
          <span>
            {!criteriaChecks.hasOwnProperty("country") ? (
              "-"
            ) : criteriaChecks.country ? (
              <FaCheck />
            ) : (
              <FaTimes />
            )}
          </span>
        </div>
        <div
          className={`flex ${!criteriaChecks.hasOwnProperty("investment_stage") ? "text-black" : criteriaChecks.investment_stage ? "text-green" : "text-red"}`}
        >
          <span className={"font-semibold mr-1"}>{`${t("investment_stage")}`}</span>
          <span>
            {!criteriaChecks.hasOwnProperty("investment_stage") ? (
              "-"
            ) : criteriaChecks.investment_stage ? (
              <FaCheck />
            ) : (
              <FaTimes />
            )}
          </span>
        </div>
        <div
          className={`flex ${!criteriaChecks.hasOwnProperty("project_stage") ? "text-black" : criteriaChecks.project_stage ? "text-green" : "text-red"}`}
        >
          <span className={"font-semibold mr-1"}>{`${t("project_stage")}`}</span>
          <span>
            {!criteriaChecks.hasOwnProperty("project_stage") ? (
              "-"
            ) : criteriaChecks.project_stage ? (
              <FaCheck />
            ) : (
              <FaTimes />
            )}
          </span>
        </div>
      </div>
    </div>
  );
};
