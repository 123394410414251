import { useEffect, useState } from "react";
import { SidePanel } from "../ui/objects/SidePanel";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useToasts } from "react-toast-notifications";
import { Input } from "../ui/atoms/Input";
import { TagsSelector } from "../ui/atoms/TagsSelector";
import TagsService from "../../services/tags-service";
import Select from "../ui/atoms/Select";
import { Autocomplete } from "../ui/atoms/Autocomplete";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import InvestmentStages from "assets/json/investment-stages.json";
import Sectors from "assets/json/sectors.json";
import Countries from "assets/json/countries.json";
import BusinessModels from "assets/json/business-models.json";
import ProjectStages from "assets/json/project-stages.json";
import FormsService from "services/forms-service";
import useUser from "hooks/useUser";
import EvaluationProcessesManagerService from "services/evaluation-processes-manager-service";
import moment from "moment";
import TeamService from "services/team-service";

export const DealflowFilters = ({
  onFilter,
  onClose,
  showTags = true,
  filters = {},
  section = "pool",
}) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [tags, setTags] = useState([]);
  const [events, setEvents] = useState([]);
  const [forms, setForms] = useState([]);
  const [teammates, setTeammates] = useState([]);

  const [selectedTags, setSelectedTags] = useState([]);

  const { user, checkIfLPhasAccessToSection } = useUser();

  const sectors = Sectors.map((s) => ({ name: t(s.key), id: s._id })).sort((a, b) => (a.name > b.name ? 1 : -1));

  const countries = Countries.map((c) => ({ name: t(c.key), id: c._id })).sort((a, b) => (a.name > b.name ? 1 : -1));

  const investmentStages = InvestmentStages.map((is) => ({ value: t(is.key), id: is._id }));

  const businessModels = BusinessModels.map((bm) => ({ value: t(bm.key), id: bm._id }));

  const statuses = [
    { id: "unassigned", value: t("unassigned") },
    { id: "rejected", value: t("rejected") },
    { id: "failed", value: t("failed") },
    { id: "interesting", value: t("interesting") },
    { id: "committed", value: t("committed") },
    { id: "invested", value: t("invested") },
  ];

  const priorities = [
    { id: "low", value: t("low_priority") },
    { id: "medium", value: t("medium_priority") },
    { id: "high", value: t("high_priority") },
  ];

  const NextSteps = [
    { id: "initial_meeting", value: t("initial_meeting") },
    { id: "contact_by_email", value: t("contact_by_email") },
    { id: "intro", value: t("intro") },
  ];

  const projectStages = ProjectStages.map((ps) => ({ value: t(ps.key), id: ps._id }));

  const { register, handleSubmit, trigger, errors, setValue, reset, watch } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        type: Yup.string().nullable(),
        sector: Yup.string().nullable(),
        country_incorporation: Yup.string().nullable(),
        business_model: Yup.string().nullable(),
        status: Yup.string().nullable(),
        priority: Yup.string().nullable(),
        next_step: Yup.string().nullable(),
        project_stage: Yup.string().nullable(),
        year: Yup.string().nullable(),
        investment_stage: Yup.string().nullable(),
        events: Yup.string().nullable(),
        owners: Yup.array(),
      }),
      { abortEarly: false },
    ),
    criteriaMode: "all",
    reValidateMode: "all",
    mode: "onChange",
  });

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, config);
    trigger(name);
  };

  const filter = ({ clearFilters, ...filterValues }) => {
    let _filters = {};

    if (!clearFilters) {
      _filters = Object.keys(filterValues).reduce((acc, key) => {
        if (filterValues[key]) {
          acc[key] = filterValues[key];
        }
        return acc;
      }, {});

      if (selectedTags.length) {
        _filters.tags = selectedTags;
      }
    }

    onFilter && onFilter(_filters);
    onClose && onClose();
  };

  const resetFilters = () => {
    reset();
    setSelectedTags([]);
    filter({ clearFilters: true });
  };

  const onInvalid = () => {
    addToast(t("form_field_error"), { appearance: "error", autoDismiss: true });
  };

  const getEvents = () => {
    EvaluationProcessesManagerService.getEvaluationProcesses({
      rowsPerPage: 99999999,
    })
      .then((response) => {
        setEvents(
          response.data.data?.events?.map(
            (e) =>
              ({
                id: e._id,
                value: e.name,
              }) || [],
          ),
        );
      })
      .catch(() => {
        addToast(t("error_occurred_retrieving_events"), {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const getForms = () => {
    FormsService.getQuestionnairesForms({
      rowsPerPage: 99999999,
    })
      .then((response) => {
        setForms(
          response.data.data?.forms?.map(
            (e) =>
              ({
                id: e._id,
                value: e.name,
              }) || [],
          ),
        );
      })
      .catch(() => {
        addToast(t("error_occurred_retrieving_forms"), {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const getTags = () => {
    TagsService.getTags("dealflow")
      .then((response) => {
        setTags(response.data.data);
      })
      .catch(() => {
        addToast(t("error_occurred_retrieving_tags"), {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const getTeammates = async() => {
    try {
      const result = await TeamService.getTeammates();
      setTeammates(result.data.data);
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t("error_occurred"),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
    }
  };

  useEffect(() => {
    register("type");
    register("sector");
    register("country_incorporation");
    register("business_model");
    register("status");
    register("priority");
    register("next_step");
    register("project_stage");
    register("year");
    register("investment_stage");
    register("events");
    register("forms");
    register("owners");
  }, [register]);

  useEffect(() => {
    if (filters) {
      Object.entries(filters).forEach(([key, value]) => {
        if (key === "tags") {
          setSelectedTags(value);
        } else {
          _setValue(key, value, { shouldValidate: true });
        }
      });
    }
  }, [filters, register]);

  useEffect(() => {
    if (showTags) {
      getTags();
    }
    if (section === "pool") {
      getEvents();
      getTeammates();
    }
    getForms();
  }, []);

  const startYear = 2019;
  const currentYear = moment().year();
  const YEARS = [];

  for (let year = startYear; year <= currentYear; year++) {
    YEARS.push({ id: year.toString(), value: year });
  }

  return (
    <SidePanel title={t("filters")} onClose={() => onClose && onClose()} width="1/4">
      <form className="flex flex-col w-full" onSubmit={handleSubmit(filter, onInvalid)}>
        <div className="mt-2">
          <Select
            name="year"
            reference={register}
            label={t("year")}
            placeholder={t("year")}
            items={YEARS}
            error={errors.year}
            onSelect={(selected) => _setValue("year", selected?.length ? selected[0].id : null)}
            initialValues={
              watch("year")
                ? YEARS.filter((item) => item.id === watch("year")).map((item) => ({
                  id: item.id,
                  value: item.value,
                }))
                : []
            }
          />
          <Autocomplete
            name="sector"
            placeholder={t("choose_or_type_sector")}
            label={t("sector")}
            options={sectors}
            error={errors.sector}
            initialValues={
              watch("sector") ? sectors.find((item) => item.id === watch("sector")) : null
            }
            onSelect={(sector) => _setValue("sector", sector?.id || null)}
          />

          <Autocomplete
            name="country_incorporation"
            placeholder={t("choose_or_type_country_incorporation")}
            options={countries}
            label={t("country_incorporation")}
            error={errors.country_incorporation}
            onSelect={(country) => _setValue("country_incorporation", country ? country.id : null)}
            initialValues={
              watch("country_incorporation")
                ? countries.find((item) => item.id === watch("country_incorporation"))
                : null
            }
          />
          {section === "pool" && (
            <Select
              label={t("owners")}
              multiSelect={true}
              placeholder={t("select_owners")}
              initialValues={
                watch("owners")
                  ? teammates
                    .filter((teammate) =>
                      watch("owners").includes(teammate.user ? teammate.user._id : null),
                    )
                    .map((e) => ({
                      id: e.user ? e.user._id : `${e._id}_not_registered`,
                      value:
                          `${e.user ? e.user.name : e.name} ${e.user ? e.user.last_name : e.last_name || ""} ${!e.user ? "(Not registered)" : ""}`.trim(),
                    }))
                  : []
              }
              onSelect={(owners) =>
                _setValue("owners", owners ? owners.map((owner) => owner.id) : [])
              }
              items={
                teammates.length
                  ? teammates
                    .filter((teammate) => teammate.user)
                    .map((e) => ({
                      id: e.user ? e.user._id : `${e._id}_not_registered`,
                      value:
                          `${e.user ? e.user.name : e.name} ${e.user ? e.user.last_name : e.last_name || ""} ${
                            !e.user ? "(Not registered)" : ""
                          }`.trim(),
                      disabled: !e.user,
                      name: e.user ? e.user.name : e.name,
                      email: e.user ? e.user.email : e.email,
                      last_name: e.user ? e.user.last_name : e.last_name,
                      user: e.user ? e.user._id : null,
                      teammate_id: e._id,
                    }))
                  : []
              }
            />
          )}
          <Select
            name="business_model"
            reference={register}
            label={t("business_model")}
            placeholder={t("search_business_model")}
            items={businessModels}
            error={errors.business_model}
            onSelect={(selected) =>
              _setValue("business_model", selected?.length ? selected[0].id : null)
            }
            initialValues={
              watch("business_model")
                ? businessModels
                  .filter((item) => item.id === watch("business_model"))
                  .map((item) => ({ id: item.id, value: item.value }))
                : []
            }
          />

          <Select
            name="project_stage"
            reference={register}
            label={t("project_stage")}
            placeholder={t("search_project_stage")}
            items={projectStages}
            error={errors.project_stage}
            onSelect={(selected) =>
              _setValue("project_stage", selected?.length ? selected[0].id : null)
            }
            initialValues={
              watch("project_stage")
                ? projectStages
                  .filter((item) => item.id === watch("project_stage"))
                  .map((item) => ({ id: item.id, value: item.value }))
                : []
            }
          />

          <Select
            name="investment_stage"
            reference={register}
            label={t("investment_stage")}
            items={investmentStages}
            error={errors.investment_stage}
            onSelect={(selected) =>
              _setValue("investment_stage", selected?.length ? selected[0].id : null)
            }
            initialValues={
              watch("investment_stage")
                ? investmentStages
                  .filter((item) => item.id === watch("investment_stage"))
                  .map((item) => ({ id: item.id, value: item.value }))
                : []
            }
          />
          {section === "pool" &&
            (!user.lp || (user.lp && checkIfLPhasAccessToSection("evaluations"))) && (
              <Select
                name="events"
                reference={register}
                label={t("evaluation_process")}
                placeholder={t("evaluation_process")}
                items={events}
                error={errors.events}
                onSelect={(selected) =>
                  _setValue("events", selected.length ? selected[0].id : null)
                }
                initialValues={
                  watch("events")
                    ? events
                      .filter((item) => item.id === watch("events"))
                      .map((item) => ({ id: item.id, value: item.value }))
                    : []
                }
              />
          )}
          {(!user.lp || (user.lp && checkIfLPhasAccessToSection("questionnaires"))) && (
            <Select
              name="forms"
              reference={register}
              label={t("forms")}
              placeholder={t("forms")}
              items={forms}
              error={errors.forms}
              onSelect={(selected) => _setValue("forms", selected.length ? selected[0].id : null)}
              initialValues={
                watch("forms")
                  ? forms
                    .filter((item) => item.id === watch("forms"))
                    .map((item) => ({ id: item.id, value: item.value }))
                  : []
              }
            />
          )}
          {section === "pool" && (
            <>
              <Select
                name="status"
                reference={register}
                label={t("status")}
                placeholder={t("status")}
                items={statuses}
                error={errors.status}
                onSelect={(selected) =>
                  _setValue("status", selected?.length ? selected[0].id : null)
                }
                initialValues={
                  watch("status")
                    ? statuses
                      .filter((item) => item.id === watch("status"))
                      .map((item) => ({ id: item.id, value: item.value }))
                    : []
                }
              />

              <Select
                name="priority"
                reference={register}
                label={t("priority")}
                placeholder={t("priority")}
                items={priorities}
                error={errors.priority}
                onSelect={(selected) =>
                  _setValue("priority", selected?.length ? selected[0].id : null)
                }
                initialValues={
                  watch("priority")
                    ? priorities
                      .filter((item) => item.id === watch("priority"))
                      .map((item) => ({ id: item.id, value: item.value }))
                    : []
                }
              />

              <Select
                name="next_step"
                reference={register}
                label={t("next_step")}
                placeholder={t("next_step")}
                items={NextSteps}
                error={errors.next_step}
                onSelect={(selected) =>
                  _setValue("next_step", selected?.length ? selected[0].id : null)
                }
                initialValues={
                  watch("next_step")
                    ? NextSteps.filter((item) => item.id === watch("next_step")).map((item) => ({
                      id: item.id,
                      value: item.value,
                    }))
                    : []
                }
              />
              {showTags && (
                <div className="mt-2 pt-4 border-t border-gray-lines">
                  <label className="block mb-1 text-left text-xs font-medium text-black">
                    {t("tags")}
                  </label>
                  <TagsSelector
                    tags={tags}
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                  />
                </div>
              )}
            </>
          )}
        </div>

        <div className={"flex justify-end mt-2 pt-2 border-t border-separator"}>
          <ButtonSecondary
            text={t("clear_filters")}
            onClick={() => resetFilters()}
            type="button"
            marginRight={2}
          />
          <ButtonMain text={t("filter")} type="submit" />
        </div>
      </form>
    </SidePanel>
  );
};
