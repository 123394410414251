import { RoutesLinks } from "components/routes-links";
import Config from "config";

import { FaShareAlt, FaPen, FaRocket, FaSuitcase } from "react-icons/fa";
import { RxDashboard } from "react-icons/rx";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { RiQuestionAnswerLine } from "react-icons/ri";
import { TbReportAnalytics } from "react-icons/tb";
import { IoPeople } from "react-icons/io5";
import { BsKanban } from "react-icons/bs";

const SidebarMenuItems = [
  {
    section: "dashboard",
    title: "dashboard",
    url: RoutesLinks.DASHBOARD_LINK,
    icon: <RxDashboard className={"w-6 h-5"} />,
    iconHover: <RxDashboard className={"h-5 text-main w-6"} />,
  },
  {
    section: "evaluations",
    title: "evaluations",
    url: `${RoutesLinks.EVALUATIONS_LINK}evaluation-processes`,
    icon: <FaPen className={"w-6 h-5"} />,
    iconHover: <FaPen className={"h-5 text-main w-6"} />,
  },
  {
    section: "dealflow",
    title: "dealflow",
    url: RoutesLinks.DEALFLOW_LINK,
    icon: <FaRocket className="w-6 h-5" />,
    iconHover: <FaRocket className={"text-main w-6 h-5"} />,
  },
  {
    section: "pipeline_manager",
    title: "pipeline_manager",
    url: `${RoutesLinks.PIPELINE_MANAGER_LINK}boards`,
    icon: <BsKanban className="w-6 h-5" />,
    iconHover: <BsKanban className={"w-6 h-5 text-main"} />,
  },
  {
    section: "portfolio",
    title: "portfolio",
    url: RoutesLinks.PORFOLIO,
    icon: <FaSuitcase className={"w-6 h-5"} />,
    iconHover: <FaSuitcase className={"h-5 text-main w-6"} />,
  },
  {
    section: "members",
    title: "members_sidebar",
    url: RoutesLinks.MEMBERS_LINK,
    icon: <IoPeople className="w-6 h-5" />,
    iconHover: <IoPeople className={"h-5 text-main w-6"} />,
  },
  {
    section: "irm",
    title: "investors_relations_manager_sidebar",
    url: RoutesLinks.CONTACTS_LINK,
    icon: <FaShareAlt className="w-6 h-5" />,
    iconHover: <FaShareAlt className={"h-5 text-main w-6"} />,
  },
  {
    section: "questionnaires_engine",
    title: "questionnaires",
    url: RoutesLinks.QE_FORMS_LINK,
    icon: <RiQuestionAnswerLine className="w-6 h-5" />,
    iconHover: <RiQuestionAnswerLine className={"w-6 h-5 text-main"} />,
  },
  {
    section: "questionnaires_engine",
    title: "questionnaires",
    url: RoutesLinks.QE_QUESTIONS_LINK,
    icon: <RiQuestionAnswerLine className="w-6 h-5" />,
    iconHover: <RiQuestionAnswerLine className={"w-6 h-5 text-main"} />,
  },
  {
    section: "web",
    title: "web",
    url: RoutesLinks.WEB_LINK,
    icon: <RiQuestionAnswerLine className="w-6 h-5" />,
    iconHover: <RiQuestionAnswerLine className={"w-6 h-5 text-main"} />,
  },
  {
    section: "reports",
    title: "reports",
    url: RoutesLinks.REPORTS_LINK,
    icon: <TbReportAnalytics className="w-6 h-6" />,
    iconHover: <TbReportAnalytics className={"w-6 h-6 text-main"} />,
  },
  {
    section: "documentation",
    title: "documentation",
    url: `${RoutesLinks.DOCUMENTATION_LINK}documents`,
    icon: <HiOutlineDocumentDuplicate className="w-6 h-6" />,
    iconHover: <HiOutlineDocumentDuplicate className={"text-main h-6 w-6"} />,
  },
];

export default SidebarMenuItems;
