import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import { RoutesLinks } from "../routes-links";

const PrivateRoute = () => {
  const authInfo = useSelector((state) => state.auth);
  const checked = useSelector((state) => state.checks && state.checks.checked);
  const { token } = authInfo;

  if (!token || !checked) {
    return <Navigate to={RoutesLinks.LOGIN_LINK} replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
