import { FaFile, FaLink, FaRocket } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { TbCircleOff } from "react-icons/tb";
import { useEffect, useState } from "react";

import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { Input } from "components/ui/atoms/Input";
import { SidePanel } from "components/ui/objects/SidePanel";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Switch } from "components/ui/atoms/Switch";
import useUser from "hooks/useUser";

export const FolderSection = ({ folder, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const [lps, setLPs] = useState(folder ? folder.lps_shared : false);
  const [members, setMembers] = useState(folder ? folder.members_shared : false);

  const { hasAccessToModule } = useUser();
  const FolderSchema = Yup.object().shape({
    name: Yup.string().required(t("required_field")),
  });

  const { register, errors, setValue, trigger, getValues, reset, handleSubmit } = useForm({
    resolver: yupResolver(FolderSchema),
    mode: "all",
  });

  useEffect(() => {
    if (folder) {
      setValue("name", folder.name);
      trigger("name");
    }
  }, [folder]);

  return (
    <SidePanel width={"1/4"} onClose={onClose} title={t(folder ? "edit_folder" : "create_folder")}>
      <form className="w-full">
        <div>
          <Input
            reference={register}
            id="name"
            type="text"
            name="name"
            required={true}
            label={t("name")}
            placeholder={t("name")}
            error={errors.name}
          />
        </div>
        <div className="text-sm mb-4">{t("would_you_like_to_share_folder")}</div>
        <div className="mb-4">
          <Switch
            checked={lps}
            onChange={async(checked) => {
              setLPs(checked);
            }}
            text={t("lps")}
          />
        </div>
        {hasAccessToModule("members") && (
          <div className="mb-4">
            <Switch
              checked={members}
              onChange={async(checked) => {
                setMembers(checked);
              }}
              text={t("members")}
            />
          </div>
        )}
        <div className={"flex justify-end mt-4 border-t pt-2 border-separator"}>
          <ButtonMain
            text={t(folder ? "edit_folder" : "create_folder")}
            type="button"
            onClick={async() => {
              if (await trigger()) { onSubmit({ ...getValues(), lps_shared: lps, members_shared: members }); }
            }}
          />
        </div>
      </form>
    </SidePanel>
  );
};
