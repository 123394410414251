import { useTranslation } from "react-i18next";
import { Modal } from "components/ui/molecules/Modal";

import { useEffect, useState } from "react";
import InputFile from "components/ui/atoms/input-files";
import { useToasts } from "react-toast-notifications";
import QuestionsSample from "assets/sample/questions_sample.xlsx";
import FormUtils from "../../../utils/form-utils";
import Utils from "../../../utils/utils";
import { ButtonMain } from "../atoms/ButtonMain";
import { ButtonSecondary } from "../atoms/ButtonSecondary";

export function ImportQuestionsModal({ onSubmit, showModal, handleCloseModal }) {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [file, setFile] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const selectFile = async (file) => {
    setSelectedFile(file);
    if (!file) {
      return;
    }

    const csv = await FormUtils.readFile(file);
    const json = Utils.csvJSON(csv);

    if (!json || !json.length) {
      addToast(t("invalid_csv_format"), {
        appearance: "error",
        autoDismiss: true,
      });

      return;
    }

    setFile(json);
  };

  const submitImport = () => {
    onSubmit && onSubmit(selectedFile);
  };

  useEffect(() => {
    setFile(null);
  }, [showModal]);

  const getQuestionTypes = () => {
    const questionTypes = file.map((question) => {
      return question.type;
    });
    return [...new Set(questionTypes)];
  };

  const getQuestionsByType = (questionType) => {
    return file.filter((question) => question.type === questionType).length;
  };

  const getValidQuestions = () => {
    let validQuestions = 0;
    file.forEach((question) => {
      if (
        (question.title_es || question.title_en) &&
        (!["select", "multiselect", "likert_scale"].includes(question.type) ||
          (["select", "multiselect", "likert_scale"].includes(question.type) &&
            (question.options_es || question.options_en)))
      ) {
        validQuestions += 1;
      }
    });

    return validQuestions;
  };

  const getValidQuestionsByQuestionType = (questionType) => {
    let validQuestions = 0;
    file
      .filter((q) => q.type === questionType)
      .forEach((question) => {
        if (
          (question.title_es || question.title_en) &&
          (!["select", "multiselect", "likert_scale"].includes(question.type) ||
            (["select", "multiselect", "likert_scale"].includes(question.type) &&
              (question.options_es || question.options_en)))
        ) {
          validQuestions += 1;
        }
      });

    return validQuestions;
  };

  return (
    <Modal showModal={showModal} paddingBottom="2" paddingTop="6">
      <h2 className="font-semibold text-xl mb-2">{t("import_questions")}</h2>

      <>
        <p className="font-medium text-sm text-gray-dark">{t("import_questions_explanation")}</p>
        <p className="font-medium text-sm text-gray-dark mb-3">
          <a download="sample_questions.xlsx" href={QuestionsSample}>
            {t("download_csv_example")}.
          </a>
        </p>

        {!file && <InputFile accept={".csv"} setSelectedFile={(file) => selectFile(file)} />}

        {file && (
          <div>
            <div>
              <span className="font-semibold text-sm">{`${t("questions_to_import")}: `}</span>
              {`${file.length} (${getValidQuestions()} ${t("valid_questions")})`}
            </div>
            <div>
              <ol>
                {getQuestionTypes().map((questionType, index) => {
                  return (
                    <li key={index}>
                      {" "}
                      <span className="font-semibold text-sm">{`${t(questionType)}: `}</span>
                      {`${getQuestionsByType(questionType)} (${getValidQuestionsByQuestionType(questionType)} ${t(
                        "valid_questions",
                      )})`}
                    </li>
                  );
                })}
              </ol>
            </div>
          </div>
        )}

        <div className="flex justify-between">
          <ButtonSecondary text={t("cancel")} onClick={handleCloseModal} marginRight="10" />
          <div className="flex flex-row">
            {file && (
              <ButtonSecondary text={t("restart")} marginRight="3" onClick={() => setFile(null)} />
            )}
            <ButtonMain
              onClick={() => submitImport()}
              disabled={!file}
              text={t("import_questions")}
            />
          </div>
        </div>
      </>
    </Modal>
  );
}
