import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Arrow, useLayer } from "react-laag";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RoutesLinks } from "components/routes-links";
import { FaRobot } from "react-icons/fa";
import { BiSupport } from "react-icons/bi";
import { BsBook, BsStar } from "react-icons/bs";
import { IoHelpOutline } from "react-icons/io5";
import { VscFeedback } from "react-icons/vsc";
import { MdFeedback } from "react-icons/md";

const getMenuItems = (platform) => {
  if (!platform.cobranding && !platform.white_label) {
    return [
      { title: "help_menu_resources", icon: <BsBook />, url: RoutesLinks.RESOURCES_LINK },
      { title: "chat_with_us", icon: <FaRobot />, url: RoutesLinks.CHAT_LINK },
      { title: "give_us_feedback", icon: <MdFeedback />, url: RoutesLinks.FEEDBACK_LINK },
      { title: "support", icon: <BiSupport />, url: RoutesLinks.SUPPORT_LINK },
      { title: "updates", icon: <BsStar />, method: () => window.open("https://headwayapp.co/kiota-changelog", "_blank") },
    ];
  } else {
    return [
      { title: "help_menu_resources", icon: <BsBook />, url: RoutesLinks.RESOURCES_LINK },
      { title: "give_us_feedback", icon: <VscFeedback />, url: RoutesLinks.FEEDBACK_LINK },
      { title: "support", icon: <BiSupport />, url: RoutesLinks.SUPPORT_LINK },
      { title: "updates", icon: <BsStar />, method: () => window.open("https://headwayapp.co/kiota-changelog", "_blank") },
    ];
  }
};

export function HelpMenu() {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
  const platform = useSelector((state) => state.app.platform);

  const handleRedirect = useCallback(
    (url) => {
      setOpen(false);
      navigate(url);
    },
    [history],
  );

  const handleMethod = useCallback((method) => {
    setOpen(false);
    method();
  }, []);

  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,
    onOutsideClick: () => setOpen(false),
    onDisappear: () => setOpen(false),
    overflowContainer: true,
    auto: true,
    placement: "bottom-center",
    triggerOffset: 12,
    containerOffset: 16,
    arrowOffset: 16,
  });

  const menuItems = getMenuItems(platform);

  return (
    <>
      <button
        id="help-button"
        {...triggerProps}
        onClick={() => setOpen(!isOpen)}
        style={{ border: "0px" }} className="inline bg-gray-100 hover:bg-gray-200 border focus:outline-none  hover:shadow-inner w-8 h-8 rounded-full ml-2"
      >
        <IoHelpOutline className="text-text-buttons-secondary w-7 m-auto" />
      </button>

      {isOpen &&
        renderLayer(
          <ul
            {...layerProps}
            className="mt-2 px-2 py-2 z-30 shadow-hover border bg-white rounded-2xl border-border-buttons-secondary"
          >
            {menuItems.map((i, index) => (
              <li
                key={index}
                onClick={() => (i.method ? handleMethod(i.method) : handleRedirect(i.url))}
                className="px-2 py-2 flex text-main items-center cursor-pointer text-sm text-gray"
              >
                <span className="mr-2 text-main">{i.icon}</span>
                <span className="hover:font-bold">{t(i.title)}</span>
              </li>
            ))}
            <Arrow {...arrowProps} borderColor="#61D8BD" borderWidth={1} className="w-5" />
          </ul>,
        )}
    </>
  );
}
