/* eslint-disable default-case */
import produce from "immer";

import { OnboardingActionsTypes } from "./onboarding-actions-types";
import { UserActionsTypes } from "../user/user-actions-types";

const { HANDLE_ONBOARDING_TASKS_PANNEL } = OnboardingActionsTypes;
const { RESET: LOGOUT } = UserActionsTypes;

const defaultState = {
  show: false,
};

const OnboardingReducer = (state = defaultState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case HANDLE_ONBOARDING_TASKS_PANNEL:
        draft.show = action.payload.value;
        break;
      case LOGOUT:
        return defaultState;
    }
  });

export default OnboardingReducer;
