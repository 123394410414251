import { SidePanel } from "components/ui/objects/SidePanel";
import { useOutletContext } from "react-router-dom";
import ApplicationActions from "./application-actions";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import Select from "components/ui/atoms/Select";
import { useEffect, useImperativeHandle, useState } from "react";
import { Alert } from "components/ui/molecules/Alert";
import { JudgeStartupEvaluationView } from "../../evaluation/JudgeStartupEvaluationView";
import { JudgeStartupEvaluationForm } from "../../evaluation/JudgeStartupEvaluationForm";
import EvaluationProcessesManagerService from "services/evaluation-processes-manager-service";
import { useToasts } from "react-toast-notifications";
import ScoreCardUtils from "utils/score-card-utils";
import { useTranslation } from "react-i18next";
import useUser from "hooks/useUser";

const ApplicationEvaluationsView = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const { user } = useUser();

  const [evaluation, setEvaluation] = useState(null);

  const { deal, event, setLoading, startupId, eventId, ref } = useOutletContext();
  const [selectedJudges, setSelectedJudges] = useState([]);
  const [showEvaluationActions, setShowEvaluationActions] = useState(false);
  const [judges, setJudges] = useState([]);
  const [showEvaluateForm, setShowEvaluateForm] = useState(false);

  const isEvaluationOwner = () => {
    const judge = judges.find((j) => selectedJudges.map((selectedJudg) => selectedJudg.id).includes(j._id));
    return judge.user && judge.user === user._id;
  };

  const getJudgesFromEvaluationProcess = () => {
    setLoading(true);
    EvaluationProcessesManagerService.getJudgesFromEvaluationProcess(eventId, {
      rowsPerPage: 9999,
    })
      .then((response) => {
        setJudges(response.data.data.judges);
      })
      .catch((error) => {
        console.error(error);
        addToast(t("error_occurred"), {
          appearance: "error",
          autoDismiss: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getEvaluationForDeal = () => {
    setLoading(true);
    const params = {
      judges: selectedJudges.map((judge) => judge.id),
    };

    EvaluationProcessesManagerService.getMultipleEvaluationsForDeal(eventId, startupId, params)
      .then((result) => {
        if (result.data.data) {
          setShowEvaluateForm(false);
        }
        if (result.data.data[0]) {
          setEvaluation({
            ...result.data.data[0],
            ...ScoreCardUtils.mergeEvaluations(result.data.data),
          });
        }
      })
      .catch(() => {
        addToast(t("error_occurred_retrieving_evaluation"), {
          appearance: "error",
          autoDismiss: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useImperativeHandle(ref, () => ({
    showEvaluationActions: () => setShowEvaluationActions(true),
  }));

  useEffect(() => {
    getJudgesFromEvaluationProcess();
  }, []);

  useEffect(() => {
    if (selectedJudges.length > 0) {
      getEvaluationForDeal();
    }
  }, [selectedJudges]);

  return (
        <>
        {(deal && deal.discarded ? (
          <div className="mt-10">
            <NoDataInfo
              title={t("startup_discarded")}
              textOne={t("startup_discarded_description")}
            />
          </div>
        ) : !event.score_card ? (
          <div className="mt-10">
            <NoDataInfo
              title={t("scorecard_not_found")}
              textOne={t("scorecard_not_found_description")}
            />
          </div>
        ) : (
          <>
            {judges.length !== 0 && (
              <div className="mt-6">
                <Select
                  multiSelect
                  label={<span className="inline-block ml-3">{t("select_judges")}</span>}
                  placeholder={selectedJudges[0] ? selectedJudges.map((s) => s.value).join(", ") : t("select_judges")}
                  onSelect={(value) => {
                    if (!value.length) {
                      setEvaluation(null);
                    }
                    setSelectedJudges(value);
                  }}
                  initialValues={selectedJudges.length > 0 ? selectedJudges : []}
                  items={judges
                    .filter((judge) => judge.startups.includes(deal._id))
                    .map((j) => ({
                      id: j._id,
                      value: `${j.judge} ${!j.user ? `(${t("external")})` : ""}`.trim(),
                      enabled: j.enabled,
                      user: j.user,
                      startups: j.startups,
                    }))}
                />
              </div>
            )}
            {/* {selectedJudges[0] && !selectedJudge.startups.includes(deal._id) && (
              <div className="mt-10">
                <NoDataInfo title={t("startup_not_assigned_to_this_judge")} />
              </div>
            )} */}

            {(!selectedJudges[0] ||
              (selectedJudges[0] &&
                !isEvaluationOwner() &&
                !evaluation)) && (
              <div className="mt-10">
                <NoDataInfo
                  title={t(
                    judges.length === 0
                      ? "no_judges_assigned_to_event"
                      : !selectedJudges[0]
                          ? "select_judge_to_see_evaluation"
                          : "evaluation_not_submitted",
                  )}
                  textOne={t(
                    judges.length === 0
                      ? "no_judges_assigned_to_event_description"
                      : !selectedJudges[0]
                          ? "select_judge_to_see_evaluation_description"
                          : "evaluation_not_submitted_description",
                  )}
                />
              </div>
            )}

            {selectedJudges[0] &&
              evaluation &&
              !showEvaluateForm && (
                <>
                  {!selectedJudges.some((judge) => judge.enabled) && (
                    <Alert style={"info"} text={t("judge_currently_disabled")} />
                  )}
                  <div className="text-lg flex gap-1">
                    <span>{t("average_generated_from")}</span>
                    <h6 className="font-semibold">{`${selectedJudges.length} ${t("judges")}`}</h6>
                  </div>
                  <JudgeStartupEvaluationView evaluation={evaluation} />
                  {/* {selectedJudge.enabled &&
                    selectedJudge.user &&
                    selectedJudge.user === user._id && (
                      <div className="flex justify-end mt-4 mb-6">
                        <ButtonMain
                          onClick={() => setShowEvaluateForm(true)}
                          text={t("update_evaluation")}
                          width={"full md:w-1/2"}
                        />
                      </div>
                  )} */}
                </>
            )}
            {selectedJudges[0] && isEvaluationOwner() &&
              (!evaluation || showEvaluateForm) && (
                <>
                  <JudgeStartupEvaluationForm
                    scoreCard={event.score_card}
                    eventId={event._id}
                    judgeId={selectedJudges[0].id}
                    dealId={startupId}
                    onCancel={() => setShowEvaluateForm(false)}
                    showCancelButton={!!evaluation}
                    isUpdating={evaluation && showEvaluateForm}
                    afterSave={() => getEvaluationForDeal()}
                    evaluationToUpdate={evaluation}
                    judgeEnabled={selectedJudges.every(judge => judge.enabled)}
                  />
                </>
            )}
            {evaluation && showEvaluationActions && (
              <SidePanel width={"1/4"} title={t("actions")} onClose={() => setShowEvaluationActions(false)}>
                <ApplicationActions event={event} deal={deal} selectedJudges={selectedJudges} evaluation={evaluation} setLoading={setLoading} handleClose={() => setShowEvaluationActions(false)} />
              </SidePanel>
            )}
          </>
        ))}
        </>
  );
};

export default ApplicationEvaluationsView;
