import { Trans, useTranslation } from "react-i18next";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import Dealflow from "assets/images/Dealflow.png";
import Analysis from "assets/images/Analysis.png";
import Evaluation from "assets/images/Evaluation.png";
import Tracking from "assets/images/Tracking.png";
import { useSelector } from "react-redux";

const FirstStep = ({ nextStep }) => {
  const { t } = useTranslation();
  const platform = useSelector((state) => state.app.platform);

  return (
    <div className="bg-bgNewOnboardingOne bg-cover bg-no-repeat bg-center min-w-screen min-h-screen relative flex justify-center align-center">
      <div className="flex items-center text-center w-1/3 p-8">
        <div className="flex flex-col justify-center items-center bg-white p-6 rounded-lg w-full">
          <h1 className={"text-secondary mb-4"}>
            {
              <Trans
                i18nKey="onboarding_first_step_title"
                values={{ platform_name: platform.name }}
              />
            }
          </h1>
          <div className={"text-base text-main mb-6 font-semibold border-b border-secondary pb-4"}>
            {t("onboarding_first_step_subtitle")}
          </div>
          <div className="px-10 w-full">
            <div className="flex flex-col sm:flex-row mb-6 w-full">
              <div className="flex items-start sm:items-center relative w-auto mr-6">
                <img src={Dealflow} alt="Test" className="w-10" />
              </div>
              <div className="text-left w-full">
                <p className="text-xs sm:text-sm font-bold">
                  {t("onboarding_first_step_first_item_title")}
                </p>
                <p className="text-xxs sm:text-xs text-gray-dark">
                  {t("onboarding_first_step_first_item_subtitle")}
                </p>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row mb-6 w-full">
              <div className="flex items-start sm:items-center relative w-auto mr-6">
                <img src={Analysis} alt="Test" className="w-10" />
              </div>
              <div className="text-left w-full">
                <p className="text-xs sm:text-sm font-bold">
                  {t("onboarding_first_step_second_item_title")}
                </p>
                <p className="text-xxs sm:text-xs text-gray-dark">
                  {t("onboarding_first_step_second_item_subtitle")}
                </p>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row mb-6 w-full">
              <div className="flex items-start sm:items-center relative w-auto mr-6">
                <img src={Evaluation} alt="Test" className="w-10" />
              </div>
              <div className="text-left w-full">
                <p className="text-xs sm:text-sm font-bold">
                  {t("onboarding_first_step_third_item_title")}
                </p>
                <p className="text-xxs sm:text-xs text-gray-dark">
                  {t("onboarding_first_step_third_item_subtitle")}
                </p>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row mb-6 sm:mb-10 w-full">
              <div className="flex items-start sm:items-center relative w-auto mr-6">
                <img src={Tracking} alt="Test" className="w-10" />
              </div>
              <div className="text-left w-full">
                <p className="text-xs sm:text-sm font-bold">
                  {t("onboarding_first_step_fourth_item_title")}
                </p>
                <p className="text-xxs sm:text-xs text-gray-dark">
                  {t("onboarding_first_step_fourth_item_subtitle")}
                </p>
              </div>
            </div>
          </div>
          <div className={"w-full flex justify-between"}>
            <div className="flex items-center justify-center mr-3 font-bold">1/2</div>
            <ButtonMain onClick={nextStep} type="button" text={t("next")} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstStep;
