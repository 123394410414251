import { useTranslation } from "react-i18next";
import TranslateUtils from "utils/translate-utils";
import { NoDataInfo } from "../atoms/NoDataInfo";
import { Card } from "components/ui/atoms/Card";
import { Alert } from "components/ui/molecules/Alert";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import { QuestionsUtils } from "utils/questions-utils";
import DocumentsService from "services/documents-service";

/**
 * @param applicationQuestions {{question_id: string, answer: string}[]}
 * @param lang
 * @returns {JSX.Element}
 * @constructor
 */
export const ApplicationQuestionnaireView = ({
  applicationQuestions = [],
  lang,
  registrationDate,
  setLoading,
  shared = false,
  scoreCardId = null,
}) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const downloadDocument = async(documentId) => {
    try {
      setLoading(true);
      let result = null;
      if (!scoreCardId) {
        if (shared) {
          result = await DocumentsService.getSharedDocumentForForm(documentId);
        } else {
          result = await DocumentsService.getDocumentForForm(documentId);
        }
      } else {
        result = await DocumentsService.getDocumentForScoreCard(documentId);
      }
      if (result?.data?.data?.url) {
        window.open(result.data.data.url, "_blank");
      }
    } catch (error) {
      addToast(t(error?.response?.data?.msg_key || "error_occurred_downloading_document"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {![undefined, null].includes(registrationDate) && (
        <Alert
          style={"info"}
          text={`${t("application_registered_on")} ${moment(registrationDate).format("YYYY-MM-DD HH:mm")}`}
        />
      )}

      {applicationQuestions.length === 0 && (
        <div className="mt-6">
          <NoDataInfo title={t("no_application")} textOne={t("no_application_description")} />
        </div>
      )}

      {applicationQuestions
        .filter((q) => q.question.type !== "separator")
        .map((question, index) => (
          <div className="py-2 last:border-none" key={index}>
            <Card>
              <div
                className={`font-bold mb-1 ${question.question.type === "questions_group" && "border-b border-gray-lines mb-4"}`}
              >
                <span className={"text-main"}>{index + 1}</span>
                &nbsp;-&nbsp;
                {TranslateUtils.getTextByLang(lang, question.question.title)}
              </div>
              {question.question.type === "questions_group" && (
                <>
                  {question.question.questionsGroup.map((q, index) => (
                    <div key={index} className="mb-2">
                      <div className="font-bold">
                        {`- ${TranslateUtils.getTextByLang(lang, q.title)}`}
                      </div>
                      <div className="break-words max-w-full">
                        {QuestionsUtils.getAnswer(
                          q,
                          question.answer.split("|__|")[index],
                          t,
                          downloadDocument,
                          lang,
                        )}
                      </div>
                    </div>
                  ))}
                </>
              )}
              {question.question.type === "image_selector" && (
                <>
                  {QuestionsUtils.getAnswer(
                    question.question,
                    question.answer,
                    t,
                    downloadDocument,
                    lang,
                  )}
                </>
              )}
              {question.question.type !== "image_selector" &&
                question.question.type !== "questions_group" && (
                  <div className="break-words max-w-full">
                    {QuestionsUtils.getAnswer(
                      question.question,
                      question.answer,
                      t,
                      downloadDocument,
                      lang,
                    )}
                  </div>
              )}
            </Card>
          </div>
        ))}
    </>
  );
};
