import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RoutesLinks } from "components/routes-links";
import { Card } from "components/ui/atoms/Card";
import Avatar from "react-avatar";
import {
  FaExclamationTriangle,
  FaEye,
  FaPlus,
  FaRocket,
  FaShareAlt,
  FaUser,
  FaUsers,
} from "react-icons/fa";
import { ButtonCardMain } from "components/ui/atoms/ButtonCardMain";
import ReactTooltip from "react-tooltip";
import { BsCash } from "react-icons/bs";
import { useSwal } from "hooks/useSwal";
import { ButtonCardSecondary } from "components/ui/atoms/ButtonCardSecondary";
import useCurrency from "hooks/useCurrency";

const IRMInvestorCard = ({
  irm = true,
  kiota = false,
  addToMyContacts,
  investor,
  startup,
  setSelectedContact,
  filters,
  user,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { confirm, alert } = useSwal();
  const handleRedirect = (url) => navigate(url);

  const { currency } = useCurrency();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [irm, investor, filters]);

  const handleShareStartup = () => {
    if (investor.contact_email) {
      if (!investor.hard_filters_valid) {
        confirm({
          text: t("share_startup_hard_filters_not_matched"),
          icon: "warning",
        }).then(async(isConfirmed) => {
          if (isConfirmed) {
            setSelectedContact(investor);
          }
        });
      } else if (
        Object.keys(investor.checks)
          .filter((key) => key.includes("hard_filter"))
          .some((key) => investor.checks[key] === false)
      ) {
        confirm({
          text: t("share_startup_filters_not_matched"),
          icon: "warning",
        }).then(async(isConfirmed) => {
          if (isConfirmed) {
            setSelectedContact(investor);
          }
        });
      } else {
        setSelectedContact(investor);
      }
    } else {
      alert({ text: t("email_and_language_required_to_share_deal") });
    }
  };

  const checkFilter = (key) => investor.checks[key];

  return (
    <Card
      investor={user.investor._id !== investor.investor._id ? investor.investor : null}
      bgColor={
        irm || !investor.checks
          ? "bg-white"
          : investor.checks && !investor.hard_filters_valid
            ? "bg-gray-100"
            : "bg-white"
      }
    >
      <div className="flex pb-2 mb-2 ">
        <div className="flex flex-row w-full justify-around">
          <div className="flex flex-col h-full text-sm min-w-0 w-3/5">
            <div className="flex mb-1 items-center">
              <div className={"uppercase font-bold text-sm leading-none text-main truncate"}>
                {investor.company || investor.contact}
              </div>
              {investor.checks && !investor.hard_filters_valid && (
                <div data-tip={t("contact_does_not_match_some_hard_filter")}>
                  <FaExclamationTriangle className="inline ml-2 text-red" />
                </div>
              )}
              {investor.checks &&
                investor.hard_filters_valid &&
                Object.keys(investor.checks)
                  .filter((key) => !key.includes("hard_filter"))
                  .some((key) => investor.checks[key] === false) && (
                  <div data-tip={t("contact_does_not_match_some_filter")}>
                    <FaExclamationTriangle className="inline ml-2 text-red" />
                  </div>
              )}
              {investor.checks &&
                investor.hard_filters_valid &&
                Object.keys(investor.checks)
                  .filter((key) => !key.includes("hard_filter"))
                  .some((key) => investor.checks[key] === false) && (
                  <div data-tip={t("contact_does_not_match_some_filter")}>
                    <FaExclamationTriangle className="inline ml-2 text-red" />
                  </div>
                )}
            </div>
            <div className="flex flex-row">
              <div className="w-auto">
                <span
                  className={`inline-block mr-2 mt-1 font-semibold text-xxs px-2 py-0.5 rounded-md border ${investor.checks && !investor.hard_filters_valid ? "border-white" : "border-gray-lines"} text-text-buttons-secondary`}
                >
                  {t(investor.investor_type)}
                  {investor.investor_type === "ba" && <FaUser className="inline ml-2" />}
                  {investor.investor_type === "accelerator" && <FaRocket className="inline ml-2" />}
                  {investor.investor_type === "vc" && <BsCash className="inline ml-2" />}
                  {investor.investor_type === "ba_network" && <FaUsers className="inline ml-2" />}
                </span>
                {!irm &&
                  investor.shared_dealflow &&
                  investor.shared_dealflow.includes(startup._id) && (
                    <span
                      className={`inline-block mr-2 mt-1 font-semibold text-xxs px-2 py-0.5 rounded-md border ${investor.checks && !investor.hard_filters_valid ? "border-white" : "border-gray-lines"} text-text-buttons-secondary`}
                    >
                      {t("shared_startup")}
                    </span>
                )}
              </div>
            </div>
          </div>

          <div className="flex items-center justify-end mr-5 w-2/5 ">
            <div className="info-card-container-img">
              <Avatar
                src={investor.company_logo || investor.contact_image}
                name={investor.company || investor.contact}
                alt={investor.company || investor.contact}
                maxInitials={3}
                className={`h-full object-contain ${investor.company_logo || investor.contact_image ? "w-full" : ""}`}
                color="#e0e6f2"
                fgColor="#4d70b3"
                round="5"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full">
        <div className="flex flex-col w-full">
          <div className="text-xs mt-2 flex flex-row">
            <div>
              <span
                className={`font-semibold ${irm ? "text-black" : !investor.investment_thesis.minimum_ticket_raw ? "text-black" : checkFilter("minimum_ticket") ? "text-green" : "text-red"}`}
              >
                {`${t("minimum_ticket")}: `}
              </span>
              <span>
                {investor.investment_thesis.hasOwnProperty("minimum_ticket") &&
                ![null, undefined].includes(investor.investment_thesis.minimum_ticket)
                  ? `${investor.investment_thesis.minimum_ticket} ${t(investor.investment_thesis.minimum_ticket_unit)} ${currency}`
                  : "-"}
              </span>
            </div>
            <div className="ml-2">
              <span
                className={`font-semibold ${irm ? "text-black" : !investor.investment_thesis.maximum_ticket_raw ? "text-black" : checkFilter("maximum_ticket") ? "text-green" : "text-red"}`}
              >
                {`${t("maximum_ticket")}: `}
              </span>
              <span>
                {investor.investment_thesis.hasOwnProperty("maximum_ticket") &&
                ![null, undefined].includes(investor.investment_thesis.maximum_ticket)
                  ? `${investor.investment_thesis.maximum_ticket} ${t(investor.investment_thesis.maximum_ticket_unit)} ${currency}`
                  : "-"}
              </span>
            </div>
          </div>
          <div className="text-xs mt-1 flex flex-row">
            <div>
              <span
                className={`font-semibold ${irm ? "text-black" : !investor.investment_thesis.minimum_valuation_raw ? "text-black" : checkFilter("minimum_valuation") ? "text-green" : "text-red"}`}
              >
                {`${t("minimum_valuation")}: `}
              </span>
              <span>
                {investor.investment_thesis.hasOwnProperty("minimum_valuation") &&
                ![null, undefined].includes(investor.investment_thesis.minimum_valuation)
                  ? `${investor.investment_thesis.minimum_valuation} ${t(investor.investment_thesis.minimum_valuation_unit)} ${currency}`
                  : "-"}
              </span>
            </div>
            <div className="ml-2">
              <span
                className={`font-semibold ${irm ? "text-black" : !investor.investment_thesis.maximum_valuation_raw ? "text-black" : checkFilter("maximum_valuation") ? "text-green" : "text-red"}`}
              >
                {`${t("maximum_valuation")}: `}
              </span>
              <span>
                {investor.investment_thesis.hasOwnProperty("maximum_valuation") &&
                ![null, undefined].includes(investor.investment_thesis.maximum_valuation)
                  ? `${investor.investment_thesis.maximum_valuation} ${t(investor.investment_thesis.maximum_valuation_unit)} ${currency}`
                  : "-"}
              </span>
            </div>
          </div>
          <div className="text-xs mt-2 pt-2 flex flex-col border-t border-gray-lines w-full">
            <div>
              <span
                className={`font-semibold ${!investor.investment_thesis.sectors?.length ? "text-black" : irm ? "text-black" : checkFilter("sectors") ? "text-green" : "text-red"}`}
              >
                {`${t("sectors")}: `}
              </span>
              <span>
                {investor.investment_thesis.sectors?.length
                  ? investor.investment_thesis.sectors.length > 1
                    ? `${t(investor.investment_thesis.sectors[0].key)} (+${investor.investment_thesis.sectors.length - 1} ${t("more")})`
                    : t(investor.investment_thesis.sectors[0].key)
                  : "-"}
              </span>
            </div>
            <div>
              <span
                className={`font-semibold ${!investor.investment_thesis.countries?.length ? "text-black" : irm ? "text-black" : checkFilter("countries") ? "text-green" : "text-red"}`}
              >
                {`${t("countries")}: `}
              </span>
              <span>
                {investor.investment_thesis.countries?.length
                  ? investor.investment_thesis.countries.length > 1
                    ? `${t(investor.investment_thesis.countries[0].key)} (+${investor.investment_thesis.countries.length - 1} ${t("more")})`
                    : t(investor.investment_thesis.countries[0].key)
                  : "-"}
              </span>
            </div>
            <div>
              <span
                className={`font-semibold ${!investor.investment_thesis.investment_stages?.length ? "text-black" : irm ? "text-black" : checkFilter("investment_stages") ? "text-green" : "text-red"}`}
              >
                {`${t("investment_stages")}: `}
              </span>
              <span>
                {investor.investment_thesis.investment_stages?.length
                  ? investor.investment_thesis.investment_stages.length > 1
                    ? `${t(investor.investment_thesis.investment_stages[0].key)} (+${investor.investment_thesis.investment_stages.length - 1} ${t("more")})`
                    : t(investor.investment_thesis.investment_stages[0].key)
                  : "-"}
              </span>
            </div>
            <div>
              <span
                className={`font-semibold ${!investor.investment_thesis.project_stages?.length ? "text-black" : irm ? "text-black" : checkFilter("project_stages") ? "text-green" : "text-red"}`}
              >
                {`${t("project_stages")}: `}
              </span>
              <span>
                {investor.investment_thesis.project_stages?.length
                  ? investor.investment_thesis.project_stages.length > 1
                    ? `${t(investor.investment_thesis.project_stages[0].key)} (+${investor.investment_thesis.project_stages.length - 1} ${t("more")})`
                    : t(investor.investment_thesis.project_stages[0].key)
                  : "-"}
              </span>
            </div>
            <div>
              <span
                className={`font-semibold ${!investor.investment_thesis.business_models?.length ? "text-black" : irm ? "text-black" : checkFilter("business_models") ? "text-green" : "text-red"}`}
              >
                {`${t("business_models")}: `}
              </span>
              <span>
                {investor.investment_thesis.business_models?.length
                  ? investor.investment_thesis.business_models.length > 1
                    ? `${t(investor.investment_thesis.business_models[0].key)} (+${investor.investment_thesis.business_models.length - 1} ${t("more")})`
                    : t(investor.investment_thesis.business_models[0].key)
                  : "-"}
              </span>
            </div>
            <div>
              <span className="font-semibold">{`${t("lead_investor")}: `}</span>
              <span>
                {investor.investment_thesis.lead_investor
                  ? t(investor.investment_thesis.lead_investor)
                  : "-"}
              </span>
            </div>
          </div>
        </div>
      </div>
      {(irm || kiota || startup) && (
        <div className={"flex justify-end mt-4 border-t border-separator pt-2"}>
          {startup && investor.checks && investor.hard_filters_valid && (
            <ButtonCardMain
              onClick={() => handleShareStartup()}
              text={t(
                startup &&
                  investor.shared_dealflow &&
                  investor.shared_dealflow.includes(startup._id)
                  ? "share_again"
                  : "share",
              )}
              iconComponent={<FaShareAlt className="inline-block w-6 h-4" />}
            />
          )}
          {startup && investor.checks && !investor.hard_filters_valid && (
            <ButtonCardSecondary
              onClick={() => handleShareStartup()}
              text={t(
                startup &&
                  investor.shared_dealflow &&
                  investor.shared_dealflow.includes(startup._id)
                  ? "share_again"
                  : "share",
              )}
              iconComponent={<FaShareAlt className="inline-block w-6 h-4" />}
            />
          )}
          {kiota && (
            <ButtonCardMain
              onClick={() => addToMyContacts(investor._id)}
              text={t("add_to_my_contacts")}
              iconComponent={<FaPlus className="inline-block w-6 h-4" />}
            />
          )}
          {irm && !kiota && (
            <ButtonCardMain
              onClick={() => handleRedirect(`${RoutesLinks.CONTACTS_LINK}${investor._id}`)}
              text={t("see_details")}
              iconComponent={<FaEye className="inline-block w-6 h-4" />}
            />
          )}
        </div>
      )}
    </Card>
  );
};

export default IRMInvestorCard;
