import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "components/ui/molecules/Modal";
import { Input } from "components/ui/atoms/Input";

import Config from "config";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { RichEditor } from "components/ui/objects/RichEditor";

const CommentModal = ({ showModal, handleCloseModal, onSubmit }) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState("");

  return (
    <Modal
      showCloseModal={true}
      onClose={handleCloseModal}
      showModal={showModal}
      paddingBottom="4"
      paddingTop="6"
      px="6"
      width="auto"
    >
      <h2 className={`font-semibold text-main`}>{t("send_comment")}</h2>

      <div className="mt-4">
        <RichEditor
          name="comment"
          placeholder={t("add_comment")}
          className="mb-4"
          value={comment}
          toolbarClassName="application_form_ended_description"
          onChange={(value) => {
            setComment(value === "<p><br></p>" ? "" : value);
          }}
        />
      </div>
      <div className={`flex justify-end mt-4 border-t pt-2 border-separator`}>
        <ButtonMain
          text={t("send_comment")}
          disabled={!comment}
          onClick={() => onSubmit(comment)}
        />
      </div>
    </Modal>
  );
};

export default CommentModal;
