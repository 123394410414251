import { AppActionsTypes } from "./app-actions-types";

const AppActions = {
  setLoading: (status) => ({
    type: AppActionsTypes.SET_LOADING,
    payload: { status },
  }),

  setPlatformInformation: (status) => ({
    type: AppActionsTypes.SET_PLATFORM_INFORMATION,
    payload: status,
  }),
};

export default AppActions;
