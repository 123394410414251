import TranslateUtils from "utils/translate-utils";

import { RoutesLinks } from "components/routes-links";
import {
  FaEdit,
  FaInfoCircle,
  FaLock,
  FaMinus,
  FaMinusCircle,
  FaPlus,
  FaPlusCircle,
  FaTimesCircle,
  FaTrash,
  FaUserCheck,
  FaUserFriends,
  FaUserLock,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { QuestionsUtils } from "utils/questions-utils";
import ReactTooltip from "react-tooltip";
import useUser from "../../../hooks/useUser";
import { ButtonDanger } from "components/ui/atoms/ButtonDanger";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import Config from "config";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { Card } from "components/ui/atoms/Card";

export const Question = ({
  question,
  deleteQuestion,
  updateQuestion,
  addOrRemoveQuestion,
  iconSmall = false,
  isSelected = false,
  showTag = false,
  showEdit = true,
  openEditInTab = false,
  showRequiredBadge = false,
}) => {
  const { user, userLanguage } = useUser();
  const { t } = useTranslation();
const navigate = useNavigate();

  const openEdit = () => {
    const url = `${RoutesLinks.QE_QUESTIONS_WIZARD_LINK}/${question._id}`;

    if (openEditInTab) {
      window.open(url);
    } else {
      navigate(url);
    }
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [user, question]);

  return (
    <Card wrapperClassName={`${question.key_question && `border border-main`}`}>
      <div className="flex flex-col mb-2">
        <div className="flex flex-row">
          {QuestionsUtils.getIcon(question.type, iconSmall)}

          <div className="flex-1 flex flex-row ml-2">
            <div
              className={`w-full flex-1 mr-2 font-semibold ${iconSmall ? "text-xs" : "text-sm"}`}
            >
              <div className="flex">
                {TranslateUtils.getTextByLang(userLanguage, question.title)}
                {question.key_question && (
                  <div data-tip={t("key_question_tooltip")}>
                    <FaInfoCircle className="inline ml-2" />
                  </div>
                )}
              </div>
              <div className="text-xxs text-gray">{t(question.type)}</div>
              {question.percentage && (
                <div className="text-xxs text-gray">
                  {t("percentage_investors", { percentage: question.percentage })}
                </div>
              )}
              <div className="text-xxs text-gray font-normal text-ellipsis w-full">
                {["select", "multiselect", "likert_scale"].includes(question.type) &&
                  question.options?.length > 0 &&
                  question.options.slice(0, 15).map((option, index) => (
                    <span key={index}>
                      {index > 0 && ", "}
                      {TranslateUtils.getTextByLang(userLanguage, option)}
                    </span>
                  ))}
                {question.options?.length > 15 && (
                  <span className="ml-1">{`(+ ${question.options.length - 15})`}</span>
                )}
              </div>
            </div>

            <div className="flex flex-row justify-center items-start">
              {showEdit && question.investor && (
                <div>
                  <ButtonSecondary
                    onClick={openEdit}
                    iconComponent={<FaEdit className="inline" />}
                  />
                </div>
              )}

              {question.investor && deleteQuestion && (
                <div className="ml-1">
                  <ButtonDanger
                    onClick={() => deleteQuestion(question._id)}
                    iconComponent={<FaTrash className="inline" />}
                  />
                </div>
              )}

              {addOrRemoveQuestion && (
                <div
                  className="ml-1"
                  data-tip={t(isSelected ? "delete_question_from_form" : "add_question_to_form")}
                >
                  {!isSelected && (
                    <ButtonMain
                      onClick={() => addOrRemoveQuestion(question)}
                      iconComponent={<FaPlus className="inline" />}
                    />
                  )}
                  {isSelected && (
                    <ButtonDanger
                      onClick={() => addOrRemoveQuestion(question)}
                      iconComponent={<FaMinusCircle className="inline" />}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="font-semibold text-xs flex flex-row justify-start my-2">
          <span className="border-gray-lines border rounded-lg py-0.5 px-1">{`ID: ${question._id.slice(-4)}`}</span>

          {showTag && question.tag && (
            <span className="border-green border rounded-lg py-0.5 px-1 ml-1">
              {question.investor ? question.tag : t(question.tag)}
            </span>
          )}

          {!question.investor && (
            <span
              data-tip={t("question_owned_by_kiota")}
              className="border-gray-lines border rounded-lg py-0.5 px-1 ml-1"
            >
              <FaLock className="inline-block mr-1" />
              Kiota
            </span>
          )}

          {showRequiredBadge && (
            <span className="border-red border rounded-lg py-0.5 px-1 ml-1">
              {t("question_required")}
            </span>
          )}

          {question.deletedAt && (
            <span
              data-tip={t("question_deleted")}
              className="border-red border rounded-lg py-0.5 px-1 ml-1"
            >
              <FaTimesCircle className="text-red inline-block mr-1" />
              {t("deleted")}
            </span>
          )}

          {question.title?.es && (
            <span
              data-tip={t("es")}
              className="border-gray-lines border rounded-lg py-0.5 px-1 ml-1"
            >
              ES
            </span>
          )}

          {question.title?.en && (
            <span
              data-tip={t("es")}
              className="border-gray-lines border rounded-lg py-0.5 px-1 ml-1"
            >
              EN
            </span>
          )}
        </div>
      </div>
    </Card>
  );
};
