import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import PipelineManagersService from "services/pipeline-managers-service";
import { Loader } from "components/ui/molecules/Loader";
import { SectionHeader } from "components/ui/molecules/SectionHeader";
import { useNavigate } from "react-router-dom";
import { RoutesLinks } from "components/routes-links";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import { PopoverBoards } from "components/ui/atoms/PopoverBoards";
import { PieChart } from "components/ui/molecules/charts/PieChart";
import { Card } from "components/ui/atoms/Card";
import moment from "moment";
import { isEmpty } from "lodash";
import Arrow from "styles/images/arrow_right_square.svg";
import { useSwal } from "hooks/useSwal";
import Config from "config";
import { PageTitle } from "components/ui/atoms/PageTitle";
import { FaPlus, FaPlusCircle, FaRocket, FaUser, FaUsers } from "react-icons/fa";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { ButtonCardMain } from "components/ui/atoms/ButtonCardMain";
import { Modal } from "components/ui/molecules/Modal";
import ReportSection from "./ReportSection";

const Reports = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(true);
  const [creating, setCreating] = useState(false);
  const { confirm } = useSwal();
  const [boards, setBoards] = useState([]);
  const [reports, setReports] = useState([]);
  const [showUsedBoardError, setShowUsedBoardError] = useState(false);
  const [boardInEvents, setBoardInEvents] = useState([]);

const navigate = useNavigate();

  const handleRedirect = (url) => navigate(url);

  const getPipelineManagers = useCallback(async () => {
    try {
      setLoading(true);

      const result = await PipelineManagersService.getPipelineManagers();
      setBoards(
        result.data.data.map((board) => {
          return { id: board._id, value: board.name };
        }),
      );
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key
          ? error.response.data.msg_key
          : t("error_occurred_retrieving_boards"),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
    } finally {
      setLoading(false);
    }
  }, [setBoards]);

  useEffect(() => {
    getPipelineManagers();
  }, []);

  const confirmCreate = async ({ name }) => {
    try {
      setCreating(true);
      setShowReportSection(false);
      await PipelineManagersService.createPipelineManager(name, []);
      getPipelineManagers();
      addToast(t("board_created_successfully"), {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      console.error(error);
      addToast(
        error.response && error.response.data.msg_key
          ? error.response.data.msg_key
          : t("error_occurred_creating_board"),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
    } finally {
      setCreating(false);
    }
  };

  const confirmUpdate = ({ name }) => {
    confirm({
      text: t("update_board_text"),
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setUpdating(true);
          setShowReportSection(false);
          await PipelineManagersService.updatePipelineManager(boardToEdit._id, { name });
          getPipelineManagers();
          addToast(t("board_updated_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
        } catch (error) {
          console.error(error);
          addToast(
            error.response && error.response.data.msg_key
              ? error.response.data.msg_key
              : t("error_occurred_creating_board"),
            {
              appearance: "error",
              autoDismiss: true,
            },
          );
        } finally {
          setBoardToEdit({});
          setUpdating(false);
        }
      }
    });
  };

  const deletePipelineManager = (board) => {
    confirm({
      text: t("delete_board_text"),
      icon: "warning",
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setDeleting(true);
          await PipelineManagersService.deletePipelineManager(board._id);
          getPipelineManagers();
          addToast(t("board_deleted_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
        } catch (error) {
          if (error.response.status === 403) {
            setShowUsedBoardError(true);
            setBoardInEvents(error.response.data.events);
          } else {
            addToast(
              error.response
                ? error.response.data.msg_key
                  ? t(error.response.data.msg_key)
                  : t("error_occurred")
                : t("error_occurred_deleting_board"),
              {
                appearance: "error",
                autoDismiss: true,
              },
            );
          }
        } finally {
          setDeleting(false);
        }
      }
    });
  };

  const [showReportSection, setShowReportSection] = useState(false);

  const [boardToEdit, setBoardToEdit] = useState({});

  return (
    <>
      {showUsedBoardError && (
        <Modal
          showCloseModal={true}
          showModal={showUsedBoardError}
          paddingBottom="2"
          paddingTop="6"
          onClose={() => {
            setShowUsedBoardError(false);
            setBoardInEvents([]);
          }}
        >
          <h2 className="font-semibold text-xl mt-4">{t("error_occurred_deleting_board")}</h2>
          <p className="font-medium text-sm text-gray-dark">
            {t("board_is_being_used_in_some_automations")}
          </p>
          <p className="font-medium text-sm text-gray-dark mt-6 mb-4">
            {t("delete_board_automation_previously")}
          </p>
          <div className="text-sm  text-justify font-normal w-full px-10 mb-6">
            <ul className="list-disc">
              {boardInEvents.map((q, index) => {
                return (
                  <li key={index} className="mb-1">
                    {q}
                  </li>
                );
              })}
            </ul>
          </div>
        </Modal>
      )}

      <div className="flex items-start">
        <div className="flex-1">
          <PageTitle title={t("reports")} />
        </div>

        <ButtonMain
          iconComponent={<FaPlus className="inline-block mr-2" />}
          text={t("create_report")}
          onClick={() => {
            setShowReportSection(true);
          }}
        />
      </div>

      {(loading || creating) && <Loader />}
      {showReportSection && (
        <ReportSection
          onSubmit={confirmCreate}
          showModal={showReportSection}
          handleCloseModal={() => {
            setShowReportSection(false);
          }}
        />
      )}
      {reports.length > 0 && (
        <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4 mt-6">
          {reports.map((report, index) => {
            return (
              <Card key={index}>
                <div className="my-2 flex justify-start items-start space-x-4 relative h-full">
                  <div className="flex flex-col w-full h-full">
                    <>
                      <div className="flex flex-col">
                        <div className="flex flex-row justify-between">
                          <div className={"flex text-main font-bold"}>{report.name}</div>
                        </div>
                        <div className={"pt-2 mt-4 border-t border-separator flex justify-end"}>
                          <ButtonCardMain
                            text={t("see_board")}
                            onClick={() =>
                              handleRedirect(`${RoutesLinks.REPORTS_LINK}${report._id}`)
                            }
                          />
                        </div>
                      </div>
                    </>
                  </div>
                </div>
              </Card>
            );
          })}
        </div>
      )}
      {reports.length === 0 && !loading && (
        <div className="mt-6">
          <NoDataInfo title={t("reports_not_found")} />
        </div>
      )}
    </>
  );
};

export default Reports;
