import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { RoutesLinks } from "components/routes-links";
import { useToasts } from "react-toast-notifications";
import { Loader } from "components/ui/molecules/Loader";
import { SharedEventFooter } from "components/events/components/event/components/SharedEventFooter";
import { Pagination } from "components/ui/atoms/Pagination";
import { FaArrowLeft, FaEye, FaFileDownload, FaPen } from "react-icons/fa";
import { SectionHeader } from "components/ui/molecules/SectionHeader";
import { ApplicationQuestionnaireView } from "components/ui/objects/ApplicationQuestionnaireView";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import { Card } from "components/ui/atoms/Card";

import { JudgeStartupEvaluationForm } from "components/events/components/evaluation/JudgeStartupEvaluationForm";
import { JudgeStartupEvaluationView } from "components/events/components/evaluation/JudgeStartupEvaluationView";
import { useHash } from "../../../../hooks/useHash";
import Unauthorized from "components/ui/objects/Unauthorized";
import { Alert } from "components/ui/molecules/Alert";
import { DocumentList } from "components/documentation/documents/components/DocumentList";
import { ButtonCardMain } from "components/ui/atoms/ButtonCardMain";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { DealResumeSection } from "components/dealflow/DealResumeSection";
import FormAnswersSection from "components/qe-forms/components/forms-form/FormAnswersSection";
import FormUtils from "utils/form-utils";
import Lang from "lang";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { useSwal } from "hooks/useSwal";
import FormsService from "services/forms-service";
import DocumentsService from "services/documents-service";
import EvaluationProcessesManagerService from "services/evaluation-processes-manager-service";
import ReportsService from "services/reports-service";
import { DealflowService } from "services/dealflow-service";
import LogoDefault from "styles/images/logo_default.png";

const SharedEventJudge = () => {
  const wrapperElement = useRef(null);
  const { eventId } = useParams();
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const [event, setEvent] = useState();
  const [judge, setJudge] = useState();
  const [startups, setStartups] = useState([]);
  // const [startups, setStartups] = useState([])
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [showDiscarded, setShowDiscarded] = useState(false);
  const [totalStartups, setTotalStartups] = useState(0);
  const [selectedStartup, setSelectedStartup] = useState(null);
  const [startupEvaluation, setStartupEvaluation] = useState(null);
  const [startupApplication, setStartupApplication] = useState(null);
  const [deal, setDeal] = useState(null);
  const [startupSection, setStartupSection] = useState("information");
  const [showEvaluateForm, setShowEvaluateForm] = useState(true);
  const [judgeId, setJudgeId] = useState(null);
  const [hash, setHash] = useHash();
  const commonButtonProps = {
    textColor: "blue-dark",
    width: "12",
    horizontal: "1",
    vertical: "1",
    verticalMargin: "0",
    horizontalMargin: "0",
  };
  const { confirm } = useSwal();
  const [authorized, setAuthorized] = useState(false);
  const [sharedEventJudgeAccessToken, setSharedEventJudgeAccessToken] = useState(
    localStorage.getItem("X-SEJ-Token"),
  );
  const [showDealInfo, setShowDealInfo] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const [answerToExpand, setAnswerToExpand] = useState(null);
  const [info, setInfo] = useState(null);
  const [redFlags, setRedFlags] = useState({});

  const documentListRef = useRef();

  const downloadGeneralDocument = async(documentId) => {
    try {
      setLoading(true);
      let result = null;
      result = await DocumentsService.getDocument(documentId);
      if (result?.data?.data?.url) {
        window.open(result.data.data.url, "_blank");
      }
    } catch (error) {
      addToast(t(error?.response?.data?.msg_key || "error_occurred_downloading_document"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const downloadDocument = async(documentId) => {
    try {
      setLoading(true);
      let result = null;
      result = await DocumentsService.getSharedDocumentForForm(documentId);
      if (result?.data?.data?.url) {
        window.open(result.data.data.url, "_blank");
      }
    } catch (error) {
      addToast(t(error?.response?.data?.msg_key || "error_occurred_downloading_document"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (info?.application) {
      const parsedQuestions = FormUtils.getParsedQuestions(
        info.application.questions.map((q) => ({ question_id: q.question })),
      );
      const answers = FormUtils.getParsedAnswers(
        parsedQuestions,
        [info],
        t,
        Lang.getCurrentLang().code,
        downloadDocument,
        false,
        redFlags,
      );

      setAnswerToExpand({ answers: answers[0], questions: parsedQuestions });
    }
  }, [info, redFlags]);

  useEffect(() => {
    if (selectedStartup) {
      getSharedDeal();
    }
  }, [selectedStartup]);

  const getSharedDeal = async() => {
    try {
      setLoading(true);
      const result = await DealflowService.getSharedDeal(selectedStartup._id);
      if (result.data) {
        setDeal(result.data.data);
      } else {
        addToast(t("error_occurred_retrieving_information"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } catch (error) {
      addToast(t("error_occurred_retrieving_information"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const manageError = (
    error,

    messageKey = "error_occurred_retrieving_event",
    redirect = true,
  ) => {
    if (redirect) {
      navigate(RoutesLinks.LOGIN_LINK);
    }

    console.error(error);
    addToast(t(messageKey), {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const getEvent = () => EvaluationProcessesManagerService.getSharedEvaluationProcess(eventId).then((result) => {
    setEvent(result.data.data);
  });

  const getJudge = () => EvaluationProcessesManagerService.getJudge(eventId, judgeId).then((result) => {
    setJudge(result.data.data);
  });

  const getStartups = () => EvaluationProcessesManagerService.getDealflowFromSharedEvaluationProcess(
    eventId,
    {
      rowsPerPage: pageSize,
      page: currentPage,
      judge: judge._id,
      discarded: showDiscarded,
    },
    true,
  ).then((result) => {
    const { startups, pagination } = result.data.data;
    setStartups(startups);

    if (pagination.current - 1 !== currentPage) {
      setCurrentPage(parseInt(pagination.current));
    }

    if (pagination.total !== totalPages) {
      setTotalPages(pagination.pages);
    }

    if (pagination.totalDocs !== totalStartups) {
      setTotalStartups(pagination.totalDocs);
    }

    return startups;
  });

  const getStartupEvaluation = (startup) => EvaluationProcessesManagerService.getEvaluationByExternalJudge(
    judgeId,
    startup._id,
  ).then((result) => {
    setStartupEvaluation(result.data.data);

    if (result.data.data) {
      setShowEvaluateForm(false);
    }
  });

  const navigateToStartup = (startup, section) => {
    setSelectedStartup(startup);
    setStartupSection(section);
    setHash(`#${startup._id}`);
  };

  const navigateToHome = () => {
    setSelectedStartup(null);
    setStartupSection("information");
    window.scrollTo(0, 0);
    setHash("#");
  };

  const loadEvaluation = (startup) => getStartupEvaluation(startup).catch((error) =>
    manageError(error, "error_occurred_retrieving_evaluation", false),
  );

  const downloadEvaluation = async() => confirm({
    title: t("download_evaluation"),
  }).then(async(isConfirmed) => {
    if (isConfirmed) {
      try {
        setLoading(true);
        await ReportsService.generateExternalEvaluation(judge, deal, event);
      } catch (error) {
        console.error(error);
        addToast(t("error_occurred_downloading_evaluation"), {
          appearance: "error",
          autoDismiss: true,
        });
      } finally {
        setLoading(false);
      }
    }
  });

  const loadApplication = (startup) => getStartupApplication(startup).catch((error) =>
    manageError(error, "error_occurred_retrieving_evaluation", false),
  );

  const canEvaluateStartup = () => !selectedStartup?.discarded && judge?.enabled;

  useEffect(() => {
    const checkAccess = async() => {
      try {
        setLoading(true);
        const result = await EvaluationProcessesManagerService.checkAccessToSharedEvaluationProcess(
          eventId,
          sharedEventJudgeAccessToken,
        );
        setJudgeId(result.data.data.judge_id);
        setAuthorized(true);
        getEvent();
      } catch (error) {
        addToast(
          t(
            error?.response?.status === 403
              ? "forbidden_access_to_process_evaluation"
              : "error_occurred_checking_access",
          ),
          {
            appearance: "error",
            autoDismiss: true,
          },
        );
      } finally {
        setLoading(false);
      }
    };

    if (sharedEventJudgeAccessToken) {
      localStorage.setItem("X-SEJ-Token", sharedEventJudgeAccessToken);
      checkAccess();
    }
  }, [sharedEventJudgeAccessToken]);

  useEffect(() => {
    if (judgeId) {
      getJudge();
    }
  }, [judgeId]);

  useEffect(() => {
    if (hash === "") {
      navigateToHome();
    }
  }, [hash]);

  useEffect(() => {
    if (event && event._id && judge) {
      setLoading(true);
      getStartups()
        .catch((error) => manageError(error))
        .finally(() => setLoading(false));
    }
  }, [event, currentPage, pageSize, showDiscarded, judge]);

  useEffect(() => {
    if (wrapperElement.current) {
      wrapperElement.current.scrollIntoView({
        behavior: "smooth",
      });
    }

    if (selectedStartup && selectedStartup._id) {
      setLoading(true);
      Promise.all([
        // loadApplication(selectedStartup),
        loadEvaluation(selectedStartup),
      ]).finally(() => setLoading(false));
    } else if (event && event._id) {
      setLoading(true);
      getStartups()
        .catch((error) => manageError(error))
        .finally(() => setLoading(false));
    }
  }, [selectedStartup]);

  const getQuestionnaire = async(formId) => {
    setLoading(true);
    return FormsService.getQuestionnairesForm(formId, true)
      .then((response) => {
        setRedFlags(response.data.data.red_flags || {});
      })
      .catch((error) => {
        console.error(error);
        addToast(t("error_retrieving_form"), {
          appearance: "error",
          autoDismiss: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getForm = async() => {
    try {
      setLoading(true);
      const result = await EvaluationProcessesManagerService.getDealFromSharedEvaluationProcess(
        selectedForm,
        selectedStartup._id,
      );
      setInfo(result.data.data);
      getQuestionnaire(selectedForm);
    } catch (error) {
      addToast(t("error_occurred_retrieving_information"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setSelectedForm(null);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (selectedForm) {
      getForm();
    }
  }, [selectedForm]);

  const hasInformationToShow = () => {
    const formsInDeal = deal.forms ? deal.forms.map((form) => form.form) : [];
    const events = event.source_information.map((source) => source);
    const forms = events.filter((event) => formsInDeal.includes(event._id));
    return !!forms.length || event.show_general_information;
  };

  const getSourceInformation = () => {
    const formsInDeal = deal.forms ? deal.forms.map((form) => form.form) : [];
    const events = event.source_information.map((source) => source);
    return events
      .filter((event) => formsInDeal.includes(event._id))
      .map((source, index) => (
          <Card key={source.name}>
            <h5 className={"uppercase font-bold text-sm leading-none text-main truncate mb-1"}>
              {source.name}
            </h5>

            <div className={"flex justify-end mt-4 pt-2 border-t border-separator"}>
              <ButtonCardMain
                onClick={() => setSelectedForm(source._id)}
                text={t("see_answers")}
                iconComponent={<FaEye className="inline-block w-6 h-3" />}
              />
            </div>
          </Card>
      ));
  };

  if (loading) {
    return <Loader />;
  }

  if (loading) { return <Loader />; }
  if (!authorized && !loading) {
    return (
      <Unauthorized
        loading={loading}
        onRequestAccess={setSharedEventJudgeAccessToken}
        description={t("access_control_description_shared_event_judge")}
        buttonText={t("access_event_evaluation_process")}
      />
    );
  } else {
    return (
      <div className="flex flex-col min-h-screen" ref={wrapperElement}>
        {answerToExpand && (
          <FormAnswersSection
            redFlags={redFlags}
            rawAnswer={info}
            answer={answerToExpand}
            onClose={() => {
              setInfo(null);
              setAnswerToExpand(null);
              setSelectedForm(null);
            }}
          />
        )}
        {showDealInfo && (
          <DealResumeSection
            deal={deal || {}}
            onClose={() => {
              setShowDealInfo(null);
            }}
            downloadDocument={downloadGeneralDocument}
          />
        )}
        <section className="px-8 my-8 flex-1">
          <h1 className={"text-main text-4xl border-b border-gray-lines"}>{event?.name}</h1>
          {/* <SharedEventHeader showLoginButtons={true} /> */}
          {!selectedStartup && (
            <>
              {!judge?.enabled && <Alert style="warning" text={t("judge_disabled")} />}
              <h2 className={"text-main text-2xl mt-5"}>{t("startups")}</h2>

              {startups.length !== 0 && (
                <div className="my-6">
                  <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 gap-4">
                    {startups.map((startup) => (
                        <div key={startup._id}>
                          <Card>
                            <div className="flex">
                              <img
                                src={startup.logo || LogoDefault}
                                alt={startup.name}
                                className="h-10 w-14 object-contain cursor-pointer mr-2"
                              />
                              <div className="flex flex-col px-2 w-full">
                                <div className="flex flex-row justify-between w-full">
                                  <div className="flex">
                                    <span className={"text-sm text-main font-bold line-clamp-1"}>
                                      {startup.name}
                                    </span>
                                    <span
                                      className={
                                        "inline-block ml-2 font-semibold text-xxs px-2 py-0.5 rounded-md border border-gray-lines text-text-buttons-secondary"
                                      }
                                    >
                                      {t(startup.discarded ? "discarded" : "admitted")}
                                    </span>
                                  </div>
                                  <div>
                                    {startup.discarded && (
                                      <span className="inline-block mr-2 rounded-2xl py-0.5 px-2 relative font-semibold text-xxxs bg-gray-lines">
                                        {t("discarded")}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="mt-2 mb-1 flex flex-row">
                                  <span className="text-xs font-semibold mr-2">
                                    {`${t("your_score")}: `}
                                  </span>
                                  <span className="font-semibold text-xs">
                                    {[undefined, null].includes(startup.general_score)
                                      ? "-"
                                      : startup.general_score}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className={"flex justify-end mt-2 pt-2 border-t border-separator"}>
                              <ButtonCardMain
                                onClick={() => setSelectedStartup(startup)}
                                text={t("evaluate")}
                                iconComponent={<FaPen className="inline-block w-6 h-3" />}
                              />
                            </div>
                          </Card>
                        </div>
                    ))}
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    setCurrentPage={(page) => setCurrentPage(page)}
                    perPage={pageSize}
                    pages={totalPages}
                    setPerPage={(value) => setPageSize(value[0].id)}
                  />
                </div>
              )}
            </>
          )}

          {startups.length === 0 && (
            <Alert style={"warning"} text={t("no_startups_assigned_description")} />
          )}
          {selectedStartup && (
            <>
              <div className="flex justify-between">
                <div>
                  <h2 className={"text-main text-2xl mt-5"}>
                    <FaArrowLeft
                      className="inline-block mr-3 cursor-pointer"
                      onClick={() => navigateToHome()}
                    />
                    {selectedStartup.name}
                  </h2>
                </div>
                {startupEvaluation && (
                  <div className="flex items-center">
                    <ButtonSecondary
                      iconComponent={<FaFileDownload className="inline-block mr-2" />}
                      marginRight="2"
                      text={t("download_evaluation")}
                      onClick={downloadEvaluation}
                    />
                  </div>
                )}
              </div>
              <SectionHeader
                className="mb-2 mt-4"
                section={startupSection}
                setSection={setStartupSection}
                sectionKeys={["information", "evaluation", "documentation"].map((_section) => ({
                  id: _section,
                  route: _section,
                  title: _section,
                }))}
              />

              {startupSection === "information" && deal && hasInformationToShow() && (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 my-10">
                  {event.show_general_information && (
                    <Card
                      key={"general_information"}
                      className={"border border-gray-lines shadow-hover p-4 rounded-2xl"}
                    >
                      <h5
                        className={
                          "uppercase font-bold text-sm leading-none text-main truncate mb-1"
                        }
                      >
                        {t("general_information")}
                      </h5>

                      <div className={"flex justify-end mt-4 pt-2 border-t border-separator"}>
                        <ButtonCardMain
                          onClick={() => setShowDealInfo(true)}
                          text={t("see_details")}
                          iconComponent={<FaEye className="inline-block w-6 h-3" />}
                        />
                      </div>
                    </Card>
                  )}
                  {deal && getSourceInformation()}
                </div>
              )}

              {startupSection === "information" && deal && !hasInformationToShow() && (
                <div className="my-10">
                  <NoDataInfo
                    title={t("shared_judge_no_information_to_show")}
                    textOne={t("shared_judge_no_information_to_show_text_one")}
                  />
                </div>
              )}

              {startupSection === "application" && (
                <>
                  {startupApplication && (
                    <ApplicationQuestionnaireView
                      applicationQuestions={startupApplication.questions || []}
                      fields={[]}
                      setLoading={setLoading}
                      shared={true}
                    />
                  )}
                  {!startupApplication && (
                    <div className="mt-10">
                      <NoDataInfo
                        title={t("no_application")}
                        textOne={t("no_application_description")}
                      />
                    </div>
                  )}
                </>
              )}

              {startupSection === "evaluation" && (
                <>
                  {selectedStartup.discarded && (
                    <div className="mt-6">
                      <Alert
                        style="warning"
                        text={t("startup_discarded_description")}
                        bgColor={"bg-white"}
                      />
                    </div>
                  )}

                  {event && !event.score_card && (
                    <div className="mt-6">
                      <Alert
                        style="warning"
                        text={t("no_scorecard_assigned")}
                        bgColor={"bg-white"}
                      />
                    </div>
                  )}

                  {startupEvaluation && !showEvaluateForm && (
                    <>
                      {!judge.enabled && (
                        <div className="mt-6">
                          <Alert style={"warning"} text={t("judge_disabled")} />
                        </div>
                      )}
                      <JudgeStartupEvaluationView evaluation={startupEvaluation} />

                      {canEvaluateStartup() && (
                        <div className="flex justify-end mt-4 mb-6">
                          <ButtonMain
                            onClick={() => setShowEvaluateForm(true)}
                            text={t("update_evaluation")}
                            width={"full md:w-1/2"}
                          />
                        </div>
                      )}
                    </>
                  )}

                  {(!startupEvaluation || showEvaluateForm) && canEvaluateStartup() && (
                    <>
                      {event.score_card && (
                        <JudgeStartupEvaluationForm
                          scoreCard={event.score_card}
                          eventId={event._id}
                          judgeId={judge._id}
                          dealId={selectedStartup._id}
                          onCancel={() => setShowEvaluateForm(false)}
                          showCancelButton={!!startupEvaluation}
                          isUpdating={startupEvaluation && showEvaluateForm}
                          afterSave={() => loadEvaluation(selectedStartup)}
                          evaluationToUpdate={startupEvaluation}
                          judgeEnabled={judge.enabled}
                          shared={true}
                        />
                      )}

                      {!event.score_card && (
                        <Card>
                          <NoDataInfo
                            title={t("evaluations_closed")}
                            textOne={t("evaluations_closed_description")}
                          />
                        </Card>
                      )}
                    </>
                  )}
                </>
              )}
              {startupSection === "documentation" && (
                <DocumentList dealId={selectedStartup._id} ref={documentListRef} shared={true} />
              )}
            </>
          )}
        </section>
        <SharedEventFooter />
      </div>
    );
  }
};

export default SharedEventJudge;
