import { useTranslation } from "react-i18next";

import { SidePanel } from "components/ui/objects/SidePanel";
import { useEffect } from "react";
import { Input } from "components/ui/atoms/Input";
import Config from "config";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import useUser from "hooks/useUser";
import { ButtonDanger } from "components/ui/atoms/ButtonDanger";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import Select from "components/ui/atoms/Select";
import { useToasts } from "react-toast-notifications";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";

const TeammateSection = ({ onClose, teammate, onSubmit, removeTeammate, sendActivationCode }) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const { user } = useUser();

  const LANGUAGES = [
    { id: "es", value: t("es") },
    { id: "en", value: t("en") },
  ];

  const isMainUser = () => {
    const mainUser = user.investor.users.find(
      (teammate) => teammate._id === user.investor.main_contact_id,
    );
    return mainUser.user === user._id;
  };

  const TeammateSchema = Yup.object().shape({
    email: Yup.string().email(t("incorrect_format")).required(t("required_field")),
    name: Yup.string().required(t("required_field")),
    last_name: Yup.string().required(t("required_field")),
    language: Yup.string(),
  });

  const { register, errors, setValue, trigger, getValues, watch } = useForm({
    resolver: yupResolver(TeammateSchema),
    criteriaMode: "all",
    reValidateMode: "all",
    mode: "onChange",
  });

  useEffect(() => {
    register("language");
  }, [register]);

  useEffect(() => {
    if (teammate && Object.keys(teammate).length) {
      setValue("email", teammate?.user?.email || teammate.email, { shouldDirty: true });
      setValue("name", teammate?.user?.name || teammate.name);
      if (teammate.last_name || teammate?.user?.last_name) {
        setValue("last_name", teammate?.user?.last_name || teammate.last_name);
      }
      if (teammate.language) {
        _setValue("language", teammate.language);
      }
    }
  }, [teammate, setValue]);

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, config);
    trigger(name);
  };

  const getSelectableInitialValues = (id, collection, lonely) => {
    if (!id) {
      return lonely ? null : [];
    }

    if (Array.isArray(id)) {
      return collection.filter((i) => id.includes(i.id));
    }

    const item = collection.find((i) => i.id === id);
    return item ? (lonely ? item : [item]) : lonely ? null : [];
  };

  return (
    <>
      <SidePanel
        onClose={onClose}
        title={t(teammate ? "teammate_details" : "add_teammate")}
        width="1/4"
      >
        <form className="w-full">
          <div>
            <Input
              reference={register}
              id="name"
              type="text"
              name="name"
              label={t("name") + "*:"}
              placeholder={t("introduce_name_they")}
              error={errors.name}
            />
          </div>
          <div>
            <Input
              reference={register}
              id="last_name"
              type="text"
              name="last_name"
              label={t("last_name") + "*:"}
              placeholder={t("introduce_last_name_they")}
              error={errors.last_name}
            />
          </div>
          <div>
            <Input
              reference={register}
              id="email"
              type="email"
              name="email"
              label={t("email") + "*:"}
              placeholder={t("write_email_they")}
              error={errors.email}
            />
          </div>
          {!teammate && (
            <Select
              name="language"
              label={t("language")}
              reference={register}
              required={true}
              isClearable={false}
              placeholder={t("select_option")}
              error={errors.language}
              initialValues={getSelectableInitialValues(watch("language"), LANGUAGES)}
              items={LANGUAGES}
              onSelect={(selected) => {
                _setValue("language", selected.length ? selected[0].id : null);
              }}
            />
          )}
          <div className={"flex justify-end mt-2 pt-2 border-t border-separator"}>
            {teammate && teammate?.user?._id !== user._id && (
              <ButtonDanger
                type="button"
                text={t("delete")}
                onClick={() => removeTeammate(teammate)}
                marginRight={2}
              />
            )}
            {teammate && !teammate.activated && (
              <ButtonSecondary
                type="button"
                text={t("send_activation_code")}
                onClick={() => sendActivationCode(teammate)}
                marginRight={2}
              />
            )}
            <ButtonMain
              type="button"
              onClick={async () => {
                const validation = await trigger();
                if (validation && ((!teammate && getValues().language) || teammate)) {
                  onSubmit({ ...getValues() });
                } else {
                  addToast(t("language_required"), {
                    appearance: "error",
                    autoDismiss: true,
                  });
                }
              }}
              text={t(!teammate ? "add_teammate" : "save")}
            />
          </div>
        </form>
      </SidePanel>
    </>
  );
};

export default TeammateSection;
