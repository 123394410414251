import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "components/ui/atoms/Input";
import { isEmpty } from "lodash";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Config from "config";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { SidePanel } from "components/ui/objects/SidePanel";

const ReportSection = ({ onSubmit, handleCloseModal, initialValues }) => {
  const { t } = useTranslation();

  const BoardSchema = Yup.object().shape({
    name: Yup.string().required(t("required_field")),
  });

  const { register, errors, trigger, getValues, setValue } = useForm({
    mode: "all",
    criteriaMode: "all",
    reValidateMode: "all",
    resolver: yupResolver(BoardSchema),
  });

  useEffect(() => {
    if (!isEmpty(initialValues)) {
      setValue("name", initialValues.name);
    }
  }, [initialValues]);

  return (
    <SidePanel width={"1/4"} onClose={handleCloseModal} title={t("create_report")}>
      <form className="w-full">
        <Input
          reference={register}
          id="name"
          type="text"
          name="name"
          label={`${t("name")}: *`}
          placeholder={t("name")}
          error={errors.name}
        />
        <div className={"flex justify-end mt-4 border-t pt-2 border-separator"}>
          <ButtonMain
            type="button"
            onClick={async () => {
              if ((await trigger()) && (!isEmpty(initialValues) || isEmpty(initialValues))) {
                onSubmit({
                  ...getValues(),
                });
              }
            }}
            text={t("create_report")}
          />
        </div>
      </form>
    </SidePanel>
  );
};

export default ReportSection;
