import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import FormsService from "../../../services/forms-service";
import { useToasts } from "react-toast-notifications";
import useUser from "../../../hooks/useUser";
import { Loader } from "../../ui/molecules/Loader";
import { FormAnswers } from "./forms-form/FormAnswers";
import { usePagination } from "../../../hooks/usePagination";
import NotesContainer from "components/ui/objects/NotesContainer";
import { Card } from "components/ui/atoms/Card";
import { FaInfoCircle } from "react-icons/fa";
import NotesService from "services/notes-service";
import MigrationSection from "components/ui/molecules/MigrationSection";
import { useSwal } from "hooks/useSwal";
import { CustomFieldsService } from "services/custom-fields-service";

export const FormResultsSection = ({
  questionnaire,
  dealflow,
  showAddColumn,
  handleAddColumn,
  handleEditCustomField = () => {},
  handleDeleteQuestion,
}) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { user } = useUser();
  const [loading, setLoading] = useState(true);
  const [formAnswers, setFormAnswers] = useState([]);
  const [showNotesContainer, setShowNotesContainer] = useState(false);
  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [dealToGetNotes, setDealToGetNotes] = useState(null);
  const [showMigrationSection, setShowMigrationSection] = useState(false);
  const [noteToMigrate, setNoteToMigrate] = useState(null);
  const [customFields, setCustomFields] = useState([]);
  const { confirm } = useSwal();
  const { setTotalPages, totalPages, setPage, page, rowsPerPage, setRowsPerPage } = usePagination();

  const getQuestionnaireAnswers = () => {
    setLoading(true);
    FormsService.getQuestionnaireAnswers(questionnaire._id, {
      rowsPerPage: 9999,
      // page,
      sortBy: "-createdAt",
    })
      .then((result) => {
        const { applications, pagination } = result.data;
        setFormAnswers(applications);
        setTotalPages(pagination.pages);
      })
      .catch(() => {
        addToast(t("error_getting_answers"), {
          appearance: "error",
          autoDismiss: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (questionnaire) {
      getQuestionnaireAnswers();
      getCustomFields();
    }
  }, [user, questionnaire, page, rowsPerPage]);

  const getCustomFields = () =>
    CustomFieldsService.getCustomFields().then((result) => {
      setCustomFields(result.data.data || []);
    });

  const getNotes = async () => {
    try {
      setLoading(true);
      const result = await NotesService.getNotes({ deal: dealToGetNotes });
      setNotes(result.data.data);
    } catch (error) {
      addToast(t("error_occurred_retrieving_notes"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const createNote = async (title, type, content) => {
    try {
      setLoading(true);
      await NotesService.createNote({
        user: user._id,
        title,
        content,
        type,
        deal: dealToGetNotes,
      });
      getNotes();
    } catch (error) {
      addToast(t("error_occurred_creating_note"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const updateNote = async (title, content) => {
    try {
      setLoading(true);
      await NotesService.updateNote(selectedNote._id, { title, content });
      setSelectedNote(null);
      getNotes();
    } catch (error) {
      addToast(t("error_occurred_updating_note"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteNote = async (noteId) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await NotesService.deleteNote(noteId);
          getNotes();
        } catch (error) {
          addToast(t("error_occurred_deleting_note"), {
            appearance: "error",
            autoDismiss: true,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const getNote = async (noteId) => {
    try {
      setLoading(true);
      const result = await NotesService.getNote(noteId);
      setSelectedNote(result.data.data);
    } catch (error) {
      addToast(t("error_occurred_retrieving_note"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dealToGetNotes) {
      setShowNotesContainer(true);
      getNotes();
    }
  }, [dealToGetNotes]);

  const migrateNote = async (dealId) => {
    try {
      setLoading(true);
      await NotesService.updateNote(noteToMigrate, { deal: dealId });
      addToast(t("note_migrated_successfully"), { appearance: "success", autoDismiss: true });
      setNoteToMigrate(null);
      setShowMigrationSection(false);
      setDealToGetNotes(null);
      setNotes([]);
    } catch (error) {
      addToast(t("error_occurred_migrating_note"), { appearance: "error", autoDismiss: true });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {showMigrationSection && (
        <MigrationSection
          onClose={() => {
            setShowMigrationSection(false);
            setNoteToMigrate(null);
            setDealToGetNotes(null);
          }}
          migrationType={"notes"}
          dealflow={dealflow}
          onSubmit={(dealId) => {
            migrateNote(dealId);
          }}
        />
      )}
      {showNotesContainer && (
        <NotesContainer
          zIndex="20"
          listTitle={t("notes")}
          setFiltersButtons={false}
          setShowModal={setShowNotesContainer}
          notes={notes}
          createNote={createNote}
          updateNote={updateNote}
          deleteNote={deleteNote}
          getNote={getNote}
          selectedNote={selectedNote}
          setSelectedNote={setSelectedNote}
          onClose={() => {
            setDealToGetNotes(null);
            setShowNotesContainer(false);
            setSelectedNote(null);
          }}
          setShowMigrationSection={(noteId) => {
            setShowNotesContainer(false);
            setShowMigrationSection(true);
            setNoteToMigrate(noteId);
          }}
        />
      )}
      <div className="my-6">
        <Card wrapperClassName={"border-2 border-dotted border-border-buttons-secondary"}>
          <div>
            <FaInfoCircle className="inline mr-2 w-5 h-5" />
            <Trans i18nKey="form_results_explanation" components={{ br: <br />, bold: <strong />, small: <small /> }} />
          </div>
        </Card>
      </div>
      <FormAnswers
        redFlags={questionnaire.red_flags}
        questions={questionnaire.questions}
        customFields={
          questionnaire.custom_fields
            ? questionnaire.custom_fields.map((cf) => customFields.find((c) => c._id === cf.custom_field))
            : []
        }
        questionnaireId={questionnaire._id}
        customFieldsValues={questionnaire.custom_fields_values}
        handleAddColumn={handleAddColumn}
        handleEditCustomField={handleEditCustomField}
        handleDeleteQuestion={handleDeleteQuestion}
        showAddColumn={showAddColumn}
        answers={formAnswers}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        totalPages={totalPages}
        setRowsPerPage={setRowsPerPage}
        setDealToGetNotes={setDealToGetNotes}
      />
      {loading && <Loader />}
    </>
  );
};
