import { useTranslation } from "react-i18next";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import FormAnswersSection from "./FormAnswersSection";
import { useEffect, useMemo, useState } from "react";
import { DataGrid } from "components/ui/objects/DataGrid";
import { QuestionsUtils } from "utils/questions-utils";
import useUser from "hooks/useUser";
import { FaComment, FaEdit, FaEye, FaPaperPlane } from "react-icons/fa";
import TranslateUtils from "utils/translate-utils";
import moment from "moment";
import Lang from "lang";
import { useToasts } from "react-toast-notifications";
import { Loader } from "components/ui/molecules/Loader";
import SupportService from "services/support-service";
import FormUtils from "utils/form-utils";
import CommentModal from "./CommentModal";
import { useSwal } from "hooks/useSwal";
import { HiAnnotation } from "react-icons/hi";
import DocumentsService from "services/documents-service";
import { PopoverTrelloList } from "components/ui/atoms/PopoverTrelloList";

export const FormAnswers = ({
  setDealToGetNotes,
  questions,
  answers,
  page,
  setPage,
  rowsPerPage,
  totalPages,
  setRowsPerPage,
  shared = false,
  firstDrop = false,
  redFlags,
  questionnaireId,
  handleAddColumn = () => {},
  handleEditCustomField = () => {},
  showAddColumn = false,
  handleDeleteQuestion = () => {},
  customFields,
  customFieldsValues,
}) => {
  const { t } = useTranslation();
  const { userLanguage } = useUser();
  const dateFormat = "DD-MM-YYYY";
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const [parsedQuestions, setParsedQuestions] = useState([]);
  const [limitedAnswers, setLimitedAnswers] = useState([]);
  const [unlimitedAnswers, setUnlimitedAnswers] = useState([]);
  const [applicationToComment, setApplicationToComment] = useState(null);
  const { confirm } = useSwal();
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    setParsedQuestions(FormUtils.getParsedQuestions(questions));
  }, [questions]);

  useEffect(() => {
    setHeaders(getHeaders());
  }, [parsedQuestions, answers]);

  const [answerToExpand, setAnswerToExpand] = useState(null);

  useEffect(() => {
    if (parsedQuestions.length) {
      const _unlimitedAnswers = FormUtils.getParsedAnswers(
        parsedQuestions,
        answers,
        t,
        userLanguage,
        downloadDocument,
        false,
        redFlags
      );
      const _limitedAnswers = FormUtils.getParsedAnswers(
        parsedQuestions,
        answers,
        t,
        userLanguage,
        downloadDocument,
        true,
        redFlags
      );

      answers.forEach((answer, index) => {
        _unlimitedAnswers[index] = { ..._unlimitedAnswers[index], _id: answer._id };
        _limitedAnswers[index] = { ..._limitedAnswers[index], _id: answer._id };
      });

      setUnlimitedAnswers(_unlimitedAnswers);
      setLimitedAnswers(_limitedAnswers);
    }
  }, [answers, parsedQuestions, questions]);

  const downloadDocument = async (documentId) => {
    try {
      setLoading(true);
      let result = null;
      if (shared) {
        result = await DocumentsService.getSharedDocumentForForm(documentId);
      } else {
        result = await DocumentsService.getDocumentForForm(documentId);
      }
      if (result?.data?.data?.url) {
        window.open(result.data.data.url, "_blank");
      }
    } catch (error) {
      addToast(t(error?.response?.data?.msg_key || "error_occurred_downloading_document"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const EMAILS = {
    "cestrada@kiota.com": "Cristian Estrada",
    "nromero@kmzerohub.com": "Nora Romero",
    "jrequena@kmzerohub.com": "Juan Requena",
    "szhu@kmzerohub.com": "Sophia Zhu",
  };

  const contact = async (applicationId) => {
    confirm({}).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          const application = answers.find((application) => application._id === applicationId);
          if (application && application.deal) {
            await SupportService.contactApplicationKmZero({
              deal: application.deal,
              name: EMAILS[localStorage.getItem("X-KMZ-REP-EMAIL")],
            });
            addToast(t("request_successfully_sent"), {
              appearance: "success",
              autoDismiss: true,
            });
          } else {
            addToast(t("error_occurred_sending_request"), {
              appearance: "error",
              autoDismiss: true,
            });
          }
        } catch (error) {
          addToast(t("error_occurred_sending_request"), {
            appearance: "error",
            autoDismiss: true,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const comment = async (comment) => {
    confirm({}).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          const application = answers.find((application) => application._id === applicationToComment);
          if (application && application.deal) {
            await SupportService.commentApplicationKmZero({
              deal: application.deal,
              name: EMAILS[localStorage.getItem("X-KMZ-REP-EMAIL")],
              email: localStorage.getItem("X-KMZ-REP-EMAIL"),
              comment,
            });
            addToast(t("comment_sent_successfully"), {
              appearance: "success",
              autoDismiss: true,
            });
          } else {
            addToast(t("error_occurred_sending_comment"), {
              appearance: "error",
              autoDismiss: true,
            });
          }
          setApplicationToComment(null);
        } catch (error) {
          addToast(t("error_occurred_sending_comment"), {
            appearance: "error",
            autoDismiss: true,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const getHeaders = () => {
    const headersByQuestions = parsedQuestions
      .filter((question) => question.question.type !== "image_selector")
      .map((question) => ({
        key: question.question._id,
        title: (
          <div className="flex flex-row pb-4  align-middle  w-full">
            {QuestionsUtils.getIcon(question.question.type, true)}
            <span className="ml-2">{TranslateUtils.getTextByLang(userLanguage, question.question.title)}</span>
            <div className="w-5 absolute top-1 right-1">
              {showAddColumn && question.question.customFieldId && (
                <PopoverTrelloList
                  items={question.question.customFieldId ? ["delete_column"] : []}
                  methods={{
                    delete_column: question.question.customFieldId
                      ? () => handleDeleteQuestion(question.question._id)
                      : null,
                  }}
                />
              )}
            </div>
          </div>
        ),
        render: (key, application) => (
          <div key={key}>
            <span className="">{application[question.question._id]}</span>
          </div>
        ),
      }));

    const customFieldsHeaders = customFields.filter(Boolean).map((question) => ({
      key: question._id,
      title: (
        <div className="flex flex-row pb-4  align-middle  w-full">
          {QuestionsUtils.getIcon(question.type, true)}
          <span className="ml-2">{question.name}</span>
          <div className="w-5 absolute top-1 right-1">
            {showAddColumn && (
              <PopoverTrelloList
                items={["delete_column"]}
                methods={{
                  delete_column: () => handleDeleteQuestion(question._id, true),
                  // edit_custom_field: () => handleEditCustomField(question._id)
                }}
              />
            )}
          </div>
        </div>
      ),
      render: (key, application) => {
        const value = customFieldsValues ? customFieldsValues[`${question._id}---${application._id}`] : "-";
        return (
          <div key={key}>
            <span>{Array.isArray(value) ? value[0].value : value}</span>
            {/* <span>{customFieldsValues[`${question._id}---${application._id}`]?.value}</span> */}
            {/* <span>{`${question._id}---${application._id}`}</span> */}
            {/* <span className="ml-2">{application[question.question._id]}</span> */}
          </div>
        );
      },
    }));

    const dateHeader = {
      key: "date",
      title: (
        <div className="flex flex-row pb-4">
          {QuestionsUtils.getIcon("date", true)}
          <span className="ml-2">{t("date")}</span>
        </div>
      ),
      render: (key, application) => {
        console.log(answers.find((answer) => answer._id === application._id));
        console.log(answers);

        return (
          <div key={key}>
            <span className="ml-2">
              {answers.find((answer) => answer._id === application._id)
                ? moment(answers.find((answer) => answer._id === application._id).createdAt).format(
                    dateFormat,
                    Lang.getCurrentLang().code
                  )
                : "-"}
            </span>
          </div>
        );
      },
    };

    return [...headersByQuestions, ...customFieldsHeaders, dateHeader];
  };

  return (
    <>
      {applicationToComment && (
        <CommentModal
          handleCloseModal={() => setApplicationToComment(null)}
          showModal={applicationToComment}
          onSubmit={comment}
        />
      )}
      {loading && <Loader />}
      <div className="my-6">
        {answers.length === 0 && (
          <NoDataInfo title={t("form_answers_not_found")} textOne={t("form_answers_not_found_text_one")} />
        )}
        {answers.length !== 0 && (
          <>
            <DataGrid
              flexColumn={false}
              wrapperClassName="mt-6"
              compact={false}
              agPagination={true}
              bordered={true}
              data={unlimitedAnswers}
              headers={headers}
              showAddColumn={showAddColumn}
              handleAddColumn={handleAddColumn}
              actions={
                shared && !firstDrop
                  ? [
                      {
                        id: "contact",
                        tip: t("contact"),
                        buttonProps: {
                          iconComponent: <FaPaperPlane className="inline" />,
                          width: "12",
                          horizontal: "1",
                          vertical: "1",
                          verticalMargin: "0",
                          horizontalMargin: "0",
                        },
                        onClick: (rowData) => {
                          contact(rowData._id);
                        },
                      },
                      {
                        id: "comments",
                        tip: t("comments"),
                        buttonProps: {
                          iconComponent: <FaComment className="inline" />,
                          width: "12",
                          horizontal: "1",
                          vertical: "1",
                          verticalMargin: "0",
                          horizontalMargin: "0",
                        },
                        onClick: (rowData) => {
                          setApplicationToComment(rowData._id);
                        },
                      },
                      {
                        id: "details",
                        tip: t("details"),
                        buttonProps: {
                          iconComponent: <FaEye className="inline" />,
                          width: "12",
                          horizontal: "1",
                          vertical: "1",
                          verticalMargin: "0",
                          horizontalMargin: "0",
                        },
                        onClick: (rowData) => {
                          setAnswerToExpand({
                            answers: unlimitedAnswers.find((unlimitedAnswer) => unlimitedAnswer._id === rowData._id),
                            questions: parsedQuestions,
                          });
                        },
                      },
                    ]
                  : shared
                  ? [
                      {
                        id: "details",
                        tip: t("details"),
                        buttonProps: {
                          iconComponent: <FaEye className="inline" />,
                          width: "12",
                          horizontal: "1",
                          vertical: "1",
                          verticalMargin: "0",
                          horizontalMargin: "0",
                        },
                        onClick: (rowData) => {
                          setAnswerToExpand({
                            answers: unlimitedAnswers.find((unlimitedAnswer) => unlimitedAnswer._id === rowData._id),
                            questions: parsedQuestions,
                          });
                        },
                      },
                    ]
                  : [
                      {
                        id: "annotations",
                        tip: t("annotations"),
                        buttonProps: {
                          iconComponent: <HiAnnotation className="inline" />,
                          width: "12",
                          horizontal: "1",
                          vertical: "1",
                          verticalMargin: "0",
                          horizontalMargin: "0",
                        },
                        onClick: (rowData) => {
                          const application = answers.find((application) => application._id === rowData._id);
                          setDealToGetNotes(application.deal);
                        },
                      },
                      {
                        id: "details",
                        tip: t("details"),
                        buttonProps: {
                          iconComponent: <FaEye className="inline" />,
                          width: "12",
                          horizontal: "1",
                          vertical: "1",
                          verticalMargin: "0",
                          horizontalMargin: "0",
                        },
                        onClick: (rowData) => {
                          setAnswerToExpand({
                            answers: unlimitedAnswers.find((unlimitedAnswer) => unlimitedAnswer._id === rowData._id),
                            questions: parsedQuestions,
                          });
                        },
                      },
                      {
                        id: "edit",
                        tip: t("edit"),
                        buttonProps: {
                          iconComponent: <FaEdit className="inline" />,
                          width: "12",
                          horizontal: "1",
                          vertical: "1",
                          verticalMargin: "0",
                          horizontalMargin: "0",
                        },
                        onClick: (rowData) => {
                          handleEditCustomField({
                            answers: unlimitedAnswers.find((unlimitedAnswer) => unlimitedAnswer._id === rowData._id),
                          });
                        },
                      },
                    ]
              }
            />
            <div className="mt-4">
              {/* <Pagination
                paginateOptions={[12, 24, 36, 48, 60]}
                currentPage={page}
                setCurrentPage={(page) => setPage(page)}
                perPage={rowsPerPage}
                pages={totalPages}
                setPerPage={(value) => setRowsPerPage(value[0].id)}
              /> */}
            </div>
          </>
        )}
        {answerToExpand && (
          <FormAnswersSection
            answer={answerToExpand}
            onClose={() => setAnswerToExpand(null)}
            redFlags={redFlags}
            shared={shared}
            rawAnswers={answers}
            rawAnswer={answers[0]}
          />
        )}
      </div>
    </>
  );
};
