import { Modal } from "../../ui/molecules/Modal";
import { useTranslation } from "react-i18next";

import { Card } from "../../ui/atoms/Card";
import { FaRegClone, FaRegFile } from "react-icons/fa";
import { ImInsertTemplate } from "react-icons/im";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RoutesLinks } from "../../routes-links";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Input } from "../../ui/atoms/Input";
import FormsService from "../../../services/forms-service";
import { useToasts } from "react-toast-notifications";
import { Loader } from "../../ui/molecules/Loader";
import Config from "config";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { SidePanel } from "components/ui/objects/SidePanel";
import Select from "components/ui/atoms/Select";
import useUser from "hooks/useUser";
import { useSwal } from "hooks/useSwal";

export const CreateFormSection = ({ onClose, forms }) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState("selectType");
  const { reloadUserInfo } = useUser();
  const { alert } = useSwal();

  const { register, handleSubmit, errors, setValue, trigger } = useForm({
    mode: "onChange",
    criteriaMode: "all",
    reValidateMode: "all",
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string().required(t("required_field")),
        form: Yup.string().nullable(),
      }),
    ),
  });

  useEffect(() => {
    register("form");
  }, []);
  const goToTemplates = () => {
    onClose && onClose();
    navigate(RoutesLinks.QE_TEMPLATES_LINK);
  };

  const onSubmit = (data) => {
    setLoading(true);
    FormsService.createQuestionnairesForm(data)
      .then((result) => {
        addToast(t("form_created_successfully"), {
          appearance: "success",
          autoDismiss: true,
        });
        reloadUserInfo();
        navigate(`${RoutesLinks.QE_FORMS_LINK}/${result.data.data._id}`, {
          section: "content",
        });
      })
      .catch(() => {
        addToast(t("error_occurred_updating_form"), {
          appearance: "error",
          autoDismiss: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <SidePanel
        width={"1/4"}
        onClose={onClose}
        title={
          step === "selectType"
            ? t("create_form")
            : step === "blankForm"
              ? t("create_form_blank")
              : t("clone_form")
        }
      >
        <div className={"flex flex-row max-h-full"}>
          {step === "selectType" && (
            <div>
              <Card wrapperClassName="my-2">
                <h3>{t("create_form_blank")}</h3>
                <p className="text-sm">{t("create_form_blank_description")}</p>
                <div className="flex items-center justify-center mt-8">
                  <FaRegFile size={60} />
                </div>
                <ButtonSecondary
                  width={"full"}
                  marginTop={4}
                  text={t("select_this")}
                  onClick={() => setStep("blankForm")}
                />
              </Card>
              <Card wrapperClassName="my-2">
                <h3>{t("clone_form")}</h3>
                <p className="text-sm">{t("clone_form_description")}</p>
                <div className="flex items-center justify-center mt-8">
                  <FaRegClone size={60} />
                </div>
                <ButtonSecondary
                  width={"full"}
                  marginTop={4}
                  text={t("select_this")}
                  onClick={() =>
                    forms.length ? setStep("cloneForm") : alert({ title: t("no_forms_to_clone") })
                  }
                />
              </Card>
              <Card wrapperClassName="my-2">
                <h3>{t("create_form_template")}</h3>
                <p className="text-sm">{t("create_form_template_description")}</p>
                <div className="flex items-center justify-center mt-8">
                  <ImInsertTemplate size={60} />
                </div>
                <ButtonSecondary
                  width={"full"}
                  marginTop={4}
                  text={t("select_this")}
                  onClick={goToTemplates}
                />
              </Card>
            </div>
          )}

          {step === "blankForm" && (
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
              <p className="mb-8 text-sm">{t("create_form_blank_step_description")}</p>
              <Input reference={register} name="name" label={t("form_name")} error={errors.name} />

              <ButtonSecondary width={"full"} marginTop={4} text={t("create_form")} />
            </form>
          )}

          {step === "cloneForm" && (
            <div className="w-full">
              <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
                <div className="w-full">
                  <Input
                    reference={register}
                    name="name"
                    label={t("form_name")}
                    error={errors.name}
                  />
                  <div className="my-3">
                    <Select
                      name="form"
                      label={t("select_form")}
                      multiSelect={false}
                      isClearable={false}
                      items={forms}
                      initialValues={[]}
                      onSelect={(val) => {
                        setValue("form", val[0].id);
                      }}
                    />
                  </div>
                </div>

                <ButtonSecondary width={"full"} marginTop={4} text={t("clone_form")} />
              </form>
            </div>
          )}
        </div>
      </SidePanel>

      {loading && <Loader />}
    </>
  );
};
