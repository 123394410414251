import { useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import { FaCog, FaEye } from "react-icons/fa";
import { ButtonCardMain } from "components/ui/atoms/ButtonCardMain";
import moment from "moment";
import { useEffect, useState } from "react";
import FormAnswersSection from "components/qe-forms/components/forms-form/FormAnswersSection";
import { useToasts } from "react-toast-notifications";
import { Loader } from "components/ui/molecules/Loader";
import FormUtils from "utils/form-utils";
import useUser from "hooks/useUser";
import { Card } from "components/ui/atoms/Card";
import ReactTooltip from "react-tooltip";
import { useSwal } from "hooks/useSwal";
import FormsService from "services/forms-service";
import DocumentsService from "services/documents-service";
import EvaluationProcessesManagerService from "services/evaluation-processes-manager-service";

const DealflowForms = () => {
  const { deal } = useOutletContext();

  const { t } = useTranslation();
  const [selectedForm, setSelectedForm] = useState(null);
  const [answerToExpand, setAnswerToExpand] = useState(null);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const [info, setInfo] = useState(null);
  const { userLanguage } = useUser();
  const { alert } = useSwal();
  const [redFlags, setRedFlags] = useState({});
  const [questionnaire, setQuestionnaire] = useState(null);

  const downloadDocument = async(documentId) => {
    try {
      setLoading(true);
      let result = null;
      result = await DocumentsService.getDocumentForForm(documentId);
      if (result?.data?.data?.url) {
        window.open(result.data.data.url, "_blank");
      }
    } catch (error) {
      addToast(t(error?.response?.data?.msg_key || "error_occurred_downloading_document"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  useEffect(() => {
    if (info?.application) {
      const parsedQuestions = FormUtils.getParsedQuestions(
        info.application.questions.map((q) => ({ question_id: q.question })),
      );
      const answers = FormUtils.getParsedAnswers(
        parsedQuestions,
        [info],
        t,
        userLanguage,
        downloadDocument,
        false,
        redFlags,
      );
      setAnswerToExpand({ answers: answers[0], questions: parsedQuestions });
    }
  }, [info, redFlags]);

  useEffect(() => {
    if (questionnaire) {
      setRedFlags(questionnaire.red_flags || {});
    }
  }, [questionnaire]);

  const getForm = async() => {
    setLoading(true);
    return FormsService.getQuestionnairesForm(selectedForm.form._id)
      .then((response) => {
        setQuestionnaire(response.data.data);
      })
      .catch(() => {
        addToast(t("error_retrieving_form"), {
          appearance: "error",
          autoDismiss: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getApplication = async() => {
    try {
      setLoading(true);
      const result = await EvaluationProcessesManagerService.getDealFromEvaluationProcess(
        selectedForm.form._id,
        deal._id,
        selectedForm.application,
      );
      setInfo(result.data.data);
    } catch (error) {
      addToast(t("error_occurred_retrieving_information"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (selectedForm) {
      getForm();
      getApplication();
    }
  }, [selectedForm]);

  return (
    <>
      {loading && <Loader />}
      {answerToExpand && (
        <FormAnswersSection
          rawAnswer={info}
          answer={answerToExpand}
          redFlags={redFlags}
          onClose={() => {
            setInfo(null);
            setAnswerToExpand(null);
            setSelectedForm(null);
          }}
        />
      )}
      {(!deal?.forms || deal?.forms?.length === 0) && (
        <div className="my-6">
          <NoDataInfo title={t("dealflow_without_forms")} />
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 my-10">
        {deal?.forms?.length > 0 &&
          deal.forms.map((form, index) => (
              <Card key={form._id}>
                <h5 className={"uppercase font-bold text-sm leading-none text-main truncate mb-1"}>
                  {form.form.name}
                  {!form.application && (
                    <FaCog className="inline ml-2" data-tip={t("automated_form_not_answered")} />
                  )}
                  {form.application && form.automated && (
                    <FaCog className="inline ml-2" data-tip={t("form_assigned_by_automation")} />
                  )}
                </h5>
                {form.date && (
                  <div className="text-xs">{moment(form.date).format("YYYY-MM-DD")}</div>
                )}

                <div className={"flex justify-end mt-4 pt-2 border-t border-separator"}>
                  <ButtonCardMain
                    onClick={
                      form.application
                        ? () => {
                            setSelectedForm(form);
                          }
                        : () => alert({ text: t("automated_form_not_answered") })
                    }
                    text={t("see_answers")}
                    iconComponent={<FaEye className="inline-block w-6 h-3" />}
                  />
                </div>
              </Card>
          ))}
      </div>
    </>
  );
};

export default DealflowForms;
