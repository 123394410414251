import { Children, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { Card } from "components/ui/atoms/Card";
import useUser from "hooks/useUser";
import { Loader } from "components/ui/molecules/Loader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FormUtils from "utils/form-utils";
import Avatar from "react-avatar";
import { FaTrash } from "react-icons/fa";
import InputFile from "components/ui/atoms/input-files";
import { Input } from "components/ui/atoms/Input";
import { TextArea } from "components/ui/atoms/TextArea";
import { ButtonDanger } from "components/ui/atoms/ButtonDanger";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import AccountService from "services/account-service";
import { useOutletContext } from "react-router-dom";
import { useSwal } from "hooks/useSwal";

export const CompanyGeneralInformation = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);

  const { confirm } = useSwal();

  const { investor, isChildren, reloadUserInfo } = useOutletContext();

  const { register, handleSubmit, trigger, errors, setValue, getValues } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        company_name: Yup.string().required(t("required_field")),
        company_description: Yup.string().nullable(),
        logo: Yup.string().nullable(),
        investor: Yup.string().default(investor?._id),
      }),
      { abortEarly: false },
    ),
    criteriaMode: "all",
    reValidateMode: "all",
    mode: "onChange",
  });

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, config);
    trigger(name);
  };

  const onSelectLogo = async(file) => {
    const fileSizeMb = (file?.size || 0) / 1024 / 1024;

    if (fileSizeMb > 5) {
      addToast(t("error_file_size", { max_size: 5 }), {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }

    setLoading(true);

    _setValue("logo", file ? await FormUtils.fileToBase64(file) : null, { shouldValidate: true });

    setTimeout(() => setLoading(false), 500);
  };

  const updateCompanyInfo = (values) => {
    setLoading(true);
    AccountService.updateCompanyInfo(values)
      .then(() => reloadUserInfo())
      .then(() => {
        addToast(t("company_info_updated_successfully"), {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        addToast(
          error.response && error.response.data.msg_key
            ? t(error.response.data.msg_key)
            : t("error_occurred_updating_company_info"),
          {
            appearance: "error",
            autoDismiss: true,
          },
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteCompany = async() => {
    const ok = await confirm({ text: t("delete_deal_description") });

    if (ok) {
      try {
        setLoading(true);
        await AccountService.deleteInvestorChildren({ children: investor._id });
        reloadUserInfo(true);
        addToast(t("company_children_deleted_successfully"), {
          appearance: "success",
          autoDismiss: true,
        });
      } catch (e) {
        addToast(t("company_children_deleted_error"), {
          appearance: "error",
          autoDismiss: true,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const onInvalid = () => {
    addToast(t("form_field_error"), { appearance: "error", autoDismiss: true });
  };

  useEffect(() => {
    register("logo");
  }, [register]);

  useEffect(() => {
    if (investor) {
      _setValue("company_name", investor.company_name);
      _setValue("company_description", investor.company_description);
      _setValue("logo", investor.logo, { shouldValidate: true });
    }
  }, [investor]);

  return (
    <>
      <div className="mt-6">
        <Card>
          <div className="flex flex-row">
            <div className="w-1/3 lg:w-1/6">
              {getValues().logo ? (
                <>
                  <div className="text-xs font-medium text-black">{t("company_logo")}</div>
                  <div className="flex flex-col justify-center w-32 mt-2">
                    <Avatar
                      src={getValues().logo}
                      size="100"
                      round={false}
                      color="#e0e6f2"
                      fgColor="#4d70b3"
                      className="object-contain mx-auto my-0"
                    />
                    <ButtonDanger
                      type="button"
                      iconComponent={<FaTrash className="inline-block mr-2" />}
                      text={t("remove")}
                      onClick={() => onSelectLogo(null)}
                    />
                  </div>
                </>
              ) : (
                <div className="w-40">
                  <InputFile
                    label={t("company_logo_label")}
                    placeholder={t("company_logo_placeholder")}
                    error={errors.logo}
                    setSelectedFile={(file) => onSelectLogo(file)}
                    accept="image/*"
                    height="120px"
                    padding="18px"
                  />
                </div>
              )}
            </div>
            <div className="flex items-center w-2/3 mt-4 lg:w-5/6">
              <form
                className="flex flex-col w-full"
                onSubmit={handleSubmit(updateCompanyInfo, onInvalid)}
              >
                <div className="grid grid-cols-2">
                  <Input
                    reference={register}
                    name="company_name"
                    placeholder={t("company_name_placeholder")}
                    label={t("company_name_label")}
                    required={true}
                    error={errors.company_name}
                  />
                </div>
                <div className="mb-6">
                  <TextArea
                    reference={register}
                    name="company_description"
                    error={errors.company_description}
                    label={t("company_description_label")}
                    maxLength={200}
                    placeholder={t("company_description_placeholder")}
                  />
                </div>
                <div className="flex justify-end gap-2">
                  {isChildren && (<ButtonDanger width={"40"} text={t("delete")} type={"button"} onClick={deleteCompany} />)}
                  <ButtonMain width={"40"} text={t("save")} type={"submit"} />
                </div>
              </form>
            </div>
          </div>
        </Card>
      </div>
      {loading && <Loader />}
    </>
  );
};
