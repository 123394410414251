export function getPathSegment(url, position) {
  // Parse the URL and extract the pathname
  const pathname = new URL(url).pathname;

  // Split the pathname into segments
  const segments = pathname.split("/").filter(Boolean);

  // Return the segment at the specified position (1-based index)
  return segments[position - 1] || null;
}
