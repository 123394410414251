import { useState, useEffect } from "react";
import { FaComment, FaMinus, FaWindowMinimize } from "react-icons/fa";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const NucliaChat = () => {
  const { t } = useTranslation();

  const store = useSelector((state) => state.context);

  const [isVisible, setIsVisible] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    // Set the global callback for setting data
    // window.setDataCallback = setData

    // Cleanup on unmount
    return () => {
      window.setDataCallback = null;
    };
  }, []);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const generateReport = () => {
    console.log(data);
  };

  return (
    <div className="fixed bottom-5 right-5 flex z-30" style={{ maxWidth: "50%" }}>
      {isVisible && (
        <div className="bg-white w-full" style={{ height: "70%", border: "1px solid #f8f8f9" }}>
          <div className="bg-blue-500 text-white p-2 rounded-t z-40 mb-4 flex justify-between items-center">
            <p>{t("advanced_search")}</p>
            <FaMinus size={20} role="button" onClick={toggleVisibility} />
          </div>
          <div className="p-2 flex flex-col gap-y-2 overflow-auto" style={{ maxHeight: "60vh" }}>
            <div className="flex justify-between">
              <nuclia-search-bar
                knowledgebox="8ef5c196-3143-4aae-8f1c-f44bfc8412e5"
                zone="europe-1"
                max_tokens="50"
                features="answers,suggestions,relations,hideLogo"
                query_prepend={store.name ? `I'm currently in the startup ${store.name}` : ""}
                generativemodel="chatgpt4o"
              ></nuclia-search-bar>
              {data && <ButtonMain text={"create report"} onClick={generateReport} />}
            </div>
            <div className="overflow-auto flex-1">
              <nuclia-search-results></nuclia-search-results>
            </div>
          </div>
        </div>
      )}
      {!isVisible && (
        <div className="bg-blue-500 text-white p-2 rounded z-40">
          <FaComment size={30} role="button" onClick={toggleVisibility} />
        </div>
      )}
    </div>
  );
};

export default NucliaChat;
