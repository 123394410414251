import { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { Trans, useTranslation } from "react-i18next";
import { Loader } from "components/ui/molecules/Loader";
import { usePagination } from "hooks/usePagination";
import { DealflowService } from "services/dealflow-service";
import { IRMSuggestedStartup } from "./IRMSuggestedStartup";
import { ShareDealSection } from "components/ui/molecules/ShareDealSection";
import { useParams } from "react-router-dom";
import { Pagination } from "components/ui/atoms/Pagination";
import { useSwal } from "hooks/useSwal";
import { DealflowView } from "components/dealflow/DealflowView";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import { DealResumeSection } from "components/dealflow/DealResumeSection";
import useUser from "hooks/useUser";
import SupportService from "services/support-service";
import ContactsService from "services/contacts-service";
import DocumentsService from "services/documents-service";

const IRMSuggestedStartups = ({ thesis, contact, getContact }) => {
  const { t } = useTranslation();
  const [dealflow, setDealflow] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dealToShare, setDealToShare] = useState(null);
  const [dealToResume, setDealToResume] = useState(null);
  const { user, reloadUserInfo } = useUser();

  const { confirm } = useSwal();

  const { addToast } = useToasts();

  const { investorId } = useParams();

  const getSuggestedStartups = () => {
    setLoading(true);
    const query = {
      rowsPerPage,
      page,
    };

    return ContactsService.getSuggestedStartups(investorId, query)
      .then((result) => {
        const { dealflow, pagination } = result.data.data;
        setDealflow(dealflow);
        setTotalPages(pagination.pages);
      })
      .catch(() => {
        addToast(t("error_occurred_retrieving_suggested_startups"), {
          appearance: "error",
          autoDismiss: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { setTotalPages, totalPages, setPage, page, rowsPerPage, setRowsPerPage } =
    usePagination(12);

  useEffect(() => {
    getSuggestedStartups();
  }, [page, rowsPerPage]);

  const shareDeal = async (section, data) => {
    confirm({
      text: `${t("confirm_share_dealflow_first_part")} ${contact.contact_email} ${t("confirm_share_dealflow_second_part")} ${t(contact.language)}`,
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await DealflowService.shareDealflow(dealToShare._id, {
            section,
            ...data,
            contacts: [
              {
                _id: investorId,
                email: contact.contact_email,
                language: contact.language,
                name: contact.contact || contact.company,
              },
            ],
          });
          addToast(t("deal_shared_successfully"), { appearance: "success", autoDismiss: true });
          await getContact();
          setDealToShare(null);
          reloadUserInfo();
        } catch (error) {
          console.error(error);
          addToast(t("error_occurred_sharing_deal"), { appearance: "error", autoDismiss: true });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const downloadDocument = async (documentId) => {
    setLoading(true);
    DocumentsService.getDocument(documentId)
      .then((response) => {
        if (response?.data?.data?.url) {
          window.open(response.data.data.url, "_blank");
        }
      })
      .catch((error) => {
        addToast(
          error?.response?.data?.msg_key
            ? t(error.response.data.msg_key)
            : t("error_occurred_downloading_document"),
          {
            appearance: "error",
            autoDismiss: true,
          },
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {dealToResume && (
        <DealResumeSection
          deal={dealToResume || {}}
          onClose={() => {
            setDealToResume(null);
          }}
          section={"contacts"}
          downloadDocument={downloadDocument}
        />
      )}
      {loading && <Loader />}
      {dealToShare && (
        <ShareDealSection
          onClose={() => {
            setDealToShare(null);
          }}
          initialValues={user.share_dealflow_preferences || {}}
          onSubmit={shareDeal}
        />
      )}
      {dealflow.length === 0 && (
        <div className="my-6">
          <NoDataInfo textOne={t("suggested_startups_not_found")} />
        </div>
      )}
      {dealflow.length !== 0 && (
        <div className="grid grid-cols-1 lg:grid-cols-2  2xl:grid-cols-3 gap-4">
          {dealflow.map((dealflow) => (
            <IRMSuggestedStartup
              contact={contact}
              key={dealflow._id}
              dealflow={dealflow}
              setDealToShare={setDealToShare}
              setDealToResume={setDealToResume}
              investmentThesis={thesis}
            />
          ))}
        </div>
      )}
      {dealflow.length !== 0 && (
        <Pagination
          paginateOptions={[12, 24, 36, 48, 60]}
          currentPage={page}
          setCurrentPage={(page) => setPage(page)}
          perPage={rowsPerPage}
          pages={totalPages}
          setPerPage={(value) => setRowsPerPage(value[0].id)}
        />
      )}
    </>
  );
};

export default IRMSuggestedStartups;
