import {
  FaPhoneAlt,
  FaCircle,
  FaEnvelope,
  FaCalendarDay,
  FaTasks,
  FaAlignLeft,
  FaMinus,
  FaFileImage,
  FaFilePdf,
  FaExternalLinkAlt,
  FaDownload,
  FaFileExcel,
  FaFile,
  FaObjectGroup,
  FaImages,
  FaSearch,
} from "react-icons/fa";
import { IoMdSwitch } from "react-icons/io";
import { AiOutlineNumber, AiOutlineLink } from "react-icons/ai";
import { CgPlayListCheck } from "react-icons/cg";
import { RiPageSeparator } from "react-icons/ri";
import { BsCurrencyDollar } from "react-icons/bs";
import { TbTrafficLights } from "react-icons/tb";
import TranslateUtils from "./translate-utils";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";

export const QuestionsUtils = {
  getIcon: (type, small = false) => {
    const wrapperClass = `${
      small ? "w-6 h-6 rounded-lg text-xs" : "w-14 h-14 rounded-xl"
    } bg-main flex items-center justify-center`;

    switch (type) {
      case "questions_group":
        return (
          <div className={wrapperClass}>
            <FaObjectGroup className={`inline-block text-white ${small ? "text-sm" : ""}`} />
          </div>
        );
      case "separator":
        return (
          <div className={wrapperClass}>
            <RiPageSeparator className={`inline-block text-white ${small ? "text-sm" : ""}`} />
          </div>
        );
      case "short_answer":
        return (
          <div className={wrapperClass}>
            <FaMinus className={`inline-block text-white ${small ? "text-sm" : ""}`} />
          </div>
        );
      case "long_answer":
        return (
          <div className={wrapperClass}>
            {" "}
            <FaAlignLeft className={`inline-block text-white ${small ? "text-sm" : ""}`} />
          </div>
        );
      case "select":
        return (
          <div className={wrapperClass}>
            <CgPlayListCheck
              className={`inline-block text-white ${small ? "text-sm" : "h-8 w-8"}`}
            />
          </div>
        );
      case "boolean":
        return (
          <div className={wrapperClass}>
            <IoMdSwitch className={`inline-block text-white ${small ? "text-sm" : "h-7 w-7"}`} />
          </div>
        );
      case "traffic_light":
        return (
          <div className={wrapperClass}>
            <TbTrafficLights
              className={`inline-block text-white ${small ? "text-sm" : "h-7 w-7"}`}
            />
          </div>
        );
      case "number":
        return (
          <div className={wrapperClass}>
            <AiOutlineNumber
              className={`inline-block text-white ${small ? "text-sm" : "h-7 w-7"}`}
            />
          </div>
        );
      case "multiselect":
        return (
          <div className={wrapperClass}>
            <FaTasks className={`inline-block ${small ? "text-sm" : "h-7 w-7"} text-white`} />
          </div>
        );
      case "date":
        return (
          <div className={wrapperClass}>
            <FaCalendarDay className={`inline-block ${small ? "text-sm" : "h-5 w-5"} text-white`} />
          </div>
        );
      case "url":
        return (
          <div className={wrapperClass}>
            <AiOutlineLink className={`inline-block ${small ? "text-sm" : "h-7 w-7"} text-white`} />
          </div>
        );
      case "phone":
        return (
          <div className={wrapperClass}>
            <FaPhoneAlt className={`inline-block ${small ? "text-sm" : "h-5 w-5"} text-white`} />
          </div>
        );
      case "likert_scale":
        return (
          <div className={wrapperClass}>
            <FaCircle className={`inline-block text-white ${small ? "text-xs" : "h-5 w-5"}`} />
          </div>
        );
      case "email":
        return (
          <div className={wrapperClass}>
            <FaEnvelope className={`inline-block text-white ${small ? "text-xs" : "h-5 w-5"}`} />
          </div>
        );
      case "file_image":
        return (
          <div className={wrapperClass}>
            <FaFileImage className={`inline-block text-white ${small ? "text-xs" : "h-5 w-5"}`} />
          </div>
        );
      case "file_pdf":
        return (
          <div className={wrapperClass}>
            <FaFilePdf className={`inline-block text-white ${small ? "text-xs" : "h-5 w-5"}`} />
          </div>
        );
      case "file_excel":
        return (
          <div className={wrapperClass}>
            <FaFileExcel className={`inline-block text-white ${small ? "text-xs" : "h-5 w-5"}`} />
          </div>
        );
      case "file":
        return (
          <div className={wrapperClass}>
            <FaFile className={`inline-block text-white ${small ? "text-xs" : "h-5 w-5"}`} />
          </div>
        );
      case "image_selector":
        return (
          <div className={wrapperClass}>
            <FaImages className={`inline-block text-white ${small ? "text-xs" : "h-5 w-5"}`} />
          </div>
        );
      case "financial":
        return (
          <div className={wrapperClass}>
            <BsCurrencyDollar
              className={`inline-block text-white ${small ? "text-xs" : "h-5 w-5"}`}
            />
          </div>
        );
      case "autocomplete":
        return (
          <div className={wrapperClass}>
            <FaSearch className={`inline-block text-white ${small ? "text-xs" : "h-5 w-5"}`} />
          </div>
        );
    }
  },

  isRedFlag: (question, redFlags, answer) => {
    if (redFlags) {
      if (redFlags[question._id]) {
        if (question.type === "number") {
          if (redFlags[question._id].min !== null) {
            if (answer === null) {
              return true;
            }
            if (answer < redFlags[question._id].min) {
              return true;
            }
          }
          if (redFlags[question._id].max !== null) {
            if (answer === null) {
              return true;
            }
            if (answer > redFlags[question._id].min) {
              return true;
            }
          }
        } else if (question.type === "select" || question.type === "multiselect") {
          return redFlags[question._id].includes(answer);
        } else if (question.type === "boolean") {
          return answer
            ? redFlags[question._id].includes("yes")
            : redFlags[question._id].includes("no");
        } else if (question.type === "likert_scale") {
          return redFlags[question._id].includes(answer);
        }
      }
    }

    return false;
  },

  getUnparsedAnswer: (questionAnswer, groupedIndex = null) => {
    if (groupedIndex !== null) {
      questionAnswer = questionAnswer.split("|__|")[groupedIndex];
    }

    if (
      [null, undefined, ""].includes(questionAnswer) ||
      (Array.isArray(questionAnswer) && !questionAnswer.length)
    ) {
      return null;
    }

    return Array.isArray(questionAnswer)
      ? questionAnswer
      : questionAnswer?.$numberDecimal
        ? parseFloat(questionAnswer.$numberDecimal)
        : `${questionAnswer}`.trim();
  },

  getAnswer: (
    question,
    questionAnswer,
    t,
    downloadDocument,
    lang,
    groupedIndex = null,
    limited = false,
    redFlags,
  ) => {
    let redFlag = null;

    if (groupedIndex !== null) {
      questionAnswer = questionAnswer.split("|__|")[groupedIndex];
    }

    if (
      [null, undefined, ""].includes(questionAnswer) ||
      (Array.isArray(questionAnswer) && !questionAnswer.length)
    ) {
      return "-";
    }

    const answer = Array.isArray(questionAnswer)
      ? questionAnswer
      : questionAnswer?.$numberDecimal
        ? parseFloat(questionAnswer.$numberDecimal)
        : `${questionAnswer}`.trim();

    if (question.type === "boolean") {
      redFlag = QuestionsUtils.isRedFlag(question, redFlags, questionAnswer);
      return (
        <span className={`${redFlag && "text-red font-semibold"}`}>
          {questionAnswer ? t("yes") : t("no")}
        </span>
      );
    }

    if (question.type === "url" || question.type === "email") {
      return (
        <a
          href={`${question.type === "email" ? "mailto:" : ""}${answer}`}
          target="_blank"
          className="font-normal text-black hover:underline"
          rel="noreferrer"
        >
          {answer}&nbsp;
          <FaExternalLinkAlt className={"inline text-main"} />
        </a>
      );
    }

    if (question.type === "long_answer") {
      return (
        <pre
          style={{
            whiteSpace: "pre-wrap",
            overflow: "hidden",
            display: "-webkit-box",
            WebkitLineClamp: 4,
            WebkitBoxOrient: "vertical",
            resize: "both",
          }}
          className="font-sans"
        >
          {answer}
        </pre>
      );
    }

    if (question.type === "select") {
      redFlag = QuestionsUtils.isRedFlag(
        question,
        redFlags,
        answer !== null ? parseInt(answer) : null,
      );

      return (
        <div className={`${redFlag && "text-red font-semibold"}`}>
          {TranslateUtils.getTextByLang(lang, question.options[parseInt(answer)]).length > 30
            ? `${TranslateUtils.getTextByLang(lang, question.options[parseInt(answer)]).substring(0, 30)}...`
            : TranslateUtils.getTextByLang(lang, question.options[parseInt(answer)])}
        </div>
      );
    }

    if (question.type === "multiselect") {
      return answer.split(",").map((answ, index) => {
        redFlag = QuestionsUtils.isRedFlag(
          question,
          redFlags,
          answ !== null ? parseInt(answ) : null,
        );
        return (
          <div key={index} className={`${redFlag && "text-red font-semibold"}`}>
            {TranslateUtils.getTextByLang(lang, question.options[parseInt(answ)]).length > 30
              ? `${TranslateUtils.getTextByLang(lang, question.options[parseInt(answ)]).substring(0, 30)}...`
              : TranslateUtils.getTextByLang(lang, question.options[parseInt(answ)])}
          </div>
        );
      });
    }

    if (question.type === "likert_scale") {
      redFlag = QuestionsUtils.isRedFlag(question, redFlags, answer ? parseInt(answer - 1) : null);
      return (
        <div className={`${redFlag && "text-red font-semibold"}`}>
          {TranslateUtils.getTextByLang(lang, question.options[answer - 1])}
        </div>
      );
    }

    if (question.type === "file_image") {
      return (
        <div>
          <ButtonSecondary
            className="w-auto"
            iconComponent={<FaDownload className="inline-block mr-2" />}
            text={`${t("download")} Image`}
            onClick={() => downloadDocument(answer.split("-|-")[0])}
          />
        </div>
      );
    }

    if (question.type === "file_pdf") {
      return (
        <div>
          <ButtonSecondary
            className="w-auto"
            iconComponent={<FaDownload className="inline-block mr-2" />}
            text={`${t("download")} PDF`}
            onClick={() => downloadDocument(answer.split("-|-")[0])}
          />
        </div>
      );
    }

    if (question.type === "file_excel") {
      return (
        <div>
          <ButtonSecondary
            className="w-auto"
            iconComponent={<FaDownload className="inline-block mr-2" />}
            text={`${t("download")} Excel`}
            onClick={() => downloadDocument(answer.split("-|-")[0])}
          />
        </div>
      );
    }

    if (question.type === "file") {
      return (
        <div>
          <ButtonSecondary
            className="w-auto"
            iconComponent={<FaDownload className="inline-block mr-2" />}
            text={`${t("download")} ${t("file")}`}
            onClick={() => downloadDocument(answer.split("-|-")[0])}
          />
        </div>
      );
    }

    if (question.type === "image_selector") {
      return (
        <div className="flex">
          {answer.split(",").map((opt, index) => (
            <img
              key={index}
              src={question.options[parseInt(opt)]}
              style={{ height: "100px", marginRight: 6 }}
            />
          ))}
        </div>
      );
    }

    if (question.type === "number") {
      redFlag = QuestionsUtils.isRedFlag(question, redFlags, answer ? parseInt(answer) : null);
      return <span className={`${redFlag && "text-red font-semibold"}`}>{answer}</span>;
    }

    if (question.type === "financial") {
      // redFlag = QuestionsUtils.isRedFlag(question, redFlags, answer ? parseInt(answer) : null)
      redFlag = false;
      return (
        <span className={`${redFlag && "text-red font-semibold"}`}>
          {`${answer.split("-")[0]}${t(answer.split("-")[1])}`}
        </span>
      );
    }

    return limited ? (answer.length > 30 ? `${answer.substring(0, 30)}...` : answer) : answer;
  },

  checkIfRedFlag: (question, questionAnswer, groupedIndex = null, redFlags) => {
    if (groupedIndex !== null) {
      questionAnswer = questionAnswer.split("|__|")[groupedIndex];
    }

    if (
      [null, undefined, ""].includes(questionAnswer) ||
      (Array.isArray(questionAnswer) && !questionAnswer.length)
    ) {
      return false;
    }

    const answer = Array.isArray(questionAnswer)
      ? questionAnswer
      : questionAnswer?.$numberDecimal
        ? parseFloat(questionAnswer.$numberDecimal)
        : `${questionAnswer}`.trim();

    if (question.type === "boolean") {
      return QuestionsUtils.isRedFlag(question, redFlags, questionAnswer);
    }

    if (question.type === "select") {
      return QuestionsUtils.isRedFlag(
        question,
        redFlags,
        answer !== null ? parseInt(answer) : null,
      );
    }

    if (question.type === "multiselect") {
      let answerIsRedFlag = false;
      answer.split(",").every((answ) => {
        if (answ === null) {
          return true;
        } else {
          if (QuestionsUtils.isRedFlag(question, redFlags, parseInt(answ))) {
            answerIsRedFlag = true;
            return false;
          } else {
            return true;
          }
        }
      });

      return answerIsRedFlag;
    }

    if (question.type === "likert_scale") {
      return QuestionsUtils.isRedFlag(question, redFlags, answer ? parseInt(answer - 1) : null);
    }

    if (question.type === "number") {
      return QuestionsUtils.isRedFlag(question, redFlags, answer ? parseInt(answer) : null);
    }
  },
};
