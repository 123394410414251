import { useTranslation } from "react-i18next";
import { Input } from "components/ui/atoms/Input";
import TranslateUtils from "utils/translate-utils";
import { QuestionsUtils } from "utils/questions-utils";
import { Switch } from "components/ui/atoms/Switch";
import Select from "components/ui/atoms/Select";
import ReactTooltip from "react-tooltip";
import { useEffect } from "react";

export const RedFlag = ({
  redFlags,
  setRedFlags,
  lang,
  question,
  redFlagsEnabled,
  setRedFlagsEnabled,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const getSelectInitialValues = (value, question) => {
    return value
      ? value
          .filter((answ) => answ !== null)
          .map((answ) => ({
            id: answ,
            value: TranslateUtils.getTextByLang(lang, question.options[answ]),
          }))
      : [];
  };

  const getBooleanSelectInitialValues = (value, question) => {
    return value
      ? value
          .filter((answ) => answ !== null)
          .map((answ) => ({
            id: answ,
            value: t(value[0]),
          }))
      : [];
  };

  const renderSelectQuestion = (q) => {
    return (
      <div className="mt-2">
        <Select
          id={q._id}
          name={q._id}
          key={q._id}
          multiSelect={true}
          label={
            getSelectInitialValues(redFlags[q._id], q)
              ? getSelectInitialValues(redFlags[q._id], q)
                  .map((v) => v.value)
                  .join(", ")
              : t("select_answer")
          }
          isClearable={false}
          placeholder={t("choose_multiple_options")}
          onSelect={(opts) => {
            setRedFlags({ ...redFlags, [q._id]: opts.length ? opts.map((o) => o.id) : null });
          }}
          initialValues={getSelectInitialValues(redFlags[q._id], q)}
          items={q.options.map((option, index) => ({
            id: index,
            value: TranslateUtils.getTextByLang(lang, option),
          }))}
        />
      </div>
    );
  };

  const renderNumberQuestion = (q) => {
    return (
      <div className="mt-2">
        <Input
          label={t("minimum_value_red_flag")}
          id={`${q._id}_min`}
          key={`${q._id}_min`}
          value={redFlags?.[q._id]?.min || ""}
          placeholder={t("type_answer")}
          onChange={(e) => {
            if (redFlags[q._id]) {
              setRedFlags({ ...redFlags, [q._id]: { ...redFlags[q._id], min: e.target.value } });
            } else {
              setRedFlags({ ...redFlags, [q._id]: { min: e.target.value } });
            }
          }}
          type={q.type}
        />
        <Input
          label={t("maximum_value_red_flag")}
          id={`${q._id}_max`}
          key={`${q._id}_max`}
          value={redFlags?.[q._id]?.max || ""}
          placeholder={t("type_answer")}
          onChange={(e) => {
            if (redFlags[q._id]) {
              setRedFlags({ ...redFlags, [q._id]: { ...redFlags[q._id], max: e.target.value } });
            } else {
              setRedFlags({ ...redFlags, [q._id]: { max: e.target.value } });
            }
          }}
          type={q.type}
        />
      </div>
    );
  };

  const renderBooleanQuestion = (q) => {
    return (
      <div className="mt-2">
        <Select
          id={q._id}
          name={q._id}
          key={q._id}
          multiSelect={false}
          label={
            getBooleanSelectInitialValues(redFlags[q._id], q)
              ? getBooleanSelectInitialValues(redFlags[q._id], q)
                  .map((v) => v.value)
                  .join(", ")
              : t("select_answer")
          }
          isClearable={false}
          placeholder={t("select_answer")}
          onSelect={(opts) => {
            setRedFlags({ ...redFlags, [q._id]: opts.length ? opts.map((o) => o.id) : null });
          }}
          initialValues={getBooleanSelectInitialValues(redFlags[q._id], {
            options: [
              { id: "yes", value: t("yes") },
              { id: "no", value: t("no") },
            ],
          })}
          items={[
            { id: "yes", value: t("yes") },
            { id: "no", value: t("no") },
          ]}
        />
      </div>
    );
  };

  const renderLikertQuestion = (q) => {
    return (
      <div className="mt-2">
        <Select
          id={q._id}
          name={q._id}
          key={q._id}
          multiSelect={true}
          label={
            getSelectInitialValues(redFlags[q._id], q)
              ? getSelectInitialValues(redFlags[q._id], q)
                  .map((v) => v.value)
                  .join(", ")
              : t("select_answer")
          }
          isClearable={false}
          placeholder={t("choose_multiple_options")}
          onSelect={(opts) => {
            setRedFlags({ ...redFlags, [q._id]: opts.length ? opts.map((o) => o.id) : null });
          }}
          initialValues={getSelectInitialValues(redFlags[q._id], q)}
          items={q.options.map((option, index) => ({
            id: index,
            value: TranslateUtils.getTextByLang(lang, option),
          }))}
        />
      </div>
    );
  };

  return (
    <div className="p-2 shadow-soft-white rounded-lg bg-white my-4">
      <div className="text-xs flex justify-between">
        <div className="flex">
          <div>{QuestionsUtils.getIcon(question.type, true)}</div>
          <div className="justify-center mx-2 flex flex-col">
            <div className="font-semibold">{question.title.es}</div>
            {redFlagsEnabled[question._id] && (
              <div>
                <div className="text-xxs">{t("red_flag_enabled")}</div>
              </div>
            )}
          </div>
        </div>
        <div
          className="items-center justify-center flex"
          data-tip={redFlagsEnabled[question._id] ? t("disable_red_flag") : t("enable_red_flag")}
        >
          <Switch
            checked={!!redFlagsEnabled[question._id]}
            onChange={(checked) =>
              setRedFlagsEnabled({ ...redFlagsEnabled, [question._id]: checked })
            }
          />
        </div>
      </div>
      {redFlagsEnabled[question._id] && (
        <div>
          <div>
            {(question.type === "select" || question.type === "multiselect") &&
              renderSelectQuestion(question)}
            {question.type === "number" && renderNumberQuestion(question)}
            {question.type === "boolean" && renderBooleanQuestion(question)}
            {question.type === "likert_scale" && renderLikertQuestion(question)}
          </div>
        </div>
      )}
    </div>
  );
};
