import { useEffect, useState } from "react";
import UserOperations from "redux/user/user-operations";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Loader } from "components/ui/molecules/Loader";
import { Card } from "components/ui/atoms/Card";
import useUser from "hooks/useUser";
import Config from "config";
import { FaGripHorizontal, FaListAlt } from "react-icons/fa";
import AccountService from "services/account-service";
import { Switch } from "components/ui/atoms/Switch";
import { ButtonMain } from "components/ui/atoms/ButtonMain";

const StartupProfile = () => {
  const { t } = useTranslation();

  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { user, reloadUserInfo } = useUser();

  const setUserInfo = (data) => dispatch(UserOperations.setUserInfo({ data }));
  const [loading, setLoading] = useState(false);

  const [selectedViewMode, setSelectedViewMode] = useState(
    user.preferences.dealflow_visibility_mode || "grid",
  );

  const [annotations, setAnnotations] = useState(
    user?.preferences?.startup_profile?.annotations || false,
  );
  const [updatingAnnotationSwitch, setUpdatingAnnotationSwitch] = useState(false);

  const [customFields, setcustomFields] = useState(
    user?.preferences?.startup_profile?.custom_fields || false,
  );
  const [updatingCustomFieldSwitch, setUpdatingCustomFieldSwitch] = useState(false);

  const [team, setTeam] = useState(user?.preferences?.startup_profile?.team || false);
  const [updatingTeamSwitch, setUpdatingTeamSwitch] = useState(false);

  const [investments, setInvestments] = useState(
    user?.preferences?.startup_profile?.investments || false,
  );
  const [updatingInvestmentsSwitch, setUpdatingInvestmentsSwitch] = useState(false);

  const [captable, setCaptable] = useState(user?.preferences?.startup_profile?.captable || false);
  const [updatingCaptableSwitch, setUpdatingCaptableSwitch] = useState(false);

  const [competitors, setCompetitors] = useState(
    user?.preferences?.startup_profile?.competitors || false,
  );
  const [updatingCompetitorsSwitch, setUpdatingCompetitorsSwitch] = useState(false);

  const [tracking, setTracking] = useState(user?.preferences?.startup_profile?.tracking || false);
  const [updatingTracking, setUpdatingTracking] = useState(false);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const onSubmit = async () => {
    try {
      setLoading(true);
      await AccountService.updateStartupProfile({
        annotations,
        custom_fields: customFields,
        team,
        investments,
        captable,
        competitors,
        tracking,
      });
      reloadUserInfo();
      addToast(t("settings_updated_successfully"), {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      if (error.response) {
        addToast(t("error_occurred_updating_settings"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Card wrapperClassName="my-4">
        <h3 className={"border-b font-medium text-main font-bold mb-4 border-main"}>
          {t("startup_profile")}
        </h3>
        <div className="mb-4">
          <small className="text-gray-dark font-light">
            {t("startup_profile_settings_description")}
          </small>
        </div>
        <div>
          <div className="flex flex-row justify-between border-b border-gray-lines mb-2 hover:bg-gray-100">
            <div className="w-full text-xs mt-2 mb-4">{t("annotations")}</div>
            <Switch
              checked={annotations}
              onChange={async (checked) => {
                if (!updatingAnnotationSwitch) {
                  setUpdatingAnnotationSwitch(true);
                  setAnnotations(!annotations);
                  await delay(100);
                  setUpdatingAnnotationSwitch(false);
                }
              }}
              text={annotations ? t("yes") : t("no")}
            />
          </div>
          <div className="flex flex-row justify-between border-b border-gray-lines mb-2">
            <div className="w-full text-xs mt-2 mb-4">{t("custom_fields")}</div>
            <Switch
              checked={customFields}
              onChange={async (checked) => {
                if (!updatingCustomFieldSwitch) {
                  setUpdatingCustomFieldSwitch(true);
                  setcustomFields(!customFields);
                  await delay(100);
                  setUpdatingCustomFieldSwitch(false);
                }
              }}
              text={customFields ? t("yes") : t("no")}
            />
          </div>
          <div className="flex flex-row justify-between border-b border-gray-lines mb-2">
            <div className="w-full text-xs mt-2 mb-4">{t("team")}</div>
            <Switch
              checked={team}
              onChange={async (checked) => {
                if (!updatingTeamSwitch) {
                  setUpdatingTeamSwitch(true);
                  setTeam(!team);
                  await delay(100);
                  setUpdatingTeamSwitch(false);
                }
              }}
              text={team ? t("yes") : t("no")}
            />
          </div>
          <div className="flex flex-row justify-between border-b border-gray-lines mb-2">
            <div className="w-full text-xs mt-2 mb-4">{t("investments")}</div>
            <Switch
              checked={investments}
              onChange={async (checked) => {
                if (!updatingInvestmentsSwitch) {
                  setUpdatingInvestmentsSwitch(true);
                  setInvestments(!investments);
                  await delay(100);
                  setUpdatingInvestmentsSwitch(false);
                }
              }}
              text={investments ? t("yes") : t("no")}
            />
          </div>
          <div className="flex flex-row justify-between border-b border-gray-lines mb-2">
            <div className="w-full text-xs mt-2 mb-4">{t("captable")}</div>
            <Switch
              checked={captable}
              onChange={async (checked) => {
                if (!updatingCaptableSwitch) {
                  setUpdatingCaptableSwitch(true);
                  setCaptable(!captable);
                  await delay(100);
                  setUpdatingCaptableSwitch(false);
                }
              }}
              text={captable ? t("yes") : t("no")}
            />
          </div>
          <div className="flex flex-row justify-between border-b border-gray-lines mb-2">
            <div className="w-full text-xs mt-2 mb-4">{t("competitors")}</div>
            <Switch
              checked={competitors}
              onChange={async (checked) => {
                if (!updatingCompetitorsSwitch) {
                  setUpdatingCompetitorsSwitch(true);
                  setCompetitors(!competitors);
                  await delay(100);
                  setUpdatingCompetitorsSwitch(false);
                }
              }}
              text={competitors ? t("yes") : t("no")}
            />
          </div>
          <div className="flex flex-row justify-between border-b border-gray-lines mb-2">
            <div className="w-full text-xs mt-2 mb-4">{t("tracking")}</div>
            <Switch
              checked={tracking}
              onChange={async (checked) => {
                if (!updatingTracking) {
                  setUpdatingTracking(true);
                  setTracking(!tracking);
                  await delay(100);
                  setUpdatingTracking(false);
                }
              }}
              text={tracking ? t("yes") : t("no")}
            />
          </div>
        </div>
        <div className="flex flex-row justify-end  mt-4">
          <ButtonMain onClick={onSubmit} text={t("save")} />
        </div>
      </Card>
      {loading && <Loader />}
    </>
  );
};

export default StartupProfile;
