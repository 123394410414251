import { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
import { SectionHeader } from "components/ui/molecules/SectionHeader";
import { PageTitle } from "components/ui/atoms/PageTitle";
import IRMInvestorGeneralInformation from "./IRMInvestorGeneralInformation";
import { InvestorInvestmentThesis } from "components/investment-thesis/InvestorInvestmentThesis";
import { useParams } from "react-router-dom";
import ContactsService from "services/contacts-service";
import { RoutesLinks } from "components/routes-links";
import { useNavigate } from "react-router-dom";
import { pick } from "lodash";
import NotesContainer from "components/ui/objects/NotesContainer";
import { useSwal } from "hooks/useSwal";
import useUser from "hooks/useUser";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { TbNotes } from "react-icons/tb";
import { FaPaperPlane } from "react-icons/fa";
import IRMSuggestedStartups from "./IRMSuggestedStartups";
import NotesService from "services/notes-service";

const IRMInvestor = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { investorId } = useParams();
const navigate = useNavigate();
  const { user, checkIfLPhasAccessToSection } = useUser();

  const handleRedirect = (url) => navigate(url);
  const [showNotesContainer, setShowNotesContainer] = useState(false);
  const { confirm, alert } = useSwal();

  const [loading, setLoading] = useState(true);
  const [notes, setNotes] = useState([]);
  const [section, setSection] = useState("general_information");
  const [thesis, setThesis] = useState({});
  const [selectedNote, setSelectedNote] = useState(null);

  const SECTIONS = ["general_information", "dealflow_preferences", "suggested_startups"];

  const [generalInformation, setGeneralInformation] = useState(null);

  const getIrmInvestor = async () => {
    ContactsService.getContact(investorId)
      .then((result) => {
        if (result.data.data) {
          setGeneralInformation(
            pick(result.data.data, [
              "contact_image",
              "contact_linkedin",
              "company",
              "company_logo",
              "web",
              "company_linkedin",
              "contact",
              "contact_email",
              "contact_phone_number",
              "investor_type",
              "shared_dealflow",
              "language",
            ]),
          );
          setThesis(result.data.data.investment_thesis);
        } else {
          handleRedirect(RoutesLinks.CONTACTS_LINK);
        }
      })
      .catch((error) =>
        addToast(t("error_occurred_retrieving_information"), {
          appearance: "error",
          autoDismiss: true,
        }),
      )
      .finally(() => setLoading(false));
  };

  const saveInvestor = async (values) => {
    confirm({}).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          await ContactsService.saveContact(investorId, values);
          await getIrmInvestor();
          addToast(t("investor_updated_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
        } catch (error) {
          console.error(error);
          addToast(t("error_occurred_updating_investor"));
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const deleteInvestor = () => {
    confirm({
      text: t("delete_contact_description"),
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await ContactsService.deleteContact(investorId);
          addToast(t("contact_deleted_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
          handleRedirect(RoutesLinks.CONTACTS_LINK);
        } catch (error) {
          console.error(error);
          addToast(
            error.response && error.response.data.msg_key
              ? t(error.response.data.msg_key)
              : t("error_occurred_deleting_contact"),
            {
              appearance: "error",
              autoDismiss: true,
            },
          );
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const COMPONENTS = {
    general_information: (
      <IRMInvestorGeneralInformation
        onDelete={deleteInvestor}
        onSubmit={(values) => {
          setGeneralInformation({ ...values, shared_dealflow: generalInformation.shared_dealflow });
          saveInvestor(values);
        }}
        generalInformation={generalInformation}
      />
    ),
    dealflow_preferences: (
      <InvestorInvestmentThesis
        thesis={thesis}
        irm={true}
        onSubmit={(values) =>
          saveInvestor({
            investment_thesis: {
              ...values,
              lead_investor:
                values.lead_investor?.length && values.lead_investor[0]
                  ? values.lead_investor[0]
                  : null,
              follow_on:
                values.follow_on?.length && values.follow_on[0] ? values.follow_on[0] : null,
            },
          })
        }
      />
    ),
    suggested_startups: (
      <IRMSuggestedStartups
        thesis={thesis}
        contact={generalInformation}
        getContact={getIrmInvestor}
      />
    ),
  };

  const getNotes = async () => {
    try {
      setLoading(true);
      const result = await NotesService.getNotes({ irm_investor: investorId });
      setNotes(result.data.data);
    } catch (error) {
      addToast(t("error_occurred_retrieving_notes"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const createNote = async (title, type, content) => {
    try {
      setLoading(true);
      await NotesService.createNote({
        user: user._id,
        title,
        content,
        type,
        irm_investor: investorId,
      });
      getNotes();
    } catch (error) {
      addToast(t("error_occurred_creating_note"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const updateNote = async (title, content) => {
    try {
      setLoading(true);
      await NotesService.updateNote(selectedNote._id, { title, content });
      setSelectedNote(null);
      getNotes();
    } catch (error) {
      addToast(t("error_occurred_updating_note"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteNote = async (noteId) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await NotesService.deleteNote(noteId);
          getNotes();
        } catch (error) {
          addToast(t("error_occurred_deleting_note"), {
            appearance: "error",
            autoDismiss: true,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const getNote = async (noteId) => {
    try {
      setLoading(true);
      const result = await NotesService.getNote(noteId);
      setSelectedNote(result.data.data);
    } catch (error) {
      addToast(t("error_occurred_retrieving_note"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!user.lp || (user.lp && checkIfLPhasAccessToSection("contacts"))) {
      getIrmInvestor();
      getNotes();
    } else {
      navigate(RoutesLinks.DASHBOARD_LINK);
      addToast(t("forbidden_access"), { appearance: "error", autoDismiss: true });
    }
  }, []);

  const requestDealflowPreferences = async () => {
    confirm({
      text: `${t("confirm_request_dealflow_preferences_first_part")} ${generalInformation.contact_email} ${t("confirm_request_dealflow_preferences_second_part")} ${t(generalInformation.language)}`,
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await ContactsService.requestDealflowPreferences(investorId);
        } catch (error) {
          console.error(error);
          addToast(t("error_occurred_requesting_dealflow_preferences"), {
            appearance: "error",
            autoDismiss: true,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  return (
    <>
      <div className="flex">
        <div className="flex-1">
          <PageTitle
            title={
              generalInformation
                ? generalInformation.company || generalInformation.contact
                : t("loading")
            }
            removeMargin={true}
            showBack={true}
            onBackClick={() => handleRedirect(RoutesLinks.CONTACTS_LINK)}
          />
        </div>
        {generalInformation && (
          <div className="flex">
            {!user.lp && (
              <ButtonSecondary
                iconComponent={<FaPaperPlane className="inline-block mr-3 w-4 h-4" />}
                text={t("request_dealflow_preferences")}
                onClick={
                  generalInformation.contact_email && generalInformation.language
                    ? () => requestDealflowPreferences()
                    : () =>
                        alert({
                          text: t("email_and_language_required_to_request_dealflow_preferences"),
                        })
                }
                marginRight={2}
              />
            )}
            {(!user.lp || (user.lp && checkIfLPhasAccessToSection("annotations"))) && (
              <ButtonSecondary
                iconComponent={<TbNotes className="inline-block mr-3 w-4 h-4" />}
                text={t("notes")}
                onClick={() => setShowNotesContainer(true)}
              />
            )}
          </div>
        )}
      </div>

      <div className="my-6">
        <SectionHeader
          section={section}
          setSection={setSection}
          sectionKeys={SECTIONS}
          sectionTitles={SECTIONS.map((s) => t(s))}
        />
      </div>

      {showNotesContainer && (
        <NotesContainer
          zIndex="20"
          listTitle={t("notes")}
          setFiltersButtons={false}
          setShowModal={setShowNotesContainer}
          notes={notes}
          createNote={createNote}
          updateNote={updateNote}
          deleteNote={deleteNote}
          getNote={getNote}
          selectedNote={selectedNote}
          setSelectedNote={setSelectedNote}
          onClose={() => {
            setSelectedNote(null);
            setShowNotesContainer(false);
          }}
        />
      )}

      <div className="my-6">{COMPONENTS[section]}</div>
    </>
  );
};

export default IRMInvestor;
