import { useSelector } from "react-redux";
import currencies from "assets/json/currencies.json";

/**
 * Gets the currency from investor's preferences
 * @returns {string} currency
 */
const useCurrency = () => {
  const { investor } = useSelector((state) => state.user);

  const currency = investor.preferences.currency
    ? `${currencies[investor.preferences.currency] ?? "€"}`
    : "€";

  return {
    currency,
  };
};

export default useCurrency;
