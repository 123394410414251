import { Trans, useTranslation } from "react-i18next";
import { Card } from "components/ui/atoms/Card";
import { FaInfoCircle } from "react-icons/fa";
import Config from "config";
import { RedFlag } from "./RedFlag";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";

export const FormRedFlags = ({
  redFlags,
  setRedFlags,
  lang,
  questionnaire,
  redFlagsEnabled,
  setRedFlagsEnabled,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="my-6">
        <Card wrapperClassName={`border-2 border-dotted border-border-buttons-secondary`}>
          <div className="font-semibold">
            <FaInfoCircle className="inline mr-2 w-5 h-5" />
            {t("what_are_red_flags")}
          </div>
          <div className="mt-2">
            <Trans
              i18nKey="red_flags_explanation"
              components={{ br: <br />, bold: <strong />, small: <small /> }}
            />
          </div>
        </Card>
      </div>
      <div className="my-6">
        {questionnaire.questions
          .filter((q) =>
            ["number", "select", "multiselect", "boolean", "likert_scale"].includes(
              q.question_id.type,
            ),
          )
          .map((q) => {
            return (
              <RedFlag
                question={q.question_id}
                redFlags={redFlags}
                setRedFlags={setRedFlags}
                lang={lang}
                redFlagsEnabled={redFlagsEnabled}
                setRedFlagsEnabled={setRedFlagsEnabled}
              />
            );
          })}
        {questionnaire.questions
          .filter((q) => q.question_id.type === "questions_group")
          .map((q) => {
            return (
              <RedFlag
                question={q.question_id}
                redFlags={redFlags}
                setRedFlags={setRedFlags}
                lang={lang}
                redFlagsEnabled={redFlagsEnabled}
                setRedFlagsEnabled={setRedFlagsEnabled}
              />
            );
          })}
        {questionnaire.questions.filter((q) =>
          ["number", "select", "multiselect", "boolean", "likert_scale"].includes(
            q.question_id.type,
          ),
        ).length == 0 &&
          questionnaire.questions.filter((q) => q.question_id.type === "questions_group").length ===
            0 && (
            <div className="my-10">
              <NoDataInfo
                title={t("no_available_questions_for_red_flags")}
                textOne={t("no_available_questions_for_red_flags_description")}
              />
            </div>
          )}
      </div>
    </>
  );
};
