import { useTranslation } from "react-i18next";

import { PieChart } from "components/ui/molecules/charts/PieChart";
import ScoreCardUtils from "utils/score-card-utils";

export function ScoreCardCriteriaGraph({ criteria, legend, filter }) {
  const { t } = useTranslation();

  const getCriteriaParents = () => {
    return ScoreCardUtils.getCriteriaParents(criteria);
  };

  const getCriteria = () => {
    const limit = 6;

    const arr =
      !filter || filter === "main"
        ? getCriteriaParents().map((c) => {
            return {
              id: c.name,
              label: c.name,
              value: c.weigth,
            };
          })
        : criteria
            .filter((c) => c.parent === filter)
            .map((c) => {
              return {
                id: c.name,
                label: c.name,
                value: c.weigth,
              };
            });
    const parsedCriterias = [...arr].slice(0, limit - 1);
    const rest = [...arr].slice(limit - 1).reduce((acc, cur) => acc + cur.value, 0);

    if (rest) {
      parsedCriterias.push({
        id: t("other_criteria"),
        label: t("other_criteria"),
        value: rest,
      });
    }

    return parsedCriterias;
  };

  return <PieChart legend={legend} data={getCriteria()} />;
}
