import { useTranslation } from "react-i18next";

import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import { FaDownload, FaTrash, FaEye } from "react-icons/fa";
import { ButtonCardMain } from "components/ui/atoms/ButtonCardMain";
import moment from "moment";
import { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { Loader } from "components/ui/molecules/Loader";
import { Card } from "components/ui/atoms/Card";
import ReactTooltip from "react-tooltip";
import ReportsService from "services/reports-service";
import { Pagination } from "components/ui/atoms/Pagination";
import { usePagination } from "hooks/usePagination";
import { ButtonDanger } from "components/ui/atoms/ButtonDanger";
import { useSwal } from "hooks/useSwal";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import DocumentPreview from "components/documentation/documents/components/DocumentPreview";
import { useOutletContext } from "react-router-dom";

const DealflowReports = () => {
  const { deal } = useOutletContext();

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const [reports, setReports] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [showReportPreview, setShowReportPreview] = useState(false);
  const [reportPreviewUrl, setReportPreviewUrl] = useState(null);

  const { confirm } = useSwal();
  const { setTotalPages, totalPages, setPage, page, rowsPerPage, setRowsPerPage } =
    usePagination(12);

  const downloadReport = async(reportId) => {
    try {
      setLoading(true);
      let result = null;
      result = await ReportsService.getReport(reportId);
      if (result?.data?.data?.url) {
        window.open(result.data.data.url, "_blank");
      }
    } catch (error) {
      addToast(t(error?.response?.data?.msg_key || "error_occurred_downloading_report"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteReport = async(reportId) => {
    confirm({
      title: t("are_you_sure"),
    }).then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await ReportsService.deleteReport(reportId);
          addToast(t("report_deleted_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
          getReports();
        } catch (error) {
          addToast(t(error?.response?.data?.msg_key || "error_occurred_deleting_report"), {
            appearance: "error",
            autoDismiss: true,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const previewReport = async(reportId) => {
    try {
      setLoading(true);
      let result = null;
      result = await ReportsService.getReportPreview(reportId);
      if (result?.data?.data?.url) {
        console.log(result.data.data);
        setReportPreviewUrl(result?.data?.data?.url);
        setSelectedDocument({ ...result.data.data, reportId });
        setShowReportPreview(true);
      }
    } catch (error) {
      addToast(t(error?.response?.data?.msg_key || "error_occurred_downloading_report"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const getReports = async() => {
    setLoading(true);
    // TODO: Revisar que ocurre cuando se elimina el último reporte de la página actual. La página actual debería disminuir en 1.
    return ReportsService.getReports({
      rowsPerPage,
      page,
      startup: deal._id,
    })
      .then((response) => {
        setReports(response.data.data.reports);
        setTotalPages(response.data.data.pagination.pages);
      })
      .catch(() => {
        addToast(t("error_retrieving_reports"), {
          appearance: "error",
          autoDismiss: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getReports();
  }, [page, rowsPerPage]);

  return (
    <>
      {loading && <Loader />}
      {(!reports || reports?.length === 0) && (
        <div className="my-6">
          <NoDataInfo title={t("reports_not_found")} />
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols- gap-4 my-10">
        {reports.map((report, index) => (
            <Card key={report._id}>
              <h5 className={"uppercase font-bold text-sm leading-none text-main truncate mb-1"}>
                {report.report_name}
              </h5>
              <div className="text-xs">{moment(report.createdAt).format("YYYY-MM-DD HH:mm")}</div>

              <div className={"flex justify-end mt-4 pt-2 border-t border-separator"}>
                <ButtonSecondary
                  text={t("view_report")}
                  onClick={() => previewReport(report._id)}
                  iconComponent={<FaEye className="inline-block w-6 h-3" />}
                />
                <div data-tip={t("download_report")}>
                  <ButtonCardMain
                    marginLeft={2}
                    onClick={() => downloadReport(report._id)}
                    iconComponent={<FaDownload className="inline-block w-6 h-3" />}
                  />
                </div>
                <ButtonDanger
                  marginLeft={2}
                  onClick={() => deleteReport(report._id)}
                  iconComponent={<FaTrash className="inline-block w-3 h-3" />}
                />
              </div>
            </Card>
        ))}
      </div>
      {reports && reports.length !== 0 && (
        <Pagination
          showRowsPerPage={true}
          paginateOptions={[12, 24, 36]}
          currentPage={page}
          setCurrentPage={(page) => setPage(page)}
          perPage={rowsPerPage}
          pages={totalPages}
          setPerPage={(value) => setRowsPerPage(value[0].id)}
        />
      )}
      {showReportPreview && (
        <DocumentPreview
          document={selectedDocument}
          previewUrl={reportPreviewUrl}
          downloadDocument={() => downloadReport(selectedDocument.reportId)}
          handleClose={() => setShowReportPreview(false)}
          refetch={() => previewReport(selectedDocument.reportId)}
        />
      )}
    </>
  );
};

export default DealflowReports;
