import { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
import { ModalSwal } from "components/ui/molecules/Modal";
import { Loader } from "components/ui/molecules/Loader";
import { RoutesLinks } from "components/routes-links";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import { PageTitle } from "components/ui/atoms/PageTitle";
import AuthUtils from "utils/auth-utils";
import UserOperations from "redux/user/user-operations";
import Config from "config";
import { isEmpty, isEqual } from "lodash";
import Info from "styles/images/vidio-info.svg";
import { useSwal } from "hooks/useSwal";
import useUser from "../../hooks/useUser";
import RemindersService from "services/reminders-service";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { FaCheckCircle, FaFilter, FaPen, FaPlus, FaSearch, FaTrash } from "react-icons/fa";
import ReminderSection from "./ReminderSection";
import RemindersOperations from "redux/reminders/reminders-operations";
import moment from "moment";
import { Card } from "components/ui/atoms/Card";
import { DataGrid } from "components/ui/objects/DataGrid";
import Avatar from "react-avatar";
import Utils from "utils/utils";
import { RemindersFilters } from "./RemindersFilters";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { DealflowService } from "services/dealflow-service";
import { usePagination } from "hooks/usePagination";
import { Pagination } from "components/ui/atoms/Pagination";
import LogoDefault from "styles/images/logo_default.png";
import LPService from "services/lp-service";
import MembersService from "services/members-service";
import { countAppliedFilters } from "utils/countAppliedFilters";

const headerClass = "text-main font-bold text-sm border-b border-main text-left bg-white";

export const Reminders = () => {
  const { userLanguage, hasAccessToModule } = useUser();

const navigate = useNavigate();
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { confirm } = useSwal();
  const handleRedirect = (url) => navigate(url);
  const dispatch = useDispatch();
  const [showModalInfo, setShowModalInfo] = useState(false);

  const setUserInfo = (data) => dispatch(UserOperations.setUserInfo({ data }));

  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({});
  const [filtersCount, setFiltersCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingReminders, setLoadingReminders] = useState(false);
  const [showReminderSection, setShowReminderSection] = useState(false);
  const [reminderToEdit, setReminderToEdit] = useState(null);

  const [reminders, setReminders] = useState([]);
  const [lps, setLPs] = useState([]);
  const [members, setMembers] = useState([]);

  const formInitialized = useRef(false);
  const { setTotalPages, totalPages, setPage, page, rowsPerPage, setRowsPerPage } =
    usePagination(12);

  const [dealflow, setDealflow] = useState([]);

  const getDealflow = () => DealflowService.getDealflow({
    rowsPerPage: 999999,
    pool: "true",
    populate: "false",
    boards: "false",
    milestones: "false",
    files: "false",
    annotations: "false",
  }).then((response) => {
    setDealflow(
      response.data.data.dealflow
        .map((d) => ({ name: d.name, id: d._id }))
        .sort((a, b) => (a.name > b.name ? 1 : -1)),
    );
  });

  const getLPs = async() => {
    try {
      setLoading(true);
      const result = await LPService.getLPs();
      setLPs(
        result.data.data
          .map((lp) => ({ name: `${lp.user.name} ${lp.user.last_name}`, id: lp.user._id }))
          .sort((a, b) => (a.name > b.name ? 1 : -1)),
      );
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t("error_occurred"),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getMembers = async() => {
    try {
      setLoading(true);
      const res = await MembersService.getMembers({ rowsPerPage: 999 });
      setMembers(
        res.data.data.members
          .map((member) => ({ name: `${member.name} ${member.last_name}`, id: member._id }))
          .sort((a, b) => (a.name > b.name ? 1 : -1)),
      );
    } catch (error) {
      // addToast(t('error_occurred_retrieving_members'), { appearance: 'error', autoDismiss: true })
    } finally {
      setLoading(false);
    }
  };

  const setRemindersOperations = (reminders) =>
    dispatch(RemindersOperations.setReminders(reminders));

  const setTotalReminders = (total) => dispatch(RemindersOperations.setTotalReminders(total));

  const getRemindersResume = async() => {
    try {
      const result = await RemindersService.getRemindersResume();
      if (result.data) {
        setRemindersOperations(result.data.data.reminders);
        setTotalReminders(result.data.data.total);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getReminders = async() => {
    try {
      setLoadingReminders(true);
      const result = await RemindersService.getReminders({
        ...filters,
        rowsPerPage,
        page,
      });
      setReminders(result.data.data.reminders);
      setTotalPages(result.data.data.pagination.pages);
    } catch (error) {
      console.error(error);
      addToast(t("error_occurred_retrieving_reminders"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoadingReminders(false);
    }
  };

  const deleteReminder = (reminderId) => {
    confirm().then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await RemindersService.deleteReminder(reminderId);
          getReminders();
          getRemindersResume();
          addToast(t("reminder_deleted_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
        } catch (error) {
          addToast(t("error_occurred_deleting_reminder"), {
            appearance: "error",
            autoDismiss: true,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const markAsCompleted = (reminder) => {
    confirm().then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await RemindersService.updateReminder(reminder._id, { completed: true });
          getReminders();
          getRemindersResume();
          addToast(t("reminder_marked_as_completed_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
        } catch (error) {
          addToast(t("error_occurred_marking_reminder_as_completed"), {
            appearance: "error",
            autoDismiss: true,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const handleSetFilters = (_filters) => {
    setFilters(_filters);
    setFiltersCount(countAppliedFilters(_filters));
  };

  useEffect(() => {
    getDealflow();
    getLPs();
    if (hasAccessToModule("members")) {
      getMembers();
    }
  }, []);

  useEffect(() => {
    if (!formInitialized.current) {
      formInitialized.current = true;
      return;
    }

    if (page > 0) {
      setPage(0);
      return;
    }
    getReminders();
  }, [filters]);

  useEffect(() => {
    getReminders();
  }, [page, rowsPerPage]);

  return (
    <>
      {showFilters && (
        <RemindersFilters
          onClose={() => setShowFilters(false)}
          onFilter={handleSetFilters}
          filters={filters}
          deals={dealflow}
          members={members}
          lps={lps}
        />
      )}
      {(showReminderSection || reminderToEdit) && (
        <ReminderSection
          dealflow={dealflow}
          members={members}
          lps={lps}
          onClose={() => {
            setReminderToEdit(null);
            setShowReminderSection(false);
          }}
          afterSubmit={() => {
            setReminderToEdit(null);
            setShowReminderSection(false);
            getReminders();
            getRemindersResume();
          }}
          initialValues={reminderToEdit}
          dealId={reminderToEdit?.deal?._id}
          memberId={reminderToEdit?.member?._id}
          lpId={reminderToEdit?.lp?._id}
        />
      )}
      <div className="flex items-start">
        <div className="flex-1">
          <PageTitle title={t("reminders")} />
        </div>
        <div className="flex">
          <ButtonMain
            onClick={() => setShowReminderSection(true)}
            text={t("add_reminder")}
            iconComponent={<FaPlus className="inline-block mr-2" />}
          />
          {(reminders?.length !== 0 || !isEmpty(filters)) && (
            <ButtonSecondary
              onClick={() => setShowFilters(true)}
              iconComponent={<FaFilter />}
              marginLeft={2}
              badgeBgColor={"red"}
              badgeContent={filtersCount > 0 ? filtersCount : null}
            />
          )}
        </div>
      </div>
      <div className="my-6">
        {reminders && reminders.length > 0 && (
          <Card>
            <DataGrid
              actionsHeaderClassName={headerClass}
              data={reminders.map((d) => ({ ...d, id: d._id }))}
              headers={[
                {
                  key: "reminder",
                  title: t("reminder"),
                  headerClassName: headerClass,
                  render: (key, reminder) => (
                    <div className="flex">
                      <div className="ml-2">
                        {Utils.getReminderIcon(reminder, t)} {reminder.reminder}
                      </div>
                    </div>
                  ),
                },
                {
                  key: "deal",
                  title: t("startup"),
                  headerClassName: headerClass,
                  render: (key, reminder) => (
                    <>
                      {reminder.deal ? (
                        <div className="flex">
                          <img
                            src={reminder.deal.logo || LogoDefault}
                            alt={reminder.deal.name}
                            onClick={() =>
                              handleRedirect(`${RoutesLinks.DEALFLOW_LINK}${reminder.deal._id}`)
                            }
                            className="h-10 w-14 object-contain cursor-pointer"
                          />
                          {reminder.deal.name && (
                            <div className="flex flex-col justify-center text-xs font-semibold">
                              <span className="ml-2">{reminder.deal.name}</span>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div>-</div>
                      )}
                    </>
                  ),
                },
                {
                  key: "member",
                  title: t("member"),
                  headerClassName: headerClass,
                  render: (key, reminder) => (
                    <div className="flex">
                      <div>
                        {reminder.member
                          ? `${reminder.member.name} ${reminder.member.last_name}`
                          : "-"}
                      </div>
                    </div>
                  ),
                },
                {
                  key: "lp",
                  title: t("lp"),
                  headerClassName: headerClass,
                  render: (key, reminder) => (
                    <div className="flex">
                      <div>
                        {reminder.lp ? `${reminder.lp.name} ${reminder.lp.last_name}` : "-"}
                      </div>
                    </div>
                  ),
                },
                {
                  key: "visibility",
                  title: t("visibility"),
                  headerClassName: headerClass,
                  render: (key, reminder) => (
                    <div className="flex">
                      <div>{reminder.is_private ? t("private") : t("public")}</div>
                    </div>
                  ),
                },
                {
                  key: "date",
                  title: t("date"),
                  headerClassName: headerClass,
                  render: (key, reminder) => (
                    <div className="flex">
                      <div className="ml-2">{moment(reminder.date).format("YYYY-MM-DD HH:MM")}</div>
                    </div>
                  ),
                },
              ]}
              actions={[
                {
                  id: "mark_as_completed",
                  tip: t("mark_as_completed"),
                  onClick: (reminder) => markAsCompleted(reminder),
                  buttonProps: {
                    iconComponent: <FaCheckCircle />,
                  },
                },
                {
                  id: "update",
                  tip: t("update_reminder"),
                  onClick: (reminder) => setReminderToEdit(reminder),
                  buttonProps: {
                    iconComponent: <FaPen />,
                  },
                },
                {
                  id: "delete",
                  tip: t("delete_reminder"),
                  onClick: (reminder) => deleteReminder(reminder._id),
                  buttonProps: {
                    iconComponent: <FaTrash />,
                  },
                },
              ]}
            />
            <Pagination
              paginateOptions={[12, 24, 36, 48, 60]}
              currentPage={page}
              setCurrentPage={(page) => setPage(page)}
              perPage={rowsPerPage}
              pages={totalPages}
              setPerPage={(value) => setRowsPerPage(value[0].id)}
            />
          </Card>
        )}
        {!loading && reminders.length === 0 && (
          <div className="my-10">
            <img
              src={`${Config.GUIDE_STEPS_BACKGROUND_IMAGES_BUCKET}reminders_${userLanguage}.png`}
              className="w-auto"
            />
          </div>
        )}
        {(loading || loadingReminders) && <Loader />}
      </div>
    </>
  );
};
