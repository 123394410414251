import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { PageTitle } from "components/ui/atoms/PageTitle";
import { SectionHeader } from "components/ui/molecules/SectionHeader";
import useUser from "hooks/useUser";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaFilter, FaPlus } from "react-icons/fa";
import { Outlet } from "react-router-dom";
import { getPathSegment } from "utils/get-path-segment";

export const DocumentationView = () => {
  const [section, setSection] = useState(getPathSegment(location.href, 2));
  const [filtersCount, setFiltersCount] = useState(0);

  const SECTIONS = [
    { id: "documents", route: "documents", title: "documents" },
    { id: "tags", route: "tags", title: "tags" },
  ];

  const { t } = useTranslation();

  const { user, checkIfLPhasAccessToSection } = useUser();

  const pageRef = useRef();

  return (
    <>
        <div className="flex items-start">
          <div className="flex-1">
            <PageTitle title={t("documentation")} />
            </div>
        {section === "documents" && (
            <>
            {!user.lp && (
          <>
            <ButtonMain
              iconComponent={<FaPlus className="inline-block mr-2" />}
              marginRight={"2"}
              text={t("upload_document")}
              onClick={() => pageRef?.current?.uploadDocument()}
            />
            <ButtonSecondary
              iconComponent={<FaPlus className="inline-block mr-2" />}
              marginRight={"2"}
              text={t("create_folder")}
              onClick={() => pageRef?.current?.createFolder()}
            />
          </>
            )}
        {(!user.lp || (user.lp && checkIfLPhasAccessToSection("documentation"))) && (
          <div>
            <ButtonSecondary
              iconComponent={<FaFilter className="h-4" />}
              onClick={() => pageRef?.current?.showFilters()}
              badgeBgColor={"red"}
              badgeContent={filtersCount > 0 ? filtersCount : null}
            />
          </div>
        )}
            </>
        )}
        {section === "tags" && !user.lp && (
            <ButtonMain
                iconComponent={<FaPlus className="inline-block mr-2" />}
                text={t("create_tag")}
                onClick={() => pageRef?.current?.handleCreateTag()}
            />
        )}
        </div>
        <SectionHeader
        sectionTitles={SECTIONS.map((sec) => t(sec))}
        sectionKeys={SECTIONS}
        section={section}
        setSection={(sec) =>
          setSection(sec)
        }
        showAdd={false}
        showFilters={false}
        setShowFilters={null}
        navigate
        />
        <Outlet context={{ ref: pageRef, setFiltersCount }}/>
    </>
  );
};
