import { useState } from "react";
import { useTranslation } from "react-i18next";

import Select from "components/ui/atoms/Select";
import useUser from "hooks/useUser";

export const CurrencyForm = ({ onSubmit }) => {
  const { t } = useTranslation();

  const { user } = useUser();

  const currencies = [
    {
      id: "usd",
      value: "USD $",
    },
    {
      id: "eur",
      value: "EUR €",
    },
  ];

  const userCurrencyId = user.investor.preferences.currency;
  const userSelectedCurrency = currencies.find((currency) => currency.id === userCurrencyId);

  console.log(userSelectedCurrency);

  const [selectedCurrency, setselectedCurrency] = useState([userSelectedCurrency]);

  const handleCurrencyChange = (event) => {
    setselectedCurrency(event);
    onSubmit(event[0].id);
  };

  return (
    <>
      <div className="w-full">
        <Select
          id="currency"
          items={currencies}
          initialValues={selectedCurrency}
          onSelect={(e) => {
            handleCurrencyChange(e);
          }}
          placeholder={t("currency")}
          name="currency"
          isClearable={false}
        />
      </div>
    </>
  );
};
