import { FaFile, FaLink } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import { Autocomplete } from "components/ui/atoms/Autocomplete";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import InputFile from "components/ui/atoms/input-files";
import { Input } from "components/ui/atoms/Input";
import { SidePanel } from "components/ui/objects/SidePanel";
import Select from "components/ui/atoms/Select";
import { Switch } from "components/ui/atoms/Switch";
import { Checkbox } from "components/ui/molecules/Checkbox";

export const UploadFileSection = ({
  onClose,
  onSubmit,
  tags,
  startup,
  dealflow = [],
  dealId,
  accept = ".doc, .docx, .json, .ppt, .pptx, .xls, .xlsx, video/mp4, video/avi, video/mov, .pdf, .jpg, .png, .ico, .svg",
  multiple = true,
}) => {
  const [selectedTags, setSelectedTags] = useState([]);
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState(false);
  const [linkError, setLinkError] = useState(false);
  const [documentNameError, setDocumentNameError] = useState(false);
  const [selectedStartup, setSelectedStartup] = useState(dealId || startup || null);
  const [documentTypeError, setDocumentTypeError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [lps, setLPs] = useState(false);
  const [members, setMembers] = useState(false);
  const [indexDocument, setIndexDocument] = useState(false);

  const DOCUMENT_CATEGORIES = [
    { id: "dc_deck", value: t("dc_deck") },
    { id: "dc_one_pager", value: t("dc_one_pager") },
    { id: "dc_financials", value: t("dc_financials") },
    { id: "dc_business_plan", value: t("dc_business_plan") },
    { id: "dc_kpis", value: t("dc_kpis") },
    { id: "dc_video_meeting", value: t("dc_video_meeting") },
    { id: "dc_other_documents", value: t("dc_other_documents") },
  ];

  const parsedDealflow = (dealflow || [])
    .sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    })
    .map((n) => ({ id: n._id, name: n.name }));

  const [entityToAssignDocument, setEntityToAssignDocument] = useState(dealId ? "startup" : null);

  const [documentType, setDocumentType] = useState(null);
  const [category, setCategory] = useState(null);

  const [link, setLink] = useState(null);
  const [documentName, setDocumentName] = useState(null);

  const uploadDocument = () => {
    if (documentType && category) {
      if (documentType === "file" && !selectedFile) {
        setFileError(true);
      } else if (documentType === "link" && (!link || !documentName)) {
        if (!link) {
          setLinkError(true);
        }
        if (!documentName) {
          setDocumentNameError(true);
        }
      } else {
        onClose(false);
        onSubmit(
          documentType,
          selectedFile,
          selectedTags.length ? selectedTags : null,
          selectedStartup,
          link,
          documentName,
          entityToAssignDocument,
          category,
          lps,
          members,
          indexDocument,
        );
      }
    } else {
      if (!documentType) {
        setDocumentTypeError(true);
      } else {
        setCategoryError(true);
      }
    }
  };

  useEffect(() => {
    if (link && linkError) {
      setLinkError(false);
    }
  }, [link]);

  useEffect(() => {
    if (documentName && documentNameError) {
      setDocumentNameError(false);
    }
  }, [documentName]);

  return (
    <SidePanel width={"1/4"} onClose={onClose} title={t("upload_document")}>
      <div className={"w-full px-1 text-sm font-medium my-2 flex text-main"}>
        {t("what_do_you_want_to_upload")}
      </div>
      <div className="flex flex-row my-3">
        <div
          data-tip={t("document")}
          onClick={
            documentType !== "file"
              ? () => {
                  setDocumentTypeError(false);
                  setDocumentType("file");
                  setSelectedFile(null);
                }
              : null
          }
          className={`w-8 flex justify-center  p-1 hover:shadow-inner rounded-md cursor-pointer border-2 bg-white ${documentType === "file" ? "shadow-inner border-main" : "shadow-soft-white"}`}
        >
          <FaFile />
        </div>

        <div
          data-tip={t("link")}
          onClick={
            documentType !== "link"
              ? () => {
                  setDocumentTypeError(false);
                  setDocumentType("link");
                  setSelectedFile(null);
                }
              : null
          }
          className={`bg-white w-8 flex justify-center p-1 hover:shadow-inner ml-2 rounded-md cursor-pointer border-2 ${documentType === "link" ? "shadow-inner border-main" : "shadow-soft-white"}`}
        >
          <FaLink />
        </div>
      </div>
      {documentTypeError && (
        <div className="text-red relative mt-2 text-xxs text-left">
          {t("you_have_to_select_a_document_type")}
        </div>
      )}
      {documentType !== null && (
        <>
          <div className="border-t my-4 pt-2">
            <Select
              name="category"
              label={t("category")}
              required={true}
              isClearable={false}
              placeholder={t("select_option")}
              error={
                categoryError ? { message: t("you_have_to_select_a_document_category") } : null
              }
              initialValues={
                category ? DOCUMENT_CATEGORIES.filter((cat) => cat.id === category) : []
              }
              items={DOCUMENT_CATEGORIES}
              onSelect={(selected) => {
                setCategoryError(false);
                setCategory(selected[0].id);
                setSelectedFile(null);
              }}
            />
          </div>
          {documentType === "file" && category && (
            <div className="my-4">
              <p className={"font-medium text-sm text-main border-t mb-1 pt-2"}>
                {t("select_file")}
              </p>
              <InputFile
                multiple={multiple} // multiple prop default true
                selectedFile={selectedFile}
                setSelectedFile={(e) => {
                  setFileError(false);
                  setSelectedFile(e);
                }}
                // multiple={category === 'dc_other_documents'} allow multiple for any section
                accept={accept}
                error={fileError ? { message: t("required_field") } : {}}
              />
            </div>
          )}
          {documentType === "link" && category && (
            <div className="mt-4">
              <Input
                label={t("document_name_label")}
                required={true}
                onChange={(e) => setDocumentName(e.target.value)}
                placeholder={t("document_name_placeholder")}
                error={documentNameError ? { message: t("required_field") } : {}}
              />
              <Input
                label={t("insert_link")}
                required={true}
                onChange={(e) => setLink(e.target.value)}
                placeholder={t("link_placeholder")}
                error={linkError ? { message: t("required_field") } : {}}
              />
            </div>
          )}
          {selectedFile && (
            <>
              <div className="text-sm mb-4">{t("would_you_like_to_share_document")}</div>
              <div className="mb-4">
                <Switch
                  checked={lps}
                  onChange={async(checked) => {
                    setLPs(checked);
                  }}
                  text={t("lps")}
                />
              </div>
              <div className="mb-4">
                <Switch
                  checked={members}
                  onChange={async(checked) => {
                    setMembers(checked);
                  }}
                  text={t("members")}
                />
              </div>
              {!startup && !dealId && dealflow.length !== 0 && (
                <div className="mt-4 w-full">
                  <Autocomplete
                    name="startup"
                    label={t("startup")}
                    placeholder={t("startup")}
                    options={parsedDealflow}
                    onSelect={(selected) => {
                      setSelectedStartup(selected?.id);
                    }}
                    initialValues={
                      parsedDealflow.length !== 0
                        ? parsedDealflow.find((dealflow) => dealflow.id === selectedStartup)
                        : null
                    }
                  />
                </div>
              )}

              {!startup && !dealId && dealflow.length === 0 && (
                <div className="text-xs text-center font-bold my-4">{t("no_startups_found")}</div>
              )}
            </>
          )}
          <Checkbox
            label={t("index_document")}
            value={indexDocument}
            onChange={(e) => setIndexDocument(e.target.checked)}
          />
        </>
      )}

      {category && (
        <div className={"flex justify-end mt-4 border-t pt-2 border-separator"}>
          <ButtonMain text={t("upload_document")} onClick={() => uploadDocument()} />
        </div>
      )}
    </SidePanel>
  );
};
