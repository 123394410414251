import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TagsSelector } from "components/ui/atoms/TagsSelector";
import TagsService from "services/tags-service";
import { useToasts } from "react-toast-notifications";
import { Spinner } from "components/ui/atoms/Spinner";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { SidePanel } from "components/ui/objects/SidePanel";
import Select from "components/ui/atoms/Select";
import { Switch } from "components/ui/atoms/Switch";
import useUser from "hooks/useUser";
import { Checkbox } from "components/ui/molecules/Checkbox";

export const DocumentSection = ({
  cat,
  onClose,
  onSubmit,
  tags: tagsFromProps,
  currentTags,
  document,
  handleShowMoveModal,
  closeOnClickOutside,
}) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [tags, setTags] = useState(tagsFromProps || []);
  const [loading, setLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [categoryError, setCategoryError] = useState(false);
  const [category, setCategory] = useState(cat);
  const [lps, setLPs] = useState(document.lps_shared || false);
  const [members, setMembers] = useState(document.members_shared || false);
  const { hasAccessToModule } = useUser();

  const DOCUMENT_CATEGORIES = [
    { id: "dc_deck", value: t("dc_deck") },
    { id: "dc_one_pager", value: t("dc_one_pager") },
    { id: "dc_financials", value: t("dc_financials") },
    { id: "dc_other_documents", value: t("dc_other_documents") },
  ];

  /* const getTags = () => {
    return TagsService.getTags(category).then((response) => {
      setTags(response.data.data)
    }).catch((error) => {
      addToast(
        error.response && error.response.data.msg_key
          ? error.response.data.msg_key
          : t('error_occurred_retrieving_tags'),
        {
          appearance: 'error',
          autoDismiss: true
        }
      )
    })
  } */

  /* useEffect(() => {
    if (tagsFromProps) {
      setTags(tagsFromProps)
    } else if (category) {
      setLoading(true)
      getTags().finally(() => setLoading(false))
    }
  }, [tagsFromProps, category]) */

  /* useEffect(() => {
    setSelectedTags(currentTags ? currentTags.map((tag) => tag._id) : [])
  }, [currentTags]) */

  return (
    <SidePanel
      width={"1/4"}
      onClose={onClose}
      title={t("edit_document")}
      closeOnClickOutside={closeOnClickOutside}
    >
      <div className="w-full">
        <h3 className={"mb-4 text-main border-b border-main"}>{t("category")}</h3>
        <Select
          name="category"
          label={t("category")}
          required={true}
          isClearable={false}
          placeholder={t("select_option")}
          error={categoryError ? { message: t("you_have_to_select_a_document_category") } : null}
          initialValues={category ? DOCUMENT_CATEGORIES.filter((cat) => cat.id === category) : []}
          items={DOCUMENT_CATEGORIES}
          onSelect={(selected) => {
            setCategoryError(false);
            setCategory(selected[0].id);
          }}
        />
        <div className="text-sm mb-4">{t("would_you_like_to_share_document")}</div>
        <div className="mb-4">
          <Switch
            checked={lps}
            onChange={async(checked) => {
              setLPs(checked);
            }}
            text={t("lps")}
          />
        </div>
        {hasAccessToModule("members") && (
          <div className="mb-4">
            <Switch
              checked={members}
              onChange={async(checked) => {
                setMembers(checked);
              }}
              text={t("members")}
            />
          </div>
        )}
        {/* <h3 className={'mb-4 text-main border-b border-main'}>
          {t('tags')}
        </h3>

        {loading && (
          <div className='text-center'>
            <Spinner />
          </div>
        )}

        {!loading && (
          <TagsSelector
            tags={tags}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
          />
        )} */}
        <h3 className={"mb-4 text-main border-b border-main"}>{t("location")}</h3>

        <ButtonSecondary text={t("move")} onClick={handleShowMoveModal} marginRight={2} />

        <div className={"flex justify-end mt-2 pt-2 border-t border-separator"}>
          <ButtonSecondary text={t("cancel")} onClick={onClose} marginRight={2} />
          <ButtonMain
            type="submit"
            text={t("save")}
            onClick={() => onSubmit(selectedTags, category, lps, members)}
          />
        </div>
      </div>
    </SidePanel>
  );
};
