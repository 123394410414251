import axios from "axios/index";
import Config from "../config";

export const CustomFieldsService = {
  createCustomField: async(data) => axios.post(`${Config.CUSTOM_FIELDS_API_URL}`, data),

  getCustomFields: async() => axios.get(`${Config.CUSTOM_FIELDS_API_URL}`),

  deleteCustomField: async(id) => axios.delete(`${Config.CUSTOM_FIELDS_API_URL}${id}`),

  updateCustomField: async(id, data) => axios.patch(`${Config.CUSTOM_FIELDS_API_URL}${id}`, data),
};
