import { ScoreCardCriteriaGraph } from "./ScoreCardGraphCriteriasGraph";
import { Card } from "components/ui/atoms/Card";
import { FaEye } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { useEffect } from "react";
import { ButtonCardMain } from "../atoms/ButtonCardMain";

export function ScoreCardBox({ active, criteria, name, onClick, investor = null }) {
  const { t } = useTranslation();
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <div className="mt-4">
      <Card containerClassName="relative overflow-hidden" investor={investor}>
        <div className={"flex flex-row w-full"}>
          <div className={"flex flex-row justify-between w-full mt-2"}>
            <div className={"text-lg font-bold text-main px-2"}>{name}</div>
          </div>
        </div>

        <div className="w-full  h-64 mb-6">
          <ScoreCardCriteriaGraph legend={false} criteria={criteria} filter={"main"} />
        </div>
        {onClick && (
          <div className={"flex justify-end mt-2 pt-2 border-t border-separator"}>
            <ButtonCardMain
              onClick={onClick}
              text={t("see_details")}
              iconComponent={<FaEye className="inline-block mr-2 w-5 h-4" />}
            />
          </div>
        )}
      </Card>
    </div>
  );
}
