import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { Loader } from "components/ui/molecules/Loader";
import { SharedEventFooter } from "components/events/components/event/components/SharedEventFooter";
import Config from "config";
import { useNavigate, useParams } from "react-router-dom";
import { RoutesLinks } from "components/routes-links";
import { InvestorInvestmentThesis } from "components/investment-thesis/InvestorInvestmentThesis";
import { useSwal } from "hooks/useSwal";
import ContactsService from "services/contacts-service";

export const DealflowPreferences = () => {
  const wrapperElement = useRef(null);
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
const navigate = useNavigate();
  const { contactId } = useParams();
  const [dealflowPreferences, setDealflowPreferences] = useState({});

  const { confirm } = useSwal();

  const getDealflowPreferences = async () => {
    try {
      setLoading(true);
      const result = await ContactsService.getDealflowPreferences(contactId);
      setDealflowPreferences(result.data.data);
    } catch (error) {
      console.error(error);
      addToast(
        t(error?.response?.data?.msg_key || "error_occurred_retrieving_dealflow_preferences"),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
      // navigate(RoutesLinks.LOGIN_LINK)
    } finally {
      setLoading(false);
    }
  };

  const saveDealflowPreferences = async (values) => {
    confirm({}).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          await ContactsService.saveDealflowPreferences(contactId, values);
          addToast(t("dealflow_preferences_updated_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
        } catch (error) {
          console.error(error);
          addToast(t("error_occurred_updating_dealflow_preferences"));
        } finally {
          setLoading(false);
        }
      }
    });
  };

  useEffect(() => {
    if (contactId) {
      getDealflowPreferences();
    } else {
      navigate(RoutesLinks.LOGIN_LINK);
    }
  }, [contactId]);

  return (
    <div className="flex flex-col min-h-screen" ref={wrapperElement}>
      {loading && <Loader />}
      <section className="px-8 my-8 flex-1">
        <>
          <div className="flex justify-between border-b border-gray-lines mb-6">
            <div>
              <h1 className={`text-main text-3xl`}>{t("dealflow_preferences")}</h1>
            </div>
          </div>
          <div className="my-6">
            <InvestorInvestmentThesis
              thesis={dealflowPreferences}
              onSubmit={(values) =>
                saveDealflowPreferences({
                  investment_thesis: {
                    ...values,
                    lead_investor:
                      values.lead_investor?.length && values.lead_investor[0]
                        ? values.lead_investor[0]
                        : null,
                    follow_on:
                      values.follow_on?.length && values.follow_on[0] ? values.follow_on[0] : null,
                  },
                })
              }
            />
          </div>
        </>
      </section>
      <SharedEventFooter />
    </div>
  );
};
