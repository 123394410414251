import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { CoverBackground } from "components/ui/atoms/CoverBackground";
import { Container } from "components/ui/objects/Container";
import RemindersOperations from "redux/reminders/reminders-operations";
import { RoutesLinks } from "components/routes-links";
import LoginForm from "./components/login-form";
import AuthService from "services/auth-service";
import Lang from "lang";
import { useAuth } from "../../hooks/useAuth";
import useUser from "../../hooks/useUser";
import { useLoginEntries } from "../../hooks/useLoginEntries";

const Login = () => {
  const dispatch = useDispatch();
  const { token, setToken, setApiKey } = useAuth();
  const { setUserInfo } = useUser();
  const { setLoginEntries } = useLoginEntries();
  const { addToast } = useToasts();
  const { i18n, t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const setReminders = (reminders) => dispatch(RemindersOperations.setReminders(reminders));

  const setTotalReminders = (total) => dispatch(RemindersOperations.setTotalReminders(total));

  const onSubmit = async({ email, password, keep_logged }) => {
    try {
      setLoading(true);
      // const result = await AuthService.login(email, password, keep_logged);
      const result = await AuthService.login(email, password, true);
      Lang.setLang(result.data.data.user.preferences.language.code);
      await i18n.changeLanguage(result.data.data.user.preferences.language.code);

      setUserInfo(result.data.data.user);
      setToken(result.data.data.token);
      setApiKey(result.data.data.api_key);
      setLoginEntries(result.data.data.login_entries);
      if (result.data.data.reminders) {
        setReminders(result.data.data.reminders.reminders);
        setTotalReminders(result.data.data.reminders.total);
      }
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t("error_occurred_login_in"),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
    } finally {
      setLoading(false);
    }
  };

  if (token) {
    return (
      <Navigate
        to={{
          pathname: RoutesLinks.CHECKS_LINK,
        }}
      />
    );
  }

  return (
    <Container variant="session">
      <Container>
        <LoginForm onSubmit={onSubmit} loading={loading} />
      </Container>

      {/* <CoverBackground bg="logo"> */}
      <CoverBackground link={true}>
        <Container>
          <img src="./Banner_camaras.png" />
        </Container>
      </CoverBackground>
    </Container>
  );
};

export default Login;
