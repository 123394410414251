import { useTranslation } from "react-i18next";
import { SidePanel } from "components/ui/objects/SidePanel";
import { useState } from "react";
import Config from "config";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import Select from "components/ui/atoms/Select";
import { useToasts } from "react-toast-notifications";
import Spanish from "styles/images/spanish.svg";
import English from "styles/images/english.jpg";

/**
 * @param onClose
 * @returns {JSX.Element}
 * @constructor
 */
const ExportStageSection = ({ onClose, onSubmit, fields = [] }) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const [selectedFields, setSelectedFields] = useState([]);
  const [language, setLanguage] = useState(null);

  return (
    <>
      <SidePanel width={"1/4"} onClose={onClose} title={t("export_startups")}>
        <div className="flex flex-col w-full">
          <div className="my-2">
            <Select
              name="field"
              initialValues={
                selectedFields
                  ? selectedFields.map((selectedFieldId) =>
                      fields.find((item) => item.id === selectedFieldId),
                    )
                  : []
              }
              label={t("select_fields_to_export")}
              multiSelect={true}
              required={true}
              placeholder={t("select_fields_to_export")}
              onSelect={(value) => {
                setSelectedFields(value.map((s) => s.id));
              }}
              items={fields}
              isClearable={false}
            />
          </div>
          <div className="w-full px-1 text-xs my-4 font-semibold">
            {t("exportation_language_description")}
          </div>

          <div className="flex flex-row">
            <div
              className={`w-10 p-2 ${language === "es" ? "shadow-inner border-2 border-main" : "border border-border-buttons-secondary"} bg-white hover:shadow-inner mr-2 rounded-lg cursor-pointer`}
              onClick={() => setLanguage("es")}
            >
              <img src={Spanish} alt="Spanish" className="h-full" />
            </div>

            <div
              className={`${language === "en" ? "shadow-inner border-2 border-main" : "border border-border-buttons-secondary"} bg-white w-10 p-2 hover:shadow-inner mr-2 rounded-lg cursor-pointer`}
              onClick={() => setLanguage("en")}
            >
              <img src={English} alt="English" className="h-full" />
            </div>
          </div>
        </div>
        <div className={"flex justify-end mt-2 pt-2 border-t border-separator"}>
          <ButtonMain
            onClick={async () => {
              if (selectedFields.length && language) {
                onSubmit(selectedFields, language);
              } else {
                if (!selectedFields.length) {
                  addToast(t("fields_to_export_are_required"), {
                    appearance: "error",
                    autoDismiss: true,
                  });
                } else {
                  addToast(t("exportation_language_is_required"), {
                    appearance: "error",
                    autoDismiss: true,
                  });
                }
              }
            }}
            text={t("export_startups")}
          />
        </div>
      </SidePanel>
    </>
  );
};

export default ExportStageSection;
