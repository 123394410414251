import Lang from "lang";
import { utils, read } from "xlsx";
import { FaCalendarAlt, FaCheck, FaCheckCircle, FaEnvelope, FaPen, FaPhoneAlt, FaTrash } from "react-icons/fa";
import { AiFillFlag } from "react-icons/ai";
import { HiOutlineDotsCircleHorizontal } from "react-icons/hi";
import moment from "moment";

export const checkIfPreviousStepsArCompleted = (userOnboardingSteps, investorOnboardingSteps, currentStep) => {
  const steps = {
    create_pipeline_manager: ["activate_account", "take_quick_tour", "configure_investment_thesis"],
    add_startup_to_pool: [
      "activate_account",
      "take_quick_tour",
      "configure_investment_thesis",
      "create_pipeline_manager",
    ],
    create_questionnaire: [
      "activate_account",
      "take_quick_tour",
      "configure_investment_thesis",
      "create_pipeline_manager",
      "add_startup_to_pool",
    ],
    publish_questionnaire: [
      "activate_account",
      "take_quick_tour",
      "configure_investment_thesis",
      "create_pipeline_manager",
      "add_startup_to_pool",
      "create_questionnaire",
    ],
    create_score_card: [
      "activate_account",
      "take_quick_tour",
      "configure_investment_thesis",
      "create_pipeline_manager",
      "add_startup_to_pool",
      "create_questionnaire",
    ],
    create_evaluation_process: [
      "activate_account",
      "take_quick_tour",
      "configure_investment_thesis",
      "create_pipeline_manager",
      "add_startup_to_pool",
      "create_questionnaire",
      "create_score_card",
    ],
    add_judge_to_evaluation_process: [
      "activate_account",
      "take_quick_tour",
      "configure_investment_thesis",
      "create_pipeline_manager",
      "add_startup_to_pool",
      "create_questionnaire",
      "create_score_card",
      "create_evaluation_process",
    ],
    add_startup_to_evaluation_process: [
      "activate_account",
      "take_quick_tour",
      "configure_investment_thesis",
      "create_pipeline_manager",
      "add_startup_to_pool",
      "create_questionnaire",
      "create_score_card",
      "create_evaluation_process",
      "add_judge_to_evaluation_process",
    ],
    evaluate_startup: [
      "activate_account",
      "take_quick_tour",
      "configure_investment_thesis",
      "create_pipeline_manager",
      "create_questionnaire",
      "add_startup_to_pool",
      "create_score_card",
      "create_evaluation_process",
      "add_judge_to_evaluation_process",
      "add_startup_to_evaluation_process",
    ],
  };

  return steps[currentStep].every((v) => [...userOnboardingSteps, ...investorOnboardingSteps].includes(v));
};

export const getSuggestedStep = (userOnboardingSteps = [], investorOnboardingSteps = [], currentStep) => {
  const steps = {
    create_pipeline_manager: ["activate_account", "take_quick_tour", "configure_investment_thesis"],
    add_startup_to_pool: [
      "activate_account",
      "take_quick_tour",
      "configure_investment_thesis",
      "create_pipeline_manager",
    ],
    create_questionnaire: [
      "activate_account",
      "take_quick_tour",
      "configure_investment_thesis",
      "create_pipeline_manager",
      "add_startup_to_pool",
    ],
    publish_questionnaire: [
      "activate_account",
      "take_quick_tour",
      "configure_investment_thesis",
      "create_pipeline_manager",
      "add_startup_to_pool",
      "create_questionnaire",
    ],
    create_score_card: [
      "activate_account",
      "take_quick_tour",
      "configure_investment_thesis",
      "create_pipeline_manager",
      "add_startup_to_pool",
      "create_questionnaire",
    ],
    create_evaluation_process: [
      "activate_account",
      "take_quick_tour",
      "configure_investment_thesis",
      "create_pipeline_manager",
      "add_startup_to_pool",
      "create_questionnaire",
      "create_score_card",
    ],
    add_judge_to_evaluation_process: [
      "activate_account",
      "take_quick_tour",
      "configure_investment_thesis",
      "create_pipeline_manager",
      "add_startup_to_pool",
      "create_questionnaire",
      "create_score_card",
      "create_evaluation_process",
    ],
    add_startup_to_evaluation_process: [
      "activate_account",
      "take_quick_tour",
      "configure_investment_thesis",
      "create_pipeline_manager",
      "add_startup_to_pool",
      "create_questionnaire",
      "create_score_card",
      "create_evaluation_process",
      "add_judge_to_evaluation_process",
    ],
    evaluate_startup: [
      "activate_account",
      "take_quick_tour",
      "configure_investment_thesis",
      "create_pipeline_manager",
      "create_questionnaire",
      "add_startup_to_pool",
      "create_score_card",
      "create_evaluation_process",
      "add_judge_to_evaluation_process",
      "add_startup_to_evaluation_process",
    ],
  };

  let suggestedStep = null;
  const onboardingTasksFinished = [...userOnboardingSteps, ...investorOnboardingSteps];

  steps[currentStep].every((v) => {
    if (onboardingTasksFinished.includes(v)) {
      return true;
    } else {
      suggestedStep = v;
      return false;
    }
  });

  return suggestedStep;
};

export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const saturateColor = (color, percent) => {
  const rgb = hexToRgb(color);

  if (!rgb) {
    return color;
  }

  return `rgb(${Math.round(rgb.r + (255 - rgb.r) * percent)}, ${Math.round(rgb.g + (255 - rgb.g) * percent)}, ${Math.round(rgb.b + (255 - rgb.b) * percent)})`;
};

const Utils = {
  hexToRgb,
  saturateColor,
  checkIfPreviousStepsArCompleted,
  getSuggestedStep,

  moneyFormatter: (amount) => {
    if (amount > 1000000) {
      return `${amount / 1000000}M`;
    } else if (amount > 10000) {
      return `${amount / 1000}K`;
    }
    return Math.round(amount);
  },

  copyToClipboard: (text) => navigator.clipboard.writeText(text),

  xlsxToJson: (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;

      reader.onload = (e) => {
        const bstr = e.target.result;
        const wb = read(bstr, { type: rABS ? "binary" : "array", bookVBA: true });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        resolve(utils.sheet_to_json(ws));
      };
      reader.onerror = (error) => {
        reject(error);
      };

      if (rABS) {
        reader.readAsBinaryString(file);
      } else {
        reader.readAsArrayBuffer(file);
      }
    }),

  csvJSON: (csv, separator = ";") => {
    const lines = csv.split("\n");
    const result = [];
    // separate line considering quotes
    const separateLine = (line) => {
      const result = [];
      let current = "";
      let inQuote = false;
      for (let i = 0; i < line.length; i++) {
        if (line[i] === "\"") {
          inQuote = !inQuote;
        } else if (line[i] === separator && !inQuote) {
          result.push(current.trim());
          current = "";
        } else {
          current += line[i];
        }
      }
      result.push(current.trim());
      return result;
    };
    const headers = separateLine(lines[0]);

    for (let i = 1; i < lines.length; i++) {
      if (lines[i] === "") {
        continue;
      }

      const obj = {};
      const currentLine = separateLine(lines[i]);

      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentLine[j];
      }

      result.push(obj);
    }

    return result;
  },

  toSnakeCase: (str) => str.replace(/\s+/g, "_").toLowerCase(),

  nFormatter: (num) => {
    const number = num && num.$numberDecimal ? num.$numberDecimal : num;

    if (number >= 1000000000) {
      return (number / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
    }
    if (number >= 1000000) {
      return (number / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
    }
    if (number >= 1000) {
      return (number / 1000).toFixed(1).replace(/\.0$/, "") + "K";
    }

    return number || number === 0 ? number : null;
  },

  humanFileSize: (bytes) => {
    const thresh = 1024;
    const dp = 1;

    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }

    const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + " " + units[u];
  },

  getReadableDate: (dateString) => {
    if (!dateString) {
      return "";
    }

    const DATE_UNITS = [
      ["year", 31536000],
      ["month", 2592000],
      ["day", 86400],
      ["hour", 3600],
      ["minute", 60],
      ["second", 1],
    ];

    const getDateDiffs = (timestamp) => {
      const now = Date.now();
      const elapsed = (timestamp - now) / 1000;

      for (const [unit, secondsInUnit] of DATE_UNITS) {
        if (Math.abs(elapsed) > secondsInUnit || unit === "second") {
          const value = Math.round(elapsed / secondsInUnit);
          return { value, unit };
        }
      }
    };

    const { value, unit } = getDateDiffs(new Date(dateString));
    const formatter = new Intl.RelativeTimeFormat(Lang.getCurrentLang().code, { style: "long" });

    return formatter.format(value, unit);
  },

  getMonthName: (lang, month, short = false) => {
    const date = new Date(1990, month);
    const options = { month: short ? "short" : "long" };
    const monthName = new Intl.DateTimeFormat(lang, options).format(date);
    return monthName.charAt(0).toUpperCase() + monthName.slice(1);
  },

  downloadFileFromBase64: (base64, filename) => {
    const link = document.createElement("a");
    link.href = base64;
    link.download = filename;
    link.click();
  },

  getDealflowNextStep: (dealflow, t) => (
    <span
      data-tip={t("next_step")}
      key={`${dealflow._id}-next-step`}
      className={"inline-block mr-2 mt-1 font-semibold text-xxs px-2 py-0.5 rounded-md border"}
    >
      {t(dealflow.next_step)}
    </span>
  ),

  getDealflowPriority: (dealflow, t) => {
    if (dealflow.priority === "low") {
      return (
        <span
          data-tip={t("priority")}
          key={`${dealflow._id}-priority`}
          className={
            "inline-block mr-2 mt-1 font-semibold text-xxs px-2 py-0.5 rounded-md border text-white bg-green border"
          }
        >
          {t("low_priority")}
        </span>
      );
    } else if (dealflow.priority === "medium") {
      return (
        <span
          data-tip={t("priority")}
          key={`${dealflow._id}-priority`}
          className={
            "inline-block mr-2 mt-1 font-semibold text-xxs px-2 py-0.5 rounded-md bg-yellow-50 text-main border"
          }
        >
          {t("medium_priority")}
        </span>
      );
    } else {
      return (
        <span
          data-tip={t("priority")}
          key={`${dealflow._id}-priority`}
          className={
            "inline-block mr-2 mt-1 font-semibold text-xxs px-2 py-0.5 rounded-md border bg-red text-white border"
          }
        >
          {t("high_priority")}
        </span>
      );
    }
  },

  getDealflowStatus: (dealflow, t) => {
    if (dealflow.exited) {
      return (
        <span
          key={`${dealflow._id}-status`}
          className={
            "inline-block mr-2 mt-1 font-semibold text-xxs px-2 py-0.5 rounded-md border border-gray-lines text-text-buttons-secondary"
          }
        >
          {t("exited")}
        </span>
      );
    } else if (dealflow.portfolio) {
      return (
        <span
          key={`${dealflow._id}-status`}
          className={
            "inline-block mr-2 mt-1 font-semibold text-xxs px-2 py-0.5 rounded-md border border-gray-lines text-text-buttons-secondary"
          }
        >
          {t("portfolio")}
        </span>
      );
    } else if (dealflow.rejected) {
      return (
        <span
          data-tip={t(dealflow.rejection_reason.key)}
          key={`${dealflow._id}-status`}
          className={
            "inline-block mr-2 mt-1 font-semibold text-xxs px-2 py-0.5 rounded-md border border-gray-lines text-text-buttons-secondary"
          }
        >
          {t("rejected")}
        </span>
      );
    } else if (dealflow.archived) {
      return (
        <span
          key={`${dealflow._id}-status`}
          className={
            "inline-block mr-2 mt-1 font-semibold text-xxs px-2 py-0.5 rounded-md border border-gray-lines text-text-buttons-secondary"
          }
        >
          {t("startup_archived")}
        </span>
      );
    } else if (dealflow.committed) {
      return (
        <span
          key={`${dealflow._id}-status`}
          className={
            "inline-block mr-2 mt-1 font-semibold text-xxs px-2 py-0.5 rounded-md border border-gray-lines text-text-buttons-secondary"
          }
        >
          {t("committed")}
        </span>
      );
    } else if (dealflow.failed) {
      return (
        <span
          data-tip={t(dealflow.failure_reason.key)}
          key={`${dealflow._id}-status`}
          className={
            "inline-block mr-2 mt-1 font-semibold text-xxs px-2 py-0.5 rounded-md border border-gray-lines text-text-buttons-secondary"
          }
        >
          {t("failed")}
        </span>
      );
    } else if (dealflow.interesting) {
      return (
        <span
          key={`${dealflow._id}-status`}
          className={
            "inline-block mr-2 mt-1 font-semibold text-xxs px-2 py-0.5 rounded-md border border-gray-lines text-text-buttons-secondary"
          }
        >
          {t("interesting")}
        </span>
      );
    }
    return (
      <span
        key={`${dealflow._id}-status`}
        className={
          "inline-block mr-2 mt-1 font-semibold text-xxs px-2 py-0.5 rounded-md border border-gray-lines text-text-buttons-secondary"
        }
      >
        {t("no_status")}
      </span>
    );
  },

  getReminderIcon: (reminder, t, small = false) => {
    if (reminder.reminder_type === "call") {
      return (
        <FaPhoneAlt data-tip={t(reminder.reminder_type)} className={`inline ${small ? "w-4 h-4" : "w-6 h-6"} mr-1`} />
      );
    }
    if (reminder.reminder_type === "meeting") {
      return (
        <FaCalendarAlt
          data-tip={t(reminder.reminder_type)}
          className={`inline ${small ? "w-4 h-4" : "w-6 h-6"} mr-1`}
        />
      );
    }
    if (reminder.reminder_type === "task") {
      return (
        <FaCheckCircle
          data-tip={t(reminder.reminder_type)}
          className={`inline ${small ? "w-4 h-4" : "w-6 h-6"} mr-1`}
        />
      );
    }
    if (reminder.reminder_type === "deadline") {
      return (
        <AiFillFlag data-tip={t(reminder.reminder_type)} className={`inline ${small ? "w-4 h-4" : "w-6 h-6"} mr-1`} />
      );
    }
    if (reminder.reminder_type === "email") {
      return (
        <FaEnvelope data-tip={t(reminder.reminder_type)} className={`inline ${small ? "w-4 h-4" : "w-6 h-6"} mr-1`} />
      );
    }
    return (
      <HiOutlineDotsCircleHorizontal
        data-tip={t(reminder.reminder_type)}
        className={`inline ${small ? "w-4 h-4" : "w-6 h-6"} mr-1`}
      />
    );
  },

  getReminderBadge: (reminder, t, deleteReminder, setReminderToEdit, markReminderAsCompleted) => (
    <div
      key={reminder._id}
      className={`inline-block mr-2 rounded-2xl py-0.5 px-2 relative font-semibold text-xs border ${moment(reminder.date).isSame(moment(), "day") ? "border-red" : "border-gray-lines"}`}
    >
      <div className="flex">
        {Utils.getReminderIcon(reminder, t, true)} {reminder.reminder}
        <span className="ml-1 text-xxxs">{`(${moment(reminder.date).format("YYYY-MM-DD HH:MM")})`}</span>
        <FaCheck
          data-tip={t("mark_as_completed")}
          className={`inline ml-2 ${markReminderAsCompleted && "cursor-pointer"} text-main`}
          onClick={markReminderAsCompleted ? () => markReminderAsCompleted(reminder) : null}
        />
        <FaPen
          data-tip={t("update_reminder")}
          className={`inline ml-2 ${setReminderToEdit && "cursor-pointer"} text-main`}
          onClick={setReminderToEdit ? () => setReminderToEdit(reminder) : null}
        />
        <FaTrash
          data-tip={t("delete_reminder")}
          className={`inline ml-2 ${deleteReminder && "cursor-pointer"} text-main`}
          onClick={deleteReminder ? () => deleteReminder(reminder._id) : null}
        />
      </div>
    </div>
  ),

  getDifferenceBetweenDates: (stageDate, t) => {
    const differenceInDays = moment().diff(moment(stageDate), "days");
    if (differenceInDays) {
      return t("date_difference_days", { days: differenceInDays });
    }

    const differenceInHours = moment().diff(moment(stageDate), "hours");
    if (differenceInHours) {
      return t("date_difference_hours", { hours: differenceInHours });
    }

    const differenceInMinutes = moment().diff(moment(stageDate), "minutes");
    if (differenceInMinutes) {
      return t("date_difference_minutes", { minutes: differenceInMinutes });
    }

    return t("date_difference_seconds", { seconds: moment().diff(moment(stageDate), "seconds") });
  },

  sortByKey: (obj, key) => {
    const resp = obj.sort((a, b) => {
      const nameA = a[key].toUpperCase();
      const nameB = b[key].toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    return resp;
  },

  checkInvestmentCriteriaMatch: (dealflow, investmentThesis) => {
    const criteriaChecks = {};
    if (investmentThesis.countries?.length && dealflow.country_incorporation) {
      criteriaChecks.country = !!investmentThesis.countries.find(
        (country) => country._id === dealflow.country_incorporation._id,
      );
    }

    if (investmentThesis.sectors?.length && dealflow.sector) {
      criteriaChecks.sector = !!investmentThesis.sectors.find((sector) => sector._id === dealflow.sector._id);
    }
    if (investmentThesis.business_models?.length && dealflow.business_model) {
      criteriaChecks.business_model = !!investmentThesis.business_models.find(
        (businessModel) => businessModel._id === dealflow.business_model._id,
      );
    }
    if (investmentThesis.project_stages?.length && dealflow.project_stage) {
      criteriaChecks.project_stage = !!investmentThesis.project_stages.find(
        (projectStage) => projectStage._id === dealflow.project_stage._id,
      );
    }
    if (investmentThesis.investment_stages?.length && dealflow.investment_stage) {
      criteriaChecks.investment_stage = !!investmentThesis.investment_stages.find(
        (investmentStage) => investmentStage._id === dealflow.investment_stage._id,
      );
    }

    if (
      ![null, undefined].includes(investmentThesis.maximum_ticket_raw) &&
      ![null, undefined].includes(dealflow.minimum_ticket_raw)
    ) {
      criteriaChecks.maximum_ticket = investmentThesis.maximum_ticket_raw >= dealflow.minimum_ticket_raw;
    }

    if (
      ![null, undefined].includes(investmentThesis.minimum_valuation_raw) &&
      ![null, undefined].includes(dealflow.valuation_raw)
    ) {
      criteriaChecks.minimum_valuation =
        investmentThesis.minimum_valuation * (investmentThesis.minimum_valuation_unit === "k" ? 1000 : 1000000) <=
        dealflow.valuation_raw;
    }
    if (
      ![null, undefined].includes(investmentThesis.maximum_valuation_raw) &&
      ![null, undefined].includes(dealflow.valuation_raw)
    ) {
      criteriaChecks.maximum_valuation =
        investmentThesis.maximum_valuation * (investmentThesis.maximum_valuation_unit === "k" ? 1000 : 1000000) >=
        dealflow.valuation_raw;
    }

    return criteriaChecks;
  },
};

export default Utils;
