import { useOutletContext } from "react-router-dom";
import { InvestorInvestmentThesis } from "components/investment-thesis/InvestorInvestmentThesis";
import AccountService from "services/account-service";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";

export const CompanyInvestmentThesis = () => {
  const {
    reloadUserInfo,
    investor,
    isChildren,
  } = useOutletContext();

  const { addToast } = useToasts();
  const { t } = useTranslation();

  const thesis = investor?.investment_thesis ?? {};

  console.log(thesis);

  const onSubmitInvestmentThesis = (values) => AccountService.updateDealflowPreferences({
    ...values,
    lead_investor: values.lead_investor ? values.lead_investor[0] : null,
    follow_on: values.follow_on ? values.follow_on[0] : null,
    investor: isChildren ? investor._id : null,
  })
    .then(async() => await reloadUserInfo())
    .then(() => {
      addToast(t("investment_thesis_updated_successfully"), {
        appearance: "success",
        autoDismiss: true,
      });
    })
    .catch((error) => {
      addToast(
        error.response && error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t("error_occurred_updating_investment_thesis"),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
    });

  return (
    <>
      <div className="mt-6">
        <InvestorInvestmentThesis onSubmit={onSubmitInvestmentThesis} thesis={thesis} />
      </div>
    </>
  );
};
