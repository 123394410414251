import React from "react";
import { useTranslation } from "react-i18next";
import { Arrow, useLayer } from "react-laag";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import UserOperations from "redux/user/user-operations";
import { RoutesLinks } from "components/routes-links";
import { FaUser } from "react-icons/fa";
import useUser from "../../../hooks/useUser";
import HeaderMenuItems from "components/layout/header/header-menu-items";
import Avatar from "react-avatar";

export function PopoverMenu() {
  const { t } = useTranslation();
  const [isOpen, setOpen] = React.useState(false);
  const { user } = useUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const INVESTOR_SECTIONS = ["company"];

  const handleRedirect = (url) => {
    setOpen(false);
    navigate(url);
  };

  const handleMethod = (method) => {
    setOpen(false);
    method();
  };

  const getMenuItems = () => {
    const sections = [
      {
        title: "my_account",
        icon: <FaUser />,
        url: `${RoutesLinks.ACCOUNT_LINK}/personal-information`,
      },
    ];

    !user.lp &&
      user.investor.permissions.sections
        .filter((section) => INVESTOR_SECTIONS.includes(section))
        .forEach((section) => {
          const s = HeaderMenuItems.find((s) => s.section === section);
          if (s) {
            sections.push(s);
          }
        });

    sections.push({
      title: "logout",
      icon: <FaUser />,
      method: () => dispatch(UserOperations.logoutUser()),
    });

    return sections;
  };

  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,
    onOutsideClick: () => setOpen(false),
    onDisappear: () => setOpen(false),
    overflowContainer: true,
    auto: true,
    placement: "bottom-center",
    triggerOffset: 12,
    containerOffset: 16,
    arrowOffset: 16,
  });

  return (
    <>
      <button
        id={"account-button"}
        {...triggerProps}
        onClick={() => setOpen(!isOpen)}
        style={{ border: "0px" }} className="inline ml-2 bg-gray-100 hover:bg-gray-200 border focus:outline-none  hover:shadow-inner w-12 h-12 rounded-full ml-2"
      >
        {user.investor.logo ? <Avatar src={user.investor.logo} className="h-9 w-9 rounded-xl" /> : <FaUser className="h-6 w-6 m-auto"/>}
      </button>

      {isOpen &&
        renderLayer(
          <ul
            {...layerProps}
            className={
              "mt-2 px-2 py-2 z-30 | shadow-hover border bg-white rounded-2xl border-border-buttons-secondary"
            }
          >
            {getMenuItems().map((i, index) => (
              <li
                key={index}
                onClick={() => (i.method ? handleMethod(i.method) : handleRedirect(i.url))}
                className={"px-2 py-2 flex text-main items-center cursor-pointer text-sm text-gray"}
              >
                <span className={"mr-2 text-main"}>{i.icon}</span>
                <span className="font-bold">{t(i.title)}</span>
              </li>
            ))}
            <Arrow {...arrowProps} borderColor="#61D8BD" borderWidth={1} className={"w-5"} />
          </ul>,
        )}
    </>
  );
}
