import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { Input } from "components/ui/atoms/Input";
import Select from "components/ui/atoms/Select";
import Config from "config";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonDanger } from "components/ui/atoms/ButtonDanger";
import { FaPlus, FaTrash } from "react-icons/fa";
import { useSwal } from "hooks/useSwal";
import { Switch } from "components/ui/atoms/Switch";
import { useToasts } from "react-toast-notifications";

export const CreateCustomFieldForm = ({ customField, onSubmit }) => {
  const { t } = useTranslation();
  const { confirm } = useSwal();
  const [name, setName] = useState(customField?.name || "");
  const [type, setType] = useState(customField?.type || "");
  const [important, setImportant] = useState(customField?.important || false);
  const [required, setRequired] = useState(customField?.required || false);
  const [asFormQuestion, setAsFormQuestion] = useState(customField?.asFormQuestion || false);
  const [showInMainForm, setShowInMainForm] = useState(customField?.showInMainForm || false);
  const [options, setOptions] = useState(customField?.options || []);
  const { addToast } = useToasts();
  const [nameError, setNameError] = useState(false);
  const [typeError, setTypeError] = useState(false);

  const QUESTION_TYPES = [
    {
      id: "short_answer",
      value: t("short_answer"),
    },
    {
      id: "long_answer",
      value: t("long_answer"),
    },
    {
      id: "select",
      value: t("select"),
    },
    {
      id: "multiselect",
      value: t("multiselect"),
    },
    {
      id: "traffic_light",
      value: t("traffic_light"),
    },
  ];

  const addOption = () => {
    setOptions((options) => [
      ...options,
      {
        value: "",
      },
    ]);
  };

  return (
    <>
      <div className="mt-4">
        <Input
          id="name"
          type="text"
          name="custom_field_name"
          label={t("custom_field_name")}
          value={name}
          onChange={(e) => {
            if (e.target.value) {
              setNameError(false);
            } else {
              setNameError(true);
            }
            setName(e.target.value);
          }}
          placeholder={t("introduce_field_name")}
          maxLength={50}
          required={true}
          error={nameError ? { message: t("required_field") } : null}
        />
      </div>
      <div className="mt-2">
        <Select
          isClearable={false}
          required={true}
          name="custom_field_type"
          label={t("custom_field_type")}
          items={QUESTION_TYPES}
          onSelect={(selected) => {
            if (selected && selected.length > 0) {
              setType(selected[0].id);
              setTypeError(false);
            }
          }}
          initialValues={type ? [{ id: type, value: t(type) }] : []}
          error={typeError ? { message: t("required_field") } : null}
        />
      </div>

      <div className="mt-4">
        <Switch
          checked={important}
          onChange={(checked) => setImportant(checked)}
          text={t("important")}
        />
      </div>

      <div className="mt-4">
        <Switch
          checked={required}
          onChange={(checked) => setRequired(checked)}
          text={t("required")}
        />
      </div>

      <div className="mt-4">
        <Switch
          checked={asFormQuestion}
          onChange={(checked) => setAsFormQuestion(checked)}
          text={t("as_form_question")}
        />
      </div>

      <div className="mt-4">
        <Switch
          checked={showInMainForm}
          onChange={(checked) => setShowInMainForm(checked)}
          text={t("show_in_main_form")}
        />
      </div>

      {["select", "multiselect"].includes(type) && (
        <div className="mt-6">
          <div className="w-full px-1 text-xs my-2 font-semibold">{t("custom_field_options")}</div>
          {options.map((option, index) => (
            <div key={index} className="flex w-full mt-4 mb-4 justify-items-center">
              <div className="w-full -mb-5">
                <Input
                  type="text"
                  id={option._id || `option_${index}`}
                  name={option._id || `option_${index}`}
                  placeholder={`${t("option")} ${index + 1}`}
                  required={true}
                  value={option.value || ""}
                  onChange={(e) => {
                    const temp = [...options];
                    temp.splice(index, 1, { ...option, value: e.target.value });
                    setOptions(temp);
                  }}
                />
              </div>

              {index !== 0 && (
                <div className="flex ml-2" data-tip={t("delete")}>
                  <ButtonDanger
                    iconComponent={<FaTrash className="inline" />}
                    onClick={() => {
                      confirm({}).then((isConfirmed) => {
                        if (isConfirmed) {
                          const temp = [...options];
                          temp.splice(index, 1);
                          setOptions(temp);
                        }
                      });
                    }}
                  />
                </div>
              )}
            </div>
          ))}

          <ButtonMain
            text={t("add_option")}
            iconComponent={<FaPlus className="inline mr-2" />}
            onClick={addOption}
          />
        </div>
      )}
      <div className={`flex justify-end mt-4 border-t pt-2 border-separator`}>
        <ButtonMain
          text={customField ? t("update_custom_field") : t("create_custom_field")}
          onClick={() => {
            if (!name || !type) {
              addToast(t("form_field_error"), { appearance: "error", autoDismiss: true });
              if (!name) {
                setNameError(true);
              }
              if (!type) {
                setTypeError(true);
              }
            } else if (
              type &&
              ["select", "multiselect"].includes(type) &&
              (!options.length || options.some((option) => !option.value))
            ) {
              if (!options.length) {
                addToast(t("field_options_are_required"), {
                  appearance: "error",
                  autoDismiss: true,
                });
              } else {
                addToast(t("field_contains_empty_option"), {
                  appearance: "error",
                  autoDismiss: true,
                });
              }
            } else {
              onSubmit({
                name,
                type,
                options,
                required,
                important,
                asFormQuestion,
                showInMainForm,
              });
            }
          }}
        />
      </div>
    </>
  );
};
