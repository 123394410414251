import { useTranslation } from "react-i18next";
import { PageTitle } from "components/ui/atoms/PageTitle";
import useUser from "hooks/useUser";

const Web = () => {
  const { t } = useTranslation();
  const { userLanguage } = useUser();

  const FORMS = {
    es: "https://tally.so/r/nW2X0j",
    en: "https://tally.so/r/nW2X0j",
  };

  return (
    <>
      <div className="flex mb-6">
        <div className="flex-1">
          <PageTitle removeMargin={true} showBack={false} title={t("web")} />
        </div>
      </div>
      <div className="h-screen my-6">
        <iframe
          src={FORMS[userLanguage]}
          width="100%"
          height="100%"
          className="rounded-lg"
        ></iframe>
      </div>
    </>
  );
};

export default Web;
