import UserActions from "./user-actions";

const isTokenInState = (state) => {
  return Boolean(state.auth.token);
};

const UserOperations = {
  checkUserExist: () => async (dispatch, getState) => {
    if (!isTokenInState(getState())) {
      dispatch(UserOperations.logoutUser());
    }
    return Promise.resolve();
  },

  logoutUser: () => async (dispatch) => {
    dispatch(UserActions.cleanUserData());
  },

  setToken: (token) => (dispatch, getState) => {
    dispatch(UserActions.setToken(token));
  },

  setApiKey: (apiKey) => (dispatch, getState) => {
    dispatch(UserActions.setApiKey(apiKey));
  },

  setLoginEntries: (loginEntries) => (dispatch, getState) => {
    dispatch(UserActions.setLoginEntries(loginEntries));
  },

  setUserInfo:
    ({ data }) =>
    (dispatch, getState) => {
      dispatch(UserActions.setUserInfo(data));
    },
};

export default UserOperations;
