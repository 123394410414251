import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Autocomplete } from "components/ui/atoms/Autocomplete";
import ReactTooltip from "react-tooltip";

/**
 * @param onClose
 * @returns {JSX.Element}
 * @constructor
 */
const SearchStartupSection = ({ onSubmit, initialValues, dealflow }) => {
  const { t } = useTranslation();

  const [startupToSearch, setStartupToSearch] = useState(initialValues);

  const onSelect = (value, setter) => {
    setter(value);
    onSubmit(value);
  };

  return (
    <>
        <div className="flex">
          <div className="w-full">
            <Autocomplete
              initialValues={
                startupToSearch ? dealflow.find((deal) => deal._id === initialValues) : null
              }
              name="startup_to_search"
              placeholder={t("search_startup")}
              isClearable={true}
              options={dealflow.sort((a, b) =>
                a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1,
              )}
              onSelect={(value) => { onSelect(value?._id || null, setStartupToSearch, "dealflow"); ReactTooltip.hide(); }}
            />
          </div>
        </div>
    </>
  );
};

export default SearchStartupSection;
