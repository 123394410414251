import React, { useRef, useState, useCallback, useEffect } from "react";
import { ReactSortable } from "react-sortablejs";

const VirtualizedList = ({
  items,
  itemHeight,
  height,
  renderItem,
  setList,
  dataId,
  group,
  animation,
  easing,
  chosenClass,
  onStart,
  onSort,
  onRemove,
  onAdd,
  itemMargin = 10,
  movedElement,
  onEnd,
  onChange,
}) => {
  const [scrollOffset, setScrollOffset] = useState(0);
  const [itemHeights, setItemHeights] = useState({});
  const listRef = useRef(null);

  const placeholderIndex = movedElement?.newIndex ?? null;
  const oldIndex = movedElement?.oldIndex ?? null;

  const handleScroll = useCallback(() => {
    if (listRef.current) {
      setScrollOffset(listRef.current.scrollTop);
    }
  }, []);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.addEventListener("scroll", handleScroll);
      return () => {
        if (listRef.current) {
          listRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [handleScroll]);

  const handleSetItemHeight = (index, height) => {
    setItemHeights((prevHeights) => ({
      ...prevHeights,
      [index]: height,
    }));
  };

  const calculateVisibleItems = () => {
    let startIndex = 0;
    let endIndex = items.length - 1;
    let cumulativeHeight = 0;

    for (let i = 0; i < items.length; i++) {
      const currentItemHeight = itemHeights[i] || itemHeight;
      if (cumulativeHeight + currentItemHeight > scrollOffset) {
        startIndex = i;
        break;
      }
      cumulativeHeight += currentItemHeight + itemMargin;
    }

    cumulativeHeight = 0;
    for (let i = startIndex; i < items.length; i++) {
      const currentItemHeight = itemHeights[i] || itemHeight;
      cumulativeHeight += currentItemHeight + itemMargin;
      if (cumulativeHeight >= scrollOffset + height) {
        endIndex = i;
        break;
      }
    }

    return { startIndex, endIndex };
  };

  const { startIndex, endIndex } = calculateVisibleItems();
  let visibleItems = items.slice(startIndex, endIndex + 1);
  const totalHeight = items.reduce(
    (sum, _, index) => sum + (itemHeights[index] || itemHeight) + itemMargin,
    0,
  ) - itemMargin;

  // Handle the placeholder rendering and remove the moved element
  if (placeholderIndex !== null) {
    const adjustedItems = [...items];
    adjustedItems.splice(oldIndex, 1); // Remove the moved element from its old position
    visibleItems = adjustedItems.slice(startIndex, endIndex + 1);
    if (placeholderIndex >= startIndex && placeholderIndex <= endIndex) {
      visibleItems = [
        ...visibleItems.slice(0, placeholderIndex - startIndex),
        { ...movedElement, isPlaceholder: true },
        ...visibleItems.slice(placeholderIndex - startIndex),
      ];
    }
  }

  const renderItems = items.slice(0, startIndex);

  return (
    <div ref={listRef} style={{ height, overflowY: "auto" }}>
      <div style={{ height: totalHeight, position: "relative" }}>
        <ReactSortable
          data-id={dataId}
          setList={setList}
          list={items}
          group={group}
          animation={animation}
          easing={easing}
          chosenClass={chosenClass}
          onStart={onStart}
          onSort={onSort}
          onRemove={onRemove}
          onAdd={onAdd}
          onEnd={onEnd}
          onChange={onChange}
        >
          {renderItems.map((item, index) => (<div key={index}></div>))}
          {visibleItems.map((item, index) => {
            const absoluteIndex = startIndex + index;
            const isPlaceholder = item.isPlaceholder;

            return (
              <div
                key={item._id || index}
                style={{
                  position: "absolute",
                  top: items
                    .slice(0, absoluteIndex)
                    .reduce((sum, _, i) => sum + (itemHeights[i] || itemHeight) + itemMargin, 0) - itemMargin,
                  left: 0,
                  right: 0,
                  marginBottom: itemMargin,
                  paddingTop: 10,
                  paddingRight: 10,
                  visibility: isPlaceholder ? "hidden" : "visible",
                }}
              >
                {isPlaceholder ? (
                  <div style={{ height: itemHeight, backgroundColor: "rgba(0,0,0,0.1)" }} />
                ) : (
                  renderItem({
                    item,
                    index: absoluteIndex,
                    setItemHeight: (height) => handleSetItemHeight(absoluteIndex, height),
                  })
                )}
              </div>
            );
          })}
        </ReactSortable>
      </div>
    </div>
  );
};

export default VirtualizedList;
