/* eslint-disable space-before-function-paren */
import { Client } from "@microsoft/microsoft-graph-client";
let graphClient;

function ensureClient(authProvider) {
  if (!graphClient) {
    graphClient = Client.initWithMiddleware({
      authProvider,
    });
  }

  return graphClient;
}

export const getMails = async(authProvider, emails, query) => {
  ensureClient(authProvider);

  const mails = emails.map((item) => `participants:${item}`);

  const response = graphClient
    ?.api("/me/messages")
    .search(query?.$search || `"${mails.join(" ")}"`)
    .select(
      query?.$select ||
        "sender,subject,body,from,sentDateTime,categories,toRecipients,isRead,isDraft,isReadReceiptRequested",
    )
    .top(query?.top || 10);

  if (query?.$url) {
    const parseUrl = new URL(query?.$url);
    const skiptoken = parseUrl.searchParams.get("$skiptoken");

    if (skiptoken) {
      response.skipToken(skiptoken);
    }
  }
  if (query?.$skipToken) {
    response.skipToken(query?.$skipToken);
  }

  const contentType = await response.responseType();
  const messages = await response.get();
  return {
    queryPage: contentType.urlComponents.oDataQueryParams,
    ...messages,
  };
};

export async function getUser(authProvider) {
  ensureClient(authProvider);

  // Return the /me API endpoint result as a User object
  const user = await graphClient
    ?.api("/me")
    // Only retrieve the specific fields needed
    .select("displayName,mail,userPrincipalName")
    .get();

  return user;
}

export async function sendMessage(authProvider, { subject, content, emails }) {
  ensureClient(authProvider);
  const emailPromises = emails.map(async(email) => {
    const message = {
      message: {
        subject,
        body: {
          contentType: "HTML",
          content,
        },
        toRecipients: [
          {
            emailAddress: {
              address: email,
            },
          },
        ],
      },
    };

    return graphClient?.api("/me/sendMail").post(message);
  });
  return await Promise.all(emailPromises);
}
