export const RoutesLinks = {
  LOGIN_LINK: "/login",
  CHECKS_LINK: "/checks",
  ON_BOARDING_LINK: "/on-boarding",
  SHARED_LINK: "/shared/",
  REQUESTED_INFORMATION_LINK: "/requested-information",
  PUBLIC_EVENTS_LINK: "/public/events/",
  REGISTER_LINK: "/register/",
  REGISTER_CONFIRM: "/register/confirm",
  FORGOT_LINK: "/forgot",
  FORGOT_RESEND_LINK: "/forgot/resend",
  FORGOT_CONFIRM_LINK: "/forgot/confirm",
  ACCOUNT_LINK: "/account",
  DEALFLOW_LINK: "/dealflow/",
  QE_LINK: "/questionnaires-engine",
  QE_FORMS_LINK: "/questionnaires-engine/forms",
  QE_TEMPLATES_LINK: "/questionnaires-engine/templates",
  QE_QUESTIONS_WIZARD_LINK: "/questionnaires-engine/questions/wizard",
  QE_QUESTIONS_LINK: "/questionnaires-engine/questions",
  DASHBOARD_LINK: "/",
  GENERAL_INFORMATION: "/general-information",
  PIPELINE_MANAGER_LINK: "/pipeline-manager/",
  EVALUATIONS_LINK: "/evaluations/",
  SCORE_CARDS_LINK: "/evaluations/score-cards/",
  EVALUATION_PROCESSES_LINK: "/evaluations/evaluation-processes/",
  DOCUMENTATION_LINK: "/documentation/",
  MILESTONES_LINK: "/milestones/",
  PUBLIC_FORMS_LINK: "/public/forms/",
  PUBLIC_TEMPLATES_LINK: "/public/templates/",
  SHARED_REPORTS_LINK: "/shared/reports/",
  CONTACTS_LINK: "/contacts/",
  STARTUPS_LINK: "/startups",
  REPORTS_LINK: "/reports/",
  REMINDERS_LINK: "/reminders",
  RESOURCES_LINK: "/help/resources",
  CHAT_LINK: "/help/chat-with-us",
  FEEDBACK_LINK: "/help/feedback",
  SUPPORT_LINK: "/help/support",
  MEMBERS_LINK: "/members/",
  COMPANY_GENERAL_INFORMATION_LINK: "/company/general-information",
  COMPANY_TEAM_LINK: "/company/team",
  COMPANY_LPS_LINK: "/company/lps",
  COMPANY_INVESTMENT_THESIS_LINK: "/company/investment-thesis",
  COMPANY_SETTINGS_LINK: "/company/settings",
  DEALFLOW_PREFERENCES_LINK: "/dealflow-preferences",
  WEB_LINK: "/web",
  COMPANY_ROLES_LINK: "/company/roles",
  COMPANY_LINK: "/company/",
  PORFOLIO: "/portfolio",
};
