import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Loader } from "components/ui/molecules/Loader";
import { PageTitle } from "components/ui/atoms/PageTitle";

import { FaFilter, FaPlus, FaSearch } from "react-icons/fa";
import MembersFilters from "./MembersFilters";
import MembersSearcher from "./MembersSearcher";
import MembersList from "./MembersList";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import MemberSection from "./MemberSection";
import MembersService from "services/members-service";
import { useToasts } from "react-toast-notifications";
import { useSwal } from "hooks/useSwal";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";

const Members = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { confirm } = useSwal();

  const handleRedirect = (url) => navigate(url);
  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [showSearcher, setShowSearcher] = useState(false);
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [showMemberSection, setShowMemberSection] = useState(false);
  const [member, setMember] = useState(null);
  const [reloadMembers, setReloadMembers] = useState(false);

  const filterMembers = (filters) => {
    setCurrentPage(0);
    setFilters(filters);
  };

  const removeMember = (member) => {
    confirm({
      text: t("revert_notification"),
      icon: "warning",
    }).then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await MembersService.removeMember(member._id);
          addToast(t("member_deleted_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
          setMember(null);
          setShowMemberSection(false);
          filterMembers();
        } catch (error) {
          addToast(
            error.response && error.response.data.msg_key
              ? t(error.response.data.msg_key)
              : t("error_occurred_deleting_members"),
            {
              appearance: "error",
              autoDismiss: true,
            },
          );
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const sendActivationCode = (memberId) => {
    confirm({
      icon: "info",
    }).then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await MembersService.sendActivationCode(memberId);
          addToast(t("activation_code_sent_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
        } catch (error) {
          addToast(
            error.response && error.response.data.msg_key
              ? t(error.response.data.msg_key)
              : t("error_occurred_sending_activation_code"),
            {
              appearance: "error",
              autoDismiss: true,
            },
          );
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const saveMember = async(values) => {
    confirm({
      icon: "info",
    }).then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          if (!member) {
            await MembersService.createMember(values);
            addToast(t("member_created"), {
              appearance: "success",
              autoDismiss: true,
            });
          } else {
            await MembersService.updateMember(
              member._id,
              values.name,
              values.last_name,
              values.email,
              values.language,
            );
            addToast(t("member_updated"), {
              appearance: "success",
              autoDismiss: true,
            });
          }
          setShowMemberSection(false);
          setMember(null);
          setReloadMembers(true);
        } catch (error) {
          console.error(error);
          addToast(
            error.response
              ? error.response.data.msg_key
                ? t(error.response.data.msg_key)
                : t(member ? "error_occurred_updating_member" : "error_occurred_creating_member")
              : t(member ? "error_occurred_updating_member" : "error_occurred_creating_member"),
            {
              appearance: "error",
              autoDismiss: true,
            },
          );
        } finally {
          setLoading(false);
        }
      }
    });
  };

  useEffect(() => {
    if (member) {
      setShowMemberSection(true);
    }
  }, [member]);

  return (
    <>
      {showMemberSection && (
        <MemberSection
          onClose={() => setShowMemberSection(false)}
          onSubmit={saveMember}
          removeMember={removeMember}
          sendActivationCode={sendActivationCode}
          member={member}
        />
      )}
      <div className="flex items-start">
        <div className="flex-1">
          <PageTitle title={t("members")} removeMargin={true} />
        </div>

        {/* {!user?.help?.irm && <MiniHelp item={'irm'} type={'video'} url={'https://www.youtube.com/watch?v=LHDfyxf9BNc'} />} */}
        <ButtonMain
          marginRight={2}
          iconComponent={<FaPlus className="inline-block mr-2" />}
          text={t("add_member")}
          onClick={() => setShowMemberSection(true)}
        />
        <div className='flex'>
          <ButtonSecondary
            iconComponent={<FaFilter className='h-4'/>}
            onClick={() => setShowFilters(true)}
          />
        </div>
      </div>
      {loading && <Loader />}

      <MembersList
        sendActivationCode={sendActivationCode}
        setFilters={setFilters}
        reloadMembers={reloadMembers}
        setReloadMembers={setReloadMembers}
        filters={filters}
        filterMembers={filterMembers}
        loading={loading}
        setLoading={setLoading}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setMember={setMember}
      />

      {showSearcher && (
        <MembersSearcher
          onClose={() => setShowSearcher(false)}
          onFilter={filterMembers}
          filters={filters}
        />
      )}
      {showFilters && (
        <MembersFilters
          onClose={() => setShowFilters(false)}
          onFilter={filterMembers}
          filters={filters}
        />
      )}
    </>
  );
};

export default Members;
