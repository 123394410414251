import * as yup from "yup";

const yupObjectId = yup.string().matches(/^[0-9a-fA-F]{24}$/, "Must be a valid ObjectId");

export const entrepreneurSchema = yup.object().shape({
  enabled: yup.boolean().default(true),
  name: yup.string(),
  born_date: yup.date(),
  image: yup.string(), // Assuming this is a URL or S3 key
  email: yup.string().email("Must be a valid email"),
  phone_number: yup.string(),
  location: yup.string(),
  age: yup.number().integer().positive(),
  bio: yup.string(),
  document: yup.string(),
  education_level: yup.string(),
  academic_education: yup.string(),
  certifications: yup.array().of(yup.string()),
  professional_experience: yup.string(),
  prev_relevant_projects: yup.string(),
  startups: yup.array().of(
    yup.object().shape({
      startup: yupObjectId,
      role: yup.string(),
      role_description: yup.string(),
      shares: yup.number().integer().positive(),
      incorporation_date: yup.date(),
    }),
  ),
  technical_skills: yup.array().of(yup.string()),
  soft_skills: yup.array().of(yup.string()),
  languages: yup.array().of(
    yup.object().shape({
      language: yup.string(),
      level: yup.string(),
    }),
  ),
  awards: yup.array().of(yup.string()),
  publications: yup.array().of(yup.string()),
  patents: yup.array().of(yup.string()),
  social_media: yup.object().shape({
    linkedin: yup.string().url("Must be a valid URL"),
    web: yup.string().url("Must be a valid URL"),
    github: yup.string().url("Must be a valid URL"),
  }),
  cv: yup.string(), // Assuming this is a URL or S3 key
  portfolio: yup.string(), // Assuming this is a URL or S3 key
  presentation_video: yup.string(), // Assuming this is a URL or S3 key
  work_life: yup.object(),
  personal_motivation: yup.string(),
  long_term_vision: yup.string(),
  mentors: yup.array().of(yup.string()),
  psychometric_evaluation: yup.object(),
  referrals: yup.array().of(yup.string()),
  associated_mentors: yup.array().of(yupObjectId),
});
