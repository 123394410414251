import { Trans, useTranslation } from "react-i18next";
import { Input } from "components/ui/atoms/Input";
import {
  FaChevronCircleDown,
  FaChevronCircleUp,
  FaExclamationCircle,
  FaInfoCircle,
  FaMinusCircle,
  FaPlusCircle,
} from "react-icons/fa";
import { ReactSortable } from "react-sortablejs";
import TranslateUtils from "utils/translate-utils";
import { QuestionsUtils } from "utils/questions-utils";
import { Switch } from "components/ui/atoms/Switch";
import { Question } from "components/qe-questions/components/Question";
import ReactTooltip from "react-tooltip";
import { useEffect, useState } from "react";
import { ButtonDanger } from "components/ui/atoms/ButtonDanger";
import Config from "config";
import { useSwal } from "hooks/useSwal";
import { omit } from "lodash";
import FormsService from "services/forms-service";

export const FormQuestions = ({
  selectedQuestions,
  setSelectedQuestions,
  lang,
  requiredQuestions,
  setRequiredQuestions,
  filteredQuestions,
  setFilteredQuestions,
  keyQuestions,
  setKeyQuestions,
  questions,
  processQuestions,
  selectedExpandedQuestions,
  targetRole,
}) => {
  const TOTAL_KEY_QUESTIONS = 30;
  const { alert, confirm } = useSwal();

  const [KEY_QUESTIONS_ASSOCIATION, SET_KEY_QUESTIONS_ASSOCIATION] = useState({
    startup_name: "62a9be4527d02e4204a5cdbc",
    brand_statement: "636a7265f07fe15babfe7cc5",
    cif: "650c5189c714472bf34f1098",
    startup_logo: "64c12715b328c99408635fcd",
    problem: "62a9e43027d02e4204a5cdc9",
    solution: "62a9e49a27d02e4204a5cdcc",
    market: "64c1026fb328c99408635f61",
    business_model: "64c0feed1f027a82b8c7e4be",
    business_description: "64c126deb328c99408635fc6",
    sector: "64c0feed1f027a82b8c7e4ba",
    investment_stage: "64c0feed1f027a82b8c7e4bc",
    project_stage: "64c0feed1f027a82b8c7e4bb",
    country: "64c0feed1f027a82b8c7e4bd",
    city: "64c126a3b328c99408635fc1",
    startup_deck: "64c1268cb328c99408635fbc",
    one_pager: "64c12662b328c99408635fb7",
    financials: "6596c3c07759ab30d1d4d115",
    business_plan: "6596c4067759ab30d1d4d117",
    kpis: "6596c3e37759ab30d1d4d116",
    main_contact_email: "64c125f2b328c99408635fac",
    main_contact_person: "64c125d3b328c99408635fa7",
    main_contact_phone: "64c125c1b328c99408635fa2",
    web: "64c125a1b328c99408635f9d",
    linkedin: "64c1258db328c99408635f98",
    crunchbase: "64c8b63dc97f7d159465eecd",
    /* facebook: '62a9be4527d02e4204a5cdbc',
    twitter: '62a9be4527d02e4204a5cdbc',
    instagram: '62a9be4527d02e4204a5cdbc',
    youtube: '62a9be4527d02e4204a5cdbc',
    tiktok: 62a9be4527d02e4204a5cdbc', */
    investment_desired: "64c124dcb328c99408635f8f",
    valuation: "64c124aab328c99408635f86",
    compromised_amount: "64c1249ab328c99408635f83",
    minimum_ticket: "64c12481b328c99408635f80",
    referrals: "65803605099f934e6f269f05",
  });

  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  const expandOrCollapseQuestionsList = (elementId) => {
    const element = document.getElementById(elementId);
    element.classList.toggle("hidden");
    setCollapsed(!collapsed);
  };

  const onSort = (evt) => {
    const itemToSort = { ...selectedQuestions[evt.oldIndex] };
    const tmpSelectedQuestions = [...selectedQuestions];

    tmpSelectedQuestions.splice(evt.oldIndex, 1);
    tmpSelectedQuestions.splice(
      evt.newIndex < evt.oldIndex ? evt.newIndex : evt.oldIndex + evt.newIndex,
      0,
      itemToSort,
    );

    setSelectedQuestions(tmpSelectedQuestions);
  };

  const onRemoveQuestion = (question) => {
    confirm().then((isConfirmed) => {
      if (isConfirmed) {
        if (question.type !== "questions_group") {
          const tmpSelectedQuestions = [...selectedQuestions];
          tmpSelectedQuestions.splice(
            tmpSelectedQuestions.findIndex((q) => q._id === question._id),
            1,
          );
          setSelectedQuestions(tmpSelectedQuestions);
          const keyQuestionsKey = Object.keys(keyQuestions).filter(
            (key) => keyQuestions[key] === question._id,
          );
          setKeyQuestions({ ...omit(keyQuestions, keyQuestionsKey) });
        } else {
          const tmpSelectedQuestions = [...selectedQuestions];
          tmpSelectedQuestions.splice(
            tmpSelectedQuestions.findIndex((q) => q._id === question._id),
            1,
          );
          setSelectedQuestions(tmpSelectedQuestions);

          const keyQuestionsKey = Object.keys(keyQuestions).filter((key) =>
            question.questionsGroup.includes(keyQuestions[key]),
          );
          setKeyQuestions({ ...omit(keyQuestions, keyQuestionsKey) });
        }
      }
    });
  };

  const onMakeRequired = (question, value) => {
    setRequiredQuestions({
      ...requiredQuestions,
      [question._id]: value,
    });
  };

  const onSearch = (e) => {
    const search = e.target.value;
    setFilteredQuestions(processQuestions(questions, search));
  };

  const questionIsSelected = (questionId) => !!selectedQuestions.find((q) => q._id === questionId);

  const onAddQuestion = (question) => {
    // confirm().then(isConfirmed => {
    // if (isConfirmed) {
    if (
      question.type === "questions_group" &&
      question.questionsGroup.some((qg) => selectedExpandedQuestions.find((q) => q._id === qg))
    ) {
      alert({ text: t("question_group_contains_question_in_form") });
    } else if (selectedExpandedQuestions.find((q) => q._id === question._id)) {
      alert({ text: t("question_already_added") });
    } else {
      if (question.type !== "questions_group") {
        const keyQuestionAssociated = Object.keys(KEY_QUESTIONS_ASSOCIATION).find(
          (key) => KEY_QUESTIONS_ASSOCIATION[key] === question._id,
        );
        if (keyQuestionAssociated) {
          setKeyQuestions({ ...keyQuestions, [keyQuestionAssociated]: question._id });
        }
        setSelectedQuestions([...selectedQuestions, question]);
      } else {
        const keyQuestionsToUpdate = { ...keyQuestions };

        question.questionsGroup.forEach((qg) => {
          const keyQuestionAssociated = Object.keys(KEY_QUESTIONS_ASSOCIATION).find(
            (key) => KEY_QUESTIONS_ASSOCIATION[key] === qg,
          );
          if (keyQuestionAssociated) {
            keyQuestionsToUpdate[keyQuestionAssociated] = qg;
          }
        });
        setKeyQuestions(keyQuestionsToUpdate);
        setSelectedQuestions([...selectedQuestions, question]);
      }
    }
    // }
    // })
  };

  const expandOrCollapse = (elementId) => {
    const element = document.getElementById(elementId);
    const icon = document.getElementById(`${elementId}_icon`);
    element.classList.toggle("hidden");
    icon.classList.toggle("rotate-180");
    setTimeout(() => ReactTooltip.rebuild(), 200);
  };

  const addKeyQuestionToForm = (keyQuestion) => {
    /* confirm().then(isConfirmed => {
      if (isConfirmed) { */
    // Busca la pregunta clave
    const question = questions.find((q) => q._id === KEY_QUESTIONS_ASSOCIATION[keyQuestion]);
    if (question) {
      if (selectedExpandedQuestions.find((q) => q._id === question._id)) {
        alert({ text: t("question_already_added") });
        // añade la pregunta si la ha encontrado
      } else {
        setSelectedQuestions([...selectedQuestions, question]);
        setKeyQuestions({ ...keyQuestions, [keyQuestion]: question._id });
      }
    } else {
      alert({ text: t("question_not_available") });
    }
    /* }
  }) */
  };

  const deleteKeyQuestionFromForm = (keyQuestion) => {
    if (selectedQuestions.find((q) => q._id === keyQuestions[keyQuestion])) {
      confirm().then((isConfirmed) => {
        if (isConfirmed) {
          const tmpSelectedQuestions = [...selectedQuestions];
          // Find the index of the question (if it exists)
          const indexToDelete = tmpSelectedQuestions.findIndex(
            (q) =>
              q._id ===
              (questions.find((q) => q._id === KEY_QUESTIONS_ASSOCIATION[keyQuestion]) || {})._id,
          );
          if (indexToDelete !== -1) {
            // Remove the question from the temporary array
            tmpSelectedQuestions.splice(indexToDelete, 1);
            // Update state with the modified array
            setSelectedQuestions(tmpSelectedQuestions);
            setKeyQuestions({ ...omit(keyQuestions, [keyQuestion]) });
          } else {
            // Alert for questions not deleted
            alert({ text: t("question_not_removed") });
          }
        }
      });
    } else {
      alert({ text: t("key_question_included_in_question_group") });
    }
  };

  const getQuestions = () => {
    const questions = Object.keys(filteredQuestions).map((tag, index) => (
      <div key={`filtered_${index}`}>
        <div
          onClick={() => expandOrCollapse(`${tag}_${index}`)}
          className="border-b border-gray-lines font-bold cursor-pointer flex flex-row justify-between hover:bg-gray-100 px-2 hover:rounded-md transition-colors  mt-6"
        >
          <div className="text-xs">
            {tag === "other_questions" ? t(tag) : filteredQuestions[tag][0].investor ? tag : t(tag)}
            &nbsp; ({filteredQuestions[tag].length})
          </div>
          <div id={`${tag}_${index}_icon`} className="flex justify-center items-center">
            <FaChevronCircleDown data-panel={1} className={"text-main inline-block mt-1"} />
          </div>
        </div>
        <div id={`${tag}_${index}`} className="mt-2 hidden">
          {filteredQuestions[tag].map((question) => (
            <div className="my-2" key={question._id}>
              <Question
                key={question._id}
                question={question}
                showTag={false}
                iconSmall={true}
                openEditInTab={true}
                isSelected={questionIsSelected(question._id)}
                addOrRemoveQuestion={(question) => {
                  if (questionIsSelected(question._id)) {
                    onRemoveQuestion(question);
                  } else {
                    onAddQuestion(question);
                  }
                }}
              />
            </div>
          ))}
        </div>
      </div>
    ));
    return questions;
  };

  const handleAddOrRemoveKeyQuestions = () => {
    if (Object.keys(keyQuestions).length < TOTAL_KEY_QUESTIONS) {
      const questionsToAdd = [];
      const keyQuestionsToAdd = {};
      Object.keys(KEY_QUESTIONS_ASSOCIATION).forEach((keyQuestion) => {
        if (!keyQuestions[keyQuestion]) {
          const question = questions.find((q) => q._id === KEY_QUESTIONS_ASSOCIATION[keyQuestion]);
          questionsToAdd.push(question);
          keyQuestionsToAdd[keyQuestion] = question._id;
        }
      });

      setSelectedQuestions([...selectedQuestions, ...questionsToAdd]);
      setKeyQuestions({ ...keyQuestions, ...keyQuestionsToAdd });
    } else {
      const tmpSelectedQuestions = [...selectedQuestions];
      const keyQuestionToRemove = [];
      Object.keys(keyQuestions).forEach((keyQuestion) => {
        const index = tmpSelectedQuestions.findIndex(
          (q) =>
            q._id === questions.find((q) => q._id === KEY_QUESTIONS_ASSOCIATION[keyQuestion])._id,
        );
        if (index > -1) {
          tmpSelectedQuestions.splice(
            tmpSelectedQuestions.findIndex(
              (q) =>
                q._id ===
                questions.find((q) => q._id === KEY_QUESTIONS_ASSOCIATION[keyQuestion])._id,
            ),
            1,
          );
          keyQuestionToRemove.push(keyQuestion);
        }
      });
      setSelectedQuestions(tmpSelectedQuestions);
      setKeyQuestions({ ...omit(keyQuestions, keyQuestionToRemove) });

      if (keyQuestionToRemove.length !== TOTAL_KEY_QUESTIONS) {
        alert({ text: t("some_key_questions_were_not_remove_grouped_questions") });
      }
    }
  };

  const getKeyQuestionsAssociations = async() => {
    // const res = await FormsService.getQuestionnaireKeyQuestions(targetRole);

    // const keyQuestions = res.data.data;

    // const parsed = {};

    // keyQuestions.forEach((keyQuestion) => {
    //   parsed[keyQuestion.title.es] = keyQuestion._id;
    // });

    // SET_KEY_QUESTIONS_ASSOCIATION(parsed);
  };

  useEffect(() => {
    getKeyQuestionsAssociations();
  }, []);

  return (
    <>
      <div className="w-full flex flex-row my-6">
        <div className="w-1/3 flex flex-col pr-4">
          {/* <div className="w-full px-1 text-xl border-b my-4 font-semibold">{t('content')}</div> */}

          {selectedQuestions.length === 0 ? (
            <div
              className={"w-full py-36 text-center justify-center flex flex-col text-xs text-main border-main border-dotted border-2 rounded-lg px-4 py-2 font-semibold"}
            >
              {t("add_first_question_to_form")}
            </div>
          ) : (
            <div
              className={"w-full text-center justify-center flex flex-col text-xs bg-main text-white rounded-lg px-4 py-2 font-semibold"}
            >
              {t("sort_questions")}
            </div>
          )}

          {selectedQuestions.length !== 0 && (
            <div className={"border border-main border-dotted border-2 rounded-lg my-2 p-2"}>
              <ReactSortable
                group="grupo1"
                data-id={"selected"}
                setList={() => {}}
                list={selectedQuestions}
                className="flex items-start h-full my-4 flex flex-col"
                animation={200}
                easing="cubic-bezier(0.25, 1, 0.5, 1)"
                onSort={onSort}
              >
                {selectedQuestions.map((q) => (
                  <div
                    key={`selected_${q._id}`}
                    data-id={`selected_${q._id}`}
                    className="w-full cursor-move"
                    data-tip={t("hold_and_slide_question")}
                  >
                    <div className="mb-2 bg-white rounded-2xl p-2 h-18 w-full">
                      <div className="flex">
                        <div className="w-8">{QuestionsUtils.getIcon(q.type, true)}</div>
                        <div className="w-full mx-1 px-2 text-xs font-semibold truncate">
                          {TranslateUtils.getTextByLang(lang, q.title)}
                        </div>
                        <div className="w-14 h-7 rounded-xl flex flex-row justify-center items-center">
                          <div className="ml-1">
                            <ButtonDanger
                              onClick={() => onRemoveQuestion(q)}
                              iconComponent={<FaMinusCircle className="inline" />}
                            />
                          </div>
                        </div>
                      </div>
                      {q.type !== "separator" && (
                        <div className="flex justify-start my-2">
                          <Switch
                            size="sm"
                            checked={requiredQuestions[q._id] || false}
                            onChange={(value) => onMakeRequired(q, value)}
                            text={t("is_this_question_required")}
                            textSize={"xs"}
                          />
                        </div>
                      )}
                      {q.type !== "questions_group" &&
                        Object.keys(keyQuestions).some((key) => keyQuestions[key] === q._id) && (
                          <div className="text-xxxs mt-4">
                            <FaExclamationCircle className="inline mr-2" />
                            {t("key_question_mark")}
                          </div>
                      )}
                      {q.type === "questions_group" &&
                        Object.keys(keyQuestions).some((key) =>
                          q.questionsGroup.includes(keyQuestions[key]),
                        ) && (
                          <div className="text-xxxs mt-4">
                            <FaExclamationCircle className="inline mr-2" />
                            {t("question_group_key_question_mark")}
                          </div>
                      )}
                    </div>
                  </div>
                ))}
              </ReactSortable>
            </div>
          )}
        </div>

        <div className={"border-l border-gray-lines px-4 w-2/3"}>
          <div className="w-full px-1 my-4 font-semibold text-xl border-b">{t("questions")}</div>

          {
            <div
              className={"w-full rounded-lg p-2 mb-4 bg-white border-dotted border-border-buttons-secondary border-2"}
            >
              <div
                className="mb-2 flex justify-between cursor-pointer"
                onClick={() => expandOrCollapseQuestionsList("key_questions_list")}
              >
                <div className="flex items-center whitespace-pre-wrap">
                  <FaInfoCircle className="inline-block relative mr-2" />
                  <Trans
                    i18nKey="key_questions_selected_description"
                    values={{
                      key_questions_selected: Object.keys(keyQuestions).length,
                      total_key_questions: TOTAL_KEY_QUESTIONS,
                    }}
                    components={{ bold: <strong /> }}
                  />
                </div>
                <div>
                  {collapsed ? (
                    <FaChevronCircleDown
                      data-panel={"key_questions_list"}
                      className={"inline-block"}
                    />
                  ) : (
                    <FaChevronCircleUp
                      data-panel={"key_questions_list"}
                      className={"inline-block"}
                    />
                  )}
                </div>
              </div>
              <div className="text-xs mx-6 mb-6">{t("key_questions_selected_subdescription")}</div>
              <div id={"key_questions_list"}>
                <div className="my-2 flex justify-end text-xs">
                  <div
                    className={"cursor-pointer hover:text-main hover:font-bold"}
                    onClick={() => handleAddOrRemoveKeyQuestions()}
                  >
                    {Object.keys(keyQuestions).length === TOTAL_KEY_QUESTIONS
                      ? t("delete_all_key_questions")
                      : t("add_all_key_questions")}
                  </div>
                </div>
                <>
                  <ul className="list-disc px-8">
                    {Object.keys(KEY_QUESTIONS_ASSOCIATION).map((keyQuestion) => (
                        <li key={keyQuestion} className="mb-1 flex border-b items-center">
                          <div className="font-bold text-base">{t(keyQuestion)}</div>
                          <div
                            className="ml-2 cursor-pointer"
                            data-tip={t(
                              !keyQuestions[keyQuestion]
                                ? "add_question_to_form"
                                : "delete_question_from_form",
                            )}
                          >
                            {!keyQuestions[keyQuestion] && (
                              <FaPlusCircle onClick={() => addKeyQuestionToForm(keyQuestion)} />
                            )}
                            {keyQuestions[keyQuestion] && (
                              <FaMinusCircle
                                className="text-red"
                                onClick={() => deleteKeyQuestionFromForm(keyQuestion)}
                              />
                            )}
                          </div>
                        </li>
                    ))}
                  </ul>
                </>
              </div>
            </div>
          }

          <div className="-mb-5 flex-1">
            <Input placeholder={t("search")} onChange={onSearch} />
          </div>
          {getQuestions()}
        </div>
      </div>
    </>
  );
};
