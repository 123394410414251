import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Trans, useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { BannerResponsive } from "components/ui/atoms/BannerResponsive";
import { Checkbox } from "components/ui/molecules/Checkbox";
import { BottomText } from "components/ui/atoms/BottomText";
import { FormTitle } from "components/ui/atoms/FormTitle";
import ShowPassword from "assets/images/ShowPassword.png";
import { RoutesLinks } from "components/routes-links";

import { Input } from "components/ui/atoms/Input";
import moment from "moment";
import { PoweredText } from "components/ui/atoms/PoweredText";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import Lang from "lang";

const Form = ({ onSubmit, loading }) => {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const platform = useSelector((state) => state.app.platform);
  const handleShowhide = () => {
    setShowPassword(!showPassword);
  };

  const handleShowhideConfirm = () => {
    setShowConfirm(!showConfirm);
  };

  const RegisterSchema = Yup.object().shape({
    password_register: Yup.string()
      .required(t("required_field"))
      .min(8, t("password_length"))
      .matches(/^.*(?=.*?[A-Z]).*$/, t("password_req_uppercase"))
      .matches(/^.*(?=.*?[a-z]).*$/, t("password_req_lowercase"))
      .matches(/^.*(?=.*?[0-9]).*$/, t("password_req_number"))
      .matches(
        /^.*(?=.*?[\^ $ * . [ \] { } ( ) ? " ! @ # % & , > < ' : ; _ ~ ` \\ / |]).*$/,
        t("password_req_special_char"),
      ),

    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password_register"), null], t("passwords_do_not_match"))
      .required(t("required_field")),

    accept_terms: Yup.boolean().oneOf([true], t("accept_terms_required")),
  });

  const { register, handleSubmit, errors, setValue, trigger, getValues } = useForm({
    resolver: yupResolver(RegisterSchema, { abortEarly: false }),
    criteriaMode: "all",
    reValidateMode: "all",
    mode: "onChange",
  });

  return (
    <section className="flex flex-col justify-center items-center | min-h-screen w-full  | sm:pt-0 lg:p-16">
      <div className="max-w-xs mx-2 mt-16 sm:max-w-md 2xl:max-w-lg sm:mt-32 md:mt-0 lg:pt-4">
        <BannerResponsive />
        <FormTitle
          formTitle={<Trans i18nKey="started" values={{ platform_name: platform.name }} />}
          formSubtitle={t("started_subtitle")}
        />
        <form className="w-full">
          <div>
            <Input
              reference={register}
              id="passwordRegister"
              name="password_register"
              type={showPassword ? "text" : "password"}
              label={t("password") + ": *"}
              placeholder={t("password_req_min_char")}
              error={errors}
              icon={ShowPassword}
              show={handleShowhide}
              isRegister="isRegister"
              disabled={loading}
            />
          </div>
          <Input
            disabled={loading}
            reference={register}
            id="confirm_password"
            name="password_confirmation"
            type={showConfirm ? "text" : "password"}
            label={t("confirm_password") + ": *"}
            placeholder={t("password_req_min_char")}
            error={errors.password_confirmation}
            icon={ShowPassword}
            show={handleShowhideConfirm}
          />
          <Checkbox
            id="accept_terms"
            label={
              <a
                href={platform.terms_and_conditions[Lang.getCurrentLang().code]}
                target="_blank"
                className={"underline inline text-link hover:text-link-hover"}
                rel="noreferrer"
              >
                {<Trans i18nKey="privacy_policy" values={{ platform_name: platform.name }} />}
              </a>
            }
            name="accept_terms"
            reference={register}
            error={errors.accept_terms}
            disabled={loading}
          />

          <div className="my-4">
            <ButtonMain
              width={"full"}
              type="button"
              onClick={async() => {
                if (await trigger()) { onSubmit(getValues()); }
              }}
              text={t("sign_up")}
              disabled={loading}
            />
          </div>

          <span className="text-xs text-gray font-regular">
            {t("already_have_an_account")}
            <Link
              to={`${RoutesLinks.LOGIN_LINK}`}
              className={"underline inline text-main hover:text-link-hover"}
            >
              {t("go_to_login")}
            </Link>
          </span>
        </form>
      </div>

      <BottomText
        text={
          <Trans
            i18nKey="copyright"
            values={{ year: moment().format("YYYY"), platform_name: platform.name }}
          />
        }
      />
      {platform && (platform.cobranding || platform.white_label) && <PoweredText />}
    </section>
  );
};

export default Form;
