import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { Card } from "components/ui/atoms/Card";
import { useTranslation } from "react-i18next";
import Subsectors from "assets/json/subsectors.json";

import ReactTooltip from "react-tooltip";
import Utils from "../../utils/utils";
import moment from "moment";
import {
  FaCalendarAlt,
  FaEnvelope,
  FaHandHoldingUsd,
  FaHandshake,
  FaInfoCircle,
  FaLightbulb,
  FaLink,
  FaMapMarkerAlt,
  FaPhone,
  FaQuestion,
  FaUser,
} from "react-icons/fa";
import { BsBarChartSteps } from "react-icons/bs";
import { BiTargetLock } from "react-icons/bi";
import { MdPriceChange } from "react-icons/md";
import Linkedin from "../../assets/images/linkedin.png";
import Twitter from "../../assets/images/twitter.png";
import Instagram from "../../assets/images/instagram.png";
import Youtube from "../../assets/images/youtube.png";
import Facebook from "../../assets/images/facebook.png";
import { FiTarget } from "react-icons/fi";
import { NoDataInfo } from "../ui/atoms/NoDataInfo";
import DeckIcon from "styles/images/deck_icon.png";
import OnePagerIcon from "styles/images/one_pager_icon.png";
import FinancialsIcon from "styles/images/financials_icon.png";
import Crunchbase from "../../assets/images/Crunchbase.png";

import { SidePanel } from "components/ui/objects/SidePanel";
import { ReferralsBar } from "./ReferralsBar";
import DocumentPreview from "components/documentation/documents/components/DocumentPreview";
import DocumentsService from "services/documents-service";
import { useToasts } from "react-toast-notifications";

export const DealResumeSection = ({ deal, onClose, section = "pool", downloadDocument }) => {
  const { addToast } = useToasts();

  const [isLoading, setIsLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [deal]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [deal]);

  const { t } = useTranslation();

  const previewDocument = async (document) => {
    setIsLoading(true);
    setPreviewUrl(null);
    try {
      let response = null;

      response = await DocumentsService.getDocumentPreview(document._id);

      if (response?.data?.data?.url) {
        setPreviewUrl(response?.data?.data?.url);
        setSelectedDocument(document);
        setShowPreview(true);
      }
    } catch (error) {
      addToast(
        error?.response?.data?.msg_key
          ? t(error.response.data.msg_key)
          : t("error_occurred_downloading_document"),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
    } finally {
      setIsLoading(false);
    }
  };

  const MAIN_DOCUMENTS = [
    {
      name: "dc_deck",
      icon: DeckIcon,
      document: deal.deck,
    },
    {
      name: "dc_one_pager",
      icon: OnePagerIcon,
      document: deal.one_pager,
    },
    {
      name: "dc_financials",
      icon: FinancialsIcon,
      document: deal.financials,
    },
  ];

  const socialNetworks = [
    {
      name: "linkedin",
      icon: Linkedin,
      url: deal.linkedin,
    },
    {
      name: "twitter",
      icon: Twitter,
      url: deal.twitter,
    },
    {
      name: "instagram",
      icon: Instagram,
      url: deal.instagram,
    },
    {
      name: "youtube",
      icon: Youtube,
      url: deal.youtube,
    },
    /* {
      name: 'tiktok',
      icon: Tiktok,
      url: deal.tiktok
    }, */
    {
      name: "crunchbase_profile",
      icon: Crunchbase,
      url: deal.crunchbase_url,
    },
    {
      name: "facebook",
      icon: Facebook,
      url: deal.facebook,
    },
  ].sort((a, b) => {
    if (a.url && b.url) {
      return a.url.length - b.url.length;
    } else if (a.url) {
      return -1;
    } else if (b.url) {
      return 1;
    }
    return 0;
  });

  const getSectors = () => [t(deal.sector?.key), t(deal.subsector?.key)].filter(Boolean).join(", ");

  const getOtherInterestingAreas = () =>
    Subsectors.filter((subsector) => (deal.other_interesting_areas || []).includes(subsector._id))
      .map((subsector) => t(subsector.key))
      .join(", ");

  const renderBadge = (title, icon, value) => (
    <span
      data-tip={title}
      className={
        "rounded bg-gray-100 flex justify-between items-center gap-2 px-2 py-1 hover:bg-main hover:text-white parent-hover:text-white"
      }
    >
      <span className={"text-main"}>{icon}</span>
      <span className="font-semibold">{value}</span>
    </span>
  );

  return (
    <>
      <SidePanel width={"2/5"} onClose={onClose} title={t("general_information")}>
        <div className="mt-4">
          <div className="flex flex-col">
            <div className="w-full">
              <Card>
                <div className="flex">
                  <div className="mr-4">
                    <Avatar
                      src={deal.logo}
                      alt={deal.name}
                      color="#e0e6f2"
                      fgColor="#4d70b3"
                      size="100px"
                      round={false}
                      className={"avatar-startup"}
                    />
                  </div>
                  <div>
                    <h1 className={"text-4xl text-main mb-2 font-bold"}>
                      {deal.name}
                      {deal.web && (
                        <FaLink
                          className="inline ml-2 h-4 cursor-pointer"
                          onClick={() => window.open(deal.web, "_blank")}
                        />
                      )}
                    </h1>
                    <div>
                      <p className="font-bold">{deal.sector && getSectors()}</p>
                      <p className="text-xs">{getOtherInterestingAreas()}</p>
                    </div>
                    <div className="flex flex-row">
                      <div className="w-auto">{Utils.getDealflowStatus(deal, t)}</div>
                      {deal.priority && (
                        <div className="w-auto">{Utils.getDealflowPriority(deal, t)}</div>
                      )}
                      {deal.next_step && (
                        <div className="w-auto">{Utils.getDealflowNextStep(deal, t)}</div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex-1 mt-2 pt-2 border-t border-gray-lines">
                  {deal.brand_statement && (
                    <pre className="font-sans whitespace-pre-wrap">{deal.brand_statement}</pre>
                  )}
                  {!deal.brand_statement && (
                    <span className="text-gray font-normal text-xs">
                      {t("brand_statement_not_provided")}
                    </span>
                  )}
                </div>

                {(deal.country_incorporation ||
                  deal.city_incorporation ||
                  deal.creation_date ||
                  deal.project_stage ||
                  deal.investment_stage ||
                  deal.business_model ||
                  deal.minimum_ticket ||
                  deal.investment_desired ||
                  deal.valuation) && (
                  <div className="flex flex-row flex-wrap mt-4 gap-2 border-t border-gray-lines">
                    {(deal.country_incorporation || deal.city_incorporation) &&
                      renderBadge(
                        t("location"),
                        <FaMapMarkerAlt />,
                        [t(deal.country_incorporation?.key), deal.city_incorporation]
                          .filter(Boolean)
                          .join(", "),
                      )}

                    {deal.creation_date &&
                      renderBadge(
                        t("creation_date"),
                        <FaCalendarAlt />,
                        moment(deal.creation_date).format("DD/MM/YYYY"),
                      )}

                    {deal.project_stage &&
                      renderBadge(
                        t("project_stage"),
                        <BsBarChartSteps />,
                        t(deal.project_stage.key),
                      )}
                    {deal.investment_stage &&
                      renderBadge(
                        t("investment_stage"),
                        <BsBarChartSteps />,
                        t(deal.investment_stage.key),
                      )}

                    {deal.business_model &&
                      renderBadge(
                        t("business_model"),
                        <BiTargetLock />,
                        t(deal.business_model.key),
                      )}

                    {deal.minimum_ticket &&
                      renderBadge(
                        t("minimum_ticket"),
                        <MdPriceChange />,
                        `${deal.minimum_ticket} ${t(deal.minimum_ticket_unit)}`,
                      )}

                    {deal.investment_desired &&
                      renderBadge(
                        t("investment_desired"),
                        <FaHandHoldingUsd />,
                        `${deal.investment_desired} ${t(deal.investment_desired_unit)}`,
                      )}

                    {deal.valuation &&
                      renderBadge(
                        t("valuation"),
                        <FaHandHoldingUsd />,
                        `${deal.valuation} ${t(deal.valuation_unit)}`,
                      )}
                  </div>
                )}
                <div className="flex-1 border-t border-gray-lines pt-2 flex text-xs mt-2">
                  <FaUser className={"text-main mr-1"} data-tip={t("main_contact_person")} />
                  {deal.main_contact_person || "-"}
                  <a
                    className="flex items-center"
                    href={
                      deal.main_contact_email !== undefined
                        ? `mailto:${deal.main_contact_email}`
                        : ""
                    }
                  >
                    <FaEnvelope
                      className={"text-main ml-4 mr-1"}
                      data-tip={t("main_contact_email")}
                    />
                    {deal.main_contact_email || "-"}
                  </a>
                  <FaPhone className={"text-main ml-4 mr-1"} data-tip={t("main_contact_phone")} />
                  {deal.main_contact_phone || "-"}
                  <FaInfoCircle className={"text-main ml-4 mr-1"} data-tip={t("cif")} />
                  {deal.cif || "-"}
                </div>
              </Card>
            </div>

            <div className="w-full my-4">
              <Card wrapperClassName={"mb-4"}>
                <h3 className={"text-main"}>{t("referrals")}</h3>
                <div className="flex-1">
                  <ReferralsBar referrals={deal.referrals || []} />
                </div>
              </Card>
              <Card wrapperClassName={"mb-4"}>
                <h3 className={"text-main"}>{t("main_documents")}</h3>
                <div className="grid grid-cols-3 gap-4 py-4">
                  {MAIN_DOCUMENTS.map(({ name, icon, document }) => (
                    <div key={name} className="flex justify-center flex-row" data-tip={t(name)}>
                      <img
                        src={icon}
                        alt={name}
                        className={
                          !document || document === ""
                            ? "opacity-25 filter grayscale w-12"
                            : "w-12 cursor-pointer"
                        }
                        onClick={
                          !document || document === ""
                            ? null
                            : document.link
                              ? () => window.open(document.link, "_blank")
                              : () => previewDocument(document)
                        }
                      />
                    </div>
                  ))}
                </div>
              </Card>
              <Card>
                <h3 className={"text-main"}>{t("social_networks")}</h3>
                <div className="grid grid-cols-3 gap-4 py-4">
                  {socialNetworks.map(({ name, icon, url }) => (
                    <div key={name} className="flex justify-center flex-row">
                      <a
                        target="_blank"
                        href={url}
                        rel="noreferrer"
                        data-tip={!url || url === "" ? null : t(name)}
                        className={!url || url === "" ? "opacity-25 filter grayscale" : ""}
                      >
                        <img
                          src={icon}
                          alt={name}
                          className={`${name === "crunchbase_profile" ? "w-10" : "w-8"}`}
                        />
                      </a>
                    </div>
                  ))}
                </div>
              </Card>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-4 my-8">
            {["problem", "solution", "business_description", "market"].map((key) => (
              <div key={key} className="relative bg-white py-6 px-6 rounded-2xl my-4">
                <div className="text-white flex items-center absolute rounded-full py-4 px-4 shadow-xl left-4 -top-6 bg-lightBlue-600">
                  {key === "problem" && <FaQuestion className="inline-block w-6 h-6" />}
                  {key === "solution" && <FaLightbulb className="inline-block w-6 h-6" />}
                  {key === "business_description" && (
                    <FaHandshake className="inline-block w-6 h-6" />
                  )}
                  {key === "market" && <FiTarget className="inline-block w-6 h-6" />}
                </div>

                <div className="pt-6 flex flex-col justify-between h-full">
                  <p className="text-lg font-semibold mt-2">{t(key)}</p>

                  <div className="mt-2 flex-1 flex text-base">
                    {deal[key] && deal[key] !== "" && deal[key]}

                    {(!deal[key] || deal[key] === "") && (
                      <NoDataInfo title={t("no_market_section_data")} />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </SidePanel>
      {showPreview && (
        <DocumentPreview
          previewUrl={previewUrl}
          document={selectedDocument}
          downloadDocument={() => downloadDocument(selectedDocument._id)}
          handleClose={() => setShowPreview(false)}
          refetch={() => previewDocument(selectedDocument)}
        />
      )}
    </>
  );
};
