import { useOutletContext } from "react-router-dom";
import { TagListView } from "../../tags/TagListView";

const DocumentationTags = () => {
  const { ref } = useOutletContext();

  return (

      <TagListView category="documentation" ref={ref} />
  );
};

export default DocumentationTags;
