import axios from "axios/index";
import Config from "../config";

const AuthService = {
  confirmRegister: async(code, clientId, password, language) =>
    axios.post(`${Config.AUTH_API_URL}clients/teammates/activation/confirm`, {
      code,
      client: clientId,
      password,
      language,
    }),

  checkActivationCode: async(activationCode, clientId) =>
    axios.post(`${Config.AUTH_API_URL}clients/teammates/activation/checks`, {
      code: activationCode,
      client: clientId,
    }),

  login: async(email, password) =>
    axios.post(`${Config.AUTH_API_URL}clients/teammates/login`, {
      email,
      password,
    }),

  resendActivationCode: async(email) =>
    axios.post(`${Config.AUTH_API_URL}clients/teammates/resend-activation-link`, {
      email,
    }),

  forgotPassword: async(email) =>
    axios.post(`${Config.AUTH_API_URL}clients/teammates/password/forgot`, {
      email,
    }),

  checkRestorePasswordCode: async(code) =>
    axios.post(`${Config.AUTH_API_URL}clients/teammates/password/checks`, { code }),

  restorePassword: async(code, password) =>
    axios.post(`${Config.AUTH_API_URL}clients/teammates/password/restore`, {
      code,
      password,
    }),

  updatePassword: async(values) =>
    axios.patch(`${Config.AUTH_API_URL}clients/teammates/password`, values),

  registerUser: async(values) =>
    axios.post(`${Config.AUTH_API_URL}clients/teammates/password/restore`, {
      ...values,
    }),
};
export default AuthService;
