/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { useTranslation } from "react-i18next";

// import GraphSvg from '@/styles/images/graph.svg'
import { useState } from "react";
import { DealflowService } from "../../../services/dealflow-service";
import { useToasts } from "react-toast-notifications";
import { useEffect } from "react";
// import { DealflowPoolListItem } from './DealflowPoolListItem'
import { DealResumeSection } from "../../dealflow/DealResumeSection";
import DocumentsService from "services/documents-service";
import { usePagination } from "hooks/usePagination";
import { Card } from "components/ui/atoms/Card";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import { PieChart } from "components/ui/molecules/charts/PieChart";
import { Pagination } from "components/ui/atoms/Pagination";
import { Loader } from "components/ui/molecules/Loader";
import { AddStartUpSidePanel } from "./AddStartupSidePanel";
import { useSwal } from "hooks/useSwal";
import useUser from "hooks/useUser";
import InvestmentSection from "./InvestmentSection";
import { DealflowPoolListItem } from "components/dealflow/DealflowPoolListItem";
import { InvestmentsService } from "services/investments-service";
import { DealflowFilters } from "components/dealflow/DealflowFilters";
import GraphSvg from "../../../assets/images/graph.svg";

export const PortfolioStartups = ({
  statistics,
  showAddStartUp: _showAddToStartUp,
  startUpFilters,
  showStarUpsFilter,
  setShowStarUpsFilter,
  setSection,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [dealflow, setDealflow] = useState([]);
  const [filters, setFilters] = useState({});
  const [dealToResume, setDealToResume] = useState(null);
  const [addStartUp, setAddStartUp] = useState(_showAddToStartUp);
  const [startUpRegistered, setStartUpRegistered] = useState(false);
  const [registeredStartup, setRegisteredStartUp] = useState(null);
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);

  const { confirm } = useSwal();
  const { user, reloadUserInfo } = useUser();

  useEffect(() => {
    setAddStartUp(_showAddToStartUp);
  }, [_showAddToStartUp]);

  const { addToast } = useToasts();
  const dataGeo = [
    {
      id: "AFG",
      value: 723792,
    },
    {
      id: "AGO",
      value: 772875,
    },
    {
      id: "ALB",
      value: 109552,
    },
    {
      id: "ARE",
      value: 345814,
    },
    {
      id: "ARG",
      value: 905416,
    },
    {
      id: "ARM",
      value: 38851,
    },
    {
      id: "ATA",
      value: 945737,
    },
    {
      id: "ATF",
      value: 396647,
    },
    {
      id: "AUT",
      value: 88852,
    },
    {
      id: "AZE",
      value: 156383,
    },
    {
      id: "BDI",
      value: 149249,
    },
    {
      id: "BEL",
      value: 133106,
    },
    {
      id: "BEN",
      value: 428542,
    },
    {
      id: "BFA",
      value: 665759,
    },
    {
      id: "BGD",
      value: 624120,
    },
    {
      id: "BGR",
      value: 335012,
    },
    {
      id: "BHS",
      value: 4369,
    },
    {
      id: "BIH",
      value: 788889,
    },
    {
      id: "BLR",
      value: 372055,
    },
    {
      id: "BLZ",
      value: 58911,
    },
    {
      id: "BOL",
      value: 917142,
    },
    {
      id: "BRN",
      value: 255140,
    },
    {
      id: "BTN",
      value: 224171,
    },
    {
      id: "BWA",
      value: 450442,
    },
    {
      id: "CAF",
      value: 914076,
    },
    {
      id: "CAN",
      value: 936872,
    },
    {
      id: "CHE",
      value: 666477,
    },
    {
      id: "CHL",
      value: 251096,
    },
    {
      id: "CHN",
      value: 623107,
    },
    {
      id: "CIV",
      value: 332445,
    },
    {
      id: "CMR",
      value: 690448,
    },
    {
      id: "COG",
      value: 910358,
    },
    {
      id: "COL",
      value: 858605,
    },
    {
      id: "CRI",
      value: 399033,
    },
    {
      id: "CUB",
      value: 154405,
    },
    {
      id: "-99",
      value: 846510,
    },
    {
      id: "CYP",
      value: 607367,
    },
    {
      id: "CZE",
      value: 374316,
    },
    {
      id: "DEU",
      value: 586030,
    },
    {
      id: "DJI",
      value: 536002,
    },
    {
      id: "DNK",
      value: 338230,
    },
    {
      id: "DOM",
      value: 521727,
    },
    {
      id: "DZA",
      value: 623695,
    },
    {
      id: "ECU",
      value: 267218,
    },
    {
      id: "EGY",
      value: 223911,
    },
    {
      id: "ERI",
      value: 214749,
    },
    {
      id: "ESP",
      value: 531975,
    },
    {
      id: "EST",
      value: 542949,
    },
    {
      id: "ETH",
      value: 761031,
    },
    {
      id: "FIN",
      value: 650958,
    },
    {
      id: "FJI",
      value: 781514,
    },
    {
      id: "FLK",
      value: 937686,
    },
    {
      id: "FRA",
      value: 265074,
    },
    {
      id: "GAB",
      value: 20700,
    },
    {
      id: "GBR",
      value: 55031,
    },
    {
      id: "GEO",
      value: 115937,
    },
    {
      id: "GHA",
      value: 539972,
    },
    {
      id: "GIN",
      value: 95621,
    },
    {
      id: "GMB",
      value: 468102,
    },
    {
      id: "GNB",
      value: 855050,
    },
    {
      id: "GNQ",
      value: 678349,
    },
    {
      id: "GRC",
      value: 895034,
    },
    {
      id: "GTM",
      value: 820729,
    },
    {
      id: "GUY",
      value: 80015,
    },
    {
      id: "HND",
      value: 44131,
    },
    {
      id: "HRV",
      value: 236951,
    },
    {
      id: "HTI",
      value: 166402,
    },
    {
      id: "HUN",
      value: 983646,
    },
    {
      id: "IDN",
      value: 560365,
    },
    {
      id: "IND",
      value: 876555,
    },
    {
      id: "IRL",
      value: 951929,
    },
    {
      id: "IRN",
      value: 441022,
    },
    {
      id: "IRQ",
      value: 837040,
    },
    {
      id: "ISL",
      value: 783572,
    },
    {
      id: "ISR",
      value: 823722,
    },
    {
      id: "ITA",
      value: 828592,
    },
    {
      id: "JAM",
      value: 631600,
    },
    {
      id: "JOR",
      value: 730123,
    },
    {
      id: "JPN",
      value: 101977,
    },
    {
      id: "KAZ",
      value: 320789,
    },
    {
      id: "KEN",
      value: 112184,
    },
    {
      id: "KGZ",
      value: 274017,
    },
    {
      id: "KHM",
      value: 509178,
    },
    {
      id: "OSA",
      value: 803661,
    },
    {
      id: "KWT",
      value: 871448,
    },
    {
      id: "LAO",
      value: 699577,
    },
    {
      id: "LBN",
      value: 107980,
    },
    {
      id: "LBR",
      value: 400407,
    },
    {
      id: "LBY",
      value: 206225,
    },
    {
      id: "LKA",
      value: 482748,
    },
    {
      id: "LSO",
      value: 781254,
    },
    {
      id: "LTU",
      value: 905352,
    },
    {
      id: "LUX",
      value: 310036,
    },
    {
      id: "LVA",
      value: 815288,
    },
    {
      id: "MAR",
      value: 353833,
    },
    {
      id: "MDA",
      value: 314686,
    },
    {
      id: "MDG",
      value: 822597,
    },
    {
      id: "MEX",
      value: 247384,
    },
    {
      id: "MKD",
      value: 992802,
    },
    {
      id: "MLI",
      value: 116095,
    },
    {
      id: "MMR",
      value: 877152,
    },
    {
      id: "MNE",
      value: 536524,
    },
    {
      id: "MNG",
      value: 229043,
    },
    {
      id: "MOZ",
      value: 848311,
    },
    {
      id: "MRT",
      value: 358161,
    },
    {
      id: "MWI",
      value: 54612,
    },
    {
      id: "MYS",
      value: 155332,
    },
    {
      id: "NAM",
      value: 615189,
    },
    {
      id: "NCL",
      value: 320288,
    },
    {
      id: "NER",
      value: 659707,
    },
    {
      id: "NGA",
      value: 431216,
    },
    {
      id: "NIC",
      value: 541761,
    },
    {
      id: "NLD",
      value: 20653,
    },
    {
      id: "NOR",
      value: 953095,
    },
    {
      id: "NPL",
      value: 437398,
    },
    {
      id: "NZL",
      value: 265415,
    },
    {
      id: "OMN",
      value: 615806,
    },
    {
      id: "PAK",
      value: 758432,
    },
    {
      id: "PAN",
      value: 102666,
    },
    {
      id: "PER",
      value: 890563,
    },
    {
      id: "PHL",
      value: 556059,
    },
    {
      id: "PNG",
      value: 355514,
    },
    {
      id: "POL",
      value: 533604,
    },
    {
      id: "PRI",
      value: 467173,
    },
    {
      id: "PRT",
      value: 78790,
    },
    {
      id: "PRY",
      value: 14312,
    },
    {
      id: "QAT",
      value: 546323,
    },
    {
      id: "ROU",
      value: 253512,
    },
    {
      id: "RUS",
      value: 304817,
    },
    {
      id: "RWA",
      value: 618638,
    },
    {
      id: "ESH",
      value: 295343,
    },
    {
      id: "SAU",
      value: 999659,
    },
    {
      id: "SDN",
      value: 577738,
    },
    {
      id: "SDS",
      value: 350737,
    },
    {
      id: "SEN",
      value: 38383,
    },
    {
      id: "SLB",
      value: 917948,
    },
    {
      id: "SLE",
      value: 916406,
    },
    {
      id: "SLV",
      value: 909175,
    },
    {
      id: "ABV",
      value: 812456,
    },
    {
      id: "SOM",
      value: 880120,
    },
    {
      id: "SRB",
      value: 910944,
    },
    {
      id: "SUR",
      value: 548673,
    },
    {
      id: "SVK",
      value: 160128,
    },
    {
      id: "SVN",
      value: 356797,
    },
    {
      id: "SWZ",
      value: 702833,
    },
    {
      id: "SYR",
      value: 965172,
    },
    {
      id: "TCD",
      value: 777337,
    },
    {
      id: "TGO",
      value: 276822,
    },
    {
      id: "THA",
      value: 830097,
    },
    {
      id: "TJK",
      value: 455737,
    },
    {
      id: "TKM",
      value: 336255,
    },
    {
      id: "TLS",
      value: 364428,
    },
    {
      id: "TTO",
      value: 241959,
    },
    {
      id: "TUN",
      value: 282704,
    },
    {
      id: "TUR",
      value: 56727,
    },
    {
      id: "TWN",
      value: 157037,
    },
    {
      id: "TZA",
      value: 156692,
    },
    {
      id: "UGA",
      value: 565565,
    },
    {
      id: "UKR",
      value: 923131,
    },
    {
      id: "URY",
      value: 293314,
    },
    {
      id: "USA",
      value: 47350,
    },
    {
      id: "UZB",
      value: 604885,
    },
    {
      id: "VEN",
      value: 964315,
    },
    {
      id: "VNM",
      value: 476231,
    },
    {
      id: "VUT",
      value: 104029,
    },
    {
      id: "PSE",
      value: 154295,
    },
    {
      id: "YEM",
      value: 460449,
    },
    {
      id: "ZAF",
      value: 58017,
    },
    {
      id: "ZMB",
      value: 810402,
    },
    {
      id: "ZWE",
      value: 309540,
    },
    {
      id: "KOR",
      value: 86945,
    },
  ];
  const { setTotalPages, totalPages, setPage, page, rowsPerPage, setRowsPerPage } =
    usePagination(12);

  const downloadDocument = async (documentId) => {
    setLoading(true);
    DocumentsService.getDocument(documentId)
      .then((response) => {
        if (response?.data?.data?.url) {
          window.open(response.data.data.url, "_blank");
        }
      })
      .catch((error) => {
        addToast(
          error?.response?.data?.msg_key
            ? t(error.response.data.msg_key)
            : t("error_occurred_downloading_document"),
          {
            appearance: "error",
            autoDismiss: true,
          },
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addToPortfolio = async ({ name }) => {
    console.log("Add to portfolio", name);
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await DealflowService.addToPortfolio(name);
          addToast(t("start_up_added_to_portfolio"), { appearance: "success", autoDismiss: true });
          setAddStartUp(false);
          setSection("investments");
          getDealflow();
        } catch (error) {
          console.log("Error adding to portfolio", error);
          setLoading(false);
          addToast(
            t(error?.response?.data?.msg_key || "error_occurred_adding_startup_to_portfolio"),
            { appearance: "error", autoDismiss: true },
          );
        } finally {
          setLoading(false);
        }
      }
    });
  };
  const addDeal = (data) => {
    if (
      data.assign_startup_to_pipeline_manager &&
      (!data.pipeline_manager_board || !data.pipeline_manager_stage)
    ) {
      alert({ text: t("assign_startup_to_pipeline_manager_incomplete") });
      return;
    }
    confirm({}).then(async (isConfirmed) => {
      if (isConfirmed) {
        setLoading(true);
        try {
          const response = await DealflowService.addDeal(data);
          getDealflow();
          addToast(t("startup_added_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
          reloadUserInfo();
          setStartUpRegistered(true);
          if (response.data.data._id) {
            setRegisteredStartUp(response.data.data);
            addToPortfolio({ name: response.data.data._id });
          }
        } catch (error) {
          console.error(error);
          addToast(t(error?.response?.data?.msg_key || "error_adding_startup"), {
            appearance: "error",
            autoDismiss: true,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };
  const getDealflow = () => {
    setLoading(true);
    return DealflowService.getDealflow({
      ...filters,
      // rowsPerPage: 9999,
      rowsPerPage,
      page,
      sortBy: "-createdAt",
      pool: "true",
      reminders: "true",
      status: "portfolio",
    })
      .then((result) => {
        const { dealflow, pagination } = result.data.data;
        setDealflow(dealflow);
        setTotalPages(pagination.pages);
      })
      .catch(() => {
        addToast(t("error_occurred_retrieving_dealflow"), {
          appearance: "error",
          autoDismiss: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleInvestment = async (values) => {
    try {
      await InvestmentsService.registerInvestment(values);

      // await InvestmentsService.registerInvestment({
      //   deal: '650d30adbf9a3c6318de8bf4',
      //   investment_type: 'first_investment',
      //   status: 'commited',
      //   investment_stage: '6120116e296138194f439ce3',
      //   investment: 200000,
      //   shares: 10,
      //   commission: 1000,
      //   round: 5000000,
      //   premoney_valuation: 10000000,
      //   is_own_investment: true,
      //   investment_date: moment(),
      //   comments: 'Comentario'
      // })
      addToast(t("investment_registered_successfully"), {
        appearance: "success",
        autoDismiss: true,
      });
      startUpRegistered(false);
      // getInvestments()
    } catch (error) {
      console.error(error);
      // addToast(t('error_occurred_registering_investment'), {
      //   appearance: 'error',
      //   autoDismiss: true
      // })
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDealflow();
  }, [page, rowsPerPage, filters]);

  return (
    <>
      {loading && <Loader />}

      <div className="flex grid grid-cols-1 lg:grid-cols-2  2xl:grid-cols-2 gap-4">
        <div className="my-3 w-full">
          <Card>
            <h3 className="font-bold text-center">{t("sectors")}</h3>
            <div className="h-96">
              {statistics?.sectors?.length > 0 && (
                <PieChart
                  linkLabels={true}
                  legend={false}
                  data={statistics.sectors?.map((group) => {
                    return { id: t(group.id), value: group.value };
                  })}
                />
              )}
              {!statistics?.sectors?.length > 0 && (
                <NoDataInfo backgroundImage={GraphSvg} title={t("no_data_registered")} />
              )}
            </div>
          </Card>
        </div>
        <div className="my-3 w-full">
          <Card>
            <h3 className="font-bold text-center">{t("investment_stages")}</h3>
            <div className="h-96">
              {statistics?.investment_stages?.length > 0 && (
                <PieChart
                  linkLabels={true}
                  legend={false}
                  data={statistics.investment_stages?.map((group) => {
                    return { id: t(group.id), value: group.value };
                  })}
                />
              )}
              {!statistics?.investment_stages?.length > 0 && (
                <NoDataInfo backgroundImage={GraphSvg} title={t("no_data_registered")} />
              )}
            </div>
          </Card>
        </div>
      </div>
      <div className="flex grid grid-cols-1 gap-4">
        {/* <div className='my-3 w-full'>
          <Card>
            <h3 className='font-bold text-center'>{t('project_stages')}</h3>
            <div className='h-96'>
                {statistics?.project_stages?.length > 0 && (
                  // <GeoChart
                  //   data={dataGeo}
                  // />
                )}
                {!statistics?.project_stages?.length > 0 && <NoDataInfo backgroundImage={GraphSvg} title={t('no_data_registered')}/>}
             </div>
          </Card>
        </div> */}
      </div>

      {dealToResume && (
        <DealResumeSection
          deal={dealToResume || {}}
          onClose={() => {
            setDealToResume(null);
          }}
          downloadDocument={downloadDocument}
        />
      )}

      {!loading && dealflow.length > 0 && (
        <div className="mt-6">
          {dealflow && (
            <div className="grid grid-cols-1 lg:grid-cols-2  2xl:grid-cols-2 gap-4">
              {/* <h2>{dealflow.length}</h2> */}
              {dealflow.map((dealflow) => (
                // <div>
                //   <Avatar src={dealflow.logo} size='20'/>
                //   <span>{dealflow.name}</span> - <span>{dealflow._id}</span>
                // </div>
                <DealflowPoolListItem
                  key={dealflow._id}
                  dealflow={dealflow}
                  setDealToResume={setDealToResume}
                  // showAdditionalInfo={showAdditionalInfo}
                  // setShowAdditionalInfo={setShowAdditionalInfo}
                />
              ))}
            </div>
          )}
          <Pagination
            paginateOptions={[12, 24, 36, 48, 60]}
            currentPage={page}
            setCurrentPage={(page) => setPage(page)}
            perPage={rowsPerPage}
            pages={totalPages}
            // setPerPage={(value) => setRowsPerPage(value[0].id)}
            setPerPage={(value) => setRowsPerPage(999)}
          />
        </div>
      )}
      {addStartUp && (
        <AddStartUpSidePanel
          onClose={() => setAddStartUp(false)}
          onFilter={(values) => {
            addToPortfolio(values);
          }}
          onDealAdded={addDeal}
          filters={filters}
          status={status}
        />
      )}
      {startUpRegistered && registeredStartup?._id && (
        <InvestmentSection
          onClose={() => setStartUpRegistered(false)}
          startUpId={registeredStartup}
          onSubmit={handleInvestment}
        />
      )}
      {showStarUpsFilter && (
        <DealflowFilters
          onClose={() => setShowStarUpsFilter(false)}
          onFilter={setFilters}
          filters={filters}
          status={null}
        />
      )}
    </>
  );
};
