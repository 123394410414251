import { useState, useEffect } from "react";
import { RoutesLinks } from "../routes-links";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { ChecksOperations } from "../../redux/checks";
import OnBoarding from "../on-boarding";
import { Loader } from "components/ui/molecules/Loader";
import useUser from "../../hooks/useUser";

const CHECKS = {
  LOADING: "LOADING",
  ON_BOARDING_FIRST_STEP: "ON_BOARDING_FIRST_STEP",
  ON_BOARDING_SECOND_STEP: "ON_BOARDING_SECOND_STEP",
  ON_BOARDING_THIRD_STEP: "ON_BOARDING_THIRD_STEP",
  ON_BOARDING_FINISHED: "ON_BOARDING_FINISHED",
  ACCOUNT_ACTIVATED: "ACCOUNT_ACTIVATED",
};

const Checks = () => {
  const authInfo = useSelector((state) => state.auth);
  const { user } = useUser();
  const checked = useSelector((state) => state.checks.checked);
  const [check, setCheck] = useState(null);

  const dispatch = useDispatch();
  const stage = useSelector((state) => state.checks.stage);

  const { token } = authInfo;

  useEffect(() => {
    const getChecks = async() => {
      if (stage === CHECKS.ON_BOARDING_FINISHED) {
        dispatch(ChecksOperations.setChecked(true));
        dispatch(ChecksOperations.setStage(null));
        setCheck(null);
      } else if (stage === CHECKS.ON_BOARDING_FIRST_STEP) {
        setCheck(stage);
      } else if (stage === CHECKS.ON_BOARDING_SECOND_STEP) {
        setCheck(stage);
      } else if (stage === CHECKS.ON_BOARDING_THIRD_STEP) {
        setCheck(stage);
      } else if (!stage || stage === CHECKS.ACCOUNT_ACTIVATED) {
        if (!user.welcome_steps) {
          dispatch(ChecksOperations.setStage(CHECKS.ON_BOARDING_FIRST_STEP));
          setCheck(CHECKS.ON_BOARDING_FIRST_STEP);
        } else {
          dispatch(ChecksOperations.setChecked(true));
          dispatch(ChecksOperations.setStage(null));
          setCheck(null);
        }
      }
    };

    getChecks();
  }, [dispatch, user, stage]);

  if (token) {
    if (check === CHECKS.LOADING) {
      return <Loader />;
    } else if (
      check === CHECKS.ON_BOARDING_FIRST_STEP ||
      check === CHECKS.ON_BOARDING_SECOND_STEP ||
      check === CHECKS.ON_BOARDING_THIRD_STEP
    ) {
      return <OnBoarding />;
    } else if (checked) {
      return <Navigate to={RoutesLinks.DASHBOARD_LINK} />;
    } else {
      return <Loader />;
    }
  } else {
    return <Navigate to={RoutesLinks.LOGIN_LINK} />;
  }
};

export default Checks;
