import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Loader } from "components/ui/molecules/Loader";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { isEqual } from "lodash";
import { Pagination } from "components/ui/atoms/Pagination";
import {
  FaTrash,
  FaTimes,
  FaCheck,
  FaDownload,
  FaLock,
  FaClipboardList,
  FaPaperPlane,
} from "react-icons/fa";
import { BsBullseye } from "react-icons/bs";
import { DataGrid } from "components/ui/objects/DataGrid";
import { useSwal } from "hooks/useSwal";
import { Modal } from "components/ui/molecules/Modal";
import { RoutesLinks } from "components/routes-links";
import QRCode from "qrcode";
import Config from "config";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import JudgeSection from "./JudgeSection";
import AssignStartupsSection from "./AssignStartupsSection";
import Utils from "utils/utils";
import useUser from "hooks/useUser";
import TeamService from "services/team-service";
import EvaluationProcessesManagerService from "services/evaluation-processes-manager-service";

export const EventJudges = forwardRef(({ event, getEvaluations }, ref) => {
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 10,
    totalPages: 0,
    totalDocs: 0,
  });
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { confirm } = useSwal();
  const [employees, setEmployees] = useState([]);
  const [judges, setJudges] = useState([]);
  const [sort] = useState("-createdAt");
  const [loading, setLoading] = useState(true);
  const [loadingEmployees, setLoadingEmployees] = useState(true);
  const [loadingStartups, setLoadingStartups] = useState(true);
  const [showJudgeSection, setShowJudgeSection] = useState(false);
  const [judgeQR, setJudgeQR] = useState(null);
  const [qrImage, setQrImage] = useState(null);
  const [selectedJudge, setSelectedJudge] = useState(null);
  const [showAssignStartupsModal, setShowAssignStartupsModal] = useState(false);
  const [startups, setStartups] = useState([]);
  const [regularAccessToken, setRegularAccessToken] = useState(null);
  const { reloadUserInfo, userLanguage } = useUser();

  const getStartups = async () => {
    try {
      setLoadingStartups(true);

      const result = await EvaluationProcessesManagerService.getDealflowFromEvaluationProcess(
        event._id,
        {
          rowsPerPage: 9999,
        },
      );
      setStartups(result.data.data.startups);
    } catch (error) {
      addToast(t("error_occurred_retrieving_startups"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoadingStartups(false);
    }
  };

  const getJudges = () => {
    setLoading(true);
    return EvaluationProcessesManagerService.getJudgesFromEvaluationProcess(event._id, {
      page: pagination.page,
      rowsPerPage: pagination.rowsPerPage,
      sortBy: sort,
    })
      .then((result) => {
        setJudges(result.data.data.judges);
        if (result.data.data.pagination) {
          const newPagination = {
            ...pagination,

            page:
              result.data.data.pagination.current >= result.data.data.pagination.pages
                ? result.data.data.pagination.pages - 1
                : result.data.data.pagination.current - 1,
            totalPages: result.data.data.pagination.pages,
            totalDocs: result.data.data.pagination.totalDocs,
          };

          if (!isEqual(newPagination, pagination)) {
            setPagination(newPagination);
          }
        }
      })
      .catch((error) => {
        addToast(t("error_occurred_retrieving_judges"), {
          appearance: "error",
          autoDismiss: true,
        });
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const inviteJudge = (data) => {
    setShowJudgeSection(false);
    setLoading(true);
    return EvaluationProcessesManagerService.addJudgeToEvaluationProcess(event._id, data)
      .then(() => {
        addToast(t("judge_invited_successfully"), {
          appearance: "success",
          autoDismiss: true,
        });
        reloadUserInfo();
        return getJudges();
      })
      .catch((error) => {
        addToast(
          t(
            error.response && error.response.data.msg_key
              ? error.response.data.msg_key
              : "error_inviting_judge_to_event",
          ),
          {
            appearance: "error",
            autoDismiss: true,
          },
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteJudge = (judge) => {
    confirm({
      text: t("delete_judge_description"),
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await EvaluationProcessesManagerService.deleteJudgeFromEvaluationProcess(
            event._id,
            judge._id,
          );
          getJudges();
          addToast(t("judge_deleted_from_event_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
        } catch (error) {
          console.error(error);
          addToast(
            error.response && error.response.data.msg_key
              ? error.response.data.msg
              : t("error_occurred_deleting_startup_from_event"),
            {
              appearance: "error",
              autoDismiss: true,
            },
          );
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const updateJudge = (judge) => {
    confirm({
      title: t("are_you_sure"),
      text: t(judge.enabled ? "disable_judge_description" : "enable_judge_description"),
      icon: "warning",
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await EvaluationProcessesManagerService.updateJudgeFromEvaluationProcess(
            event._id,
            judge._id,
            {
              enabled: !judge.enabled,
            },
          );
          getJudges();
          addToast(
            t(judge.enabled ? "judge_disabled_successfully" : "judge_enabled_successfully"),
            {
              appearance: "success",
              autoDismiss: true,
            },
          );
        } catch (error) {
          console.error(error);
          addToast(
            error.response && error.response.data.msg_key
              ? error.response.data.msg
              : t(
                  judge.enabled
                    ? "error_occurred_disabling_judge"
                    : "error_occurred_enabling_judge",
                ),
            {
              appearance: "error",
              autoDismiss: true,
            },
          );
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const resendEmail = (judgeId) => {
    confirm({
      title: t("are_you_sure"),
      icon: "info",
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await EvaluationProcessesManagerService.resendEmailToJudge(event._id, judgeId);
          addToast(t("email_sent_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
        } catch (error) {
          console.error(error);
          addToast(t("error_occurred_sending_email"), {
            appearance: "error",
            autoDismiss: true,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const getEmployees = () => {
    setLoadingEmployees(true);
    TeamService.getTeammates()
      .then((response) => {
        setEmployees(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        addToast(t("error_occurred"), {
          appearance: "error",
          autoDismiss: true,
        });
      })
      .finally(() => {
        setLoadingEmployees(false);
      });
  };

  const generateQr = async (judge) => {
    const domain = window.location.origin;

    QRCode.toDataURL(
      `${domain}${RoutesLinks.SHARED_LINK}events/${event._id}/judge/${judge._id}`,
    ).then((qrURLData) => {
      setQrImage(qrURLData);
      setJudgeQR(judge);
    });
  };

  const downloadQr = () => {
    const a = document.createElement("a");
    a.href = "data:application/png;base64" + qrImage;
    a.download = `QR - ${judgeQR.judge}.png`;
    a.click();
  };

  useEffect(() => {
    if (event) {
      getEmployees();
    }
  }, [event]);

  useEffect(() => {
    if (event) {
      getJudges();
      getStartups();
    }
  }, [event, pagination.page, pagination.rowsPerPage]);

  useImperativeHandle(ref, () => ({
    inviteJudge: () => {
      setShowJudgeSection(true);
    },
  }));

  const assignStartups = (startups, currentStartups) => {
    confirm({
      title: t("are_you_sure"),
      text: t(
        currentStartups ? "assign_current_startups_description" : "assign_startups_description",
      ),
      icon: "warning",
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await EvaluationProcessesManagerService.updateJudgeFromEvaluationProcess(
            event._id,
            selectedJudge._id,
            {
              startups,
            },
          );
          getJudges();
          getEvaluations();
          addToast(t("startups_assigned_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
        } catch (error) {
          console.error(error);
          addToast(
            error.response && error.response.data.msg_key
              ? error.response.data.msg
              : t("error_occurred_assigning_startups"),
            {
              appearance: "error",
              autoDismiss: true,
            },
          );
        } finally {
          setSelectedJudge(null);
          setLoading(false);
        }
      }
    });
  };

  useEffect(() => {
    if (selectedJudge) {
      setShowAssignStartupsModal(true);
    }
  }, [selectedJudge]);

  return (
    <>
      {regularAccessToken && (
        <Modal
          showModal={!!regularAccessToken}
          showCloseModal={true}
          onClose={() => {
            setRegularAccessToken(null);
          }}
        >
          <div className="flex items-center justify-center my-12 text-2xl font-semibold">
            {regularAccessToken}
            <ButtonSecondary
              marginLeft={3}
              onClick={() => {
                addToast(t("judge_password_copied_to_clipbard_successfully"), {
                  appearance: "success",
                  autoDismiss: true,
                });
                Utils.copyToClipboard(regularAccessToken);
              }}
              iconComponent={<FaClipboardList className="inline-block w-7 h-7" />}
            />
          </div>
        </Modal>
      )}
      {showAssignStartupsModal && (
        <AssignStartupsSection
          loadingStartups={loadingStartups}
          judgeStartups={selectedJudge.startups || []}
          onClose={() => {
            setSelectedJudge(null);
            setShowAssignStartupsModal(false);
          }}
          startups={startups}
          onSubmit={(assignedStartups, currentStartups = false) => {
            setShowAssignStartupsModal(false);
            assignStartups(assignedStartups, currentStartups);
          }}
        />
      )}
      {judgeQR && qrImage && (
        <Modal
          showModal={qrImage}
          showCloseModal={true}
          onClose={() => {
            setQrImage(null);
            setJudgeQR(null);
          }}
        >
          <div className="flex items-center justify-center mt-4 mb-4">
            <img src={qrImage} alt="QR" />
          </div>

          <ButtonSecondary
            width={"full"}
            onClick={() => downloadQr()}
            text={t("download")}
            iconComponent={<FaDownload className="inline-block mr-3" />}
          />
        </Modal>
      )}

      {showJudgeSection && (
        <JudgeSection
          onClose={() => {
            setShowJudgeSection(false);
          }}
          onSubmit={inviteJudge}
          employees={employees}
        />
      )}

      {judges.length === 0 && !loading && (
        <div className="my-10">
          <img
            src={`${Config.GUIDE_STEPS_BACKGROUND_IMAGES_BUCKET}evaluation_process_judges_${userLanguage}.png`}
            className="w-auto"
          />
        </div>
      )}

      {judges.length !== 0 && (
        <>
          <DataGrid
            wrapperClassName="mt-8"
            compact={true}
            bordered={true}
            data={judges.map((judge) => ({ ...judge, id: judge._id }))}
            headers={[
              { key: "judge", title: t("judge") },
              { key: "email", title: t("email") },
              {
                key: "evaluations",
                title: t("evaluations"),
                render: (key, row) => {
                  return row?.startups?.length
                    ? `${row.evaluations} / ${row.startups.length}`
                    : t("no_startups_found");
                },
              },
              {
                key: "type",
                title: t("type"),
                render: (key, row) => {
                  return t(!row.investor ? "registered" : row.user ? "employee" : "external");
                },
              },
            ]}
            actions={[
              {
                id: "resend_password",
                tip: t("judge_resend_password"),
                buttonProps: {
                  iconComponent: <FaPaperPlane className="inline" />,
                  textColor: "green",
                  width: "12",
                  horizontal: "1",
                  vertical: "1",
                  verticalMargin: "0",
                  horizontalMargin: "0",
                },
                show: (row) => {
                  return row.regular_access_token;
                },
                onClick: (rowData) => {
                  resendEmail(rowData._id);
                },
              },
              {
                id: "show_password",
                tip: t("judge_show_password"),
                buttonProps: {
                  iconComponent: <FaLock className="inline" />,
                  textColor: "green",
                  width: "12",
                  horizontal: "1",
                  vertical: "1",
                  verticalMargin: "0",
                  horizontalMargin: "0",
                },
                show: (row) => {
                  return row.regular_access_token;
                },
                onClick: (rowData) => {
                  setRegularAccessToken(rowData.regular_access_token);
                },
              },
              {
                id: "enable",
                tip: t("enable_judge"),
                buttonProps: {
                  iconComponent: <FaCheck className="inline" />,
                  textColor: "green",
                  width: "12",
                  horizontal: "1",
                  vertical: "1",
                  verticalMargin: "0",
                  horizontalMargin: "0",
                },
                show: (row) => {
                  return !row.enabled && !event.archived;
                },
                onClick: (rowData) => {
                  updateJudge(rowData);
                },
              },
              {
                id: "disable",
                tip: t("disable_judge"),
                buttonProps: {
                  iconComponent: <FaTimes className="inline" />,
                  textColor: "red",
                  width: "12",
                  horizontal: "1",
                  vertical: "1",
                  verticalMargin: "0",
                  horizontalMargin: "0",
                },
                show: (row) => {
                  return row.enabled && !event.archived;
                },
                onClick: (rowData) => {
                  updateJudge(rowData);
                },
              },
              {
                id: "assign_startups",
                tip: t("assign_startups"),
                buttonProps: {
                  iconComponent: <BsBullseye className="inline" />,
                  width: "12",
                  horizontal: "1",
                  vertical: "1",
                  verticalMargin: "0",
                  horizontalMargin: "0",
                },
                show: () => !event.archived,
                onClick: (rowData) => {
                  setSelectedJudge(rowData);
                },
              },
              {
                id: "delete",
                buttonProps: {
                  iconComponent: <FaTrash className="inline" />,
                  textColor: "red",
                  width: "12",
                  horizontal: "1",
                  vertical: "1",
                  verticalMargin: "0",
                  horizontalMargin: "0",
                },
                show: () => !event.archived,
                onClick: (rowData) => {
                  deleteJudge(rowData);
                },
              },
            ]}
          />

          <div className="mb-10">
            <Pagination
              paginateOptions={[10, 20, 30]}
              currentPage={pagination.page}
              setCurrentPage={(page) => {
                setPagination({
                  ...pagination,
                  page,
                });
              }}
              perPage={pagination.rowsPerPage}
              pages={pagination.totalPages}
              setPerPage={(value) => {
                setPagination({
                  ...pagination,
                  page: 0,
                  rowsPerPage: value[0].id,
                });
              }}
            />
          </div>
        </>
      )}

      {(loading || loadingEmployees) && <Loader />}
    </>
  );
});
