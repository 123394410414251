import { useTranslation } from "react-i18next";

import { SidePanel } from "components/ui/objects/SidePanel";
import { useEffect, useState } from "react";
import { Input } from "components/ui/atoms/Input";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import useUser from "hooks/useUser";
import { ButtonDanger } from "components/ui/atoms/ButtonDanger";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import Select from "components/ui/atoms/Select";
import { useToasts } from "react-toast-notifications";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { Switch } from "components/ui/atoms/Switch";

const LPSection = ({ onClose, lp, onSubmit, removeLP, sendActivationCode, boards, investor }) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const { user } = useUser();

  const LANGUAGES = [
    { id: "es", value: t("es") },
    { id: "en", value: t("en") },
  ];
  const [permittedBoards, setPermittedBoards] = useState(
    lp?.user?.lp_permissions?.permitted_boards || boards.map((board) => board._id),
  );

  // const [dashboard, setDashboard] = useState(lp?.user?.lp_permissions?.hasOwnProperty('dashboard') ? lp.user.lp_permissions.dashboard : true)
  const [dealflow, setDealflow] = useState(
    lp?.user?.lp_permissions?.hasOwnProperty("dealflow") ? lp.user.lp_permissions.dealflow : true,
  );
  const [pipelineManager, setPipelineManager] = useState(
    lp?.user?.lp_permissions?.hasOwnProperty("pipeline_manager")
      ? lp.user.lp_permissions.pipeline_manager
      : true,
  );
  const [contacts, setContacts] = useState(
    lp?.user?.lp_permissions?.hasOwnProperty("contacts") ? lp.user.lp_permissions.contacts : true,
  );
  const [documentation, setDocumentation] = useState(
    lp?.user?.lp_permissions?.hasOwnProperty("documentation")
      ? lp.user.lp_permissions.documentation
      : true,
  );

  // const [evaluations, setEvaluations] = useState(lp?.user?.lp_permissions?.hasOwnProperty('evaluations') ? lp.user.lp_permissions.evaluations : true)
  // const [questionnaires, setQuestionnaries] = useState(lp?.user?.lp_permissions?.hasOwnProperty('questionnaires') ? lp.user.lp_permissions.questionnaires : true)
  // const [reminders, setReminders] = useState(lp?.user?.lp_permissions?.hasOwnProperty('reminders') ? lp.user.lp_permissions.reminders : true)
  // const [company, setCompany] = useState(lp?.user?.lp_permissions?.hasOwnProperty('company') ? lp.user.lp_permissions.company : true)
  const [annotations, setAnnotations] = useState(
    lp?.user?.lp_permissions?.hasOwnProperty("annotations")
      ? lp.user.lp_permissions.annotations
      : true,
  );

  const LPSchema = Yup.object().shape({
    email: Yup.string().email(t("incorrect_format")).required(t("required_field")),
    name: Yup.string().required(t("required_field")),
    last_name: Yup.string().required(t("required_field")),
    language: Yup.string(),
    investor: Yup.string(),
  });

  const { register, errors, setValue, trigger, getValues, watch } = useForm({
    resolver: yupResolver(LPSchema),
    criteriaMode: "all",
    reValidateMode: "all",
    mode: "onChange",
  });

  useEffect(() => {
    register("language");
    console.log(getValues());
  }, [register]);

  useEffect(() => {
    if (lp && Object.keys(lp).length) {
      setValue("email", lp?.user?.email || lp.email, { shouldDirty: true });
      setValue("name", lp?.user?.name || lp.name);
      if (lp.last_name || lp?.user?.last_name) {
        setValue("last_name", lp?.user?.last_name || lp.last_name);
      }
      if (lp.language) {
        _setValue("language", lp.language);
      }
    }
    if (investor) {
      _setValue("investor", investor._id);
    }
  }, [lp, setValue]);

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, config);
    trigger(name);
  };

  const getSelectableInitialValues = (id, collection, lonely) => {
    if (!id) {
      return lonely ? null : [];
    }

    if (Array.isArray(id)) {
      return collection.filter((i) => id.includes(i.id));
    }

    const item = collection.find((i) => i.id === id);
    return item ? (lonely ? item : [item]) : lonely ? null : [];
  };

  return (
    <>
      <SidePanel onClose={onClose} title={t(lp ? "lp_details" : "add_lp")} width="1/4">
        <form className="w-full">
          <div>
            <Input
              reference={register}
              id="name"
              type="text"
              name="name"
              label={t("name") + "*:"}
              placeholder={t("introduce_name_they")}
              error={errors.name}
            />
          </div>
          <div>
            <Input
              reference={register}
              id="last_name"
              type="text"
              name="last_name"
              label={t("last_name") + "*:"}
              placeholder={t("introduce_last_name_they")}
              error={errors.last_name}
            />
          </div>
          <div>
            <Input
              reference={register}
              id="email"
              type="email"
              name="email"
              label={t("email") + "*:"}
              placeholder={t("write_email_they")}
              error={errors.email}
            />
          </div>
          {!lp && (
            <Select
              name="language"
              label={t("language")}
              reference={register}
              required={true}
              isClearable={false}
              placeholder={t("select_option")}
              error={errors.language}
              initialValues={getSelectableInitialValues(watch("language"), LANGUAGES)}
              items={LANGUAGES}
              onSelect={(selected) => {
                _setValue("language", selected.length ? selected[0].id : null);
              }}
            />
          )}

          <div className="border-b border-gray-lines text-sm font-semibold my-6">
            {t("permissions")}
          </div>
          {/* <div className='mb-4'>
              <Switch
                checked={dashboard}
                onChange={async (checked) => {
                  setDashboard(!dashboard)
                }}
                text={t('dashboard')}
              />
              </div> */}
          <div className="mb-4">
            <Switch
              checked={dealflow}
              onChange={async(checked) => {
                setDealflow(!dealflow);
              }}
              text={t("dealflow")}
            />
          </div>
          <div className="mb-4">
            <Switch
              checked={pipelineManager}
              onChange={async(checked) => {
                setPipelineManager(!pipelineManager);
              }}
              text={t("pipeline_manager")}
            />
          </div>
          {pipelineManager && (
            <div className="px-8">
              {boards.map((board) => (
                  <div className="mb-4" key={board._id}>
                    <Switch
                      checked={!!permittedBoards.find((id) => id === board._id)}
                      onChange={async(checked) => {
                        if (checked) {
                          setPermittedBoards([...permittedBoards, board._id]);
                        } else {
                          setPermittedBoards(permittedBoards.filter((id) => id !== board._id));
                        }
                      }}
                      text={board.name}
                    />
                  </div>
              ))}
            </div>
          )}
          <div className="mb-4">
            <Switch
              checked={contacts}
              onChange={async(checked) => {
                setContacts(!contacts);
              }}
              text={t("contacts")}
            />
          </div>
          {/* <div className='mb-4'>
              <Switch
                checked={evaluations}
                onChange={async (checked) => {
                  setEvaluations(!evaluations)
                }}
                text={t('evaluations')}
              />
            </div>
            <div className='mb-4'>
              <Switch
                checked={questionnaires}
                onChange={async (checked) => {
                  setQuestionnaries(!questionnaires)
                }}
                text={t('questionnaires')}
              />
            </div> */}
          {/* <div className='mb-4'>
              <Switch
                checked={reminders}
                onChange={async (checked) => {
                  setReminders(!reminders)
                }}
                text={t('reminders')}
              />
            </div>
            <div className='mb-4'>
              <Switch
                checked={company}
                onChange={async (checked) => {
                  setCompany(!company)
                }}
                text={t('company')}
              />
            </div> */}
          <div className="mb-4">
            <Switch
              checked={annotations}
              onChange={async(checked) => {
                setAnnotations(!annotations);
              }}
              text={t("annotations")}
            />
          </div>
          <div className="mb-4">
            <Switch
              checked={documentation}
              onChange={async(checked) => {
                setDocumentation(!documentation);
              }}
              text={t("documentation")}
            />
          </div>
          <div className={"flex justify-end mt-2 pt-2 border-t border-separator"}>
            {lp && lp?.user?._id !== user._id && (
              <ButtonDanger
                type="button"
                text={t("delete")}
                onClick={() => removeLP(lp)}
                marginRight={2}
              />
            )}
            {lp && !lp.activated && (
              <ButtonSecondary
                type="button"
                text={t("send_activation_code")}
                onClick={() => sendActivationCode(lp)}
                marginRight={2}
              />
            )}
            <ButtonMain
              type="button"
              onClick={async() => {
                const validation = await trigger();
                if (validation && ((!lp && getValues().language) || lp)) {
                  onSubmit({
                    ...getValues(),
                    investor: investor._id,
                    lp_permissions: {
                      dealflow,
                      pipeline_manager: pipelineManager,
                      contacts,
                      documentation,
                      /* evaluations, questionnaires, reminders, company, */ annotations,
                      permitted_boards: permittedBoards,
                      dashboard: true,
                    },
                  });
                } else {
                  addToast(t("language_required"), {
                    appearance: "error",
                    autoDismiss: true,
                  });
                }
              }}
              text={t(!lp ? "add_lp" : "save")}
            />
          </div>
        </form>
      </SidePanel>
    </>
  );
};

export default LPSection;
