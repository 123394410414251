import React, { useEffect, useState } from "react";
import { DataGrid } from "../../ui/objects/DataGrid";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import FormUtils from "../../../utils/form-utils";
import { Input } from "../../ui/atoms/Input";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import { FaTrash } from "react-icons/fa";
import { FaEdit, FaPlus } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { Card } from "../../ui/atoms/Card";
import { NoDataInfo } from "../../ui/atoms/NoDataInfo";
import Config from "config";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { useSwal } from "hooks/useSwal";
import { Loader } from "components/ui/molecules/Loader";
import { DealflowService } from "services/dealflow-service";
import { CurrencyInput } from "components/ui/atoms/CurrencyInput";
import { SidePanel } from "components/ui/objects/SidePanel";
import useCurrency from "hooks/useCurrency";

export const StartupEditCaptable = ({
  captable,
  onChange,
  isDealflow,
  startup,
  loading,
  setLoading,
}) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [showShareholderSection, setShowShareholderSection] = useState(false);
  const [shareholderToEdit, setShareholderToEdit] = useState(null);
  const { confirm } = useSwal();

  const { currency } = useCurrency();

  const { register, handleSubmit, trigger, errors, reset, setValue, watch } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        stakeholder: Yup.string().required(t("required_field")),
        investment: Yup.number()
          .typeError(t("incorrect_format"))
          .transform((_, val) => (val === `${Number(val)}` ? Number(val) : null))
          .required(t("required_field"))
          .min(0, t("minimum_investment_required")),
        unit: Yup.string().required(t("required_field")),
        incorporation_date: Yup.string()
          .nullable()
          .transform((value) => FormUtils.parseDateFromString(value, "YYYY-MM-DD HH:mm")),
        shares: Yup.number()
          .typeError(t("incorrect_format"))
          .transform((_, val) => (val === `${Number(val)}` ? Number(val) : null))
          .required(t("required_field"))
          .min(0, t("minimum_shares_required")),
      }),
      { abortEarly: false },
    ),
    criteriaMode: "all",
    reValidateMode: "all",
    mode: "onChange",
  });

  useEffect(() => {
    register("unit");
    if (!shareholderToEdit) {
      setValue("unit", "k");
      trigger();
    }
  }, [register]);

  const onInvalid = () => {
    addToast(t("form_field_error"), { appearance: "error", autoDismiss: true });
  };

  const onSuccess = (messageKey) => {
    closeForm();
    onChange && onChange();
    addToast(t(messageKey), {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const onError = (messageKey) => {
    addToast(t(messageKey), {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const onSubmit = (values) => {
    values.incorporation_date = values.incorporation_date
      ? new Date(values.incorporation_date)
      : null;

    const restOfPercentage = captable
      .filter((shareholder) =>
        shareholderToEdit ? shareholder._id !== shareholderToEdit._id : true,
      )
      .reduce((acc, member) => acc + (member.shares || 0), 0);

    if (restOfPercentage + (values.percentage || 0) > 100) {
      addToast(t("captable_percentage_overload"), {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }

    if (isDealflow) {
      if (!shareholderToEdit) {
        confirm().then((isConfirmed) => {
          if (isConfirmed) {
            setLoading(true);
            DealflowService.addShareholder(startup._id, values)
              .then(() => onSuccess("shareholder_added_successfully"))
              .catch((error) =>
                onError(
                  error.response && error.response.data.msg_key
                    ? t(error.response.data.msg_key)
                    : t("error_occurred_adding_shareholder"),
                ),
              )
              .finally(() => setLoading(false));
          }
        });
      } else {
        setLoading(true);
        DealflowService.updateShareholder(startup._id, values, shareholderToEdit._id)
          .then(() => onSuccess("shareholder_updated_successfully"))
          .catch((error) => onError("error_occurred_updating_shareholder"))
          .finally(() => setLoading(false));
      }
    }
  };

  const deleteHolder = (shareholder) => {
    confirm().then(async (isConfirmed) => {
      if (isConfirmed) {
        setLoading(true);
        DealflowService.deleteShareholder(startup._id, shareholder._id)
          .then(() => onSuccess("shareholder_deleted_successfully"))
          .catch((error) => onError("error_occurred_deleting_shareholder"))
          .finally(() => setLoading(false));
      }
    });
  };

  const openForm = (shareholder) => {
    reset({
      incorporation_date:
        shareholder !== null && shareholder.incorporation_date
          ? FormUtils.getDateInputValueFromDate(new Date(shareholder.incorporation_date))
          : null,
      shares: shareholder !== null ? shareholder.shares : null,
      stakeholder: shareholder !== null ? shareholder.stakeholder : null,
      investment: shareholder !== null ? shareholder.investment : null,
      unit: shareholder !== null ? shareholder.unit || "k" : "k",
    });

    if (shareholder) {
      setValue("unit", shareholder.unit || "k");
    }
    trigger();
    setShareholderToEdit(shareholder);
    setShowShareholderSection(true);
  };

  const closeForm = () => {
    setShowShareholderSection(false);
    setShareholderToEdit(null);
    reset();
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [captable]);

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, { shouldValidate: true, shouldTouch: true, ...config });
    trigger(name);
  };

  return (
    <>
      <div className="w-full mt-6 mb-8">
        <Card containerClassName="flex flex-col lg:flex-row lg:justify-between p-6 text-2">
          <div className="md:w-full lg:w-10/12">
            <h3 className={`text-main`}>{t("would_you_like_to_add_captable_member")}</h3>
          </div>
          <div className="md:w-full lg:w-2/12 justify-end flex text-center">
            <ButtonMain
              width="1/2 lg:w-full"
              text={t("add_shareholder")}
              onClick={() => openForm(null)}
            />
          </div>
        </Card>
      </div>

      {(!captable || captable.length === 0) && (
        <NoDataInfo title={t("no_captable_title")} textOne={t("no_captable_description")} />
      )}

      {captable?.length > 0 && (
        <DataGrid
          wrapperClassName="mt-3"
          data={captable}
          bordered={true}
          compact={true}
          headers={[
            {
              key: "stakeholder",
              title: t("stakeholder"),
            },
            {
              key: "investment",
              title: t("investment"),
              render: (key, shareholder) =>
                `${shareholder[key]}${shareholder?.unit?.toUpperCase() || "K"} ${currency}`,
            },
            {
              key: "shares",
              title: t("shares"),
            },
            {
              key: "incorporation_date",
              title: t("incorporation_date"),
              render: (key, shareholder) =>
                shareholder[key] ? moment(shareholder[key]).format("YYYY-MM-DD") : t("no_data"),
            },
          ]}
          actions={[
            {
              id: "edit",
              tip: t("edit"),
              onClick: (shareholder) => openForm(shareholder),
              buttonProps: {
                iconComponent: <FaEdit className="inline" />,
                className: "w-12",
                horizontal: "1",
                vertical: "1",
                verticalMargin: "0",
                horizontalMargin: "0",
                textColor: `buttons-card-text`,
                bgColor: `buttons-card`,
                bgHoverColor: `buttons-hover`,
              },
            },
            {
              id: "delete",
              tip: t("delete"),
              onClick: (shareholder) => deleteHolder(shareholder),
              buttonProps: {
                iconComponent: <FaTrash className="inline" />,
                className: "w-12 bg-red text-white",
                horizontal: "1",
                vertical: "1",
                verticalMargin: "0",
                horizontalMargin: "0",
              },
            },
          ]}
        />
      )}

      {showShareholderSection && (
        <SidePanel
          width={"1/4"}
          onClose={closeForm}
          title={t(!shareholderToEdit ? "add_shareholder" : "update_shareholder")}
        >
          <form onSubmit={handleSubmit(onSubmit, onInvalid)} noValidate className="mt-6">
            <Input
              name="stakeholder"
              label={t("stakeholder")}
              error={errors.stakeholder}
              required={true}
              reference={register}
            />

            <CurrencyInput
              nameInput={"investment"}
              nameSelect={"unit"}
              reference={register}
              placeholder={t("investment")}
              label={t("investment")}
              error={errors.investment}
              watch={watch}
              setValue={_setValue}
            />

            <Input
              type="number"
              name="shares"
              label={`${t("shares")} (%)`}
              placeholder={`${t("shares")} (%)`}
              error={errors.shares}
              required={true}
              reference={register}
            />

            <Input
              type="date"
              name="incorporation_date"
              label={t("incorporation_date")}
              error={errors.incorporation_date}
              required={false}
              reference={register}
            />

            <div className={`flex justify-end mt-4 border-t pt-2 border-separator`}>
              <ButtonMain
                text={t(!shareholderToEdit ? "add_shareholder" : "update_shareholder")}
                type="submit"
              />
            </div>
          </form>
        </SidePanel>
      )}
      {loading && <Loader />}
    </>
  );
};
