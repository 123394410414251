import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "components/ui/molecules/Modal";
import { TagsSelector } from "../ui/atoms/TagsSelector";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";

export const ManageTagsModal = ({ showModal, handleCloseModal, onSubmit, tags, currentTags }) => {
  const { t } = useTranslation();
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    setSelectedTags(currentTags ? currentTags.map((tag) => tag._id) : []);
  }, [currentTags]);

  const memoizedTags = useMemo(() => tags, [tags]);
  const memoizedSelectedTags = useMemo(() => selectedTags, [selectedTags]);

  console.log(tags);

  return (
    <Modal showModal={showModal}>
      <div className="w-72">
        <h3 className={"mb-4 text-main"}>{t("manage_tags")}</h3>
        <TagsSelector tags={tags} selectedTags={selectedTags} setSelectedTags={setSelectedTags} />
        <div className="flex justify-between pt-6 mt-6 border-t border-gray-lines">
          <ButtonSecondary text={t("cancel")} onClick={handleCloseModal} />
          <ButtonMain type="submit" text={t("save")} onClick={() => onSubmit(selectedTags)} />
        </div>
      </div>
    </Modal>
  );
};
