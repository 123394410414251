import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

import { CoverBackground } from "components/ui/atoms/CoverBackground";
import { Container } from "components/ui/objects/Container";
import { RoutesLinks } from "components/routes-links";
import ForgotForm from "./components/forgot-form";
import AuthService from "services/auth-service";
import { Loader } from "components/ui/molecules/Loader";
const Forgot = () => {
  const { addToast } = useToasts();

  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async({ email }) => {
    try {
      setLoading(true);
      setEmail(email);
      await AuthService.forgotPassword(email);
      setEmailSent(true);
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t("error_occurred"),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
    } finally {
      setLoading(false);
    }
  };

  if (emailSent) {
    return (
      <Navigate
        to={{
          pathname: RoutesLinks.FORGOT_RESEND_LINK,
          state: { email },
        }}
      />
    );
  } else {
    return (
      <Container variant="session">
        <Container>
          <ForgotForm onSubmit={handleSubmit} />
        </Container>
        {loading && <Loader />}
        <CoverBackground link={true} />
      </Container>
    );
  }
};

export default Forgot;
