import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import Select from "components/ui/atoms/Select";
import { Modal } from "components/ui/molecules/Modal";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { DealflowService } from "services/dealflow-service";
import TeamService from "services/team-service";

export const DealOwner = ({ deal, handleClose, setLoading, getDeal }) => {
  const [teammates, setTeammates] = useState([]);
  const [selectedTeammates, setSelectedTeammates] = useState(
    deal.owners ? deal.owners.map((owner) => ({ id: owner._id, ...owner })) : [],
  );
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const getTeammates = async () => {
    try {
      setLoading(true);
      const result = await TeamService.getTeammates();
      setTeammates(result.data.data);
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t("error_occurred"),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    const ownersIds = selectedTeammates.map((selectedTeammate) => selectedTeammate.id);
    setLoading(true);
    DealflowService.updateDeal(deal._id, { ...deal, owners: ownersIds })
      .then(() => addToast(t("project_updated"), { appearance: "success", autoDismiss: true }))
      .finally(() => {
        setLoading(false);
        handleClose();
        getDeal();
      });
  };

  const handleSelectTeammates = (values) => {
    setSelectedTeammates(values);
  };

  useEffect(() => {
    getTeammates();
  }, []);

  return (
    <Modal showModal={true} onClose={handleClose}>
      <div className="w-72">
        <h3 className={"mb-4 text-main"}>{t("manage_owners")}</h3>
        <Select
          label={t("select_teammates")}
          required={true}
          multiSelect={true}
          placeholder={t("select_teammates")}
          initialValues={selectedTeammates}
          onSelect={handleSelectTeammates}
          items={teammates
            .filter((teammate) => teammate.user)
            .map((e) => ({
              id: e.user ? e.user._id : `${e._id}_not_registered`,
              value:
                `${e.user ? e.user.name : e.name} ${e.user ? e.user.last_name : e.last_name || ""} ${
                  !e.user ? "(Not registered)" : ""
                }`.trim(),
              disabled: !e.user,
              name: e.user ? e.user.name : e.name,
              email: e.user ? e.user.email : e.email,
              last_name: e.user ? e.user.last_name : e.last_name,
              user: e.user ? e.user._id : null,
              teammate_id: e._id,
            }))}
        />
        <div className="flex justify-between">
          <ButtonSecondary text={t("close")} onClick={() => handleClose()} />
          <ButtonMain text={t("submit")} onClick={() => handleSubmit()} />
        </div>
      </div>
    </Modal>
  );
};
