import { RoutesLinks } from "components/routes-links";
import { CoverBackground } from "components/ui/atoms/CoverBackground";
import { Loader } from "components/ui/molecules/Loader";
import { Container } from "components/ui/objects/Container";
import Lang from "lang";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { ChecksOperations } from "redux/checks";
import UserOperations from "redux/user/user-operations";
import AuthService from "services/auth-service";
import Form from "./components/form";
import RemindersOperations from "redux/reminders/reminders-operations";

const RegisterConfirm = () => {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState(null);
  const location = useLocation();

  const navigate = useNavigate();
  const handleRedirect = (url) => navigate(url);
  const queryParams = new URLSearchParams(location.search);
  const { addToast } = useToasts();
  const activationCode = queryParams.get("code");
  const clientId = queryParams.get("client");
  const [registered, setRegistered] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const checkActivationParameters = async() => {
      try {
        setLoading(true);
        await AuthService.checkActivationCode(activationCode, clientId);
      } catch (error) {
        console.error(error);
        addToast(t("invalid_activation_parameters"), {
          appearance: "error",
          autoDismiss: true,
        });
        handleRedirect(RoutesLinks.LOGIN_LINK);
      } finally {
        setLoading(false);
      }
    };

    if (!activationCode || !clientId) {
      handleRedirect(RoutesLinks.LOGIN_LINK);
    } else {
      checkActivationParameters();
    }
  }, []);

  const setToken = (token) => dispatch(UserOperations.setToken({ token }));
  const setApiKey = (apiKey) => dispatch(UserOperations.setApiKey({ apiKey }));

  const setUserInfo = (data) => dispatch(UserOperations.setUserInfo({ data }));
  const setLoginEntries = (loginEntries) => dispatch(UserOperations.setLoginEntries(loginEntries));

  const setReminders = (reminders) => dispatch(RemindersOperations.setReminders(reminders));

  const setTotalReminders = (total) => dispatch(RemindersOperations.setTotalReminders(total));

  const handleSubmit = async({ password_register, password_confirmation, accept_terms }) => {
    try {
      setLoading(true);
      setEmail(email);
      let result = {};
      const response = await AuthService.confirmRegister(
        activationCode,
        clientId,
        password_register,
        Lang.getCurrentLang().code,
      );
      result = response.data.data;

      if (Object.keys(result).length) {
        dispatch(ChecksOperations.setStage("ACCOUNT_ACTIVATED"));

        setUserInfo(result.user);
        setToken(result.token);
        setApiKey(result.api_key);
        setLoginEntries(result.login_entries);
        if (result.reminders) {
          setReminders(result.reminders.reminders);
          setTotalReminders(result.reminders.total);
        }
      }
      setRegistered(true);
    } catch (error) {
      console.error(error);
      addToast(
        error.response && error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t("error_occurred"),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
    } finally {
      setLoading(false);
    }
  };

  if (registered) {
    return (
      <Navigate
        to={{
          pathname: RoutesLinks.CHECKS_LINK,
        }}
      />
    );
  } else {
    return (
      <Container variant="session">
        <Container>
          <Form onSubmit={handleSubmit} loading={loading} />
          {loading && <Loader />}
        </Container>

        <CoverBackground link={true} />
      </Container>
    );
  }
};

export default RegisterConfirm;
