import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Trans, useTranslation } from "react-i18next";
import Config from "../../../../config";
import { RoutesLinks } from "../../../routes-links";
import { Link } from "react-router-dom";

import { FormTitle } from "../../../ui/atoms/FormTitle";
import { BannerResponsive } from "../../../ui/atoms/BannerResponsive";

import { Input } from "../../../ui/atoms/Input";
import { BottomText } from "../../../ui/atoms/BottomText";
import moment from "moment";
import { PoweredText } from "components/ui/atoms/PoweredText";

import "styles/main.css";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { useSelector } from "react-redux";

const LoginForm = ({ onSubmit, loading }) => {
  const { t } = useTranslation();

  const platform = useSelector((state) => state.app.platform);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(t("incorrect_format")).required(t("required_field")),
    password: Yup.string().required(t("required_field")).min(8, t("password_length")),
  });

  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
    resolver: yupResolver(LoginSchema),
  });

  return (
    <section className="flex flex-col justify-center items-center | h-full w-full  | lg:p-16">
      <div className="max-w-xs sm:max-w-md md:max-w-lg mx-2 mt-16 sm:mt-32 md:mt-0 lg:py-4 ">
        <BannerResponsive />
        <FormTitle formTitle={<Trans i18nKey="welcome" values={{ platform_name: platform.name }} />} />
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <Input
            reference={register}
            id="email"
            type="email"
            name="email"
            label={t("email") + ": *"}
            placeholder={t("introduce_email")}
            error={errors?.email}
            disabled={!!loading}
          />

          <Input
            reference={register}
            id="password"
            type="password"
            name="password"
            label={t("password")}
            required={true}
            placeholder={t("password_req_min_char")}
            error={errors?.password}
            disabled={!!loading}
          />

          {loading ? (
            <span className={"text-xs text-main hover:text-link-hover font-semibold cursor-default"}>
              {t("forgot_password")}
            </span>
          ) : (
            <Link to={`${RoutesLinks.FORGOT_LINK}`} className={"text-xs text-main hover:text-link-hover"}>
              {t("forgot_password")}
            </Link>
          )}

          {/* <Checkbox
            reference={register}
            id="keep_logged"
            name="keep_logged"
            label="Keep me logged in"
          /> */}

          <div className="my-4">
            <ButtonMain type="submit" text={t("sign_in")} disabled={!!loading} width={"full"} paddingVertical={3} />
          </div>
          {!platform.cobranding && !platform.white_label && (
            <>
              <span className="text-gray font-medium text-xs">{t("not_registered_yet")} </span>
              {
                <a
                  href={"https://www.kiota.com/demo-account.html"}
                  target="_blank"
                  rel="noreferrer"
                  className={
                    "underline text-xs text-main hover:text-link-hover font-semibold cursor-default cursor-pointer"
                  }
                >
                  {t("create_account")}
                </a>
              }
            </>
          )}
        </form>
      </div>
      <BottomText
        text={<Trans i18nKey="copyright" values={{ year: moment().format("YYYY"), platform_name: platform.name }} />}
      />
      {platform && (platform.cobranding || platform.white_label) && <PoweredText />}
    </section>
  );
};

export default LoginForm;
