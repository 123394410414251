import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import LanguageForm from "./components/language-form";
import { useTranslation } from "react-i18next";
import Lang from "lang";
import { Loader } from "components/ui/molecules/Loader";
import { Card } from "components/ui/atoms/Card";
import useUser from "../../../../../hooks/useUser";
import Config from "config";
import AccountService from "services/account-service";

const Language = ({ activeTheme }) => {
  const { t, i18n } = useTranslation();

  const { addToast } = useToasts();
  const { user, reloadUserInfo } = useUser();

  const [showLanguage, setShowLanguage] = useState(false);

  const [loading, setLoading] = useState(false);

  const onSubmit = async (langCode) => {
    try {
      setLoading(true);
      await AccountService.updateLanguage({
        language: langCode,
      });
      Lang.setLang(langCode);
      reloadUserInfo();
      await i18n.changeLanguage(langCode);
      setShowLanguage(!showLanguage);
      addToast(t("language_updated_successfully"), {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      if (error.response) {
        addToast(t("error_occurred_updating_language"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <h3 className={"border-b font-medium text-main font-bold mb-4 border-main"}>
        {t("language")}
      </h3>
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-start sm:justify-between">
        <div>
          <small className="text-gray-dark font-light">{t("change_language")}</small>
        </div>
        <div className="flex flex-row">
          <LanguageForm
            onSubmit={onSubmit}
            activeTheme={activeTheme}
            setShowLanguage={setShowLanguage}
          />
        </div>
      </div>
      {loading && <Loader />}
    </Card>
  );
};

export default Language;
