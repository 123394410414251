import { useTranslation } from "react-i18next";
import { SidePanel } from "components/ui/objects/SidePanel";
import { ProgressBar } from "../atoms/ProgressBar";
import { useOnboardingSteps } from "hooks/useOnboardingSteps";
import { ProgressTask } from "../atoms/ProgressTask";
import OnboardingOperations from "redux/onboarding/onboarding-operations";
import { useDispatch } from "react-redux";

export const OnboardingTasksPannel = () => {
  const onboardingSteps = useOnboardingSteps();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const SECTIONS = [
    "ot_general",
    "ot_dealflow_caption",
    "ot_dealflow_management",
    "ot_dealflow_analysis",
    "ot_automations",
  ];

  return (
    <SidePanel
      title={t("onboarding_taks")}
      onClose={() => dispatch(OnboardingOperations.handleOnboardingTasksPannel(false))}
      width="1/3"
    >
      {SECTIONS.map((section, index) => {
        return (
          <div key={index} className={"font-bold text-secondary"}>
            <div className={"border-b border-secondary"}>{t(section)}</div>
            {onboardingSteps.getTasks(section).map((task, index) => {
              return (
                <ProgressTask
                  key={index}
                  title={task.title}
                  description={task.description}
                  completed={task.completed}
                  onClick={
                    task.onClick
                      ? () => {
                          task.onClick();
                          dispatch(OnboardingOperations.handleOnboardingTasksPannel(false));
                        }
                      : null
                  }
                />
              );
            })}
          </div>
        );
      })}
      <div className="mb-4 mt-6 border-t pt-4">
        <ProgressBar value={onboardingSteps.getTaskProgress()} />
      </div>
    </SidePanel>
  );
};
