import { useEffect, useState } from "react";
import { SidePanel } from "components/ui/objects/SidePanel";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useToasts } from "react-toast-notifications";
import { Input } from "components/ui/atoms/Input";
import Select from "components/ui/atoms/Select";
import { Autocomplete } from "components/ui/atoms/Autocomplete";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import ProjectStages from "assets/json/project-stages.json";
import Config from "config";
import SupportService from "services/support-service";
import FormsService from "services/forms-service";

export const EventsStartupsFilters = ({ onFilter, onClose, filters = {}, judges = [] }) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const evaluationStatuses = [
    { id: "evaluated", value: t("evaluated") },
    { id: "not_evaluated", value: t("not_evaluated") },
  ];

  const statuses = [
    { id: "admitted", value: t("admitted") },
    { id: "discarded", value: t("discarded") },
  ];

  const { register, handleSubmit, trigger, errors, setValue, reset, watch } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string(),
        status: Yup.string().nullable(),
        evaluation_status: Yup.string().nullable(),
        assigned_judge: Yup.string().nullable(),
      }),
      { abortEarly: false },
    ),
    criteriaMode: "all",
    reValidateMode: "all",
    mode: "onChange",
  });

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, config);
    trigger(name);
  };

  const filter = ({ clearFilters, ...filterValues }) => {
    let _filters = {};

    if (!clearFilters) {
      _filters = Object.keys(filterValues).reduce((acc, key) => {
        if (filterValues[key]) {
          acc[key] = filterValues[key];
        }
        return acc;
      }, {});
    }

    onFilter && onFilter(_filters);
    onClose && onClose();
  };

  const resetFilters = () => {
    reset();
    filter({ clearFilters: true });
  };

  const onInvalid = () => {
    addToast(t("form_field_error"), { appearance: "error", autoDismiss: true });
  };

  useEffect(() => {
    register("evaluation_status");
    register("status");
    register("assigned_judge");
  }, [register]);

  useEffect(() => {
    if (filters) {
      Object.entries(filters).forEach(([key, value]) => {
        _setValue(key, value, { shouldValidate: true });
      });
    }
  }, [filters, register]);

  return (
    <SidePanel title={t("filters")} onClose={() => onClose && onClose()} width="1/4">
      <form className="flex flex-col w-full" onSubmit={handleSubmit(filter, onInvalid)}>
        <div className="mt-2">
          <Input
            reference={register}
            name="name"
            label={t("name")}
            placeholder={t("name")}
            error={errors.name}
          />
          <Select
            name="assigned_judge"
            reference={register}
            label={t("judge")}
            placeholder={t("judge")}
            items={judges}
            error={errors.assigned_judge}
            onSelect={(selected) =>
              _setValue("assigned_judge", selected.length ? selected[0].id : null)
            }
            initialValues={
              watch("assigned_judge")
                ? judges
                    .filter((item) => item.id === watch("assigned_judge"))
                    .map((item) => ({ id: item.id, value: item.value }))
                : []
            }
          />

          <Select
            name="evaluation_status"
            reference={register}
            label={t("evaluation_status")}
            placeholder={t("evaluation_status")}
            items={evaluationStatuses}
            error={errors.evaluation_status}
            onSelect={(selected) =>
              _setValue("evaluation_status", selected?.length ? selected[0].id : null)
            }
            initialValues={
              watch("evaluation_status")
                ? evaluationStatuses
                    .filter((item) => item.id === watch("evaluation_status"))
                    .map((item) => ({ id: item.id, value: item.value }))
                : []
            }
          />

          <Select
            name="status"
            reference={register}
            label={t("status")}
            placeholder={t("status")}
            items={statuses}
            error={errors.status}
            onSelect={(selected) => _setValue("status", selected?.length ? selected[0].id : null)}
            initialValues={
              watch("status")
                ? statuses
                    .filter((item) => item.id === watch("status"))
                    .map((item) => ({ id: item.id, value: item.value }))
                : []
            }
          />
        </div>
        <div className={`flex justify-end mt-2 pt-2 border-t border-separator`}>
          <ButtonSecondary
            text={t("clear_filters")}
            onClick={() => resetFilters()}
            type="button"
            marginRight={2}
          />
          <ButtonMain text={t("filter")} type="submit" />
        </div>
      </form>
    </SidePanel>
  );
};
