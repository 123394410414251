import { Card } from "components/ui/atoms/Card";
import { Loader } from "components/ui/molecules/Loader";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Sectors from "assets/json/sectors.json";
import InvestmentStages from "assets/json/investment-stages.json";
import Countries from "assets/json/countries.json";
import ProjectStages from "assets/json/project-stages.json";
import BusinessModels from "assets/json/business-models.json";

import Select from "../ui/atoms/Select";
import { FaTimes } from "react-icons/fa";
import { TextArea } from "components/ui/atoms/TextArea";
import { CurrencyInput } from "components/ui/atoms/CurrencyInput";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { Switch } from "components/ui/atoms/Switch";
import useUser from "hooks/useUser";

const sortByKey = (obj, key) => {
  const resp = obj.sort((a, b) => {
    const nameA = a[key].toUpperCase();
    const nameB = b[key].toUpperCase();
    if (nameA < nameB) { return -1; }
    if (nameA > nameB) { return 1; }
    // names must be equal
    return 0;
  });
  return resp;
};

export const InvestorInvestmentThesis = ({ onSubmit, thesis, irm = false, member = false }) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);

  const { user } = useUser();
  const [minimumTicketHardFilter, setMinimumTicketHardFilter] = useState(false);
  const [maximumTicketHardFilter, setMaximumTicketHardFilter] = useState(false);
  const [minimumValuationHardFilter, setMinimumValuationHardFilter] = useState(false);
  const [maximumValuationHardFilter, setMaximumValuationHardFilter] = useState(false);
  const [sectorsHardFilter, setSectorsHardFilter] = useState(false);
  const [businessModelsHardFilter, setBusinessModelsHardFilter] = useState(false);
  const [investmentStagesHardFilter, setInvestmentStagesHardFilter] = useState(false);
  const [projectStagesHardFilter, setProjectStagesHardFilter] = useState(false);
  const [countriesHardFilter, setCountriesHardFilter] = useState(false);

  const leadInvestorsOptions = [
    { id: "yes", value: t("yes") },
    { id: "no", value: t("no") },
    { id: "depends", value: t("depends") },
  ];
  const followOnOptions = [
    { id: "yes", value: t("yes") },
    { id: "no", value: t("no") },
    { id: "depends", value: t("depends") },
  ];

  const sectors = Sectors.map((item) => ({ value: t(item.key), id: item._id }))
    .sort((a, b) => (a.value > b.value ? 1 : -1))
    .reduce((prev, curr) => {
      const alreadyPushedIndex = prev.findIndex((i) => i.id === curr.id);
      if (alreadyPushedIndex === -1) {
        prev.push(curr);
      }
      return prev;
    }, []);

  const countries = Countries.map((item) => ({
    ...item,
    id: item._id,
    value: `${t(item.key)}`,
  }));
  const businessModels = BusinessModels.map((item) => ({
    ...item,
    id: item._id,
    value: t(item.key),
  }));
  const projectStages = ProjectStages.map((item) => ({
    ...item,
    id: item._id,
    value: t(item.key),
  }));
  const investmentStages = InvestmentStages.map((item) => ({
    ...item,
    id: item._id,
    value: t(item.key),
  })).sort((a, b) => a.value - b.value);

  const countriesSorted = sortByKey(countries, "value");
  const sectorsSorted = sortByKey(sectors, "value");

  const { register, errors, trigger, setValue, handleSubmit, watch } = useForm({
    mode: "all",
    criteriaMode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(
      Yup.object().shape({
        sectors: Yup.array().nullable(),
        business_models: Yup.array().nullable(),
        investment_stages: Yup.array().nullable(),
        project_stages: Yup.array().nullable(),
        countries: Yup.array().nullable(),
        lead_investor: Yup.array().nullable(),
        lead_investor_comments: Yup.string().nullable(),
        follow_on: Yup.array().nullable(),
        follow_on_comments: Yup.string().nullable(),
        minimum_ticket_unit: Yup.string(),
        maximum_ticket_unit: Yup.string(),
        maximum_valuation_unit: Yup.string(),
        minimum_valuation_unit: Yup.string(),
        minimum_ticket: Yup.number()
          .typeError(t("incorrect_format"))
          .transform((_, val) => (val === `${Number(val)}` ? Number(val) : null))
          .nullable(),
        maximum_ticket: Yup.number()
          .typeError(t("incorrect_format"))
          .transform((_, val) => (val === `${Number(val)}` ? Number(val) : null))
          .nullable(),
        minimum_valuation: Yup.number()
          .typeError(t("incorrect_format"))
          .transform((_, val) => (val === `${Number(val)}` ? Number(val) : null))
          .nullable(),
        maximum_valuation: Yup.number()
          .typeError(t("incorrect_format"))
          .transform((_, val) => (val === `${Number(val)}` ? Number(val) : null))
          .nullable(),
      }),
    ),
  });

  const onSubmitForm = async(values) => {
    setLoading(true);
    const payload = {
      ...values,
      minimum_ticket_hard_filter: minimumTicketHardFilter,
      maximum_ticket_hard_filter: maximumTicketHardFilter,
      minimum_valuation_hard_filter: minimumValuationHardFilter,
      maximum_valuation_hard_filter: maximumValuationHardFilter,
      sectors_hard_filter: sectorsHardFilter,
      business_models_hard_filter: businessModelsHardFilter,
      investment_stages_hard_filter: investmentStagesHardFilter,
      project_stages_hard_filter: projectStagesHardFilter,
      countries_hard_filter: countriesHardFilter,
    };
    await onSubmit(payload);
    setLoading(false);
  };

  const onError = () => {
    addToast(t("form_field_error"), { appearance: "error", autoDismiss: true });
  };

  const hydrateForm = () => {
    const selectables = [
      "sectors",
      "business_models",
      "investment_stages",
      "project_stages",
      "countries",
    ];

    const hardFilterKeys = [
      "minimum_ticket_hard_filter",
      "maximum_ticket_hard_filter",
      "minimum_valuation_hard_filter",
      "maximum_valuation_hard_filter",
      "sectors_hard_filter",
      "business_models_hard_filter",
      "investment_stages_hard_filter",
      "project_stages_hard_filter",
      "countries_hard_filter",
    ];

    if (!thesis.minimum_ticket_unit) {
      setValue("minimum_ticket_unit", "k");
    }
    if (!thesis.maximum_ticket_unit) {
      setValue("maximum_ticket_unit", "k");
    }
    if (!thesis.minimum_valuation_unit) {
      setValue("minimum_valuation_unit", "k");
    }
    if (!thesis.maximum_valuation_unit) {
      setValue("maximum_valuation_unit", "k");
    }

    Object.keys(thesis).forEach((key) => {
      if (!hardFilterKeys.includes(key)) {
        setValue(
          key,
          selectables.includes(key)
            ? (thesis[key] || []).map((item) => item._id)
            : key === "lead_investor"
              ? [thesis[key]] || []
              : key === "follow_on"
                ? [thesis[key]] || []
                : [
                    "minimum_ticket_unit",
                    "maximum_ticket_unit",
                    "minimum_valuation_unit",
                    "maximum_valuation_unit",
                  ].includes(key)
                    ? thesis[key] || "k"
                    : thesis[key] || "",
        );
      } else {
        if (thesis.minimum_ticket_hard_filter) { setMinimumTicketHardFilter(thesis.minimum_ticket_hard_filter); }
        if (thesis.maximum_ticket_hard_filter) { setMaximumTicketHardFilter(thesis.maximum_ticket_hard_filter); }
        if (thesis.minimum_valuation_hard_filter) { setMinimumValuationHardFilter(thesis.minimum_valuation_hard_filter); }
        if (thesis.maximum_valuation_hard_filter) { setMaximumValuationHardFilter(thesis.maximum_valuation_hard_filter); }
        if (thesis.sectors_hard_filter) { setSectorsHardFilter(thesis.sectors_hard_filter); }
        if (thesis.business_models_hard_filter) { setBusinessModelsHardFilter(thesis.business_models_hard_filter); }
        if (thesis.investment_stages_hard_filter) { setInvestmentStagesHardFilter(thesis.investment_stages_hard_filter); }
        if (thesis.project_stages_hard_filter) { setProjectStagesHardFilter(thesis.project_stages_hard_filter); }
        if (thesis.countries_hard_filter) { setCountriesHardFilter(thesis.countries_hard_filter); }
      }
    });

    trigger();
  };

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, { shouldValidate: true, shouldTouch: true, ...config });
    trigger(name);
  };

  const renderLeadInvestorCard = () => (
      <div className="border border-gray-lines p-4 rounded-2xl">
        <h5 className={"text-main text-lg font-bold border-b border-main mb-4"}>
          {t("lead_investor")}
        </h5>
        {member && (
          <>
            {thesis.hasOwnProperty("lead_investor") && thesis.lead_investor !== null && (
              <div className="font-semibold">{t(thesis.lead_investor)}</div>
            )}
            {(!thesis.hasOwnProperty("lead_investor") || thesis.lead_investor === null) && (
              <div className="text-xs">{t("information_not_provided")}</div>
            )}
          </>
        )}
        {!member && (
          <>
            <Select
              disabled={member}
              name={"lead_investor"}
              placeholder={t(irm ? "is_this_investor_lead" : "do_you_lead_rounds")}
              items={leadInvestorsOptions}
              error={errors.lead_investor}
              multiSelect={false}
              isClearable={false}
              showQuantity={false}
              initialValues={
                leadInvestorsOptions.filter((i) => watch("lead_investor")?.includes(i.id)) || []
              }
              onSelect={(selection) =>
                _setValue(
                  "lead_investor",
                  selection.map((item) => item.id),
                )
              }
            />
            <div className="my-2">
              <TextArea
                disabled={member}
                reference={register}
                name={"lead_investor_comments"}
                error={errors.lead_investor_comments}
                label={t(
                  irm
                    ? "lead_investor_comments"
                    : member
                      ? "comments"
                      : "do_you_have_comments_lead_investor",
                )}
                maxLength={200}
                placeholder={t(
                  irm
                    ? "lead_investor_comments"
                    : member
                      ? "comments"
                      : "do_you_have_comments_lead_investor",
                )}
              />
            </div>
          </>
        )}
      </div>
  );

  const renderFollowOnCard = () => (
      <div className="border border-gray-lines p-4 rounded-2xl">
        <h5 className={"text-main text-lg font-bold border-b border-main mb-4"}>
          {t("follow_on")}
        </h5>

        {member && (
          <>
            {thesis.hasOwnProperty("follow_on") && thesis.follow_on !== null && (
              <div className="font-semibold">{t(thesis.follow_on)}</div>
            )}
            {(!thesis.hasOwnProperty("follow_on") || thesis.follow_on === null) && (
              <div className="text-xs">{t("information_not_provided")}</div>
            )}
          </>
        )}
        {!member && (
          <>
            <Select
              disabled={member}
              name={"follow_on"}
              placeholder={t(irm ? "does_this_investor_follow_on" : "do_you_follow_on")}
              items={followOnOptions}
              error={errors.follow_on}
              multiSelect={false}
              isClearable={false}
              showQuantity={false}
              initialValues={
                followOnOptions.filter((i) => watch("follow_on")?.includes(i.id)) || []
              }
              onSelect={(selection) =>
                _setValue(
                  "follow_on",
                  selection.map((item) => item.id),
                )
              }
            />
            <div className="my-2">
              <TextArea
                disabled={member}
                reference={register}
                name={"follow_on_comments"}
                error={errors.follow_on_comments}
                label={t(
                  irm
                    ? "follow_on_comments"
                    : member
                      ? "comments"
                      : "do_you_have_comments_follow_on",
                )}
                maxLength={200}
                placeholder={t(
                  irm
                    ? "follow_on_comments"
                    : member
                      ? "comments"
                      : "do_you_have_comments_follow_on",
                )}
              />
            </div>
          </>
        )}
      </div>
  );

  const renderSelectorCard = (title, name, collection, switchItem, onEmpty) => (
      <div className="border border-gray-lines p-4 rounded-2xl">
        <div className={"flex justify-between border-b border-main mb-4"}>
          <div>
            <h5 className={"text-main text-lg font-bold"}>{t(title)}</h5>
          </div>
          <div>{switchItem}</div>
        </div>

        {!member && (
          <Select
            disabled={member}
            name={name}
            placeholder={t("choose_multiple_options")}
            items={collection}
            error={errors[name]}
            multiSelect={true}
            isClearable={false}
            showQuantity={false}
            initialValues={
              watch(name)
                ? watch(name).map((item) => collection.find((i) => i.id === item))
                : []
            }
            onSelect={(selection) => {
              _setValue(
                name,
                selection.map((item) => item.id),
              );
            }}
          />
        )}

        {watch(name)?.length !== 0 && (
          <div>
            {watch(name)?.map((modelId) => (
              <span
                key={modelId}
                className={
                  "inline-block px-2 py-1 mr-2 my-1 text-xs font-medium text-buttons-primary bg-white border-gray-lines border rounded-full"
                }
              >
                {collection.find((s) => s.id === modelId)?.value}

                {!member && (
                  <FaTimes
                    onClick={() => {
                      _setValue(
                        name,
                        watch(name).filter((id) => id !== modelId),
                      );
                    }}
                    className="inline-block ml-2 cursor-pointer"
                  />
                )}
              </span>
            ))}
          </div>
        )}
        {watch(name)?.length === 0 && (
          <div className="text-xs">{t("information_not_provided")}</div>
        )}
      </div>
  );

  useEffect(() => {
    register("minimum_ticket_unit");
    register("maximum_ticket_unit");
    register("minimum_valuation_unit");
    register("maximum_valuation_unit");

    register("follow_on");
    register("lead_investor");
    register("sectors");
    register("business_models");
    register("investment_stages");
    register("project_stages");
    register("countries");
  }, [register]);

  useEffect(() => {
    if (thesis) {
      hydrateForm();
    }
  }, [thesis]);

  return (
    <>
      <Card>
        <form onSubmit={handleSubmit(onSubmitForm, onError)}>
          <div className="mb-4">
            <h5 className={"text-main text-lg font-bold border-b border-main mb-4"}>
              {t("investment")}
            </h5>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="border border-gray-lines rounded-2xl p-4">
                <CurrencyInput
                  disabled={member}
                  nameInput={"minimum_ticket"}
                  nameSelect={"minimum_ticket_unit"}
                  reference={register}
                  placeholder={t("minimum_ticket")}
                  label={t("minimum_ticket")}
                  error={errors.minimum_ticket}
                  watch={watch}
                  setValue={_setValue}
                  switchOption={true}
                  switchValue={minimumTicketHardFilter}
                  setSwitchValue={(value) => setMinimumTicketHardFilter(value)}
                  switchText={t(
                    minimumTicketHardFilter ? "hard_filter_enabled" : "hard_filter_disabled",
                  )}
                  switchTooltip={t("hard_filter_tooltip")}
                />
              </div>
              <div className="border border-gray-lines rounded-2xl p-4">
                <CurrencyInput
                  disabled={member}
                  nameInput={"maximum_ticket"}
                  nameSelect={"maximum_ticket_unit"}
                  reference={register}
                  placeholder={t("maximum_ticket")}
                  label={t("maximum_ticket")}
                  error={errors.maximum_ticket}
                  watch={watch}
                  setValue={_setValue}
                  switchOption={true}
                  switchValue={maximumTicketHardFilter}
                  setSwitchValue={(value) => setMaximumTicketHardFilter(value)}
                  switchText={t(
                    maximumTicketHardFilter ? "hard_filter_enabled" : "hard_filter_disabled",
                  )}
                  switchTooltip={t("hard_filter_tooltip")}
                />
              </div>
              <div className="border border-gray-lines rounded-2xl p-4">
                <CurrencyInput
                  disabled={member}
                  nameInput={"minimum_valuation"}
                  nameSelect={"minimum_valuation_unit"}
                  reference={register}
                  placeholder={t("minimum_valuation")}
                  label={t("minimum_valuation")}
                  error={errors.minimum_valuation}
                  watch={watch}
                  setValue={_setValue}
                  switchOption={true}
                  switchValue={minimumValuationHardFilter}
                  setSwitchValue={(value) => setMinimumValuationHardFilter(value)}
                  switchText={t(
                    minimumValuationHardFilter ? "hard_filter_enabled" : "hard_filter_disabled",
                  )}
                  switchTooltip={t("hard_filter_tooltip")}
                />
              </div>
              <div className="border border-gray-lines rounded-2xl p-4">
                <CurrencyInput
                  disabled={member}
                  nameInput={"maximum_valuation"}
                  nameSelect={"maximum_valuation_unit"}
                  reference={register}
                  placeholder={t("maximum_valuation")}
                  label={t("maximum_valuation")}
                  error={errors.maximum_valuation}
                  watch={watch}
                  setValue={_setValue}
                  switchOption={true}
                  switchValue={maximumValuationHardFilter}
                  setSwitchValue={(value) => setMaximumValuationHardFilter(value)}
                  switchText={t(
                    maximumValuationHardFilter ? "hard_filter_enabled" : "hard_filter_disabled",
                  )}
                  switchTooltip={t("hard_filter_tooltip")}
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {renderLeadInvestorCard()}
            {renderFollowOnCard()}
            {renderSelectorCard(
              "sectors",
              "sectors",
              sectorsSorted,
              <Switch
                disabled={member}
                tooltip={t("hard_filter_tooltip")}
                checked={sectorsHardFilter}
                onChange={(checked) => {
                  setSectorsHardFilter(checked);
                }}
                text={t(sectorsHardFilter ? "hard_filter_enabled" : "hard_filter_disabled")}
              />,
              () => setSectorsHardFilter(false),
            )}

            {renderSelectorCard(
              "business_models",
              "business_models",
              businessModels,
              <Switch
                disabled={member}
                tooltip={t("hard_filter_tooltip")}
                checked={businessModelsHardFilter}
                onChange={(checked) => {
                  setBusinessModelsHardFilter(checked);
                }}
                text={t(businessModelsHardFilter ? "hard_filter_enabled" : "hard_filter_disabled")}
              />,
              () => setBusinessModelsHardFilter(false),
            )}

            {renderSelectorCard(
              "investment_stages",
              "investment_stages",
              investmentStages,
              <Switch
                disabled={member}
                tooltip={t("hard_filter_tooltip")}
                checked={investmentStagesHardFilter}
                onChange={(checked) => {
                  setInvestmentStagesHardFilter(checked);
                }}
                text={t(
                  investmentStagesHardFilter ? "hard_filter_enabled" : "hard_filter_disabled",
                )}
              />,
              () => setInvestmentStagesHardFilter(false),
            )}

            {renderSelectorCard(
              "project_stages",
              "project_stages",
              projectStages,
              <Switch
                disabled={member}
                tooltip={t("hard_filter_tooltip")}
                checked={projectStagesHardFilter}
                onChange={(checked) => {
                  setProjectStagesHardFilter(checked);
                }}
                text={t(projectStagesHardFilter ? "hard_filter_enabled" : "hard_filter_disabled")}
              />,
              () => setProjectStagesHardFilter(false),
            )}

            {renderSelectorCard(
              "countries",
              "countries",
              countriesSorted,
              <Switch
                disabled={member}
                tooltip={t("hard_filter_tooltip")}
                checked={countriesHardFilter}
                onChange={(checked) => {
                  setCountriesHardFilter(checked);
                }}
                text={t(countriesHardFilter ? "hard_filter_enabled" : "hard_filter_disabled")}
              />,
              () => setCountriesHardFilter(false),
            )}
          </div>

          {!member && !user.lp && (
            <div className="flex justify-end my-4">
              <ButtonMain text={t("save")} type="submit" width="full md:w-1/2" />
            </div>
          )}
        </form>
      </Card>
      {loading && <Loader />}
    </>
  );
};
