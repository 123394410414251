import { useCallback, useEffect, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import PipelineList from "../lists";
import PipelineDealflowTypeCard from "../cards";
import "./pipeline.css";
import { useToasts } from "react-toast-notifications";
import PipelineManagersService from "services/pipeline-managers-service";
import { Loader } from "components/ui/molecules/Loader";
import { RoutesLinks } from "components/routes-links";
import { isEmpty, findIndex } from "lodash";
import NewCardModal from "../new-card-modal";
import { useSwal } from "hooks/useSwal";
import { FaPlus, FaSearch, FaEye, FaTrash } from "react-icons/fa";
import { HiMagnifyingGlassMinus, HiMagnifyingGlassPlus } from "react-icons/hi2";
import { TbRuler2 } from "react-icons/tb";
import { ManageTagsModal } from "../../../tags/ManageTagsModal";
import { PageTitle } from "../../../ui/atoms/PageTitle";
import { Modal } from "components/ui/molecules/Modal";
import Config from "config";
import StageSection from "../StageSection";
import SearchStartupSection from "../SearchStartupSection";
import FormUtils from "utils/form-utils";
import FormAnswersSection from "components/qe-forms/components/forms-form/FormAnswersSection";
import useUser from "hooks/useUser";
import { DealflowService } from "services/dealflow-service";
import { DealResumeSection } from "components/dealflow/DealResumeSection";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import FormsService from "services/forms-service";
import ExportStageSection from "./ExportStageSection";
import BasicFields from "assets/json/basic-fields.json";
import { ExchangeCardSection } from "./ExchangeCardSection";
import { RecordSection } from "./RecordSection";
import { StageRecordSection } from "./StageRecordSection";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { MiniHelp } from "components/ui/atoms/MiniHelp";
import { ShareDealSection } from "components/ui/molecules/ShareDealSection";
import ContactsService from "services/contacts-service";
import { CustomFieldsService } from "services/custom-fields-service";
import NotesService from "services/notes-service";
import DocumentsService from "services/documents-service";
import EvaluationProcessesManagerService from "services/evaluation-processes-manager-service";
import MigrationSection from "components/ui/molecules/MigrationSection";
import { SidePanel } from "components/ui/objects/SidePanel";
import AddNewStartupForm from "./AddNewStartupForm";
import BoardDealNotes from "./components/board-deal-notes";
import TagsService from "services/tags-service";
import VirtualizedList from "./virtualized-list";
import { AiOutlineForm } from "react-icons/ai";
import { SkeletonList } from "components/ui/atoms/skeletons/SkeletonList";

const Board = ({ editable = true, identifier }) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const boardId = identifier || useParams().boardId;
  const { confirm, alert } = useSwal();
  const navigate = useNavigate();
  const location = useLocation();
  const [board, setBoard] = useState(null);
  const [startupToSearch, setStartupToSearch] = useState(null);
  const [showSearchStartupSection, setShowSearchStartupSection] = useState(false);
  const [loading, setLoading] = useState(editable);
  const [loadingRetrievingBoard, setLoadingRetrievingBoard] = useState(true);
  const [loadingJudges, setLoadingJudges] = useState(editable);
  const [intialLoading, setInitialLoading] = useState(true);
  const [showNewCardModal, setShowNewCardModal] = useState(false);
  const [showStageSection, setShowStageSection] = useState(false);
  const [columnToEdit, setColumnToEdit] = useState({});
  const [isSortingColumns, setIsSortingColumns] = useState(null);
  const [movedElement, setMovedElement] = useState({});
  const [updatedSort, setUpdatedSort] = useState([]);
  const [updatedElement, setUpdatedElement] = useState(null);
  const [adding, setAdding] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [cardToAdd, setCardToAdd] = useState({});
  const [cardToRemove, setCardToRemove] = useState({});
  const [cardToEdit, setCardToEdit] = useState({});
  const [showEditCardModal, setShowEditCardModal] = useState(false);
  const [section, setSection] = useState(location.state?.section ?? "board");
  const [showUsedStageError, setShowUsedStageError] = useState(false);
  const [stageInEvents, setStageInEvents] = useState([]);
  const [evaluationProcesses, setEvaluationProcesses] = useState([]);
  const [forms, setForms] = useState([]);
  const [judges, setJudges] = useState([]);
  const [info, setInfo] = useState(null);
  const [deal, setDeal] = useState(null);
  const [record, setRecord] = useState(null);
  const [stageRecord, setStageRecord] = useState(null);
  const [answerToExpand, setAnswerToExpand] = useState(null);
  const { userLanguage, user, reloadUserInfo, checkIfLPhasAccessToSection, getHardFilters } = useUser();
  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [dealToGetNotes, setDealToGetNotes] = useState(null);
  const [dealflowToShare, setDealflowToShare] = useState(null);
  const [stageToExport, setStageToExport] = useState({});
  const [showExportstageSection, setShowExportStageSection] = useState(false);
  const [customFields, setCustomFields] = useState([]);
  const [loadingCustomFields, setLoadingCustomFields] = useState(false);
  const [redFlags, setRedFlags] = useState({});
  const [contacts, setContacts] = useState([]);
  const [dealflowInBoard, setDealflowInBoard] = useState([]);
  const [dealflow, setDealflow] = useState([]);
  const [showCreateDealSection, setShowCreateDealSection] = useState(false);
  const [showExchangeCardSection, setShowExchangeCardSection] = useState(false);
  const [showMigrationSection, setShowMigrationSection] = useState(false);
  const [noteToMigrate, setNoteToMigrate] = useState(null);
  const [dealToExchange, setDealToExchange] = useState(null);
  const [currentStage, setCurrentStage] = useState(null);
  const [dealToUpdateTags, setDealToUpdateTags] = useState(null);
  const [boardWidth, setBoardWidth] = useState();

  const boardMenuWidth = document.getElementById("board-menu");

  const [tags, setTags] = useState([]);

  const BASIC_FIELDS = BasicFields.map((basifField) => ({ id: basifField, value: t(basifField) }));

  const getCustomFields = () => {
    setLoadingCustomFields(true);
    CustomFieldsService.getCustomFields()
      .then((response) => {
        setCustomFields(response.data.data);
      })
      .catch((error) => {
        addToast(
          error.response && error.response.data.msg_key
            ? error.response.data.msg_key
            : t("error_occurred_retrieving_custom_fields"),
          {
            appearance: "error",
            autoDismiss: true,
          },
        );
      })
      .finally(() => {
        setLoadingCustomFields(false);
      });
  };

  const getPipelineManager = (newItem) => {
    setLoadingRetrievingBoard(true);
    if (editable) {
      PipelineManagersService.getPipelineManager(boardId)
        .then((response) => {
          setBoard(response.data.data);
        })
        .catch((error) => {
          addToast(
            error.response && error.response.data.msg_key
              ? error.response.data.msg_key
              : t("error_occurred_retrieving_board"),
            {
              appearance: "error",
              autoDismiss: true,
            },
          );
        })
        .finally(() => {
          setLoadingRetrievingBoard(false);
        });
    } else {
      PipelineManagersService.getSharedBoard(boardId)
        .then((response) => {
          setBoard(response.data.data);
        })
        .catch((error) => {
          addToast(
            error.response && error.response.data.msg_key
              ? error.response.data.msg_key
              : t("error_occurred_retrieving_board"),
            {
              appearance: "error",
              autoDismiss: true,
            },
          );
        })
        .finally(() => {
          setLoadingRetrievingBoard(false);
        });
    }
  };

  const deleteColumn = (column) => {
    confirm({
      title: t("are_you_sure"),
      text: t("delete_column_text"),
    }).then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await PipelineManagersService.deleteStage(boardId, column?._id);
          getPipelineManager();
          addToast(t("column_deleted_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
        } catch (error) {
          if (error.response.status === 403) {
            setShowUsedStageError(true);
            setStageInEvents(error.response.data.extra.events);
          } else {
            addToast(
              error.response
                ? error.response.data.msg_key
                  ? t(error.response.data.msg_key)
                  : t("error_occurred")
                : t("error_occurred_deleting_stage"),
              {
                appearance: "error",
                autoDismiss: true,
              },
            );
          }
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const deleteCard = (column, card) => {
    confirm({
      text: t("delete_card_text"),
    }).then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await PipelineManagersService.deleteItem(boardId, card?._id);
          getPipelineManager();
        } catch (error) {
          console.error(error);
          addToast(
            error.response && error.response.data.msg_keys
              ? error.response.data.msg_key
              : t("error_occurred_deleting_card"),
            {
              appearance: "error",
              autoDismiss: true,
            },
          );
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const editColumn = (column) => {
    setColumnToEdit({
      evaluation_process: column.evaluation_process,
      deleteable: column.deleteable,
      name: column?.name,
      _id: column?._id,
      advance_email_automation: column.advance_email_automation,
      end_reached_email_automation: column.end_reached_email_automation,
      rejection_email_automation: column.rejection_email_automation,
      temporarily_discarded_email_automation: column.temporarily_discarded_email_automation,
      judges: column.judges,
      forms_automation: column.forms_automation,
      forms: column.forms,
      request_kpis: column.request_kpis,
      confirmation_mode: column.confirmation_mode,
      time_analysis_enabled: column.time_analysis_enabled,
    });
    setShowStageSection(true);
  };

  const exportStage = (stage) => {
    setStageToExport(stage);
    setShowExportStageSection(true);
  };

  const createColumn = () => {
    setColumnToEdit({});
    setShowStageSection(true);
  };

  const addDeal = (data) => {
    confirm({}).then(async(isConfirmed) => {
      if (isConfirmed) {
        setLoading(true);
        try {
          data.assign_startup_to_pipeline_manager = true;
          data.pipeline_manager_board = boardId;
          data.pipeline_manager_stage = columnToEdit?._id;
          await DealflowService.addDeal(data);
          getPipelineManager();
          addToast(t("startup_added_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
          reloadUserInfo();
          setShowCreateDealSection(false);
        } catch (error) {
          console.error(error);
          addToast(t(error?.response?.data?.msg_key || "error_adding_startup"), {
            appearance: "error",
            autoDismiss: true,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const confirmCreateColumn = async(data) => {
    try {
      setLoading(true);
      await PipelineManagersService.addStage(boardId, data);
      getPipelineManager();
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key
          ? error.response.data.msg_key
          : t("error_occurred_creating_column"),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
    } finally {
      setLoading(false);
      setShowStageSection(false);
      setColumnToEdit({});
    }
  };

  const confirmCreateCard = async(card) => {
    const hasEmailAutomationEnabled =
      columnToEdit.advance_email_automation ||
      columnToEdit.end_reached_email_automation ||
      columnToEdit.rejection_email_automation ||
      columnToEdit.temporarily_discarded_email_automation;
    const hasEvaluationProcessAutomationEnabled = !!columnToEdit.evaluation_process;
    const hasFormAutomationEnabled = columnToEdit.forms_automation;
    const hasKPIsAutomationEnabled = columnToEdit.request_kpis;
    const confirmationMode = columnToEdit.confirmation_mode;

    if (
      (hasEmailAutomationEnabled || hasFormAutomationEnabled || hasKPIsAutomationEnabled) &&
      !card.dealflow.main_contact_email
    ) {
      alert({ text: t("email_required_to_move_startup_to_stage_with_automations") });
    } else {
      if (confirmationMode) {
        confirm({
          title: t("are_you_sure"),
          text: t(
            hasEvaluationProcessAutomationEnabled ||
              hasEmailAutomationEnabled ||
              hasFormAutomationEnabled ||
              hasKPIsAutomationEnabled
              ? t("add_card_to_stage_with_automation_text")
              : null,
          ),
        }).then(async(isConfirmed) => {
          if (isConfirmed) {
            try {
              setLoading(true);
              await PipelineManagersService.addItem(boardId, {
                dealflow: card.dealflow?._id,
                stage: columnToEdit?._id,
              });
              getPipelineManager();
            } catch (error) {
              addToast(
                error?.response?.data?.msg_key ? t(error?.response?.data?.msg_key) : t("error_occurred_creating_card"),
                {
                  appearance: "error",
                  autoDismiss: true,
                },
              );
            } finally {
              setLoading(false);
              setShowNewCardModal(false);
              setColumnToEdit({});
            }
          }
        });
      } else {
        try {
          setLoading(true);
          await PipelineManagersService.addItem(boardId, { dealflow: card.dealflow?._id, stage: columnToEdit?._id });
          getPipelineManager();
        } catch (error) {
          addToast(
            error?.response?.data?.msg_key ? t(error?.response?.data?.msg_key) : t("error_occurred_creating_card"),
            {
              appearance: "error",
              autoDismiss: true,
            },
          );
        } finally {
          setLoading(false);
          setShowNewCardModal(false);
          setColumnToEdit({});
        }
      }
    }
  };

  const confirmExportStage = async(fields, language) => {
    try {
      setLoading(true);
      const result = await PipelineManagersService.exportStage(boardId, stageToExport?._id, fields, language);
      if (result.data.data && result.data.data !== "") {
        window.open(result.data.data, "_blank");
      }
      addToast(t("stage_exported_successfully"), {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key
          ? error.response.data.msg_key
          : t("error_occurred_exporting_stage"),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
    } finally {
      setLoading(false);
      setShowExportStageSection(false);
      setStageToExport({});
    }
  };

  const confirmUpdateColumn = async(data) => {
    try {
      setLoading(true);
      await PipelineManagersService.updateStage(boardId, columnToEdit?._id, data);
      getPipelineManager();
      reloadUserInfo();
      addToast(t("column_updated_successfully"), {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key
          ? error.response.data.msg_key
          : t("error_occurred_updating_column"),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
    } finally {
      setLoading(false);
      setShowStageSection(false);
      setColumnToEdit({});
    }
  };

  const sortCard = async(column, oldIndex, newIndex) => {
    try {
      setLoading(true);
      await PipelineManagersService.sortItem(boardId, movedElement?._id, {
        stage: column?._id,
        old_index: oldIndex,
        new_index: newIndex,
      });
      getPipelineManager();
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key ? error.response.data.msg_key : t("error_occurred_updating_card"),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
    } finally {
      setMovedElement({});
      setLoading(false);
    }
  };

  const updateDealTags = async(dealflowId, tags) => {
    setLoading(true);
    try {
      try {
        await DealflowService.updateDealTags(dealflowId, tags);
        return getPipelineManager();
      } catch {
        addToast(t("error_occurred_updating_tags"), { appearance: "error", autoDismiss: true });
      }
    } finally {
      setLoading(false);
    }
  };

  const getTags = () => TagsService.getTags("dealflow").then((response) => {
    setTags(response.data.data);
  }).catch((error) => {
    addToast(
      error.response && error.response.data.msg_key
        ? error.response.data.msg_key
        : t("error_occurred_retrieving_tags"),
      {
        appearance: "error",
        autoDismiss: true,
      },
    );
  });

  useEffect(() => {
    if (board?.columns) {
      const deals = [];
      board.columns.forEach((column) => {
        column.cards.forEach((card, i) => {
          deals.push({ _id: card.dealflow?._id, name: card.dealflow?.name });
        });
      });

      setDealflowInBoard(deals);
    }
    getTags();
  }, [board]);

  useEffect(() => {
    if (board?.columns) {
      const deals = [];
      board.columns.forEach((column) => {
        column.cards.forEach((card) => {
          deals.push({ _id: card.dealflow?._id, name: card.dealflow?.name });
        });
      });

      setDealflowInBoard(deals);
    }
  }, [board]);

  useEffect(() => {
    const orderColumns = async(newIndex) => {
      try {
        setLoading(true);
        await PipelineManagersService.updateStage(boardId, updatedElement?._id, {
          order: newIndex,
        });
        getPipelineManager();
      } catch (error) {
        addToast(
          error.response && error.response.data.msg_key
            ? error.response.data.msg_key
            : t("error_occurred_updating_column"),
          {
            appearance: "error",
            autoDismiss: true,
          },
        );
      } finally {
        setLoading(false);
      }
    };

    if (isSortingColumns === false) {
      setIsSortingColumns(null);
      const newIndex = findIndex(updatedSort, (column) => column?._id === updatedElement?._id);
      const oldIndex = findIndex(board.columns, (column) => column?._id === updatedElement?._id);
      if (newIndex !== oldIndex) {
        orderColumns(newIndex);
      }
      setUpdatedElement(null);
    } else if (isSortingColumns) {
      setUpdatedElement(updatedSort[findIndex(updatedSort, (column) => column.chosen === true)]);
    }
  }, [isSortingColumns]);

  const moveCard = async() => {
    const newColumn = board.columns.find((c) => c._id === cardToAdd.column);
    const oldColumn = board.columns.find((c) => c._id === cardToRemove.column);

    // Optimistic UI update
    const newBoard = { ...board };
    const oldColumnIndex = newBoard.columns.findIndex(col => col._id === oldColumn._id);
    const newColumnIndex = newBoard.columns.findIndex(col => col._id === newColumn._id);
    const [movedCard] = newBoard.columns[oldColumnIndex].cards.splice(movedElement.index, 1);
    newBoard.columns[newColumnIndex].cards.splice(cardToAdd.index, 0, movedCard);
    setBoard(newBoard);

    setLoading(false);

    try {
      await PipelineManagersService.moveItem(boardId, movedElement._id, {
        add: cardToAdd,
        remove: cardToRemove,
      });
      expandOrCollapseColumn(newColumn._id, true);
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key
          ? error.response.data.msg_key
          : t("error_occurred_updating_card"),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
      // Rollback UI change if the API call fails
      getPipelineManager();
    } finally {
      lastColumnAdd = cardToAdd.column;
      lastColumnAdd = cardToRemove.column;
      setCardToRemove({});
      setCardToAdd({});
      setMovedElement({});
      setLoading(false);
      setAdding(false);
      setRemoving(false);
    }
  };
  let lastColumnAdd;
  let lastColumnRemove;
  useEffect(() => {
    const moveCard = async() => {
      const newColumn = board.columns.find((c) => c?._id === cardToAdd.column);
      const hasEmailAutomationEnabled =
        newColumn.advance_email_automation ||
        newColumn.end_reached_email_automation ||
        newColumn.rejection_email_automation ||
        newColumn.temporarily_discarded_email_automation;
      const hasEvaluationProcessAutomationEnabled = !!newColumn.evaluation_process;
      const hasFormAutomationEnabled = newColumn.forms_automation;
      const hasKPIsAutomationEnabled = newColumn.request_kpis;
      const confirmationMode = newColumn.confirmation_mode;

      if (
        hasEmailAutomationEnabled ||
        hasEvaluationProcessAutomationEnabled ||
        hasFormAutomationEnabled ||
        hasKPIsAutomationEnabled ||
        confirmationMode
      ) {
        if (
          (hasEmailAutomationEnabled || hasFormAutomationEnabled || hasKPIsAutomationEnabled) &&
          !movedElement.dealflow.main_contact_email
        ) {
          alert({ text: t("email_required_to_move_startup_to_stage_with_automations") });
        } else {
          confirm({
            title: t("are_you_sure"),
            text:
              hasEmailAutomationEnabled ||
              hasEvaluationProcessAutomationEnabled ||
              hasFormAutomationEnabled ||
              hasKPIsAutomationEnabled
                ? t("move_card_to_stage_with_automation_text")
                : null,
          }).then(async(isConfirmed) => {
            if (isConfirmed) {
              try {
                setLoading(true);
                await PipelineManagersService.moveItem(boardId, movedElement?._id, {
                  add: cardToAdd,
                  remove: cardToRemove,
                });
                getPipelineManager();
                expandOrCollapseColumn(newColumn?._id, true);
              } catch (error) {
                addToast(
                  error.response && error.response.data.msg_key
                    ? error.response.data.msg_key
                    : t("error_occurred_updating_card"),
                  {
                    appearance: "error",
                    autoDismiss: true,
                  },
                );
              } finally {
                setCardToRemove({});
                setCardToAdd({});
                setMovedElement({});
                setLoading(false);
                setAdding(false);
                setRemoving(false);
              }
            }
          });
        }
      } else {
        try {
          setLoading(true);
          await PipelineManagersService.moveItem(boardId, movedElement?._id, {
            add: cardToAdd,
            remove: cardToRemove,
          });
          getPipelineManager();
          expandOrCollapseColumn(newColumn?._id, true);
        } catch (error) {
          addToast(
            error.response && error.response.data.msg_key
              ? error.response.data.msg_key
              : t("error_occurred_updating_card"),
            {
              appearance: "error",
              autoDismiss: true,
            },
          );
        } finally {
          setCardToRemove({});
          setCardToAdd({});
          setMovedElement({});
          setLoading(false);
          setAdding(false);
          setRemoving(false);
        }
      }
    };

    if (adding && removing && !isEmpty(cardToAdd) && !isEmpty(cardToRemove)) {
      moveCard();
    }
  }, [adding, removing, cardToAdd, cardToRemove]);

  const exchangeCard = async(newStage) => {
    const hasEmailAutomationEnabled =
      newStage.advance_email_automation ||
      newStage.end_reached_email_automation ||
      newStage.rejection_email_automation ||
      newStage.temporarily_discarded_email_automation;
    const hasEvaluationProcessAutomationEnabled = !!newStage.evaluation_process;
    const hasFormAutomationEnabled = newStage.forms_automation;
    const hasKPIsAutomationEnabled = newStage.request_kpis;
    const confirmationMode = newStage.confirmation_mode;

    if (
      hasEmailAutomationEnabled ||
      hasEvaluationProcessAutomationEnabled ||
      hasFormAutomationEnabled ||
      hasKPIsAutomationEnabled ||
      confirmationMode
    ) {
      if (
        (hasEmailAutomationEnabled || hasFormAutomationEnabled || hasKPIsAutomationEnabled) &&
        !dealToExchange.dealflow.main_contact_email
      ) {
        alert({ text: t("email_required_to_move_startup_to_stage_with_automations") });
      } else {
        confirm({
          title: t("are_you_sure"),
          text:
            hasEmailAutomationEnabled ||
            hasEvaluationProcessAutomationEnabled ||
            hasFormAutomationEnabled ||
            hasKPIsAutomationEnabled
              ? t("move_card_to_stage_with_automation_text")
              : null,
        }).then(async(isConfirmed) => {
          if (isConfirmed) {
            try {
              setLoading(true);
              await PipelineManagersService.moveItem(boardId, dealToExchange?._id, {
                add: { column: newStage?._id, index: 0 },
                remove: { column: currentStage?._id },
              });
              getPipelineManager();
            } catch (error) {
              addToast(
                error.response && error.response.data.msg_key
                  ? error.response.data.msg_key
                  : t("error_occurred_updating_card"),
                {
                  appearance: "error",
                  autoDismiss: true,
                },
              );
            } finally {
              setShowExchangeCardSection(false);
              setDealToExchange(null);
              setCurrentStage(null);
              setLoading(false);
              setAdding(false);
              setRemoving(false);
            }
          }
        });
      }
    } else {
      try {
        setLoading(true);
        await PipelineManagersService.moveItem(boardId, dealToExchange?._id, {
          add: { column: newStage?._id, index: 0 },
          remove: { column: currentStage?._id },
        });
        getPipelineManager();
      } catch (error) {
        addToast(
          error.response && error.response.data.msg_key
            ? error.response.data.msg_key
            : t("error_occurred_updating_card"),
          {
            appearance: "error",
            autoDismiss: true,
          },
        );
      } finally {
        setShowExchangeCardSection(false);
        setDealToExchange(null);
        setCurrentStage(null);
        setLoading(false);
        setAdding(false);
        setRemoving(false);
      }
    }
  };

  const getEvaluationProcesses = (archived = false) => {
    setLoading(true);
    EvaluationProcessesManagerService.getEvaluationProcesses({
      page: 0,
      rowsPerPage: 9999,
      sortBy: "-createdAt",
      archived: false,
    })
      .then((result) => {
        setEvaluationProcesses(
          result.data.data.events.map((event) => ({ id: event?._id, value: event?.name })),
        );
      })
      .catch((error) => {
        addToast(t("error_occurred_retrieving_evaluation_processes"), {
          appearance: "error",
          autoDismiss: true,
        });
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getForms = async() => {
    try {
      setLoading(true);
      const result = await FormsService.getQuestionnairesForms({
        rowsPerPage: 9999,
        page: 0,
      });
      const { forms } = result.data.data;
      setForms(
        forms.map((form) => ({ id: form?._id, value: form?.name })),
      );
    } catch (error) {
      addToast(t("error_retrieving_forms"), {
        appearance: "error",
        autoDismiss: true,
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getJudges = () => {
    setLoadingJudges(true);
    EvaluationProcessesManagerService.getAllJudges()
      .then((result) => {
        setJudges(result.data.data);
      })
      .catch((error) => {
        addToast(t("error_occurred_retrieving_judges"), {
          appearance: "error",
          autoDismiss: true,
        });
        console.error(error);
      })
      .finally(() => {
        setLoadingJudges(false);
      });
  };

  const getContactsList = async() => {
    try {
      setLoading(true);
      const res = await ContactsService.getContactsList();
      setContacts(res.data.data);
    } catch (error) {
      addToast(t("error_occurred_retrieving_contacts"), { appearance: "error", autoDismiss: true });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      !user.lp ||
      (user.lp &&
        checkIfLPhasAccessToSection("pipeline_manager") &&
        user.lp_permissions.permitted_boards.includes(boardId))
    ) {
      getPipelineManager();
      if (editable) {
        getCustomFields();
        getEvaluationProcesses();
        getJudges();
        getForms();
        getContactsList();
        getDealflow();
      }
    } else {
      navigate(RoutesLinks.DASHBOARD_LINK);
      addToast(t("forbidden_access"), { appearance: "error", autoDismiss: true });
    }
    setInitialLoading(false);
  }, []);

  useEffect(() => {
    if (boardMenuWidth) {
      setBoardWidth(boardMenuWidth.clientWidth);
    }
  }, [boardMenuWidth]);

  const downloadGeneralDocument = async(documentId) => {
    try {
      setLoading(true);
      let response = null;
      if (!editable) {
        response = await DocumentsService.getSharedDocument(documentId);
      } else {
        response = await DocumentsService.getDocument(documentId);
      }
      if (response?.data?.data?.url) {
        window.open(response.data.data.url, "_blank");
      }
    } catch (error) {
      addToast(t(error?.response?.data?.msg_key || "error_occurred_downloading_document"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const downloadDocument = async(documentId) => {
    try {
      setLoading(true);
      let result = null;
      result = await DocumentsService.getDocumentForForm(documentId, !editable);
      if (result?.data?.data?.url) {
        window.open(result.data.data.url, "_blank");
      }
    } catch (error) {
      addToast(t(error?.response?.data?.msg_key || "error_occurred_downloading_document"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const getQuestionnaire = async(formId) => {
    setLoading(true);
    return FormsService.getQuestionnairesForm(formId)
      .then((response) => {
        setRedFlags(response.data.data.red_flags || {});
      })
      .catch(() => {
        addToast(t("error_retrieving_form"), {
          appearance: "error",
          autoDismiss: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getForm = async(formId, dealId, application) => {
    try {
      setLoading(true);
      let result = null;
      if (editable) {
        result = await EvaluationProcessesManagerService.getDealFromEvaluationProcess(formId, dealId, application);
      } else {
        result = await EvaluationProcessesManagerService.getDealFromSharedEvaluationProcess(formId, dealId);
      }
      if (result.data.data) {
        setInfo(result.data.data);
        getQuestionnaire(formId);
      } else {
        alert({ text: t("automated_form_not_answered") });
      }
    } catch (error) {
      addToast(t("error_occurred_retrieving_information"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const getRecord = async(dealId) => {
    try {
      setLoading(true);
      const result = await PipelineManagersService.getDealRecords(boardId, dealId);
      setRecord(result.data.data);
    } catch (error) {
      console.error(error);
      addToast(t("error_occurred_retrieving_information"), { appearance: "error", autoDismiss: true });
    } finally {
      setLoading(false);
    }
  };

  const getStageRecord = async(stageId) => {
    try {
      setLoading(true);
      const result = await PipelineManagersService.getStageRecords(boardId, stageId);
      setStageRecord(result.data.data);
    } catch (error) {
      console.error(error);
      addToast(t("error_occurred_retrieving_information"), { appearance: "error", autoDismiss: true });
    } finally {
      setLoading(false);
    }
  };

  const getDealflow = () => DealflowService.getDealflow({
    rowsPerPage: 999999,
    pool: "true",
    populate: "false",
    boards: "true",
    milestones: "false",
    files: "false",
    reminders: "false",
    annotations: "false",
  }).then((response) => {
    const filteredDealflow = response.data.data.dealflow.filter(
      (deal) => !deal.boards.find((board) => board?._id === boardId),
    );
    setDealflow(filteredDealflow);
  });

  const getDeal = async(dealId) => {
    try {
      setLoading(true);
      const result = await DealflowService.getDeal(dealId);
      if (result.data.data) {
        setDeal(result.data.data);
      } else {
        addToast(t("error_occurred_retrieving_dealflow"), { appearance: "error", autoDismiss: true });
      }
    } catch (error) {
      console.error(error);
      addToast(t("error_occurred_retrieving_dealflow"), { appearance: "error", autoDismiss: true });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (info?.application) {
      const parsedQuestions = FormUtils.getParsedQuestions(
        info.application.questions.map((q) => ({ question_id: q.question })),
      );
      const answers = FormUtils.getParsedAnswers(
        parsedQuestions,
        [info],
        t,
        userLanguage,
        downloadDocument,
        false,
        redFlags,
      );
      setAnswerToExpand({ answers: answers[0], questions: parsedQuestions });
    }
  }, [info, redFlags]);

  const shareDeal = async(section, selectedContacts, data) => {
    confirm({}).then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await DealflowService.shareDealflow(dealflowToShare, {
            section,
            ...data,
            contacts: selectedContacts.map((contact) => ({
              _id: contact?._id,
              name: contact.contact || contact.company,
              email: contact.contact_email,
              language: contact.language,
            })),
          });
          addToast(t("deal_shared_successfully"), { appearance: "success", autoDismiss: true });
          setDealflowToShare(null);
          reloadUserInfo();
        } catch (error) {
          console.error(error);
          addToast(t("error_occurred_sharing_deal"), { appearance: "error", autoDismiss: true });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  useEffect(() => {
    if (dealToExchange) {
      setShowExchangeCardSection(true);
    }
  }, [dealToExchange]);

  const migrateNote = async(dealId) => {
    try {
      setLoading(true);
      await NotesService.updateNote(noteToMigrate, { deal: dealId });
      addToast(t("note_migrated_successfully"), { appearance: "success", autoDismiss: true });
      setNoteToMigrate(null);
      setShowMigrationSection(false);
      setDealToGetNotes(null);
      setNotes([]);
    } catch (error) {
      addToast(t("error_occurred_migrating_note"), { appearance: "error", autoDismiss: true });
    } finally {
      setLoading(false);
    }
  };

  const [zoomLevel, setZoomLevel] = useState(1); // Empieza con zoom normal

  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 0.1); // Aumenta el zoom en 10%
  };

  const handleZoomOut = () => {
    setZoomLevel(zoomLevel - 0.1); // Disminuye el zoom en 10%
  };

  const containerStyle = {
    transform: `scale(${zoomLevel})`,
    transformOrigin: "center center", // Puedes ajustar esto según necesites
  };

  const expandOrCollapseColumn = async(columnId, value) => {
    try {
      setLoading(TbRuler2);
      await PipelineManagersService.updateStage(boardId, columnId, { expanded: value });
      getPipelineManager();
      reloadUserInfo();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleBottomScroll = (e) => {
    const scrollContainer = document.querySelector(".h-full.w-full.pb-6.overflow-x-auto.overflow-y-auto");
    if (scrollContainer) {
      scrollContainer.scrollLeft = e.target.scrollLeft;
    }
  };

  const handleContentScroll = (e) => {
    // Actualiza la posición de desplazamiento de ambos scrollbars
    // cuando el contenido principal se desplace.
    const scrollLeft = e.target.scrollLeft;
    const topScrollbar = document.querySelector(".top-scrollbar");
    const bottomScrollbar = document.querySelector(".bottom-scrollbar");
    if (topScrollbar) {
      topScrollbar.scrollLeft = scrollLeft;
    }
    if (bottomScrollbar) {
      bottomScrollbar.scrollLeft = scrollLeft;
    }
  };

  const renderCard = useCallback(({ item, index, setItemHeight }) => {
    const card = item;
    const column = item.column;
    return (
      <PipelineDealflowTypeCard
        card={card}
        getRecord={getRecord}
        startupToSearch={startupToSearch}
        editable={editable}
        key={`${card._id}-${index}`}
        boardDate={card.board_date}
        userIdentity={card.user}
        stageDate={card.stage_date}
        dealflow={card.dealflow}
        deleteCard={() => deleteCard(column, card)}
        getForm={getForm}
        getDeal={getDeal}
        setDealToGetNotes={setDealToGetNotes}
        setDealflowToShare={setDealflowToShare}
        setDealToExchange={setDealToExchange}
        currentStage={column}
        setCurrentStage={setCurrentStage}
        updateDealTags={updateDealTags}
        tags={tags}
        user={user}
        getHardFilters={getHardFilters}
        checkIfLPhasAccessToSection={checkIfLPhasAccessToSection}
        setItemHeight={setItemHeight}
        setShowTagsModal={setShowEditCardModal}
        setDealToUpdateTags={setDealToUpdateTags}
      />
    );
  }, [deleteCard, getRecord, getForm, getDeal, setDealToGetNotes, setDealflowToShare, setDealToExchange, setCurrentStage, updateDealTags, startupToSearch, tags, user, getHardFilters, checkIfLPhasAccessToSection]);

  const renderNoEditableCard = useCallback(({ item, index, setItemHeight }) => {
    const card = item;
    const column = item.column;

    return (
      <PipelineDealflowTypeCard
        setItemHeight={setItemHeight}
        startupToSearch={startupToSearch}
        getForm={getForm}
        editable={editable}
        key={`${card?._id}-${index}`}
        dealflow={card.dealflow}
        tags={card.tags}
        actions={
          card.event
            ? [
                {
                  label: t("see_profile"),
                  icon: <FaEye className="inline-block" />,
                  onClick: () => {
                    navigate({
                      pathname: `${RoutesLinks.DEALFLOW_LINK}${card.dealflow?._id}`,
                      state: { from: location.pathname },
                    });
                  },
                  className: "text-text-buttons-main bg-bg-buttons-main border border-main",
                },
                {
                  label: t("see_request"),
                  icon: <AiOutlineForm className="inline-block" />,
                  onClick: () => {
                    navigate({
                      pathname: `${RoutesLinks.EVENTS_LINK}${card.event?._id}/startups/${card.dealflow?._id}/application`,
                      state: { from: location.pathname },
                    });
                  },
                  className:
                    "text-text-buttons-secondary border border-border-buttons-secondary bg-white",
                },
                {
                  label: t("delete"),
                  icon: <FaTrash className="inline-block text-red" />,
                  className: "border border-red bg-white",
                  onClick: () => deleteCard(column, card),
                },
              ]
            : [
                {
                  label: t("see_profile"),
                  icon: <FaEye className="inline-block" />,
                  onClick: () => {
                    navigate({
                      pathname: `${RoutesLinks.DEALFLOW_LINK}${card.dealflow?._id}`,
                      state: { from: location.pathname },
                    });
                  },
                  className: "text-text-buttons-main bg-bg-buttons-main border border-main",
                },
                {
                  label: t("delete"),
                  icon: <FaTrash className="inline-block text-red" />,
                  className: "border border-red bg-white",
                  onClick: () => deleteCard(column, card),
                },
              ]
        }
      />
    );
  }, []);

  const vhToPx = (value) => {
    const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    return (value * h) / 100;
  };

  return (
    <>
      {dealflowToShare && (
        <ShareDealSection
          onClose={() => {
            setDealflowToShare(null);
          }}
          onSubmit={shareDeal}
          irm={false}
          contacts={contacts}
          initialValues={user.share_dealflow_preferences || {}}
        />
      )}
      {showExportstageSection && (
        <ExportStageSection
          onClose={() => {
            setStageToExport(null);
            setShowExportStageSection(false);
          }}
          onSubmit={confirmExportStage}
          fields={[
            ...BASIC_FIELDS,
            ...customFields.map((customField) => ({ id: customField?._id, value: `${customField?.name} (${t("custom_field")})` })),
          ].sort((a, b) => {
            const nameA = a.value.toUpperCase(); // ignore upper and lowercase
            const nameB = b.value.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          })}
        />
      )}
      {showMigrationSection && (
        <MigrationSection
          onClose={() => {
            setShowMigrationSection(false);
            setNoteToMigrate(null);
            setDealToGetNotes(null);
          }}
          migrationType={"notes"}
          dealflow={dealflow}
          onSubmit={(dealId) => {
            migrateNote(dealId);
          }}
        />
      )}
      <BoardDealNotes deal={dealToGetNotes} setDeal={setDealToGetNotes} setLoading={setLoading} user={user} />

      {deal && (
        <DealResumeSection
          deal={deal || {}}
          onClose={() => {
            setDeal(null);
          }}
          downloadDocument={downloadGeneralDocument}
        />
      )}
      {record && (
        <RecordSection
          onClose={() => {
            setRecord(null);
          }}
          record={record}
        />
      )}
      {showExchangeCardSection && (
        <ExchangeCardSection
          onClose={() => {
            setShowExchangeCardSection(false);
            setDealToExchange(null);
            setCurrentStage(null);
          }}
          stages={board?.columns}
          exchangeCard={exchangeCard}
          currentStage={currentStage}
        />
      )}
      {stageRecord && (
        <StageRecordSection
          onClose={() => {
            setStageRecord(null);
          }}
          record={stageRecord}
        />
      )}
      {answerToExpand && (
        <FormAnswersSection
          rawAnswer={info}
          redFlags={redFlags}
          answer={answerToExpand}
          onClose={() => {
            setInfo(null);
            setAnswerToExpand(null);
          }}
        />
      )}
      {showUsedStageError && (
        <Modal
          showCloseModal={true}
          showModal={showUsedStageError}
          paddingBottom="2"
          paddingTop="6"
          onClose={() => {
            setShowUsedStageError(false);
            setStageInEvents([]);
          }}
        >
          <h2 className="mt-4 text-xl font-semibold">{t("error_occurred_deleting_stage")}</h2>
          <p className="text-sm font-medium text-gray-dark">{t("stage_is_being_used_in_some_automations")}</p>
          <p className="mt-6 mb-4 text-sm font-medium text-gray-dark">{t("delete_stage_automation_previously")}</p>
          <div className="w-full px-10 mb-6 text-sm font-normal text-justify">
            <ul className="list-disc">
              {stageInEvents.map((q, index) => (
                  <li key={index} className="mb-1">
                    {q}
                  </li>
              ))}
            </ul>
          </div>
        </Modal>
      )}
      {editable && (
        <div className="flex items-start" id="board-menu">
          <div className="flex-1">
            <PageTitle
              title={!board ? t("board") : `${t("board")}: ${board?.name}`}
              showBack={true}
              removeMargin={true}
              onBackClick={() =>
                navigate({
                  pathname: `${RoutesLinks.PIPELINE_MANAGER_LINK}boards`,
                })
              }
            />
          </div>
          <div className="flex items-start gap-4">
            {!user?.help?.configure_pipeline_manager && !user.lp && <MiniHelp item={"configure_pipeline_manager"} />}
            <div className="flex justify-end">
            <HiMagnifyingGlassPlus onClick={handleZoomIn} className="w-6 h-6 mr-1 cursor-pointer hover:font-bold" />
            {(zoomLevel * 100).toFixed(0)}%
            <HiMagnifyingGlassMinus onClick={handleZoomOut} className="w-6 h-6 ml-1 cursor-pointer hover:font-bold" />
            </div>
            {!user.lp && (
              <ButtonMain
                iconComponent={<FaPlus className="inline-block mr-1" />}
                text={t("add_stage")}
                onClick={createColumn}
              />
            )}
            <SearchStartupSection
              onSubmit={(value) => {
                setStartupToSearch(value);
                setShowSearchStartupSection(false);
              }}
              dealflow={dealflowInBoard}
              initialValues={startupToSearch}
            />
          </div>
        </div>
      )}
      {!editable && (
        <div className="flex justify-end">
          <ButtonSecondary
            onClick={() => setShowSearchStartupSection(true)}
            iconComponent={<FaSearch className="inline-block" />}
            marginLeft={2}
          />
        </div>
      )}

      {/* <SectionHeader
        section={section}
        setSection={setSection}
        sectionKeys={['board', 'eda']}
        sectionTitles={[t('board'), t('eda')]}
      /> */}
        {section === "board" && board && (
          <div style={{ flexDirection: "column", justifyContent: "space-between" }}>
            <div className="h-full">
              <div
                style={{ maxWidth: boardWidth }}
                className="h-full pb-6 overflow-x-auto overflow-y-hidden content-container"
                onScroll={handleContentScroll}
              >
                {!intialLoading && board?.columns.length !== 0 && (
                  <div className="flex items-start" style={containerStyle}>
                    {editable && (
                      <ReactSortable
                        onStart={() => setIsSortingColumns(true)}
                        onEnd={() => setIsSortingColumns(false)}
                        list={board.columns}
                        setList={setUpdatedSort}
                        group="root"
                        animation={150}
                        className="flex items-start"
                        chosenClass="sortable-choosen-column"
                      >
                        {board.columns.map((column) => (
                          <div style={{ height: "100%" }} key={column?._id}>
                            <PipelineList
                              entityToManage={board.entity_to_manage}
                              key={column?._id}
                              addCard={() => {
                                setShowNewCardModal(true);
                                setColumnToEdit(column);
                              }}
                              column={{
                                ...column,
                                id: column?._id,
                              }}
                              editable={editable}
                              ListTitle={column?.name}
                              deleteColumn={column.deleteable ? deleteColumn : null}
                              editColumn={editColumn}
                              items={column.cards.length}
                              exportStage={exportStage}
                              getRecord={getStageRecord}
                              expandOrCollapseColumn={expandOrCollapseColumn}
                              startupToSearch={startupToSearch}
                              user={user}
                            >
                                {column.expanded &&
                                    <VirtualizedList
                                    height={vhToPx(60)}
                                    itemHeight={100}
                                    items={column.cards.map((card) => ({ ...card, column }))}
                                    renderItem={renderCard}
                                    dataIid={column._id}
                                    setList={setUpdatedSort}
                                    list={column.cards}
                                    group="grupo1"
                                    animation={200}
                                    easing="cubic-bezier(0.25, 1, 0.5, 1)"
                                    chosenClass="sortable-choosen"
                                    onStart={(evt) => {
                                      setMovedElement({ ...column.cards[evt.oldIndex], initialColumn: column._id, columnId: column._id, oldIndex: evt.oldIndex, newIndex: evt.newIndex });
                                    }}
                                    onSort={(evt) => {
                                      if (!adding && !removing) {
                                        if (movedElement.column === movedElement.initialColumn) {
                                          sortCard(column, evt.oldIndex, evt.newIndex);
                                        }
                                      }
                                    }}
                                    onRemove={(evt) => {
                                      setRemoving(true);
                                      setCardToRemove({
                                        column: column._id,
                                      });
                                    }}
                                    onAdd={(evt) => {
                                      setAdding(true);
                                      setCardToAdd({
                                        column: column._id,
                                        index: evt.newIndex,
                                      });
                                    }}
                                    movedElement={movedElement.columnId && movedElement.columnId === column._id ? { ...movedElement, initialColumn: movedElement.initialColumn === column._id } : null}
                                    onChange={(evt) => {
                                      if (evt.newIndex !== undefined) {
                                        setMovedElement({ ...movedElement, newIndex: evt.newIndex, columnId: column._id });
                                      }
                                    }}
                                  />
                                  }
                            </PipelineList>
                          </div>
                        ))}
                      </ReactSortable>
                    )}
                    {!editable && (
                      board.columns.map((column) => (
                        <PipelineList
                          entityToManage={board.entity_to_manage}
                          key={column?._id}
                          addCard={() => {
                            setShowNewCardModal(true);
                            setColumnToEdit(column);
                          }}
                          column={{
                            ...column,
                            id: column?._id,
                          }}
                          editable={editable}
                          ListTitle={column?.name}
                          deleteColumn={column.deleteable ? deleteColumn : null}
                          editColumn={editColumn}
                          items={column.cards.length}
                        >
                          {column.expanded &&
                            <VirtualizedList
                            height={vhToPx(40)}
                            itemHeight={100}
                            items={column.cards.map((card) => ({ ...card, column }))}
                            renderItem={renderNoEditableCard}
                            dataIid={column._id}
                            setList={setUpdatedSort}
                            list={column.cards}
                            group="grupo1"
                            animation={200}
                            easing="cubic-bezier(0.25, 1, 0.5, 1)"
                            chosenClass="sortable-choosen"
                            onStart={(evt) => {
                              setMovedElement({ ...column.cards[evt.oldIndex], initialColumn: column._id, columnId: column._id, oldIndex: evt.oldIndex, newIndex: evt.newIndex });
                            }}
                            onSort={(evt) => {
                              if (!adding && !removing) {
                                sortCard(column, evt.oldIndex, evt.newIndex);
                              }
                            }}
                            onRemove={(evt) => {
                              setRemoving(true);
                              setCardToRemove({
                                column: column._id,
                              });
                            }}
                            onAdd={(evt) => {
                              setAdding(true);
                              setCardToAdd({
                                column: column._id,
                                index: evt.newIndex,
                              });
                            }}
                            onEnd={(evt) => {
                              setMovedElement({});
                            }}
                            movedElement={movedElement.columnId && movedElement.columnId === column._id ? { ...movedElement, initialColumn: movedElement.initialColumn === column._id } : null}
                            onChange={(evt) => {
                              console.log({ ...movedElement, newIndex: evt.newIndex, columnId: column._id });
                              if (evt.newIndex !== undefined) {
                                console.log({ ...movedElement, newIndex: evt.newIndex, columnId: column._id });
                                setMovedElement({ ...movedElement, newIndex: evt.newIndex, columnId: column._id });
                              }
                            }}
                          />
                        }
                        </PipelineList>
                      )))}
                </div>
                )}
                {/* {board?.columns.length !== 0 && (
                    <div className="scrollbar-container bottom-scrollbar" onScroll={handleBottomScroll}>
                      Este div actuará como un scrollbar inferior falso
                      <div className="fake-scrollbar"></div>
                    </div>
                  )} */}
              </div>
            </div>
            {board?.columns.length !== 0 && (
              <div className="scrollbar-container bottom-scrollbar" onScroll={handleBottomScroll}>
                {/* Este div actuará como un scrollbar inferior falso */}
                <div className="fake-scrollbar"></div>
              </div>
            )}
          </div>
        )}

      {dealToUpdateTags && (
        <ManageTagsModal
          category="pipeline_manager"
          currentTags={cardToEdit.tags}
          showModal={showEditCardModal}
          tags = {tags}
          handleCloseModal={() => setShowEditCardModal(false)}
          tags={tags}
          onSubmit={(selectedTags) => {
            setShowEditCardModal(false);
            updateDealTags(dealToUpdateTags._id, selectedTags);
          }}
        />
      )}

      {showCreateDealSection && (
        <SidePanel
          onClose={() => {
            setShowCreateDealSection(false);
            setColumnToEdit({});
          }}
          title={t("add_startup")}
          width="1/4"
        >
          <AddNewStartupForm onSubmit={(data) => addDeal(data)} />
        </SidePanel>
      )}
      {showNewCardModal && (
        <NewCardModal
          showModal={showNewCardModal}
          onSubmit={confirmCreateCard}
          handleCloseModal={() => {
            setShowNewCardModal(false);
            setColumnToEdit({});
          }}
          showCreateDealSection={() => {
            setShowNewCardModal(false);
            setShowCreateDealSection(true);
          }}
          dealflow={dealflow}
        />
      )}

      {showSearchStartupSection && (
        <SearchStartupSection
          onClose={() => setShowSearchStartupSection(false)}
          onSubmit={(value) => {
            setStartupToSearch(value);
            setShowSearchStartupSection(false);
          }}
          dealflow={dealflowInBoard}
          initialValues={startupToSearch}
        />
      )}
      {showStageSection && (
        <StageSection
          judges={judges.map((j) => ({ id: j?._id, value: j.judge, event: j.event }))}
          evaluationProcesses={evaluationProcesses}
          forms={forms}
          onClose={() => {
            setShowStageSection(false);
            setColumnToEdit({});
          }}
          onSubmit={isEmpty(columnToEdit) ? confirmCreateColumn : confirmUpdateColumn}
          initialValues={columnToEdit}
        />
      )}

      {!intialLoading && (loadingRetrievingBoard || loading || loadingJudges) && <Loader />}
      {intialLoading && (<div className="flex gap-4 h-[60vh] overflow-x-auto mt-20"><SkeletonList style={"h-full w-96"} length={5}/></div>)}
      {board && board.columns.length === 0 && (
        <div className="my-10">
          <img
            src={`${Config.GUIDE_STEPS_BACKGROUND_IMAGES_BUCKET}pipeline_manager_details_${userLanguage}.png`}
            className="w-auto"
          />
        </div>
      )}
    </>
  );
};

export default Board;
