import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { RoutesLinks } from "../../../routes-links";
import { BannerResponsive } from "../../../ui/atoms/BannerResponsive";
import { FormTitle } from "../../../ui/atoms/FormTitle";
import { BottomText } from "../../../ui/atoms/BottomText";
import Tick from "styles/images/tick_blue.svg";
import moment from "moment";
import Config from "config";
import { useSelector } from "react-redux";

import { PoweredText } from "components/ui/atoms/PoweredText";
import { ButtonMain } from "components/ui/atoms/ButtonMain";

const ResendLinkForm = ({ onSubmit, error, emailSent }) => {
  const { t } = useTranslation();
  const platform = useSelector((state) => state.app.platform);

  return (
    <section className="flex flex-col justify-center items-center | min-h-screen w-full  | pt-20 sm:pt-0 lg:p-16">
      <div className="max-w-xs mx-4 sm:max-w-lg 2xl:max-w-lg">
        <BannerResponsive />
        <div className="flex flex-col">
          <>
            <FormTitle formTitle={t("recover_password")} />
            <div className="text-black text-base">
              <p className="pb-2">
                {!error ? t("recover_password_code_sent") : t("recover_password_code_failed")}
              </p>
            </div>

            <div className="w-full flex flex-col justify-center my-6 sm:my-14">
              <ButtonMain onClick={onSubmit} text={t("resend")} />
              {emailSent && (
                <div className="text-gray flex text-sm w-full justify-center mt-2">
                  <img src={Tick} alt="Tick icon" className="w-3 mr-2" />
                  {t("msg_resend_successfully")}
                </div>
              )}
            </div>

            <div className="w-full text-center mt-4">
              <Link to={`${RoutesLinks.LOGIN_LINK}`} className="underline">
                {t("go_to_login")}
              </Link>
            </div>
          </>
        </div>
      </div>
      <BottomText
        text={
          <Trans
            i18nKey="copyright"
            values={{ year: moment().format("YYYY"), platform_name: platform.name }}
          />
        }
      />
      {platform && (platform.cobranding || platform.white_label) && <PoweredText />}
    </section>
  );
};

export default ResendLinkForm;
