import { TeammateCard } from "components/ui/molecules/TeammateCard";
import { useEffect, useImperativeHandle, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useSwal } from "hooks/useSwal";
import { useTranslation } from "react-i18next";
import TeammateSection from "./TeammateSection";
import useUser from "hooks/useUser";
import { Loader } from "components/ui/molecules/Loader";
import TeamService from "services/team-service";
import { useOutletContext } from "react-router-dom";

export const CompanyTeam = () => {
  const [teammates, setTeammates] = useState([]);
  const [teammate, setTeammate] = useState(null);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const { confirm, alert } = useSwal();
  const [showTeammateSection, setShowTeammateSection] = useState(false);

  const { user } = useUser();

  const { ref, investor } = useOutletContext();

  const isChildren = user.investor._id !== investor._id;

  useImperativeHandle(ref, () => ({
    showTeamMateSection: () => handleShowTeamMateSection(),
  }));

  const handleShowTeamMateSection = () => {
    if (user.investor.seats > teammates.length) {
      setShowTeammateSection(true);
    } else {
      alert({
        title: t("limit_of_seats_reached"),
        text: t("limit_of_seats_reached_explanation"),
      });
    }
  };

  const getTeammates = async() => {
    try {
      setLoading(true);
      const result = await TeamService.getTeammates({ investor: isChildren ? investor._id : undefined });
      setTeammates(result.data.data);
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t("error_occurred"),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const saveTeammate = async(values) => {
    confirm({
      icon: "info",
    }).then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          if (!teammate) {
            await TeamService.createTeammate({ ...values, investor: isChildren ? investor._id : undefined });
            addToast(t("teammate_created"), {
              appearance: "success",
              autoDismiss: true,
            });
          } else {
            await TeamService.updateTeammate(
              teammate._id,
              values.name,
              values.last_name,
              values.email,
              values.language,
            );
            addToast(t("teammate_updated"), {
              appearance: "success",
              autoDismiss: true,
            });
          }
          setShowTeammateSection(false);
          setTeammate(null);
          getTeammates();
        } catch (error) {
          addToast(
            error.response
              ? error.response.data.msg_key
                ? t(error.response.data.msg_key)
                : t(
                  teammate
                    ? "error_occurred_updating_teammate"
                    : "error_occurred_creating_teammate",
                )
              : t(
                teammate
                  ? "error_occurred_updating_teammate"
                  : "error_occurred_creating_teammate",
              ),
            {
              appearance: "error",
              autoDismiss: true,
            },
          );
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const removeTeammate = (teammate) => {
    confirm({
      text: t("revert_notification"),
      icon: "warning",
    }).then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await TeamService.removeTeammate(teammate._id, isChildren ? investor._id : null);
          addToast(t("teammate_deleted_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
          setTeammate(null);
          setShowTeammateSection(false);
          getTeammates();
        } catch (error) {
          addToast(
            error.response && error.response.data.msg_key
              ? t(error.response.data.msg_key)
              : t("error_occurred_deleting_teammate"),
            {
              appearance: "error",
              autoDismiss: true,
            },
          );
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const sendActivationCode = (teammate) => {
    confirm({
      icon: "info",
    }).then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await TeamService.sendActivationCode(teammate._id, isChildren ? investor._id : undefined);
          addToast(t("activation_code_sent_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
          setTeammate(null);
          setShowTeammateSection(false);
          getTeammates();
        } catch (error) {
          addToast(
            error.response && error.response.data.msg_key
              ? t(error.response.data.msg_key)
              : t("error_occurred_sending_activation_code"),
            {
              appearance: "error",
              autoDismiss: true,
            },
          );
        } finally {
          setLoading(false);
        }
      }
    });
  };

  useEffect(() => {
    getTeammates();
  }, []);

  const editTeammate = (teammate) => {
    setTeammate(teammate);
    setShowTeammateSection(true);
  };

  return (
    <>
      {loading && <Loader />}
      {showTeammateSection && (
        <TeammateSection
          onClose={() => {
            setShowTeammateSection(false);
            setTeammate(null);
          }}
          teammate={teammate}
          onSubmit={saveTeammate}
          removeTeammate={removeTeammate}
          sendActivationCode={sendActivationCode}
        />
      )}
      <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-4 my-10">
        {teammates.map((teammate, index) => <TeammateCard key={index} teammate={teammate} editTeammate={editTeammate} />)}
      </div>
    </>
  );
};
