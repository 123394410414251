import { Switch } from "components/ui/atoms/Switch";

export const QuestionTypeSwitch = ({ currentQuestion, onChange, value }) => {
  return (
    <Switch
      key={currentQuestion.question_id._id}
      size="lg"
      onChange={(v) => onChange(v)}
      checked={!!value}
    />
  );
};
