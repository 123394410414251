import { useTranslation } from "react-i18next";
import { Card } from "components/ui/atoms/Card";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { FaEye } from "react-icons/fa";
import Config from "config";
import { useState } from "react";
import { Modal } from "components/ui/molecules/Modal";

const Resource = ({ resource, filters, setFilters }) => {
  const { t } = useTranslation();
  const [showVideo, setShowVideo] = useState(false);

  return (
    <>
      {showVideo && (
        <Modal
          showModal={!!showVideo}
          showCloseModal={true}
          onClose={() => setShowVideo(null)}
          fixedWidth={"w-1/2"}
        >
          <div className="py-10">
            <video width="auto" height="auto" controls autoPlay>
              <source src={resource.url} type="video/mp4" />
              <source src={resource.url} type="video/ogg" />
              Your browser does not support the video tag.
            </video>
          </div>
        </Modal>
      )}
      <Card>
        <div className="flex py-4">
          <div
            className="w-52 h-44 bg-cover bg-center rounded-lg"
            style={{ backgroundImage: `url(${resource.img})` }}
          />
          <div className="flex flex-col w-full pl-6">
            <div className="flex items-center">
              <h6 className="font-space font-bold text-secondary text-xl">{resource.title}</h6>
              <span
                onClick={() => {
                  if (filters.topic === resource.topic) {
                    if (filters.type) {
                      setFilters({ type: filters.type });
                    } else {
                      setFilters({});
                    }
                  } else {
                    setFilters({ ...filters, topic: resource.topic });
                  }
                }}
                className={
                  "cursor-pointer ml-2 border border-border-buttons-secondary bg-bg-buttons-secondary font-semibold p-1 rounded-lg w-auto text-xs"
                }
              >
                {t(`resource_topic_${resource.topic}`)}
              </span>
              <span
                onClick={() => {
                  if (filters.type === resource.type) {
                    if (filters.topic) {
                      setFilters({ topic: filters.topic });
                    } else {
                      setFilters({});
                    }
                  } else {
                    setFilters({ ...filters, type: resource.type });
                  }
                }}
                className={
                  "cursor-pointer ml-2 border border-border-buttons-secondary bg-bg-buttons-secondary font-semibold p-1 rounded-lg w-auto text-xs"
                }
              >
                {t(`resource_type_${resource.type}`)}
              </span>
            </div>
            <p className="font-poppins text-secondary-secondary60P text-sm h-20 pt-2">
              {resource.description}
            </p>
            <div className="flex justify-between items-end flex-wrap lg:pt-3">
              <div className="flex justify-start items-end">
                <ButtonMain
                  text={t("see")}
                  iconComponent={<FaEye className="inline-block mr-2" />}
                  onClick={() =>
                    resource.type === "video"
                      ? setShowVideo(true)
                      : window.open(resource.url, "_blank")
                  }
                />
              </div>
              <div className="flex justify-end items-center pt-2"></div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default Resource;
