import { Trans } from "react-i18next";
import moment from "moment";
import { useSelector } from "react-redux";
import { PoweredText } from "components/ui/atoms/PoweredText";

export const SharedEventFooter = () => {
  const platform = useSelector((state) => state.app.platform);

  return (
    <footer className="w-full h-16 flex justify-between items-center border-t px-8 border-gray-lines bg-white">
      <div>
        <span className="text-gray text-xxs">
          <Trans
            i18nKey="copyright"
            values={{ year: moment().format("YYYY"), platform_name: platform.name }}
          />
        </span>
      </div>
      {platform && (platform.cobranding || platform.white_label) && (
        <div>
          <PoweredText />
        </div>
      )}
      <div>
        <img width="100px" src={platform.logo_shared_pages} alt={platform.name} />
      </div>
    </footer>
  );
};
