import axios from "axios/index";
import Config from "../config";

const SupportService = {
  contactApplicationKmZero: async(data) =>
    axios.post(`${Config.SUPPORT_API_URL}contact-application-kmzero`, data),

  commentApplicationKmZero: async(data) =>
    axios.post(`${Config.SUPPORT_API_URL}comment-application-kmzero`, data),
};
export default SupportService;
