import axios from "axios/index";
import Config from "../config";

const NotesService = {
  createNote: (data) => axios.post(`${Config.NOTES_API_URL}`, data),

  getNote: async(noteId) => axios.get(`${Config.NOTES_API_URL}${noteId}`),

  updateNote: async(noteId, data) => axios.patch(`${Config.NOTES_API_URL}${noteId}`, data),

  deleteNote: async(noteId) => axios.delete(`${Config.NOTES_API_URL}${noteId}`),

  getNotes: (filters) => {
    let query = "";

    Object.keys(filters).forEach((key) => {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}${key}=${filters[key]}`;
    });
    return axios.get(`${Config.NOTES_API_URL}${query}`);
  },
};
export default NotesService;
