import { RemindersActionsTypes } from "./reminders-actions-types";

const RemindersActions = {
  setReminders: (reminders) => ({
    type: RemindersActionsTypes.SET_REMINDERS,
    payload: { reminders },
  }),

  setTotalReminders: (total) => ({
    type: RemindersActionsTypes.SET_TOTAL_REMINDERS,
    payload: { total },
  }),
};

export default RemindersActions;
