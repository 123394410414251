import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { RoutesLinks } from "components/routes-links";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { useEffect, useState } from "react";
import { Arrow, useLayer } from "react-laag";
import { FaBell, FaUser } from "react-icons/fa";
import Utils from "utils/utils";
import moment from "moment";
import useUser from "hooks/useUser";
import LogoDefault from "styles/images/logo_default.png";

export function RemindersMenu() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);

  const { user } = useUser();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const handleRedirect = (url) => {
    navigate(url);
  };

  const reminders = useSelector((state) => state.reminders.reminders);
  const totalReminders = useSelector((state) => state.reminders.total);

  const getReminders = () => {
    if (!user.lp) {
      if (totalReminders) {
        return [
          ...reminders,
          {
            title:
              totalReminders - reminders.length
                ? `${t("go_to_reminders")} (+${totalReminders - reminders.length})`
                : `${t("go_to_reminders")}`,
            icon: <FaBell />,
            url: RoutesLinks.REMINDERS_LINK,
          },
        ];
      } else {
        return [
          { title: t("reminders_not_found_today") },
          {
            title: t("go_to_reminders"),
            icon: <FaBell />,
            url: RoutesLinks.REMINDERS_LINK,
          },
        ];
      }
    } else {
      return [
        {
          title: t("forbidden_access"),
        },
      ];
    }
  };

  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,
    onOutsideClick: () => setOpen(false),
    onDisappear: () => setOpen(false),
    overflowContainer: true,
    auto: true,
    placement: "bottom-center",
    triggerOffset: 12,
    containerOffset: 16,
    arrowOffset: 16,
  });

  return (
    <div>
      <button
        id={"reminders-button"}
        {...triggerProps}
        onClick={() => setOpen(!isOpen)}
        style={{ border: "0px" }} className="inline ml-2 bg-gray-100 hover:bg-gray-200 border focus:outline-none  hover:shadow-inner w-8 h-8 rounded-full ml-2"
      >
        <FontAwesomeIcon className={"text-text-buttons-secondary w-3"} icon="bell" />
        {totalReminders > 0 && (
          <span className="inline-block w-1.5 h-1.5 bg-red -mr-1.5 -mb-0.5 rounded-full" />
        )}
      </button>

      {isOpen &&
        renderLayer(
          <ul
            {...layerProps}
            className={
              "mt-2 px-2 py-2 z-30 | shadow-hover border bg-white rounded-2xl border-border-buttons-secondary"
            }
          >
            {getReminders().map((i, index) => (
              <li
                key={index}
                onClick={!i.reminder ? () => handleRedirect(i.url) : null}
                className={`px-2 py-2 flex text-main items-center ${!i.reminder && "cursor-pointer"} ${i.reminder && "border-b border-gray-lines"} text-sm text-gray`}
              >
                {!i.reminder && (
                  <>
                    <span className={"mr-2 text-main"}>{i.icon}</span>
                    <span className={"hover:font-bold"}>{t(i.title)}</span>
                  </>
                )}
                {i.reminder && (
                  <>
                    {i.reminder_type && (
                      <span className={"mr-2 text-main"}>{Utils.getReminderIcon(i, t)}</span>
                    )}
                    <div className="flex flex-col">
                      {i.deal && (
                        <div>
                          <img
                            src={i.deal.logo || LogoDefault}
                            alt={i.deal.name}
                            className="h-10 w-14 object-contain"
                          />
                          <span className="text-sm">{i.deal.name}</span>
                        </div>
                      )}
                      {i.member && (
                        <div className="flex">
                          <FaUser className="mr-2" />
                          <span className="text-sm">{i.member.name}</span>
                        </div>
                      )}
                      {i.lp && (
                        <div className="flex">
                          <FaUser className="mr-2" />
                          <span className="text-sm">{i.lp.name}</span>
                        </div>
                      )}
                      <div>
                        <span className="text-xs">{`${t(i.reminder)} (${moment(i.date).format("HH:MM")})`}</span>
                      </div>
                    </div>
                  </>
                )}
              </li>
            ))}
            <Arrow {...arrowProps} borderColor="#61D8BD" borderWidth={1} className={"w-5"} />
          </ul>,
        )}
    </div>
  );
}
