/** Función para calcular los filtros que son usados
 * @param filters Filtros a contar
 * @param excludedKeys Parametros que no se cuentan
 */
export const countAppliedFilters = (filters, excludedKeys = []) => {
  let count = 0;

  for (const key in filters) {
    if (!excludedKeys.includes(key)) {
      const value = filters[key];
      // Exclude empty arrays or strings
      if (Array.isArray(value) && value.length > 0) {
        count++;
      } else if (typeof value === "string" && value !== "") {
        count++;
      }
    }
  }

  return count;
};
