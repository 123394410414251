import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Loader } from "../ui/molecules/Loader";
import { SectionHeader } from "../ui/molecules/SectionHeader";
import { useToasts } from "react-toast-notifications";
import { SharedEventFooter } from "components/events/components/event/components/SharedEventFooter";

import { PageTitle } from "components/ui/atoms/PageTitle";
import UnauthorizedKmZeroReport from "components/ui/objects/UnauthorizedKmZeroReport";
import FormsService from "services/forms-service";
import { usePagination } from "hooks/usePagination";
import { FormAnswers } from "components/qe-forms/components/forms-form/FormAnswers";
import Board from "components/pipeline-manager/components/board";

const EMAILS = {
  "cestrada@kiota.com": "Cristian Estrada",
  "orfeo@firstdropvc.com": "Orfeo Balboa",
  "m.nieto@firstdropvc.com": "Manu Nieto",
  "hwdj@ziggo.nl": "HWDJZ",
};

export const FirstDropReport = () => {
  const wrapperElement = useRef(null);
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState("pipeline_manager");
  const [sections, setSections] = useState(["pipeline_manager", "startups"]);
  const [authorized, setAuthorized] = useState(false);
  const [email, setEmail] = useState(localStorage.getItem("X-FIRSTDROP-REP-EMAIL"));
  const [totalStartups, setTotalStartups] = useState(0);
  const [formAnswers, setFormAnswers] = useState([]);
  const [questionnaire, setQuestionnaire] = useState(null);

  const { setTotalPages, totalPages, setPage, page, rowsPerPage, setRowsPerPage } =
    usePagination(12);

  useEffect(() => {
    const checkAccess = async() => {
      if (EMAILS[email]) {
        setAuthorized(true);
      } else {
        addToast(t("forbidden_access_to_report"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    };

    if (email) {
      localStorage.setItem("X-FIRSTDROP-REP-EMAIL", email);
      checkAccess();
    }
  }, [email]);

  useEffect(() => {
    if (wrapperElement.current) {
      wrapperElement.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, []);

  const getForm = async() => {
    setLoading(true);
    return FormsService.getQuestionnairesForm("6343e0f98430842f0a3487f3", true)
      .then((response) => {
        setQuestionnaire(response.data.data);
      })
      .catch(() => {
        addToast(t("error_retrieving_form"), {
          appearance: "error",
          autoDismiss: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getQuestionnaireAnswers = () => {
    setLoading(true);
    FormsService.getQuestionnaireAnswers(
      "6343e0f98430842f0a3487f3",
      {
        rowsPerPage,
        page,
        sortBy: "-createdAt",
      },
      true,
    )
      .then((result) => {
        const { applications, pagination } = result.data;
        setTotalStartups(pagination.totalDocs);
        setFormAnswers(applications);
        setTotalPages(pagination.pages);
      })
      .catch(() => {
        addToast(t("error_retrieving_startups"), {
          appearance: "error",
          autoDismiss: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (authorized) {
      getForm();
      getQuestionnaireAnswers();
    }
  }, [authorized]);

  useEffect(() => {
    if (questionnaire) {
      getQuestionnaireAnswers();
    }
  }, [questionnaire, page, rowsPerPage]);

  if (!authorized) {
    return (
      <>
        {loading && <Loader />}
        <UnauthorizedKmZeroReport
          loading={loading}
          onRequestAccess={setEmail}
          description={t("access_control_description_report")}
          buttonText={t("access_report")}
        />
      </>
    );
  }

  return (
    <div className="flex flex-col min-h-screen" ref={wrapperElement}>
      <section className="px-8 my-8 flex-1">
        <PageTitle title={"Reporte Segunda Convocatoria KM ZERO"} />
        <div className="flex items-start">
          <div className="flex-1 mb-6">
            <SectionHeader
              sectionTitles={sections.map((section) => t(section))}
              sectionKeys={sections}
              section={section}
              setSection={setSection}
            />
          </div>
        </div>

        <div className={"text-2xl font-semibold text-secondary"}>
          {t("total_startups_applied_currently", { startups: totalStartups })}
        </div>
        {section === "startups" && (
          <FormAnswers
            questions={questionnaire.questions}
            answers={formAnswers}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            totalPages={totalPages}
            setRowsPerPage={setRowsPerPage}
            shared={true}
            firstDrop={true}
          />
        )}
        {section === "pipeline_manager" && (
          <Board identifier={"6343e1bd8430842f0a348806"} editable={false} />
        )}
      </section>
      <SharedEventFooter />
      {loading && <Loader />}
    </div>
  );
};
