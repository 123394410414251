import Config from "config";

export function FormTitle(props) {
  return (
    <div className="block mb-4 sm:mb-12 sm:px-0 mt-20 lg:mt-0">
      <h1 className={`text-main`}>{props.formTitle}</h1>
      <h5 className={`text-secondary font-semibold`}>{props.formSubtitle}</h5>
    </div>
  );
}
