import { applyMiddleware, combineReducers, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "localforage";
import UserReducer from "./user";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import AppReducer, { AppOperations } from "./app";

import NetworkService from "../network-service.js";
import UserOperations from "./user/user-operations";
import ChecksReducer from "./checks";
import RemindersReducer from "./reminders";
import OnboardingReducer from "./onboarding";
import PlatformService from "services/platform-service";
import ContextReducer from "./context";
import ThemeReducer from "./theme";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "auth", "login_entries"],
};

const userPersistConfig = {
  key: "user",
  storage,
  whitelist: ["name", "lastName", "email", "dni", "group"],
};

const remindersPersistConfig = {
  key: "reminders",
  storage,
  whitelist: ["reminders", "total"],
};

const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["token", "api_key"],
};

const loginEntriesPersistConfig = {
  key: "login_entries",
  storage,
  whitelist: ["data"],
};

const checksPersistConfig = {
  key: "checks",
  storage,
  whitelist: ["checked", "stage"],
};

const contextPersistConfig = {
  key: "context",
  storage,
  whitelist: ["deal"],
};

const themePersistConfig = {
  key: "theme",
  storage,
  whitelist: ["sidebar_expanded"],
};

const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, UserReducer.user),
  auth: persistReducer(authPersistConfig, UserReducer.auth),
  loginEntries: persistReducer(loginEntriesPersistConfig, UserReducer.loginEntries),
  checks: persistReducer(checksPersistConfig, ChecksReducer),
  onboarding: OnboardingReducer,
  reminders: persistReducer(remindersPersistConfig, RemindersReducer),
  context: persistReducer(contextPersistConfig, ContextReducer),
  theme: persistReducer(themePersistConfig, ThemeReducer),
  app: AppReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = composeWithDevTools({
  // trace: true,
  // traceLimit: 25,
  actionsBlacklist: [],
});

const middlewares = [thunk];
const store = createStore(persistedReducer, {}, composeEnhancers(applyMiddleware(...middlewares)));

const persistor = persistStore(store, {}, async() => {
  NetworkService.setupInterceptors(store);
  const response = await PlatformService.getPlatform();
  store.dispatch(AppOperations.setPlatformInformation(response.data.data));
  await store.dispatch(UserOperations.checkUserExist());
  store.dispatch(AppOperations.setLoading(false));
});

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default { store, persistor };
