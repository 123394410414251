import { get, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import FormsService from "../../../../services/forms-service";
import { Loader } from "../../../ui/molecules/Loader";
import { RoutesLinks } from "../../../routes-links";
import Plus from "../../../../styles/images/plus.svg";
import AuthUtils from "utils/auth-utils";
import { useNavigate, useParams } from "react-router-dom";
import { Input } from "components/ui/atoms/Input";
import { useSwal } from "hooks/useSwal";
import Spanish from "styles/images/spanish.svg";
import English from "styles/images/english.jpg";
import {
  FaFile,
  FaFileExcel,
  FaFilePdf,
  FaImage,
  FaInfoCircle,
  FaMusic,
  FaSave,
  FaTrash,
  FaVideo,
  FaPencilAlt,
  FaTimes,
  FaPlus,
} from "react-icons/fa";
import {
  MdOutlineAlignHorizontalRight,
  MdOutlineAlignHorizontalLeft,
  MdOutlineAlignVerticalBottom,
  MdOutlineAlignVerticalTop,
  MdOutlineAlignHorizontalCenter,
} from "react-icons/md";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import { QuestionsUtils } from "utils/questions-utils";
import { Switch } from "components/ui/atoms/Switch";
import { PageTitle } from "components/ui/atoms/PageTitle";
import ReactTooltip from "react-tooltip";
import useUser from "../../../../hooks/useUser";
import InputFile from "../../../ui/atoms/input-files";
import FormUtils from "../../../../utils/form-utils";
import { RichEditor } from "components/ui/objects/RichEditor";
import Select from "components/ui/atoms/Select";
import TranslateUtils from "utils/translate-utils";
import SupportService from "services/support-service";
import Config from "config";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { ButtonDanger } from "components/ui/atoms/ButtonDanger";
import { MiniHelp } from "components/ui/atoms/MiniHelp";
import DocumentsService from "services/documents-service";

const QuestionsForm = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { questionId } = useParams();
  const { confirm, alert } = useSwal();
const navigate = useNavigate();
  const { user, investorId } = useUser();
  const [loadingQuestion, setLoadingQuestion] = useState(!!questionId);
  const [loading, setLoading] = useState(false);
  const [question, setQuestion] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [questionOptions, setQuestionOptions] = useState([]);
  const [groupQuestions, setGroupQuestions] = useState([]);
  const [type, setType] = useState(null);
  const [fileTypes, setFileTypes] = useState([]);
  const [likertScaleOptions, setLikertScaleOptions] = useState(null);
  const [multipleSelectionEnabled, setMultipleSelectionEnabled] = useState(false);
  const [title, setTitle] = useState({});
  const [tag, setTag] = useState("");
  const [maxLength, setMaxLength] = useState(null);
  const [variable, setVariable] = useState("");
  const [percentage, setPercentage] = useState("");
  const [description, setDescription] = useState({});
  const [separatorImage, setSeparatorImage] = useState("");
  const [separatorImageOpacity, setSeparatorImageOpacity] = useState("opacity-100");
  const [selectedSeparatorAlignment, setSelectedSeparatorAlignment] = useState("left");
  const [selectedSeparatorImageAlignment, setSelectedSeparatorImageAlignment] =
    useState("items-center");
  const [currentQuestions, setCurrentQuestions] = useState([]);
  const [questionImageOptions, setQuestionImageOptions] = useState([]);

  const [imageSelectorOptionsToUpdate, setImageSelectorOptionsToUpdate] = useState([]);

  const QUESTION_TYPES = [
    {
      id: "short_answer",
      value: t("short_answer"),
    },
    {
      id: "long_answer",
      value: t("long_answer"),
    },
    {
      id: "boolean",
      value: t("boolean"),
    },
    {
      id: "select",
      value: t("select"),
    },
    {
      id: "number",
      value: t("number"),
    },
    {
      id: "multiselect",
      value: t("multiselect"),
    },
    {
      id: "separator",
      value: t("separator"),
    },
    {
      id: "questions_group",
      value: t("questions_group"),
    },
    {
      id: "date",
      value: t("date"),
    },
    {
      id: "url",
      value: t("url"),
    },
    {
      id: "phone",
      value: t("phone"),
    },
    {
      id: "likert_scale",
      value: t("likert_scale"),
    },
    {
      id: "email",
      value: t("email"),
    },
    {
      id: "file",
      value: t("file"),
    },
    {
      id: "image_selector",
      value: t("image_selector"),
    },
    {
      id: "autocomplete",
      value: t("autocomplete"),
    },
    {
      id: "financial",
      value: t("financial_question"),
    },
  ];
  const FILE_TYPES = [
    "file_image",
    "file_pdf",
    "file_excel",
    "file_doc",
    "file_audio",
    "file_video",
  ];

  const deleteQuestion = () => {
    confirm({
      text: t("delete_question_warning"),
      icon: "warning",
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          await FormsService.deleteQuestionnairesQuestion(questionId);
          addToast(t("question_deleted_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });

          navigate(RoutesLinks.QE_QUESTIONS_LINK);
        } catch (error) {
          addToast(
            error.response
              ? error.response.data.msg_key
                ? t(error.response.data.msg_key)
                : t("error_occurred")
              : t("error_occurred"),
            {
              appearance: "error",
              autoDismiss: true,
            },
          );
        }
      }
    });
  };

  const validOptions = (questionOptions) => {
    let emptyOption = questionOptions.some((option) => isEmpty(option));

    if (emptyOption) {
      return false;
    }

    questionOptions.every((option) => {
      if (!option.en && !option.es) {
        emptyOption = true;
        return false;
      }
      return true;
    });

    return !emptyOption;
  };

  const handleSubmit = async () => {
    if (type === "file" && fileTypes.length === 0) {
      alert({ text: t("file_type_is_required") });
    } else if (isEmpty(title)) {
      alert({ text: t("question_title_is_required") });
    } else if (type === "likert_scale" && !likertScaleOptions) {
      alert({ text: t("likert_scale_options_required") });
    } else if (type === "image_selector" && !questionImageOptions.filter((opt) => opt).length) {
      alert({ text: t("image_selector_options_required") });
    } else if (
      ["select", "multiselect", "likert_scale", "autocomplete"].includes(type) &&
      !validOptions(questionOptions)
    ) {
      alert({ text: t("question_options_required_for_this_type") });
    } else if (type === "questions_group" && groupQuestions.length === 0) {
      alert({ text: t("questions_group_questions_required") });
    } else {
      confirm({
        text: questionId ? t("update_question_warning") : null,
        icon: "warning",
      }).then(async (isConfirmed) => {
        if (isConfirmed) {
          let separatorOptions = {};

          if (type === "separator") {
            separatorOptions = {
              image: separatorImage,
              elementsAlignment: selectedSeparatorAlignment || "",
              imageOpacity: separatorImageOpacity ? separatorImageOpacity[0] : "",
              imagePosition: selectedSeparatorImageAlignment
                ? selectedSeparatorImageAlignment[0]
                : "",
            };
          }

          let options = [];

          if (type && ["select", "multiselect", "likert_scale", "autocomplete"].includes(type)) {
            options = [...questionOptions];
          } else if (type && ["image_selector"].includes(type)) {
            options = questionImageOptions.filter((opt) => opt);
          }

          const data = {
            title,
            description,
            type,
            tag,
            max_length: maxLength,
            separatorOptions,
            public: false,
            variable,
            percentage,
            options,
            multiple_selection_enabled:
              type && type === "image_selector" ? multipleSelectionEnabled : null,
            questionsGroup:
              type && type === "questions_group"
                ? Array.from(new Set(groupQuestions)).filter((q) => q)
                : [],
            file_accepted_extensions:
              type === "file"
                ? fileTypes.map((type) => getFileTypeExtensions(type)).join(",")
                : null,
          };

          if (questionId) {
            try {
              await FormsService.updateQuestionnairesQuestion(questionId, {
                ...data,
              });

              addToast(t("question_successfully_updated"), {
                appearance: "success",
                autoDismiss: true,
              });

              // navigate(`${RoutesLinks.QE_QUESTIONS_LINK}`)
            } catch (error) {
              addToast(t("error_updating_question"), {
                appearance: "error",
                autoDismiss: true,
              });
            }
          } else {
            try {
              await FormsService.createQuestionnairesQuestion({
                ...data,
              });

              addToast(t("question_successfully_created"), {
                appearance: "success",
                autoDismiss: true,
              });
              navigate(`${RoutesLinks.QE_QUESTIONS_LINK}`);
            } catch (error) {
              addToast(t("error_creating_question"), {
                appearance: "error",
                autoDismiss: true,
              });
            }
          }
        }
      });
    }
  };

  const getQuestion = async () => {
    try {
      setLoadingQuestion(true);
      const response = await FormsService.getQuestionnairesQuestion(questionId);

      const q = response.data.data;
      setTitle(q.title);
      setDescription(q.description);
      setType(q.type);
      setTag(q.tag);
      setMaxLength(q.max_length);
      setVariable(q.variable || "");
      setPercentage(q.percentage);
      setMultipleSelectionEnabled(!!q.multiple_selection_enabled);
      setSelectedLanguage(get(user, "preferences.language.code"), "en");
      setQuestion(q);
      if (q.type === "image_selector") {
        setQuestionImageOptions(q.options ? q.options : []);
      } else if (["select", "multiselect", "likert_scale", "autocomplete"].includes(q.type)) {
        setQuestionOptions(q.options ? q.options : []);
      }
      setGroupQuestions(q.questionsGroup || []);

      if (q.type === "separator") {
        setSeparatorImage(q.separatorOptions?.image);
        setSelectedSeparatorAlignment(q.separatorOptions?.elementsAlignment);
        setSeparatorImageOpacity(q.separatorOptions?.imageOpacity);
        setSelectedSeparatorImageAlignment(q.separatorOptions?.imagePosition);
      }
    } catch (error) {
      navigate(RoutesLinks.QE_QUESTIONS_LINK);
      addToast(t("error_getting_question"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoadingQuestion(false);
    }
  };

  const addQuestionOption = () => {
    setQuestionOptions((questionOptions) => [...questionOptions, {}]);
  };

  const addQuestionImageOption = () => {
    setQuestionImageOptions((questionImageOptions) => [...questionImageOptions, ""]);
  };

  const addGroupQuestion = () => {
    setGroupQuestions((groupQuestions) => [...groupQuestions, ""]);
  };

  const onSelectFile = async (file) => {
    if (!file) {
      return;
    }

    if ((file?.size || 0) / 1024 / 1024 > 200) {
      addToast(t("error_file_size", { max_size: 200 }), {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append("document", file);

    try {
      setLoading(true);
      const result = await DocumentsService.saveSeparatorImage(formData);
      setSeparatorImage(result.data.data);
    } catch (error) {
      setSeparatorImage(null);
      addToast(t("error_occurred"), { appearance: "error", autoDismiss: true });
    } finally {
      setLoading(false);
    }
  };

  const onSelectImageOptionFile = async (file, index) => {
    if (!file) {
      return;
    }

    if ((file?.size || 0) / 1024 / 1024 > 200) {
      addToast(t("error_file_size", { max_size: 200 }), {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append("document", file);

    try {
      setLoading(true);
      const result = await DocumentsService.saveImageSelectorOption(formData);
      const currentImages = [...questionImageOptions];
      currentImages[index] = result.data.data;
      setQuestionImageOptions(currentImages);
    } catch (error) {
      addToast(t("error_occurred"), { appearance: "error", autoDismiss: true });
    } finally {
      setLoading(false);
      const tmpImageSelectorOptionsToUpdate = [...imageSelectorOptionsToUpdate];
      const indexToDelete = tmpImageSelectorOptionsToUpdate.findIndex(
        (indexOpt) => indexOpt === index,
      );
      tmpImageSelectorOptionsToUpdate.splice(indexToDelete, 1);
      setImageSelectorOptionsToUpdate(tmpImageSelectorOptionsToUpdate);
    }
  };

  const getFileTypeExtensions = (type) => {
    switch (type) {
      case "file_image":
        return "image/*";
      case "file_pdf":
        return ".pdf";
      case "file_excel":
        return ".csv, .xls, .xlsx";
      case "file_doc":
        return ".doc, .docx";
      case "file_audio":
        return ".mp3, .wav";
      case "file_video":
        return ".mp4, .avi";
      default:
        return null;
    }
  };

  const updateFileType = (type) => {
    if (fileTypes.includes(type)) {
      setFileTypes(fileTypes.filter((fileType) => fileType !== type));
    } else {
      setFileTypes([...fileTypes, type]);
    }
  };

  const getQuestions = async () => {
    try {
      const response = await FormsService.getQuestionnairesQuestions();
      setCurrentQuestions(response?.data?.data || []);
    } catch (error) {
      setCurrentQuestions([]);
    }
  };

  const getGroupQuestionInitialValues = (questionId) => {
    const question = currentQuestions.find((q) => q._id === questionId);

    if (question) {
      return [
        {
          id: question._id,
          value: TranslateUtils.getTextByLang(selectedLanguage, question.title),
        },
      ];
    } else {
      return [];
    }
  };

  useEffect(() => {
    getQuestions();
    if (questionId) {
      getQuestion();
    }
  }, [questionId]);

  useEffect(() => {
    if (likertScaleOptions === 3) {
      setQuestionOptions([{}, {}, {}]);
    } else if (likertScaleOptions === 5) {
      setQuestionOptions([{}, {}, {}, {}, {}]);
    } else if (likertScaleOptions === 7) {
      setQuestionOptions([{}, {}, {}, {}, {}, {}, {}]);
    }
  }, [likertScaleOptions]);

  useEffect(() => {
    if (question && question.type === type) {
      setQuestionOptions(question.options ? question.options : []);
      setFileTypes(
        FILE_TYPES.filter((type) =>
          question.file_accepted_extensions?.includes(getFileTypeExtensions(type)),
        ),
      );
    } else if (["select", "multiselect", "autocomplete"].includes(type)) {
      setQuestionOptions([{}]);
    }
    setLikertScaleOptions(null);
  }, [type, question]);

  useEffect(() => {
    ReactTooltip.hide();
    ReactTooltip.rebuild();
  }, [questionOptions, imageSelectorOptionsToUpdate]);

  const separatorImageOpacityOptions = [
    { id: "opacity-100", value: 0 },
    { id: "opacity-75", value: 25 },
    { id: "opacity-50", value: 50 },
    { id: "opacity-25", value: 75 },
    { id: "opacity-0", value: 100 },
  ];

  const separatorImageAlignmentOptions = [
    { id: "items-center", value: "center" },
    { id: "items-start", value: "top" },
    { id: "items-end", value: "bottom" },
  ];

  return (
    <>
      <div className="flex">
        <div className="flex-1">
          <PageTitle
            title={t(questionId ? "update_question" : "new_question")}
            showBack={true}
            onBackClick={() => navigate(`${RoutesLinks.QE_QUESTIONS_LINK}`)}
          />
        </div>
        {!user?.help?.create_question && <MiniHelp item={"create_question"} />}
      </div>

      {!loadingQuestion && (
        <>
          <h5 className={`text-main font-bold border-b border-main mb-4 mt-10`}>
            {t("question_type")}
          </h5>

          <div className="w-full px-1 my-4 text-xs font-semibold">{t("select_question_type")}</div>

          <div className="grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
            {QUESTION_TYPES.map((questionType) => (
              <div
                key={questionType.id}
                onClick={() => setType(questionType.id)}
                className={`${type === questionType.id ? `shadow-inner border-2 border-main` : `border border-border-buttons-secondary`} bg-white flex hover:shadow-inner rounded-lg p-2 cursor-pointer`}
              >
                {QuestionsUtils.getIcon(questionType.id, true)}

                <div className="justify-center w-full px-2 mx-4 text-sm font-semibold">
                  {questionType.value}
                </div>
              </div>
            ))}
          </div>

          <h5 className={`text-main font-bold border-b border-main mb-4 mt-10`}>{t("language")}</h5>

          {!type && (
            <div className="py-6">
              <NoDataInfo
                title={t("cannot_configure_question")}
                textOne={t("question_type_is_required")}
              />
            </div>
          )}

          {type && (
            <>
              <div className="w-full px-1 my-4 text-xs font-semibold">
                {t("question_select_language")}
              </div>

              <div className="flex flex-row">
                <div
                  className={`w-12 p-2 ${selectedLanguage === "es" ? `shadow-inner border-2 border-main` : `border border-border-buttons-secondary`} bg-white hover:shadow-inner mr-2 rounded-lg cursor-pointer`}
                  onClick={() => setSelectedLanguage("es")}
                >
                  <img src={Spanish} alt="Spanish" className="h-full" />
                </div>

                <div
                  className={`${selectedLanguage === "en" ? `shadow-inner border-2 border-main` : `border border-border-buttons-secondary`} bg-white w-12 p-2 hover:shadow-inner mr-2 rounded-lg cursor-pointer`}
                  onClick={() => setSelectedLanguage("en")}
                >
                  <img src={English} alt="English" className="h-full" />
                </div>
              </div>

              <div className="p-4 my-3 text-xs font-semibold shadow-soft-white rounded-2xl bg-transparence-blue">
                <FaInfoCircle className="inline-block w-4 h-4 mr-3" />
                {t("question_language_explanation")}
              </div>
            </>
          )}

          <h5 className={`text-main font-bold border-b border-main mb-4 mt-10`}>{t("question")}</h5>

          {(!type || !selectedLanguage) && (
            <div className="py-6">
              <NoDataInfo
                title={t("cannot_configure_question")}
                textOne={t(!type ? "question_type_is_required" : "question_language_is_required")}
              />
            </div>
          )}

          {type && selectedLanguage && (
            <>
              <div className="flex flex-col justify-center my-4 mt-6 align-center rounded-2xl">
                <div>
                  <Input
                    type="text"
                    id={"title"}
                    name={"title"}
                    label={t("title")}
                    required={true}
                    placeholder={`${t("set_title_for")} ${t(selectedLanguage).toLowerCase()}`}
                    value={title[selectedLanguage] || ""}
                    onChange={(e) => setTitle({ ...title, [selectedLanguage]: e.target.value })}
                  />

                  {type !== "separator" && (
                    <>
                      <RichEditor
                        name="description"
                        label={t("description")}
                        placeholder={`${t("set_description_for")} ${t(selectedLanguage).toLowerCase()} (${t("optional")})`}
                        onChange={(content) =>
                          setDescription({ ...description, [selectedLanguage]: content })
                        }
                        toolbarClassName="description"
                        value={
                          description && description[selectedLanguage]
                            ? description[selectedLanguage] || ""
                            : ""
                        }
                      />
                    </>
                  )}

                  {type === "questions_group" && currentQuestions?.length !== 0 && (
                    <>
                      <div className="w-full px-1 my-2 text-xs font-semibold">
                        {t("configure_options")}
                      </div>
                      <div className="w-full">
                        <div className="py-2">
                          {groupQuestions.map((option, index) => (
                            <div key={index} className="flex w-full">
                              <Select
                                className="w-full"
                                name={`group-question-${index}`}
                                initialValues={getGroupQuestionInitialValues(option)}
                                onSelect={(value) => {
                                  const temp = [...groupQuestions];
                                  temp[index] = value[0].id;

                                  setGroupQuestions(temp);
                                }}
                                isClearable={false}
                                noOptionsText={t("no_questions")}
                                items={
                                  currentQuestions?.length &&
                                  currentQuestions.map((q) => {
                                    return {
                                      value: q.key_question
                                        ? `${TranslateUtils.getTextByLang(selectedLanguage, q.title)} (${t("key_question")})`
                                        : TranslateUtils.getTextByLang(selectedLanguage, q.title),
                                      id: q._id,
                                    };
                                  })
                                }
                              />
                              <div
                                className="flex flex-col items-center justify-start mt-2 ml-2"
                                data-tip={t("delete")}
                              >
                                <ButtonDanger
                                  iconComponent={<FaTrash className="inline" />}
                                  onClick={() => {
                                    confirm({}).then(async (isConfirmed) => {
                                      if (isConfirmed) {
                                        const temp = [...groupQuestions];
                                        temp.splice(index, 1);
                                        setGroupQuestions(temp);
                                      }
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          ))}

                          <ButtonMain
                            text={t("add_question")}
                            iconComponent={<FaPlus className="inline mr-2" />}
                            onClick={addGroupQuestion}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {type === "separator" && (
                    <>
                      <RichEditor
                        className="mb-4"
                        id={"description"}
                        name={"description"}
                        label={t("description")}
                        toolbarClassName="description"
                        placeholder={`${t("set_description_for")} ${t(selectedLanguage).toLowerCase()} (${t("optional")})`}
                        value={(description && description[selectedLanguage]) || ""}
                        onChange={(e) => setDescription({ ...description, [selectedLanguage]: e })}
                        formats={[
                          "bold",
                          "italic",
                          "underline",
                          "strike",
                          "blockquote",
                          "list",
                          "bullet",
                          "link",
                        ]}
                      />
                      {separatorImage ? (
                        <>
                          <div className="my-4">
                            <img
                              src={separatorImage}
                              alt="Banner preview"
                              className="w-[640px] max-w-full rounded-lg"
                            />

                            <ButtonDanger
                              marginTop={4}
                              type="button"
                              iconComponent={<FaTrash className="inline-block mr-2" />}
                              text={t("remove")}
                              onClick={() => setSeparatorImage(null)}
                            />
                          </div>
                        </>
                      ) : (
                        <InputFile
                          setSelectedFile={(file) => onSelectFile(file)}
                          accept={".jpg, .jpeg, .png"}
                        />
                      )}

                      <label className="block mt-4 mb-1 text-xs font-medium text-left">
                        {t("elements_alignment")}
                      </label>
                      <div className="flex flex-row">
                        <div
                          className={`w-12 flex justify-center p-2 ${selectedSeparatorAlignment === "left" ? `shadow-inner border-2 border-main` : "shadow-soft-white"} hover:shadow-inner mr-2 rounded-lg cursor-pointer`}
                          onClick={() => setSelectedSeparatorAlignment("left")}
                        >
                          <MdOutlineAlignHorizontalRight />
                        </div>
                        <div
                          className={`w-12 flex justify-center p-2 ${selectedSeparatorAlignment === "right" ? `shadow-inner border-2 border-main` : "shadow-soft-white"} hover:shadow-inner mr-2 rounded-lg cursor-pointer`}
                          onClick={() => setSelectedSeparatorAlignment("right")}
                        >
                          <MdOutlineAlignHorizontalLeft />
                        </div>
                        <div
                          className={`w-12 flex justify-center p-2 ${selectedSeparatorAlignment === "top" ? `shadow-inner border-2 border-main` : "shadow-soft-white"} hover:shadow-inner mr-2 rounded-lg cursor-pointer`}
                          onClick={() => setSelectedSeparatorAlignment("top")}
                        >
                          <MdOutlineAlignVerticalBottom />
                        </div>
                        <div
                          className={`w-12 flex justify-center p-2 ${selectedSeparatorAlignment === "bottom" ? `shadow-inner border-2 border-main` : "shadow-soft-white"} hover:shadow-inner mr-2 rounded-lg cursor-pointer`}
                          onClick={() => setSelectedSeparatorAlignment("bottom")}
                        >
                          <MdOutlineAlignVerticalTop />
                        </div>
                        <div
                          className={`w-12 flex justify-center p-2 ${selectedSeparatorAlignment === "back" ? `shadow-inner border-2 border-main` : "shadow-soft-white"} hover:shadow-inner mr-2 rounded-lg cursor-pointer`}
                          onClick={() => setSelectedSeparatorAlignment("back")}
                        >
                          <MdOutlineAlignHorizontalCenter />
                        </div>
                      </div>

                      {separatorImage && (
                        <>
                          <Select
                            name="separatorImageOpity"
                            className="mt-4"
                            multiSelect={false}
                            label={t("opacity")}
                            isClearable={false}
                            placeholder={t("select_opacity")}
                            initialValues={
                              separatorImageOpacity
                                ? separatorImageOpacityOptions.filter(
                                    (opt) => opt.id === separatorImageOpacity,
                                  )
                                : []
                            }
                            onSelect={(opts) =>
                              setSeparatorImageOpacity(opts.length ? opts.map((o) => o.id) : [])
                            }
                            items={separatorImageOpacityOptions}
                          />

                          <Select
                            name="separatorImagePosition"
                            className="mt-4"
                            multiSelect={false}
                            label={t("image_position")}
                            isClearable={false}
                            placeholder={t("select_image_position")}
                            initialValues={
                              selectedSeparatorImageAlignment
                                ? separatorImageAlignmentOptions.filter(
                                    (opt) => opt.id === selectedSeparatorImageAlignment,
                                  )
                                : []
                            }
                            onSelect={(opts) =>
                              setSelectedSeparatorImageAlignment(
                                opts.length ? opts.map((o) => o.id) : null,
                              )
                            }
                            items={separatorImageAlignmentOptions}
                          />
                        </>
                      )}

                      <label className="block mt-4 mb-1 text-sm font-bold text-left">
                        {t("preview")}
                      </label>

                      <div
                        className={`relative flex overflow-hidden ${["left", "right"].includes(selectedSeparatorAlignment) ? "flex-row" : ["top", "bottom"].includes(selectedSeparatorAlignment) ? "flex-col" : ""}`}
                      >
                        {selectedSeparatorAlignment === "back" && (
                          <div className="absolute z-0 w-full h-full">
                            <img
                              src={separatorImage}
                              className={`w-full h-full ${separatorImageOpacity}`}
                            />
                          </div>
                        )}
                        <div
                          className="flex-1 p-3"
                          style={{
                            order: ["left", "top"].includes(selectedSeparatorAlignment) ? 1 : 2,
                          }}
                          dangerouslySetInnerHTML={{
                            __html: description ? description[selectedLanguage] || "" : "",
                          }}
                        />
                        {selectedSeparatorAlignment !== "back" && separatorImage && (
                          <div
                            className={`flex-1 p-3 flex ${selectedSeparatorImageAlignment}`}
                            style={{
                              order: ["right", "bottom"].includes(selectedSeparatorAlignment)
                                ? 1
                                : 2,
                            }}
                          >
                            <img
                              src={separatorImage}
                              className={`w-full ${separatorImageOpacity}`}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  )}

                  {["multiselect", "select", "likert_scale", "autocomplete"].includes(type) && (
                    <>
                      {type === "likert_scale" && (
                        <div className="mb-10">
                          <div className="w-full px-1 my-4 text-xs font-semibold">
                            {t("how_many_options_likert_scale")}
                          </div>
                          <div className="grid grid-cols-3 gap-4 my-2">
                            {[3, 5, 7].map((option, index) => (
                              <div
                                key={index}
                                onClick={() => setLikertScaleOptions(option)}
                                className={`${likertScaleOptions === option ? `shadow-inner border-2 border-main` : "shadow-soft-white"} rounded-xl p-2 text-center cursor-pointer hover:shadow-inner`}
                              >
                                {option}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      {(type !== "likert_scale" || likertScaleOptions?.length !== 0) && (
                        <>
                          <div className="w-full px-1 my-2 text-xs font-semibold">
                            {t("configure_options")}
                          </div>
                          <div className="w-full mb-2">
                            <div className="py-2 mt-4">
                              {questionOptions.map((option, index) => (
                                <div
                                  key={index}
                                  className="flex w-full mt-2 mt-4 mb-4 justify-items-center"
                                >
                                  <div className="w-full -mb-5">
                                    <Input
                                      type="text"
                                      id={`option_${index}`}
                                      name={`option_${index}`}
                                      placeholder={`${t("option")} ${index + 1}`}
                                      value={questionOptions[index][selectedLanguage] || ""}
                                      onChange={(e) => {
                                        const temp = [...questionOptions];
                                        temp.splice(index, 1, {
                                          ...temp[index],
                                          [selectedLanguage]: e.target.value,
                                        });
                                        setQuestionOptions(temp);
                                      }}
                                    />
                                  </div>

                                  {type !== "likert_scale" && index !== 0 && (
                                    <div className="flex ml-2" data-tip={t("delete")}>
                                      <ButtonDanger
                                        iconComponent={<FaTrash className="inline" />}
                                        onClick={() => {
                                          confirm({}).then((isConfirmed) => {
                                            if (isConfirmed) {
                                              const temp = [...questionOptions];
                                              temp.splice(index, 1);
                                              setQuestionOptions(temp);
                                            }
                                          });
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              ))}

                              {type !== "likert_scale" && (
                                <ButtonMain
                                  text={t("add_option")}
                                  iconComponent={<FaPlus className="inline mr-2" />}
                                  onClick={addQuestionOption}
                                />
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {["image_selector"].includes(type) && (
                    <>
                      <div className="w-full px-1 mt-6 text-xs font-semibold">
                        {t("allow_this_question_multiple_selection")}
                      </div>
                      <div className="w-full px-1 mt-6 text-xs font-semibold">
                        <Switch
                          checked={multipleSelectionEnabled}
                          onChange={(value) => {
                            setMultipleSelectionEnabled(value);
                          }}
                          text={t(multipleSelectionEnabled ? "yes" : "no")}
                        />
                      </div>
                      <div className="w-full px-1 mt-6 text-xs font-semibold">
                        {t("configure_options")}
                      </div>
                      <div className="w-full mb-2">
                        <div className="grid grid-cols-1 gap-4 py-2 mt-4 md:grid-cols-2 xl:grid-cols-3">
                          {questionImageOptions.map((option, index) => (
                            <div key={index} className="flex justify-around w-full gap-2 mb-4">
                              {option && !imageSelectorOptionsToUpdate.includes(index) && (
                                <>
                                  <div className="flex justify-center w-full">
                                    <img
                                      style={{ maxWidth: "100%", maxHeight: "150px" }}
                                      src={option}
                                    />
                                  </div>
                                  <div className="flex flex-col justify-around">
                                    <div className="flex align-center" data-tip={t("edit")}>
                                      <ButtonMain
                                        width="auto"
                                        verticalMargin="1"
                                        horizontal="4"
                                        iconComponent={<FaPencilAlt className="inline" />}
                                        onClick={() => {
                                          setImageSelectorOptionsToUpdate([
                                            ...imageSelectorOptionsToUpdate,
                                            index,
                                          ]);
                                        }}
                                      />
                                    </div>
                                    <div className="flex align-center" data-tip={t("delete")}>
                                      <ButtonDanger
                                        iconComponent={<FaTrash className="inline" />}
                                        onClick={() => {
                                          confirm({}).then((isConfirmed) => {
                                            if (isConfirmed) {
                                              const temp = [...questionImageOptions];
                                              temp.splice(index, 1);
                                              setQuestionImageOptions(temp);
                                            }
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                              {(!option || imageSelectorOptionsToUpdate.includes(index)) && (
                                <>
                                  <InputFile
                                    setSelectedFile={(file) => onSelectImageOptionFile(file, index)}
                                    accept={".jpg, .jpeg, .png"}
                                  />
                                  <div className="flex flex-col justify-around">
                                    <div
                                      className="flex align-center"
                                      data-tip={
                                        imageSelectorOptionsToUpdate.includes(index)
                                          ? t("cancel")
                                          : t("delete")
                                      }
                                    >
                                      <ButtonDanger
                                        width="auto"
                                        verticalMargin="1"
                                        horizontal="4"
                                        iconComponent={<FaTimes className="inline" />}
                                        onClick={() => {
                                          if (imageSelectorOptionsToUpdate.includes(index)) {
                                            const tmpImageSelectorOptionsToUpdate = [
                                              ...imageSelectorOptionsToUpdate,
                                            ];
                                            const indexToDelete =
                                              tmpImageSelectorOptionsToUpdate.findIndex(
                                                (indexOpt) => indexOpt === index,
                                              );
                                            tmpImageSelectorOptionsToUpdate.splice(
                                              indexToDelete,
                                              1,
                                            );
                                            setImageSelectorOptionsToUpdate(
                                              tmpImageSelectorOptionsToUpdate,
                                            );
                                          } else {
                                            const tmpImageSelectorOptionsToUpdate = [
                                              ...imageSelectorOptionsToUpdate,
                                            ];
                                            const indexToDelete =
                                              tmpImageSelectorOptionsToUpdate.findIndex(
                                                (indexOpt) => indexOpt === index,
                                              );
                                            tmpImageSelectorOptionsToUpdate.splice(
                                              indexToDelete,
                                              1,
                                            );
                                            const tmpQuestionImageOptions = [
                                              ...questionImageOptions,
                                            ];
                                            tmpQuestionImageOptions.splice(indexToDelete, 1);
                                            setQuestionImageOptions(tmpQuestionImageOptions);
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          ))}
                        </div>
                        <div>
                          <ButtonMain
                            text={t("add_option")}
                            iconComponent={<FaPlus className="inline mr-2" />}
                            onClick={addQuestionImageOption}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>

              {type === "file" && (
                <>
                  <h5 className={`text-main font-bold border-b border-main mb-4 mt-10`}>
                    {t("file_type")}
                  </h5>

                  <div className="grid grid-cols-4 gap-4 my-4">
                    {FILE_TYPES.map((fileType, index) => (
                      <div
                        key={index}
                        onClick={() => updateFileType(fileType)}
                        className={`${fileTypes.includes(fileType) ? `shadow-inner border-2 border-main` : "shadow-soft-white"} rounded-xl p-2 text-center cursor-pointer hover:shadow-inner`}
                      >
                        {fileType === "file_image" && <FaImage className="inline" />}
                        {fileType === "file_video" && <FaVideo className="inline" />}
                        {fileType === "file_audio" && <FaMusic className="inline" />}
                        {fileType === "file_doc" && <FaFile className="inline" />}
                        {fileType === "file_pdf" && <FaFilePdf className="inline" />}
                        {fileType === "file_excel" && <FaFileExcel className="inline" />}{" "}
                        {t(fileType)}
                      </div>
                    ))}
                  </div>
                </>
              )}

              {type === "long_answer" && (
                <div className="py-2 mb-2 border-t border-gray-lines">
                  <div className="w-full px-1 my-6 text-xs font-semibold">
                    {t("answer_limitations")}
                  </div>

                  <Input
                    label={t("max_length_label")}
                    type="number"
                    id={"max_length"}
                    name={"max_length"}
                    placeholder={t("max_length_placeholder")}
                    value={maxLength}
                    onChange={(e) => {
                      setMaxLength(e.target.value ? parseInt(e.target.value) : null);
                    }}
                  />
                </div>
              )}

              <div className="py-4 mb-4 border-t border-b border-gray-lines">
                <div className="w-full px-1 my-6 text-xs font-semibold">
                  {t("classify_question")}
                </div>

                <Input
                  type="text"
                  id={"tag"}
                  name={"tag"}
                  placeholder={t("set_question_tag")}
                  value={tag}
                  onChange={(e) => {
                    setTag(e.target.value);
                  }}
                />
              </div>

              {((question && question.investor) || !question) && (
                <div className="flex justify-end pb-8">
                  {questionId && (
                    <ButtonDanger
                      onClick={deleteQuestion}
                      text={t("delete_question")}
                      marginRight="5"
                      iconComponent={<FaTrash className="inline mr-3" />}
                    />
                  )}
                  <ButtonMain
                    onClick={handleSubmit}
                    text={questionId ? t("update_question") : t("create_question")}
                    iconComponent={<FaSave className="inline mr-3" />}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}

      {(loadingQuestion || loading) && <Loader />}
    </>
  );
};

export default QuestionsForm;
