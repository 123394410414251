import { useState, useEffect, useMemo, forwardRef, useImperativeHandle } from "react";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
import { Loader } from "components/ui/molecules/Loader";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FaTrash } from "react-icons/fa";
import Avatar from "react-avatar";
import Sectors from "assets/json/sectors.json";
import FormUtils from "utils/form-utils";
import { Input } from "components/ui/atoms/Input";
import InputFile from "components/ui/atoms/input-files";
import { TextArea } from "components/ui/atoms/TextArea";
import { Autocomplete } from "components/ui/atoms/Autocomplete";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { ButtonDanger } from "components/ui/atoms/ButtonDanger";
import { Alert } from "components/ui/molecules/Alert";
import Select from "components/ui/atoms/Select";
import RejectionReasons from "assets/json/rejection-reasons.json";

const AddNewStartupForm = forwardRef(({ onSubmit, showSubmitButton = true }, ref) => {
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const sectors = Sectors.map((item) => ({ name: t(item.key), id: item._id })).sort((a, b) =>
    a.name > b.name ? 1 : -1,
  );

  const rejectionReasons = RejectionReasons.map((rejectionReason) => ({ id: rejectionReason.id, value: t(rejectionReason.key) }));

  const statuses = [
    { id: "pool", value: t("pool") },
    { id: "interesting", value: t("interesting") },
    { id: "portfolio", value: t("portfolio") },
    { id: "archived", value: t("archived") },
    { id: "rejected", value: t("rejected") },
  ];

  const validationShape = useMemo(
    () => ({
      name: Yup.string().required(t("required_field")),
      main_contact_email: Yup.string().email(t("incorrect_format")),
      logo: Yup.string().nullable(),
      sector: Yup.string().nullable(),
      brand_statement: Yup.string().nullable(),
      status: Yup.string().nullable(),
      rejection_reason: Yup.string().nullable(),
      rejection_comments: Yup.string().nullable(),
    }),
    [],
  );

  const { register, handleSubmit, trigger, errors, setValue, watch } = useForm({
    resolver: yupResolver(Yup.object().shape(validationShape), { abortEarly: false }),
    criteriaMode: "all",
    reValidateMode: "all",
    mode: "onChange",
  });

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, config);
    trigger(name);
  };

  const _onSubmit = () => {
    handleSubmit(
      (values) => {
        onSubmit(values);
      },
      () => {
        addToast(t("form_field_error"), { appearance: "error", autoDismiss: true });
      },
    )();
  };

  const onSelectImage = async(file) => {
    const fileSizeMb = (file?.size || 0) / 1024 / 1024;

    if (fileSizeMb > 5) {
      addToast(t("error_file_size", { max_size: 5 }), {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }

    setLoading(true);

    _setValue("logo", file ? await FormUtils.fileToBase64(file) : null, { shouldValidate: true });

    setTimeout(() => setLoading(false), 500);
  };

  const onSelectSector = (sector) => {
    _setValue("sector", sector ? sector.id : null);
  };

  const getSelectableInitialValues = (id, collection, lonely) => {
    if (!id) {
      return lonely ? null : [];
    }

    if (Array.isArray(id)) {
      return collection.filter((i) => id.includes(i.id));
    }

    const item = collection.find((i) => i.id === id);
    return item ? (lonely ? item : [item]) : lonely ? null : [];
  };

  useImperativeHandle(
    ref,
    () => ({
      submit: () => _onSubmit(),
    }),
    [],
  );

  useEffect(() => {
    register("logo");
    register("sector");
    register("status");
    register("rejection_reason");
  }, [register]);

  useEffect(() => {
    setValue("rejection_reason", rejectionReasons[0].id);
    trigger();
  }, []);

  return (
    <div className="w-full flex flex-col items-center">
      <div className="text-xs">
        <Alert style={"info"} text={t("register_startup_description")} bgColor={"bg-white"} />
      </div>
      <div className="w-full">
        <div className="mb-6">
          <div>
            {watch().logo ? (
              <>
                <div className="text-xs font-medium text-black">{t("logo")}</div>
                <div className="mt-6 flex flex-col justify-center items-center">
                  <Avatar
                    src={watch().logo}
                    size="80"
                    round={true}
                    color="#e0e6f2"
                    fgColor="#4d70b3"
                    className="mx-auto my-0 avatar-startup"
                  />
                  <ButtonDanger
                    type="button"
                    width={"full md:w-1/2"}
                    iconComponent={<FaTrash className="inline-block mr-2" />}
                    text={t("remove")}
                    onClick={() => onSelectImage(null)}
                  />
                </div>
              </>
            ) : (
              <div className="my-6">
                <InputFile
                  label={t("logo")}
                  placeholder={t("select_logo")}
                  error={errors.logo}
                  setSelectedFile={(file) => onSelectImage(file)}
                  accept="image/*"
                  padding="18px"
                />
              </div>
            )}
          </div>
          <div>
            <div className="my-4">
              <Input
                reference={register}
                id="name"
                name="name"
                placeholder={t("introduce_project_name")}
                label={t("project_name")}
                required={true}
                error={errors.name}
              />
            </div>
            <div className="my-4">
              <TextArea
                reference={register}
                name="brand_statement"
                label={t("brand_statement")}
                placeholder={t("brand_statement_help")}
                error={errors.brand_statement}
                row={6}
              />
            </div>
            <div className="my-2">
              <Autocomplete
                name="sector"
                placeholder={t("choose_or_type_sector")}
                label={t("sector")}
                required={false}
                options={sectors}
                initialValues={getSelectableInitialValues(null, sectors, true)}
                error={errors.sector}
                onSelect={(sector) => onSelectSector(sector)}
              />
            </div>
            <div className="my-2">
              <Input
                reference={register}
                id="main_contact_email"
                name="main_contact_email"
                placeholder={t("main_contact_email")}
                label={t("main_contact_email")}
                required={false}
                error={errors.main_contact_email}
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="w-full px-1 text-xs mb-2 font-medium">{t("status")}</div>
            <Select
              reference={register}
              name="status"
              isClearable={false}
              initialValues={statuses.filter((item) => item.id === watch("status"))}
              onSelect={(selection) =>
                _setValue("status", selection.length ? selection[0].id : null)
              }
              items={statuses}
            />
          </div>
          {watch().status === "rejected" && (
            <div>
              <p className={"font-medium mb-6 text-xs text-main"}>{t("rejected_reason_title")}</p>
              <div className="w-full mr-4">
                <Select
                  isClearable={false}
                  items={rejectionReasons}
                  initialValues={
                    watch("rejection_reason")
                      ? rejectionReasons.filter((item) => item.id === watch("rejection_reason"))
                      : []
                  }
                  onSelect={(selected) => {
                    setValue("rejection_reason", selected.length ? selected[0].id : null);
                    trigger();
                  }}
                  id="rejection_reason"
                  error={errors.rejection_reason}
                  name="rejection_reason"
                  reference={register}
                />
              </div>
              <div className="mt-2 w-full">
                <TextArea
                  reference={register}
                  name="rejection_comments"
                  error={errors.rejection_comments}
                  label={t("comments")}
                  maxLength={200}
                  placeholder={t("comments")}
                />
              </div>
            </div>
          )}
        </div>
        {showSubmitButton && (
          <div className={"flex justify-end mt-2 pt-2 border-t border-separator"}>
            <ButtonMain text={t("add_startup")} onClick={() => _onSubmit()} />
          </div>
        )}
      </div>

      {loading && <Loader />}
    </div>
  );
});

export default AddNewStartupForm;
