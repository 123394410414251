import { RoutesLinks } from "components/routes-links";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { Card } from "components/ui/atoms/Card";
import Select from "components/ui/atoms/Select";
import { SidePanel } from "components/ui/objects/SidePanel";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaRegClone, FaRegFile } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import ScoreCardsService from "services/score-cards-service";

const CreateTypeSelector = ({ onClose }) => {
  const { t } = useTranslation();

const navigate = useNavigate();
  const { addToast } = useToasts();

  const [clone, setClone] = useState(false);

  const [formItems, setFormItems] = useState([]);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    register("scorecard");
    fetchScoreCards();
  }, []);

  const fetchScoreCards = async () => {
    try {
      const queryParams = {
        page: 0,
        rowsPerPage: 9999,
        sortBy: "-createdAt",
      };

      const result = await ScoreCardsService.getScoreCards(queryParams);
      setFormItems(
        result.data.data.score_cards.map((scorecard) => ({
          id: scorecard._id,
          value: scorecard.name,
          ...scorecard,
        })),
      );
    } catch (error) {
      addToast(t("error_occurred_retrieving_score_cards"), {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const onSubmit = (data) => {
    navigate(`${RoutesLinks.SCORE_CARDS_LINK}wizard?cloneId=${data.scorecard._id}`);
  };

  return (
    <SidePanel width={"1/4"} onClose={onClose} title={t("create_score_card")}>
      <div className={"flex flex-col max-h-full"}>
        {!clone && (
          <>
            <Card wrapperClassName="my-2">
              <h3>{t("create_scorecard_blank")}</h3>
              <p className="text-sm">{t("create_scorecard_blank_description")}</p>
              <div className="flex items-center justify-center mt-8">
                <FaRegFile size={60} />
              </div>
              <ButtonSecondary
                width={"full"}
                marginTop={4}
                text={t("select_this")}
                onClick={() => navigate(`${RoutesLinks.SCORE_CARDS_LINK}wizard`)}
              />
            </Card>
            <Card wrapperClassName="my-2">
              <h3>{t("clone_scorecard")}</h3>
              <p className="text-sm">{t("clone_scorecard_description")}</p>
              <div className="flex items-center justify-center mt-8">
                <FaRegClone size={60} />
              </div>
              <ButtonSecondary
                width={"full"}
                marginTop={4}
                text={t("select_this")}
                onClick={() => setClone(true)}
              />
            </Card>
          </>
        )}
        {clone && (
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
            <p className="mb-8 text-sm">{t("clone_scorecard_description")}</p>
            <div className="my-3">
              <Select
                name="scorecard"
                label={t("select_scorecard")}
                multiSelect={false}
                isClearable={false}
                items={formItems}
                initialValues={[]}
                onSelect={(val) => {
                  setValue("scorecard", val[0]);
                }}
              />
            </div>
            <div className="flex gap-2 mt-4 justify-end">
              <ButtonSecondary
                onClick={() => setClone(false)}
                type={"button"}
                text={t("back")}
              ></ButtonSecondary>
              <ButtonMain text={t("clone_score_card")} />
            </div>
          </form>
        )}
      </div>
    </SidePanel>
  );
};

export default CreateTypeSelector;
