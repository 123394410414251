import axios from "axios/index";
import Config from "../config";

export const DealflowCustomTableService = {
  getCustomTable: async() => axios.get(`${Config.DEALFLOW_CUSTOM_TABLE_API_URL}`),

  addColumn: async(fieldId, customField) =>
    axios.post(`${Config.DEALFLOW_CUSTOM_TABLE_API_URL}columns`, {
      field: fieldId,
      custom: customField,
    }),

  updateColumn: async(columnId, data) =>
    axios.patch(`${Config.DEALFLOW_CUSTOM_TABLE_API_URL}columns/${columnId}`, data),

  deleteColumn: async(columnId) =>
    axios.delete(`${Config.DEALFLOW_CUSTOM_TABLE_API_URL}columns/${columnId}`),
};
