import React, { useState } from "react";
import LanguagesJson from "assets/json/languages.json";
import { useTranslation } from "react-i18next";
import Lang from "lang";

import Select from "components/ui/atoms/Select";

const LanguageForm = ({ onSubmit }) => {
  const { t } = useTranslation();

  const languages = LanguagesJson.map((language) => {
    return { id: language.code, value: language.name };
  });
  const [selectedLanguage, setSelectedLanguage] = useState([
    {
      id: Lang.getCurrentLang().code,
      value: Lang.getCurrentLang().name,
    },
  ]);

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event);
    onSubmit(event[0].id);
    localStorage.setItem("lang_code", event[0].id);
  };

  return (
    <>
      <div className="w-full">
        <Select
          id="language"
          items={languages}
          initialValues={selectedLanguage}
          onSelect={(e) => {
            handleLanguageChange(e);
          }}
          placeholder={t("language")}
          name="language"
          isClearable={false}
        />
      </div>
    </>
  );
};

export default LanguageForm;
