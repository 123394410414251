import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://478209701fe746a8ad1006a09694e029@o474089.ingest.sentry.io/5509840",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const SentryProvider = ({ children }) => <>{children}</>;

export default SentryProvider;
