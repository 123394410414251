import { DealResumeSection } from "components/dealflow/DealResumeSection";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import { Loader } from "components/ui/molecules/Loader";
import { usePagination } from "hooks/usePagination";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import DocumentsService from "services/documents-service";
import { MemberStartup } from "./MemberStartup";
import { Pagination } from "components/ui/atoms/Pagination";
import MembersService from "services/members-service";
import { useParams } from "react-router-dom";
import { SidePanel } from "components/ui/objects/SidePanel";
import { Checkbox } from "components/ui/molecules/Checkbox";
import { ButtonMain } from "components/ui/atoms/ButtonMain";

const MemberStartups = forwardRef(({ setFiltersCount }, ref) => {
  const { memberId } = useParams();
  const [dealflow, setDealflow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dealToResume, setDealToResume] = useState(null);
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [showFilters, setShowFilters] = useState(false);
  const [interesting, setInteresting] = useState(false);

  const { setTotalPages, totalPages, setPage, page, rowsPerPage, setRowsPerPage } =
    usePagination(12);

  const getStartups = () => {
    setLoading(true);
    const query = {
      rowsPerPage,
      page,
      interesting: interesting || undefined,
    };
    return MembersService.getMemberDealflow(memberId, query)
      .then((result) => {
        const { dealflow, pagination } = result.data.data;
        setDealflow(dealflow);
        setTotalPages(pagination.pages);
      })
      .catch(() => {
        addToast(t("error_occurred_retrieving_startups"), {
          appearance: "error",
          autoDismiss: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useImperativeHandle(
    ref,
    () => ({
      showFilters() {
        setShowFilters(true);
      },
    }),
    [],
  );

  const handleApplyFilters = () => {
    if (interesting) {
      setFiltersCount(1);
    } else {
      setFiltersCount(false);
    }

    setShowFilters(false);

    getStartups();
  };

  useEffect(() => {
    getStartups();
  }, [page, rowsPerPage]);

  const downloadDocument = async (documentId) => {
    setLoading(true);
    DocumentsService.getDocument(documentId)
      .then((response) => {
        if (response?.data?.data?.url) {
          window.open(response.data.data.url, "_blank");
        }
      })
      .catch((error) => {
        addToast(
          error?.response?.data?.msg_key
            ? t(error.response.data.msg_key)
            : t("error_occurred_downloading_document"),
          {
            appearance: "error",
            autoDismiss: true,
          },
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {dealToResume && (
        <DealResumeSection
          deal={dealToResume || {}}
          onClose={() => {
            setDealToResume(null);
          }}
          section={"contacts"}
          downloadDocument={downloadDocument}
        />
      )}
      {loading && <Loader />}
      {dealflow.length === 0 && (
        <div className="my-6">
          <NoDataInfo textOne={t("member_startups_not_found")} />
        </div>
      )}
      {dealflow.length !== 0 && (
        <div className="grid grid-cols-1 lg:grid-cols-2  2xl:grid-cols-3 gap-4">
          {dealflow.map((dealflow) => (
            <MemberStartup
              key={dealflow._id}
              dealflow={dealflow}
              setDealToResume={setDealToResume}
            />
          ))}
        </div>
      )}
      {showFilters && (
        <SidePanel title={t("filters")} onClose={() => setShowFilters()} width="1/4">
          <form className="flex flex-col w-full h-full">
            <Checkbox
              checked={interesting}
              label={t("interesting")}
              onChange={() => setInteresting(!interesting)}
            />
            <div className={"flex justify-end mt-2 pt-2 border-t border-separator"}>
              <ButtonMain type={"button"} text={t("filter")} onClick={handleApplyFilters} />
            </div>
          </form>
        </SidePanel>
      )}
      {dealflow.length !== 0 && (
        <Pagination
          paginateOptions={[12, 24, 36, 48, 60]}
          currentPage={page}
          setCurrentPage={(page) => setPage(page)}
          perPage={rowsPerPage}
          pages={totalPages}
          setPerPage={(value) => setRowsPerPage(value[0].id)}
        />
      )}
    </>
  );
});

export default MemberStartups;
