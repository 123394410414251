import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import SidebarMenuItems from "./sidebar-menu-items";
import { UiList } from "../../ui/objects/UiList";
import useUser from "../../../hooks/useUser";
import { useSelector } from "react-redux";
import { checkUrl } from "utils/layouts/check-url";

const INVESTOR_SECTIONS = [
  "dashboard",
  "dealflow",
  "irm",
  "pipeline_manager",
  "evaluations",
  "documentation",
  "events",
  "questionnaires_engine",
  "web",
  "reports",
  "portfolio",
];

const Menu = () => {
  const theme = useSelector((state) => state.theme);

  const isExpanded = theme.sidebar_expanded;

  const { t } = useTranslation();
  const { user, hasAccessToModule } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [hovers, setHovers] = useState({});
  const platform = useSelector((state) => state.app.platform);
  const [sidebarHover, setSidebarHover] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const onItemClick = useCallback((item, index) => {
    setHovers({ [index]: true });
    navigate(item.url);
  }, [history]);

  const onMouseOver = useCallback((index) => {
    setHovers((prev) => ({ ...prev, [index]: true }));
  }, []);

  const onMouseLeave = useCallback((item, index) => {
    setHovers({ [index]: checkUrl(item, location.pathname, user) });
  }, [location.pathname, user]);

  const getMenuItems = useCallback((isExpanded, sidebarHover) => {
    const sections = [];
    const userSections = user.lp
      ? ["dashboard"].concat(
          user.lp_permissions.dealflow ? ["dealflow"] : [],
          user.lp_permissions.contacts ? ["irm"] : [],
          user.lp_permissions.pipeline_manager ? ["pipeline_manager"] : [],
          user.lp_permissions.documentation ? ["documentation"] : [],
        )
      : [
          ...user.investor.permissions.sections.slice(0, 2),
          "portfolio",
          ...user.investor.permissions.sections.slice(2),
        ].filter((section) => INVESTOR_SECTIONS.includes(section));

    userSections.forEach((section) => {
      const s = SidebarMenuItems.find((s) => s.section === section);
      if (s) { sections.push(s); }
    });

    return sections.map((item, index) => (
      <div id={`sidebar-menu-item-${index + 1}`} key={index} className="flex justify-center text-right w-full">
        <div
          onMouseOver={() => onMouseOver(index)}
          onMouseLeave={() => onMouseLeave(item, index)}
          onClick={() => onItemClick(item, index)}
          className={`flex items-center w-full h-9 my-2 ml-6 lg:ml-2 pl-2 rounded-l-2xl cursor-pointer text-center 
            hover:bg-white hover:text-main hover:shadow-none ${
              checkUrl(item, location.pathname, user) ? "font-bold bg-white text-main shadow-none" : "text-white shadow-soft-white"
            }`}
        >
          <div className="w-8 flex justify-between text-center text-white hover:text-white">
            {hovers[index] || checkUrl(item, location.pathname, user) ? item.iconHover : item.icon}
          </div>
          {(isExpanded || sidebarHover) && (
            <div
              className={`text-sm font-medium hidden lg:block pr-6 sidebar-title ${
                isExpanded || sidebarHover ? "visible" : ""
              }`}
            >
              {t(item.title)}
            </div>
          )}
        </div>
      </div>
    ));
  }, [user, location.pathname, hovers, onMouseOver, onMouseLeave, onItemClick, t]);

  useEffect(() => {
    if (hasAccessToModule("members")) {
      INVESTOR_SECTIONS.push("members");
    }
  }, [user]);

  useEffect(() => {
    setHovers({});
  }, [location.pathname]);

  return (
    <>
    <div className={`${((sidebarHover && !isExpanded) || isClosing) && "w-20"}`}></div>
    <div
      className={`sidebar bg-main h-screen z-20 ${isExpanded || sidebarHover ? "sidebar-expanded" : "sidebar-collapsed"} ${((sidebarHover && !isExpanded) || isClosing) && "fixed"}`}
      onMouseOver={() => setSidebarHover(true)}
      onMouseLeave={() => {
        if (!isExpanded) {
          setIsClosing(true);
        }
        setSidebarHover(false);
        setTimeout(() => {
          setIsClosing(false);
        }, 300);
      }}
    >
      <div className="relative h-full">
        <div className="menu-logo-container border-b h-16 border-b-gray-700">
          {(isExpanded || sidebarHover) && (
            <div className="flex justify-center items-center hidden lg:flex h-full">
              <img src={platform.logo_sidebar} alt={platform.name} className="m-auto" style={{ height: "80%" }}/>
            </div>
          )}
          {!isExpanded && !sidebarHover && (
            <div className="flex justify-center items-center hidden lg:flex h-full">
              <img src={platform.logo_sidebar_min} alt={platform.name} className="m-auto h-full pt-2" style={{ height: "80%" }} />
            </div>
          )}
          <div className="flex justify-center items-center block lg:hidden">
            <img src={platform.logo_sidebar_min} alt={platform.name} className="m-auto h-full pt-2" style={{ height: "80%" }} />
          </div>
        </div>
        <UiList className="mt-4 lg:mt-8 flex-grow ">{getMenuItems(isExpanded, sidebarHover)}</UiList>
        {platform.cobranding && (
        <div className="absolute bottom-2 left-0 w-full text-xxs lg:text-xxs text-center text-white border-t">
          <a href="https://www.kiota.com" target="_blank" className="block text-white mt-2 font-normal text-xxs" rel="noreferrer">
            {t("powered_by_kiota")}
          </a>
        </div>
        )}
        </div>
      </div>
    </>
  );
};

export default Menu;
