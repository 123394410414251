import { useTranslation, Trans } from "react-i18next";
import Config from "config";
import useUser from "hooks/useUser";
import moment from "moment";
import { ButtonSecondary } from "./ButtonSecondary";

export const DemoAccountBanner = () => {
  const { t } = useTranslation();

  const { user } = useUser();

  const getReminingDays = () => Config.TRIAL_DAYS - moment().diff(moment(user.investor.activation_date), "days");

  const remainingDays = getReminingDays();

  return (
    <>
      <div className={"fixed top-0 left-0 border-t-4 border-main b-2 z-50 w-full ml-20 lg:ml-52"} />
      <div
        className={
          "fixed top-0 left-0 flex flex-col items-center justify-center w-full ml-20 z-50 lg:ml-52 bg-secondary"
        }
      >
        <div className={"text-center text-sm text-main font-bold px-3 py-2 flex items-center"}>
          <div className="flex items-center">{t("demo_account")}</div>
          <div className="ml-2 flex items-center whitespace-pre-wrap">
            <Trans
              i18nKey="remining_time"
              // Solo muestra el tiempo restante si es positivo o 0
              values={{ days: remainingDays < 0 ? 0 : remainingDays }}
              components={{ bold: <strong /> }}
            />
            <ButtonSecondary
              text={t("upgrade_now")}
              marginLeft={2}
              onClick={() => window.open("https://buy.stripe.com/4gw3e7g2B7TqfL2bJ0", "_blank")}
            />
          </div>
        </div>
      </div>
    </>
  );
};
