import "shepherd.js/dist/css/shepherd.css";
import "tailwindcss/tailwind.css";
import "index.css";
import "App.css";

import { useRef, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "components/loader";
import PropTypes from "prop-types";
import { Chat } from "components/chat/Chat";
import { useTour } from "hooks/useTour";
import ReactTooltip from "react-tooltip";
import { OnboardingTasksPannel } from "components/ui/molecules/OnboardingTasksPannel";
import Lang from "lang";
import { mountScript } from "components/account/components/emails/scripts/mountGoogleScript";
import { ProductUpdatesModal } from "components/ui/molecules/ProductUpdatesModal";
import { SetupProcessModal } from "components/ui/molecules/SetupProcessModal";
import Config from "config";
import moment from "moment";
import useUser from "hooks/useUser";

const FullPageLayout = () => {
  const scrollBar = useRef(null);
  const location = useLocation();
  const { user, isDemoAccount } = useUser();
  const appLoading = useSelector((state) => state.app.loading);
  const checked = useSelector((state) => state.checks && state.checks.checked);
  const showOnboardingTasksPannel = useSelector(
    (state) => state.onboarding && state.onboarding.show,
  );
  const platform = useSelector((state) => state.app.platform);

  const [showProductUpdates, setShowProductUpdates] = useState(false);
  const [showSetupProcess, setShowSetupProcess] = useState(false);

  const tour = useTour("quick_tour");

  useEffect(() => {
    if (platform.favicon) {
      const favicon = document.querySelector("head link[rel=\"icon\"]");
      favicon.setAttribute("href", platform.favicon);
    }
    if (platform.title_page) {
      document.title = platform.title_page[Lang.getCurrentLang().code];
    }
    sessionStorage.setItem("platform_id", Math.random().toString(36).substring(2, 9));
  }, [platform]);

  useEffect(() => {
    if (scrollBar && scrollBar.current) {
      scrollBar.current.scrollToTop();
    }

    ReactTooltip.hide();
  }, [location.pathname]);

  useEffect(() => {
    // tour launch
    if (user && user._id && user.welcome_steps && !user.investor.setup_type && !user.lp) {
      setShowSetupProcess(true);
    } else if (user && user._id && user.welcome_steps && !user?.tours?.quick_tour && !user.lp) {
      setShowSetupProcess(false);
      tour.start();
    } else if (
      user &&
      user._id &&
      user.welcome_steps &&
      user.tours?.quick_tour &&
      user.show_product_updates &&
      !user.lp
    ) {
      setShowSetupProcess(false);
      setShowProductUpdates(true);
    } else if (
      user &&
      user._id &&
      user.welcome_steps &&
      user.tours?.quick_tour &&
      !user.show_product_updates &&
      !user.lp
    ) {
      setShowSetupProcess(false);
      setShowProductUpdates(false);
    }
  }, [user]);

  useEffect(() => {
    mountScript();
  }, []);

  if (appLoading) {
    return <Loader />;
  }

  const getReminingDays = () => Config.TRIAL_DAYS - moment().diff(moment(user.investor.activation_date), "days");

  return (
    <>

         <Outlet />
    </>
  );
};

FullPageLayout.propTypes = {
  window: PropTypes.func,
};

export default FullPageLayout;
