import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useToasts } from "react-toast-notifications";

import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import InvestmentStages from "assets/json/investment-stages.json";
import Sectors from "assets/json/sectors.json";
import Countries from "assets/json/countries.json";
import BusinessModels from "assets/json/business-models.json";
import ProjectStages from "assets/json/project-stages.json";
import FormsService from "services/forms-service";
import useUser from "hooks/useUser";
import EvaluationProcessesManagerService from "services/evaluation-processes-manager-service";
import { SidePanel } from "components/ui/objects/SidePanel";
import { Autocomplete } from "components/ui/atoms/Autocomplete";
import Select from "components/ui/atoms/Select";
import { Input } from "components/ui/atoms/Input";
import TagsService from "services/tags-service";

export const InvestmentsFilters = ({
  onFilter,
  onClose,
  showTags = true,
  filters = {},
  section = "pool",
}) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [tags, setTags] = useState([]);
  const [events, setEvents] = useState([]);
  const [forms, setForms] = useState([]);

  const [selectedTags, setSelectedTags] = useState([]);

  const { user, checkIfLPhasAccessToSection } = useUser();

  const sectors = Sectors.map((s) => ({ name: t(s.key), id: s._id })).sort((a, b) => (a.name > b.name ? 1 : -1));

  const countries = Countries.map((c) => ({ name: t(c.key), id: c._id })).sort((a, b) => (a.name > b.name ? 1 : -1));

  const investmentStages = InvestmentStages.map((is) => ({ value: t(is.key), id: is._id }));

  const businessModels = BusinessModels.map((bm) => ({ value: t(bm.key), id: bm._id }));

  const statuses = [
    { id: "unassigned", value: t("unassigned") },
    { id: "rejected", value: t("rejected") },
    { id: "failed", value: t("failed") },
    { id: "interesting", value: t("interesting") },
    { id: "committed", value: t("committed") },
    { id: "invested", value: t("invested") },
  ];

  const priorities = [
    { id: "low", value: t("low_priority") },
    { id: "medium", value: t("medium_priority") },
    { id: "high", value: t("high_priority") },
  ];

  const NextSteps = [
    { id: "initial_meeting", value: t("initial_meeting") },
    { id: "contact_by_email", value: t("contact_by_email") },
    { id: "intro", value: t("intro") },
  ];

  const INVESTMENT_TYPES = [
    { id: "first_investment", name: t("first_ticket") },
    { id: "follow_on", name: t("follow_on") },
  ];
  // const INVESTMENT_TYPES = InvestmentStages.map((item) => {
  //   return { _id: item._id, id: item.key, name: item.translation_en }
  // })

  const projectStages = ProjectStages.map((ps) => ({ value: t(ps.key), id: ps._id }));

  const { register, handleSubmit, trigger, errors, setValue, reset, watch } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string().nullable(),
        investment_from: Yup.string().nullable(),
        investment_to: Yup.string().nullable(),
        investment_type: Yup.string().nullable(),
        sector: Yup.string().nullable(),
        country_incorporation: Yup.string().nullable(),
        business_model: Yup.string().nullable(),
        status: Yup.string().nullable(),
        priority: Yup.string().nullable(),
        next_step: Yup.string().nullable(),
        project_stage: Yup.string().nullable(),
        investment_stage: Yup.string().nullable(),
        events: Yup.string().nullable(),
      }),
      { abortEarly: false },
    ),
    criteriaMode: "all",
    reValidateMode: "all",
    mode: "onChange",
  });

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, config);
    trigger(name);
  };

  const filter = ({ clearFilters, ...filterValues }) => {
    let _filters = {};

    if (!clearFilters) {
      _filters = Object.keys(filterValues).reduce((acc, key) => {
        if (filterValues[key]) {
          acc[key] = filterValues[key];
        }
        return acc;
      }, {});

      if (selectedTags.length) {
        _filters.tags = selectedTags;
      }
    }

    onFilter && onFilter(_filters);
    onClose && onClose();
  };

  const resetFilters = () => {
    reset();
    setSelectedTags([]);
    filter({ clearFilters: true });
  };

  const onInvalid = () => {
    console.log(errors);
    addToast(t("form_field_error"), { appearance: "error", autoDismiss: true });
  };

  const getEvents = () => {
    EvaluationProcessesManagerService.getEvaluationProcesses({
      rowsPerPage: 99999999,
    })
      .then((response) => {
        setEvents(
          response.data.data?.events?.map(
            (e) =>
              ({
                id: e._id,
                value: e.name,
              }) || [],
          ),
        );
      })
      .catch(() => {
        addToast(t("error_occurred_retrieving_events"), {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const getForms = () => {
    FormsService.getQuestionnairesForms({
      rowsPerPage: 99999999,
    })
      .then((response) => {
        setForms(
          response.data.data?.forms?.map(
            (e) =>
              ({
                id: e._id,
                value: e.name,
              }) || [],
          ),
        );
      })
      .catch(() => {
        addToast(t("error_occurred_retrieving_forms"), {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const getTags = () => {
    TagsService.getTags("dealflow")
      .then((response) => {
        setTags(response.data.data);
      })
      .catch(() => {
        addToast(t("error_occurred_retrieving_tags"), {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };
  useEffect(() => {
    register("type");
    register("name");
    register("investment_from");
    register("investment_to");
    register("sector");
    register("country_incorporation");
    register("business_model");
    register("status");
    register("priority");
    register("investment_type");
    register("next_step");
    register("project_stage");
    register("investment_stage");
    register("events");
    register("forms");
  }, [register]);

  useEffect(() => {
    if (filters) {
      Object.entries(filters).forEach(([key, value]) => {
        if (key === "tags") {
          setSelectedTags(value);
        } else {
          _setValue(key, value, { shouldValidate: true });
        }
      });
    }
  }, [filters, register]);

  useEffect(() => {
    if (showTags) {
      getTags();
    }
    if (section === "pool") {
      getEvents();
    }
    getForms();
  }, []);

  return (
    <SidePanel title={t("filters")} onClose={() => onClose && onClose()} width="1/3">
      <form className="flex flex-col w-full" onSubmit={handleSubmit(filter, onInvalid)}>
        <div className="mt-2">
          <Input
            reference={register}
            name="name"
            label={t("name")}
            placeholder={t("type_name_placeholder")}
            error={errors.name}
          />

          <Input
            reference={register}
            name="investment_from"
            label={t("investment_from")}
            placeholder={t("investment_from_placeholder")}
            type={"number"}
            error={errors.name}
          />

          <Input
            reference={register}
            name="investment_to"
            label={t("investment_to")}
            placeholder={t("investment_to_placeholder")}
            type={"number"}
            error={errors.name}
          />
          <Autocomplete
            name="investment_type"
            placeholder={t("investment_type_placeholder")}
            label={t("investment_type")}
            options={INVESTMENT_TYPES}
            error={errors.sector}
            initialValues={
              watch("investment_type")
                ? INVESTMENT_TYPES.find((item) => item.id === watch("investment_type"))
                : null
            }
            onSelect={(type) => _setValue("investment_type", type?.id || null)}
          />

          <Autocomplete
            name="sector"
            placeholder={t("sector_placeholder")}
            label={t("sector")}
            options={sectors}
            error={errors.sector}
            initialValues={
              watch("sector") ? sectors.find((item) => item.id === watch("sector")) : null
            }
            onSelect={(sector) => _setValue("sector", sector?.id || null)}
          />

          <Autocomplete
            name="country_incorporation"
            placeholder={t("country_placeholder")}
            options={countries}
            label={t("country_incorporation")}
            error={errors.country_incorporation}
            onSelect={(country) => _setValue("country_incorporation", country ? country.id : null)}
            initialValues={
              watch("country_incorporation")
                ? countries.find((item) => item.id === watch("country_incorporation"))
                : null
            }
          />

          <Select
            name="business_model"
            reference={register}
            label={t("business_model")}
            placeholder={t("bussiness_model_placeholder")}
            items={businessModels}
            error={errors.business_model}
            onSelect={(selected) =>
              _setValue("business_model", selected?.length ? selected[0].id : null)
            }
            initialValues={
              watch("business_model")
                ? businessModels
                  .filter((item) => item.id === watch("business_model"))
                  .map((item) => ({ id: item.id, value: item.value }))
                : []
            }
          />

          <Select
            name="project_stage"
            reference={register}
            label={t("project_stage")}
            placeholder={t("phase_placeholder")}
            items={projectStages}
            error={errors.project_stage}
            onSelect={(selected) =>
              _setValue("project_stage", selected?.length ? selected[0].id : null)
            }
            initialValues={
              watch("project_stage")
                ? projectStages
                  .filter((item) => item.id === watch("project_stage"))
                  .map((item) => ({ id: item.id, value: item.value }))
                : []
            }
          />

          <Select
            name="investment_stage"
            reference={register}
            label={t("investment_stage")}
            items={investmentStages}
            placeholder={t("investment_stage_placeholder")}
            error={errors.investment_stage}
            onSelect={(selected) =>
              _setValue("investment_stage", selected?.length ? selected[0].id : null)
            }
            initialValues={
              watch("investment_stage")
                ? investmentStages
                  .filter((item) => item.id === watch("investment_stage"))
                  .map((item) => ({ id: item.id, value: item.value }))
                : []
            }
          />

          <Select
            name="events"
            reference={register}
            label={t("evaluation_process")}
            placeholder={t("ecaluation_process_placeholder")}
            items={events}
            error={errors.events}
            onSelect={(selected) => _setValue("events", selected.length ? selected[0].id : null)}
            initialValues={
              watch("events")
                ? events
                  .filter((item) => item.id === watch("events"))
                  .map((item) => ({ id: item.id, value: item.value }))
                : []
            }
          />
        </div>

        <div className={"flex justify-end mt-2 pt-2 border-t border-separator"}>
          <ButtonSecondary
            text={t("clear_filters")}
            onClick={() => resetFilters()}
            type="button"
            marginRight={2}
          />
          <ButtonMain text={t("filter")} type="submit" />
        </div>
      </form>
    </SidePanel>
  );
};
