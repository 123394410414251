import { SectionHeader } from "../../ui/molecules/SectionHeader";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormQuestions } from "./forms-form/FormQuestions";
import useUser from "../../../hooks/useUser";
import { Alert } from "../../ui/molecules/Alert";
import { FormKeyQuestions } from "./forms-form/FormKeyQuestions";
import { isEmpty } from "lodash";

export const FormContentSection = ({
  processQuestions,
  filteredQuestions,
  setFilteredQuestions,
  questions,
  questionnaire,
  keyQuestions,
  setKeyQuestions,
  selectedQuestions,
  setSelectedQuestions,
  requiredQuestions,
  setRequiredQuestions,
  targetRole,
}) => {
  const { t } = useTranslation();
  const { userLanguage } = useUser();
  const [section, setSection] = useState("questions");
  const SECTIONS = [
    { id: "questions", route: "questions", title: "questions" },
    { id: "key_questions", route: "key_questions", title: "key_questions" },
  ];

  const [selectedExpandedQuestions, setSelectedExpandedQuestions] = useState([]);

  useEffect(() => {
    if (questions.length && selectedQuestions.length) {
      const selectedExpandedQuestionsIds = [];
      selectedQuestions.forEach((selectedQuestion) => {
        if (selectedQuestion.type !== "separator") {
          if (selectedQuestion.type !== "questions_group") {
            selectedExpandedQuestionsIds.push(selectedQuestion._id);
          } else if (selectedQuestion.type === "questions_group") {
            const groupedQuestion = questions.find(
              (question) => question._id === selectedQuestion._id,
            );
            groupedQuestion.questionsGroup.forEach((q) => {
              if (!selectedExpandedQuestionsIds.includes(q)) {
                selectedExpandedQuestionsIds.push(q);
              }
            });
          }
        }
      });
      const _selectedExpandedQuestions = [];
      selectedExpandedQuestionsIds.forEach((selectedExpandedQuestionId) => {
        const question = questions.find((q) => q._id === selectedExpandedQuestionId);
        if (question) {
          _selectedExpandedQuestions.push(question);
        }
      });
      setSelectedExpandedQuestions(_selectedExpandedQuestions);
    } else if (!selectedQuestions.length) {
      setSelectedExpandedQuestions([]);
    }
  }, [questions, selectedQuestions]);

  useEffect(() => {
    if (isEmpty(keyQuestions) && questionnaire) {
      let _keyQuestions = questionnaire.draft
        ? questionnaire.draft.key_questions || {}
        : questionnaire.key_questions || {};
      for (const key in keyQuestions) {
        if (
          keyQuestions[key] &&
          !selectedExpandedQuestions.find((q) => q._id === keyQuestions[key])
        ) {
          _keyQuestions = { ..._keyQuestions, [key]: null };
        }
      }

      setKeyQuestions(_keyQuestions);
    }
  }, [questionnaire, selectedExpandedQuestions]);

  return (
    <>
      <SectionHeader
        section={section}
        setSection={setSection}
        sectionKeys={SECTIONS}
        sectionTitles={SECTIONS.map((s) => t(s))}
      />

      {!!questionnaire?.draft && <Alert style="warning" text={t("form_draft_warning_2")} />}

      {section === "questions" && (
        <FormQuestions
          selectedQuestions={selectedQuestions}
          setSelectedQuestions={setSelectedQuestions}
          lang={userLanguage}
          requiredQuestions={requiredQuestions}
          setRequiredQuestions={setRequiredQuestions}
          filteredQuestions={filteredQuestions}
          setFilteredQuestions={setFilteredQuestions}
          keyQuestions={keyQuestions}
          setKeyQuestions={setKeyQuestions}
          questions={questions}
          processQuestions={processQuestions}
          selectedExpandedQuestions={selectedExpandedQuestions}
          targetRole={targetRole}
        />
      )}

      {section === "key_questions" && (
        <FormKeyQuestions
          lang={userLanguage}
          keyQuestions={keyQuestions}
          setKeyQuestions={setKeyQuestions}
          selectedExpandedQuestions={selectedExpandedQuestions}
        />
      )}

      {section === "logic" && <div>logic</div>}
    </>
  );
};
