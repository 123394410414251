import axios from "axios/index";
import Config from "../config";

const RemindersService = {
  getReminders: (filters) => {
    let query = "";

    Object.keys(filters).forEach((key) => {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}${key}=${filters[key]}`;
    });
    return axios.get(`${Config.REMINDERS_API_URL}${query}`);
  },

  createReminder: (data) => axios.post(Config.REMINDERS_API_URL, data),

  getReminder: async(reminderId) => axios.get(`${Config.REMINDERS_API_URL}${reminderId}`),

  updateReminder: async(reminderId, data) =>
    axios.patch(`${Config.REMINDERS_API_URL}${reminderId}`, data),

  deleteReminder: async(reminderId) => axios.delete(`${Config.REMINDERS_API_URL}${reminderId}`),

  getRemindersResume: async() => axios.get(`${Config.REMINDERS_API_URL}resume`),
};
export default RemindersService;
