import { useEffect } from "react";
import { SidePanel } from "../ui/objects/SidePanel";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useToasts } from "react-toast-notifications";
import Select from "../ui/atoms/Select";
import { ButtonMain } from "components/ui/atoms/ButtonMain";

export const DealflowOrderBy = ({
  onOrder,
  onClose,
  orderBy,
}) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const orderByOptions = [{ id: "-interested_count", value: t("interested_count_desc") }, { id: "+interested_count", value: t("interested_count_asc") }];

  const { register, handleSubmit, trigger, errors, setValue, reset, watch } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        order_by: Yup.string().nullable(),
      }),
      { abortEarly: false },
    ),
    criteriaMode: "all",
    reValidateMode: "all",
    mode: "onChange",
  });

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, config);
    trigger(name);
  };

  const filter = ({ clearFilters, ...filterValues }) => {
    let _filters = {};

    if (!clearFilters) {
      _filters = Object.keys(filterValues).reduce((acc, key) => {
        if (filterValues[key]) {
          acc[key] = filterValues[key];
        }
        return acc;
      }, {});
    }

    onOrder && onOrder(_filters.order_by);
    onClose && onClose();
  };

  const resetFilters = () => {
    reset();
    filter({ clearFilters: true });
  };

  const onInvalid = () => {
    addToast(t("form_field_error"), { appearance: "error", autoDismiss: true });
  };

  useEffect(() => {
    register("order_by");
    orderBy && setValue("order_by", orderBy);
  }, [register]);

  return (
    <SidePanel title={t("order_by")} onClose={() => onClose && onClose()} width="1/4">
      <form className="flex flex-col w-full" onSubmit={handleSubmit(filter, onInvalid)}>
        <div className="mt-2">
            <Select
                name="order_by"
                reference={register}
                label={t("order_by")}
                placeholder={t("order_by")}
                items={orderByOptions}
                error={errors.status}
                onSelect={(selected) =>
                  _setValue("order_by", selected?.length ? selected[0].id : null)
                }
                initialValues={
                  watch("order_by")
                    ? orderByOptions
                      .filter((item) => item.id === watch("order_by"))
                      .map((item) => ({ id: item.id, value: item.value }))
                    : []
                }
              />

          </div>
          <ButtonMain type="submit" text={t("sort")} />
      </form>
    </SidePanel>
  );
};
