import { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import {
  FaEnvelope,
  FaWpforms,
  FaChevronUp,
  FaChevronDown,
  FaPlus,
} from "react-icons/fa";
import { IoMdRocket } from "react-icons/io";
import { GiPodium, GiConfirmed } from "react-icons/gi";
import { BiLineChart, BiSelectMultiple } from "react-icons/bi";
import { BsBookmark } from "react-icons/bs";
import { AiOutlineClockCircle } from "react-icons/ai";
import ReactTooltip from "react-tooltip";

import { PopoverTrelloList } from "components/ui/atoms/PopoverTrelloList";
import moment from "moment";

const PipelineList = ({
  editable,
  key,
  ListTitle,
  children,
  addCard,
  editColumn,
  deleteColumn,
  column,
  items,
  exportStage,
  getRecord,
  expandOrCollapseColumn,
  startupToSearch,
  user,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const [containSearch, setContainSearch] = useState(false);
  const getCurrentAvgTime = () => {
    let seconds = 0;
    const cardsWithTime = column.cards.filter((card) => card.stage_date);
    cardsWithTime.forEach((card) => {
      seconds = seconds + moment().diff(moment(card.stage_date), "seconds");
    });

    const avgSeconds = seconds / cardsWithTime.length;
    if (avgSeconds < 60) {
      return t("date_difference_seconds", { seconds: avgSeconds });
    } else if (avgSeconds < 3600) {
      return t("date_difference_minutes", { minutes: parseInt(avgSeconds / 60) });
    } else if (avgSeconds < 86400) {
      return t("date_difference_hours", { hours: parseInt(avgSeconds / 3600) });
    } else {
      return t("date_difference_days", { days: parseInt(avgSeconds / 86400) });
    }
  };

  const hasStartupsWithMultipleApplies = column.cards.some((card) =>
    card.dealflow && card.dealflow.forms && card.dealflow?.forms?.filter((form) => !form.automated).length > 1,
  );

  const getRecordAvgTime = (column) => {
    let seconds = 0;
    column.record.forEach((card) => {
      card.times.forEach((time) => {
        if (time.end) {
          seconds = seconds + moment(time.end).diff(moment(time.start), "seconds");
        } else {
          seconds = seconds + moment().diff(moment(time.start), "seconds");
        }
      });
    });

    const avgSeconds = seconds / column.record.length;

    if (avgSeconds < 60) {
      return (
        <Trans
          i18nKey="stage_resume_seconds_label"
          values={{ startups: column.record?.length || 0, seconds: parseInt(avgSeconds) }}
          components={{ bold: <strong /> }}
        />
      );
    } else if (avgSeconds < 3600) {
      return (
        <Trans
          i18nKey="stage_resume_minutes_label"
          values={{ startups: column.record?.length || 0, minutes: parseInt(avgSeconds / 60) }}
          components={{ bold: <strong /> }}
        />
      );
    } else if (avgSeconds < 86400) {
      return (
        <Trans
          i18nKey="stage_resume_hours_label"
          values={{ startups: column.record?.length || 0, hours: parseInt(avgSeconds / 3600) }}
          components={{ bold: <strong /> }}
        />
      );
    } else {
      return (
        <Trans
          i18nKey="stage_resume_days_label"
          values={{ startups: column.record?.length || 0, days: parseInt(avgSeconds / 86400) }}
          components={{ bold: <strong /> }}
        />
      );
    }
  };

  useEffect(() => {
    setContainSearch(false);
    if (column.cards && startupToSearch) {
      const containsSearchedStartup = column.cards.some(card => card.dealflow?._id === startupToSearch);

      if (containsSearchedStartup) {
        setContainSearch(true);
      }
    }
  }, [column, startupToSearch]);

  return (
    <div>
      <div
        id={key}
        className={`px-4 pt-4 pb-4 rounded-2x mr-2 ${containSearch && "border-yellow-400 border-2"} ${
          editable && "cursor-move"
        } bg-white flex flex-col`}
        style={{ minWidth: "300px", maxWidth: "300px" }}
      >
        <div
          style={{ overflowY: "auto", overflowX: "hidden", padding: "6px" }}
          id="seccion-con-scroll"
          onScroll={(event) => event.stopPropagation()}
        >
          <div className="flex items-center justify-between w-full">
            {!deleteColumn && (
              <h6 className="text-base font-semibold">
                {ListTitle}
                {/* <FaInfoCircle data-tip={t('stage_not_deleteable')} className='inline w-3 ml-2' /> */}
                {editable && column?.evaluation_process && (
                  <GiPodium
                    data-tip={t("add_to_judge_automation_enabled_tooltip")}
                    className="inline w-4 mb-1 ml-2 text-yellow-600"
                  />
                )}
                {editable && column?.forms_automation && (
                  <FaWpforms
                    data-tip={t("assign_forms_automation_enabled_tooltip")}
                    className="inline w-4 mb-2 ml-2 text-yellow-600"
                  />
                )}
              </h6>
            )}
            {deleteColumn && (
              <h6 className="text-base font-semibold">
                {ListTitle}
                {editable && column?.forms_automation && (
                  <FaWpforms
                    data-tip={t("assign_forms_automation_enabled_tooltip")}
                    className="inline w-4 mb-2 ml-2 text-yellow-600"
                  />
                )}
                {editable && column?.evaluation_process && (
                  <GiPodium
                    data-tip={t("add_to_judge_automation_enabled_tooltip")}
                    className="inline w-4 mb-2 ml-2 text-yellow-600"
                  />
                )}
                {editable &&
                  (column?.advance_email_automation ||
                    column?.end_reached_email_automation ||
                    column?.rejection_email_automation ||
                    column?.temporarily_discarded_email_automation) && (
                    <FaEnvelope
                      data-tip={
                        column?.advance_email_automation
                          ? t("advance_email_automation_enabled_tooltip")
                          : column?.end_reached_email_automation
                            ? t("end_reached_email_utomation_enabled_tooltip")
                            : column?.temporarily_discarded_email_automation
                              ? t("temporarily_discarded_email_automation_enabled_tooltip")
                              : t("rejection_email_automation_enabled_tooltip")
                      }
                      className="inline w-3 mb-1 ml-2 text-yellow-600"
                    />
                )}
                {editable && column?.request_kpis && (
                  <BiLineChart
                    data-tip={t("kpis_automation_enabled_tooltip")}
                    className="inline w-4 mb-2 ml-2 text-yellow-600"
                  />
                )}
                {editable && column?.confirmation_mode && (
                  <GiConfirmed
                    data-tip={t("confirmation_mode_tooltip")}
                    className="inline w-4 mb-1 ml-2 text-yellow-600"
                  />
                )}
                {hasStartupsWithMultipleApplies && (
                  <BiSelectMultiple
                    data-tip={t("startup_multiple_applies_tooltip")}
                    className="inline w-4 mb-1 ml-2 text-[#E20606]"
                  />
                )}
              </h6>
            )}
            {editable && !user.lp && (
              <PopoverTrelloList
                items={
                  deleteColumn
                    ? items
                      ? ["add_startup", "edit_stage", "delete_stage", "export_startups", "see_record"]
                      : ["add_startup", "edit_stage", "delete_stage", "see_record"]
                    : items
                      ? ["add_startup", "edit_stage", "export_startups", "see_record"]
                      : ["add_startup", "edit_stage", "see_record"]
                }
                methods={{
                  add_startup: () => addCard(),
                  edit_stage: () => {
                    editColumn(column);
                  },
                  delete_stage: deleteColumn ? () => deleteColumn(column) : null,
                  export_startups: () => exportStage(column),
                  see_record: () => getRecord(column._id),
                }}
              />
            )}
          </div>

          <div className="flex flex-col my-2">
            {items > 0 && (
              <div className="flex flex-row mb-1">
                <IoMdRocket
                  className={"inline-block text-main mr-1 w-3 h-4"}
                  data-tip={t("stage_current_startups_tooltip")}
                />
                <div className={"text-xs"}>
                  {`${t("startups")}:`} <span className="ml-1 font-bold">{items}</span>
                </div>
              </div>
            )}
            {column?.time_analysis_enabled !== false &&
              editable &&
              items > 0 &&
              column.cards.some((item) => item.stage_date) && (
                <div className="flex flex-row mb-1">
                  <AiOutlineClockCircle
                    className={"inline-block text-main mr-1 w-3 h-3"}
                    data-tip={t("stage_current_avg_time_tooltip")}
                  />
                  <div className={"text-xs"}>
                    {`${t("stage_avg_time_label")}:`} <span className="ml-1 font-bold">{getCurrentAvgTime()}</span>
                  </div>
                </div>
            )}
            {column?.time_analysis_enabled !== false && editable && column.record?.length > 0 && (
              <div className="flex flex-row">
                <BsBookmark className={"inline-block text-main mr-1 w-7 h-7"} data-tip={t("stage_resume_tooltip")} />
                <div className={"text-xs"}>{getRecordAvgTime(column)}</div>
              </div>
            )}
          </div>

          <div className="flex justify-end border-b border-gray-lines">
            <span className={"text-sm text-center text-main w-5 h-5 mr-0.5 cursor-pointer"}>
              {column.expanded ? (
                <FaChevronUp
                  onClick={() => expandOrCollapseColumn(column._id, !column.expanded)}
                  data-tip={t("collapse_stage")}
                />
              ) : (
                <FaChevronDown
                  onClick={() => expandOrCollapseColumn(column._id, !column.expanded)}
                  data-tip={t("expand_stage")}
                />
              )}
            </span>
          </div>
          <div style={{ flex: "1 1 auto" }}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default PipelineList;
