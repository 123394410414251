import { Card } from "components/ui/atoms/Card";
import { SkeletonList } from "components/ui/atoms/skeletons/SkeletonList";
import { SidePanel } from "components/ui/objects/SidePanel";
import { useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import AccountService from "services/account-service";
import CompanyChildrenWizard from "./children/company-children-wizard";
import Avatar from "react-avatar";
import { ButtonCardMain } from "components/ui/atoms/ButtonCardMain";
import { FaEye } from "react-icons/fa";
import { RoutesLinks } from "components/routes-links";
import ExpandableAvatar from "components/ui/atoms/images/ExpandableImage";
import useUser from "hooks/useUser";
import { useToasts } from "react-toast-notifications";

const CompanyChildren = () => {
  const [loading, setLoading] = useState(false);
  const [showCompanyChildrenWizard, setShowCompanyChildrenWizard] = useState(false);

  const { ref, setInvestor, isChildren, investor, reloadUserInfo } = useOutletContext();

  const { user } = useUser();

  const [children, setChildren] = useState([]);
  const { addToast } = useToasts();

  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    showCompanyChildrenWizard: () => setShowCompanyChildrenWizard(true),
  }));

  const { t } = useTranslation();

  const getCompanyChildren = async() => {
    setLoading(true);
    const res = await AccountService.getInvestorChildren({});

    setChildren(res.data.data.data);

    setLoading(false);
  };

  useEffect(() => {
    getCompanyChildren();
  }, []);

  const handleChildrenCreated = async() => {
    try {
      setLoading(true);
      reloadUserInfo();
      setShowCompanyChildrenWizard(false);
      addToast(t("company_children_created_successfully"), {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (e) {
      addToast(t("company_children_created_error"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isChildren) {
      navigate(-1);
    }
  }, [isChildren]);

  useEffect(() => {
    if (investor) {
      setChildren(investor.children ?? []);
    }
  }, [investor]);

  return (
    <>
      {loading && <div className="grid grid-cols-2 gap-4 mt-6"><SkeletonList length={4} style={"h-96"} /></div> }
      {!loading && children.length > 0 && (
        <div className="grid grid-cols-2 gap-4 mt-6 flex-1">
          {children.map((childrenItem) => (
            <Card key={childrenItem._id}>
              <div className="flex justify-between">
                <h5 className={"uppercase font-bold text-sm leading-none text-main truncate mb-1"}>{childrenItem.company_name}</h5>
                <ExpandableAvatar src={childrenItem.logo} name={childrenItem.company_name}>
                  <Avatar
                    src={childrenItem.logo}
                    name={childrenItem.company_name}
                    alt={childrenItem.company_name}
                    maxInitials={3}
                    className={"h-30 object-contain"}
                    color="#e0e6f2"
                    fgColor="#4d70b3"
                    round="5"
                  />
                </ExpandableAvatar>
              </div>
              <div>
                <p>{childrenItem.company_description ?? "-"}</p>
              </div>
              <div className={"flex justify-end mt-2 pt-2 border-t border-separator"}>
              <ButtonCardMain
                onClick={() => {
                  setInvestor(childrenItem);
                  navigate(`${RoutesLinks.COMPANY_GENERAL_INFORMATION_LINK}`);
                }}
                text={t("see_profile")}
                iconComponent={<FaEye className="inline-block w-5 h-4 mr-2" />}
              />
            </div>
            </Card>
          ))}
        </div>
      )}
    {showCompanyChildrenWizard && (
      <SidePanel title={t("create_subcompany")} width={"1/4"} onClose={() => setShowCompanyChildrenWizard(false)}>
        <CompanyChildrenWizard submitOk={handleChildrenCreated}/>
      </SidePanel>
    )}
    </>
  );
};

export default CompanyChildren;
