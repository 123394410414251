import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ConfirmRecoverPasswordForm from "./components/confirm-recover-password-form";
import { CoverBackground } from "components/ui/atoms/CoverBackground";
import { Container } from "components/ui/objects/Container";
import { useToasts } from "react-toast-notifications";
import { RoutesLinks } from "components/routes-links";
import AuthService from "services/auth-service";
import Lang from "lang";
import { Loader } from "components/ui/molecules/Loader";
import { useAuth } from "../../hooks/useAuth";
import useUser from "../../hooks/useUser";
import { useLoginEntries } from "../../hooks/useLoginEntries";
import { useDispatch } from "react-redux";
import RemindersOperations from "redux/reminders/reminders-operations";

const ConfirmRecoverPassword = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const { i18n, t } = useTranslation();
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const { token, setToken, setApiKey } = useAuth();
  const { setUserInfo } = useUser();
  const { setLoginEntries } = useLoginEntries();
  const [loading, setLoading] = useState(true);
  const queryParams = new URLSearchParams(location.search);
  const recoverCode = queryParams.get("code");

  const setTotalReminders = (total) => dispatch(RemindersOperations.setTotalReminders(total));

  const setReminders = (reminders) => dispatch(RemindersOperations.setReminders(reminders));

  useEffect(() => {
    const checkRestorePasswordCode = async() => {
      try {
        setLoading(true);
        await AuthService.checkRestorePasswordCode(recoverCode);
      } catch (error) {
        addToast(
          error.response && error.response.data.msg_key
            ? t(error.response.data.msg_key)
            : t("error_occurred"),
          {
            appearance: "error",
            autoDismiss: true,
          },
        );
        navigate(RoutesLinks.LOGIN_LINK);
      } finally {
        setLoading(false);
      }
    };

    if (recoverCode) {
      checkRestorePasswordCode();
    } else {
      addToast(t("invalid_code"), {
        appearance: "error",
        autoDismiss: true,
      });
      navigate(RoutesLinks.LOGIN_LINK);
    }
  }, [recoverCode]);

  const onSubmit = async({ new_password, password_confirmation }) => {
    try {
      setLoading(true);
      const result = await AuthService.restorePassword(recoverCode, new_password);
      Lang.setLang(result.data.data.user.preferences.language.code);
      await i18n.changeLanguage(result.data.data.user.preferences.language.code);

      setUserInfo(result.data.data.user);
      setToken(result.data.data.token);
      setApiKey(result.data.data.api_key);
      setLoginEntries(result.data.data.login_entries);
      if (result.data.data.reminders) {
        setReminders(result.data.data.reminders.reminders);
        setTotalReminders(result.data.data.reminders.total);
      }
    } catch (error) {
      console.error(error);
      addToast(
        error.response && error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t("error_occurred"),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
    } finally {
      setLoading(false);
    }
  };

  if (token) {
    return (
      <Navigate
        to={{
          pathname: RoutesLinks.CHECKS_LINK,
        }}
      />
    );
  } else {
    return (
      <Container variant="session">
        <Container>
          <ConfirmRecoverPasswordForm onSubmit={onSubmit} />
        </Container>
        {loading && <Loader />}
        <CoverBackground link={true} />
      </Container>
    );
  }
};

export default ConfirmRecoverPassword;
