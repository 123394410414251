import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
import { Loader } from "components/ui/molecules/Loader";
import { RoutesLinks } from "components/routes-links";
import { Pagination } from "components/ui/atoms/Pagination";

import Config from "config";
import ReactTooltip from "react-tooltip";
import useUser from "hooks/useUser";
import MembersService from "services/members-service";
import { MemberCard } from "components/ui/molecules/MemberCard";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { FaSearch, FaTrashAlt } from "react-icons/fa";
import { ButtonDanger } from "components/ui/atoms/ButtonDanger";

const MembersList = ({
  currentPage,
  setCurrentPage,
  filters,
  setFilters,
  setMember,
  reloadMembers,
  setReloadMembers,
  sendActivationCode,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalMembers, setTotalMembers] = useState(0);
  const [members, setMembers] = useState([]);
  const { userLanguage } = useUser();
  const [filterName, setFilterName] = useState();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  useEffect(() => {
    if (reloadMembers) {
      if (currentPage === 0) {
        getMembers();
      } else {
        setCurrentPage(0);
        setFilters({});
      }
      setReloadMembers(false);
    }
  }, [reloadMembers]);

  const getMembers = (name = null) => {
    setLoading(true);

    const params = {
      ...filters,
      name: name ?? filterName,
      rowsPerPage: pageSize,
      page: currentPage,
    };

    MembersService.getMembers(params)
      .then((response) => {
        const { members, pagination } = response.data.data;
        setMembers(members);

        if (pagination.current - 1 !== currentPage) {
          setCurrentPage(pagination.current);
        }

        if (pagination.total !== totalPages) {
          setTotalPages(pagination.pages);
        }

        if (pagination.totalDocs !== totalMembers) {
          setTotalMembers(pagination.totalDocs);
        }
      })
      .catch((error) => {
        console.error(error);
        addToast(t("error_occurred_retrieving_information"), {
          appearance: "error",
          autoDismiss: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getMembers();
  }, [currentPage, pageSize, filters]);

  const editMember = (member) => {
    setMember(member);
  };

  const handleClearFilterAndSubmit = async(e) => {
    e.preventDefault();
    setFilterName(""); // Ensuring the state is cleared
    getMembers(""); // Calling getDealflow with the cleared filter name
  };

  return (
    <>
      {loading && <Loader />}

      <div className="flex justify-between my-6 gap-4">
          <h3 className="text-xl">{t("you_have")}<strong className="mx-1">{totalMembers}</strong>{t("members")}</h3>
          <form className="flex gap-2" onSubmit={(e) => {
            e.preventDefault();
            getMembers();
          }}>
              <input
                className={
                  "shadow-soft-white border border-gray-lines focus:border-main bg-input w-full py-2 px-7 relative z-10 text-left text-xs font-normal rounded-2xl placeholder-gray cursor-pointer transition-all duration-200 outline-none hover:border-main hover:outline-none hover:shadow-inner focus:outline-none focus:shadow-focus active:outline-none"
                }

                placeholder={t("dealflow_example")}
                onChange={(e) => {
                  setFilterName(e.currentTarget.value);
                }}
                value={filterName}
              />
              <ButtonSecondary
                type="submit"
                iconComponent={<FaSearch className="h-4" />}
              />
              {filterName && (<ButtonDanger
                type="button"
                onClick={handleClearFilterAndSubmit}
                iconComponent={<FaTrashAlt className="h-4" />}
              />)}
            </form>
          </div>
      {members.length !== 0 && (
        <div className="">
          <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4">
            {members.map((member) => (
                <MemberCard
                  key={member._id}
                  member={member}
                  seeDetails={() => navigate(`${RoutesLinks.MEMBERS_LINK}${member._id}`)}
                  sendActivationCode={sendActivationCode}
                />
            ))}
          </div>
          <Pagination
            currentPage={currentPage}
            setCurrentPage={(page) => setCurrentPage(page)}
            perPage={pageSize}
            pages={totalPages}
            setPerPage={(value) => setPageSize(value[0].id)}
          />
        </div>
      )}
      {!loading && members.length === 0 && (
        <div className="">
          <img
            src={`${Config.GUIDE_STEPS_BACKGROUND_IMAGES_BUCKET}irm_${userLanguage}.png`}
            className="w-auto"
          />
        </div>
      )}
    </>
  );
};

export default MembersList;
