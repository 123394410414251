import { yupResolver } from "@hookform/resolvers/yup";
import { ButtonDanger } from "components/ui/atoms/ButtonDanger";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { Input } from "components/ui/atoms/Input";
import InputFile from "components/ui/atoms/input-files/InputFile";
import { TextArea } from "components/ui/atoms/TextArea";
import { Loader } from "components/ui/molecules/Loader";
import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaTrash } from "react-icons/fa";
import { useToasts } from "react-toast-notifications";
import AccountService from "services/account-service";
import FormUtils from "utils/form-utils";
import * as Yup from "yup";

const CompanyChildrenWizard = ({ submitOk }) => {
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, trigger, errors, setValue, getValues } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        company_name: Yup.string().required(t("required_field")),
        company_description: Yup.string().nullable(),
        logo: Yup.string().nullable(),
      }),
      { abortEarly: false },
    ),
    criteriaMode: "all",
    reValidateMode: "all",
    mode: "onChange",
  });

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, config);
    trigger(name);
  };

  const onSelectLogo = async(file) => {
    const fileSizeMb = (file?.size || 0) / 1024 / 1024;

    if (fileSizeMb > 5) {
      addToast(t("error_file_size", { max_size: 5 }), {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }

    setLoading(true);

    _setValue("logo", file ? await FormUtils.fileToBase64(file) : null, { shouldValidate: true });

    setTimeout(() => setLoading(false), 500);
  };

  const onInvalid = () => {
    addToast(t("form_field_error"), { appearance: "error", autoDismiss: true });
  };

  useEffect(() => {
    register("logo");
  }, [register]);

  const createCompanyChildren = async(data) => {
    const res = await AccountService.createInvestorChildren(data);
    submitOk();
  };

  //   useEffect(() => {
  //     if (user) {
  //       _setValue("company_name", user.investor.company_name);
  //       _setValue("company_description", user.investor.company_description);
  //       _setValue("logo", user.investor.logo, { shouldValidate: true });
  //     }
  //   }, [user]);

  return (
    <>
    {loading && <Loader />}
    <div className="fle flex-col gap-y-4">
        <div>
        {getValues().logo ? (
            <>
            <div className="text-xs font-medium text-black">{t("company_logo")}</div>
            <div className="flex flex-col justify-center mt-2">
                <Avatar
                src={getValues().logo}
                size="100"
                round={false}
                color="#e0e6f2"
                fgColor="#4d70b3"
                className="object-contain mx-auto my-0"
                />
                <ButtonDanger
                type="button"
                iconComponent={<FaTrash className="inline-block mr-2" />}
                text={t("remove")}
                onClick={() => onSelectLogo(null)}
                />
            </div>
            </>
        ) : (
            <div className="">
            <InputFile
                label={t("company_logo_label")}
                placeholder={t("company_logo_placeholder")}
                error={errors.logo}
                setSelectedFile={(file) => onSelectLogo(file)}
                accept="image/*"
                height="120px"
                padding="18px"
            />
            </div>
        )}
        </div>
        <div>
        <form
            className="flex flex-col w-full"
            onSubmit={handleSubmit(createCompanyChildren, onInvalid)}
        >
            <div className="mt-4">
            <Input
                reference={register}
                name="company_name"
                placeholder={t("company_name_placeholder")}
                label={t("company_name_label")}
                required={true}
                error={errors.company_name}
            />
            </div>
            <div className="mb-6">
            <TextArea
                reference={register}
                name="company_description"
                error={errors.company_description}
                label={t("company_description_label")}
                maxLength={200}
                placeholder={t("company_description_placeholder")}
            />
            </div>
            <div className="flex justify-end border-t border-separator">
            <ButtonMain width={"full md:w-1/2 mt-4"} text={t("save")} type={"submit"} />
            </div>
        </form>
        </div>
    </div>
  </>
  );
};

export default CompanyChildrenWizard;
