import { RadioButton } from "../atoms/RadioButton";
import Config from "config";

export function Likert({ id, name, disabled, value, onChange, setError, error, options }) {
  const widthClass =
    options.length === 3
      ? "w-1/2"
      : options.length === 5
        ? "w-1/4"
        : options.length === 7
          ? "w-1/6"
          : "w-auto";

  const _onChange = (index) => {
    onChange((index + 1).toString());

    if (setError) {
      setError(false);
    }
  };

  return (
    <div className="flex w-full justify-between mb-6">
      {options.map((item, index) => {
        return (
          <div className={index !== 0 ? widthClass : null} key={index}>
            <div className="flex items-center justify-end">
              <div className={index === 0 ? "hidden" : `h-px w-full top-1/4 left-3/4 bg-main`} />
              <div>
                <RadioButton
                  onChange={() => _onChange(index)}
                  disabled={disabled}
                  name={id ? `${id}-${index}` : index}
                  checked={(index + 1).toString() === value}
                  label={item.value}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
