import { DocumentList } from "components/documentation/documents/components/DocumentList";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import DocumentsService from "services/documents-service";

const ApplicationDocumentsView = () => {
  const [reloadDocuments, setReloadDocuments] = useState(false);
  const [documentToMigrate, setDocumentToMigrate] = useState();
  const [showMigrationSection, setShowMigrationSection] = useState(false);

  const { t } = useTranslation();

  const documentListRef = useRef();

  const { addToast } = useToasts();

  const { deal, ref } = useOutletContext();

  return (
        <DocumentList
        dealId={deal._id}
        ref={ref}
        setDocumentToMigrate={(document) => {
          setDocumentToMigrate(document);
          setShowMigrationSection(true);
        }}
        reloadDocuments={reloadDocuments}
        setReloadDocuments={setReloadDocuments}
      />
  );
};

export default ApplicationDocumentsView;
