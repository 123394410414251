import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { Card } from "components/ui/atoms/Card";
import Utils from "utils/utils";

import { FaExclamationTriangle } from "react-icons/fa";
import { AiOutlineReload } from "react-icons/ai";
import { BiLike } from "react-icons/bi";
import { CircularChard } from "components/ui/molecules/charts/CircularChart";
import moment from "moment";

import KiotaGammaEvaluationPannel from "components/kiota-gamma/KiotaGammaEvaluationPannel";
import ReactTooltip from "react-tooltip";
import { RobustnessComparisonItemGraph } from "./RobustnessComparisonItemGraph";
import { Alert } from "components/ui/molecules/Alert";
import { Loader } from "components/ui/molecules/Loader";
import IntegrationsService from "services/integrations-service";
import InvestmentStages from "assets/json/investment-stages.json";
import Sectors from "assets/json/sectors.json";
import Countries from "assets/json/countries.json";
import { useAuth } from "hooks/useAuth";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { useOutletContext } from "react-router-dom";
import KrainService from "services/krain-service";

export const KiotaGammaEvaluation = () => {
  const { deal, ref } = useOutletContext();

  const [evaluation, setEvaluation] = useState(null);

  const getKiotaGammaEvaluation = async() => {
    try {
      setLoading(true);
      const result = await KrainService.getKiotaGammaEvaluation(deal._id);
      if (result.data.data) {
        setEvaluation({
          general_error: result.data.data.general_error,
          growth_robustness_comparison_error: result.data.data.growth_robustness_comparison_error,
          survival_robustness_comparison_error:
            result.data.data.survival_robustness_comparison_error,
          pre_valuation_robustness_comparison_error:
            result.data.data.pre_valuation_robustness_comparison_error,
          general_result: result.data.data.general_result,
          growth_robustness_comparison: result.data.data.growth_robustness_comparison,
          survival_robustness_comparison: result.data.data.survival_robustness_comparison,
          pre_valuation_robustness_comparison: result.data.data.pre_valuation_robustness_comparison,
          createdAt: result.data.data.createdAt,
        });
      }
    } catch (error) {
      addToast(t("error_occurred_retrieving_information"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const { t } = useTranslation();
  const [showExecutionPannel, setShowExecutionPannel] = useState(false);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  const { apiKey } = useAuth();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const evaluateStartup = async(values) => {
    try {
      setLoading(true);
      const parsedValues = {
        ...values,
        dealflow: deal._id,
      };
      parsedValues.funding_type = InvestmentStages.find(
        (is) => is._id === parsedValues.funding_type,
      ).translation_en;
      parsedValues.industries = Sectors.find(
        (sector) => sector._id === parsedValues.industries,
      ).translation_en;

      if (!parsedValues.last_investment_round_announced_date) {
        parsedValues.last_investment_round_announced_date = moment().format("YYYY-MM-DD");
      }
      if (parsedValues.incorporation_country) {
        parsedValues.incorporation_country = Countries.find(
          (country) => country._id === parsedValues.incorporation_country,
        ).translation_en;
      } else {
        parsedValues.incorporation_country = "";
      }

      parsedValues.funding_rounds =
          parsedValues.funding_rounds !== "" ? parsedValues.funding_rounds : 0;
      parsedValues.last_investment_round_equity_only_funding =
          parsedValues.last_investment_round_equity_only_funding === "yes";
      parsedValues.last_investment_round_investors =
          parsedValues.last_investment_round_investors !== ""
            ? parsedValues.last_investment_round_investors
            : 0;
      parsedValues.last_investment_round_lead_investors =
          parsedValues.last_investment_round_lead_investors !== ""
            ? parsedValues.last_investment_round_lead_investors
            : 0;
      parsedValues.last_investment_round_money_raised =
          parsedValues.last_investment_round_money_raised !== ""
            ? parsedValues.last_investment_round_money_raised
            : 0;
      parsedValues.last_investment_round_partner_investors =
          parsedValues.last_investment_round_partner_investors !== ""
            ? parsedValues.last_investment_round_partner_investors
            : 0;
      parsedValues.last_investment_round_pre_money_valuation =
          parsedValues.last_investment_round_pre_money_valuation !== ""
            ? parsedValues.last_investment_round_pre_money_valuation
            : 0;
      parsedValues.total_funding_amount =
          parsedValues.total_funding_amount !== "" ? parsedValues.total_funding_amount : 0;

      await IntegrationsService.executeKiotaGammaEvaluation(parsedValues, apiKey);
      getKiotaGammaEvaluation();
    } catch (error) {
      console.error(error);
      addToast(t("error_occurred_evaluating_startup"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    showRequestEvaluationSection: () => setShowExecutionPannel(true),
  }));

  return (
      <>
        {loading && <Loader />}
        {showExecutionPannel && (
          <KiotaGammaEvaluationPannel
            onClose={() => {
              setShowExecutionPannel(false);
            }}
            expandedInformation={{
              organization_name: deal?.name || evaluation?.startup || "",
              industries: deal?.sector?._id,
              incorporation_country: deal?.incorporation_country?._id,
              funding_type: deal?.investment_stage?._id,
            }}
            dealflow={true}
            onSubmit={evaluateStartup}
          />
        )}
        <div className={"w-full flex flex-col my-2"}>
          {evaluation &&
            (!evaluation.general_result ||
              !evaluation.growth_robustness_comparison ||
              !evaluation.survival_robustness_comparison ||
              !evaluation.pre_valuation_robustness_comparison) && (
              <Alert style={"warning"} text={t("startup_under_analysis")} />
          )}
          <div className={"flex w-full px-4 mb-8"}></div>
          <div className="flex">
            <div className="flex flex-col items-center w-full px-2 border-r lg:w-1/2 border-gray-lines">
              <div className="flex justify-between w-full mb-2 text-lg font-semibold border-b border-gray-lines">
                <div className="flex items-center">
                  <span className={"text-main"}>{t("overview")}</span>
                  {(!evaluation || !evaluation?.general_result || evaluation.general_error) && (
                    <FaExclamationTriangle
                      className={`ml-2 ${!evaluation ? "text-main" : evaluation?.general_error ? "text-red" : "text-orange-400"}`}
                      data-tip={t(
                        !evaluation
                          ? "pending_evaluation"
                          : evaluation.general_error
                            ? "evaluation_could_not_be_completed"
                            : "pending_evaluation_result",
                      )}
                    />
                  )}
                </div>
                {evaluation && !evaluation?.general_result && (
                  <div data-tip={t("reload")}>
                    <ButtonMain
                      iconComponent={<AiOutlineReload />}
                      className="m-0 text-xs"
                      horizontal="1"
                      width="auto"
                      verticalMargin="1"
                      vertical="1"
                      marginRight="2"
                      onClick={getKiotaGammaEvaluation}
                    />
                  </div>
                )}
              </div>
              <div className="w-full text-center">
                <Card>
                  <div className="flex flex-col items-center justify-center">
                    <div className="rounded-full w-28 shadow-hover" id={"global-score"}>
                      <CircularChard
                        width="15"
                        fontSize="2xl"
                        value={
                          evaluation?.general_result
                            ? Math.round(evaluation.general_result.general_score * 100)
                            : 0
                        }
                        valueText={
                          evaluation?.general_result
                            ? `${Math.round(evaluation.general_result.general_score * 100)}%`
                            : "-"
                        }
                      />
                    </div>
                  </div>
                  <div className={"mt-2"}>{t("general_score")}</div>
                  <div className="flex flex-col items-center justify-center">
                    <div className="mt-4 rounded-full w-28 shadow-hover" id={"global-score"}>
                      <CircularChard
                        width="15"
                        fontSize="2xl"
                        value={
                          evaluation?.general_result
                            ? Math.round(evaluation.general_result.survival_score * 100)
                            : 0
                        }
                        valueText={
                          evaluation?.general_result
                            ? `${Math.round(evaluation.general_result.survival_score * 100)}%`
                            : "-"
                        }
                      />
                    </div>
                  </div>
                  <div className={"mt-2"}>{t("survival_score")}</div>
                  <div className="flex flex-col items-center justify-center">
                    <div className="mt-4 rounded-full w-28 shadow-hover" id={"growth-score"}>
                      <CircularChard
                        width="15"
                        fontSize="2xl"
                        value={
                          evaluation?.general_result
                            ? Math.round(evaluation.general_result.growth_score * 100)
                            : 0
                        }
                        valueText={
                          evaluation?.general_result
                            ? `${Math.round(evaluation.general_result.growth_score * 100)}%`
                            : "-"
                        }
                      />
                    </div>
                  </div>
                  <div className={"mt-2"}>{t("growth_score")}</div>
                  <div className={"mt-5 text-3xl"}>
                    {evaluation?.general_result
                      ? `${Utils.moneyFormatter(evaluation.general_result.pre_money_valuation)} $`
                      : "-"}
                  </div>
                  <div className={"mt-2"}>{t("pre_valuation")}</div>
                </Card>
              </div>
            </div>

            <div className="flex flex-col w-full px-2 lg:w-1/2">
              <div
                className={"font-semibold text-lg mb-2 w-full text-main border-b border-gray-lines flex justify-between"}
              >
                <div className="flex items-center">
                  {t("robustness_comparison")}
                  {(!evaluation ||
                    !evaluation?.growth_robustness_comparison ||
                    !evaluation.survival_robustness_comparison ||
                    !evaluation.pre_valuation_robustness_comparison ||
                    evaluation.growth_robustness_comparison_error ||
                    evaluation.survival_robustness_comparison_error ||
                    evaluation.pre_valuatio_robustness_comparison_error) && (
                    <FaExclamationTriangle
                      className={`ml-2 ${!evaluation ? "text-main" : evaluation?.growth_robustness_comparison_error || evaluation?.survival_robustness_comparison_error || evaluation?.pre_valuation_robustness_comparison_error ? "text-red" : "text-orange-400"}`}
                      data-tip={t(
                        !evaluation
                          ? "pending_evaluation"
                          : evaluation.general_error
                            ? "evaluation_could_not_be_completed"
                            : "pending_evaluation_result",
                      )}
                    />
                  )}
                </div>
                <div>
                  {evaluation &&
                    (!evaluation?.growth_robustness_comparison ||
                      !evaluation.survival_robustness_comparison ||
                      !evaluation.pre_valuation_robustness_comparison) && (
                      <div data-tip={t("reload")}>
                        <ButtonMain
                          iconComponent={<AiOutlineReload />}
                          className="m-0 text-xs"
                          horizontal="1"
                          width="auto"
                          verticalMargin="1"
                          vertical="1"
                          marginRight="2"
                          onClick={getKiotaGammaEvaluation}
                        />
                      </div>
                  )}
                </div>
              </div>
              <div className="mx-4">
                <div className="my-4">
                  <Card>
                    <RobustnessComparisonItemGraph
                      title={t("survival_score")}
                      description={t("survival_prediction_description")}
                      group={"survival"}
                      dataToPlot={evaluation?.survival_robustness_comparison}
                      hasEvaluation={!!evaluation}
                      error={evaluation?.survival_robustness_comparison_error}
                    />
                  </Card>
                </div>
                <div className="my-4">
                  <Card>
                    <RobustnessComparisonItemGraph
                      title={t("growth_score")}
                      description={t("growth_prediction_description")}
                      group={"growth"}
                      dataToPlot={evaluation?.growth_robustness_comparison}
                      hasEvaluation={!!evaluation}
                      error={evaluation?.growth_robustness_comparison_error}
                    />
                  </Card>
                </div>
                <div className="my-4">
                  <Card>
                    <RobustnessComparisonItemGraph
                      title={t("pre_money_valuation")}
                      description={t("pre_valuation_prediction_description")}
                      group={"pre_valuation"}
                      dataToPlot={evaluation?.pre_valuation_robustness_comparison}
                      hasEvaluation={!!evaluation}
                      error={evaluation?.pre_valuation_robustness_comparison_error}
                    />
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  );
};
