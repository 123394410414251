/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { Investments } from "../components/Investments";
import { PortfolioStartups } from "../components/PortfolioStartups";
import { FaFilter, FaPlus, FaSearch } from "react-icons/fa";
import { useToasts } from "react-toast-notifications";
// import { DealflowSearcher } from '../../dealflow/DealflowSearcher'
// import { DealflowSearcherPortfolio } from '../components/DealflowSearcher'
import { StatisticsService } from "services/statistics-service";
import { useSwal } from "hooks/useSwal";
import { DealflowService } from "services/dealflow-service";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { PageTitle } from "components/ui/atoms/PageTitle";
import { DealflowSearcherPortfolio } from "../components/DealflowSearcher";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { SectionHeader } from "components/ui/molecules/SectionHeader";

export const Portfolio = () => {
  const { t } = useTranslation();
  const [showInvestmentSection, setShowInvestmentSection] = useState(false);
  const { addToast } = useToasts();
  const [startupsStatistics, setStartupsStatistics] = useState({});
  const [showDealSection, setShowDealSection] = useState(false);
  const { confirm } = useSwal();

  const [filters, setFilters] = useState({});
  const [showInvestmentsFilters, setShowInvestmentsFilters] = useState(false);
  const [showStartUpsFilter, setShowStartUpsFilter] = useState(false);

  const [showSearcher, setShowSearcher] = useState(false);
  const [showAddStartUp, setShowAddStartUp] = useState(false);
  const [loading, setLoading] = useState(true);
  const [startupsList, setStartupsList] = useState([]);

  const SECTIONS = ["investments", "startups"];

  const components = {
    investments: (
      <Investments
        showSearcher={showSearcher}
        setShowSearcher={setShowSearcher}
        startups={startupsList}
        showInvestmentSection={showInvestmentSection}
        showInvestmentsFilters={showInvestmentsFilters}
        setShowInvestmentsFilters={setShowInvestmentsFilters}
        setShowInvestmentSection={setShowInvestmentSection}
      />
    ),
    startups: (
      <PortfolioStartups
        statistics={startupsStatistics}
        startUpFilters={filters}
        showAddStartUp={showAddStartUp}
        showStarUpsFilter={showStartUpsFilter}
        setShowStarUpsFilter={setShowStartUpsFilter}
        setSection={(sec) => setSection(sec)}
      />
    ),
  };

  const [section, setSection] = useState("investments");

  const getPortfolioDealflow = async () => {
    try {
      setLoading(true);
      const response = await DealflowService.getDealflow({
        rowsPerPage: 999999,
        pool: "false",
        populate: "false",
        boards: "false",
        milestones: "false",
        files: "false",
        reminders: "false",
        status: "portfolio",
      });
      setStartupsList(response.data.data.dealflow);
    } catch (error) {
      console.error("Error getPortfolio dealflow", error);
      setLoading(false);
    } finally {
      setLoading(true);
    }
  };

  useEffect(() => {
    getPortfolioDealflow();
  }, []);

  const getPortfolioDealflowStatistics = async () => {
    try {
      const response = await StatisticsService.getPortfolioStats();
      setStartupsStatistics(response.data.data);
    } catch (error) {
      console.error(error);
      addToast(t("error_occurred_retrieving_statistics"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPortfolioDealflowStatistics();
  }, []);

  return (
    <>
      {/* {loading && <Loader />} */}
      <div className="flex flex-wrap justify-between">
        <PageTitle title={t("portfolio")} />
        <div className="flex gap-2">
          <div>
            {section === "investments" && (
              <ButtonMain
                marginLeft={2}
                iconComponent={<FaPlus className="inline-block mr-2" />}
                text={t("add_investment")}
                onClick={() => setShowInvestmentSection(true)}
              />
            )}
            {section === "startups" && (
              <ButtonMain
                marginLeft={2}
                iconComponent={<FaPlus className="inline-block mr-2" />}
                text={t("add_startup")}
                onClick={() => setShowAddStartUp(!showAddStartUp)}
              />
            )}
          </div>
          <div>
            {section === "investments" && (
              <ButtonSecondary
                iconComponent={<FaSearch className="h-4" />}
                onClick={() => {
                  setShowSearcher(true);
                }}
              />
            )}
          </div>
          <div>
            <ButtonSecondary
              iconComponent={<FaFilter className="h-4" />}
              onClick={() => {
                if (section === "startups") {
                  setShowStartUpsFilter(true);
                } else {
                  setShowInvestmentsFilters(true);
                }
              }}
            />
          </div>
        </div>
      </div>
      <SectionHeader
        sectionTitles={SECTIONS.map((sec) => t(sec))}
        sectionKeys={SECTIONS}
        section={section}
        setSection={(sec) => {
          setSection(sec);
        }}
      />
      <div className="flex flex-col w-full my-6">{components[section]}</div>

      {showDealSection && (
        <DealflowSearcherPortfolio
          onClose={() => setShowDealSection(false)}
          onFilter={(values) => {
            setFilters(values);
          }}
          filters={filters}
          status={status}
        />
      )}
    </>
  );
};
