import { useSelector } from "react-redux";

export function CoverBackground({ link = "false" }) {
  // const logo = require('../../../assets/images/logo/logoeoniq.svg').default

  const platform = useSelector((state) => state.app.platform);

  return (
    <div className={"hidden lg:block bg-main "}>
      <div
        style={{ height: "calc(100vh - 5rem)" }}
        className="px-5 flex justify-center items-center"
      >
        <div>
          <img src={platform.logo_cover_background} alt={platform.name} className="image-logo" />
        </div>
      </div>
      {link && (
        <div>
          <div
            className={"w-full flex justify-center items-center relative bottom-16 xl:bottom-42"}
          >
            <a
              href={platform.main_url_link}
              target="_blank"
              rel="noreferrer"
              className={
                "absolute translate-y-1/2 bg-white px-5 py-1 text-xs font-medium rounded-md cursor-pointer text-main hover:text-link-hover"
              }
            >
              {platform.main_url_preview_text}
            </a>
            <hr className="w-8/12 h-full  text-white"></hr>
          </div>
        </div>
      )}
    </div>
  );
}
