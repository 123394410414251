import { useDispatch, useSelector } from "react-redux";
import UserOperations from "../redux/user/user-operations";

export const useLoginEntries = () => {
  const dispatch = useDispatch();
  const loginEntries = useSelector((state) => state.loginEntries.data);
  const setLoginEntries = (loginEntries) => dispatch(UserOperations.setLoginEntries(loginEntries));

  return { loginEntries, setLoginEntries };
};
