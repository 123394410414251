import React, { useState, useEffect } from "react";
import { RoutesLinks } from "components/routes-links";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { Loader } from "components/ui/molecules/Loader";
import { Card } from "components/ui/atoms/Card";
import Arrow from "styles/images/arrow_right_square.svg";
import ArrowRight from "styles/images/arrow_right_blue.svg";
import { useToasts } from "react-toast-notifications";

import moment from "moment";
import LineChart from "components/ui/molecules/charts/LineChart";
import Lang from "lang";
import {
  FaArrowRight,
  FaCalendarWeek,
  FaRocket,
  FaCalendar,
  FaSeedling,
  FaChartLine,
  FaFileAlt,
} from "react-icons/fa";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import Avatar from "react-avatar";
import ReactTooltip from "react-tooltip";
import { PieChart } from "components/ui/molecules/charts/PieChart";
import { Spinner } from "components/ui/atoms/Spinner";
import Utils from "utils/utils";
import { PageTitle } from "components/ui/atoms/PageTitle";
import Config from "config";
import { ResponsiveChoropleth } from "@nivo/geo";
import { DealflowService } from "services/dealflow-service";
import GraphSvg from "../../../../assets/images/graph.svg";
import { StatisticsService } from "services/statistics-service";
import useUser from "hooks/useUser";
import useCurrency from "hooks/useCurrency";

const InvestorScope = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const [totalStartups, setTotalStartups] = useState(0);
  const [latestStartups, setLatestStartups] = useState([]);
  const [events, setEvents] = useState([]);
  const [statistics, setStatistics] = useState({});
  const [totalEvents, setTotalEvents] = useState(0);
  const [dealflow, setDealflow] = useState([]);
  const [eventStartups, setEventStartups] = useState([]);
  const [poolStats, setPoolStats] = useState(null);
  const [poolRejectedStats, setPoolRejectedStats] = useState(null);
  const [poolFailedStats, setPoolFailedStats] = useState(null);

  const { currency } = useCurrency();

  const [investmentStats, setInvestmentStats] = useState(null);
  const [investmentDistributionStats, setInvestmentDistributionStats] = useState(null);

  const { user, checkIfLPhasAccessToSection } = useUser();

  const getPoolStats = async() => {
    try {
      setLoading(true);
      const result = await StatisticsService.getDealflowStats();
      setPoolStats(result.data.data.pool);
    } catch (error) {
      console.error(error);
      addToast(t("error_occurred_retrieving_statistics"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const getPoolRejectedStats = async() => {
    try {
      setLoading(true);
      const result = await StatisticsService.getRejectedStats();
      setPoolRejectedStats(result.data.data);
    } catch (error) {
      console.error(error);
      addToast(t("error_occurred_retrieving_statistics"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const getPoolFailedStats = async() => {
    try {
      setLoading(true);
      const result = await StatisticsService.getFailedStats();
      setPoolFailedStats(result.data.data.failed);
    } catch (error) {
      console.error(error);
      addToast(t("error_occurred_retrieving_statistics"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const getInvestmentStats = async() => {
    try {
      setLoading(true);
      const result = await StatisticsService.getInvestmentStats();
      setInvestmentStats(result.data.data);
    } catch (error) {
      console.error(error);
      addToast(t("error_occurred_retrieving_statistics"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const getInvestmentDistributionStats = async() => {
    try {
      setLoading(true);
      const result = await StatisticsService.getInvestmentDistributionStats();
      setInvestmentDistributionStats(result.data.data.investmentsDistribution);
    } catch (error) {
      console.error(error);
      addToast(t("error_occurred_retrieving_statistics"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!user.lp || (user.lp && checkIfLPhasAccessToSection("dashboard"))) {
      getPoolStats();
      getPoolRejectedStats();
      getInvestmentStats();
      getPoolFailedStats();
      getInvestmentDistributionStats();
    }
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [events, totalEvents]);

  return (
    <>
      <PageTitle title={t("dashboard")} />
      {(!user.lp || (user.lp && checkIfLPhasAccessToSection("dashboard"))) && (
        <>
          <div className={"mt-8 text-2xl font-semibold text-secondary"}>
            {!poolStats?.total
              ? t("no_startups_in_pool")
              : t("current_startups_in_pool", { startups: poolStats?.total })}
          </div>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 2xl:grid-cols-4 my-6">
            <Card>
              <div className="h-96">
                <div className={"text-main font-semibold text-center text-xl"}>{t("status")}</div>
                {!poolStats && (
                  <div className="h-full flex justify-center items-center">
                    <Spinner />
                  </div>
                )}
                {poolStats && (
                  <>
                    {poolStats.total > 0 && (
                      <PieChart
                        linkLabels={true}
                        legend={false}
                        data={poolStats.status.map((group) => ({ id: t(group.id), value: group.value }))}
                      />
                    )}
                    {!poolStats?.total > 0 && (
                      <NoDataInfo
                        backgroundImage={GraphSvg}
                        title={t("no_data_registered")}
                        showExtraTextOnHover={true}
                        textOne={t(!poolStats?.total ? "no_data_in_pool" : "data_missed_in_pool")}
                      />
                    )}
                  </>
                )}
              </div>
            </Card>
            <Card>
              <div className="h-96">
                <div className={"text-main font-semibold text-center text-xl"}>{t("sectors")}</div>
                {!poolStats && (
                  <div className="h-full flex justify-center items-center">
                    <Spinner />
                  </div>
                )}
                {!poolStats && (
                  <div className="h-full flex justify-center items-center">
                    <Spinner />
                  </div>
                )}
                {poolStats && (
                  <>
                    {poolStats.sectors?.length > 0 && (
                      <PieChart
                        linkLabels={true}
                        legend={false}
                        data={poolStats.sectors?.map((group) => ({ id: t(group.id), value: group.value }))}
                      />
                    )}
                    {!poolStats?.sectors?.length > 0 && (
                      <NoDataInfo
                        backgroundImage={GraphSvg}
                        title={t("no_data_registered")}
                        showExtraTextOnHover={true}
                        textOne={t(!poolStats?.total ? "no_data_in_pool" : "data_missed_in_pool")}
                      />
                    )}
                  </>
                )}
              </div>
            </Card>
            <Card>
              <div className="h-96">
                <div className={"text-main font-semibold text-center text-xl"}>
                  {t("project_stage")}
                </div>
                {!statistics && (
                  <div className="h-full flex justify-center items-center">
                    <Spinner />
                  </div>
                )}
                {!poolStats && (
                  <div className="h-full flex justify-center items-center">
                    <Spinner />
                  </div>
                )}
                {poolStats && (
                  <>
                    {poolStats.project_stages?.length > 0 && (
                      <PieChart
                        linkLabels={true}
                        legend={false}
                        data={poolStats.project_stages?.map((group) => ({ id: t(group.id), value: group.value }))}
                      />
                    )}
                    {!poolStats?.project_stages?.length > 0 && (
                      <NoDataInfo
                        backgroundImage={GraphSvg}
                        title={t("no_data_registered")}
                        showExtraTextOnHover={true}
                        textOne={t(!poolStats?.total ? "no_data_in_pool" : "data_missed_in_pool")}
                      />
                    )}
                  </>
                )}
              </div>
            </Card>
            <Card>
              <div className="h-96">
                <div className={"text-main font-semibold text-center text-xl"}>
                  {t("investment_stage")}
                </div>
                {!poolStats && (
                  <div className="h-full flex justify-center items-center">
                    <Spinner />
                  </div>
                )}
                {poolStats && (
                  <>
                    {poolStats.investment_stages?.length > 0 && (
                      <PieChart
                        linkLabels={true}
                        legend={false}
                        data={poolStats.investment_stages?.map((group) => ({ id: t(group.id), value: group.value }))}
                      />
                    )}
                    {!poolStats?.investment_stages?.length > 0 && (
                      <NoDataInfo
                        backgroundImage={GraphSvg}
                        title={t("no_data_registered")}
                        showExtraTextOnHover={true}
                        textOne={t(!poolStats?.total ? "no_data_in_pool" : "data_missed_in_pool")}
                      />
                    )}
                  </>
                )}
              </div>
            </Card>
          </div>

          {poolRejectedStats?.total !== 0 && (
            <>
              <div className={"mt-16 text-2xl font-semibold text-secondary"}>
                {!poolRejectedStats?.total
                  ? t("no_startups_rejected")
                  : t("current_rejected_startups", { startups: poolRejectedStats?.total })}
              </div>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3 my-6">
                <Card>
                  <div className="h-96">
                    <div className={"text-main font-semibold text-center text-xl"}>
                      {t("sectors")}
                    </div>
                    {!poolRejectedStats && (
                      <div className="h-full flex justify-center items-center">
                        <Spinner />
                      </div>
                    )}
                    {poolRejectedStats?.sectors?.length > 0 && (
                      <PieChart
                        legend={false}
                        data={poolRejectedStats.sectors?.map((group) => ({ id: t(group.id), value: group.value }))}
                      />
                    )}
                    {!poolRejectedStats?.sectors?.length > 0 && (
                      <NoDataInfo
                        backgroundImage={GraphSvg}
                        title={t("no_data_registered")}
                        showExtraTextOnHover={true}
                        textOne={t(
                          !poolRejectedStats?.total ? "no_data_in_pool" : "data_missed_in_pool",
                        )}
                      />
                    )}
                  </div>
                </Card>
                <Card>
                  <div className="h-96">
                    <div className={"text-main font-semibold text-center text-xl"}>
                      {t("project_stage")}
                    </div>
                    {!poolRejectedStats && (
                      <div className="h-full flex justify-center items-center">
                        <Spinner />
                      </div>
                    )}
                    {poolRejectedStats?.project_stages?.length > 0 && (
                      <PieChart
                        legend={false}
                        data={poolRejectedStats.project_stages?.map((group) => ({ id: t(group.id), value: group.value }))}
                      />
                    )}
                    {!poolRejectedStats?.project_stages?.length > 0 && (
                      <NoDataInfo
                        backgroundImage={GraphSvg}
                        title={t("no_data_registered")}
                        showExtraTextOnHover={true}
                        textOne={t(
                          !poolRejectedStats?.total ? "no_data_in_pool" : "data_missed_in_pool",
                        )}
                      />
                    )}
                  </div>
                </Card>
                <Card>
                  <div className="h-96">
                    <div className={"text-main font-semibold text-center text-xl"}>
                      {t("investment_stage")}
                    </div>
                    {!poolRejectedStats && (
                      <div className="h-full flex justify-center items-center">
                        <Spinner />
                      </div>
                    )}
                    {poolRejectedStats?.investment_stages?.length > 0 && (
                      <PieChart
                        legend={false}
                        data={poolRejectedStats.investment_stages?.map((group) => ({ id: t(group.id), value: group.value }))}
                      />
                    )}
                    {!poolRejectedStats?.investment_stages?.length > 0 && (
                      <NoDataInfo
                        backgroundImage={GraphSvg}
                        title={t("no_data_registered")}
                        showExtraTextOnHover={true}
                        textOne={t(
                          !poolRejectedStats?.total ? "no_data_in_pool" : "data_missed_in_pool",
                        )}
                      />
                    )}
                  </div>
                </Card>
              </div>
              {poolRejectedStats?.total !== 0 && (
                <>
                  <div className={"mt-2 text-xl font-semibold text-secondary"}>
                    {t("main_rejection_reasons")}
                  </div>
                  <div className="my-6">
                    <Card>
                      <div className="h-80">
                        {!poolRejectedStats && (
                          <div className="h-full flex justify-center items-center">
                            <Spinner />
                          </div>
                        )}
                        {poolRejectedStats?.rejection_reasons?.length > 0 && (
                          <PieChart
                            legend={false}
                            data={poolRejectedStats.rejection_reasons?.map((group) => ({ id: t(group.id), value: group.value }))}
                          />
                        )}
                        {!poolRejectedStats?.rejection_reasons?.length > 0 && (
                          <NoDataInfo backgroundImage={GraphSvg} title={t("no_data_registered")} />
                        )}
                      </div>
                    </Card>
                  </div>
                </>
              )}
            </>
          )}
          {poolFailedStats?.total !== 0 && (
            <>
              <div className={"mt-2 text-xl font-semibold text-secondary"}>
                {t("current_failed_startups", { startups: poolFailedStats?.total })}
              </div>
              <div className="my-6">
                <Card>
                  <div className="h-80">
                    {!poolFailedStats && (
                      <div className="h-full flex justify-center items-center">
                        <Spinner />
                      </div>
                    )}
                    {poolFailedStats?.failure_reasons?.length > 0 && (
                      <PieChart
                        legend={false}
                        data={poolFailedStats.failure_reasons?.map((group) => ({ id: t(group.id), value: group.value }))}
                      />
                    )}
                    {!poolFailedStats?.failure_reasons?.length > 0 && (
                      <NoDataInfo backgroundImage={GraphSvg} title={t("no_data_registered")} />
                    )}
                  </div>
                </Card>
              </div>
            </>
          )}

          {investmentStats?.total !== 0 && (
            <>
              <div className={"mt-8 text-2xl font-semibold text-secondary"}>
                {t("current_investments", {
                  startups: investmentStats?.total,
                  amount: investmentStats?.totalInvested,
                  currency,
                })}
              </div>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3 my-6">
                <Card>
                  <div className="h-96">
                    <div className={"text-main font-semibold text-center text-xl"}>
                      {t("sectors")}
                    </div>
                    {!investmentStats && (
                      <div className="h-full flex justify-center items-center">
                        <Spinner />
                      </div>
                    )}
                    {investmentStats?.sectors?.length > 0 && (
                      <PieChart
                        legend={false}
                        data={investmentStats.sectors?.map((group) => ({ id: t(group.id), value: group.value }))}
                      />
                    )}
                    {!investmentStats?.sectors?.length > 0 && (
                      <NoDataInfo backgroundImage={GraphSvg} title={t("no_data_registered")} />
                    )}
                  </div>
                </Card>
                <Card>
                  <div className="h-96">
                    <div className={"text-main font-semibold text-center text-xl"}>
                      {t("project_stage")}
                    </div>
                    {!investmentStats && (
                      <div className="h-full flex justify-center items-center">
                        <Spinner />
                      </div>
                    )}
                    {investmentStats?.project_stages?.length > 0 && (
                      <PieChart
                        legend={false}
                        data={investmentStats.project_stages?.map((group) => ({ id: t(group.id), value: group.value }))}
                      />
                    )}
                    {!investmentStats?.project_stages?.length > 0 && (
                      <NoDataInfo backgroundImage={GraphSvg} title={t("no_data_registered")} />
                    )}
                  </div>
                </Card>
                <Card>
                  <div className="h-96">
                    <div className={"text-main font-semibold text-center text-xl"}>
                      {t("investment_stage")}
                    </div>
                    {!investmentStats && (
                      <div className="h-full flex justify-center items-center">
                        <Spinner />
                      </div>
                    )}
                    {investmentStats?.investment_stages?.length > 0 && (
                      <PieChart
                        legend={false}
                        data={investmentStats.investment_stages?.map((group) => ({ id: t(group.id), value: group.value }))}
                      />
                    )}
                    {!investmentStats?.investment_stages?.length > 0 && (
                      <NoDataInfo backgroundImage={GraphSvg} title={t("no_data_registered")} />
                    )}
                  </div>
                </Card>
              </div>
              <div className={"mt-8 text-2xl font-semibold text-secondary"}>
                {t("current_investments_distribuition")}
              </div>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3 my-6">
                <Card>
                  <div className="h-96">
                    <div className={"text-main font-semibold text-center text-xl"}>
                      {t("sectors")}
                    </div>
                    {!investmentDistributionStats && (
                      <div className="h-full flex justify-center items-center">
                        <Spinner />
                      </div>
                    )}
                    {investmentDistributionStats?.sectors?.length > 0 && (
                      <PieChart
                        legend={false}
                        data={investmentDistributionStats.sectors?.map((group) => ({ id: t(group.id), value: group.value }))}
                      />
                    )}
                    {!investmentDistributionStats?.sectors?.length > 0 && (
                      <NoDataInfo backgroundImage={GraphSvg} title={t("no_data_registered")} />
                    )}
                  </div>
                </Card>
                <Card>
                  <div className="h-96">
                    <div className={"text-main font-semibold text-center text-xl"}>
                      {t("project_stage")}
                    </div>
                    {!investmentDistributionStats && (
                      <div className="h-full flex justify-center items-center">
                        <Spinner />
                      </div>
                    )}
                    {investmentDistributionStats?.project_stages?.length > 0 && (
                      <PieChart
                        legend={false}
                        data={investmentDistributionStats.project_stages?.map((group) => ({ id: t(group.id), value: group.value }))}
                      />
                    )}
                    {!investmentDistributionStats?.project_stages?.length > 0 && (
                      <NoDataInfo backgroundImage={GraphSvg} title={t("no_data_registered")} />
                    )}
                  </div>
                </Card>
                <Card>
                  <div className="h-96">
                    <div className={"text-main font-semibold text-center text-xl"}>
                      {t("investment_stage")}
                    </div>
                    {!investmentDistributionStats && (
                      <div className="h-full flex justify-center items-center">
                        <Spinner />
                      </div>
                    )}
                    {investmentDistributionStats?.investment_stages?.length > 0 && (
                      <PieChart
                        legend={false}
                        data={investmentDistributionStats.investment_stages?.map((group) => ({ id: t(group.id), value: group.value }))}
                      />
                    )}
                    {!investmentDistributionStats?.investment_stages?.length > 0 && (
                      <NoDataInfo backgroundImage={GraphSvg} title={t("no_data_registered")} />
                    )}
                  </div>
                </Card>
              </div>
            </>
          )}
          {investmentDistributionStats?.higher_shares.length > 0 && (
            <>
              <div className={"mt-2 text-xl font-semibold text-secondary"}>
                Siendo las startups con mayor participación...
              </div>
              <div className="my-6">
                <Card>
                  {!!investmentDistributionStats?.higher_shares.length > 0 && (
                    <div className="w-full flex flex-col justify-center h-full mt-2 mb-8">
                      {investmentDistributionStats?.higher_shares.map((startup, index) => (
                        <div className="my-2 flex flex-row" key={index}>
                          <div className="flex items-center">
                            <div className="mr-2">
                              <Avatar
                                src={startup.dealflowLogo}
                                size="50"
                                round={true}
                                color="#e0e6f2"
                                fgColor="#4d70b3"
                                alt={startup.dealflowName || t("startup_name_not_provided")}
                              />
                            </div>
                            <div>
                              <h4 className="font-semibold">
                                {startup.dealflowName || t("startup_name_not_provided")}
                              </h4>
                              <span className="text-xxs capitalize">{startup.totalShares}%</span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </Card>
              </div>
            </>
          )}
        </>
      )}
      {user.lp && !checkIfLPhasAccessToSection("dashboard") && (
        <div className="my-6">
          <NoDataInfo title={t("access_forbidden")} />
        </div>
      )}
    </>
  );
};

export default InvestorScope;
