import axios from "axios/index";
import Config from "../config";

const DocumentsService = {
  getDocuments: async(params) => {
    let query = "";
    if (params.sortBy) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}sortBy=${params.sortBy}`;
    }

    if (params.hasOwnProperty("page")) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}page=${params.page}`;
    }

    if (params.rowsPerPage) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}rowsPerPage=${params.rowsPerPage}`;
    }

    if (params.startup) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}startup=${params.startup}`;
    }

    if (params.parent_folder) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}parent_folder=${params.parent_folder}`;
    }

    if (params.event_startup) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}event_startup=${params.event_startup}`;
    }

    if (params.name) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}name=${params.name}`;
    }

    if (params.tags) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}tags=${params.tags}`;
    }

    if (params.category) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}category=${params.category}`;
    }

    return axios.get(`${Config.DOCUMENTS_API_URL}${query}`);
  },

  getFolders: async(params, parentFolder) => {
    let query = "";

    if (params.sortBy) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}sortBy=${params.sortBy}`;
    }

    if (params.hasOwnProperty("page")) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}page=${params.page}`;
    }

    if (params.rowsPerPage) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}rowsPerPage=${params.rowsPerPage}`;
    }

    if (params.startup) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}startup=${params.startup}`;
    }

    if (params.parent_folder) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}parent_folder=${params.parent_folder}`;
    }

    if (params.event_startup) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}event_startup=${params.event_startup}`;
    }

    if (params.name) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}name=${params.name}`;
    }

    if (params.tags) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}tags=${params.tags}`;
    }

    if (params.category) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}category=${params.category}`;
    }

    if (parentFolder) {
      query = `${query}${query ? "&" : "?"}`;
      query = `?parentFolder=${parentFolder}`;
    }

    return await axios.get(`${Config.DOCUMENTS_API_URL}folders${query}`);
  },

  getDocument: async(documentId) =>
    await axios.get(`${Config.DOCUMENTS_API_URL}documents/${documentId}`),

  getDocumentPreview: async(documentId) =>
    await axios.get(`${Config.DOCUMENTS_API_URL}documents/${documentId}/preview`),

  uploadDocument: async(data) =>
    await axios.post(`${Config.DOCUMENTS_API_URL}documents/`, data, {
      headers: {
        charset: "utf-8",
      },
    }),

  deleteDocument: async(documentId) =>
    await axios.delete(`${Config.DOCUMENTS_API_URL}documents/${documentId}`),

  updateDocument: async(documentId, data) =>
    await axios.patch(`${Config.DOCUMENTS_API_URL}documents/${documentId}`, data),

  uploadLink: async(data) => await axios.post(`${Config.DOCUMENTS_API_URL}links/`, data),

  deleteLink: async(linkId) => await axios.delete(`${Config.DOCUMENTS_API_URL}links/${linkId}`),

  updateLink: async(linkId, data) =>
    await axios.patch(`${Config.DOCUMENTS_API_URL}links/${linkId}`, data),

  saveDocumentForForm: async(data) =>
    await axios.post(`${Config.DOCUMENTS_API_URL}forms/documents`, data),

  saveImageSelectorOption: async(data) =>
    await axios.post(`${Config.DOCUMENTS_API_URL}forms/image-selector-option`, data),
  saveSeparatorImage: async(data) =>
    await axios.post(`${Config.DOCUMENTS_API_URL}forms/separator-image`, data),

  getDocumentForForm: async(documentId) =>
    await axios.get(`${Config.DOCUMENTS_API_URL}forms/documents/${documentId}`),

  saveDocumentInScoreCard: async(data) =>
    await axios.post(`${Config.DOCUMENTS_API_URL}score-cards/documents`, data),

  getDocumentForScoreCard: async(documentId) =>
    await axios.get(`${Config.DOCUMENTS_API_URL}score-cards/documents/${documentId}`),

  getSharedDocument: async(documentId) =>
    await axios.get(`${Config.DOCUMENTS_API_URL}shared/documents/${documentId}`),

  getSharedDocumentForForm: async(documentId) =>
    await axios.get(`${Config.DOCUMENTS_API_URL}shared/forms/documents/${documentId}`),

  getSharedDocuments: async(params) => {
    let query = "";

    if (params.sortBy) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}sortBy=${params.sortBy}`;
    }

    if (params.hasOwnProperty("page")) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}page=${params.page}`;
    }

    if (params.rowsPerPage) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}rowsPerPage=${params.rowsPerPage}`;
    }

    if (params.startup) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}startup=${params.startup}`;
    }

    if (params.event_startup) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}event_startup=${params.event_startup}`;
    }

    if (params.name) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}name=${params.name}`;
    }

    if (params.tags) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}tags=${params.tags}`;
    }

    if (params.category) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}category=${params.category}`;
    }

    return axios.get(`${Config.DOCUMENTS_API_URL}shared/documents${query}`);
  },

  migrateDocument: async(documentId, dealId) =>
    await axios.post(`${Config.DOCUMENTS_API_URL}documents/${documentId}/migrate`, {
      deal: dealId,
    }),

  createFolder: async(data) => await axios.post(`${Config.DOCUMENTS_API_URL}folders/`, data),

  deleteFolder: async(folderId) =>
    await axios.delete(`${Config.DOCUMENTS_API_URL}folders/${folderId}`),

  updateFolder: async(folderId, data) =>
    await axios.patch(`${Config.DOCUMENTS_API_URL}folders/${folderId}`, data),

  getFolder: async(params) => {
    let query = "";
    if (params.sortBy) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}sortBy=${params.sortBy}`;
    }

    if (params.hasOwnProperty("page")) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}page=${params.page}`;
    }

    if (params.rowsPerPage) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}rowsPerPage=${params.rowsPerPage}`;
    }

    return axios.get(`${Config.DOCUMENTS_API_URL}${query}`);
  },
};
export default DocumentsService;
