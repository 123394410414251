import { useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import FormsService from "../../services/forms-service";
import { Pagination } from "components/ui/atoms/Pagination";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";

import { Card } from "components/ui/atoms/Card";
import { useSwal } from "hooks/useSwal";
import { Question } from "components/qe-questions/components/Question";
import { ImportQuestionsModal } from "components/ui/molecules/ImportQuestionsModal";
import useUser from "../../hooks/useUser";
import { FormQuestionsFilters } from "./components/FormQuestionsFilters";
import { Modal } from "components/ui/molecules/Modal";
import TranslateUtils from "utils/translate-utils";
import { countAppliedFilters } from "utils/countAppliedFilters";
import { SkeletonList } from "components/ui/atoms/skeletons/SkeletonList";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { FaSearch, FaTrashAlt } from "react-icons/fa";
import { ButtonDanger } from "components/ui/atoms/ButtonDanger";

const QuestionnairesQuestions = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { confirm } = useSwal();
  const [loading, setLoading] = useState(true);
  const { user, userLanguage } = useUser();
  const [questions, setQuestions] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [showImportQuestionsModal, setShowImportQuestionsModal] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({});
  const [filterName, setFilterName] = useState("");

  const [showUsedQuestionError, setShowUsedQuestionError] = useState(false);
  const [questionUsedInForms, setQuestionUsedInForms] = useState([]);
  const [questionUsedInQuestionsGroup, setQuestionUsedInQuestionsGroup] = useState([]);

  const { setPageTitle, ref, setFiltersCount } = useOutletContext();

  const handleSetFilters = (_filters) => {
    setFilters(_filters);
    setFiltersCount(countAppliedFilters(_filters));
  };

  useImperativeHandle(ref, () => ({
    showFilters: () => setShowFilters(true),
  }));

  const getQuestionnaireQuestions = (title = undefined) => {
    setLoading(true);

    const params = {
      ...filters,
      title: title ?? filterName,
      rowsPerPage: pageSize,
      page: currentPage,
    };

    FormsService.getQuestionnairesQuestions(params)
      .then((response) => {
        const { questions, pagination } = response.data.data;
        setQuestions(questions);

        if (pagination.current - 1 !== currentPage) {
          setCurrentPage(pagination.current);
        }

        if (pagination.total !== totalPages) {
          setTotalPages(pagination.pages);
        }

        if (pagination.totalDocs !== totalQuestions) {
          setTotalQuestions(pagination.totalDocs);
        }
      })
      .catch((error) => {
        console.error(error);
        addToast(t("error_getting_questions"), {
          appearance: "error",
          autoDismiss: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteQuestionnaireQuestion = (questionId) => {
    confirm({
      text: t("delete_question_warning"),
      icon: "warning",
    }).then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          await FormsService.deleteQuestionnairesQuestion(questionId);
          addToast(t("question_deleted_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });

          getQuestionnaireQuestions();
        } catch (error) {
          if (error.response.status === 403) {
            setShowUsedQuestionError(true);
            setQuestionUsedInForms(error.response.data.questionnaires);
            setQuestionUsedInQuestionsGroup(error.response.data.questions);
          } else {
            addToast(
              error.response
                ? error.response.data.msg_key
                  ? t(error.response.data.msg_key)
                  : t("error_occurred")
                : t("error_occurred_deleting_question"),
              {
                appearance: "error",
                autoDismiss: true,
              },
            );
          }
        }
      }
    });
  };

  const updateQuestion = (questionId, data) => {
    confirm({
      text: t(data.public ? "public_question_warning" : "private_question_warning"),
      icon: "warning",
    }).then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          await FormsService.updateQuestionnairesQuestion(questionId, data);
          addToast(t("question_updated_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });

          getQuestionnaireQuestions();
        } catch (error) {
          addToast(
            error.response
              ? error.response.data.msg_key
                ? t(error.response.data.msg_key)
                : t("error_occurred")
              : t("error_occurred_updating_question"),
            {
              appearance: "error",
              autoDismiss: true,
            },
          );
        }
      }
    });
  };

  const showSectionHeader = () => {
    let sections = [];
    sections = user?.investor?.permissions?.sections || [];

    return sections.includes("questionnaires_engine");
  };

  const importQuestionsFile = async(file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      setLoading(true);
      await FormsService.importQuestions(formData);
      addToast(t("questions_imported_successfully"), {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      addToast(
        error.response && error.response.data.msg_key
          ? t(error.response.data.msg_key)
          : t("error_occurred_importing_questions"),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
    } finally {
      setShowImportQuestionsModal(false);
      setLoading(false);
    }
  };

  const filterQuestions = (filters) => {
    setCurrentPage(0);
    handleSetFilters(filters);
  };

  useEffect(() => {
    getQuestionnaireQuestions();
  }, [currentPage, pageSize, filters]);

  useEffect(() => {
    setPageTitle(showSectionHeader() ? "questionnaires_engine" : "questions");
  }, []);

  const handleClearFilterAndSubmit = (e) => {
    e.preventDefault();
    setFilterName(""); // Ensuring the state is cleared
    getQuestionnaireQuestions(""); // Calling getDealflow with the cleared filter name
  };

  return (
    <>
      {showUsedQuestionError && (
        <Modal
          showCloseModal={true}
          showModal={showUsedQuestionError}
          paddingBottom="2"
          paddingTop="6"
          onClose={() => {
            setShowUsedQuestionError(false);
            setQuestionUsedInForms([]);
            setQuestionUsedInQuestionsGroup([]);
          }}
        >
          <h2 className="font-semibold text-xl mr-4">{t("error_occurred_deleting_question")}</h2>
          <p className="font-medium text-sm text-gray-dark">
            {t(
              questionUsedInForms?.length
                ? "question_is_being_used_in_some_questionnaires"
                : "question_is_included_in_some_question_group",
            )}
          </p>
          <p className="font-medium text-sm text-gray-dark mt-6 mb-4">
            {t(
              questionUsedInForms?.length
                ? "delete_from_questionnaires_previously"
                : "delete_from_questions_group_previously",
            )}
          </p>
          <div className="text-sm  text-justify font-normal w-full px-10 mb-6">
            {questionUsedInForms?.length > 0 && (
              <ul className="list-disc">
                {questionUsedInForms.map((q, index) => (
                    <li key={index} className="mb-1">
                      {q}
                    </li>
                ))}
              </ul>
            )}
            {questionUsedInQuestionsGroup?.length > 0 && (
              <ul className="list-disc">
                {questionUsedInQuestionsGroup.map((q, index) => (
                    <li key={index} className="mb-1">
                      {TranslateUtils.getTextByLang(userLanguage, q)}
                    </li>
                ))}
              </ul>
            )}
          </div>
        </Modal>
      )}
      {!loading && (
        <>
          {showImportQuestionsModal && (
            <ImportQuestionsModal
              onSubmit={(file) => importQuestionsFile(file)}
              showModal={showImportQuestionsModal}
              handleCloseModal={() => {
                setShowImportQuestionsModal(false);
              }}
            />
          )}
      <div>
      <div className="flex justify-end mt-4">
        <form onSubmit={(evt) => {
          evt.preventDefault();
          getQuestionnaireQuestions();
        }} className="flex gap-2 w-1/4">
                <input
                  className={
                    "shadow-soft-white border border-gray-lines focus:border-main bg-input w-full py-2 px-7 relative z-10 text-left text-xs font-normal rounded-2xl placeholder-gray cursor-pointer transition-all duration-200 outline-none hover:border-main hover:outline-none hover:shadow-inner focus:outline-none focus:shadow-focus active:outline-none"
                  }
                  onChange={(e) => {
                    setFilterName(e.currentTarget.value);
                  }}
                  placeholder={t("form_question_example")}
                  value={filterName ?? ""}
                />
                <ButtonSecondary
                  type="submit"
                  iconComponent={<FaSearch className="h-4" />}
                />
                                {filterName && (<ButtonDanger
                  type="button"
                  onClick={handleClearFilterAndSubmit}
                  iconComponent={<FaTrashAlt className="h-4" />}
                />)}
              </form>
              </div>
          </div>

          {questions.length > 0 && (
            <>
              <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 mb-4 mt-4">
                {questions.map((question) => (
                  <Question
                    key={question._id}
                    question={question}
                    deleteQuestion={deleteQuestionnaireQuestion}
                    updateQuestion={updateQuestion}
                  />
                ))}
              </div>

              <Pagination
                currentPage={currentPage}
                setCurrentPage={(page) => setCurrentPage(page)}
                perPage={pageSize}
                pages={totalPages}
                setPerPage={(value) => setPageSize(value[0].id)}
              />
            </>
          )}

          {questions.length === 0 && (
            <div className="my-6">
              <Card>
                <NoDataInfo
                  title={t(
                    filters && Object.keys(filters).length
                      ? "questions_not_found"
                      : "questions_not_found_empty",
                  )}
                  textOne={t(
                    filters && Object.keys(filters).length
                      ? "questions_not_found_text"
                      : "questions_not_found_empty_text",
                  )}
                />
              </Card>
            </div>
          )}
        </>
      )}

      {showFilters && (
        <FormQuestionsFilters
          onClose={() => setShowFilters(false)}
          onFilter={filterQuestions}
          filters={filters}
        />
      )}

      {loading && <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 mt-6"><SkeletonList length={7} style="rounded-xl h-40"/></div>}
    </>
  );
};

export default QuestionnairesQuestions;
