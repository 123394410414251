import { useEffect, useState } from "react";
import { RoutesLinks } from "components/routes-links";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import FormsService from "../../services/forms-service";
import { Loader } from "../ui/molecules/Loader";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import { Card } from "components/ui/atoms/Card";
import useUser from "../../hooks/useUser";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { ButtonMain } from "components/ui/atoms/ButtonMain";

const Templates = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { user } = useUser();
  const [templates, setTemplates] = useState([]);

  const { setPageTitle } = useOutletContext();

  useEffect(() => {
    setPageTitle(showSectionHeader() ? "questionnaires_engine" : "templates");
  }, []);

  const getQuestionnaireTemplates = () => FormsService.getQuestionnairesTemplates()
    .then((response) => {
      setTemplates(response.data.data);
    })
    .catch(() => {
      addToast(t("error_getting_templates"), {
        appearance: "error",
        autoDismiss: true,
      });
    });

  const showSectionHeader = () => {
    const sections = user?.investor?.permissions?.sections || [];
    return sections.includes("questionnaires_engine");
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [templates]);

  useEffect(() => {
    setLoading(true);
    getQuestionnaireTemplates().finally(() => setLoading(false));
  }, []);

  return (
    <>

      {!showSectionHeader() && (
        <>
          <h2 className="text-sm">{t("explore_templates")}</h2>
          <p className="text-gray-500 text-xs">{t("explore_templates_description")}</p>
        </>
      )}

      {!loading && (
        <>
          {templates.length !== 0 && (
            <div className="mt-6 grid grid-cols-4 gap-8">
              {templates.map((template) => (
                <Card
                  key={template._id}
                  onClick={() => navigate(`${RoutesLinks.QE_TEMPLATES_LINK}/${template._id}`)}
                  wrapperClassName="transition-transform hover:scale-105 cursor-pointer overflow-hidden"
                  movilePadding={"0"}
                  containerClassName="p-0 relative group h-full"
                >
                  <div className="absolute bg-gray-400 flex justify-center items-center w-full h-full -top-full transition-opacity opacity-0 group-hover:top-0 group-hover:opacity-90">
                    <ButtonMain width={"1/2"} text={t("preview")} />
                  </div>
                  <div className="flex flex-col justify-between">
                    <div
                      className="h-32 bg-cover bg-center border-b border-gray-lines"
                      style={{
                        backgroundImage: `url(${template.logo})`,
                        height: "450px",
                      }}
                    />
                    <div className="p-4">
                      <div className="w-full text-md font-bold">{template.name}</div>
                      <div className="text-xs font-semibold">
                        {template.investor_name || "Kiota"}
                      </div>
                      <div className="text-xs flex justify-between">
                        <span>
                          {template.questions?.length
                            ? t("n_questions", { count: template.questions.length })
                            : t("no_questions")}
                        </span>
                        <span>{moment(template.updatedAt).format("DD/MM/YYYY")}</span>
                      </div>
                    </div>
                  </div>
                </Card>
              ))}
            </div>
          )}

          {templates.length === 0 && (
            <div className="my-6">
              <NoDataInfo
                title={t("empty_questionnaires_templates")}
                textOne={t("empty_questionnaires_templates_text_one")}
              />
            </div>
          )}
        </>
      )}

      {loading && <Loader />}
    </>
  );
};

export default Templates;
