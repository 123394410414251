import { Trans, useTranslation } from "react-i18next";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { Modal } from "components/ui/molecules/Modal";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import useUser from "hooks/useUser";
import Utils from "utils/utils";
import { useOnboardingSteps } from "hooks/useOnboardingSteps";
import { useEffect, useState } from "react";

const SuggestedStep = ({
  showSuggestedStep,
  setShowSuggestedStep,
  continueAction,
  action,
  continueText,
}) => {
  const { t } = useTranslation();

  const { user } = useUser();

  const { getTasks } = useOnboardingSteps();
  const [suggestedStep, setSuggestedStep] = useState(null);

  const getSuggestedStep = () => {
    setSuggestedStep(
      Utils.getSuggestedStep(user?.onboarding_tasks, user?.investor?.onboarding_tasks, action),
    );
  };

  useEffect(() => {
    getSuggestedStep();
  }, [action]);

  return (
    <Modal
      showModal={showSuggestedStep}
      onClose={() => setShowSuggestedStep(false)}
      showCloseModal={true}
      fixedWidth={"w-1/2 lg:w-1/3"}
    >
      <div className={"flex flex-col max-h-full"}>
        <h2 className={"font-semibold text-xl mb-2 text-main"}>{t("suggested_step_title")}</h2>
        <div>
          <Trans i18nKey="suggested_step_description" components={{ bold: <strong /> }} />
        </div>
        <div>
          <Trans
            i18nKey="suggested_step_subdescription"
            values={{ step: t(`onboarding_task_${suggestedStep}`) }}
            components={{ bold: <strong /> }}
          />
        </div>
        <div className={"flex justify-end mt-4 border-t pt-2 border-separator"}>
          <ButtonSecondary text={continueText} onClick={continueAction} marginRight={2} />
          <ButtonMain
            type={"button"}
            text={t("take_me_to_suggested_step")}
            onClick={() =>
              getTasks()
                .find((task) => task.id === suggestedStep)
                .onClick()
            }
          />
        </div>
      </div>
    </Modal>
  );
};

export default SuggestedStep;
