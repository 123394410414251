import { OnboardingActionsTypes } from "./onboarding-actions-types";

const ChecksActions = {
  handleOnboardingTasksPannel: (value) => ({
    type: OnboardingActionsTypes.HANDLE_ONBOARDING_TASKS_PANNEL,
    payload: { value },
  }),
};

export default ChecksActions;
