import React, { useState } from "react";

const ExpandableAvatar = ({ src, name, children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <div onClick={handleExpand} className="cursor-pointer hover:opacity-80">
        {children}
      </div>

      {isExpanded && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
          onClick={handleExpand}
        >
          <div className="bg-white p-4 rounded-lg">
            <img src={src} alt={name} style={{ maxHeight: "80vh", maxWidth: "80vw" }}/>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExpandableAvatar;
