import { BasicToast } from "components/ui/atoms/BasicToast";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Scrollbar } from "react-scrollbars-custom";
import { ToastProvider } from "react-toast-notifications";
import ReactTooltip from "react-tooltip";
import { ToastContainer } from "components/ui/objects/ToastContainer";

const ScrollbarProvider = ({ children }) => {
  const scrollBar = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (scrollBar && scrollBar.current) {
      scrollBar.current.scrollToTop();
    }

    ReactTooltip.hide();
  }, [location.pathname]);

  return (
    <ToastProvider components={{ Toast: BasicToast, ToastContainer }}>
        {/* <Scrollbar
            ref={scrollBar}
            trackYProps={{ className: "scrollbar_trackY" }}
            thumbYProps={{ className: "scrollbar_thumbY" }}
            disableTracksWidthCompensation={true}
        > */}
            {children}
        {/* </Scrollbar> */}
        <ReactTooltip
          effect="solid"
          backgroundColor="white"
          border={true}
          borderColor={"#61D8BD"}
          className={"shadow-soft-white text-main opacity-100 font-bold w-auto max-w-sm"}
        />
    </ToastProvider>
  );
};

export default ScrollbarProvider;
