import axios from "axios/index";
import Config from "../config";

const TagsService = {
  createTag: async(category, name, color) =>
    axios.post(`${Config.TAGS_API_URL}`, { category, name, color }),

  getTags: async(category) => axios.get(`${Config.TAGS_API_URL}?category=${category}`),

  deleteTag: async(tagId) => axios.delete(`${Config.TAGS_API_URL}${tagId}`),

  updateTag: async(tagId, data) => axios.put(`${Config.TAGS_API_URL}${tagId}`, data),
};
export default TagsService;
