import axios from "axios/index";
import Config from "../config";

const ScoreCardsService = {
  // TODO: MIGRAR EL BACKEND DE ESTO
  createScoreCard: async(data) => axios.post(`${Config.SCORE_CARDS_API_URL}`, data),

  getScoreCard: async(scoreCardId) => axios.get(`${Config.SCORE_CARDS_API_URL}${scoreCardId}`),

  archiveScoreCard: async(scoreCardId) =>
    axios.delete(`${Config.SCORE_CARDS_API_URL}${scoreCardId}`),

  getScoreCards: async(params) => {
    let query = "";

    if (params.name) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}name=${params.name}`;
    }

    if (params.status) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}status=${params.status}`;
    }

    if (params.sortBy) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}sortBy=${params.sortBy}`;
    }

    if (params.hasOwnProperty("page")) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}page=${params.page}`;
    }

    if (params.rowsPerPage) {
      query = `${query}${query ? "&" : "?"}`;
      query = `${query}rowsPerPage=${params.rowsPerPage}`;
    }

    return axios.get(`${Config.SCORE_CARDS_API_URL}${query}`);
  },
};

export default ScoreCardsService;
