import { useTranslation } from "react-i18next";

import { SidePanel } from "components/ui/objects/SidePanel";
import { useEffect } from "react";
import { Input } from "components/ui/atoms/Input";
import Config from "config";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import useUser from "hooks/useUser";
import { ButtonDanger } from "components/ui/atoms/ButtonDanger";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import Select from "components/ui/atoms/Select";
import { useToasts } from "react-toast-notifications";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";

const MemberSection = ({ onClose, member, onSubmit, removeMember, sendActivationCode }) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const { user } = useUser();

  const LANGUAGES = [
    { id: "es", value: t("es") },
    { id: "en", value: t("en") },
  ];

  const isMainUser = () => {
    const mainUser = user.investor.users.find(
      (teammate) => teammate._id === user.investor.main_contact_id,
    );
    return mainUser.user === user._id;
  };

  const TeammateSchema = Yup.object().shape({
    email: Yup.string().email(t("incorrect_format")).required(t("required_field")),
    name: Yup.string().required(t("required_field")),
    last_name: Yup.string().required(t("required_field")),
    language: Yup.string(),
  });

  const { register, errors, setValue, trigger, getValues, watch } = useForm({
    resolver: yupResolver(TeammateSchema),
    criteriaMode: "all",
    reValidateMode: "all",
    mode: "onChange",
  });

  useEffect(() => {
    register("language");
  }, [register]);

  useEffect(() => {
    if (member && Object.keys(member).length) {
      setValue("email", member?.user?.email || member.email, { shouldDirty: true });
      setValue("name", member?.user?.name || member.name);
      if (member.last_name || member?.user?.last_name) {
        setValue("last_name", member?.user?.last_name || member.last_name);
      }
      if (member.language) {
        _setValue("language", member.language);
      }
    }
  }, [member, setValue]);

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, config);
    trigger(name);
  };

  const getSelectableInitialValues = (id, collection, lonely) => {
    if (!id) {
      return lonely ? null : [];
    }

    if (Array.isArray(id)) {
      return collection.filter((i) => id.includes(i.id));
    }

    const item = collection.find((i) => i.id === id);
    return item ? (lonely ? item : [item]) : lonely ? null : [];
  };

  return (
    <>
      <SidePanel onClose={onClose} title={t(member ? "member_details" : "add_member")} width="1/4">
        <form className="w-full">
          <div>
            <Input
              reference={register}
              id="name"
              type="text"
              name="name"
              label={t("name") + "*:"}
              placeholder={t("introduce_name_they")}
              error={errors.name}
            />
          </div>
          <div>
            <Input
              reference={register}
              id="last_name"
              type="text"
              name="last_name"
              label={t("last_name") + "*:"}
              placeholder={t("introduce_last_name_they")}
              error={errors.last_name}
            />
          </div>
          <div>
            <Input
              reference={register}
              id="email"
              type="email"
              name="email"
              label={t("email") + "*:"}
              placeholder={t("write_email_they")}
              error={errors.email}
            />
          </div>
          {!member && (
            <Select
              name="language"
              label={t("language")}
              reference={register}
              required={true}
              isClearable={false}
              placeholder={t("select_option")}
              error={errors.language}
              initialValues={getSelectableInitialValues(watch("language"), LANGUAGES)}
              items={LANGUAGES}
              onSelect={(selected) => {
                _setValue("language", selected.length ? selected[0].id : null);
              }}
            />
          )}
          <div className={"flex justify-end mt-2 pt-2 border-t border-separator"}>
            {member && member?.user?._id !== user._id && (
              <ButtonDanger
                type="button"
                text={t("delete")}
                onClick={() => removeMember(member)}
                marginRight={2}
              />
            )}
            {member && !member.activated && (
              <ButtonSecondary
                type="button"
                text={t("send_activation_code")}
                onClick={() => sendActivationCode(member)}
                marginRight={2}
              />
            )}
            <ButtonMain
              type="button"
              onClick={async () => {
                const validation = await trigger();
                if (validation && ((!member && getValues().language) || member)) {
                  onSubmit({ ...getValues() });
                } else if (!getValues().language) {
                  addToast(t("language_required"), {
                    appearance: "error",
                    autoDismiss: true,
                  });
                }
              }}
              text={t(!member ? "add_member" : "save")}
            />
          </div>
        </form>
      </SidePanel>
    </>
  );
};

export default MemberSection;
