import { useTranslation } from "react-i18next";
import { PageTitle } from "components/ui/atoms/PageTitle";
import { SectionHeader } from "components/ui/molecules/SectionHeader";
import { useNavigate } from "react-router-dom";
import Resource from "./Resource";
import ResourcesItems from "assets/json/resources.json";
import { usePagination } from "hooks/usePagination";
import { Pagination } from "components/ui/atoms/Pagination";
import Config from "config";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { FaFilter } from "react-icons/fa";
import { useEffect, useState } from "react";
import ResourcesFilters from "./ResourcesFilters";
import useUser from "hooks/useUser";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import { countAppliedFilters } from "utils/countAppliedFilters";

const Resources = ({ section, urls, sections }) => {
  const { t } = useTranslation();

const navigate = useNavigate();
  const [filters, setFilters] = useState({});
  const [filtersCount, setFiltersCount] = useState(0);
  const { userLanguage } = useUser();

  const [resources, setResources] = useState([]);

  const { setTotalPages, totalPages, setPage, page, rowsPerPage, setRowsPerPage } =
    usePagination(12);

  const handleRedirect = (url) => navigate(url);
  const [showFilters, setShowFilters] = useState(false);
  const [paginatedRespurces, setPaginatedResources] = useState([]);
  const filterResources = (_filters) => {
    setPage(0);
    setFilters(_filters);
    setFiltersCount(countAppliedFilters(_filters));
  };

  const getResources = () => {
    let filteredResources = [];
    if (filters.topic && filters.type) {
      filteredResources = ResourcesItems.filter(
        (resource) =>
          resource.topic === filters.topic &&
          resource.lang === userLanguage &&
          resource.type === filters.type,
      );
    } else if (filters.topic) {
      filteredResources = ResourcesItems.filter(
        (resource) => resource.topic === filters.topic && resource.lang === userLanguage,
      );
    } else if (filters.type) {
      filteredResources = ResourcesItems.filter(
        (resource) => resource.lang === userLanguage && resource.type === filters.type,
      );
    } else {
      filteredResources = ResourcesItems.filter((resource) => resource.lang === userLanguage);
    }

    setResources(filteredResources);
    setPaginatedResources(filteredResources.slice(page * 12, (page + 1) * 12));
  };

  useEffect(() => {
    setTotalPages(parseInt(resources.length / 12) + 1);
  }, [resources]);

  useEffect(() => {
    getResources();
  }, [filters, page, userLanguage]);

  return (
    <>
      {showFilters && (
        <ResourcesFilters
          onClose={() => setShowFilters(false)}
          onFilter={filterResources}
          filters={filters}
        />
      )}
      <div className="flex mb-6">
        <div className="flex-1">
          <PageTitle removeMargin={true} showBack={false} title={t("help")} />
        </div>
        <div>
          <ButtonSecondary
            iconComponent={<FaFilter className="h-4" />}
            onClick={() => setShowFilters(true)}
            badgeBgColor={"red"}
            badgeContent={filtersCount > 0 ? filtersCount : null}
          />
        </div>
      </div>
      <SectionHeader
        section={section}
        setSection={(sec) => {
          handleRedirect(urls[sec]);
        }}
        sectionKeys={sections}
        sectionTitles={sections.map((s) => t(s))}
      />
      {paginatedRespurces.length === 0 && (
        <div className="my-10">
          <NoDataInfo title={t("no_resources_found")} />
        </div>
      )}
      {paginatedRespurces.length !== 0 && (
        <>
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 my-6">
            {paginatedRespurces.map((resource, index) => {
              return (
                <Resource
                  resource={resource}
                  key={index}
                  filters={filters}
                  setFilters={setFilters}
                />
              );
            })}
          </div>
          <div className="mt-4">
            <Pagination
              paginateOptions={[12, 24, 36, 48, 60]}
              currentPage={page}
              setCurrentPage={(page) => setPage(page)}
              perPage={rowsPerPage}
              pages={totalPages}
              setPerPage={(value) => setRowsPerPage(value[0].id)}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Resources;
