import axios from "axios/index";
import Config from "../config";

const EvaluationProcessesManagerService = {
  resendEmailToJudge: async(evaluationProcessId, judgeId) =>
    axios.post(
      `${Config.EVALUATION_PROCESSES_MANAGER_API_URL}evaluation-processes/private/ep/${evaluationProcessId}/judges/${judgeId}/email`,
      {},
    ),

  getEvaluationProcesses: async(params) => axios.get(
      `${Config.EVALUATION_PROCESSES_MANAGER_API_URL}evaluation-processes/private/ep`,
      { params },
  ),

  createEvaluationProcess: async(data) =>
    axios.post(
      `${Config.EVALUATION_PROCESSES_MANAGER_API_URL}evaluation-processes/private/ep`,
      data,
    ),

  archiveEvaluationProcess: async(evaluationProcessId) =>
    axios.post(
      `${Config.EVALUATION_PROCESSES_MANAGER_API_URL}evaluation-processes/private/ep/${evaluationProcessId}/archive`,
    ),

  deleteEvaluationProcess: async(evaluationProcessId) =>
    axios.delete(
      `${Config.EVALUATION_PROCESSES_MANAGER_API_URL}evaluation-processes/private/ep/${evaluationProcessId}`,
    ),

  addStartupToEvaluationProcess: async(evaluationProcessId, data) =>
    axios.post(
      `${Config.EVALUATION_PROCESSES_MANAGER_API_URL}evaluation-processes/private/ep/${evaluationProcessId}/dealflow`,
      data,
    ),

  addJudgeToEvaluationProcess: async(evaluationProcessId, judge) =>
    axios.post(
      `${Config.EVALUATION_PROCESSES_MANAGER_API_URL}evaluation-processes/private/ep/${evaluationProcessId}/judges`,
      judge,
    ),

  getJudgesFromEvaluationProcess: async(evaluationProcessId, params) => {
    if (params) {
      let query = "";

      if (params.sortBy) {
        query = `${query}${query ? "&" : "?"}`;
        query = `${query}sortBy=${params.sortBy}`;
      }

      if (params.hasOwnProperty("page")) {
        query = `${query}${query ? "&" : "?"}`;
        query = `${query}page=${params.page}`;
      }

      if (params.rowsPerPage) {
        query = `${query}${query ? "&" : "?"}`;
        query = `${query}rowsPerPage=${params.rowsPerPage}`;
      }
      return axios.get(
        `${Config.EVALUATION_PROCESSES_MANAGER_API_URL}evaluation-processes/private/ep/${evaluationProcessId}/judges${query}`,
      );
    } else {
      return axios.get(
        `${Config.EVALUATION_PROCESSES_MANAGER_API_URL}evaluation-processes/private/ep/${evaluationProcessId}/judges`,
      );
    }
  },

  updateEvaluationProcess: async(evaluationProcessId, data) =>
    axios.patch(
      `${Config.EVALUATION_PROCESSES_MANAGER_API_URL}evaluation-processes/private/ep/${evaluationProcessId}`,
      data,
    ),

  updateDealFromEvaluationProcess: async(evaluationProcessId, dealId, data) =>
    axios.patch(
      `${Config.EVALUATION_PROCESSES_MANAGER_API_URL}evaluation-processes/private/ep/${evaluationProcessId}/dealflow/${dealId}`,
      data,
    ),

  deleteStartupFromEvaluationProcess: async(evaluationProcessId, dealId) =>
    axios.delete(
      `${Config.EVALUATION_PROCESSES_MANAGER_API_URL}evaluation-processes/private/ep/${evaluationProcessId}/dealflow/${dealId}`,
    ),

  deleteJudgeFromEvaluationProcess: async(evaluationProcessId, judgeId) =>
    axios.delete(
      `${Config.EVALUATION_PROCESSES_MANAGER_API_URL}evaluation-processes/private/ep/${evaluationProcessId}/judges/${judgeId}`,
    ),

  updateJudgeFromEvaluationProcess: async(evaluationProcessId, judgeId, data) =>
    axios.patch(
      `${Config.EVALUATION_PROCESSES_MANAGER_API_URL}evaluation-processes/private/ep/${evaluationProcessId}/judges/${judgeId}`,
      data,
    ),

  getEvaluationProcess: async(evaluationProcessId) =>
    axios.get(
      `${Config.EVALUATION_PROCESSES_MANAGER_API_URL}evaluation-processes/private/ep/${evaluationProcessId}`,
    ),

  cloneEvaluationProcess: async(evaluationProcessId, data) => axios.post(`${Config.EVALUATION_PROCESSES_MANAGER_API_URL}evaluation-processes/private/ep/${evaluationProcessId}/clone`, data),

  getJudge: async(evaluationProcessId, judgeId) =>
    axios.get(
      `${Config.EVALUATION_PROCESSES_MANAGER_API_URL}evaluation-processes/shared/ep/${evaluationProcessId}/judges/${judgeId}`,
    ),

  getDealflowFromEvaluationProcess: async(evaluationProcessId, params) => {
    const query = new URLSearchParams(params);
    return axios.get(
      `${Config.EVALUATION_PROCESSES_MANAGER_API_URL}evaluation-processes/private/ep/${evaluationProcessId}/dealflow?${query}`,
    );
  },

  getDealFromEvaluationProcess: async(evaluationProcessId, dealId, applicationId) =>
    axios.get(
      `${Config.EVALUATION_PROCESSES_MANAGER_API_URL}evaluation-processes/private/ep/${evaluationProcessId}/dealflow/${dealId}`,
      { params: { questionnarieResponse: applicationId } },
    ),

  exportEvaluationsCSV: async(evaluationProcessId) =>
    axios.post(`${Config.EVALUATION_PROCESSES_MANAGER_API_URL}evaluations/private/export/csv`, {
      evaluation_process: evaluationProcessId,
    }),

  evaluateStartupByInternalJudge: async(data) =>
    axios.post(`${Config.EVALUATION_PROCESSES_MANAGER_API_URL}evaluations/private`, data),

  getEvaluations: async(params) => {
    const searchParams = new URLSearchParams(params);
    return axios.get(
      `${Config.EVALUATION_PROCESSES_MANAGER_API_URL}evaluations/private?${searchParams}`,
    );
  },

  getEvaluationForDeal: async(eventId, dealId, judgeId) =>
    axios.get(
      `${Config.EVALUATION_PROCESSES_MANAGER_API_URL}evaluations/private/dealflow/${dealId}/judges/${judgeId}`,
    ),

  getMultipleEvaluationsForDeal: async(eventId, dealId, params) =>
    axios.get(
        `${Config.EVALUATION_PROCESSES_MANAGER_API_URL}evaluations/private/dealflow/${dealId}/judges`,
        { params },
    ),

  getAllJudges: async() =>
    axios.get(`${Config.EVALUATION_PROCESSES_MANAGER_API_URL}judges/private`),

  checkAccessToSharedEvaluationProcess: (evaluationProcessId, accessToken) =>
    axios.post(
      `${Config.EVALUATION_PROCESSES_MANAGER_API_URL}evaluation-processes/shared/ep/${evaluationProcessId}/check-access`,
      { access_token: accessToken },
    ),

  getSharedEvaluationProcess: async(evaluationProcessId) =>
    axios.get(
      `${Config.EVALUATION_PROCESSES_MANAGER_API_URL}evaluation-processes/shared/ep/${evaluationProcessId}`,
    ),

  getDealflowFromSharedEvaluationProcess: async(evaluationProcessId, params) => {
    const query = new URLSearchParams(params);
    return axios.get(
      `${Config.EVALUATION_PROCESSES_MANAGER_API_URL}evaluation-processes/shared/ep/${evaluationProcessId}/dealflow?${query}`,
    );
  },

  getDealFromSharedEvaluationProcess: async(evaluationProcessId, dealId) =>
    axios.get(
      `${Config.EVALUATION_PROCESSES_MANAGER_API_URL}evaluation-processes/shared/ep/${evaluationProcessId}/dealflow/${dealId}`,
    ),

  evaluateStartupByExternalJudge: async(data) =>
    axios.post(`${Config.EVALUATION_PROCESSES_MANAGER_API_URL}evaluations/shared`, data),

  getEvaluationByExternalJudge: async(judgeId, dealId) =>
    axios.get(
      `${Config.EVALUATION_PROCESSES_MANAGER_API_URL}evaluations/shared/dealflow/${dealId}/judges/${judgeId}`,
    ),
};
export default EvaluationProcessesManagerService;
