import { ContextActionsTypes } from "./context-actions-types";

const ContextActions = {
  handleSetContext: (value) => ({
    type: ContextActionsTypes.SET_CONTEXT,
    payload: { value },
  }),
  handleRemoveContext: () => ({
    type: ContextActionsTypes.REMOVE_CONTEXT,
    payload: null,
  }),
};

export default ContextActions;
