import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import Voice from "../../../styles/images/voice_icon.svg";
import Text from "../../../styles/images/text_icon.svg";
import Draw from "../../../styles/images/draw_icon.svg";

import { BasicCardNotes } from "../molecules/BasicCardNotes";
import VoiceNotes from "components/project/components/general-information/components/voice-notes";
import TextNotes from "components/project/components/general-information/components/text-notes";
import DrawNotes from "components/project/components/general-information/components/draw-notes";
import Cross from "styles/images/cross_blue.svg";
import { SidePanel } from "./SidePanel";
import { ButtonSecondary } from "../atoms/ButtonSecondary";
import Config from "config";

const NotesContainer = ({
  editable = true,
  zIndex = "0",
  setShowModal,
  notes,
  createNote,
  deleteNote,
  getNote,
  selectedNote,
  setSelectedNote,
  updateNote,
  listTitle,
  onClose,
  setShowMigrationSection = null,
}) => {
  const { t } = useTranslation();

  const [showVoiceModal, setShowVoiceModal] = useState(false);
  const [showDrawerModal, setShowDrawerModal] = useState(false);

  const getNoteWrapper = (id) => {
    getNote(id);
  };

  useEffect(() => {
    if (selectedNote) {
      if (selectedNote.type === "draw") {
        setShowDrawerModal(true);
      } else if (selectedNote.type === "audio") {
        setShowVoiceModal(true);
      }
    }
  }, [selectedNote]);

  const handleCloseTextModal = () => {
    setSelectedNote(null);
  };

  const handleCloseDrawerModal = () => {
    setShowDrawerModal(false);
    setSelectedNote(null);
  };

  const handleCloseVoiceModal = () => {
    setShowVoiceModal(false);
    setSelectedNote(null);
  };

  console.log(notes);

  return (
    <>
      {showVoiceModal && (
        <VoiceNotes
          editable={editable}
          showModal={showVoiceModal}
          onSubmit={(title, type, content) => {
            setShowVoiceModal(false);
            createNote(title, type, content);
          }}
          initialValues={selectedNote}
          handleClose={handleCloseVoiceModal}
        />
      )}
      {showDrawerModal && (
        <DrawNotes
          editable={editable}
          showModal={showDrawerModal}
          onSubmit={(title, content) => {
            setShowDrawerModal(false);
            if (selectedNote) {
              updateNote(title, content);
            } else {
              createNote(title, "draw", content);
            }
          }}
          handleClose={handleCloseDrawerModal}
          initialValues={{
            title: selectedNote ? selectedNote.title : "",
            content: selectedNote ? selectedNote.content : "",
          }}
        />
      )}

      <SidePanel onClose={onClose} title={t("notes")} width="2/3 sm:w-1/2 md:w-1/3 lg:w-1/4">
        {editable && (
          <TextNotes
            editable={editable}
            onSubmit={(values) => {
              if (selectedNote) {
                updateNote(values.title, values.content);
              } else {
                createNote(values.title, "text", values.content);
              }
            }}
            initialValues={{
              title: selectedNote && selectedNote.type !== "audio" ? selectedNote.title : "",
              content: selectedNote && selectedNote.type !== "audio" ? selectedNote.content : "",
            }}
            handleClose={handleCloseTextModal}
            setShowVoiceModal={setShowVoiceModal}
            setSelectedNote={setSelectedNote}
          />
        )}
        <div className="my-6">
          <div className="pb-20 border-t border-gray-lines">
            <p className={"font-medium mt-6 text-base text-main"}>
              {listTitle || t("my_annotations")}
            </p>
            <div>
              {notes.length > 0 && (
                <div className="overflow-auto mt-4">
                  {notes.map((note, index) => (
                      <div className="my-4" key={index}>
                        <BasicCardNotes
                          key={index}
                          note={note}
                          deleteNote={editable ? deleteNote : null}
                          getNote={getNoteWrapper}
                          setShowMigrationSection={setShowMigrationSection}
                        />
                      </div>
                  ))}
                </div>
              )}
            </div>
            {notes.length === 0 && (
              <div className="text-xs text-center my-2">
                <span>{t(editable ? "no_notes_added" : "no_notes_added_not_editable")}</span>
              </div>
            )}
          </div>
        </div>
      </SidePanel>
    </>
  );
};

export default NotesContainer;
