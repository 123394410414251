import { useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { useOutletContext } from "react-router-dom";
import { DocumentationStatistics } from "components/ui/atoms/DocumentationStatistics";
import { DocumentList } from "./components/DocumentList";

import { StatisticsService } from "services/statistics-service";
import { DealflowService } from "services/dealflow-service";
import MigrationSection from "components/ui/molecules/MigrationSection";
import DocumentsService from "services/documents-service";
import { useSwal } from "hooks/useSwal";
import useUser from "hooks/useUser";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";

const Documents = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const { confirm } = useSwal();

  const { user, checkIfLPhasAccessToSection } = useUser();
  const [documentToMigrate, setDocumentToMigrate] = useState(null);
  const [reloadDocuments, setReloadDocuments] = useState(false);
  const [dealflow, setDealflow] = useState([]);
  const [showMigrationSection, setShowMigrationSection] = useState(false);
  const [showStatistics, setShowStatistics] = useState(true);

  const [statistics, setStatistics] = useState({
    total: 0,
    documents: [
      { extension: "total", documents: 0, size: 0 },
      { extension: "pdf", documents: 0, size: 0 },
      { extension: "excel", documents: 0, size: 0 },
      { extension: "multimedia", documents: 0, size: 0 },
      { extension: "img", documents: 0, size: 0 },
      { extension: "others", documents: 0, size: 0 },
    ],
  });

  const { ref, setFiltersCount } = useOutletContext();

  const getStats = () => StatisticsService.getDocumentsStats()
    .then((response) => {
      setStatistics(response.data.data);
    })
    .catch((error) => {
      addToast(error?.response?.data?.msg_key || t("error_occurred_retrieving_document_stats"), {
        appearance: "error",
        autoDismiss: true,
      });
    });

  const getDealflow = () => DealflowService.getDealflow({
    rowsPerPage: 999999,
    pool: "true",
    populate: "false",
    boards: "false",
    milestones: "false",
    files: "false",
    reminders: "false",
    annotations: "false",
  }).then((response) => {
    setDealflow(response.data.data.dealflow);
  });

  useEffect(() => {
    if (!user.lp) {
      setLoading(true);
      Promise.all([getDealflow(), getStats()]).finally(() => setLoading(false));
    } else if (
      user.lp &&
      checkIfLPhasAccessToSection("documentation") &&
      checkIfLPhasAccessToSection("dealflow")
    ) {
      setLoading(true);
      Promise.all([getDealflow()]).finally(() => setLoading(false));
    }
  }, []);

  const migrateDocument = async(dealToMigrateId) => {
    try {
      setLoading(true);
      if (documentToMigrate.category !== "dc_other_documents") {
        confirm({
          title: t("are_you_sure"),
          text: t("migrate_document_key_category_explanation"),
        }).then(async(isConfirmed) => {
          if (isConfirmed) {
            await DocumentsService.migrateDocument(documentToMigrate._id, dealToMigrateId);
            addToast(t("document_migrated_successfully"), {
              appearance: "success",
              autoDismiss: true,
            });
            setDocumentToMigrate(null);
            setShowMigrationSection(false);
            setReloadDocuments(true);
          }
        });
      } else {
        await DocumentsService.migrateDocument(documentToMigrate._id, dealToMigrateId);
        addToast(t("document_migrated_successfully"), { appearance: "success", autoDismiss: true });
        setDocumentToMigrate(null);
        setShowMigrationSection(false);
        setReloadDocuments(true);
      }
    } catch (error) {
      addToast(t("error_occurred_migrating_document"), { appearance: "error", autoDismiss: true });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {showMigrationSection && (
        <MigrationSection
          onClose={() => {
            setShowMigrationSection(false);
            setDocumentToMigrate(null);
          }}
          migrationType={"documents"}
          dealflow={dealflow}
          onSubmit={(dealToMigrateId) => {
            migrateDocument(dealToMigrateId);
          }}
        />
      )}
      {(!user.lp || (user.lp && checkIfLPhasAccessToSection("documentation"))) && (
        <>
          {showStatistics && !user.lp && (
            <DocumentationStatistics
              used={statistics?.used || 0}
              available={statistics?.available || 0}
              documents={statistics?.documents || []}
              loading={loading}
            />
          )}

          <DocumentList
            ref={ref}
            setDocumentToMigrate={(document) => {
              setDocumentToMigrate(document);
              setShowMigrationSection(true);
            }}
            reloadDocuments={reloadDocuments}
            setReloadDocuments={setReloadDocuments}
            setShowStatistics={setShowStatistics}
            setFiltersCount={setFiltersCount}
          />
        </>
      )}

      {user.lp && !checkIfLPhasAccessToSection("documentation") && (
        <div className="my-6">
          <NoDataInfo title={t("access_forbidden")} />
        </div>
      )}
    </>
  );
};

export default Documents;
