import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillEye } from "react-icons/ai";
// import Datepicker from "react-tailwindcss-datepicker"
import moment from "moment";
import InvestmentSection from "./InvestmentSection";
import { useToasts } from "react-toast-notifications";
import { usePagination } from "hooks/usePagination";
import { Card } from "components/ui/atoms/Card";
import { DataGrid } from "components/ui/objects/DataGrid";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import { Pagination } from "components/ui/atoms/Pagination";
import { InvestmentsService } from "services/investments-service";
// import { usePagination } from "hooks/usePagination"
import InvestmentStages from "assets/json/investment-stages.json";
import { InvestmentsFilters } from "./InvestmentsFilter";
import { DealflowSearcherPortfolio } from "./DealflowSearcher";
import EditInvestmentSection from "./EditInvestmentSection";
import useUser from "hooks/useUser";
import { DealflowService } from "services/dealflow-service";
import { useNavigate } from "react-router-dom";

import { useSwal } from "hooks/useSwal";
import useCurrency from "hooks/useCurrency";
import { Skeleton } from "components/ui/atoms/skeletons/Skeleton";

export const Investments = ({
  showSearcher,
  setShowSearcher,
  showInvestmentSection,
  setShowInvestmentSection,
  setShowInvestmentFilters,
  showInvestmentsFilters,
  setShowInvestmentsFilters,
  startups,
}) => {
  const headerClass = "text-main font-bold text-sm border-b border-main text-left bg-white";
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [investments, setInvestments] = useState([]);
  const [allInvestments, setAllInvestments] = useState([]);

  const [filters, setFilters] = useState({});
  const { addToast } = useToasts();
  const [detailsInvestments, setDetailsInvestments] = useState(null);
  const { user, reloadUserInfo, hasAccessToSection, checkIfLPhasAccessToSection } = useUser();
  const { alert, confirm } = useSwal();
  const { currency } = useCurrency();

  const { setTotalPages, totalPages, setPage, page, rowsPerPage, setRowsPerPage } =
    usePagination(12);

  const [date, setDate] = useState({
    startDate: null,
    endDate: null,
  });

  const handleValueChange = (date) => {
    setDate(date);
  };
  const INVESTMENT_STAGES = InvestmentStages.map((is) => ({
    ...is,
    id: is._id,
    name: t(is.key),
  }));
  const navigate = useNavigate();

  const showInvestmentDetails = (investment) => {
    setDetailsInvestments(investment);
  };

  const getInvestments = async() => {
    try {
      setLoading(true);
      const response = await InvestmentsService.getInvestments({
        ...filters,
        rowsPerPage: 12,
        page,
        populate: "true",
        sortBy: "-createdAt",
      });
      setInvestments(response.data.data.investments);
      setTotalPages(response.data.data.pagination.pages);
      await getAllInvestments();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getAllInvestments = async() => {
    // TODO: For perfomance Create specific endpoint for this
    try {
      const response = await InvestmentsService.getInvestments({
        rowsPerPage: 9999,
        populate: "true",
        sortBy: "-createdAt",
      });
      setAllInvestments(response.data.data.investments);
    } catch (error) {
      console.error(error);
    } finally {
      // setLoading(false)
    }
  };

  const onDeleteInvestment = async(investment) => {
    console.log("On delete investment", investment);
    confirm({}).then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          const response = await InvestmentsService.deleteInvestment(investment._id);

          addToast(t("investment_deleted"), {
            appearance: "success",
            autoDismiss: true,
          });
          setDetailsInvestments(null);
          getInvestments();
        } catch (error) {
          console.error(error);
          addToast(t(error?.response?.data?.msg_key || "investment_deleted"), {
            appearance: "success",
            autoDismiss: true,
          });
        } finally {
          // setLoading(false)
        }
      }
    });
  };

  const handleFilters = (values) => {
    setFilters(values);
  };

  useEffect(() => {
    getInvestments();
  }, [page, rowsPerPage, filters]);

  const handleInvestment = async(values) => {
    console.log("values", values);

    try {
      await InvestmentsService.registerInvestment(values);

      addToast(t("investment_registered_successfully"), {
        appearance: "success",
        autoDismiss: true,
      });
      setShowInvestmentSection(false);
      getInvestments();
    } catch (error) {
      console.error(error);
      // addToast(t('error_occurred_registering_investment'), {
      //   appearance: 'error',
      //   autoDismiss: true
      // })
    } finally {
      setLoading(false);
    }
  };
  const handleInvestmentUpdate = async(id, values) => {
    try {
      await InvestmentsService.updateInvestment(id, { ...values, userId: user._id });

      // await InvestmentsService.registerInvestment({
      //   deal: '650d30adbf9a3c6318de8bf4',
      //   investment_type: 'first_investment',
      //   status: 'commited',
      //   investment_stage: '6120116e296138194f439ce3',
      //   investment: 200000,
      //   shares: 10,
      //   commission: 1000,
      //   round: 5000000,
      //   premoney_valuation: 10000000,
      //   is_own_investment: true,
      //   investment_date: moment(),
      //   comments: 'Comentario'
      // })
      addToast(t("investment_updated_successfully"), {
        appearance: "success",
        autoDismiss: true,
      });
      setDetailsInvestments(null);
      getInvestments();
    } catch (error) {
      console.error(error);
      // addToast(t('error_occurred_registering_investment'), {
      //   appearance: 'error',
      //   autoDismiss: true
      // })
    } finally {
      setLoading(false);
    }
  };

  const registerInvestment = async(values) => {
    confirm().then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          if (values.is_own_investment) {
            delete values.shareholder;
          }
          await DealflowService.registerInvestment(values.deal, { ...values });
          addToast(t("investment_registered_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
          setShowInvestmentSection(false);
          getInvestments();
        } catch (error) {
          console.error(error);
          addToast(t("error_occurred_registering_investment"), {
            appearance: "error",
            autoDismiss: true,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  return (
    <>
      {showInvestmentSection && (
        <InvestmentSection
          onClose={() => setShowInvestmentSection(false)}
          startups={startups}
          onSubmit={registerInvestment}
        />
      )}
      {detailsInvestments && (
        <EditInvestmentSection
          onClose={() => setDetailsInvestments(null)}
          startups={startups}
          onSubmit={handleInvestmentUpdate}
          investment={detailsInvestments}
          onDeleteInvestment={(investment) => onDeleteInvestment(investment)}
        />
      )}
      {showInvestmentsFilters && (
        <InvestmentsFilters
          onClose={() => setShowInvestmentsFilters(false)}
          startups={startups}
          onFilter={handleFilters}
          filters={filters}
        />
      )}
      {showSearcher && (
        <DealflowSearcherPortfolio
          onClose={() => setShowSearcher(false)}
          onFilter={setFilters}
          filters={filters}
          status={status}
          investments={allInvestments}
        />
      )}
      {/* {JSON.stringify(filters)} */}
      {/* {loading && (
          <div className="my-6 flex flex-wrap w-full">
            <div className="w-full">
          </div>
          </div>
      )}
      <div className="flex justify-end">
        <div className="w-1/4 my-2">
          <h2>{showInvestmentsFilters}</h2>
          <Datepicker
            primaryColor={"blue"}
            value={date}
            onChange={handleValueChange}
            showShortcuts={true}
          />
        </div>
      </div> */}
      <div className="my-6 flex flex-wrap w-full">
        <div className="w-full">
          {loading && (<div className="h-24"><Skeleton /></div>)}
          {!loading && (
            <Card>
              <div className="flex flex-wrap">
                <div className="flex flex-wrap w-2/3">
                  <div className="w-1/2 xl:w-1/4">
                    <h5 className="font-light">Invertido</h5>
                    <h3 className="font-bold">
                      {String(
                        allInvestments.reduce((acc, investment) => acc + Number(investment.investment), 0),
                      ).replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                      {currency}
                    </h3>
                  </div>
                  <div className="w-1/2 xl:w-1/4">
                    <h5 className="font-light">Comprometido</h5>
                    <h3 className="font-bold">
                      {String(
                        allInvestments
                          .filter((s) => s.status === "commited")
                          .reduce((acc, investment) => acc + Number(investment.investment), 0),
                      ).replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                      {currency}
                    </h3>
                  </div>
                  <div className="w-1/2 xl:w-1/4">
                    <h5 className="font-light">Comisiones</h5>
                    <h3 className="font-bold">
                      {String(
                        allInvestments
                          .filter((s) => s.investment_type === "follow_on")
                          .reduce((acc, investment) => acc + Number(investment.commission), 0),
                      ).replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                      {currency}
                    </h3>
                  </div>
                  {/* <div className="w-1/2 xl:w-1/4">
                    <h5 className='font-light'>Total</h5>
                    <h3 className='font-bold'>379.249{currency}</h3>
                  </div> */}
                </div>
                <div className="flex flex-wrap w-1/3 border-l-2 border-separator pl-4">
                  <div className=" w-full xl:w-1/2">
                    <h5 className="font-light">Nº inversiones</h5>
                    <h3 className="font-bold">{allInvestments.length}</h3>
                  </div>
                  <div className="w-full xl:w-1/2">
                    <h5 className="font-light w-">Follow-on</h5>
                    <h3 className="font-bold">
                      {
                        allInvestments.filter((s) => s.investment_type === "follow_on").length
                      }
                    </h3>
                  </div>
                </div>
              </div>
            </Card>
          )}
        </div>

        <div className="w-full lg:w-3/4 py-5">
          {loading && (<div className="h-24"><Skeleton /></div>)}
          {!loading && (
          <Card>
            <div className="flex flex-wrap w-full lg:w-2/3">
              <div className="w-1/2 xl:w-1/4">
                <h5 className="font-light">Nuevas inversiones</h5>
                <h3 className="font-bold">
                  {String(
                    allInvestments
                      .filter((s) => s.investment_type === "first_investment")
                      .reduce((acc, investment) => acc + Number(investment.investment), 0),
                  ).replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                  {currency}
                </h3>
              </div>
              <div className="w-1/2 xl:w-1/4">
                <h5 className="font-light">Follow ON</h5>
                <h3 className="font-bold">
                  {String(
                    allInvestments
                      .filter((s) => s.investment_type === "follow_on")
                      .reduce((acc, investment) => acc + Number(investment.investment), 0),
                  ).replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                  {currency}
                </h3>
              </div>
              {/* <div className="w-1/2 xl:w-1/4">
                <h5 className='font-light'>Efectividad ({currency})</h5>
                <h3 className='font-bold'>1.749{currency}</h3>
              </div>
              <div className="w-1/2 xl:w-1/4">
                <h5 className='font-light'>Efectividad (%)</h5>
                <h3 className='font-bold'>379.249{currency}</h3>
              </div> */}
            </div>
          </Card>
          )}
        </div>
      </div>
      <div className="my-3 grid grid-cols-1">
        <Card>
          <h3 className="font-semibold text-border-buttons-secondary mb-5">
            {t("last_investments")}
          </h3>
          <div className="w-full">
            <DataGrid
              data={investments}
              headers={[
                {
                  key: "startup",
                  title: "Startup",
                  headerClassName: headerClass,
                  render: (key, doc) => (
                    <div className="flex items-center">
                      <div className="mx-2">
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            navigate("/dealflow/" + doc.deal._id);
                          }}
                        >
                          {" "}
                          {doc.deal?.name}
                        </a>
                      </div>
                    </div>
                  ),
                },
                {
                  key: "investment_type",
                  title: t("type"),
                  headerClassName: headerClass,
                  render: (key, doc) => (
                    <>
                      {doc.investment_type && (
                        <div>
                          <span className="ml-2">{t(doc.investment_type)}</span>
                        </div>
                      )}
                      {!doc.investment_type && "-"}
                    </>
                  ),
                },
                {
                  key: "status",
                  title: t("status"),
                  headerClassName: headerClass,
                  render: (key, doc) => (
                    <>
                      {doc.status && (
                        <div>
                          <span className="ml-2">{t(doc.status)}</span>
                        </div>
                      )}
                      {!doc.status && "-"}
                    </>
                  ),
                },
                {
                  key: "investment_stage",
                  title: t("investment_stage"),
                  headerClassName: headerClass,
                  render: (key, doc) => (
                    <>
                      {doc.investment_stage && (
                        <div>
                          <span className="ml-2">
                            {t(INVESTMENT_STAGES.find((i) => doc.investment_stage === i.id)?.key)}
                          </span>
                        </div>
                      )}
                      {!doc.investment_stage && "-"}
                    </>
                  ),
                },
                {
                  key: "investment",
                  title: t("investment"),
                  headerClassName: headerClass,
                  render: (key, doc) => (
                    <>
                      {doc.investment && (
                        <div>
                          <span className="ml-2">{doc.investment}</span>
                        </div>
                      )}
                      {!doc.investment && "-"}
                    </>
                  ),
                },
                {
                  key: "shares",
                  title: t("shares"),
                  headerClassName: headerClass,
                  render: (key, doc) => (
                    <>
                      {doc.shares && (
                        <div>
                          <span className="ml-2">{doc.shares}</span>
                        </div>
                      )}
                      {!doc.shares && "-"}
                    </>
                  ),
                },
                {
                  key: "commission",
                  title: t("commission"),
                  headerClassName: headerClass,
                  render: (key, doc) => (
                    <>
                      {doc.commission && (
                        <div>
                          <span className="ml-2">{doc.commission}</span>
                        </div>
                      )}
                      {!doc.commission && "-"}
                    </>
                  ),
                },
                {
                  key: "valuation",
                  title: t("valuation"),
                  headerClassName: headerClass,
                  render: (key, doc) => (
                    <>
                      {doc.premoney_valuation && (
                        <div>
                          <span className="ml-2">{doc.premoney_valuation}</span>
                        </div>
                      )}
                      {!doc.premoney_valuation && "-"}
                    </>
                  ),
                },
                {
                  key: "round_size",
                  title: t("round_size"),
                  headerClassName: headerClass,
                  render: (key, doc) => (
                    <>
                      {doc.round && (
                        <div>
                          <span className="ml-2">{doc.round}</span>
                        </div>
                      )}
                      {!doc.round && "-"}
                    </>
                  ),
                },
                {
                  key: "date",
                  title: t("date"),
                  headerClassName: headerClass,
                  render: (key, doc) => (
                    <>
                      {doc.investment_date && (
                        <div>
                          <span className="ml-2">
                            {moment(doc.investment_date).format("YYYY-MM-DD")}
                          </span>
                        </div>
                      )}
                      {!doc.investment_date && "-"}
                    </>
                  ),
                },
                {
                  key: "total",
                  title: "Total",
                  headerClassName: headerClass,
                  render: (key, doc) => (
                    <>
                      {
                        <div>
                          <span className="ml-2">
                            {Number(doc.investment) + Number(doc.commission)}
                          </span>
                        </div>
                      }
                    </>
                  ),
                },
                {
                  key: "actions",
                  title: "Acciones",
                  headerClassName: headerClass,
                  render: (key, doc) => (
                    <>
                      <div className="flex">
                        <div className="cursor-pointer m-1 p-2 rounded-full border  border-round border-gray-lines text-text-buttons-secondary">
                          <AiFillEye onClick={() => showInvestmentDetails(doc)}></AiFillEye>
                        </div>
                      </div>
                    </>
                  ),
                },
              ]}
            />
            {!investments?.length && (
              <NoDataInfo title={t("no_data_registered")} className="py-10" />
            )}
          </div>
          <Pagination
            paginateOptions={[12, 24, 36, 48, 60]}
            currentPage={page}
            setCurrentPage={(page) => setPage(page)}
            perPage={rowsPerPage}
            pages={totalPages}
            setPerPage={(value) => setRowsPerPage(value[0].id)}
          />
        </Card>
      </div>
    </>
  );
};
