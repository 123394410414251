import { useSelector, useDispatch } from "react-redux";
import UserOperations from "../redux/user/user-operations";

export const useAuth = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const apiKey = useSelector((state) => state.auth.api_key);
  const refreshToken = useSelector((state) => state.auth.refreshToken);
  const setToken = (token) => dispatch(UserOperations.setToken({ token }));
  const setApiKey = (apiKey) => dispatch(UserOperations.setApiKey({ apiKey }));
  const internalRole = useSelector((state) => state.user.internal_role);

  const checkPermission = (key) => internalRole[key];

  return { token, refreshToken, setToken, setApiKey, apiKey, checkPermission };
};
