import { useTranslation } from "react-i18next";
import { SidePanel } from "components/ui/objects/SidePanel";
import {
  FaArchive,
  FaBell,
  FaFileDownload,
  FaFileExport,
  FaPaperPlane,
  FaShareAlt,
  FaTags,
  FaTimesCircle,
  FaTrash,
  FaUsers,
} from "react-icons/fa";

import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { ButtonDanger } from "components/ui/atoms/ButtonDanger";
import useUser from "hooks/useUser";

const DealflowActions = ({
  onClose,
  showTags,
  deleteDeal,
  registerInvestment,
  markAsFailed,
  markAsInvested,
  markAsInteresting,
  registerExit,
  rejectDeal,
  archiveDeal,
  unarchiveDeal,
  invested,
  rejected,
  failed,
  exited,
  committed,
  interesting,
  downloadKpisReport,
  downloadStartupProfile,
  mainContact,
  contact,
  addReminder,
  setShowShareDealSection,
  archived,
  setShowDealOwnersModal,
  generateStartupProfile,
}) => {
  const { t } = useTranslation();
  const { hasAccessToModule } = useUser();

  return (
    <SidePanel width={"1/6"} onClose={onClose} title={t("actions")}>
      {!invested && !failed && !exited && !rejected && !committed && (
        <ButtonSecondary
          width={"full"}
          marginTop={2}
          onClick={rejectDeal}
          text={t("reject_startup")}
          iconComponent={<FaTimesCircle className="inline-block mr-3" />}
        />
      )}
      {!archived && (
        <ButtonSecondary
          width={"full"}
          marginTop={2}
          onClick={archiveDeal}
          text={t("archive_startup")}
          iconComponent={<FaArchive className="inline-block mr-3" />}
        />
      )}
      {archived && (
        <ButtonSecondary
          width={"full"}
          marginTop={2}
          onClick={unarchiveDeal}
          text={t("unarchive_startup")}
          iconComponent={<FaArchive className="inline-block mr-3" />}
        />
      )}
      {/*! invested && !failed && !exited && !rejected && !committed && <ButtonSecondary
        width={'full'}
        marginTop={2}
        onClick={markAsFailed}
        text={t('mark_as_failed')}
        iconComponent={<FaExclamationCircle className="inline-block mr-3" />}
      /> */}
      {/*! interesting && !invested && !failed && !exited && !rejected && <ButtonSecondary
        width={'full'}
        marginTop={2}
        onClick={markAsInteresting}
        text={t('mark_as_interesting')}
        iconComponent={<AiOutlineLike className="inline-block mr-3" />}
      /> */}
      {/*! invested && !failed && !exited && !rejected && <ButtonSecondary
        width={'full'}
        marginTop={2}
        onClick={markAsInvested}
        text={t('mark_as_invested')}
        iconComponent={<FaEuroSign className="inline-block mr-3" />}
      /> */}
      {/*! rejected && !failed && !exited && <ButtonSecondary
        width={'full'}
        marginTop={2}
        onClick={registerInvestment}
        text={t('register_investment')}
        iconComponent={<FaEuroSign className="inline-block mr-3" />}
      /> */}
      {/*! rejected && !failed && !exited && <ButtonSecondary
        width={'full'}
        marginTop={2}
        onClick={registerExit}
        text={t('register_exit')}
        iconComponent={<FaHandshake className="inline-block w-4 h-4 mr-3" />}
      /> */}
      {/** Active for all */}
      <ButtonSecondary
        width={"full"}
        marginTop={2}
        onClick={downloadStartupProfile}
        text={t("download_startup_profile")}
        iconComponent={<FaFileDownload className="inline-block mr-3" />}
      />
      {hasAccessToModule("nuclia") && (
        <ButtonSecondary
          width={"full"}
          marginTop={2}
          onClick={generateStartupProfile}
          text={t("create_investment_memo")}
          iconComponent={<FaFileExport className="inline-block mr-3" />}
        />
      )}
      <ButtonSecondary
        width={"full"}
        marginTop={2}
        onClick={downloadKpisReport}
        text={t("download_kpis_report")}
        iconComponent={<FaFileDownload className="inline-block mr-3" />}
      />
      <ButtonSecondary
        width={"full"}
        marginTop={2}
        onClick={() => showTags()}
        text={t("manage_tags")}
        iconComponent={<FaTags className="inline-block mr-3" />}
      />

      <ButtonSecondary
        marginTop={2}
        width={"full"}
        onClick={() => addReminder()}
        text={t("add_reminder")}
        iconComponent={<FaBell className="inline-block mr-3" />}
      />
      <ButtonSecondary
        marginTop={2}
        width={"full"}
        onClick={() => setShowShareDealSection(true)}
        text={t("share")}
        iconComponent={<FaShareAlt className="inline-block mr-3" />}
      />
      {contact && (
        <ButtonSecondary
          text={t("contact")}
          marginTop={2}
          width={"full"}
          iconComponent={<FaPaperPlane className="inline mr-1" />}
          onClick={() => contact()}
        />
      )}

      <ButtonSecondary
        marginTop={2}
        width={"full"}
        onClick={() => setShowDealOwnersModal(true)}
        text={t("assign_teammates")}
        iconComponent={<FaUsers className="inline-block mr-3" />}
      />

      <div className={"flex justify-end mt-4 pt-2 border-t border-separator"}>
        <ButtonDanger
          onClick={() => deleteDeal()}
          text={t("delete")}
          iconComponent={<FaTrash className="inline-block mr-3" />}
          width={"full"}
        />
      </div>
    </SidePanel>
  );
};

export default DealflowActions;
