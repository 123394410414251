import { RadarChart } from "components/ui/molecules/charts/RadarChart";
import { CircularChard } from "components/ui/molecules/charts/CircularChart";
import { FaChevronCircleDown, FaComment } from "react-icons/fa";
import { ScoreCardInfo } from "components/events/components/event/components/score-card-info";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useEffect, useState } from "react";
import ScoreCardUtils from "utils/score-card-utils";
import { useTranslation } from "react-i18next";
import { ApplicationQuestionnaireView } from "components/ui/objects/ApplicationQuestionnaireView";
import useUser from "hooks/useUser";
import { Loader } from "components/ui/molecules/Loader";
import CriteriaComment from "./CriteriaComment";
import { Card } from "components/ui/atoms/Card";

export const JudgeStartupEvaluationView = ({ evaluation }) => {
  const { t } = useTranslation();
  const [parents, setParents] = useState([]);

  const { userLanguage } = useUser();
  const [loading, setLoading] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [criteriaToSeeComment, setCriteriaToSeeComment] = useState(null);

  useEffect(() => {
    setShowCommentModal(!!criteriaToSeeComment);
  }, [criteriaToSeeComment]);

  const getParentScore = (parent) => ScoreCardUtils.getParentScore(
    parent,
    evaluation.score_card.criteria,
    evaluation.criterias_score,
  );

  const getRadarData = () => parents.map((parent) => ({
    id: parent.name,
    [t("score")]: getParentScore(parent),
  }));

  const getChildren = (parent) => ScoreCardUtils.getChildren(parent, evaluation.score_card.criteria);

  const hasChildren = (currentCriteria, isId = false) => ScoreCardUtils.hasChildren(currentCriteria, evaluation.score_card.criteria, isId);

  const expandOrCollapse = (elementId) => {
    const element = document.getElementById(elementId);
    element.classList.toggle("hidden");
  };

  useEffect(() => {
    if (!evaluation) {
      return;
    }

    setParents(ScoreCardUtils.getCriteriaParents(evaluation.score_card.criteria));
  }, [evaluation]);

  if (!evaluation) {
    return null;
  }

  return (
    <>
      {showCommentModal && (
        <CriteriaComment
          editable={false}
          initialValues={{ content: evaluation.comments[criteriaToSeeComment] }}
          showModal={showCommentModal}
          handleClose={() => setCriteriaToSeeComment(null)}
        />
      )}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 my-6">
        <Card>
          <div className="text-lg font-semibold mb-4 border-b border-gray-lines">
            {t("criteria")}
          </div>

          {evaluation?.score_card && evaluation.score_card.criteria.length > 2 && (
            <>
              <div style={{ height: "20rem" }} className="w-full p-6" id="radar">
                <RadarChart
                  maxValue={
                    evaluation.score_card.scale === "scale_to_ten"
                      ? 10
                      : evaluation.score_card.scale === "traffic_light"
                        ? 10
                        : 100
                  }
                  gridShape="linear"
                  gridLabelOffset={
                    evaluation.score_card.scale === "scale_to_ten"
                      ? 1
                      : evaluation.score_card.scale === "traffic_light"
                        ? 1
                        : 10
                  }
                  legend={false}
                  data={getRadarData()}
                />
              </div>
              <div className="text-xs text-left mt-4">{t("score_over_one_hundred")}</div>
            </>
          )}

          {evaluation?.score_card && evaluation.score_card.criteria.length <= 2 && (
            <div className="grid grid-cols-1 md:grid-cols-2">
              {Object.keys(evaluation.criterias_score).map((criteria, index) => (
                <div
                  key={index}
                  className="mt-2 text-base text-center font-semibold w-full flex flex-row justify-center"
                >
                  <div className="w-40">
                    <CircularChard
                      maxValue={
                        evaluation.score_card.scale === "scale_to_ten"
                          ? 10
                          : evaluation.score_card.scale === "traffic_light"
                            ? 10
                            : 100
                      }
                      analysed={true}
                      title={evaluation.score_card.criteria.find((c) => c._id === criteria).name}
                      fontSize="2xl"
                      value={evaluation.criterias_score[criteria]}
                      valueText={`${evaluation.criterias_score[criteria]}`}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </Card>

        <Card>
          <div className="text-base font-semibold mt-4 mb-6 border-b border-gray-lines">
            {t("general_score")}
          </div>

          {evaluation?.score_card && (
            <div>
              <div className="mt-2 text-base text-center font-semibold w-full flex flex-row justify-center">
                <div className="w-64">
                  <CircularChard
                    maxValue={
                      evaluation.score_card.scale === "scale_to_ten"
                        ? 10
                        : evaluation.score_card.scale === "traffic_light"
                          ? 10
                          : 100
                    }
                    analysed={true}
                    fontSize="2xl"
                    value={evaluation.general_score.toFixed(2)}
                    valueText={`${evaluation.general_score.toFixed(2)}`}
                  />
                </div>
              </div>
              <div className="text-xs text-left mt-4">
                {evaluation.score_card.scale === "scale_to_ten"
                  ? t("general_score_calculated_over_ten")
                  : evaluation.score_card.scale === "traffic_light"
                    ? t("general_score_calculated_over_ten")
                    : t("general_score_calculated_over_one_hundred")}
              </div>
            </div>
          )}
        </Card>
      </div>

      <Card>
        <div className="text-lg font-semibold mb-4 border-b border-gray-lines">{t("details")}</div>

        {parents.map((parent) => (
          <div key={parent._id}>
            <div
              className="border-b border-gray-lines font-bold flex flex-row justify-between hover:bg-gray-100 px-2 hover:rounded-md transition-colors cursor-pointer mt-6"
              onClick={() => expandOrCollapse(parent._id)}
            >
              <span>
                {`${parent.name}`}{" "}
                {evaluation?.comments && evaluation.comments[parent._id] && (
                  <FaComment
                    className="inline ml-2 text-gray cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setCriteriaToSeeComment(parent._id);
                    }}
                  />
                )}
              </span>
              <FaChevronCircleDown
                data-panel={parent._id}
                className={"text-main inline-block mt-1"}
              />
            </div>

            <div id={parent._id} className="mt-2">
              {getChildren(parent).map((child) => (
                <div key={child._id}>
                  <div className="ml-4 font-bold mt-1 text-sm flex flex-row justify-between hover:bg-gray-100 px-2 rounded-md transition-colors">
                    <span>
                      {child.name}
                      {evaluation?.comments && evaluation.comments[child._id] && (
                        <FaComment
                          className="inline ml-2 text-gray cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            setCriteriaToSeeComment(child._id);
                          }}
                        />
                      )}
                    </span>
                    <span className={"text-main inline-block mt-1 mr-[1px]"}>
                      {evaluation.criterias_score[child._id]}
                    </span>
                  </div>
                </div>
              ))}

              {!hasChildren(parent) && (
                <div key={`${parent._id}_no_subcriteria`}>
                  <div className="ml-4 font-bold mt-1 text-sm flex flex-row justify-between hover:bg-gray-100 px-2 rounded-md transition-colors ">
                    <span>{t("no_subcriteria")}</span>
                  </div>
                </div>
              )}

              <div key={`${parent._id}_total`} className={"border-t border-gray-lines"}>
                <div className="ml-4 font-bold mt-1 text-sm flex flex-row justify-between hover:bg-gray-100 px-2 rounded-md transition-colors cursor-pointer">
                  <span>{t("total")}</span>
                  <span className={"text-main inline-block mt-1 mr-[1px]"}>
                    {getParentScore(parent)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Card>

      {evaluation.score_card && (

        <div className="my-6">
        <Card>
          <div className="text-lg font-semibold mt-4 mb-6 border-b border-gray-lines">
            {t("weigths")}
          </div>

          <ScoreCardInfo legend={false} criteria={evaluation.score_card.criteria} />
        </Card>
        </div>

      )}

      {evaluation?.score_card?.questionnaire?.questions &&
        evaluation.score_card.questionnaire.questions.length !== 0 && (
          <div className="my-6">
            <Card>
              <div className="text-lg font-semibold my-6 border-b border-gray-lines">
                {t("questions")}
              </div>
              <ApplicationQuestionnaireView
                applicationQuestions={evaluation.score_card.questionnaire.questions.map((q) => ({
                  question: { ...q.question_id },
                  answer: evaluation.answers[q.question_id._id],
                }))}
                lang={userLanguage}
                setLoading={setLoading}
                scoreCardId={evaluation.score_card._id}
              />
            </Card>
          </div>
      )}

      <div className="my-6">
        <Card>
          <div className="text-lg font-semibold mt-4 mb-6 border-b border-gray-lines">
            {t("metadata")}
          </div>
          <div className="flex gap-4">
          {Array.isArray(evaluation.createdAt) ? evaluation.createdAt.map((createdAt) => (
            <div className="mt-2 text-base text-left font-semibold" key={createdAt.toString()}>
              <FontAwesomeIcon className={"text-main text-sm mr-1"} icon="calendar" />
              {moment(createdAt).format("YYYY-MM-DD HH:MM")}
            </div>
          )) : (
            <div className="mt-2 text-base text-left font-semibold" key={evaluation.createdAt.toString()}>
            <FontAwesomeIcon className={"text-main text-sm mr-1"} icon="calendar" />
            {moment(evaluation.createdAt).format("YYYY-MM-DD HH:MM")}
          </div>
          )}
          </div>

        </Card>
      </div>
      {loading && <Loader />}
    </>
  );
};
