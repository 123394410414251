import { useTranslation } from "react-i18next";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import { FaChartLine, FaEye, FaPaperPlane, FaPercent, FaPlayCircle } from "react-icons/fa";
import LineChart from "components/ui/molecules/charts/LineChart";
import { Card } from "components/ui/atoms/Card";
import moment from "moment";

export const FormStatistics = ({ statistics, answersEvolutions, showAnswerEvolution = true }) => {
  const dateFormat = "MMM, D";

  const { t } = useTranslation();
  return (
    <>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4 my-10">
        <div className="relative bg-white py-6 px-6 rounded-2xl border border-gray-lines my-4 shadow-soft-white h-56">
          <div className=" text-white flex items-center absolute rounded-full py-4 px-4 shadow-xl bg-lightBlue-600 left-4 -top-6">
            <FaEye className="inline-block w-6 h-6" />
          </div>
          <div className="pt-6 flex flex-col justify-between h-full">
            <div>
              <p className="text-xl font-semibold my-2">{t("visualizations")}</p>
            </div>
            <div className="flex-1 flex font-bold justify-center items-center text-7xl">
              <div>
                {statistics.hasOwnProperty("visualizations") ? statistics.visualizations : "-"}
              </div>
            </div>
          </div>
        </div>

        <div className="relative bg-white py-6 px-6 rounded-2xl border border-gray-lines my-4 shadow-soft-white h-56">
          <div className="text-white flex items-center absolute rounded-full py-4 px-4 shadow-xl bg-green left-4 -top-6">
            <FaPlayCircle className="inline-block w-6 h-6" />
          </div>

          <div className="pt-6 flex flex-col justify-between h-full">
            <p className="text-xl font-semibold my-2">{t("forms_started")}</p>
            <div className="flex-1 flex font-bold justify-center items-center text-7xl">
              <div>
                {statistics.hasOwnProperty("forms_started") ? statistics.forms_started : "-"}
              </div>
            </div>
          </div>
        </div>

        <div className="relative bg-white py-6 px-6 rounded-2xl border border-gray-lines my-4 shadow-soft-white h-56">
          <div className="text-white flex items-center absolute rounded-full py-4 px-4 shadow-xl bg-yellow-400 left-4 -top-6">
            <FaPaperPlane className="inline-block w-6 h-6" />
          </div>
          <div className="pt-6 flex flex-col justify-between h-full">
            <div>
              <p className="text-xl font-semibold my-2">{t("responses")}</p>
            </div>

            <div className="flex-1 flex font-bold justify-center items-center text-7xl">
              <div>{statistics.hasOwnProperty("responses") ? statistics.responses : "-"}</div>
            </div>
          </div>
        </div>

        <div className="relative bg-white py-6 px-6 rounded-2xl border border-gray-lines my-4 shadow-soft-white h-56">
          <div className="text-white flex items-center absolute rounded-full py-4 px-4 shadow-xl bg-pink-600 left-4 -top-6">
            <FaPercent className="inline-block w-6 h-6" />
          </div>
          <div className="pt-6 flex flex-col justify-between h-full">
            <div>
              <p className="text-xl font-semibold my-2">{t("forms_completed")}</p>
            </div>
            <div className="flex-1 flex font-bold justify-center items-center text-7xl">
              <div>
                {statistics.hasOwnProperty("visualizations")
                  ? `${Number(statistics.visualizations > 0 ? (statistics.responses / statistics.visualizations) * 100 : 0).toFixed(0)}%`
                  : "-"}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showAnswerEvolution && (
        <div className="relative">
          <div className="text-white flex items-center absolute rounded-full py-4 px-4 shadow-xl bg-violet-600 ml-6 -top-6">
            <FaChartLine className="inline-block w-6 h-6" />
          </div>
          <Card>
            <div className="pt-8">
              <p className="text-xl font-semibold my-2">{t("responses")}</p>

              {!answersEvolutions && statistics.responses !== 0 && (
                <div>
                  <NoDataInfo title={t("period_without_form_responses")} />
                </div>
              )}
              {!answersEvolutions && statistics.responses === 0 && (
                <div>
                  <NoDataInfo title={t("form_responses_sample")} />
                </div>
              )}

              <div className="w-full h-56 mt-6">
                <LineChart
                  data={
                    answersEvolutions || [
                      {
                        id: "dealflow",
                        data: Array(31)
                          .fill(null)
                          .map((v, i) => ({
                            x: moment()
                              .subtract(31 - i, "week")
                              .format(dateFormat),
                            y: Math.floor(Math.random() * (20 - 2 + 1)) + 2,
                          })),
                      },
                    ]
                  }
                  sample={!answersEvolutions}
                />
              </div>
            </div>
          </Card>
        </div>
      )}
    </>
  );
};
