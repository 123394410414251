import { Modal } from "components/ui/molecules/Modal";
import { useEffect, useState } from "react";
import DocumentsService from "services/documents-service";
import { Pagination } from "../../../ui/atoms/Pagination";
import { Card } from "../../../ui/atoms/Card";
import { DataGrid } from "../../../ui/objects/DataGrid";
import { Trans, useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { isEqual } from "lodash";
import moment from "moment";
import {
  FaArrowLeft,
  FaSave,
  FaEye,
  FaFolder,
  FaPen,
  FaShareAlt,
  FaTrashAlt,
} from "react-icons/fa";
import { MdFolderShared } from "react-icons/md";
import LogoDefault from "styles/images/logo_default.png";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { Loader } from "components/ui/molecules/Loader";

const FoldersModal = ({ open, handleClose, documentToMove, onSubmit, startupId, filters }) => {
  const headerClass = "text-main font-bold text-sm border-b border-main text-left bg-white";

  const { t } = useTranslation();
  const { addToast } = useToasts();

  const [loading, setLoading] = useState(true);
  const [selectedFolder, setselectedFolder] = useState(null);
  const [folders, setFolders] = useState([]);
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 8,
    totalPages: 0,
    totalDocs: 0,
  });

  const getFolders = async() => {
    try {
      let result = null;
      result = await DocumentsService.getFolders(
        filters
          ? {
              page: pagination.page,
              rowsPerPage: pagination.rowsPerPage,
              ...filters,
            }
          : {
              page: pagination.page,
              rowsPerPage: pagination.rowsPerPage,
            },
        selectedFolder && selectedFolder._id,
      );
      //   }

      const { documents, pagination: responsePagination } = result.data.data;
      setFolders(documents);

      if (responsePagination) {
        const newPagination = {
          ...pagination,
          page:
            responsePagination.current >= responsePagination.pages
              ? responsePagination.pages - 1
              : responsePagination.current - 1,
          totalPages: responsePagination.pages,
          totalDocs: responsePagination.totalDocs,
        };

        if (!isEqual(newPagination, pagination)) {
          setPagination(newPagination);
        }
      }
    } catch (error) {
      addToast(
        error?.response?.data?.msg_key
          ? t(error.response.data.msg_key)
          : t("error_occurred_retrieving_documents"),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getFolders().finally(() => setLoading(false));
  }, [selectedFolder, pagination]);

  return (
    <Modal showModal={open} showCloseModal={true} onClose={handleClose}>
      <div style={{ minWidth: "50vw" }}>
        <div>
          <h3>{documentToMove.name}</h3>
        </div>
        <div className="text-sm font-semibold flex mt-4 flex justify-between items-center">
          <div className="flex">
            {selectedFolder && (
              <div className="cursor-pointer">
                <FaArrowLeft
                  className="inline-block mr-3 cursor-pointer"
                  onClick={() => setselectedFolder(selectedFolder.parent_folder || null)}
                />
              </div>
            )}
            <Trans
              i18nKey="viewing_current_folder"
              values={{ folder_name: selectedFolder ? selectedFolder.name : t("root_folder") }}
            />
          </div>
          <ButtonSecondary
            marginLeft={"auto"}
            text={t("move_to_root")}
            onClick={() => onSubmit(null)}
          />{" "}
          {/** null = raiz */}
        </div>
        {loading && <Loader />}
        {folders.length === 0 && (
          <>
            <div className="mt-6 h-20">
              <NoDataInfo title={t("documents_not_found")} />
            </div>
          </>
        )}
        {folders.length !== 0 && (
          <>
            <div className="mt-6">
              <Card>
                <DataGrid
                  loading={loading}
                  actionsHeaderClassName={headerClass}
                  data={folders.map((d) => ({ ...d, id: d._id }))}
                  headers={[
                    {
                      key: "name",
                      title: t("name"),
                      headerClassName: headerClass,
                      render: (key, doc) => (
                        <div
                          className={`flex h-10 flex-col justify-center ${doc.is_folder && "cursor-pointer hover:font-semibold"}`}
                          onClick={() => (doc.is_folder ? setselectedFolder(doc) : null)}
                        >
                          <div className="flex flex-row items-center">
                            {doc.is_folder && (doc.lps_shared || doc.members_shared) && (
                              <MdFolderShared className="text-lg" />
                            )}
                            {doc.is_folder && !doc.lps_shared && !doc.members_shared && (
                              <FaFolder className="text-lg" />
                            )}

                            <div className="ml-2">{doc.name}</div>
                          </div>
                        </div>
                      ),
                    },
                    {
                      key: "startup",
                      title: t("startup"),
                      headerClassName: headerClass,
                      render: (key, doc) => (
                        <>
                          {doc.startup ? (
                            <div className="flex">
                              <img
                                src={doc.startup.logo || LogoDefault}
                                alt={doc.startup.name}
                                className="h-10 w-14 object-contain cursor-pointer"
                              />
                              {doc.startup.name && (
                                <div className="flex flex-col justify-center text-xs font-semibold">
                                  <span className="ml-2">{doc.startup.name}</span>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div>-</div>
                          )}
                        </>
                      ),
                    },
                    {
                      key: "createdAt",
                      title: t("date"),
                      headerClassName: headerClass,
                      render: (key, document) => moment(document.createdAt).format("DD/MM/YYYY"),
                    },
                  ]}
                  actions={[
                    {
                      id: "see",
                      tip: t("see"),
                      onClick: (document) => {
                        if (document.is_folder) {
                          setselectedFolder(document);
                        }
                      },
                      show: (document) => true,
                      buttonProps: {
                        iconComponent: <FaEye />,
                      },
                    },
                    {
                      id: "save",
                      tip: t("save"),
                      onClick: (document) => {
                        if (document.is_folder) {
                          onSubmit(document._id);
                        }
                      },
                      show: (document) => true,
                      buttonProps: {
                        iconComponent: <FaSave />,
                      },
                    },
                  ]}
                />
                <Pagination
                  paginateOptions={[8]}
                  currentPage={pagination.page}
                  setCurrentPage={(page) => setPagination({ ...pagination, page })}
                  perPage={pagination.rowsPerPage}
                  pages={pagination.totalPages}
                  setPerPage={(value) =>
                    setPagination({ ...pagination, page: 0, rowsPerPage: value[0].id })
                  }
                />
              </Card>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default FoldersModal;
