/* eslint-disable default-case */
import produce from "immer";

import { ChecksActionsTypes } from "./checks-actions-types";
import { UserActionsTypes } from "../user/user-actions-types";

const { SET_CHECKED, SET_STAGE } = ChecksActionsTypes;
const { RESET: LOGOUT } = UserActionsTypes;

const defaultState = {
  checked: false,
  stage: null,
};

const ChecksReducer = (state = defaultState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_CHECKED:
        draft.checked = action.payload.checked;
        break;
      case SET_STAGE:
        draft.stage = action.payload.stage;
        break;
      case LOGOUT:
        return defaultState;
    }
  });

export default ChecksReducer;
