import { useTranslation } from "react-i18next";
import { ScoreCardCriteriaGraph } from "components/ui/molecules/ScoreCardGraphCriteriasGraph";
import { Card } from "components/ui/atoms/Card";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import Select from "components/ui/atoms/Select";
import { useState, useEffect } from "react";
import ScoreCardUtils from "utils/score-card-utils";

export function ScoreCardInfo({ legend, criteria, showSelector = true }) {
  const { t } = useTranslation();
  const [weightOptions, setWeightOptions] = useState([]);
  const [selectedWeight, setSelectedWeight] = useState({
    id: "main",
    value: t("main_criteria"),
  });

  const hasChildren = (currentCriteria, isId = false) => {
    return ScoreCardUtils.hasChildren(currentCriteria, criteria, isId);
  };

  useEffect(() => {
    if (criteria) {
      const options = [
        { id: "main", value: t("main_criteria") },
        ...ScoreCardUtils.getCriteriaParents(criteria).map((parent) => ({
          id: parent._id,
          value: parent.name,
        })),
      ];

      setWeightOptions(options);
      setSelectedWeight(options.length ? options[0] : []);
    }
  }, [criteria]);

  if (!criteria) {
    return null;
  }

  return (
    <>
      {showSelector && (
        <div className="mt-6">
          <Select
            label={t("criterias")}
            initialValues={[selectedWeight]}
            placeholder={t("criterias")}
            onSelect={(value) => setSelectedWeight(value[0])}
            items={weightOptions}
            isClearable={false}
          />
        </div>
      )}

      {selectedWeight && criteria && (
        <>
          {selectedWeight.id === "main" || hasChildren(selectedWeight.id, true) ? (
            <div className="h-64 px-1 text-xs">
              <ScoreCardCriteriaGraph
                legend={legend}
                criteria={criteria}
                filter={selectedWeight.id}
              />
            </div>
          ) : (
            <Card wrapperClassName="mt-6">
              <NoDataInfo title={t("no_subcriteria")} textOne={t("no_subcriteria_description")} />
            </Card>
          )}
        </>
      )}
    </>
  );
}
