import Config from "config";

export function TextArea({
  reference,
  error,
  label,
  placeholder,
  rows = 4,
  required = false,
  className = "",
  ...inputProps
}) {
  return (
    <div className={className}>
      <label htmlFor={label} className="block mb-1 text-left text-xs font-medium text-black flex">
        {label} {required && <span className="text-red inline-block mx-1">*</span>}
      </label>

      <textarea
        ref={reference}
        {...inputProps}
        rows={rows}
        placeholder={placeholder}
        required={required}
        className={`bg-white w-full h-auto py-3 px-7 text-left text-xs font-normal rounded-lg placeholder-gray shadow-soft-white border border-gray-lines cursor-pointer transition-all duration-200 outline-none hover:border-main hover:outline-none hover:shadow-inner focus:outline-none focus:shadow-focus active:outline-none resize-none`}
      />

      {error && <div className="text-red relative text-xs">{error.message}</div>}
    </div>
  );
}
