import axios from "axios/index";
import Config from "config";

const MembersService = {
  getMembers: async(params) => axios.post(`${Config.MEMBERS_API_URL}search`, params),

  createMember: async(data) => axios.post(`${Config.MEMBERS_API_URL}`, data),

  getMember: async(memberId) => axios.get(`${Config.MEMBERS_API_URL}${memberId}`),

  getMemberDealflow: async(memberId, query) => axios.get(`${Config.MEMBERS_API_URL}${memberId}/dealflow`, query),

  deleteMember: async(memberId) => axios.delete(`${Config.MEMBERS_API_URL}${memberId}`),

  sendActivationCode: async(memberId) => axios.post(`${Config.MEMBERS_API_URL}${memberId}/send-activation-code`),
};
export default MembersService;
