import axios from "axios/index";
import Config from "../config";

const FormsService = {
  getQuestionnairesQuestions: async(params) =>
    axios.post(`${Config.FORMS_API_URL}get-questions`, params),

  getQuestionnairesQuestion: async(id) => axios.get(`${Config.FORMS_API_URL}questions/id/${id}`),

  createQuestionnairesQuestion: async(data) =>
    axios.post(`${Config.FORMS_API_URL}questions`, data),

  deleteQuestionnairesQuestion: async(id) =>
    axios.delete(`${Config.FORMS_API_URL}questions/${id}`),

  updateQuestionnairesQuestion: async(id, data) =>
    axios.patch(`${Config.FORMS_API_URL}questions/${id}`, data),

  getQuestionnairesTemplates: async(params) => {
    const query = new URLSearchParams(params);
    return axios.get(`${Config.FORMS_API_URL}templates?${query}`);
  },

  getQuestionnairesTemplate: async(id, isPublic = false) =>
    axios.get(`${Config.FORMS_API_URL}templates/${id}`, {
      headers: isPublic ? { "X-Public": "true" } : {},
    }),

  getQuestionnairesForms: async(params) => {
    const query = new URLSearchParams(params);
    return axios.get(`${Config.FORMS_API_URL}forms?${query}`);
  },

  getQuestionnairesForm: async(id, isPublic) =>
    axios.get(
      `${Config.FORMS_API_URL}forms/${id}`,
      isPublic ? { headers: { "X-Public": "true" } } : {},
    ),

  createQuestionnairesForm: async(data) => axios.post(`${Config.FORMS_API_URL}forms`, data),

  deleteQuestionnairesForm: async(id) => axios.delete(`${Config.FORMS_API_URL}forms/${id}`),

  updateQuestionnairesForm: async(id, data) =>
    axios.patch(`${Config.FORMS_API_URL}forms/${id}`, data),

  publishQuestionnairesForm: async(data) =>
    axios.post(`${Config.FORMS_API_URL}forms/publish`, data),

  importQuestions: async(data) =>
    await axios.post(`${Config.FORMS_API_URL}questions/import`, data),

  getPublishedForm: async(formId, isPublic = false) =>
    axios.get(`${Config.FORMS_API_URL}forms/${formId}`, {
      headers: { "X-Published": "true", "X-Public": isPublic ? "true" : "false" },
    }),

  getDraftForm: async(formId, isPublic = false) =>
    axios.get(`${Config.FORMS_API_URL}forms/${formId}`, {
      headers: {
        "X-Draft": "true",
        "X-Public": isPublic ? "true" : "false",
      },
    }),

  saveFormApplication: (formId, data) =>
    axios.post(`${Config.FORMS_API_URL}forms/${formId}/application`, data, {
      headers: {
        "X-Public": "true",
      },
    }),

  saveAutomatedFormApplication: (formId, data) =>
    axios.post(`${Config.FORMS_API_URL}shared-forms/${formId}/application`, data, {
      headers: {
        "X-Public": "true",
      },
    }),

  getQuestionnaireAnswers: (formId, params, isPublic = false) =>
    axios.get(`${Config.FORMS_API_URL}forms/${formId}/applications`, {
      params,
      headers: {
        "X-Public": isPublic ? "true" : "false",
      },
    }),

  exportResponses: async(formId) =>
    axios.get(`${Config.FORMS_API_URL}forms/${formId}/applications/export`),

  getQuestionnaireKeyQuestions: (targetRole) =>
    axios.get(`${Config.FORMS_API_URL}key-questions`, {
      params: {
        target_role: targetRole,
      },
    }),

  sendQuestionnarieToDeal: async(formId, dealId) =>
    axios.post(`${Config.FORMS_API_URL}forms/${formId}/deals/${dealId}`),

  sendQuestionnarieToMember: async(formId, memberId) =>
    axios.post(`${Config.FORMS_API_URL}forms/${formId}/members/${memberId}`),
};
export default FormsService;
