import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Loader } from "../ui/molecules/Loader";
import { SectionHeader } from "../ui/molecules/SectionHeader";
import { useToasts } from "react-toast-notifications";
import { SharedEventFooter } from "components/events/components/event/components/SharedEventFooter";

import { PageTitle } from "components/ui/atoms/PageTitle";
import UnauthorizedKmZeroReport from "components/ui/objects/UnauthorizedKmZeroReport";
import FormsService from "services/forms-service";
import { usePagination } from "hooks/usePagination";
import { FormAnswers } from "components/qe-forms/components/forms-form/FormAnswers";
import Board from "components/pipeline-manager/components/board";

const EMAILS = {
  "cestrada@kiota.com": "Cristian Estrada",
  "nromero@kmzerohub.com": "Nora Romero",
  "jrequena@kmzerohub.com": "Juan Requena",
  "szhu@kmzerohub.com": "Sophia Zhu",
  "pablo.lamsfus@korosti.es": "Pablo Lamsfus",
  "marta.laorden@mcwin.fund": "Marta Laorden",
  "jdeantonio@ship2bventures.com": "Jaime de Antonio",
  "alava@clave.capital": "Pedro de Álava",
  "akulisic@thefoodtechlab.com": "Akulisic",
  "da@unovis.vc": "DA (Unovis)",
  "juancividanesroger@thefoodtechlab.com": "Juan Cividanes",
  "giancarlo@fiveseasons.vc": "Giancarlo Addario",
  "cjacoster@mahou-sanmiguel.com": "C Jacoster",
  "rgonzaleze@mahou-sanmiguel.com": "R Gonzalez",
  "aarranzs@mahou-sanmiguel.com": "A Agarranzs",
  "aguzman@arancia.com.mx": "A Guzman",
  "laia.alemany@vickyfoods.es": "Laia Alemany",
  "eduardo.lobo@vickyfoods.es": "Eduardo Lobo",
  "joaquin.rio@vickyfoods.es": "Joaquín Rio",
  "mgarcia@incarlopsa.es": "M García",
  "r.castilla@makro.es": "R Castillla (Makro)",
  "martha.gomez01@makro.es": "Martha Gomez (Makro)",
  "mbertomeu@helados-estiu.com": "M Bertomeu",
  "daniele.calzolari@me.com": "Daniele Calzolari",
  "ruben.hidalgo@capsa.es": "Ruben Hidalgo",
  "pvillanueva@embutidosmartinez.es": "PVillanueva",
  "rlacomba@familia-martinez.es": "RLacomba",
  "maria.juez@makro.es": "Maria Juez",
  "gonzalo@arpegio.vc": "Gonzalo Arpegio",
  "andres@arpegio.vc": "Andres Arpegio",
  "william@peakbridge.vc": "William Peakbridge",
  "beatriz@peakbridge.vc": "Beatriz Peakbridge",
  "eva@peakbridge.vc": "Eva Peakbridge",
  "owen@peakbridge.vc": "Owen Peakbridge",
  "jalcaraz@platostradicionales.com": "J Alcaraz",
  "josep.fuster@vickyfoods.es": "Josep Fuster",
  "paco@embutidosmartinez.es": "Paco Embutidos Martinez",
  "cdacunha@kmzerohub.com": "Claudia Da Cunha",
  "jcassagne@kmzerohub.com": "Justine Cassagne",
  "pascual.tolsa@vickyfoods.es": "Pascual Tolsa",
  "aaguilar@embutidosmartinez.es": "A Aguilar",
  "mlages@mahou-sanmiguel.com": "M Lages",
};

export const KmZeroReport = () => {
  const wrapperElement = useRef(null);
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const [deal, setDeal] = useState(null);
  const [section, setSection] = useState("pipeline_manager");
  const [sections, setSections] = useState(["pipeline_manager", "startups"]);
  const [authorized, setAuthorized] = useState(false);
  const [email, setEmail] = useState(localStorage.getItem("X-KMZ-REP-EMAIL"));
  const [sharedDealflow, setSharedDealflow] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalStartups, setTotalStartups] = useState(0);
  const [dealInfo, setDealInfo] = useState(null);
  const [formAnswers, setFormAnswers] = useState([]);
  const [questionnaire, setQuestionnaire] = useState(null);
  const [board, setBoard] = useState(null);

  const { setTotalPages, totalPages, setPage, page, rowsPerPage, setRowsPerPage } =
    usePagination(12);

  useEffect(() => {
    const checkAccess = async() => {
      if (EMAILS[email.toLocaleLowerCase()]) {
        setAuthorized(true);
      } else {
        addToast(t("forbidden_access_to_report"), {
          appearance: "error",
          autoDismiss: true,
        });
      }
    };

    if (email) {
      localStorage.setItem("X-KMZ-REP-EMAIL", email);
      checkAccess();
    }
  }, [email]);

  useEffect(() => {
    if (wrapperElement.current) {
      wrapperElement.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, []);

  const getForm = async() => {
    setLoading(true);
    return FormsService.getQuestionnairesForm("6423021a10f9e261a95ec61f", true)
      .then((response) => {
        setQuestionnaire(response.data.data);
      })
      .catch(() => {
        addToast(t("error_retrieving_form"), {
          appearance: "error",
          autoDismiss: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getQuestionnaireAnswers = () => {
    setLoading(true);
    FormsService.getQuestionnaireAnswers(
      "6423021a10f9e261a95ec61f",
      {
        rowsPerPage,
        page,
        sortBy: "-createdAt",
      },
      true,
    )
      .then((result) => {
        const { applications, pagination } = result.data;
        setTotalStartups(pagination.totalDocs);
        setFormAnswers(applications);
        setTotalPages(pagination.pages);
      })
      .catch(() => {
        addToast(t("error_retrieving_startups"), {
          appearance: "error",
          autoDismiss: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (authorized) {
      getForm();
      getQuestionnaireAnswers();
    }
  }, [authorized]);

  useEffect(() => {
    if (questionnaire) {
      getQuestionnaireAnswers();
    }
  }, [questionnaire, page, rowsPerPage]);

  if (!authorized) {
    return (
      <>
        {loading && <Loader />}
        <UnauthorizedKmZeroReport
          loading={loading}
          onRequestAccess={setEmail}
          description={t("access_control_description_report")}
          buttonText={t("access_report")}
        />
      </>
    );
  }

  return (
    <div className="flex flex-col min-h-screen" ref={wrapperElement}>
      <section className="px-8 my-8 flex-1">
        <PageTitle title={"Reporte Segunda Convocatoria KM ZERO"} />
        <div className="flex items-start">
          <div className="flex-1 mb-6">
            <SectionHeader
              sectionTitles={sections.map((section) => t(section))}
              sectionKeys={sections}
              section={section}
              setSection={setSection}
            />
          </div>
        </div>

        <div className={"text-2xl font-semibold text-secondary"}>
          {t("total_startups_applied_currently", { startups: totalStartups })}
        </div>
        {section === "startups" && (
          <FormAnswers
            questions={questionnaire.questions}
            answers={formAnswers}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            totalPages={totalPages}
            setRowsPerPage={setRowsPerPage}
            shared={true}
          />
        )}
        {section === "pipeline_manager" && (
          <Board identifier={"6408a9ee818880325fc1c96c"} editable={false} />
        )}
      </section>
      <SharedEventFooter />
      {loading && <Loader />}
    </div>
  );
};
