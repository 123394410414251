import { FaArrowLeft } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";

export const PageTitle = ({ title, showBack = false, onBackClick, removeMargin = false }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const backClick = () => {
    if (onBackClick) {
      onBackClick();
    } else if (location.state && location.state.from) {
      navigate(location.state.from);
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="flex">
      <div className={`text-3xl text-main items-center font-bold ${removeMargin ? "" : "mb-4"}`}>
        {showBack && (
          <FaArrowLeft className="inline-block mr-3 cursor-pointer" onClick={backClick} />
        )}
        {title}
      </div>
    </div>
  );
};
