import React, {
  useState,
  useEffect,
  useMemo,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import ReactTooltip from "react-tooltip";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
import { Loader } from "components/ui/molecules/Loader";
import { useSwal } from "hooks/useSwal";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FaListUl, FaTrash, FaEdit, FaPlus, FaStar, FaTimes } from "react-icons/fa";
import Avatar from "react-avatar";
import Sectors from "assets/json/sectors.json";
import Subsectors from "assets/json/subsectors.json";
import FormUtils from "../../../utils/form-utils";
import { SectionHeader } from "../../ui/molecules/SectionHeader";
import { Input } from "../../ui/atoms/Input";
import { useNavigate } from "react-router-dom";

import InputFile from "../../ui/atoms/input-files";
import { TextArea } from "../../ui/atoms/TextArea";
import { StartupEditCaptable } from "./StartupEditCaptable";
import { StartupEditInvestments } from "./StartupEditInvestments";
import { Autocomplete } from "../../ui/atoms/Autocomplete";
import { StartupEditYearMonthsInput } from "./StartupEditYearMonthsInput";
import Select from "../../ui/atoms/Select";
import { StartupEditCompetitors } from "./StartupEditCompetitors";
import { StartupEditReferrals } from "./StartupEditReferrals";
import Config from "config";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { ButtonDanger } from "components/ui/atoms/ButtonDanger";
import { DealEditTeamMembers } from "./DealEditTeamMembers";
import { StartupEditCustomFields } from "./StartupEditCustomFields";
import useUser from "hooks/useUser";
import { CurrencyInput } from "components/ui/atoms/CurrencyInput";
import { DealflowService } from "services/dealflow-service";
import { CustomFieldsService } from "services/custom-fields-service";
import Countries from "assets/json/countries.json";
import BusinessModels from "assets/json/business-models.json";
import ProjectStages from "assets/json/project-stages.json";
import InvestmentStages from "assets/json/investment-stages.json";
import { ButtonCardSecondary } from "components/ui/atoms/ButtonCardSecondary";
import { RoutesLinks } from "components/routes-links";

const ProjectCreateOrUpdate = forwardRef(
  (
    {
      info,
      onSubmit,
      onChangeStartupMembers,
      isDealflow = false,
      onChangeCaptableShareholders,
      onChangeCompetitors,
      onChangeReferrals,
      showSubmitButton = true,
      investments,
      onChangeInvestments,
    },
    ref,
  ) => {
    const { addToast } = useToasts();
    const { confirm, alert } = useSwal();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [section, setSection] = useState("general_info");
    const [sectors, setSectors] = useState([]);
    const [subSectors, setSubSectors] = useState([]);
    const COUNTRIES = Countries.map((item) => ({
      ...item,
      id: item._id,
      name: t(item.key),
    }));
    const navigate = useNavigate();

    const { user, checkIfLPhasAccessToSection } = useUser();

    const BUSINESS_MODELS = BusinessModels.map((bm) => ({ value: t(bm.key), id: bm._id }));

    const PROJECT_STAGES = ProjectStages.map((ps) => ({ value: t(ps.key), id: ps._id }));

    const INVESTMENT_STAGES = InvestmentStages.map((is) => ({
      ...is,
      id: is._id,
      name: t(is.key),
    }));
    const [otherInterestingAreas, setOtherInterestingAreas] = useState([]);
    const [customFields, setCustomFields] = useState([]);
    const [dealflowCustomFields, setDealflowCustomFields] = useState([]);
    const customFieldsRequired = useRef(false);
    const [otherEmails, setOtherEmails] = useState([]);

    const sectionKeys = useMemo(
      () =>
        [
          "general_info",
          "contact_info",
          "market",
          "socials",
          "kpis",
          "financing",
          isDealflow ? "investments" : null,
          isDealflow ? "team_dealflow" : null,
          "competitors",
          "referrals",
          "captable",
          "custom_fields",
        ].filter(Boolean),
      [info, isDealflow],
    );

    const SECTIONS = useMemo(
      () =>
        sectionKeys.map((key) => ({
          id: key,
          route: key,
          title: key,
        })),
      [sectionKeys],
    );

    const validationShape = useMemo(
      () => ({
        name: Yup.string().required(t("required_field")),
        cif: Yup.string(),
        logo: Yup.string().nullable(),
        sector: Yup.string().nullable(),
        subsector: Yup.string().nullable(),
        other_interesting_areas: Yup.array().default([]),
        business_model: Yup.string().nullable(),
        project_stage: Yup.string().nullable(),
        investment_stage: Yup.string().nullable(),
        country_incorporation: Yup.string().nullable(),
        city_incorporation: Yup.string().nullable(),
        brand_statement: Yup.string().nullable(),
        presentation_video: Yup.string().nullable(),
        problem: Yup.string().nullable(),
        solution: Yup.string().nullable(),
        business_description: Yup.string().nullable(),
        market: Yup.string().nullable(),
        captable: Yup.array().default([]),
        competitors: Yup.array().default([]),
        referrals: Yup.array().default([]),
        economic_income: Yup.object().default({}),
        economic_resources: Yup.object().default({}),
        economic_spending: Yup.object().default({}),
        churn_rate: Yup.object().default({}),
        priority: Yup.string().nullable(),
        next_step: Yup.string().nullable(),
        profile_visibility: Yup.string().nullable(),
        gmv: Yup.object().default({}),
        nps: Yup.object().default({}),
        aov: Yup.number()
          .typeError(t("incorrect_format"))
          .transform((_, val) => (val === `${Number(val)}` ? Number(val) : null))
          .nullable(),
        cac: Yup.number()
          .typeError(t("incorrect_format"))
          .transform((_, val) => (val === `${Number(val)}` ? Number(val) : null))
          .nullable(),
        ltv: Yup.number()
          .typeError(t("incorrect_format"))
          .transform((_, val) => (val === `${Number(val)}` ? Number(val) : null))
          .max(100, t("incorrect_format"))
          .nullable(),
        investment_desired: Yup.number()
          .typeError(t("incorrect_format"))
          .transform((_, val) => (val === `${Number(val)}` ? Number(val) : null))
          .nullable(),
        investment_desired_unit: Yup.string().nullable(),
        valuation: Yup.number()
          .typeError(t("incorrect_format"))
          .transform((_, val) => (val === `${Number(val)}` ? Number(val) : null))
          .nullable(),
        valuation_unit: Yup.string().nullable(),
        compromised_amount: Yup.number()
          .typeError(t("incorrect_format"))
          .transform((_, val) => (val === `${Number(val)}` ? Number(val) : null))
          .nullable(),
        compromised_amount_unit: Yup.string().nullable(),
        minimum_ticket: Yup.number()
          .typeError(t("incorrect_format"))
          .transform((_, val) => (val === `${Number(val)}` ? Number(val) : null))
          .nullable(),
        minimum_ticket_unit: Yup.string().nullable(),
        web: Yup.string()
          .transform((value) => (!value ? null : value))
          .matches(FormUtils.URL_REGEX, t("incorrect_format"))
          .nullable(),
        crunchbase_url: Yup.string()
          .transform((value) => (!value ? null : value))
          .matches(FormUtils.URL_REGEX, t("incorrect_format"))
          .nullable(),
        linkedin: Yup.string()
          .transform((value) => (!value ? null : value))
          .matches(FormUtils.URL_REGEX, t("incorrect_format"))
          .nullable(),
        twitter: Yup.string()
          .transform((value) => (!value ? null : value))
          .matches(FormUtils.URL_REGEX, t("incorrect_format"))
          .nullable(),
        instagram: Yup.string()
          .transform((value) => (!value ? null : value))
          .matches(FormUtils.URL_REGEX, t("incorrect_format"))
          .nullable(),
        youtube: Yup.string()
          .transform((value) => (!value ? null : value))
          .matches(FormUtils.URL_REGEX, t("incorrect_format"))
          .nullable(),
        tiktok: Yup.string()
          .transform((value) => (!value ? null : value))
          .matches(FormUtils.URL_REGEX, t("incorrect_format"))
          .nullable(),
        facebook: Yup.string()
          .transform((value) => (!value ? null : value))
          .matches(FormUtils.URL_REGEX, t("incorrect_format"))
          .nullable(),
        creation_date: Yup.string()
          .nullable()
          .transform((value) => FormUtils.parseDateFromString(value, "YYYY-MM-DD HH:mm")),
        contact_persons: Yup.string().nullable(),
        main_contact_person: Yup.string().nullable(),
        main_contact_phone: Yup.string().nullable(),
        main_contact_email: Yup.string().email(t("incorrect_format")).nullable(),
        main_contact_linkedin: Yup.string()
          .transform((value) => (!value ? null : value))
          .matches(FormUtils.URL_REGEX, t("incorrect_format"))
          .nullable(),
        contact: Yup.string().nullable(),
        other_emails: Yup.array(),
      }),
      [],
    );

    const { register, handleSubmit, trigger, errors, setValue, watch, reset } = useForm({
      resolver: yupResolver(Yup.object().shape(validationShape), { abortEarly: false }),
      criteriaMode: "all",
      reValidateMode: "all",
      mode: "onChange",
    });

    const _setValue = (name, value, config = {}) => {
      setValue(name, value, config);
      trigger(name);
    };

    const onChangeCustomFields = (id, value) => {
      const tempCustomFields = {
        ...dealflowCustomFields,
        [id]: value,
      };

      setDealflowCustomFields(tempCustomFields);
    };

    const _onSubmit = () => {
      handleSubmit(
        (values) => {
          if (values.creation_date) {
            values.creation_date = new Date(values.creation_date);
          }
          const emailsValid = otherEmails.every((email) => validateEmail(email));
          if (!emailsValid) {
            alert({
              title: t("error_email_validation"),
              text: t("error_email_validation_text"),
            });
            return false;
          }
          let customFieldsRequiredError = false;
          customFieldsRequired.current = false;

          if (Object.keys(dealflowCustomFields).length) {
            values.custom_fields = Object.keys(dealflowCustomFields).map((key) => {
              const findedCustomFieldIsRequired = customFields.find(
                (customField) => customField._id === key,
              )?.required;
              let options = [];

              let value = "";
              if (Array.isArray(dealflowCustomFields[key])) {
                options = dealflowCustomFields[key].map((v) => v.id);
                if (findedCustomFieldIsRequired && !options.length) {
                  customFieldsRequiredError = true;
                }
              } else {
                value = dealflowCustomFields[key];
                if (findedCustomFieldIsRequired && [null, undefined].includes(value)) {
                  customFieldsRequiredError = true;
                }
              }
              return {
                custom_field: key,
                value,
                options,
              };
            });
          }
          if (customFieldsRequiredError) {
            customFieldsRequired.current = true;
            addToast(t("form_field_error"), { appearance: "error", autoDismiss: true });
            return;
          }

          if (!isUpdate) {
            confirm().then((isConfirmed) => {
              if (isConfirmed) {
                onSubmit(values);
              }
            });
          } else {
            if (
              (info.cif && values.cif !== info.cif) ||
              (info.main_contact_email && values.main_contact_email !== info.main_contact_email)
            ) {
              confirm({
                text: t("cif_or_main_contact_email_updated_warning"),
                icon: "warning",
              }).then((isConfirmed) => {
                if (isConfirmed) {
                  onSubmit(values);
                }
              });
            } else {
              onSubmit(values);
            }
          }
        },
        (error) => {
          console.error(error);
          addToast(t("form_field_error"), { appearance: "error", autoDismiss: true });
        },
      )();
    };

    const onSelectImage = async(file) => {
      const fileSizeMb = (file?.size || 0) / 1024 / 1024;

      if (fileSizeMb > 5) {
        addToast(t("error_file_size", { max_size: 5 }), {
          appearance: "error",
          autoDismiss: true,
        });
        return;
      }

      setLoading(true);

      _setValue("logo", file ? await FormUtils.fileToBase64(file) : null, { shouldValidate: true });

      setTimeout(() => setLoading(false), 500);
    };

    const Priorities = [
      { id: "low", value: t("low_priority") },
      { id: "medium", value: t("medium_priority") },
      { id: "high", value: t("high_priority") },
    ];

    const NextSteps = [
      { id: "initial_meeting", value: t("initial_meeting") },
      { id: "contact_by_email", value: t("contact_by_email") },
      { id: "intro", value: t("intro") },
    ];

    const getSectors = () => {
      const _sectors = Sectors.map((item) => ({ name: t(item.key), id: item._id })).sort((a, b) =>
        a.name > b.name ? 1 : -1,
      );
      setSectors(_sectors);
      return Promise.resolve();
    };

    const getCustomFields = () => CustomFieldsService.getCustomFields().then((result) => {
      setCustomFields(result.data.data || []);
    });

    const onSelectSector = (sector) => {
      if (sector) {
        const _subSectors = Subsectors.filter((s) => s.sectors.includes(sector.id))
          .map((item) => ({ name: t(item.key), id: item._id }))
          .sort((a, b) => (a.name > b.name ? 1 : -1));
        setSubSectors(_subSectors);
      } else {
        onSelectSubSector(null);
        setSubSectors([]);
      }

      _setValue("sector", sector ? sector.id : null);
    };

    const onSelectSubSector = (subSector) => {
      if (subSector) {
        const _otherAreas = Subsectors.filter((s) => s._id !== subSector.id)
          .map((s) => ({ value: t(s.key), id: s._id }))
          .sort((a, b) => (a.name > b.name ? 1 : -1));
        setOtherInterestingAreas(_otherAreas);
      } else {
        _setValue("other_interesting_areas", []);
        setOtherInterestingAreas([]);
      }

      _setValue("subsector", subSector ? subSector.id : null);
    };

    const getSelectableInitialValues = (id, collection, lonely) => {
      if (!id) {
        return lonely ? null : [];
      }

      if (Array.isArray(id)) {
        return collection.filter((i) => id.includes(i.id));
      }

      const item = collection.find((i) => i.id === id);
      return item ? (lonely ? item : [item]) : lonely ? null : [];
    };

    const hydrateForm = () => {
      Object.keys(validationShape).forEach((key) => {
        setValue(key, info[key]);
      });

      if (info.custom_fields) {
        const data = {};
        info.custom_fields.forEach((item) => {
          if (item.value) {
            data[item.custom_field._id] = item.value;
          } else {
            data[item.custom_field._id] = item.options.map((i) => {
              const findCF = item.custom_field.options.find((io) => io._id === i);
              return { id: findCF._id, value: findCF.value };
            });
          }
        });
        setDealflowCustomFields(data);
      }

      if (info.creation_date) {
        setValue(
          "creation_date",
          FormUtils.getDateInputValueFromDate(new Date(info.creation_date)),
        );
      }

      setValue("sector", info.sector ? info.sector._id : null);
      setValue("subsector", info.subsector ? info.subsector._id : null);
      setValue("business_model", info.business_model ? info.business_model._id : null);
      if (isDealflow) {
        setValue("priority", info.priority || null);
        setValue("next_step", info.next_step || null);
      }
      setValue("project_stage", info.project_stage ? info.project_stage._id : null);
      setValue("investment_stage", info.investment_stage ? info.investment_stage._id : null);
      setValue("captable", info.captable ? info.captable : []);
      setValue(
        "country_incorporation",
        info.country_incorporation ? info.country_incorporation._id : null,
      );
      setValue(
        "investment_desired_unit",
        info.investment_desired_unit ? info.investment_desired_unit : "k",
      );
      setValue("minimum_ticket_unit", info.minimum_ticket_unit ? info.minimum_ticket_unit : "k");

      setValue("valuation_unit", info.valuation_unit ? info.valuation_unit : "k");

      setValue(
        "compromised_amount_unit",
        info.compromised_amount_unit ? info.compromised_amount_unit : "k",
      );

      setValue(
        "other_interesting_areas",
        info.other_interesting_areas ? info.other_interesting_areas : [],
      );
      setValue("economic_resources", info.economic_resources ? info.economic_resources : {});
      setValue("economic_income", info.economic_income ? info.economic_income : {});
      setValue("economic_spending", info.economic_spending ? info.economic_spending : {});
      setValue("churn_rate", info.churn_rate ? info.churn_rate : {});
      setValue("gmv", info.gmv ? info.gmv : {});
      setValue("nps", info.nps ? info.nps : {});
      if (info.other_emails) {
        setValue("other_emails", info.other_emails);
        setOtherEmails(info.other_emails);
      }

      trigger();
    };

    const getFieldData = () => Promise.all([getSectors(), getCustomFields()]);

    const haveStartup = () => info && Object.keys(info).length > 0;

    const validateEmail = (email) => {
      const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      return email.match(validRegex);
    };

    const addOtherEmail = () => {
      const emailsValid = otherEmails.every((email) => validateEmail(email));
      if (emailsValid) {
        const email = "";
        setOtherEmails((prevArray) => [...prevArray, email]);
      } else {
        alert({
          title: t("error_email_validation"),
          text: t("error_email_validation_text"),
        });
      }
    };

    const updateOtherEmail = (value, index) => {
      const emails = [...otherEmails];
      emails[index] = value;
      setOtherEmails(emails);
      setValue("other_emails", emails);
    };

    const handleRemoveOtherEmails = (index) => {
      const emails = [...otherEmails];
      emails.splice(index, 1);
      setOtherEmails(emails);
      setValue("other_emails", emails);
      // Esconde el tooltip de manera manual
      // Quizas se puede optimizar
      ReactTooltip.hide();
    };

    useImperativeHandle(
      ref,
      () => ({
        submit: () => _onSubmit(),
      }),
      [],
    );

    useEffect(() => {
      register("logo");
      register("sector");
      register("subsector");
      register("other_interesting_areas");
      register("economic_income");
      register("profile_visibility");
      register("economic_resources");
      register("economic_spending");
      register("churn_rate");
      register("gmv");
      register("nps");
      register("captable");
      register("competitors");
      register("referrals");
      register("business_model");
      register("priority");
      register("next_step");
      register("project_stage");
      register("investment_stage");
      register("country_incorporation");
      register("city_incorporation");
      register("investment_desired_unit");
      register("compromised_amount_unit");
      register("minimum_ticket_unit");
      register("valuation_unit");
      register("other_emails");
    }, [register]);

    useEffect(() => {
      getFieldData().catch(() => {
        addToast(t("error_occurred"), { appearance: "error", autoDismiss: true });
      });
    }, []);

    useEffect(() => {
      if (haveStartup()) {
        setIsUpdate(true);
        hydrateForm();
      } else {
        setIsUpdate(false);
        reset();
      }
    }, [info]);

    useEffect(() => {
      if (user.lp && !checkIfLPhasAccessToSection("dealflow")) {
        navigate(RoutesLinks.DASHBOARD_LINK);
        addToast(t("forbidden_access"), { appearance: "error", autoDismiss: true });
      }
    }, []);

    return (
      <>
        <SectionHeader
          section={section}
          setSection={(s) => setSection(s)}
          sectionKeys={SECTIONS}
          sectionTitles={sectionKeys.map((key) => t(key))}
        />
        <div className="my-6">
          <div className={section !== "general_info" ? "hidden" : ""}>
            <div className="flex gap-8 border-b border-gray-lines mb-6">
              <div className="w-1/">
                {watch().logo ? (
                  <>
                    <div className="text-xs font-medium text-black">{t("logo")}</div>
                    <div className="mt-2 flex flex-col justify-center">
                      <Avatar
                        src={watch().logo}
                        size="80"
                        round={true}
                        color="#e0e6f2"
                        fgColor="#4d70b3"
                        className="mx-auto my-0 avatar-startup"
                      />
                      <ButtonDanger
                        marginTop={2}
                        type="button"
                        iconComponent={<FaTrash className="inline-block mr-2" />}
                        text={t("remove")}
                        onClick={() => onSelectImage(null)}
                      />
                    </div>
                  </>
                ) : (
                  <div className="my-3">
                    <InputFile
                      label={t("logo")}
                      placeholder={t("select_logo")}
                      error={errors.logo}
                      setSelectedFile={(file) => onSelectImage(file)}
                      accept="image/*"
                      padding="18px"
                    />
                  </div>
                )}
              </div>
              <div className="flex-1">
                <div className="my-3">
                  <Input
                    reference={register}
                    id="name"
                    name="name"
                    placeholder={t("introduce_project_name")}
                    label={t("project_name")}
                    required={true}
                    error={errors.name}
                  />
                </div>
                <div className="my-3">
                  <TextArea
                    reference={register}
                    name="brand_statement"
                    label={t("brand_statement")}
                    placeholder={t("brand_statement_help")}
                    error={errors.brand_statement}
                    row={6}
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <Autocomplete
                name="sector"
                placeholder={t("choose_or_type_sector")}
                label={t("sector")}
                required={true}
                options={sectors}
                initialValues={getSelectableInitialValues(info?.sector?._id, sectors, true)}
                error={errors.sector}
                onSelect={(sector) => onSelectSector(sector)}
              />

              <Autocomplete
                name="subsector"
                placeholder={t("choose_or_type_subsector")}
                label={t("subsector")}
                options={subSectors}
                initialValues={getSelectableInitialValues(info?.subsector?._id, subSectors, true)}
                error={errors.subsector}
                onSelect={(subsector) => onSelectSubSector(subsector)}
              />

              <Select
                disabled={!watch().subsector}
                multiSelect={true}
                name="other_interesting_areas"
                reference={register}
                label={t("other_interesting_areas")}
                placeholder={t("choose_multiple_options")}
                initialValues={getSelectableInitialValues(
                  watch("other_interesting_areas"),
                  otherInterestingAreas,
                )}
                items={otherInterestingAreas}
                onSelect={(selected) => {
                  _setValue(
                    "other_interesting_areas",
                    selected.map((o) => o.id),
                  );
                }}
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <Select
                name="project_stage"
                label={t("project_stage")}
                reference={register}
                required={false}
                placeholder={t("search_project_stage")}
                error={errors.project_stage}
                initialValues={getSelectableInitialValues(watch("project_stage"), PROJECT_STAGES)}
                items={PROJECT_STAGES}
                onSelect={(selected) => {
                  _setValue("project_stage", selected.length ? selected[0].id : null);
                }}
              />

              <Autocomplete
                name="country_incorporation"
                placeholder={t("choose_or_type_country_incorporation")}
                options={COUNTRIES}
                label={t("country_incorporation")}
                required={false}
                initialValues={
                  watch("country_incorporation")
                    ? COUNTRIES.find((item) => item.id === watch("country_incorporation"))
                    : null
                }
                error={errors.country_incorporation}
                onSelect={(country) => {
                  _setValue("country_incorporation", country ? country.id : null);
                }}
              />

              <Input
                reference={register}
                name="city_incorporation"
                placeholder={t("introduce_city_incorporation")}
                label={t("city_incorporation")}
                error={errors.city_incorporation}
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <Input
                reference={register}
                name="creation_date"
                placeholder={t("startup_creation_date")}
                label={t("startup_creation_date")}
                type="date"
                error={errors.creation_date}
              />
              <Input
                name="web"
                reference={register}
                placeholder={t("introduce_project_web")}
                label={t("project_web")}
                error={errors.web}
              />
              <Input
                name="presentation_video"
                reference={register}
                placeholder={t("introduce_presentation_video_url")}
                label={t("introduce_presentation_video")}
                error={errors.presentation_video}
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <Select
                name="priority"
                reference={register}
                label={t("priority")}
                placeholder={t("priority")}
                initialValues={getSelectableInitialValues(watch("priority"), Priorities)}
                items={Priorities}
                error={errors.priority}
                onSelect={(selected) => {
                  _setValue("priority", selected.length ? selected[0].id : null);
                }}
              />
              <Select
                name="next_step"
                reference={register}
                label={t("next_step")}
                placeholder={t("next_step")}
                initialValues={getSelectableInitialValues(watch("next_step"), NextSteps)}
                items={NextSteps}
                error={errors.next_step}
                onSelect={(selected) => {
                  _setValue("next_step", selected.length ? selected[0].id : null);
                }}
              />
              <div>
                <Input
                  reference={register}
                  id="cif"
                  name="cif"
                  placeholder={t("introduce_cif")}
                  label={t("cif")}
                  required={true}
                  error={errors.cif}
                />
              </div>
            </div>
          </div>
          <div className={section !== "contact_info" ? "hidden" : ""}>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <Input
                reference={register}
                name="main_contact_person"
                placeholder={t("main_contact_person")}
                label={t("main_contact_person")}
                error={errors.main_contact_person}
              />
              <Input
                name="main_contact_email"
                reference={register}
                placeholder={t("main_contact_email")}
                label={t("main_contact_email")}
                error={errors.main_contact_email}
              />
              <Input
                reference={register}
                name="main_contact_phone"
                placeholder={t("main_contact_phone")}
                label={t("main_contact_phone")}
                error={errors.main_contact_phone}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 pt-4">
              <Input
                reference={register}
                name="main_contact_linkedin"
                placeholder={t("main_contact_linkedin")}
                label={t("main_contact_linkedin")}
                error={errors.main_contact_linkedin}
              />
              <Input
                reference={register}
                name="contact_persons"
                placeholder={t("other_contact_persons")}
                label={t("other_contact_persons")}
                error={errors.contact_persons}
              />
              <Input
                name="contact"
                reference={register}
                placeholder={t("other_contact_ways")}
                label={t("other_contact_ways")}
                error={errors.contact}
              />
            </div>
            <div className=" border-b border-main flex justify-between items-center">
              <h3 className={"text-2xl text-main my-3"}>{t("others_email")}</h3>
              <div className="flex justify-end">
                <div className="mr-2" data-tip="Añadir Emails">
                  <ButtonCardSecondary
                    onClick={addOtherEmail}
                    iconComponent={<FaPlus className="inline-block w-4 h-4" />}
                  />
                </div>
              </div>
            </div>
            <div>
              <span className="text-xs mx-1 cursor-pointer flex text-gray py-2">
                {t("others_emails_validation")}
              </span>
            </div>
            <div className="grid md:grid-cols-3 mt-4 gap-4">
              {otherEmails.map((email, index) => (
                  <>
                    <div className="flex items-center" key={`email-${index}`}>
                      <Input
                        name={`email-${email[index + 1]}`}
                        placeholder={`Email: ${index + 1}`}
                        value={email}
                        label={`Email: ${index + 1}`}
                        onChange={(e) => updateOtherEmail(e.target.value, index)}
                      />
                      <div className="mx-2" data-tip={t("remove_email")}>
                        <ButtonDanger
                          type="button"
                          iconComponent={<FaTrash className="inline-block" />}
                          onClick={() => {
                            handleRemoveOtherEmails(index);
                          }}
                        />
                      </div>
                    </div>
                  </>
              ))}
            </div>
          </div>
          <div className={section !== "socials" ? "hidden" : ""}>
            {["facebook", "twitter", "instagram", "linkedin", "youtube", "tiktok"].map(
              (network) => (
                <div key={network} className="my-3">
                  <Input
                    name={network}
                    reference={register}
                    placeholder={t(`${network}_startup_profile_placeholder`)}
                    label={t(network)}
                    error={errors[network]}
                  />
                </div>
              ),
            )}

            <div className="my-3">
              <Input
                name="crunchbase_url"
                reference={register}
                placeholder={t("crunchbase_startup_profile_placeholder")}
                label={t("crunchbase")}
                error={errors.crunchbase_url}
              />
            </div>
          </div>

          <div className={section !== "market" ? "hidden" : ""}>
            <Select
              name="business_model"
              reference={register}
              label={t("business_model")}
              placeholder={t("search_business_model")}
              initialValues={getSelectableInitialValues(watch("business_model"), BUSINESS_MODELS)}
              items={BUSINESS_MODELS}
              error={errors.business_model}
              onSelect={(selected) => {
                _setValue("business_model", selected.length ? selected[0].id : null);
              }}
            />

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              {["problem", "solution", "market", "business_description"].map((key) => (
                <TextArea
                  key={key}
                  reference={register}
                  name={key}
                  label={t(key)}
                  placeholder={t(key + "_help")}
                  error={errors[key]}
                  row={6}
                />
              ))}
            </div>
          </div>

          <div className={section !== "competitors" ? "hidden" : ""}>
            <StartupEditCompetitors
              competitors={watch().competitors}
              onChange={() => onChangeCompetitors && onChangeCompetitors()}
              isDealflow={isDealflow}
              startup={info}
              loading={loading}
              setLoading={setLoading}
            />
          </div>

          <div className={section !== "referrals" ? "hidden" : ""}>
            <StartupEditReferrals
              referrals={watch().referrals}
              onChange={() => onChangeReferrals && onChangeReferrals()}
              isDealflow={isDealflow}
              startup={info}
              loading={loading}
              setLoading={setLoading}
            />
          </div>

          <div className={section !== "kpis" ? "hidden" : ""}>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-1">
              <Input
                reference={register}
                name="aov"
                placeholder={t("aov")}
                label={t("aov")}
                type="number"
                error={errors.aov}
              />

              <Input
                reference={register}
                name="ltv"
                placeholder={t("ltv_long")}
                label={t("ltv_long")}
                type="number"
                error={errors.ltv}
              />

              <Input
                reference={register}
                name="cac"
                placeholder={t("cac_long")}
                label={t("cac_long")}
                type="number"
                error={errors.cac}
              />
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
              <StartupEditYearMonthsInput
                title={t("economic_income")}
                description={t("economic_income_help")}
                yearsData={watch().economic_income || {}}
                onChange={(yearData) => _setValue("economic_income", yearData)}
              />
              <StartupEditYearMonthsInput
                title={t("economic_spending")}
                description={t("economic_spending_help")}
                yearsData={watch().economic_spending || {}}
                onChange={(yearData) => _setValue("economic_spending", yearData)}
              />
              <StartupEditYearMonthsInput
                title={t("economic_resources")}
                description={t("economic_resources_help")}
                yearsData={watch().economic_resources || {}}
                onChange={(yearData) => _setValue("economic_resources", yearData)}
              />
              <StartupEditYearMonthsInput
                title={t("churn_rate")}
                description={t("churn_rate_help")}
                yearsData={watch().churn_rate || {}}
                symbol="%"
                maxValue="100"
                onChange={(yearData) => _setValue("churn_rate", yearData)}
              />
              <StartupEditYearMonthsInput
                title={t("gmv")}
                description={t("gmv_help")}
                yearsData={watch().gmv || {}}
                onChange={(yearData) => _setValue("gmv", yearData)}
              />
              <StartupEditYearMonthsInput
                title={t("nps_short")}
                description={t("nps_help")}
                yearsData={watch().nps || {}}
                symbol={<FaStar />}
                maxValue="10"
                onChange={(yearData) => _setValue("nps", yearData)}
              />
            </div>
          </div>

          <div className={section !== "financing" ? "hidden" : ""}>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <Autocomplete
                name="investment_stage"
                placeholder={t("introduce_investment_stage")}
                label={t("investment_stage")}
                options={INVESTMENT_STAGES}
                error={errors.investment_stage}
                initialValues={
                  watch("investment_stage")
                    ? INVESTMENT_STAGES.find((item) => item.id === watch("investment_stage"))
                    : null
                }
                onSelect={(stage) => _setValue("investment_stage", stage ? stage.id : null)}
              />

              <CurrencyInput
                nameInput={"investment_desired"}
                nameSelect={"investment_desired_unit"}
                reference={register}
                placeholder={t("investment_desired")}
                label={t("investment_desired")}
                error={errors.investment_desired}
                watch={watch}
                setValue={_setValue}
              />

              <CurrencyInput
                nameInput={"compromised_amount"}
                nameSelect={"compromised_amount_unit"}
                reference={register}
                placeholder={t("compromised_amount")}
                label={t("compromised_amount")}
                error={errors.compromised_amount}
                watch={watch}
                setValue={_setValue}
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <CurrencyInput
                nameInput={"minimum_ticket"}
                nameSelect={"minimum_ticket_unit"}
                reference={register}
                placeholder={t("minimum_ticket")}
                label={t("minimum_ticket")}
                error={errors.minimum_ticket}
                watch={watch}
                setValue={_setValue}
              />

              <CurrencyInput
                nameInput={"valuation"}
                nameSelect={"valuation_unit"}
                reference={register}
                placeholder={t("valuation")}
                label={t("valuation")}
                error={errors.valuation}
                watch={watch}
                setValue={_setValue}
              />
            </div>
          </div>

          <div className={section !== "investments" ? "hidden" : ""}>
            <StartupEditInvestments
              investments={investments}
              captable={watch().captable}
              onChange={() => onChangeInvestments && onChangeInvestments()}
              isDealflow={isDealflow}
              startup={info}
              loading={loading}
              setLoading={setLoading}
            />
          </div>

          <div className={section !== "captable" ? "hidden" : ""}>
            <StartupEditCaptable
              captable={watch().captable}
              onChange={() => onChangeCaptableShareholders && onChangeCaptableShareholders()}
              isDealflow={isDealflow}
              startup={info}
              loading={loading}
              setLoading={setLoading}
            />
          </div>

          <div className={section !== "custom_fields" ? "hidden" : ""}>
            <StartupEditCustomFields
              dealflowCustomFields={dealflowCustomFields}
              customFields={customFields}
              customFieldsRequired={customFieldsRequired}
              onChange={onChangeCustomFields}
              isDealflow={isDealflow}
              startup={info}
              loading={loading}
              setLoading={setLoading}
            />
          </div>

          <div className={section !== "team_dealflow" ? "hidden" : ""}>
            <DealEditTeamMembers
              startup={info}
              onChange={() => onChangeStartupMembers && onChangeStartupMembers()}
              loading={loading}
              setLoading={setLoading}
            />
          </div>

          {showSubmitButton &&
            !["team_dealflow", "captable", "competitors", "referrals", "investments"].includes(
              section,
            ) && (
              <div className={"flex justify-end my-6"}>
                <ButtonMain
                  width={"w-full md:w-1/2"}
                  text={isUpdate ? t("update") : t("save")}
                  onClick={() => _onSubmit()}
                />
              </div>
          )}
        </div>

        {loading && <Loader />}
      </>
    );
  },
);

export default ProjectCreateOrUpdate;
