import { useTranslation } from "react-i18next";
import { Input } from "../../../ui/atoms/Input";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Loader } from "../../../ui/molecules/Loader";
import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import { useLoginEntries } from "../../../../hooks/useLoginEntries";
import { FaCircle } from "react-icons/fa";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import AuthService from "services/auth-service";

const AccountSecurity = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const { loginEntries } = useLoginEntries();

  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
    criteriaMode: "all",
    reValidateMode: "all",
    resolver: yupResolver(
      Yup.object().shape({
        password: Yup.string().required(t("required_field")).min(8, t("password_length")),
        new_password: Yup.string()
          .required(t("required_field"))
          .min(8, t("password_length"))
          .matches(/^.*(?=.*?[A-Z]).*$/, t("password_req_uppercase"))
          .matches(/^.*(?=.*?[a-z]).*$/, t("password_req_lowercase"))
          .matches(/^.*(?=.*?[0-9]).*$/, t("password_req_number"))
          .matches(
            /^.*(?=.*?[\^ $ * . [ \] { } ( ) ? " ! @ # % & , > < ' : ; _ ~ ` \\ / |]).*$/,
            `${t("password_req_special_char")}: ^ $ * . [ ] { } ( ) ? " ! @ # % & , > < ' : ; _ ~  \` \\ |`,
          ),
        password_confirmation: Yup.string()
          .oneOf([Yup.ref("new_password"), null], t("passwords_do_not_match"))
          .required(t("required_field")),
      }),
    ),
  });

  const updatePassword = (values) => {
    setLoading(true);
    AuthService.updatePassword({
      current_password: values.password,
      new_password: values.new_password,
    })
      .then(() => {
        addToast(t("password_updated_successfully"), {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        addToast(
          error.response && error.response.data.msg_key
            ? t(error.response.data.msg_key)
            : t("error_occurred_updating_password"),
          {
            appearance: "error",
            autoDismiss: true,
          },
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="mb-6">
        <h3 className={"border-b font-medium text-main font-bold mb-4 border-main"}>
          {t("update_password")}
        </h3>
        <form className="w-full" onSubmit={handleSubmit(updatePassword)}>
          <Input
            reference={register}
            type="password"
            name="password"
            required={true}
            label={t("password")}
            placeholder={t("password_req_min_char")}
            error={errors.password}
          />
          <Input
            reference={register}
            type="password"
            name="new_password"
            label={t("new_password")}
            required={true}
            placeholder={t("password_req_min_char")}
            error={errors.new_password}
          />
          <Input
            reference={register}
            type="password"
            name="password_confirmation"
            label={t("confirm_password")}
            required={true}
            placeholder={t("password_req_min_char")}
            error={errors.password_confirmation}
          />
          <div className="flex justify-end mt-4">
            <ButtonMain width={"full lg:w-1/2"} text={t("update_password")} type={"submit"} />
          </div>
        </form>
      </div>

      <div>
        <h3 className={"border-b font-medium text-main font-bold mb-4 border-main"}>
          {t("login_entries")}
        </h3>
        <div className="divide-y divide-gray-lines">
          {loginEntries.map((entry, index) => (
            <div key={index} className="flex py-2">
              <FaCircle className={"text-main mr-2"} />

              <div className="flex items-center text-xs">
                <div>
                  {entry.os} · {entry.browser} ·{" "}
                  {moment(entry.createdAt).format("DD/MM/YYYY HH:mm")}
                </div>
                {entry.city && <div>{entry.city}</div>}
              </div>
            </div>
          ))}
        </div>
      </div>

      {loading && <Loader />}
    </>
  );
};

export default AccountSecurity;
