import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useToasts } from "react-toast-notifications";
import { SidePanel } from "../../ui/objects/SidePanel";
import { Input } from "../../ui/atoms/Input";
import Select from "../../ui/atoms/Select";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import Utils from "utils/utils";

export const FormQuestionsFilters = ({ onFilter, onClose, filters = {} }) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const originTypes = [
    { id: "mine", value: t("questions_mine") },
    { id: "kiota", value: t("questions_kiota") },
    // { id: 'other_investors', value: t('questions_other_investors') }
  ];

  const importanceOptions = [
    { id: "key_questions", value: t("show_key_questions") },
    { id: "all", value: t("show_all_questions") },
    // { id: 'other_investors', value: t('questions_other_investors') }
  ];

  const questionTypes = [
    {
      id: "short_answer",
      value: t("short_answer"),
    },
    {
      id: "long_answer",
      value: t("long_answer"),
    },
    {
      id: "boolean",
      value: t("boolean"),
    },
    {
      id: "select",
      value: t("select"),
    },
    {
      id: "number",
      value: t("number"),
    },
    {
      id: "multiselect",
      value: t("multiselect"),
    },
    {
      id: "separator",
      value: t("separator"),
    },
    {
      id: "questions_group",
      value: t("questions_group"),
    },
    {
      id: "date",
      value: t("date"),
    },
    {
      id: "url",
      value: t("url"),
    },
    {
      id: "phone",
      value: t("phone"),
    },
    {
      id: "likert_scale",
      value: t("likert_scale"),
    },
    {
      id: "email",
      value: t("email"),
    },
    {
      id: "file",
      value: t("file"),
    },
    {
      id: "image_selector",
      value: t("image_selector"),
    },
    {
      id: "autocomplete",
      value: t("autocomplete"),
    },
    {
      id: "financial",
      value: t("financial_question"),
    },
  ];

  const { register, handleSubmit, trigger, errors, setValue, reset, watch } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        title: Yup.string(),
        origin: Yup.array().nullable(),
        type: Yup.array().nullable(),
        importance: Yup.string().nullable(),
      }),
      { abortEarly: false },
    ),
    criteriaMode: "all",
    reValidateMode: "all",
    mode: "onChange",
  });

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, config);
    trigger(name);
  };

  const filter = (filters) => {
    if (filters.origin && filters.origin.length) {
      filters.origin = filters.origin.map((item) => item.id);
    }

    if (filters.type && filters.type.length) {
      filters.type = filters.type.map((item) => item.id);
    }

    onFilter && onFilter(filters);
    onClose && onClose();
  };

  const resetFilters = () => {
    reset();
    filter({});
  };

  const onInvalid = () => {
    addToast(t("form_field_error"), { appearance: "error", autoDismiss: true });
  };

  useEffect(() => {
    register("origin");
    register("type");
    register("importance");
  }, [register]);

  useEffect(() => {
    if (filters) {
      Object.entries(filters).forEach(([key, value]) => {
        if (key === "origin") {
          const _value = (value || []).map((item) =>
            originTypes.find((origin) => origin.id === item),
          );
          _setValue(key, _value, { shouldValidate: true });
        } else if (key === "type") {
          const _value = (value || []).map((item) =>
            questionTypes.find((type) => type.id === item),
          );
          _setValue(key, _value, { shouldValidate: true });
        } else if (key === "importance") {
          _setValue(key, value, { shouldValidate: true });
        } else {
          _setValue(key, value, { shouldValidate: true });
        }
      });
    }
  }, [filters, register]);

  return (
    <SidePanel title={t("filters")} onClose={() => onClose && onClose()}>
      <form className="flex flex-col w-72" onSubmit={handleSubmit(filter, onInvalid)}>
        <div>
          <Select
            name="importance"
            label={t("importance")}
            isClearable={true}
            placeholder={t("select_option")}
            onSelect={(selection) =>
              _setValue("importance", selection?.length ? selection[0].id : null)
            }
            initialValues={
              watch("importance")
                ? importanceOptions.filter((opt) => opt.id === watch("importance"))
                : []
            }
            multiSelect={false}
            items={importanceOptions}
          />
          <Select
            name="origin"
            label={t("origin")}
            isClearable={true}
            placeholder={t("choose_multiple_options")}
            onSelect={(selection) => _setValue("origin", selection || null)}
            initialValues={watch("origin") ? watch("origin") : []}
            multiSelect={true}
            items={originTypes}
          />

          <Select
            name="type"
            label={t("type")}
            isClearable={true}
            placeholder={t("choose_multiple_options")}
            onSelect={(selection) => _setValue("type", selection || null)}
            initialValues={watch("type") ? watch("type") : []}
            multiSelect={true}
            items={Utils.sortByKey(questionTypes, "value")}
          />
        </div>

        <div className={"flex justify-end mt-2 pt-2 border-t border-separator"}>
          <ButtonSecondary
            text={t("clear_filters")}
            onClick={() => resetFilters()}
            type="button"
            marginRight={2}
          />
          <ButtonMain text={t("filter")} type="submit" />
        </div>
      </form>
    </SidePanel>
  );
};
