import { useTranslation } from "react-i18next";
import { SidePanel } from "components/ui/objects/SidePanel";
import { useState } from "react";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { Autocomplete } from "../atoms/Autocomplete";

/**
 * @param onClose
 * @returns {JSX.Element}
 * @constructor
 */
const MigrationSection = ({ onClose, onSubmit, migrationType, dealflow }) => {
  const { t } = useTranslation();

  const [error, setError] = useState(null);

  const [selectedDealflow, setSelectedDealflow] = useState(null);

  const onSelect = (value, setter) => {
    setter(value);
    setError(!value ? { message: t("required_field") } : null);
  };

  const submit = () => {
    if (selectedDealflow) {
      onSubmit(selectedDealflow);
    } else {
      setError({ message: t("required_field") });
    }
  };

  return (
    <>
      <SidePanel
        width={"1/4"}
        onClose={onClose}
        title={t(migrationType === "notes" ? "migrate_note" : "migrate_document")}
      >
        <p className={"font-medium text-sm text-main"}>{t("migration_explanation")}</p>
        <div className="my-3">
          <Autocomplete
            labelColor={selectedDealflow ? "text-black" : "text-red"}
            name="startup"
            placeholder={t("search_startup")}
            isClearable={true}
            required={true}
            label={t("startup")}
            options={dealflow}
            onSelect={(value) => onSelect(value?._id || null, setSelectedDealflow)}
            error={error}
          />
        </div>
        <div className={"flex justify-end mt-4 border-t pt-2 border-separator"}>
          <ButtonMain type="submit" onClick={() => submit()} text={t("migrate")} />
        </div>
      </SidePanel>
    </>
  );
};

export default MigrationSection;
