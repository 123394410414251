import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useToasts } from "react-toast-notifications";
import { SidePanel } from "components/ui/objects/SidePanel";
import Select from "components/ui/atoms/Select";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import Config from "config";

const ResourcesFilters = ({ onFilter, onClose, filters = {} }) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const topicOptions = [
    { id: "general", value: t("resource_topic_general") },
    { id: "gathering", value: t("resource_topic_gathering") },
    { id: "management", value: t("resource_topic_management") },
    { id: "analysis", value: t("resource_topic_analysis") },
    { id: "evaluation", value: t("resource_topic_evaluation") },
    { id: "report", value: t("resource_topic_report") },
  ];

  const typeOptions = [
    { id: "pdf", value: t("resource_type_pdf") },
    { id: "video", value: t("resource_type_video") },
  ];

  const { register, handleSubmit, trigger, errors, setValue, reset, watch } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        topic: Yup.string().nullable(),
        type: Yup.string().nullable(),
      }),
      { abortEarly: false },
    ),
    criteriaMode: "all",
    reValidateMode: "all",
    mode: "onChange",
  });

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, config);
    trigger(name);
  };

  const filter = (filters) => {
    onFilter && onFilter(filters);
    onClose && onClose();
  };

  const resetFilters = () => {
    reset();
    filter({});
  };

  const onInvalid = (error) => {
    console.error(error);
    addToast(t("form_field_error"), { appearance: "error", autoDismiss: true });
  };

  useEffect(() => {
    register("topic");
    register("type");
  }, [register]);

  useEffect(() => {
    if (filters) {
      if (Object.keys(filters).length) {
        Object.entries(filters).forEach(([key, value]) => {
          if (key === "topic") {
            _setValue(key, value, { shouldValidate: true });
          } else if (key === "type") {
            _setValue(key, value, { shouldValidate: true });
          }
        });
      }
    }
  }, [filters, register]);

  return (
    <SidePanel title={t("filter_resources")} onClose={() => onClose && onClose()} width={"1/3"}>
      <form className="flex flex-col w-full" onSubmit={handleSubmit(filter, onInvalid)}>
        <div>
          <Select
            label={t("resources_topic_label")}
            name={"topic"}
            items={topicOptions}
            multiSelect={false}
            isClearable={true}
            initialValues={
              watch("topic")
                ? [topicOptions.find((topicOption) => topicOption.id === watch("topic"))]
                : []
            }
            onSelect={(selection) => _setValue("topic", selection.length ? selection[0].id : null)}
          />
        </div>
        <div>
          <Select
            label={t("resources_type_label")}
            name={"type"}
            items={typeOptions}
            multiSelect={false}
            isClearable={true}
            initialValues={
              watch("type")
                ? [typeOptions.find((typeOption) => typeOption.id === watch("type"))]
                : []
            }
            onSelect={(selection) => _setValue("type", selection.length ? selection[0].id : null)}
          />
        </div>

        <div className={"flex justify-end mt-2 pt-2 border-t border-separator"}>
          <ButtonSecondary
            text={t("clear_filters")}
            onClick={() => resetFilters()}
            type="button"
            marginRight={2}
          />
          <ButtonMain text={t("filter")} type="submit" />
        </div>
      </form>
    </SidePanel>
  );
};

export default ResourcesFilters;
