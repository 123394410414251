import { useTranslation } from "react-i18next";
import { SidePanel } from "components/ui/objects/SidePanel";
import { useEffect } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Config from "config";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import Select from "components/ui/atoms/Select";
import RejectionReasons from "assets/json/rejection-reasons.json";
import { TextArea } from "components/ui/atoms/TextArea";
import { CurrencyInput } from "components/ui/atoms/CurrencyInput";
import { Input } from "components/ui/atoms/Input";
import FormUtils from "utils/form-utils";
import moment from "moment";
/**
 * @param onClose
 * @returns {JSX.Element}
 * @constructor
 */
const RegisterExitSection = ({ onClose, registerInvestment }) => {
  const { t } = useTranslation();

  const { register, handleSubmit, errors, setValue, trigger, watch } = useForm({
    mode: "onChange",
    criteriaMode: "all",
    reValidateMode: "all",
    resolver: yupResolver(
      Yup.object().shape({
        amount: Yup.number().required(t("required_field")).min(1, t("amount_min_required")),
        unit: Yup.string().required(t("required_field")),
        date: Yup.string()
          .required(t("required_field"))
          .transform((value) => FormUtils.parseDateFromString(value, "YYYY-MM-DD HH:mm")),
        comments: Yup.string().nullable(),
      }),
      { abortEarly: false },
    ),
  });

  useEffect(() => {
    register("unit");
  }, [register]);

  useEffect(() => {
    setValue("unit", "k");
    setValue("amount", 0);
    setValue("date", moment().format("YYYY-MM-DD"));
    trigger();
  }, []);

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, { shouldValidate: true, shouldTouch: true, ...config });
    trigger(name);
  };

  return (
    <>
      <SidePanel width={"1/4"} onClose={onClose} title={t("register_exit")}>
        <div className="mt-4 flex flex-col w-full">
          <form className="w-full flex flex-col" onSubmit={handleSubmit(registerInvestment)}>
            <div className="w-full mr-4">
              <CurrencyInput
                nameInput={"amount"}
                nameSelect={"unit"}
                reference={register}
                placeholder={t("amount")}
                label={t("amount")}
                error={errors.amount}
                watch={watch}
                setValue={_setValue}
              />
            </div>
            <div className="mt-2 w-full">
              <Input
                reference={register}
                name="date"
                placeholder={t("exit_announce_date")}
                label={t("exit_announce_date")}
                type="date"
                error={errors.date}
              />
            </div>
            <div className="mt-2 w-full">
              <TextArea
                reference={register}
                name="comments"
                error={errors.comments}
                label={t("rejected_reasons")}
                maxLength={200}
                placeholder={t("comments")}
              />
            </div>
            <div className={`flex justify-end mt-2 pt-2 border-t border-separator`}>
              <ButtonMain type={"submit"} text={t("register_exit")} />
            </div>
          </form>
        </div>
      </SidePanel>
    </>
  );
};

export default RegisterExitSection;
