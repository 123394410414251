import { DocumentList } from "components/documentation/documents/components/DocumentList";
import { useOutletContext } from "react-router-dom";

const DealflowDocumentsView = () => {
  const {
    deal, ref, getDeal, setDocumentToMigrate,
    setShowMigrationSection,
    reloadDocuments,
    setReloadDocuments,
  } = useOutletContext();

  return (
        <DocumentList dealId={deal._id} ref={ref} getDeal={getDeal} setDocumentToMigrate={(document) => {
          setDocumentToMigrate(document);
          setShowMigrationSection(true);
        }}
        reloadDocuments={reloadDocuments}
        setReloadDocuments={setReloadDocuments}
        />
  );
};

export default DealflowDocumentsView;
