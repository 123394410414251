import AnalyticEventsService from "services/analytic-events-service";

const AnalyticEventUtils = {
  async sendEvent(data) {
    try {
      await AnalyticEventsService.sendEvent({
        ...data,
        platform_id: sessionStorage.getItem("platform_id"),
      });
    } catch (e) {

    }
  },
};

export default AnalyticEventUtils;
