import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { RoutesLinks } from "components/routes-links";
import { useToasts } from "react-toast-notifications";
import { Loader } from "components/ui/molecules/Loader";
import { SharedEventFooter } from "components/events/components/event/components/SharedEventFooter";
import { FaArrowLeft, FaExclamationCircle, FaStar } from "react-icons/fa";
import moment from "moment";
import { Card } from "components/ui/atoms/Card";
import Config from "config";
import { useSwal } from "hooks/useSwal";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { StartupEditYearMonthsInput } from "components/edit-startup/components/StartupEditYearMonthsInput";
import { Input } from "components/ui/atoms/Input";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DealflowService } from "services/dealflow-service";

export const RequestedKPIsForm = () => {
  const { t } = useTranslation();

  const validationShape = useMemo(
    () => ({
      economic_income: Yup.object().default({}),
      economic_resources: Yup.object().default({}),
      economic_spending: Yup.object().default({}),
      churn_rate: Yup.object().default({}),
      gmv: Yup.object().default({}),
      nps: Yup.object().default({}),
      aov: Yup.number()
        .typeError(t("incorrect_format"))
        .transform((_, val) => (val === `${Number(val)}` ? Number(val) : null))
        .nullable(),
      cac: Yup.number()
        .typeError(t("incorrect_format"))
        .transform((_, val) => (val === `${Number(val)}` ? Number(val) : null))
        .nullable(),
      ltv: Yup.number()
        .typeError(t("incorrect_format"))
        .transform((_, val) => (val === `${Number(val)}` ? Number(val) : null))
        .max(100, t("incorrect_format"))
        .nullable(),
    }),
    [],
  );

  const { register, handleSubmit, trigger, errors, setValue, watch, reset } = useForm({
    resolver: yupResolver(Yup.object().shape(validationShape), { abortEarly: false }),
    criteriaMode: "all",
    reValidateMode: "all",
    mode: "onChange",
  });

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, config);
    trigger(name);
  };

  useEffect(() => {
    register("economic_income");
    register("economic_resources");
    register("economic_spending");
    register("churn_rate");
    register("gmv");
    register("nps");
  }, [register]);
  const queryParams = new URLSearchParams(location.search);
  const [dealId, setDealId] = useState(null);

  const wrapperElement = useRef(null);
  const { addToast } = useToasts();
const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { confirm } = useSwal();
  const [authorized, setAuthorized] = useState(false);
  const [error, setError] = useState(false);

  const checkAccess = async () => {
    try {
      setLoading(true);
      const result = await DealflowService.checkAccessToRequestedKPIs(dealId);
      setAuthorized(true);
      setValue(
        "economic_resources",
        result.data.data.economic_resources ? result.data.data.economic_resources : {},
      );
      setValue(
        "economic_income",
        result.data.data.economic_income ? result.data.data.economic_income : {},
      );
      setValue(
        "economic_spending",
        result.data.data.economic_spending ? result.data.data.economic_spending : {},
      );
      setValue("churn_rate", result.data.data.churn_rate ? result.data.data.churn_rate : {});
      setValue("gmv", result.data.data.gmv ? result.data.data.gmv : {});
      setValue("nps", result.data.data.nps ? result.data.data.nps : {});
      setValue("ltc", result.data.data.ltv);
      setValue("cac", result.data.data.cac);
      setValue("aov", result.data.data.aov);
      trigger();
    } catch (error) {
      addToast(
        t(
          error?.response?.status === 403
            ? "forbidden_access_to_form"
            : "error_occurred_checking_access",
        ),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
      setError(true);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!queryParams.get("deal")) {
      addToast(t("forbidden_access_to_form"), {
        appearance: "error",
        autoDismiss: true,
      });
      navigate(RoutesLinks.LOGIN_LINK);
    } else {
      setDealId(queryParams.get("deal"));
    }
  }, [queryParams]);

  useEffect(() => {
    if (dealId) {
      checkAccess();
    }
  }, [dealId]);

  const _onSubmit = () => {
    handleSubmit(
      (values) => {
        confirm().then(async (isConfirmed) => {
          if (isConfirmed) {
            try {
              await DealflowService.saveRequestedKPIs({
                deal: dealId,
                values,
              });
              addToast(t("information_submitted_successfully"), {
                appearance: "success",
                autoDismiss: true,
              });
            } catch (error) {
              addToast(t("error_occurred_submitting_information"), {
                appearance: "error",
                autoDismiss: true,
              });
            }
          }
        });
      },
      (error) => {
        console.error(error);
        addToast(t("form_field_error"), { appearance: "error", autoDismiss: true });
      },
    )();
  };

  return (
    <div className="flex flex-col min-h-screen" ref={wrapperElement}>
      {loading && <Loader />}
      <section className="px-8 my-8 flex-1">
        {authorized && (
          <>
            <h1 className={`text-main text-4xl border-b border-gray-lines mb-6`}>
              <FaArrowLeft
                className="inline w-6 cursor-pointer"
                onClick={() =>
                  navigate(`${RoutesLinks.REQUESTED_INFORMATION_LINK}?deal=${dealId}`)
                }
              />{" "}
              {t("kpis")}
            </h1>
            <div className="mx-4">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-1">
                <Input
                  reference={register}
                  name="aov"
                  placeholder={t("aov")}
                  label={t("aov")}
                  type="number"
                  error={errors.aov}
                />

                <Input
                  reference={register}
                  name="ltv"
                  placeholder={t("ltv_long")}
                  label={t("ltv_long")}
                  type="number"
                  error={errors.ltv}
                />

                <Input
                  reference={register}
                  name="cac"
                  placeholder={t("cac_long")}
                  label={t("cac_long")}
                  type="number"
                  error={errors.cac}
                />
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
                <StartupEditYearMonthsInput
                  title={t("economic_income")}
                  description={t("economic_income_help")}
                  yearsData={watch().economic_income || {}}
                  onChange={(yearData) => _setValue("economic_income", yearData)}
                />
                <StartupEditYearMonthsInput
                  title={t("economic_spending")}
                  description={t("economic_spending_help")}
                  yearsData={watch().economic_spending || {}}
                  onChange={(yearData) => _setValue("economic_spending", yearData)}
                />
                <StartupEditYearMonthsInput
                  title={t("economic_resources")}
                  description={t("economic_resources_help")}
                  yearsData={watch().economic_resources || {}}
                  onChange={(yearData) => _setValue("economic_resources", yearData)}
                />
                <StartupEditYearMonthsInput
                  title={t("churn_rate")}
                  description={t("churn_rate_help")}
                  yearsData={watch().churn_rate || {}}
                  symbol="%"
                  maxValue="100"
                  onChange={(yearData) => _setValue("churn_rate", yearData)}
                />
                <StartupEditYearMonthsInput
                  title={t("gmv")}
                  description={t("gmv_help")}
                  yearsData={watch().gmv || {}}
                  onChange={(yearData) => _setValue("gmv", yearData)}
                />
                <StartupEditYearMonthsInput
                  title={t("nps_short")}
                  description={t("nps_help")}
                  yearsData={watch().nps || {}}
                  symbol={<FaStar />}
                  maxValue="10"
                  onChange={(yearData) => _setValue("nps", yearData)}
                />
              </div>
              <div className={"flex justify-end my-6"}>
                <ButtonMain
                  width={"w-full md:w-1/2"}
                  text={t("save")}
                  onClick={() => _onSubmit()}
                />
              </div>
            </div>
          </>
        )}
        {(!authorized || error) && (
          <Card
            wrapperClassName={`bg-main mt-24 max-w-screen-sm mx-auto`}
            containerClassName={`text-center text-lg my-6 text-main`}
          >
            <div>
              <FaExclamationCircle className="inline-block mb-6" size={70} />
            </div>
            <p>{t(error ? "error_occurred_retrieving_form" : "forbidden_access_to_form")}</p>
          </Card>
        )}
      </section>
      <SharedEventFooter />
    </div>
  );
};
