import { useTranslation } from "react-i18next";
import { useState } from "react";
import Select from "components/ui/atoms/Select";
import { SidePanel } from "components/ui/objects/SidePanel";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import Config from "config";
import { Alert } from "components/ui/molecules/Alert";
import { Switch } from "components/ui/atoms/Switch";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";

const AssignStartupsSection = ({ onClose, onSubmit, startups, judgeStartups, loadingStartups }) => {
  const { t } = useTranslation();

  const [selectedStartups, setSelectedStartups] = useState(judgeStartups);
  const parsedStartups = startups.map((s) => {
    return { id: s._id, value: s.name };
  });

  return (
    <SidePanel width={"1/4"} onClose={onClose} title={t("assign_startups")}>
      {startups.length === 0 && !loadingStartups && (
        <Alert text={t("startups_not_found")} bgColor={"bg-white"} />
      )}
      {startups.length === 0 && loadingStartups && (
        <Alert text={t("loading_startups")} bgColor={"bg-white"} />
      )}
      {startups.length !== 0 && (
        <>
          <div className="my-3">
            <Select
              labelColor={selectedStartups.length}
              name="startup"
              placeholder={t("choose_multiple_options")}
              label={t("startups")}
              multiSelect={true}
              items={parsedStartups}
              initialValues={selectedStartups.map((s) => parsedStartups.find((ps) => ps.id === s))}
              onSelect={(value) => {
                setSelectedStartups(
                  value.map((s) => parsedStartups.find((ps) => ps.id === s.id).id),
                );
              }}
            />
          </div>
          <p className="font-medium text-sm text-gray-dark mt-2">
            {t("assign_startups_section_description")}
          </p>
          <div className={`flex justify-end mt-2 pt-2 border-t border-separator`}>
            <ButtonSecondary
              marginRight={2}
              type="submit"
              text={t("assign_current_startups")}
              onClick={() =>
                onSubmit(
                  startups.map((s) => s._id),
                  true,
                )
              }
            />
            <ButtonMain
              type="submit"
              text={t("assign_selected_startups")}
              onClick={() => onSubmit(selectedStartups)}
            />
          </div>
        </>
      )}
    </SidePanel>
  );
};

export default AssignStartupsSection;
