import { Trans, useTranslation } from "react-i18next";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import Config from "config";
import FirstItem from "assets/images/onboarding_third_step_first_item.png";
import SecondItem from "assets/images/onboarding_third_step_second_item.png";
import ThirdItem from "assets/images/onboarding_third_step_third_item.png";

import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { GrDocumentText } from "react-icons/gr";
import { useState } from "react";
import { Modal } from "components/ui/molecules/Modal";
import useUser from "hooks/useUser";

const ThirdStep = ({ nextStep, previousStep }) => {
  const { t } = useTranslation();
  const [resourceToShow, setResourceToShow] = useState(null);
  const { userLanguage } = useUser();

  const RESOURCES = {
    about_kiota: {
      es: "https://kiota-academy.s3.eu-west-1.amazonaws.com/Kiota-Your+copilot+for+Startup+investment.mp4",
      en: "https://kiota-academy.s3.eu-west-1.amazonaws.com/Kiota-Your+copilot+for+Startup+investment.mp4",
    },
    configure_pipeline_manager: {
      es: "https://kiota-academy.s3.eu-west-1.amazonaws.com/Pipeline-Manager-ES.mp4",
      en: "https://kiota-academy.s3.eu-west-1.amazonaws.com/Pipeline-Manager-EN.mp4",
    },
    configure_form: {
      es: "https://kiota-academy.s3.eu-west-1.amazonaws.com/Formulario-ES.mp4",
      en: "https://kiota-academy.s3.eu-west-1.amazonaws.com/Form-EN.mp4",
    },
    presentation: {
      es: "https://kiota-academy.s3.eu-west-1.amazonaws.com/ES-Tu+copiloto+para+inversi%C3%B3n+en+startups.pdf",
      en: "https://kiota-academy.s3.eu-west-1.amazonaws.com/EN+-+Your+co-pilot+for+Startup+investment.pdf",
    },
    starter_guide: {
      es: "https://kiota-academy.s3.eu-west-1.amazonaws.com/ES-Guia-inicio-Kiota.pdf",
      en: "https://kiota-academy.s3.eu-west-1.amazonaws.com/EN-Starter-guide-Kiota.pdf",
    },
  };

  return (
    <div className="bg-bgNewOnboardingOne bg-cover bg-no-repeat bg-center min-w-screen min-h-screen relative flex justify-center align-center">
      {resourceToShow && (
        <Modal
          showModal={!!resourceToShow}
          showCloseModal={true}
          onClose={() => setResourceToShow(null)}
          fixedWidth={"w-1/2"}
        >
          <div className="py-10">
            <video width="auto" height="auto" controls autoPlay>
              <source src={RESOURCES[resourceToShow][userLanguage]} type="video/mp4" />
              <source src={RESOURCES[resourceToShow][userLanguage]} type="video/ogg" />
              Your browser does not support the video tag.
            </video>
          </div>
        </Modal>
      )}
      <div className="flex items-center text-center w-1/3 p-8">
        <div className="flex flex-col justify-center items-center bg-white p-6 rounded-lg">
          <h1 className={`text-secondary mb-4`}>{t("onboarding_third_step_title")}</h1>
          <div className={`text-base text-main mb-6 font-semibold border-b border-secondary pb-4`}>
            <Trans i18nKey="onboarding_third_step_subtitle" components={{ bold: <strong /> }} />
          </div>
          <div className="px-10 w-full">
            <div className="flex w-full mb-10">
              <div
                className="flex items-center flex-row cursor-pointer hover:font-bold ml-10"
                onClick={() => window.open(RESOURCES.starter_guide[userLanguage], "_blank")}
              >
                <GrDocumentText className="w-5 h-5" />
                <div className="ml-2 text-xs flex items-center justify-center flex-row text-center">
                  {t("onboarding_thitd_step_second_document")}
                </div>
              </div>
              <div
                className="flex items-center flex-row cursor-pointer hover:font-bold ml-10"
                onClick={() => window.open(RESOURCES.presentation[userLanguage], "_blank")}
              >
                <GrDocumentText className="w-5 h-5" />
                <div className="ml-2 text-xs flex items-center justify-center flex-row text-center">
                  {t("onboarding_third_step_first_document")}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-8 w-full mb-10">
              <div
                className="flex items-center flex-col cursor-pointer hover:font-bold"
                onClick={() => setResourceToShow("about_kiota")}
              >
                <img src={FirstItem} alt="Test" className="w-20 mb-2" />
                <p className="text-xs sm:text-sm">{t("onboarding_third_step_first_item")}</p>
              </div>
              <div
                className="flex items-center flex-col cursor-pointer hover:font-bold"
                onClick={() => setResourceToShow("configure_pipeline_manager")}
              >
                <img src={SecondItem} alt="Test" className="w-20 mb-2" />
                <p className="text-xs sm:text-sm">{t("onboarding_third_step_second_item")}</p>
              </div>
              <div
                className="flex items-center flex-col cursor-pointer hover:font-bold"
                onClick={() => setResourceToShow("configure_form")}
              >
                <img src={ThirdItem} alt="Test" className="w-20 mb-2" />
                <p className="text-xs sm:text-sm">{t("onboarding_third_step_third_item")}</p>
              </div>
            </div>
          </div>
          <div className={"w-full flex justify-between"}>
            <div className="flex items-center justify-center mr-3 font-bold">2/2</div>
            <div className="flex">
              <ButtonSecondary
                onClick={previousStep}
                type="button"
                text={t("go_back")}
                marginRight={2}
              />
              <ButtonMain onClick={nextStep} type="button" text={t("start")} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdStep;
