import { FormResultsSection } from "components/qe-forms/components/FormResultsSection";
import Select from "components/ui/atoms/Select";
import { SidePanel } from "components/ui/objects/SidePanel";
import useUser from "hooks/useUser";
import { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import ReactTooltip from "react-tooltip";
import { CustomFieldsService } from "services/custom-fields-service";
import FormsService from "services/forms-service";
import { FaEdit, FaFileExport, FaInfoCircle, FaPlus } from "react-icons/fa";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { Loader } from "components/ui/molecules/Loader";
import { Card } from "components/ui/atoms/Card";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { useSwal } from "hooks/useSwal";
import { Input } from "components/ui/atoms/Input";
import { TextArea } from "components/ui/atoms/TextArea";
import { TrafficLight } from "components/ui/atoms/TrafficLight";

function DealflowMainForm() {
  const [questionnaire, setQuestionnaire] = useState(null);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const { reloadUserInfo } = useUser();
  const { confirm } = useSwal();

  const [showAddCustomFieldsForm, setShowAddCustomFieldsForm] = useState(false);

  const [loadingCustomFields, setLoadingCustomFields] = useState(false);
  const [rawCustomFields, setRawCustomFields] = useState([]);
  const [customFieldToEdit, setCustomFieldToEdit] = useState(null);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [customFields, setCustomFields] = useState([]);
  const [selectedCustomFields, setSelectedCustomFields] = useState([]);

  const [customFieldValues, setCustomFieldValues] = useState({});

  const onChange = (id, value) => {
    const tempCustomFields = {
      ...customFieldValues,
      [`${id}---${rowToEdit.answers._id}`]: value,
    };

    setCustomFieldValues(tempCustomFields);
  };

  const exportResponses = () => confirm({
    title: t("export_responses"),
    text: t("export_responses_explanation"),
  }).then((isConfirmed) => {
    if (isConfirmed) {
      setLoading(true);
      return FormsService.exportResponses(questionnaire._id)
        .then((result) => {
          if (result.data.data && result.data.data !== "") {
            window.open(result.data.data, "_blank");
          }
        })
        .catch((error) => {
          if (error.response) {
            addToast(t("error_occurred_exporting_responses"), {
              appearance: "error",
              autoDismiss: true,
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  });

  const getCustomFields = () => {
    setLoadingCustomFields(true);
    CustomFieldsService.getCustomFields()
      .then((response) => {
        setRawCustomFields(response.data.data);
        setCustomFields(
          response.data.data.map((customField) => ({
            ...customField,
            id: customField._id,
            value: customField.name,
          })),
        );
        ReactTooltip.rebuild();
      })
      .catch((error) => {
        addToast(
          error.response && error.response.data.msg_key
            ? error.response.data.msg_key
            : t("error_occurred_retrieving_custom_fields"),
          {
            appearance: "error",
            autoDismiss: true,
          },
        );
      })
      .finally(() => {
        setLoadingCustomFields(false);
      });
  };

  const handleAddCustomField = () => {
    setShowAddCustomFieldsForm(true);
  };

  const handleEditCustomField = (rowId) => {
    // Start up id here

    setRowToEdit(rowId);
  };

  useEffect(() => {
    getForm();
    getCustomFields();
  }, []);

  useEffect(() => {
    getForm();
  }, [rawCustomFields]);

  useEffect(() => {
    if (questionnaire) {
      setCustomFieldValues(questionnaire?.custom_fields_values);
    }
  }, [questionnaire]);

  const getForm = async() => {
    setLoading(true);

    FormsService.getQuestionnairesForms({ set_as_main: true })
      .then((response) => {
        const foundMainForms = response.data.data.forms.filter((form) => form.set_as_main);
        if (foundMainForms.length) {
          return FormsService.getQuestionnairesForm(foundMainForms[0]._id)
            .then((response) => {
              setQuestionnaire(response.data.data);
            })
            .catch(() => {
              addToast(t("error_retrieving_form"), {
                appearance: "error",
                autoDismiss: true,
              });
            })
            .finally(() => {
              setLoading(false);
            });
        }
      })
      .catch(() => {
        addToast(t("error_retrieving_form"), {
          appearance: "error",
          autoDismiss: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const parseQuestions = (selectedQuestions) => {
    const parsedQuestions = [];
    selectedQuestions.forEach((q, index) => {
      const parsedQuestion = {};
      parsedQuestion.question_id = q.question_id?._id ?? q._id;
      parsedQuestion.required = false;
      if (!index) {
        parsedQuestion.first_question = true;
      }
      if (selectedQuestions.length > index + 1) {
        parsedQuestion.next_question = {
          question_id:
            selectedQuestions[index + 1]?.question_id?._id ?? selectedQuestions[index + 1]?._id,
        };
      }
      parsedQuestions.push(parsedQuestion);
    });

    return parsedQuestions;
  };
  const deleteCustomFieldQuestion = async(questionId, isCustomField) => {
    console.log("*** questionId", questionId, isCustomField);
    // return
    if (isCustomField) {
      await FormsService.updateQuestionnairesForm(questionnaire._id, {
        custom_fields: questionnaire.custom_fields?.filter(
          (question) => question.custom_field !== questionId,
        ),
      });
    } else {
      await FormsService.updateQuestionnairesForm(questionnaire._id, {
        questions: questionnaire.questions.filter(
          (question) => question.question_id._id !== questionId,
        ),
      });
      try {
        await FormsService.deleteQuestionnairesQuestion(questionId);
      } catch (error) {
        console.log("This question was not deleted from the form as it is par of other form");
      }
    }
    getForm();

    setLoading(false);
  };
  const onEditCustomField = async() => {
    // await CustomFieldsService.updateCustomField(customFieldToEdit._id, data).then((response) => {
    //   const editedCustomFieldQuestion = questionnaire.questions.find((q) => q.question_id.customFieldId === customFieldToEdit._id)
    //   if (editedCustomFieldQuestion?.question_id?._id) {
    //     FormsService.updateQuestionnairesQuestion(editedCustomFieldQuestion.question_id._id, {
    //       ...editedCustomFieldQuestion.question_id,
    //       title: { es: data.name, en: data.name },
    //       options: data.options.map((option) => ({
    //         es: option.value,
    //         en: option.value
    //       })),
    //       type: data.type,
    //       required: data.required
    //     })
    //   }
    //   setCustomFieldToEdit(null)

    //   setShowEditCustomFieldsForm(false)
    //   getCustomFields()
    // })

    await FormsService.updateQuestionnairesForm(questionnaire._id, {
      custom_fields_values: customFieldValues,
    });

    setRowToEdit(null);

    // setShowEditCustomFieldsForm(false)
    getCustomFields();
  };
  const parseCustomFieldToQuestion = async(customFields) => {
    const customFieldsIds = rawCustomFields.map((customField) => customField._id);
    const exisitingResult = await FormsService.getQuestionnairesQuestions({
      customFieldId: customFieldsIds,
    });
    const existingCustomFieldQuestions = exisitingResult.data.data;

    // setExistingCustom(existingCustomFieldQuestions.data.data)

    const questionFomat = customFields.map((customField) => ({
      customFieldId: customField._id,
      isCustomField: true,
      type: customField.type,
      title: { es: customField.name, en: customField.name },
      description: {},
      multipleSelectionEnabled: customField.type === "select",
      required: customField.required,
      tag: "custom_field",
      options: customField.options.map((option) => ({
        es: option.value,
        en: option.value,
      })),
    }));

    const result = await Promise.all(
      questionFomat.map(async(question) => {
        const existing = existingCustomFieldQuestions.find((exisits) => exisits.customFieldId === question.customFieldId);

        if (existing) {
          // if its existing but not in this form
          if (!questionnaire.questions.find((q) => q.question_id._id === existing._id)) {
            return existing;
          }
          return null;
        }
        const data = await FormsService.createQuestionnairesQuestion(question);
        return data.data.data;
      }),
    );
    console.log(
      "*** result saved questions",
      result.filter((q) => q !== null),
    );
    return result.filter((q) => q !== null);
  };

  const saveForm = async() => {
    const customFieldsToQuestions = selectedCustomFields
      .map((s) => rawCustomFields.find((ps) => ps._id === s))
      .filter((cf) => cf.asFormQuestion);

    const customFieldsNoQuestion = selectedCustomFields
      .map((s) => rawCustomFields.find((ps) => ps._id === s))
      .filter((cf) => !cf.asFormQuestion)
      .filter((cf) =>
        // check if the custom field is already in the form
        !questionnaire.custom_fields?.find((q) => q.custom_field === cf._id),
      );

    console.log("** Custom Fields as questions", customFieldsToQuestions);

    // console.log('*** selectedCustomFields', selectedCustomFields.map((s) => rawCustomFields.find((ps) => ps._id === s)).filter((cf) => {
    //   return cf.asFormQuestion
    // }))
    const data = {
      custom_fields: await parseCustomFieldToQuestion(customFieldsToQuestions),
    };

    const cFields = questionnaire.custom_fields ?? [];
    try {
      setLoading(true);
      await FormsService.updateQuestionnairesForm(questionnaire._id, {
        questions: parseQuestions([...questionnaire.questions, ...data.custom_fields]),
        custom_fields: [
          ...cFields,
          ...customFieldsNoQuestion.map((cf) => ({
            custom_field: cf._id,
            options: [],
            value: cf.name,
          })),
        ],
      });
      setShowAddCustomFieldsForm(false);
    } catch (error) {
      console.log(error);
      addToast(t("error_occurred_updating_form"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
      reloadUserInfo();

      await getForm();
    }
  };

  return questionnaire ? (
    <div>
      {/* <button
          onClick={() => {
            setShowFilters(!showFilters)
          }}
        >
          Show filters
        </button> */}
      <div className="mt-4 flex flex-row-reverse">
        <ButtonSecondary
          marginRight={2}
          iconComponent={<FaFileExport className="inline-block mr-2" />}
          text={t("export_responses")}
          onClick={exportResponses}
        />
      </div>

      <div className="flex">
        <div className="w-full">
          <FormResultsSection
            showAddColumn={true}
            handleAddColumn={handleAddCustomField}
            handleEditCustomField={handleEditCustomField}
            questionnaire={questionnaire}
            handleDeleteQuestion={(id, isCustomField) => {
              setLoading(true);
              deleteCustomFieldQuestion(id, isCustomField);
            }}
          />
        </div>
      </div>
      {showAddCustomFieldsForm && (
        <SidePanel
          onClose={() => setShowAddCustomFieldsForm(false)}
          title={t("add_custom_field")}
          width="1/4"
        >
          <h3 className="mb-2">
            <FaEdit className="inline mr-2" />
            {t("add_custom_field")}
          </h3>

          {customFields.length > 0 ? (
            <>
              <Select
                name={"added_custom_fields"}
                label={t("added_custom_fields")}
                placeholder={t("select_option")}
                isClearable={true}
                closeOnClickOutside={true}
                initialValues={
                  selectedCustomFields
                    ? selectedCustomFields.map((selectedFieldId) =>
                      customFields.find((item) => item.id === selectedFieldId),
                    )
                    : []
                }
                items={customFields
                  .map((customField) => ({
                    ...customField,
                    value: `${customField.name} ${customField.asFormQuestion ? " (" + t("is_form_question") + ") " : ""}`,
                  }))
                  .filter((cf) => !questionnaire.questions?.find(
                    (q) => q.question_id.customFieldId === cf.id,
                  ))
                  .filter(
                    (cf) => !questionnaire.custom_fields?.find((q) => q.custom_field === cf.id),
                  )}
                multiSelect={true}
                onSelect={(value) => {
                  setSelectedCustomFields(value.map((s) => s.id));
                }}
              />
              <ButtonMain
                text={t("save")}
                iconComponent={<FaPlus className="inline mr-2" />}
                onClick={saveForm}
              />
            </>
          ) : (
            <Card wrapperClassName={"border-2 border-dotted border-border-buttons-secondary"}>
              <div>
                <FaInfoCircle className="inline mr-2 w-5 h-5" />
                <Trans
                  i18nKey="no_custom_fields_found"
                  components={{ br: <br />, bold: <strong />, small: <small /> }}
                />
              </div>
            </Card>
          )}
        </SidePanel>
      )}
      {/* {showFilters && (
          <DealflowQuestionsFilters onClose={() => setShowFilters(false)} onFilter={setFilters} filters={filters} />
        )} */}
      {rowToEdit && (
        <SidePanel
          onClose={() => setRowToEdit(null)}
          title={customFieldToEdit ? t("update_custom_field") : t("update_custom_field")}
          width="1/4"
        >
          {/* {customFieldToEdit ? <CreateCustomFieldForm customField={customFieldToEdit} onSubmit={onEditCustomField} /> : <StartupEditCustomFields
            dealflowCustomFields={dealflowCustomFields}
            customFields={customFields}
            customFieldsRequired={customFieldsRequired}
            onChange={onChangeCustomFields}
            isDealflow={isDealflow}
            startup={info}
            loading={loading}
            setLoading={setLoading}
          />} */}
          {/* <StartupEditCustomFields customFields={rawCustomFields} dealflowCustomFields={rawCustomFields}/> */}

          <div className="gap-4">
            {questionnaire?.custom_fields
              .map((cf) => rawCustomFields.find((customField) => customField._id === cf.custom_field))
              .map((customField) => (
                <div key={customField._id}>
                  {(customField.type === "select" || customField.type === "multiselect") && (
                    <Select
                      // error={customFieldsRequired.current && !dealflowCustomFields[customField._id] && { message: t('required') }}
                      className="questionnaire-select"
                      id={customField._id}
                      required={customField.required}
                      name={customField._id}
                      multiSelect={customField.type === "multiselect"}
                      label={customField.name}
                      isClearable={!customField.required}
                      placeholder={t("select_answer")}
                      onSelect={(opts) => {
                        onChange(customField._id, opts);
                      }}
                      initialValues={
                        customFieldValues
                          ? customFieldValues[`${customField._id}---${rowToEdit.answers._id}`]
                          : []
                      }
                      items={customField.options.map((option) => ({
                        id: option._id,
                        value: option.value,
                      }))}
                    />
                  )}
                  {customField.type === "short_answer" && (
                    <Input
                      // error={customFieldsRequired.current && !dealflowCustomFields[customField._id] && { message: t('required') }}
                      value={
                        customFieldValues
                          ? customFieldValues[`${customField._id}---${rowToEdit.answers._id}`]
                          : ""
                      }
                      name={customField.name}
                      required={customField.required}
                      placeholder={customField.name}
                      label={customField.name}
                      onChange={(e) => onChange(customField._id, e.target.value)}
                    />
                  )}
                  {customField.type === "long_answer" && (
                    <TextArea
                      value={
                        customFieldValues
                          ? customFieldValues[`${customField._id}---${rowToEdit.answers._id}`]
                          : ""
                      }
                      // error={customFieldsRequired.current && !dealflowCustomFields[customField._id] && { message: t('required') }}
                      required={customField.required}
                      label={customField.name}
                      onChange={(e) => onChange(customField._id, e.target.value)}
                      type="text"
                      placeholder={t("type_answer")}
                      row={8}
                      cols={8}
                      style={{ resize: "none" }}
                    />
                  )}
                  {customField.type === "traffic_light" && (
                    <>
                      <label className="block mb-1 text-left text-xs font-medium text-black">
                        {customField.name}
                      </label>
                      <TrafficLight
                        identifier={customField._id}
                        onClick={(value) => onChange(customField._id, value)}
                        value={
                          customFieldValues
                            ? customFieldValues[`${customField._id}---${rowToEdit.answers._id}`] !==
                              null
                              ? parseInt(
                                customFieldValues[
                                    `${customField._id}---${rowToEdit.answers._id}`
                                ],
                              )
                              : 0
                            : 0
                        }
                      />
                    </>
                  )}
                </div>
              ))}
            <ButtonMain
              text={t("save")}
              iconComponent={<FaPlus className="inline mr-2" />}
              onClick={onEditCustomField}
            />
          </div>
        </SidePanel>
      )}
      {loading && <Loader />}
    </div>
  ) : (
    !loading && (
      <Card wrapperClassName={"border-2 mt-8 border-dotted border-border-buttons-secondary"}>
        <div>
          <FaInfoCircle className="inline mr-2 w-5 h-5" />
          <Trans
            i18nKey="no_main_form_set"
            components={{ br: <br />, bold: <strong />, small: <small /> }}
          />
        </div>
      </Card>
    )
  );
}

export default DealflowMainForm;
