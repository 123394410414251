import { useTranslation } from "react-i18next";
import { SidePanel } from "components/ui/objects/SidePanel";
import { useEffect } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Config from "config";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import Select from "components/ui/atoms/Select";
import { useToasts } from "react-toast-notifications";
import ReactTooltip from "react-tooltip";

/**
 * @param onClose
 * @returns {JSX.Element}
 * @constructor
 */
const DealflowTableColumnSection = ({ onClose, onSubmit, fields = [] }) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const StageSchema = Yup.object().shape({
    field: Yup.string().required(t("required_field")).typeError(t("required_field")),
  });

  const { register, errors, setValue, trigger, getValues, watch } = useForm({
    mode: "onChange",
    criteriaMode: "all",
    reValidateMode: "all",
    resolver: yupResolver(StageSchema, { abortEarly: false }),
  });

  useEffect(() => {
    register("field");
  }, [register]);

  useEffect(() => {
    onSelectField("", true);
    trigger();
  }, []);

  const onSelectField = (field) => {
    setValue("field", field);
    trigger("field");
  };

  return (
    <>
      <SidePanel width={"1/4"} onClose={onClose} title={t("add_column")}>
        <div className="flex flex-col w-full">
          <div className="my-2">
            <Select
              name="field"
              label={t("select_field_to_add")}
              multiSelect={false}
              required={false}
              placeholder={t("select_field_to_add")}
              initialValues={fields.filter((item) => watch("field") === item.id)}
              onSelect={(value) => onSelectField(value[0].id)}
              items={fields}
              isClearable={false}
              error={errors.field}
            />
          </div>
        </div>
        <div className={`flex justify-end mt-2 pt-2 border-t border-separator`}>
          <ButtonMain
            onClick={async () => {
              const validation = await trigger();
              if (validation) {
                onSubmit({
                  ...getValues(),
                });
              } else {
                addToast(t("form_field_error"), { appearance: "error", autoDismiss: true });
              }
            }}
            text={t("add_column")}
          />
        </div>
      </SidePanel>
    </>
  );
};

export default DealflowTableColumnSection;
