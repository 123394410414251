import { RoutesLinks } from "components/routes-links";
import { FaUsersCog } from "react-icons/fa";

const HeaderMenuItems = [
  {
    section: "company",
    title: "company",
    url: RoutesLinks.COMPANY_GENERAL_INFORMATION_LINK,
    icon: <FaUsersCog />,
  },
];

export default HeaderMenuItems;
