import PasswordRequirements from "../../password-requirementsv2";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useState } from "react";
import Config from "config";

export function Input({
  reference,
  error,
  label,
  icon,
  placeholder,
  type,
  maxLength = 255,
  required = false,
  ...inputProps
}) {
  const [internalType, setInternalType] = useState(type || "text");
  return (
    <div className="w-full">
      {label && (
        <label htmlFor={label} className="block mb-1 text-left text-xs font-medium flex">
          {label}&nbsp;
          {required && <span className="text-red inline-block mx-1">*</span>}
        </label>
      )}

      <input
        {...inputProps}
        type={internalType}
        ref={reference}
        maxLength={maxLength}
        placeholder={placeholder}
        required={required}
        className={
          "shadow-soft-white border border-gray-lines focus:border-main bg-input w-full py-3 px-7 mb-2 sm:mb-5 relative z-10 text-left text-xs font-normal rounded-2xl placeholder-gray cursor-pointer transition-all duration-200 outline-none hover:border-main hover:outline-none hover:shadow-inner focus:outline-none focus:shadow-focus active:outline-none"
        }
      />

      {type === "password" && (
        <div className="relative flex justify-end mr-4 bottom-9 md:bottom-12">
          {internalType === "password" && (
            <FaEye
              onClick={() => setInternalType("text")}
              className={"cursor-pointer text-main text-sm z-20"}
            />
          )}

          {internalType !== "password" && (
            <FaEyeSlash
              onClick={() => setInternalType("password")}
              className={"cursor-pointer text-main text-sm z-20"}
            />
          )}
        </div>
      )}

      {error && (error.password_register || error.new_password) && (
        <PasswordRequirements errors={error} />
      )}

      {error && (
        <div className="text-red relative left-2 -top-3 text-xxs text-left">{error.message}</div>
      )}
    </div>
  );
}
