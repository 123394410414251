import { useTranslation } from "react-i18next";
import { FaProjectDiagram } from "react-icons/fa";

export function Card({
  borderColor = null,
  id = null,
  title,
  children,
  padding = "10",
  movilePadding = "4",
  marginX = "0",
  movileMarginX = "0",
  movileMarginY = "0",
  marginY = "0",
  marginYB,
  width = "full",
  shadow = "soft-white",
  rounded = "2xl",
  startupsList = false,
  warning = false,
  clickable = false,
  onClick = null,
  wrapperClassName = "",
  containerClassName = "",
  bgColor = "bg-white",
  investor = null,
  ...inputProps
}) {
  const { t } = useTranslation();

  const classCard = `h-full ${containerClassName} rounded-${rounded} w-full p-${movilePadding} sm:p-${padding} ${(startupsList || clickable) && "hover:shadow-inner"}`;

  return (
    <div
      id={id}
      onClick={onClick}
      className={`
        relative
        ${borderColor && `border border-${borderColor}`} 
        ${wrapperClassName} ${bgColor} rounded-${rounded}
        w-${width} px-${movileMarginX} py-${movileMarginY} sm:px-${marginX} 
        sm:py-${marginY} pb-${marginYB} ${clickable && "cursor-pointer"} 
      `}
    >
      <div className={classCard} {...inputProps}>
        {title && <h2 className="font-semibold mb-4">{title}</h2>}
        {children}
        {investor && investor.company_name && (
          <div className="left-3 bottom-3 absolute">
            <div className="flex gap-2 items-center" role="button" data-tip={t("belongs_to_investor")}>
              <FaProjectDiagram className={"text-main"} />
                <span className="text-xxs">
                  {investor.company_name}
                </span>
            </div>
          </div>
        )}
        </div>
    </div>
  );
}
