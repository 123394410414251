import { useState } from "react";
import { Modal } from "components/ui/molecules/Modal";
import { Autocomplete } from "components/ui/atoms/Autocomplete";
import { useTranslation } from "react-i18next";
import { ButtonMain } from "components/ui/atoms/ButtonMain";

const NewCardModal = ({
  dealflow,
  showModal,
  handleCloseModal,
  onSubmit,
  showCreateDealSection,
}) => {
  const { t } = useTranslation();
  const [selectedDealflow, setSelectedDealflow] = useState(null);
  const [error, setError] = useState(null);

  const onSelect = (value, setter) => {
    setter(value);
    setError(!value ? { message: t("required_field") } : null);
  };

  const submit = () => {
    if (selectedDealflow) {
      handleCloseModal();
      onSubmit({ dealflow: dealflow.find((d) => d._id === selectedDealflow) });
    } else {
      setError({ message: t("required_field") });
    }
  };

  return (
    <Modal
      showModal={showModal}
      paddingBottom="3"
      fixedWidth="w-6/12"
      showCloseModal={true}
      onClose={handleCloseModal}
    >
      <h2 className={"font-semibold text-xl mr-4  text-main"}>{t("add_startup")}</h2>
      <div className="my-3">
        <Autocomplete
          labelColor={selectedDealflow ? "text-black" : "text-red"}
          name="startup"
          placeholder={t("search_startup")}
          isClearable={true}
          required={true}
          label={t("startup")}
          options={dealflow.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))}
          onSelect={(value) => onSelect(value?._id || null, setSelectedDealflow, "dealflow")}
          error={error}
        />
      </div>
      <div className="mt-1">
        <div>
          {t("dont_find_startup")}{" "}
          <a className="cursor-pointer" onClick={() => showCreateDealSection()}>
            {t("create_here")}
          </a>
        </div>
      </div>

      <div className={"flex justify-end mt-4 border-t pt-2 border-separator"}>
        <ButtonMain type="submit" onClick={() => submit()} text={t("add_startup")} />
      </div>
    </Modal>
  );
};

export default NewCardModal;
