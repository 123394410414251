import { Outlet, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import useUser from "hooks/useUser";
import { useTranslation } from "react-i18next";
// import { CompanyRoles } from './CompanyRoles'
import { SectionHeader } from "components/ui/molecules/SectionHeader";
import { PageTitle } from "components/ui/atoms/PageTitle";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { FaPlus } from "react-icons/fa";
import { MiniHelp } from "components/ui/atoms/MiniHelp";
import { getPathSegment } from "utils/get-path-segment";

// const SECTIONS = [
//   { id: "general-information", route: "general-information", title: "general_information" },
//   { id: "team", route: "team", title: "team" },
//   { id: "lps", route: "lps", title: "lps" },
//   { id: "investment-thesis", route: "investment-thesis", title: "investment_thesis" },
//   { id: "settings", route: "settings", title: "settings" },
//   { id: "children", route: "children", title: "subcompanies" },
// ];

const CHILDRENSECTIONS = [
  { id: "general-information", route: "general-information", title: "general_information" },
  { id: "team", route: "team", title: "team" },
  { id: "lps", route: "lps", title: "lps" },
  { id: "investment-thesis", route: "investment-thesis", title: "investment_thesis" },
  { id: "settings", route: "settings", title: "settings" },
  // { id: "children-settings", route: "settings", title: "children-settings" },
];

export const Company = () => {
  const { t } = useTranslation();
  const { user, reloadUserInfo, hasAccessToModule } = useUser();

  const SECTIONS = [
    { id: "general-information", route: "general-information", title: "general_information" },
    { id: "team", route: "team", title: "team" },
    { id: "lps", route: "lps", title: "lps" },
    { id: "investment-thesis", route: "investment-thesis", title: "investment_thesis" },
    { id: "settings", route: "settings", title: "settings" },
    (hasAccessToModule("subcompanies") && { id: "children", route: "children", title: "subcompanies" }),
  ];

  const [investor, setInvestor] = useState(user?.investor);
  const [isChildren, setIsChildren] = useState(false);
  const nav = useNavigate();

  const [section, setSection] = useState(getPathSegment(location.href, 2));
  const pageRef = useRef();

  useEffect(() => {
    setSection(getPathSegment(location.href, 2));
    setIsChildren(user?.investor._id !== investor?._id || user?.investor.parent_id !== undefined);
  }, [investor]);

  const reloadUser = async(deleted = false) => {
    const newUser = await reloadUserInfo();
    setInvestor(deleted ? newUser.investor : !isChildren ? newUser?.investor : newUser?.investor.children?.find((child) => child._id === investor._id ?? newUser?.investor));
  };

  // useEffect(() => {

  // }, [reloadUserInfo]);

  // useEffect(() => {
  //   setInvestor(!isChildren ? investor : investor.children?.find((child) => child._id === investor._id));
  // }, [reloadUserInfo]);

  return (<>
        <div className="flex items-start mb-6">
          <div className="flex-1">
            <PageTitle removeMargin={true} showBack={isChildren} onBackClick={() => {
              setInvestor(user?.investor);
              nav("children");
            }} title={!isChildren ? t("company") : `${t("company")} - ${investor?.company_name}`} />
          </div>
          {section === "lps" && (
          <ButtonMain
            onClick={() => pageRef.current?.showLPSection()}
            text={t("add_lp")}
            iconComponent={<FaPlus className="inline-block mr-3" />}
          />
          )}
          {section === "team" && (<ButtonMain
            onClick={() => pageRef.current?.showTeamMateSection()}
            text={t("add_teammate")}
            iconComponent={<FaPlus className="inline-block mr-3" />}
          />
          )}
          {section === "investment_thesis" && !user?.help?.configure_investment_thesis && (
            <MiniHelp item={"configure_investment_thesis"} />
          )}
          {section === "settings" && !user?.help?.configure_investment_thesis && (
            <MiniHelp item={"configure_investment_thesis"} />
          )}
          {section === "children" && (<ButtonMain
            onClick={() => pageRef.current?.showCompanyChildrenWizard()}
            text={t("add_subcompany")}
            iconComponent={<FaPlus className="inline-block mr-3" />}
          />
          )}
        </div>
        <SectionHeader
          sectionTitles={SECTIONS.map((sec) => t(sec))}
          sectionKeys={isChildren ? CHILDRENSECTIONS : SECTIONS}
          section={section}
          setSection={(sec) => {
            setSection(sec);
          }}
          navigate
          />
        {investor && <Outlet context={{ ref: pageRef, reloadUserInfo: reloadUser, investor, setInvestor, isChildren }} />}
    </>
  );
};
