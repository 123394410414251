/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useToasts } from "react-toast-notifications";
import { DealflowService } from "services/dealflow-service";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { SidePanel } from "components/ui/objects/SidePanel";
import { Autocomplete } from "components/ui/atoms/Autocomplete";
import PipelineManagersService from "services/pipeline-managers-service";
import CreateStartupForm from "components/edit-startup/components/CreateStartupForm";
import useUser from "hooks/useUser";
import { useSwal } from "hooks/useSwal";

export const AddStartUpSidePanel = ({ onFilter, onClose, filters = {}, onDealAdded }) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [startupsFromPool, setStartupsFromPool] = useState([]);
  const [showCreateStartUp, setShowCreateStartup] = useState(false);
  const [boards, setBoards] = useState([]);
  const { user, reloadUserInfo } = useUser();
  const [dealAdded, setDealAdded] = useState(false);

  const { alert, confirm } = useSwal();
  const { register, handleSubmit, trigger, setValue, getValues, reset } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string(),
      }),
      { abortEarly: false },
    ),
    criteriaMode: "all",
    reValidateMode: "all",
    mode: "onChange",
  });

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, config);
    trigger(name);
  };

  const getPipelineManagers = async () => {
    try {
      // setLoadingBoards(true)
      const result = await PipelineManagersService.getPipelineManagers();
      setBoards(result.data.data);
    } catch (error) {
      console.error(error);
      addToast(
        error.response && error.response.data.msg_key
          ? error.response.data.msg_key
          : t("error_occurred_retrieving_boards"),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
    } finally {
      // setLoadingBoards(false)
    }
  };

  const filter = ({ clearFilters, ...filterValues }) => {
    let _filters = {};

    if (!clearFilters) {
      _filters = { name: filterValues.name };
    }

    onFilter && onFilter(_filters);
    onClose && onClose();
  };

  const resetFilters = () => {
    reset();
    filter({ clearFilters: true });
  };

  const onInvalid = () => {
    addToast(t("form_field_error"), { appearance: "error", autoDismiss: true });
  };

  const getStartupsFromPool = () => {
    DealflowService.getDealflow({
      rowsPerPage: 999999,
      pool: "true",
      populate: "false",
      // portfolio: 'false',
      boards: "false",
      milestones: "false",
      files: "false",
    })
      .then((response) => {
        setStartupsFromPool(
          response.data.data.dealflow.filter((item) => {
            return !(item.portfolio == "true" || item.portfolio == true);
          }),
        );
      })
      .catch(() => {
        addToast(t("error_occurred"), {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  useEffect(() => {
    if (filters) {
      Object.entries(filters).forEach(([key, value]) => {
        _setValue(key, value, { shouldValidate: true });
      });
    }
    getStartupsFromPool();
  }, [filters, register]);

  return (
    <SidePanel title={t("add_startup")} onClose={() => onClose && onClose()} width={"sm:w-1/3"}>
      {!showCreateStartUp ? (
        <form className="flex flex-col w-full" onSubmit={handleSubmit(filter, onInvalid)}>
          {/* <h3>Add StartUpSidePanel</h3> */}
          <div className="mt-2">
            <Autocomplete
              displayLogo={true}
              name="startup"
              placeholder={t("search_startup")}
              isClearable={false}
              label={t("add_startup")}
              reference={register("name")}
              initialValues={getValues("name")}
              required={true}
              options={startupsFromPool}
              error={
                getValues("name") !== undefined && getValues("name") === null
                  ? { message: t("required_field") }
                  : null
              }
              onSelect={(selected) => {
                setValue("name", selected._id);
                // trigger()
              }}
            />
            <pre>{/* {JSON.stringify(getValues('name'), null, 2)} */}</pre>
          </div>

          <div className={"flex justify-end mt-2 pt-2 border-t border-separator"}>
            <ButtonSecondary
              text={t("clear")}
              onClick={() => resetFilters()}
              type="button"
              marginRight={2}
            />
            <ButtonMain text={t("add_startup")} type="submit" />
          </div>
          <div className="mt-4">
            ¿No encuentras la startup?{" "}
            <a
              className="text-blue-500"
              onClick={() => {
                setShowCreateStartup(true);
              }}
            >
              Creala y añádela desde aquí
            </a>
          </div>
        </form>
      ) : (
        <div>
          <CreateStartupForm portfolio={true} onSubmit={onDealAdded} boards={boards} />
        </div>
      )}
    </SidePanel>
  );
};
