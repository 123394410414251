import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../../../ui/molecules/Modal";

import { CanvaTestTwo } from "components/ui/atoms/CanvaTestTwo";
import { useToasts } from "react-toast-notifications";

import { Input } from "components/ui/atoms/Input";
import DrawnotesForm from "./components/draw-notes-form/draw-notes-form";

import { isEmpty } from "lodash";

import Config from "config";
import { ButtonMain } from "components/ui/atoms/ButtonMain";

const DrawNotes = ({ showModal, handleClose, onSubmit, initialValues, editable }) => {
  const { t } = useTranslation();

  const canvasRef = useRef(null);
  const { addToast } = useToasts();

  const [title, setTitle] = useState(isEmpty(initialValues) ? "" : initialValues.title);

  const [errorTitle, setErrorTitle] = useState(null);

  return (
    <>
      <Modal showModal={showModal} showCloseModal={true} onClose={handleClose} paddingBottom="4">
        <h3 className={"font-medium mr-4 text-main"}>{t("add_draw_note")}</h3>

        <Input
          disabled={!editable}
          value={title}
          id="title"
          type="text"
          name="title"
          placeholder={t("introduce_note_title")}
          label={`${t("note_title")} :`}
          error={errorTitle}
          onChange={(e) => {
            if (e.target.value) {
              setErrorTitle(null);
            } else {
              setErrorTitle({ message: t("required_field") });
            }
            setTitle(e.target.value);
          }}
        />

        <div className="w-full">
          <CanvaTestTwo onSubmit={onSubmit} canvasRef={canvasRef} content={initialValues.content} />
        </div>

        {editable && (
          <div className={"flex justify-end mt-4 border-t pt-2 border-separator"}>
            <ButtonMain
              onClick={() => {
                if (!title) {
                  setErrorTitle({ message: t("required_field") });
                } else {
                  setErrorTitle(null);
                  canvasRef.current
                    .exportPaths()
                    .then((data) => {
                      onSubmit(title, JSON.stringify(data));
                    })
                    .catch((e) => {
                      addToast(t("error_occurred_exporting_draw_note"), {
                        appearance: "error",
                        autoDismiss: true,
                      });
                    });
                }
              }}
              text={t("save")}
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default DrawNotes;
