/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { FaTrash } from "react-icons/fa";

import { useToasts } from "react-toast-notifications";
import { DealflowService } from "services/dealflow-service";
import Select from "components/ui/atoms/Select";
import { Input } from "components/ui/atoms/Input";
import { TextArea } from "components/ui/atoms/TextArea";
import { Switch } from "components/ui/atoms/Switch";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { Checkbox } from "components/ui/molecules/Checkbox";
import { SidePanel } from "components/ui/objects/SidePanel";
import { Autocomplete } from "components/ui/atoms/Autocomplete";
import InvestmentStages from "assets/json/investment-stages.json";
import moment from "moment";
import { ButtonDanger } from "components/ui/atoms/ButtonDanger";
import { useSwal } from "hooks/useSwal";
import { InvestmentsService } from "services/investments-service";
import { CurrencyInput } from "components/ui/atoms/CurrencyInput";
import useCurrency from "hooks/useCurrency";

const EditInvestmentSection = ({
  onClose,
  onSubmit,
  startups,
  startUpId,
  investment,
  onDeleteInvestment,
}) => {
  const { t } = useTranslation();
  const [startupsFromPool, setStartupsFromPool] = useState([]);
  const { addToast } = useToasts();
  const { currency } = useCurrency();

  const STATUS = [
    { id: "commited", value: t("commited") },
    { id: "invested", value: t("invested") },
  ];
  const STEP_INVESTMENT = InvestmentStages.map((item) => {
    return { _id: item._id, id: item.key, value: item.translation_en };
  });
  const { alert, confirm } = useSwal();

  const [checkedOption, setCheckedOption] = useState("");

  const handleCheckboxChange = (option) => {
    setCheckedOption(option);
  };

  const [startudId, setStartupId] = useState(null);

  const InvestorSchema = Yup.object().shape({
    deal: Yup.string().nullable(),
    first_ticket: Yup.boolean().required(t("required_field")),
    follow_on: Yup.boolean().required(t("required_field")),
    status: Yup.string().required(t("required_field")).default(investment?.status),
    investor_type: Yup.object().required(t("required_field")),
    checkboxValidation: Yup.boolean().test(
      "checkbox-test",
      t("required_field"),
      (value, context) => {
        return context.parent.first_ticket || context.parent.follow_on;
      },
    ),
    investment: Yup.string().required(t("required_field")),
    shares: Yup.number().required(t("required_field")),
    commission: Yup.string(),
    round: Yup.string().required(t("required_field")),
    premoney_valuation: Yup.string().required(t("required_field")),
    is_own_investment: Yup.boolean().required(t("required_field")),
    investment_date: Yup.string().required(t("required_field")),
    investment_stage: Yup.string().required(t("required_field")),
    comments: Yup.string().nullable(),
  });

  const {
    register,
    formState: { errors },
    trigger,
    getValues,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(InvestorSchema),
    criteriaMode: "all",
    reValidateMode: "all",
    mode: "onChange",
  });

  const _setValue = async (name, value, config = {}) => {
    setValue(name, value, config);
    await trigger(name);
  };

  const onSelectStartUp = async (startup) => {
    await _setValue("deal", startup ? startup._id : null);
  };

  const getStartupsFromPool = () => {
    DealflowService.getDealflow({
      rowsPerPage: 999999,
      pool: "true",
      populate: "false",
      boards: "false",
      milestones: "false",
      files: "false",
      portfolio: "true",
    })
      .then((response) => {
        setStartupsFromPool(response.data.data.dealflow);
      })
      .catch(() => {
        addToast(t("error_occurred"), {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  // const onDeleteInvestment = () => {
  //   confirm().then(async (isConfirmed) => {
  //     if (isConfirmed) {
  //       // setLoading(true)
  //       DealflowService.deleteInvestment(investment.deal._id, investment._id)
  //         .then(() => addToast(t('investment_deleted_successfully'), {
  //           appearance: 'success'
  //         }))
  //         .catch(error => addToast(t('error_occurred_deleting_investment'), {
  //           appearance: 'error'
  //         }))
  //         .finally(() => onClose())
  //     }
  //   })
  // }

  useEffect(() => {
    if (investment) {
      setValue("deal", investment.deal._id);
      setValue("status", investment.status);
      setValue("investment", investment.investment + "");
      setValue("shares", investment.shares);
      setValue("commission", investment.commission + "");
      setValue("round", investment.round + "");
      setValue("premoney_valuation", investment.premoney_valuation + "");
      setValue("investment_date", investment.investment_date);
      setValue("comments", investment.comments);
      setValue("its_own_investment", investment.its_own_investment);
      setValue("investment_date", moment(investment.investment_date).format("YYYY-MM-DD"));
      setValue("investment_stage", investment.investment_stage);
      setValue("is_own_investment", investment.is_own_investment);
      setCheckedOption(investment.investment_type);
      trigger();
    }
  }, [investment]);

  useEffect(() => {
    getStartupsFromPool();
    setValue("is_shareholder", true);

    console.log("Selected investment", investment);
    console.log("StartUp from pool", startupsFromPool);
    if (startUpId) {
      setValue("startup", startUpId);
    }
  }, []);

  const getSelectableInitialValues = (id, collection, lonely) => {
    console.log("collection", collection);
    if (!id) {
      return lonely ? null : [];
    }

    if (Array.isArray(id)) {
      return collection.filter((i) => id.includes(i.id));
    }

    const item = collection.find((i) => i._id === id);
    return item ? (lonely ? item : [item]) : lonely ? null : [];
  };
  return (
    <>
      <SidePanel onClose={onClose} title={t("edit_investment")} width={"1/3"}>
        {/* <h3>Edit Investment section</h3> */}
        <form className="w-full">
          <div>
            <Autocomplete
              displayLogo
              name="startup"
              placeholder={t("search_startup")}
              reference={register("deal")}
              isClearable={false}
              label={t("add_startup")}
              required={true}
              initialValues={getSelectableInitialValues(
                investment.deal._id,
                startupsFromPool,
                true,
              )}
              options={startUpId ? [startUpId] : startupsFromPool}
              error={
                getValues("startup") !== undefined && getValues("startup") === null
                  ? { message: t("required_field") }
                  : null
              }
              onSelect={async (selected) => await onSelectStartUp(selected)}
            />
            {/* {JSON.stringify(getValues('startup'), null, 2)} */}
            <div className="block mb-1 | text-left text-xs font-medium text-black">
              {`${t("investment_type")}:`} <span className="text-red">*</span>
            </div>
            <div className="flex mb-3">
              <Checkbox
                id="first_ticket"
                label={t("first_ticket")}
                name="first_ticket"
                onChange={() => handleCheckboxChange("first_investment")}
                checked={checkedOption === "first_investment"}
                // reference={register('first_ticket')} // Ensure the checkboxes are registered correctly
                error={errors.first_ticket}
              />

              <Checkbox
                id="follow_on"
                label={t("follow_on")}
                name="follow_on"
                onChange={() => handleCheckboxChange("follow_on")}
                checked={checkedOption === "follow_on"}
                // reference={register('follow_on')} // Ensure the checkboxes are registered correctly
                error={errors.follow_on}
              />
            </div>
            <div>
              <label
                htmlFor="select-investor-type"
                className="block mb-1 | text-left text-xs font-medium text-black"
              >
                {`${t("status")}:`} <span className="text-red">*</span>
              </label>
              <Select
                id="status"
                items={STATUS}
                register={register("status")}
                initialValues={
                  investment?._id ? [STATUS.find((item) => item.id === investment.status)] : []
                }
                onSelect={(selected) => {
                  setValue("status", selected[0].id);
                }}
                placeholder={t("select_default_placeholder")}
                name="status"
                error={getValues("status") === null ? { message: t("required_field") } : null}
              />
            </div>
            <div>
              <label
                htmlFor="select-step_investment-type"
                className="block mb-1 | text-left text-xs font-medium text-black"
              >
                {`${t("investment_stage")}:`} <span className="text-red">*</span>
              </label>
              <Select
                id="status"
                items={STEP_INVESTMENT}
                register={register("investment_stage")}
                initialValues={
                  investment
                    ? [STEP_INVESTMENT.find((item) => item._id === investment.investment_stage)]
                    : []
                }
                onSelect={(selected) => {
                  console.log("investment_stage", selected[0]._id);
                  setValue("investment_stage", selected[0]._id);
                }}
                placeholder={t("select_default_placeholder")}
                name="investment_stage"
                error={
                  getValues("investment_stage") === null ? { message: t("required_field") } : null
                }
              />
            </div>
            <Input
              reference={register}
              id="investment"
              type="text"
              name="investment"
              // watch={watch}
              required={true}
              label={`${t("investment_label")} (${currency}):`}
              placeholder={t("investment_placeholder")}
              error={errors.investment}
            />
          </div>
          <div>
            <Input
              reference={register}
              id="shares"
              type="number"
              name="shares"
              required={true}
              label={`${t("ownership_label")} (%):`}
              placeholder={t("ownership_placeholder")}
              error={errors.ownership}
            />
          </div>
          <div>
            <Input
              reference={register}
              id="commission"
              type="text"
              required={false}
              name="commission"
              label={`${t("commission_label")} (${currency}):`}
              placeholder={t("commission_placeholder")}
              error={errors.commission}
            />
          </div>
          <div>
            <Input
              reference={register}
              id="round"
              type="number"
              name="round"
              required={true}
              placeholder={t("round")}
              label={`${t("round")} (${currency})`}
              error={errors.round}
            />
          </div>
          <div>
            <Input
              reference={register}
              id="premoney_valuation"
              type="text"
              name="premoney_valuation"
              required={true}
              label={`${t("pre_money")} (${currency})`}
              placeholder={t("pre_money")}
              error={errors.pre_mone}
            />
          </div>

          <div className="my-2">
            <label
              htmlFor="select-step_investment-type"
              className="block mb-1 | text-left text-xs font-medium text-black"
            >
              {`${t("is_shareholder")}: `}
              <span className="text-red">*</span>
            </label>
            <Switch
              text={t("is_shareholder")}
              register={register("is_own_investment")}
              onChange={(selected) => {
                setValue("is_own_investment", selected);
              }}
              checked={getValues("is_own_investment")}
            ></Switch>
          </div>
          <div className="mt-6">
            <Input
              reference={register}
              id="investment_date"
              type="date"
              required={true}
              name="investment_date"
              label={`${t("date_investment_label")}:`}
              placeholder={t("date_investment_placeholder")}
              error={errors.investment_date}
            />
          </div>
          <div className="mt-2">
            <TextArea
              reference={register}
              id="ncommentsotes"
              type="text"
              name="comments"
              required={false}
              label={`${t("comments")}`}
              placeholder={t("comments")}
              error={errors.comments}
            />
          </div>
          {/* <div className='mt-4'>
            <InputFile
              reference={register}
              id="files"
              name="files"
              required={true}
              label={`${t('files')}`}
              placeholder={t('files')}
              error={errors.files}
            />
          </div> */}
          <div className={"flex justify-between mt-4 pt-2 border-t border-separator"}>
            <ButtonDanger
              type="button"
              iconComponent={<FaTrash className="inline-block mr-2" />}
              onClick={async (e) => {
                e.preventDefault();
                onDeleteInvestment(investment);
              }}
              text={t("delete_investment")}
            />
            <ButtonMain
              type="button"
              onClick={async () => {
                const validation = await trigger();
                onSubmit();
                if (validation)
                  onSubmit(investment._id, {
                    ...getValues(),
                    investment_type: checkedOption,
                    comission: getValues("commission") ? getValues("commission") : "0",
                  });
              }}
              text={t("edit_investment")}
            />
          </div>
        </form>
      </SidePanel>
    </>
  );
};

export default EditInvestmentSection;
