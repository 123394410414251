import { ChecksActionsTypes } from "./checks-actions-types";

const ChecksActions = {
  setChecked: (checked) => ({
    type: ChecksActionsTypes.SET_CHECKED,
    payload: { checked },
  }),

  setStage: (stage) => ({
    type: ChecksActionsTypes.SET_STAGE,
    payload: { stage },
  }),
};

export default ChecksActions;
