import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import FormsService from "services/forms-service";
import { useParams, useNavigate } from "react-router-dom";
import { RoutesLinks } from "components/routes-links";
import { useEffect, useState } from "react";
import { Loader } from "components/ui/molecules/Loader";
import { PageTitle } from "components/ui/atoms/PageTitle";
import { Question } from "components/qe-questions/components/Question";
import TranslateUtils from "utils/translate-utils";

import CreateFormFromTemplateModal from "components/qe-templates/components/templates-modal";
import useUser from "hooks/useUser";
import { ButtonMain } from "components/ui/atoms/ButtonMain";

const Template = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { templateId } = useParams();
  const { userLanguage, reloadUserInfo } = useUser();
const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState(null);
  const [showCreateFormModal, setShowCreateFormModal] = useState(false);

  const getTemplate = () => {
    setLoading(true);
    return FormsService.getQuestionnairesTemplate(templateId)
      .then((response) => {
        setTemplate(response.data.data);
      })
      .catch(() => {
        addToast(t("error_retrieving_template"), {
          appearance: "error",
          autoDismiss: true,
        });
        navigate(RoutesLinks.QE_TEMPLATES_LINK);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createFormFromTemplate = (name) => {
    setLoading(true);
    setShowCreateFormModal(false);

    return FormsService.createQuestionnairesForm({ template: templateId, name })
      .then((response) => {
        addToast(t("form_created_successfully"), {
          appearance: "success",
          autoDismiss: true,
        });
        navigate(`${RoutesLinks.QE_FORMS_LINK}/${response.data.data._id}`, {
          section: "content",
        });
        reloadUserInfo();
      })
      .catch(() => {
        addToast(t("error_creating_questionnaire"), {
          appearance: "error",
          autoDismiss: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getKeyQuestionTitle = (questionKey) => {
    if (!template?.key_questions[questionKey]) {
      return t("no_data");
    }

    const question = template.questions.find((question) => {
      return question.question_id._id === template.key_questions[questionKey];
    });

    return TranslateUtils.getTextByLang(userLanguage, question?.question_id?.title);
  };

  useEffect(() => {
    if (templateId) {
      getTemplate();
    }
  }, [templateId]);

  return (
    <>
      <div className="flex items-start">
        <div className="flex-1">
          <PageTitle
            title={`${t("template")}: ${template?.name || ""}`}
            showBack={true}
            onBackClick={() => navigate(RoutesLinks.QE_TEMPLATES_LINK)}
          />
        </div>

        <ButtonMain onClick={() => setShowCreateFormModal(true)} text={t("use_this_template")} />
      </div>

      {showCreateFormModal && (
        <CreateFormFromTemplateModal
          showModal={showCreateFormModal}
          onSubmit={createFormFromTemplate}
          handleCloseModal={() => setShowCreateFormModal(false)}
        />
      )}

      {template && (
        <div className="flex my-6">
          <div className={"w-full pr-4"}>
            {template.questions.map((question) => (
              <div className="my-2">
                <Question
                  key={question.question_id._id}
                  question={{
                    ...question.question_id,
                    key_question: Object.keys(template.key_questions).some(
                      (key) => template.key_questions[key] === question.question_id._id,
                    ),
                  }}
                  showRequiredBadge={question.required}
                  showTag={true}
                  openEditInTab={false}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {loading && <Loader />}
    </>
  );
};

export default Template;
