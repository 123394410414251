import axios from "axios/index";
import Config from "../config";

const ContactsService = {
  getContacts: async(params) => axios.post(`${Config.CONTACTS_API_URL}search`, params),

  getContactsList: async() => axios.get(`${Config.CONTACTS_API_URL}minified-list`),

  registerContact: async(data) => axios.post(`${Config.CONTACTS_API_URL}`, data),

  getContact: async(contactId) => axios.get(`${Config.CONTACTS_API_URL}${contactId}`),

  requestDealflowPreferences: async(contactId) =>
    axios.post(`${Config.CONTACTS_API_URL}${contactId}/dealflow-preferences/request`),

  saveContact: async(contactId, data) =>
    axios.patch(`${Config.CONTACTS_API_URL}${contactId}`, data),

  deleteContact: async(contactId) => axios.delete(`${Config.CONTACTS_API_URL}${contactId}`),

  getDealflowPreferences: (contactId) =>
    axios.get(`${Config.CONTACTS_API_URL}${contactId}/dealflow-preferences`),

  saveDealflowPreferences: (contactId, data) =>
    axios.post(`${Config.CONTACTS_API_URL}${contactId}/dealflow-preferences`, data),

  getSuggestedStartups: (contactId, query) =>
    axios.post(`${Config.CONTACTS_API_URL}${contactId}/suggested-startups`, query),
};
export default ContactsService;
