import axios from "axios/index";
import Config from "../config";

const LPService = {
  getLPs: async(investorId = undefined) => axios.get(`${Config.TEAM_API_URL}lps`, { params: { investor: investorId } }),

  createLP: async(lp) => axios.post(`${Config.TEAM_API_URL}lps`, { ...lp, lp: true }),

  removeLP: async(lpId) => axios.delete(`${Config.TEAM_API_URL}lps/${lpId}`),

  updateLP: async(lpId, name, lastName, email, language, lpPermissions) =>
    axios.patch(`${Config.TEAM_API_URL}lps/${lpId}`, {
      name,
      last_name: lastName,
      email,
      language,
      lp_permissions: lpPermissions,
      lp: true,
    }),

  sendActivationCode: async(lpId, children) =>
    axios.post(`${Config.TEAM_API_URL}lps/${lpId}/send-activation-code`, {}, { params: { children } }),
};
export default LPService;
