import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { SectionHeader } from "components/ui/molecules/SectionHeader";
import { useToasts } from "react-toast-notifications";
import { useParams, useNavigate } from "react-router-dom";

import { RoutesLinks } from "components/routes-links";
import { isEmpty } from "lodash";
import { EventJudges } from "components/events/components/event/components/EventJudges";
import { EventStartups } from "components/events/components/event/components/EventStartups";
import { Loader } from "components/ui/molecules/Loader";
import {
  FaFileDownload,
  FaFilter,
  FaPlus,
  FaPlusCircle,
  FaShare,
  FaTrash,
} from "react-icons/fa";
import { BiSort } from "react-icons/bi";
import { EventCreateOrUpdateForm } from "components/events/components/event/components/EventCreateOrUpdateForm";
import { useSwal } from "hooks/useSwal";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import { Card } from "components/ui/atoms/Card";
import { PageTitle } from "components/ui/atoms/PageTitle";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { ButtonDanger } from "components/ui/atoms/ButtonDanger";
import { Modal } from "components/ui/molecules/Modal";
import { ExportEvaluationsOptionsSections } from "./components/ExportEvaluationsOptionsSections";
import ReactTooltip from "react-tooltip";
import { Arrow, useLayer } from "react-laag";
import useUser from "hooks/useUser";
import { MiniHelp } from "components/ui/atoms/MiniHelp";
import ScoreCardsService from "services/score-cards-service";
import EvaluationProcessesManagerService from "services/evaluation-processes-manager-service";
import ReportsService from "services/reports-service";

const Event = () => {
  const { t } = useTranslation();
  const { eventId } = useParams();
  const { addToast } = useToasts();
  const { confirm } = useSwal();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState("startups");
  const initialSections = ["startups", "judges", "settings"].map(section => ({
    id: section,
    route: section,
    title: section,
  }));

  const [sections, setSections] = useState(initialSections);
  const [showStartupsButton, setShowStartupsButton] = useState(false);
  const eventStartupsRef = useRef();
  const eventJudgesRef = useRef();
  const [showFilters, setShowFilters] = useState(false);
  const [scoreCards, setScoreCards] = useState([]);
  const [evaluations, setEvaluations] = useState([]);
  const [showUsedEvaluationProcessError, setShowUsedEvaluationProcessError] = useState(false);
  const [showUsedEvaluationProcessErrorArchiving, setShowUsedEvaluationProcessErrorArchiving] =
    useState(false);
  const [evaluationProcessInBoards, setEvaluationProcessInBoards] = useState([]);
  const [showExportEvaluationsOptions, setShowExportEvaluationsOptions] = useState(false);
  const [showFilterStartups, setShowFilterStartups] = useState(false);
  const [orderStartupsBy, setOrderStartupsBy] = useState(null);
  const { user, checkIfLPhasAccessToSection } = useUser();
  const [filtersCount, setFiltersCount] = useState(0);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const getEvaluations = async() => {
    try {
      const result = await EvaluationProcessesManagerService.getEvaluations({
        event: eventId,
        rowsPerPage: 999999,
      });
      setEvaluations(result.data.data.evaluations);
    } catch (error) {
      addToast(t("error_occurred_retrieving_evaluations"), {
        appearance: "error",
        autoDismiss: true,
      });
      navigate(RoutesLinks.EVALUATION_PROCESSES_LINK);
    } finally {
      setLoading(false);
    }
  };

  const updateEvent = (data) => {
    setLoading(true);
    return EvaluationProcessesManagerService.updateEvaluationProcess(event._id, data)
      .then(() => {
        addToast(t("event_updated_successfully"), {
          appearance: "success",
          autoDismiss: true,
        });
        return getEvent();
      })
      .catch((error) => {
        if (error.response) {
          addToast(t("error_occurred_updating_event"), {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const archiveEvent = () => confirm({
    title: t("archive_event_confirmation"),
    text: t("archive_event_confirmation_text"),
  }).then(async(isConfirmed) => {
    if (isConfirmed) {
      setLoading(true);
      await EvaluationProcessesManagerService.archiveEvaluationProcess(event._id)
        .then(() => {
          addToast(t("event_archived_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
          navigate(RoutesLinks.EVALUATION_PROCESSES_LINK);
        })
        .catch((error) => {
          if (error.response.status === 403) {
            setShowUsedEvaluationProcessErrorArchiving(true);
            setEvaluationProcessInBoards(error.response.data.extra.boards);
          } else {
            addToast(
              error.response
                ? error.response.data.msg_key
                  ? t(error.response.data.msg_key)
                  : t("error_occurred")
                : t("error_occurred_archiving_event"),
              {
                appearance: "error",
                autoDismiss: true,
              },
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  });

  const deleteEvent = () => {
    confirm({
      title: t("delete_event_confirmation"),
      text: t("delete_event_confirmation_text"),
    }).then(async(isConfirmed) => {
      if (isConfirmed) {
        setLoading(true);
        await EvaluationProcessesManagerService.deleteEvaluationProcess(event._id)
          .then(() => {
            addToast(t("event_deleted_successfully"), {
              appearance: "success",
              autoDismiss: true,
            });
            navigate(RoutesLinks.EVALUATION_PROCESSES_LINK);
          })
          .catch((error) => {
            if (error.response.status === 403) {
              setShowUsedEvaluationProcessError(true);
              setEvaluationProcessInBoards(error.response.data.extra.boards);
            } else {
              addToast(
                error.response
                  ? error.response.data.msg_key
                    ? t(error.response.data.msg_key)
                    : t("error_occurred")
                  : t("error_occurred_deleting_event"),
                {
                  appearance: "error",
                  autoDismiss: true,
                },
              );
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  };

  const isArchived = () => event && event.archived === true;

  const getEvent = async() => {
    try {
      const result = await EvaluationProcessesManagerService.getEvaluationProcess(eventId);
      if (!isEmpty(result.data.data)) {
        setEvent(result.data.data);
      } else {
        addToast(t("error_occurred_retrieving_event"), {
          appearance: "error",
          autoDismiss: true,
        });
        navigate(RoutesLinks.EVALUATION_PROCESSES_LINK);
      }
    } catch (error) {
      addToast(t("error_occurred_retrieving_event"), {
        appearance: "error",
        autoDismiss: true,
      });
      navigate(RoutesLinks.EVALUATION_PROCESSES_LINK);
    } finally {
      setLoading(false);
    }
  };

  const getScoreCards = async() => {
    setLoading(true);
    try {
      const result = await ScoreCardsService.getScoreCards({
        rowsPerPage: 99999999,
      });
      setScoreCards(result.data.data.score_cards);
    } catch (error) {
      addToast(t("error_occurred_retrieving_score_cards"), {
        appearance: "error",
        autoDismiss: true,
      });
      navigate(RoutesLinks.EVALUATION_PROCESSES_LINK);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!user.lp || (user.lp && checkIfLPhasAccessToSection("evaluations"))) {
      setLoading(true);
      getEvent();
      getScoreCards();
      getEvaluations();
    } else {
      navigate(RoutesLinks.EVALUATION_PROCESSES_LINK);
      addToast(t("forbidden_access"), {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }, []);

  const exportEvaluationsCSV = async() => confirm({
    title: t("export_evaluations"),
  }).then(async(isConfirmed) => {
    if (isConfirmed) {
      try {
        setLoading(true);
        const result = await EvaluationProcessesManagerService.exportEvaluationsCSV(event._id);
        if (result.data.data && result.data.data !== "") {
          window.open(result.data.data, "_blank");
        }
        setShowExportEvaluationsOptions(false);
      } catch (error) {
        addToast(t("error_occurred_exporting_evaluations"), {
          appearance: "error",
          autoDismiss: true,
        });
      } finally {
        setLoading(false);
      }
    }
  });

  const exportEvaluationsPDF = async() => confirm({
    title: t("export_evaluations"),
  }).then(async(isConfirmed) => {
    if (isConfirmed) {
      try {
        setLoading(true);
        await ReportsService.generateEvaluationsReport(event._id);
        setShowExportEvaluationsOptions(false);
      } catch (error) {
        addToast(t("error_occurred_exporting_evaluations"), {
          appearance: "error",
          autoDismiss: true,
        });
      } finally {
        setLoading(false);
      }
    }
  });

  const [isOpen, setOpen] = useState(false);

  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,
    onOutsideClick: () => setOpen(false),
    onDisappear: () => setOpen(false),
    overflowContainer: true,
    auto: true,
    placement: "bottom-center",
    triggerOffset: 12,
    containerOffset: 16,
    arrowOffset: 16,
  });

  return (
    <>
      {showExportEvaluationsOptions && (
        <ExportEvaluationsOptionsSections
          onClose={() => setShowExportEvaluationsOptions(false)}
          exportCSV={exportEvaluationsCSV}
          exportPDF={exportEvaluationsPDF}
        />
      )}
      {(showUsedEvaluationProcessError || showUsedEvaluationProcessErrorArchiving) && (
        <Modal
          showCloseModal={true}
          showModal={showUsedEvaluationProcessError || showUsedEvaluationProcessErrorArchiving}
          paddingBottom="2"
          paddingTop="6"
          onClose={() => {
            setShowUsedEvaluationProcessError(false);
            setShowUsedEvaluationProcessErrorArchiving(false);
            setEvaluationProcessInBoards([]);
          }}
        >
          <h2 className="mt-4 text-xl font-semibold">
            {t(
              showUsedEvaluationProcessError
                ? "error_occurred_deleting_evaluation_process"
                : "error_occurred_archiving_evaluation_process",
            )}
          </h2>
          <p className="text-sm font-medium text-gray-dark">
            {t("evaluation_process_is_being_used_in_some_automations")}
          </p>
          <p className="mt-6 mb-4 text-sm font-medium text-gray-dark">
            {t(
              showUsedEvaluationProcessError
                ? "delete_evaluation_process_automation_previously"
                : "delete_evaluation_process_automation_previously_archiving",
            )}
          </p>
          <div className="w-full px-10 mb-6 text-sm font-normal text-justify">
            <ul className="list-disc">
              {evaluationProcessInBoards?.map((q, index) => (
                  <li key={index} className="mb-1">
                    {q}
                  </li>
              ))}
            </ul>
          </div>
        </Modal>
      )}

      <div className="flex items-start">
        <div className="flex-1">
          <PageTitle
            title={event ? `${t("evaluation_process")}: ${event.name}` : t("event")}
            showBack={true}
            onBackClick={() => navigate(RoutesLinks.EVALUATION_PROCESSES_LINK)}
          />
        </div>
        <div className="flex items-center">
          {!user?.help?.add_startup_to_evaluation_process && section === "startups" && (
            <MiniHelp item={"add_startup_to_evaluation_process"} />
          )}
          {section === "startups" && evaluations && evaluations.length !== 0 && (
            <div className="flex">
              <ButtonSecondary
                onClick={() => setShowExportEvaluationsOptions(true)}
                text={t("export_evaluations")}
                iconComponent={<FaFileDownload className="inline-block mr-3" />}
                marginRight={2}
              />
            </div>
          )}
          {section === "startups" && !isArchived() && (
            <div className="flex">
              <ButtonSecondary
                onClick={() => eventStartupsRef?.current?.addStartup()}
                text={t("add_startup")}
                iconComponent={<FaPlusCircle className="inline-block mr-3" />}
                marginRight={2}
              />
              {/* showStartupsButton && <div className='flex'>
                <ButtonSecondary
                  onClick={() => setShowFilters(true)}
                  width="auto"
                  iconComponent={<FaFilter className="inline-block" />}
                />
          </div> */}
            </div>
          )}
          {section === "judges" && !isArchived() && (
            <div className="flex">
              {!user?.help?.add_judge_to_evaluation_process && (
                <MiniHelp item={"add_judge_to_evaluation_process"} />
              )}
              <ButtonSecondary
                onClick={() => eventJudgesRef?.current?.inviteJudge()}
                text={t("invite_judge")}
                iconComponent={<FaPlus className="inline-block mr-3" />}
              />
            </div>
          )}
          {section === "settings" && (
            <div className="flex">
              {!isArchived() && (
                <ButtonSecondary
                  onClick={() => archiveEvent()}
                  text={t("archive_event")}
                  iconComponent={<FaShare className="inline-block mr-3" />}
                  marginRight={2}
                />
              )}

              <ButtonDanger
                onClick={() => deleteEvent()}
                text={t("delete_event")}
                iconComponent={<FaTrash className="inline-block mr-3" />}
              />
            </div>
          )}
          {section === "startups" && showFilterStartups && (
            <>
              <div data-tip={t("sort")} className={"mr-2"}>
                <button
                  className={
                    "text-text-buttons-secondary bg-bg-buttons-secondary border border-border-buttons-secondary focus:outline-none custom-circle hover:shadow-inner shadow-soft-white w-12 h-12 rounded-2xl flex items-center justify-center text-center"
                  }
                  {...triggerProps}
                  onClick={() => setOpen(!isOpen)}
                >
                  <BiSort className="w-4 h-4" />
                </button>
              </div>
              <ButtonSecondary
                iconComponent={<FaFilter className="h-4" />}
                onClick={() => {
                  setShowFilters(true);
                }}
                badgeBgColor={"red"}
                badgeContent={filtersCount > 0 ? filtersCount : null}
              />
            </>
          )}
          {isOpen &&
            renderLayer(
              <ul
                {...layerProps}
                className={
                  "mt-2 px-2 py-2 z-30 | shadow-hover border bg-white rounded-2xl border-border-buttons-secondary"
                }
              >
                {[
                  { title: t("sort_avg_asc"), sort: "avg_asc" },
                  { title: t("sort_avg_desc"), sort: "avg_desc" },
                ].map((i, index) => (
                  <li
                    key={index}
                    onClick={() => setOrderStartupsBy(i.sort)}
                    className={
                      "px-2 py-2 flex text-main items-center cursor-pointer text-sm text-gray"
                    }
                  >
                    <span className={"mr-2 text-main"}>{i.icon}</span>
                    <span className="hover:font-bold">{t(i.title)}</span>
                  </li>
                ))}
                <Arrow {...arrowProps} borderColor="#61D8BD" borderWidth={1} className={"w-5"} />
              </ul>,
            )}
        </div>
      </div>

      <SectionHeader
        section={section}
        setSection={(s) => setSection(s)}
        sectionKeys={sections}
        sectionTitles={sections.map((s) => t(s))}
      />

      {section === "startups" && (
        <EventStartups
          orderBy={orderStartupsBy}
          setShowFilterStartups={setShowFilterStartups}
          evaluations={evaluations}
          event={event}
          ref={eventStartupsRef}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          setShowStartupsButton={setShowStartupsButton}
          setFiltersCount={setFiltersCount}
        />
      )}

      {section === "judges" && (
        <EventJudges
          getEvaluations={getEvaluations}
          event={event}
          ref={eventJudgesRef}
          scoreCards={scoreCards}
        />
      )}

      {section === "settings" && event && (
        <div className="my-6">
          {isArchived() ? (
            <Card wrapperClassName="mt-8">
              <NoDataInfo title={t("event_archived")} textOne={t("event_archived_description")} />
            </Card>
          ) : (
            <EventCreateOrUpdateForm
              event={event}
              onSubmit={updateEvent}
              isUpdate={true}
              evaluations={evaluations.length}
            />
          )}
        </div>
      )}
      {loading && <Loader />}
    </>
  );
};

export default Event;
