import { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Loader } from "components/ui/molecules/Loader";
import { PageTitle } from "components/ui/atoms/PageTitle";

import {
  FaTrash,
  FaPaperPlane,
  FaFilter,
} from "react-icons/fa";
import { RoutesLinks } from "components/routes-links";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import MembersService from "services/members-service";
import { useToasts } from "react-toast-notifications";
import { useSwal } from "hooks/useSwal";
import useUser from "hooks/useUser";
import { SectionHeader } from "components/ui/molecules/SectionHeader";
import { ButtonDanger } from "components/ui/atoms/ButtonDanger";
import { HiAnnotation } from "react-icons/hi";
import NotesContainer from "components/ui/objects/NotesContainer";
import NotesService from "services/notes-service";
import { InvestorInvestmentThesis } from "components/investment-thesis/InvestorInvestmentThesis";
import ReactTooltip from "react-tooltip";
import MemberStartups from "./MemberStartups";
import { getPathSegment } from "utils/get-path-segment";

const MemberDetails = () => {
  const navigate = useNavigate();
  const { memberId } = useParams();

  const { user } = useUser();
  const { t } = useTranslation();
  const { confirm } = useSwal();
  const [member, setMember] = useState({});

  const handleRedirect = (url) => navigate(url);
  const { addToast } = useToasts();
  const [showNotesContainer, setShowNotesContainer] = useState(false);
  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [filtersCount, setFiltersCount] = useState(null);

  const sharedStartupsRef = useRef();

  const [loading, setLoading] = useState(false);

  const SECTIONS = [
    { id: "general-information", route: "general-information", title: "general_information" },
    { id: "dealflow-preferences", route: "dealflow-preferences", title: "dealflow_preferences" },
    { id: "shared-startups", route: "shared-startups", title: "shared_startups" },
  ];

  const [section, setSection] = useState(getPathSegment(location.href, 3));

  const getMember = async() => {
    try {
      setLoading(true);
      const result = await MembersService.getMember(memberId);
      setMember(result.data.data);
    } catch (error) {
      console.error(error);
      addToast(t("error_occurred_retrieving_information"));
    } finally {
      setLoading(false);
    }
  };

  const deleteMember = () => {
    confirm({
      text: t("revert_notification"),
      icon: "warning",
    }).then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await MembersService.deleteMember(memberId);
          addToast(t("member_deleted_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
          handleRedirect(RoutesLinks.MEMBERS_LINK);
        } catch (error) {
          addToast(
            error.response && error.response.data.msg_key
              ? t(error.response.data.msg_key)
              : t("error_occurred_deleting_member"),
            {
              appearance: "error",
              autoDismiss: true,
            },
          );
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const getNotes = async() => {
    try {
      setLoading(true);
      const result = await NotesService.getNotes({ member: memberId });
      setNotes(result.data.data);
    } catch (error) {
      addToast(t("error_occurred_retrieving_notes"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const createNote = async(title, type, content) => {
    try {
      setLoading(true);
      await NotesService.createNote({
        user: user._id,
        title,
        content,
        type,
        member: memberId,
      });
      getNotes();
    } catch (error) {
      addToast(t("error_occurred_creating_note"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const updateNote = async(title, content) => {
    try {
      setLoading(true);
      await NotesService.updateNote(selectedNote._id, { title, content });
      setSelectedNote(null);
      getNotes();
    } catch (error) {
      addToast(t("error_occurred_updating_note"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteNote = async(noteId) => {
    confirm().then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await NotesService.deleteNote(noteId);
          getNotes();
        } catch (error) {
          addToast(t("error_occurred_deleting_note"), {
            appearance: "error",
            autoDismiss: true,
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const getNote = async(noteId) => {
    try {
      setLoading(true);
      const result = await NotesService.getNote(noteId);
      setSelectedNote(result.data.data);
    } catch (error) {
      addToast(t("error_occurred_retrieving_note"), {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (showNotesContainer) {
      getNotes();
    }
  }, [showNotesContainer]);

  const sendActivationCode = () => {
    confirm({
      icon: "info",
    }).then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          await MembersService.sendActivationCode(memberId);
          addToast(t("activation_code_sent_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
        } catch (error) {
          addToast(
            error.response && error.response.data.msg_key
              ? t(error.response.data.msg_key)
              : t("error_occurred_sending_activation_code"),
            {
              appearance: "error",
              autoDismiss: true,
            },
          );
        } finally {
          setLoading(false);
        }
      }
    });
  };

  useEffect(() => {
    ReactTooltip.rebuild();

    if (memberId) {
      getMember();
    }
  }, [memberId]);

  return (
    <>
      {loading && <Loader />}
      {showNotesContainer && (
        <NotesContainer
          zIndex="20"
          listTitle={t("notes")}
          setFiltersButtons={false}
          setShowModal={setShowNotesContainer}
          notes={notes}
          createNote={createNote}
          updateNote={updateNote}
          deleteNote={deleteNote}
          getNote={getNote}
          selectedNote={selectedNote}
          setSelectedNote={setSelectedNote}
          onClose={() => {
            setSelectedNote(null);
            setShowNotesContainer(false);
          }}
        />
      )}
      <div className="flex items-start">
        <div className="flex-1">
          <PageTitle title={member?.user?.name || t("loading")} showBack={true} />
        </div>
        {section === "general_information" && (
          <>
            {!member.activation_date && (
              <div className="mr-2">
                <ButtonMain
                  text={t("send_activation_code")}
                  onClick={() => sendActivationCode()}
                  iconComponent={<FaPaperPlane className="inline-block w-4 h-4 mr-2" />}
                />
              </div>
            )}
            <div className="mr-2" data-tip={t("annotations")}>
              <ButtonSecondary
                onClick={() => setShowNotesContainer(true)}
                iconComponent={<HiAnnotation className="inline-block w-4 h-4" />}
              />
            </div>
            <div data-tip={t("delete_member")}>
              <ButtonDanger
                iconComponent={<FaTrash className="inline-block" />}
                onClick={() => deleteMember()}
              />
            </div>
          </>
        )}
        {section === "shared_startups" && (
          <ButtonSecondary
            iconComponent={<FaFilter className="h-4" />}
            onClick={() => {
              section === "shared_startups" && sharedStartupsRef.current?.showFilters();
            }}
            badgeContent={filtersCount > 0 ? filtersCount : null}
            badgeBgColor={"red"}
          />
        )}
        {/* section === 'startups' && (
            <ButtonMain
              iconComponent={<FaShareAlt className='inline-block mr-2' />}
              marginRight='2'
              text={t('share_deal')}
              onClick={() => console.log("==")}
            />
          ) */}
      </div>
      <SectionHeader
        showFilters={false}
        showAdd={false}
        showSort={false}
        showUpload={false}
        sectionKeys={SECTIONS}
        section={section}
        setSection={(s) => setSection(s)}
        navigate
      />
      {member && <Outlet context={{ member }} />}
    </>
  );
};

export default MemberDetails;
