import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Voice from "styles/images/voice_icon.svg";
import Text from "styles/images/text_icon.svg";

import { RichEditor } from "components/ui/objects/RichEditor";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Input } from "components/ui/atoms/Input";
import { isEmpty } from "lodash";

import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import useUser from "hooks/useUser";

const TextNotes = ({ setShowVoiceModal, onSubmit, initialValues, editable, setSelectedNote }) => {
  const { t } = useTranslation();

  const { user } = useUser();

  const TextNoteSchema = Yup.object().shape({
    title: Yup.string().required(t("required_field")),
    content: Yup.string().required(t("required_field")),
  });

  const { register, handleSubmit, errors, setValue, getValues, trigger, watch } = useForm({
    mode: "all",
    criteriaMode: "all",
    reValidateMode: "all",
    resolver: yupResolver(TextNoteSchema),
  });

  const watchContent = watch("editorEnabled", false);

  const _setValue = (name, value) => {
    setValue(name, value, { shouldValidate: true });
    trigger(name);
  };

  useEffect(() => {
    register("content");
    register("editorEnabled");
  }, []);

  useEffect(() => {
    if (!isEmpty(initialValues)) {
      setValue("title", initialValues.title);
      setValue("content", initialValues.content || "");
    }
    setValue("editorEnabled", true);
  }, [initialValues]);

  return (
    <>
      <h3 className={"font-medium mr-4 text-main"}>
        {t(initialValues.title ? "update_text_note" : "add_text_note")}
      </h3>

      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-6">
          <Input
            disabled={!editable || user.lp}
            reference={register}
            id="title"
            type="text"
            name="title"
            placeholder={t("introduce_note_title")}
            label={t("note_title") + ":"}
            error={errors.title}
          />
        </div>
        <div>
          {/* <TextArea
              disabled={!editable}
              reference={register}
              id="content"
              placeholder={t('write_note')}
              label={t('note_content') + ':'}
              rows="8"
              cols="50"
              name="content"
              error={errors.note}
            /> */}
          {watchContent && (
            <div className="h-full">
              <RichEditor
                disabled={user.lp}
                id={"content"}
                reference={register}
                name="content"
                label={t("note_content") + ":"}
                placeholder={`${t("write_note")}`}
                onChange={(value) => _setValue("content", value === "<p><br></p>" ? "" : value)}
                toolbarClassName="content"
                value={getValues().content || ""}
                error={errors.content}
              />
            </div>
          )}
        </div>
        {initialValues.title && !user.lp && (
          <div
            className="text-xs mt-2 cursor-pointer hover:font-semibold"
            onClick={() => setSelectedNote(null)}
          >
            <img src={Text} alt="Icon" className={"inline | mr-2 | w-auto"} /> {t("add_text_note")}
          </div>
        )}
        {!initialValues.title && !user.lp && (
          <div
            className="text-xs mt-2 cursor-pointer hover:font-semibold"
            onClick={() => setShowVoiceModal(true)}
          >
            <img src={Voice} alt="Icon" className={"inline | mr-2 | w-auto"} />{" "}
            {t("add_voice_note")}
          </div>
        )}
        {editable && !user.lp && (
          <div className={"flex justify-end mt-4 border-t pt-2 border-separator"}>
            <ButtonMain type="submit" text={t("save")} />
          </div>
        )}
      </form>
    </>
  );
};

export default TextNotes;
