import axios from "axios/index";
import Config from "../config";

export const StatisticsService = {
  getDealflowStats: async() => axios.get(`${Config.STATISTICS_API_URL}clients/dealflow`),

  getRejectedStats: async() => axios.get(`${Config.STATISTICS_API_URL}clients/dealflow/rejected`),

  getFailedStats: async() => axios.get(`${Config.STATISTICS_API_URL}clients/dealflow/failed`),

  getInvestmentStats: async() => axios.get(`${Config.STATISTICS_API_URL}clients/investments/`),

  getInvestmentDistributionStats: async() =>
    axios.get(`${Config.STATISTICS_API_URL}clients/investments/distribution`),

  getDocumentsStats: async() => axios.get(`${Config.STATISTICS_API_URL}clients/documents`),

  getQuestionnaireStats: (formId) =>
    axios.get(`${Config.STATISTICS_API_URL}clients/forms/${formId}`),
  getPortfolioStats: async() =>
    axios.get(`${Config.STATISTICS_API_URL}clients/dealflow/portfolio`),
};
