import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "components/ui/atoms/Input";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { TextArea } from "components/ui/atoms/TextArea";
import { SidePanel } from "components/ui/objects/SidePanel";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import Config from "config";

const CriteriaSection = ({ onClose, onSubmit, parent, item, availablePercentage }) => {
  const { t } = useTranslation();

  const ItemSchema = Yup.object().shape({
    weigth: Yup.number().required(t("required_field")).min(0),
    name: Yup.string().required(t("required_field")),
  });

  const { register, errors, setValue, trigger, getValues, reset } = useForm({
    resolver: yupResolver(ItemSchema),
    criteriaMode: "all",
    reValidateMode: "all",
    mode: "onChange",
  });

  useEffect(() => {
    if (item && Object.keys(item).length) {
      setValue("weigth", item.weigth, { shouldDirty: true });
      setValue("name", item.name);
      if (item.description) {
        setValue("description", item.description);
      }
    } else {
      setValue("weigth", availablePercentage);
    }
  }, [item, setValue]);

  const [weigthError, setWeigthError] = useState({});

  return (
    <SidePanel
      width={"1/3"}
      onClose={onClose}
      title={
        item
          ? parent
            ? t("edit_subcriteria")
            : t("edit_criteria")
          : parent
            ? t("add_subcriteria")
            : t("add_criteria")
      }
    >
      <div className="text-xs w-full p-4 border border-gray-lines rounded-2xl bg-gray-lines font-semibold">
        {parent ? t("subcriteria_details") : t("criteria_details")}
      </div>
      <form className="w-full">
        <div className="mt-6">
          <Input
            reference={register}
            id="name"
            type="text"
            name="name"
            label={t("name") + "*:"}
            placeholder={t("introduce_name_they")}
            error={errors.name}
            disabled={item && item.registered}
          />
          <TextArea
            reference={register}
            id="description"
            type="text"
            name="description"
            label={`${t("description")}:`}
            placeholder={t(parent ? "subcriteria_description" : "criteria_description")}
          />

          <Input
            onChange={() => setWeigthError({})}
            reference={register}
            id="weigth"
            type="number"
            name="weigth"
            label={t("weigth") + "*:"}
            placeholder={t(
              parent ? "subcriteria_weigth_description" : "criteria_weigth_description",
            )}
            error={errors.weigth || weigthError}
            max={item ? 100 : availablePercentage}
            min={0}
          />
        </div>

        <div className={"flex justify-end mt-2 pt-2 border-t border-separator"}>
          <ButtonMain
            type="button"
            onClick={async () => {
              if (await trigger()) {
                const values = getValues();
                values.weigth = parseInt(values.weigth);

                let error = true;
                if (
                  item &&
                  ((availablePercentage === 0 && values.weigth <= item.weigth) ||
                    (availablePercentage !== 0 &&
                      item.weigth - values.weigth >= 0 &&
                      item.weigth - values.weigth <= availablePercentage))
                ) {
                  error = false;
                } else if (!item && values.weigth <= availablePercentage) {
                  error = false;
                }

                if (!error) {
                  onSubmit(item ? { ...values, _id: item._id, parent: item.parent } : values);
                } else {
                  setWeigthError({
                    message: `${t("weigth_must_be_lower_or_equal_than")}: ${
                      item
                        ? availablePercentage === 0
                          ? item.weigth
                          : availablePercentage
                        : availablePercentage
                    }`,
                  });
                }
              }
            }}
            text={t(
              item
                ? parent
                  ? "edit_subcriteria"
                  : "edit_criteria"
                : parent
                  ? "add_subcriteria"
                  : "add_criteria",
            )}
          />
        </div>
      </form>
    </SidePanel>
  );
};

export default CriteriaSection;
