import { useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { useNavigate, useLocation } from "react-router-dom";
import { RoutesLinks } from "components/routes-links";
import { Input } from "components/ui/atoms/Input";
import ScaleOverTen from "styles/images/scale_over_ten.svg";
import ScaleOverOneHundred from "styles/images/scale_over_one_hundred.svg";
import ScaleTrafficLight from "styles/images/traffic_light.svg";
import { ScoreCardInfo } from "components/events/components/event/components/score-card-info";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import { Card } from "components/ui/atoms/Card";

import CriteriaSection from "./CriteriaSection";
import { FaChevronCircleDown, FaTrash, FaPlusCircle, FaPen, FaInfoCircle } from "react-icons/fa";
import ScoreCardUtils from "utils/score-card-utils";
import { useSwal } from "hooks/useSwal";
import FormsService from "services/forms-service";
import { Loader } from "components/ui/molecules/Loader";
import Select from "components/ui/atoms/Select";
import { PageTitle } from "components/ui/atoms/PageTitle";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { ButtonCardSecondary } from "components/ui/atoms/ButtonCardSecondary";
import useUser from "hooks/useUser";
import { MiniHelp } from "components/ui/atoms/MiniHelp";
import ScoreCardsService from "services/score-cards-service";
import { SliderScore } from "components/events/components/evaluation/socore-inputs/SliderScore";

const ObjectID = require("bson-objectid");

const ScorecardsWizard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const { confirm, alert } = useSwal();
  const [name, setName] = useState("");
  const [criteria, setCriteria] = useState([]);
  const [scale, setScale] = useState(null);
  const handleRedirect = (url) => navigate(url);
  const [loading, setLoading] = useState(true);
  const [questionnaires, setQuestionnaires] = useState([]);
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(null);
  const { user, reloadUserInfo } = useUser();

  const [testScaleValue, setTestScaleValue] = useState(0);

  const location = useLocation();

  function useQuery() {
    const { search } = location;

    return useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  useEffect(() => {
    setTestScaleValue(0);
  }, [scale]);

  const [cloneScoreCard, setCloneScoreCard] = useState(null);

  const getScoreCard = useCallback(
    async(cloneId) => {
      try {
        setLoading(true);

        const result = await ScoreCardsService.getScoreCard(cloneId);
        setCloneScoreCard(result.data.data);
        setCriteria(result.data.data.criteria);
        setScale(result.data.data.scale);
        setSelectedQuestionnaire(
          result.data.data.questionnaire ? result.data.data.questionnaire._id : null,
        );
      } catch (error) {
        addToast(t("error_occurred_retrieving_score_card"), {
          appearance: "error",
          autoDismiss: true,
        });
        handleRedirect(RoutesLinks.SCORE_CARDS_LINK);
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [setLoading],
  );

  const createScoreCard = () => {
    confirm({
      text: t("create_score_card_confirmation"),
      icon: "success",
    }).then(async(isConfirmed) => {
      if (isConfirmed) {
        const scoreCardData = {
          name,
          criteria,
          scale,
          questionnaire: selectedQuestionnaire,
        };

        try {
          await ScoreCardsService.createScoreCard(scoreCardData);
          reloadUserInfo();
          handleRedirect(RoutesLinks.SCORE_CARDS_LINK);
        } catch (error) {
          addToast(t("error_creating_score_card"), {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }
    });
  };
  const UNITS = [
    // { type: 'scale_to_five', icon: ScaleOverTen },
    { type: "scale_to_ten", icon: ScaleOverTen, subtype: "scale_to_ten_decimal" },
    {
      type: "scale_to_one_hundred",
      icon: ScaleOverOneHundred,
      subtype: "scale_to_one_hundred_decimal",
    },
    { type: "traffic_light", icon: ScaleTrafficLight },
  ];

  const [createScoreCardErrorMsg, setCreateScoreCardErrorMsg] = useState(null);
  const [showCriteriaSection, setShowCriteriaSection] = useState(false);
  const [parent, setParent] = useState(null);
  const [item, setItem] = useState(null);

  const removeCriteria = (itemId) => {
    confirm().then(async(isConfirmed) => {
      if (isConfirmed) {
        const childrens = criteria.filter((c) => c.parent === itemId);

        const childrensIds = childrens.map((child) => child._id);

        const localCriteria = [...criteria.filter((c) => !childrensIds.includes(c._id))];
        localCriteria.splice(
          localCriteria.findIndex((c) => c._id === itemId),
          1,
        );
        setCriteria(localCriteria);
      }
    });
  };

  const handleCriteria = (data) => {
    if (!item) {
      const _id = new ObjectID();
      setCriteria([
        ...criteria,
        { ...data, _id: _id.toString(), parent: parent ? parent._id : null },
      ]);
    } else {
      const localCriteria = [...criteria];
      localCriteria.splice(
        localCriteria.findIndex((c) => c._id === data._id),
        1,
        data,
      );
      setCriteria(localCriteria);
    }
    setShowCriteriaSection(false);
    setParent(null);
    setItem(null);
  };

  const validWeigths = () => {
    if (getAvailablePercentageForMainCriteria() === 0) {
      return true;
    }

    let validWeigths = true;
    ScoreCardUtils.getCriteriaParents(criteria).every((c) => {
      if (getAvilablePercentageForChildrens(c) !== 0) {
        validWeigths = false;
        return false;
      }
      return true;
    });

    return validWeigths;
  };

  const getAvailablePercentageForMainCriteria = () => {
    const mainCriteria = criteria.filter((c) => !c.parent);
    let allocatedWeigth = 0;
    mainCriteria.forEach((c) => (allocatedWeigth += c.weigth));
    return 100 - allocatedWeigth;
  };

  const getAvilablePercentageForChildrens = (parentCriteria) => {
    const childrens = criteria.filter((c) => c.parent === parentCriteria._id);
    let allocatedWeigth = 0;
    childrens.forEach((c) => (allocatedWeigth += c.weigth));
    return 100 - allocatedWeigth;
  };

  const expandOrCollapse = (elementId) => {
    const element = document.getElementById(elementId);
    element.classList.toggle("hidden");
  };

  useEffect(() => {
    if (createScoreCardErrorMsg) {
      alert({ text: createScoreCardErrorMsg });
    }
  }, [createScoreCardErrorMsg]);

  const getQuestionnaires = () => FormsService.getQuestionnairesForms({
    rowsPerPage: 99999999,
    published: "true",
  })
    .then((result) => {
      setQuestionnaires(result.data.data.forms);
    })
    .catch(() => {
      addToast(t("error_occurred"), {
        appearance: "error",
        autoDismiss: true,
      });
      handleRedirect(RoutesLinks.SCORE_CARDS_LINK);
    })
    .finally(() => {
      setLoading(false);
    });

  useEffect(() => {
    getQuestionnaires();
    const cloneId = query.get("cloneId");
    if (cloneId) {
      getScoreCard(cloneId);
    }
  }, []);

  return (
    <>
      {loading && <Loader />}
      {showCriteriaSection && (
        <CriteriaSection
          onClose={() => {
            setShowCriteriaSection(false);
            setParent(null);
            setItem(null);
          }}
          onSubmit={(data) => handleCriteria(data)}
          item={item}
          parent={parent}
          availablePercentage={
            parent
              ? getAvilablePercentageForChildrens(parent)
              : getAvailablePercentageForMainCriteria()
          }
        />
      )}
      <div className="flex items-start">
        <div className="flex-1">
          <PageTitle
            title={t("new_score_card")}
            showBack={true}
            onBackClick={() => {
              handleRedirect(
                location.state ? location.state.from : RoutesLinks.SCORE_CARDS_LINK,
              );
            }}
          />
        </div>
        {!user?.help?.create_scorecard && <MiniHelp item={"create_scorecard"} />}
      </div>

      <div className="divide-y divide-gray-lines flex justify-center w-full">
        <div className="my-8 w-full mx-4">
          <Input
            id="name"
            type="text"
            name="name"
            value={name}
            label={`${t("score_card_name")} *`}
            placeholder={t("name")}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />

          <div className={"border-b border-main flex justify-between"}>
            <div>
              <h3 className={"text-main mt-6"}>{t("what_do_you_want_to_measure")}</h3>
            </div>
            <div className="flex items-end mb-1">
              {getAvailablePercentageForMainCriteria() !== 0 && (
                <ButtonSecondary
                  text={t("add_criteria")}
                  onClick={() => {
                    setShowCriteriaSection(true);
                  }}
                />
              )}
            </div>
          </div>

          {criteria.length !== 0 && (
            <>
              {ScoreCardUtils.getCriteriaParents(criteria).map((parent) => (
                <div key={parent._id}>
                  <div className="border-b border-gray-lines font-bold flex flex-row justify-between hover:bg-gray-100 px-2 hover:rounded-md transition-colors  mt-6">
                    <div>
                      <span>{`${parent.name}`}</span>
                    </div>
                    <div>
                      <>
                        {getAvilablePercentageForChildrens(parent) !== 0 && (
                          <FaPlusCircle
                            onClick={() => {
                              setItem(null);
                              setParent(parent);
                              setShowCriteriaSection(true);
                            }}
                            data-panel={parent._id}
                            className="text-main inline-block mt-1 mr-2 cursor-pointer"
                          />
                        )}
                      </>
                      <FaPen
                        onClick={() => {
                          setItem(parent);
                          setParent(null);
                          setShowCriteriaSection(true);
                        }}
                        data-panel={parent._id}
                        className="text-main inline-block mt-1 mr-2 cursor-pointer"
                      />
                      <FaTrash
                        data-panel={parent._id}
                        className="text-main inline-block mt-1 mr-2 cursor-pointer"
                        onClick={() => removeCriteria(parent._id)}
                      />
                      <FaChevronCircleDown
                        onClick={() => expandOrCollapse(parent._id)}
                        data-panel={parent._id}
                        className="text-main inline-block mt-1 cursor-pointer"
                      />
                    </div>
                  </div>

                  {parent.description && (
                    <div className="mx-6">
                      <small className="font-bold">{`${t("description")}: `}</small>
                      <small>{`${parent.description}`}</small>
                    </div>
                  )}
                  <div className="mx-6">
                    <small className="font-bold">{`${t("weigth")}: `}</small>
                    <small>{`${parent.weigth}%`}</small>
                  </div>
                  <div id={parent._id} className={"mt-2"}>
                    {ScoreCardUtils.getChildren(parent, criteria).map((child) => (
                      <div key={child._id}>
                        <div className="ml-4 font-bold mt-1 text-sm flex flex-row justify-between hover:bg-gray-100 px-2 rounded-md transition-colors">
                          <div>
                            <span>{`${child.name}`}</span>
                          </div>
                          <div>
                            <FaPen
                              onClick={() => {
                                setItem(child);
                                setParent(parent);
                                setShowCriteriaSection(true);
                              }}
                              data-panel={child._id}
                              className="text-main inline-block mt-1 mr-2 cursor-pointer"
                            />
                            <FaTrash
                              data-panel={child._id}
                              className="text-main inline-block mt-1 cursor-pointer"
                              onClick={() => removeCriteria(child._id)}
                            />
                          </div>
                        </div>
                        {child.description && (
                          <div className="px-12">
                            <small className="font-bold">{`${t("description")}: `}</small>
                            <small>{`${child.description}`}</small>
                          </div>
                        )}
                        <div className="px-12">
                          <small className="font-bold">{`${t("weigth")}: `}</small>
                          <small>{`${child.weigth}%`}</small>
                        </div>
                      </div>
                    ))}
                    {!ScoreCardUtils.hasChildren(parent, criteria) && (
                      <div key={`${parent._id}_no_subcriteria`}>
                        <div className="ml-4 font-bold mt-1 text-sm flex flex-row justify-between hover:bg-gray-100 px-2 rounded-md transition-colors ">
                          <span>{t("no_subcriteria")}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
          {criteria.length === 0 && (
            <div className="mt-6">
              <NoDataInfo title={t("criteria_not_found")} />
            </div>
          )}

          {criteria.length !== 0 && (
            <div className="mt-14">
              <ScoreCardInfo legend={false} criteria={criteria} />
            </div>
          )}
          <div className={"border-b border-main flex justify-between"}>
            <div>
              <h3 className={"text-main mt-6"}>{t("how_will_you_measure_the_criteria")}</h3>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
            {UNITS.map((item, index) => (
                <div
                  key={index}
                  className={`text-xs ${(scale === item.type || scale === item.subtype) && "rounded-2xl border-2 border-border-buttons-secondary"}`}
                >
                  <Card wrapperClassName="h-full">
                    <div className="flex flex-col">
                      <div className="flex">
                        <img
                          src={item.icon}
                          alt="Arrow icon"
                          className={`inline ${
                            item.type === "likert_scale" ? "w-6 ml-2 mr-4" : "w-10 mr-2"
                          }`}
                        />
                        <div className="flex items-center">
                          <div className="flex">
                            <span
                              className={`${(scale === item.type || scale === item.subtype) && "font-bold"}`}
                            >
                              {t(item.type)}
                            </span>
                          </div>
                        </div>
                      </div>
                      {
                        <div
                          className={"flex justify-end mt-2 gap-2 pt-2 border-t border-separator"}
                        >
                          {item.subtype && (
                            <ButtonCardSecondary
                              onClick={
                                scale !== item.subtype
                                  ? () => {
                                      setScale(item.subtype);
                                    }
                                  : () => setScale(null)
                              }
                              text={t(
                                scale !== item.subtype
                                  ? "select_scale_with_decimals"
                                  : "unselect_scale",
                              )}
                            />
                          )}
                          {item.type && (
                            <ButtonCardSecondary
                              onClick={
                                scale !== item.type
                                  ? () => {
                                      setScale(item.type);
                                    }
                                  : () => setScale(null)
                              }
                              text={t(scale !== item.type ? "select_scale" : "unselect_scale")}
                            />
                          )}
                          {/* {scale === item.type && <div className='font-bold'>{t('scale_selected')}</div>} */}
                        </div>
                      }
                    </div>
                  </Card>
                </div>
            ))}
          </div>
          {[
            "scale_to_ten",
            "scale_to_ten_decimal",
            "scale_to_one_hundred",
            "scale_to_one_hundred_decimal",
          ].includes(scale) && (
          <div className="mt-6">
            <p>{t("test_scale")}</p>
            <SliderScore
              max={
                scale === "scale_to_ten" || scale === "scale_to_ten_decimal"
                  ? 10
                  : 100
              }
              value={testScaleValue}
              showInput={true}
              onChange={(e) => {
                setTestScaleValue(e);
              }}
              step={
                scale === "scale_to_one_hundred_decimal" ||
                scale === "scale_to_ten_decimal"
                  ? 0.1
                  : 1
              } />
            </div>
          )}

          <div className={"border-b border-main flex justify-between mb-6"}>
            <div>
              <h3 className={"text-main mt-6"}>
                {t("would_you_like_to_include_questionnaire_for_judges")}
              </h3>
            </div>
          </div>
          <div className="mb-6">
            <Card wrapperClassName={"border-2 border-dotted border-border-buttons-secondary"}>
              <div className="font-semibold">
                <FaInfoCircle className="inline mr-2 w-5 h-5" />
                {t("what_is_this")}
              </div>
              <div className="mt-2">
                <Trans
                  i18nKey="questionnaire_for_scorecard_description"
                  components={{ br: <br />, bold: <strong /> }}
                />
              </div>
            </Card>
          </div>
          <Select
            name="questionnaire"
            label={t("questionnaire")}
            initialValues={questionnaires
              .filter((q) => q._id === selectedQuestionnaire)
              .map((i) => ({ id: i._id, value: i.name }))}
            onSelect={(selection) => {
              setSelectedQuestionnaire(selection[0]?.id);
            }}
            items={questionnaires.map((questionnaire) => ({
              value: questionnaire.name,
              id: questionnaire._id,
            }))}
          />

          <div className={"w-full justify-end flex text-center mt-8"}>
            <ButtonMain
              width={"full md:w-1/2"}
              text={t("create_score_card")}
              onClick={() => {
                if (!name) {
                  setCreateScoreCardErrorMsg(t("name_is_required"));
                } else if (!criteria.length) {
                  setCreateScoreCardErrorMsg(t("criteria_required"));
                } else if (!validWeigths()) {
                  setCreateScoreCardErrorMsg(t("weigths_incorrect"));
                } else if (!scale) {
                  setCreateScoreCardErrorMsg(t("scale_is_required"));
                } else {
                  createScoreCard();
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ScorecardsWizard;
