import { EmailLanguage } from "./email-language/EmailLanguage";
import { Currency } from "./currency/Currency";

export const CompanySettings = () => (
    <>
      <div className="mt-6">
        <EmailLanguage />
      </div>
      <div className="mt-6">
        <Currency />
      </div>
    </>
);
