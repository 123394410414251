import { Card } from "components/ui/atoms/Card";
import { Input } from "components/ui/atoms/Input";
import Select from "components/ui/atoms/Select";
import { Switch } from "components/ui/atoms/Switch";

import { FaCopy, FaDownload, FaInfoCircle, FaRocket, FaUser, FaUsers } from "react-icons/fa";
import { Loader } from "components/ui/molecules/Loader";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useSwal } from "hooks/useSwal";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import FormUtils from "utils/form-utils";
import SupportService from "services/support-service";
import { RoutesLinks } from "components/routes-links";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Utils from "utils/utils";
import QRCode from "qrcode";
import Config from "config";
import { Alert } from "components/ui/molecules/Alert";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import ReactTooltip from "react-tooltip";
import FormsService from "services/forms-service";
import ScoreCardsService from "services/score-cards-service";

export const EventCreateOrUpdateForm = ({ evaluations, event, onSubmit, isUpdate = false }) => {
  const { t } = useTranslation();
const navigate = useNavigate();
  const location = useLocation();
  const { addToast } = useToasts();
  const { confirm } = useSwal();
  const [loading, setLoading] = useState(true);
  const [scoreCards, setScoreCards] = useState([]);
  const [questionnaires, setQuestionnaires] = useState([]);
  const [sourceInformation, setSourceInformation] = useState([]);
  const [scoreCardInitialValues, setScoreCardInitialValues] = useState([]);
  const [sourceInformationInitialValues, setSourceInformationInitialValues] = useState([]);
  const [reset, setReset] = useState(false);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const { register, errors, trigger, getValues, setValue, handleSubmit, watch } = useForm({
    mode: "all",
    criteriaMode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string().required(t("required_field")),
        score_card: Yup.string().nullable(),
        source_information: Yup.array().required(t("required_field")),
        show_general_information: Yup.boolean(),
      }),
    ),
  });

  const handleRedirect = (url) =>
    navigate({
      pathname: url,
      state: {
        from: location.pathname,
      },
    });

  const getScoreCards = () => {
    return ScoreCardsService.getScoreCards({
      rowsPerPage: 99999999,
    })
      .then((result) => {
        setScoreCards(result.data.data.score_cards);
      })
      .catch(() => {
        addToast(t("error_occurred_retrieving_score_cards"), {
          appearance: "error",
          autoDismiss: true,
        });
        handleRedirect(RoutesLinks.EVALUATION_PROCESSES_LINK);
      });
  };

  const onSubmitForm = () => {
    let values = null;
    let index = null;
    const data = getValues();
    if (
      (!data.source_information || !data.source_information.length) &&
      !data.show_general_information
    ) {
      addToast(t("source_information_required"), {
        appearance: "error",
        autoDismiss: true,
      });
    } else {
      if (isUpdate) {
        // onSubmit && onSubmit({ ...getValues(), managed_entity: managedEntity })
        confirm({}).then((isConfirmed) => {
          if (isConfirmed) {
            values = getValues();
            index = values.source_information.findIndex((s) => !s);
            if (index > -1) {
              values.source_information.splice(index, 1);
            }
            // onSubmit && onSubmit({ ...getValues(), managed_entity: managedEntity })
            onSubmit && onSubmit(values);
          }
        });
      } else {
        confirm({
          text: t("create_event_description"),
        }).then((isConfirmed) => {
          if (isConfirmed) {
            values = getValues();
            index = values.source_information.findIndex((s) => !s);
            if (index > -1) {
              values.source_information.splice(index, 1);
            }
            // onSubmit && onSubmit({ ...getValues(), managed_entity: managedEntity })
            onSubmit && onSubmit(getValues());
          }
        });
      }
    }
  };

  const onError = (e) => {
    addToast(t("form_field_error"), { appearance: "error", autoDismiss: true });
  };

  const onSelectScoreCard = (scoreCardId) => {
    const scoreCard = scoreCards.find((scoreCard) => scoreCard._id === scoreCardId);

    if (scoreCard) {
      setScoreCardInitialValues([{ id: scoreCard._id, value: scoreCard.name }]);
      setValue("score_card", scoreCardId);
    } else {
      setScoreCardInitialValues([]);
      setValue("score_card", null);
    }

    trigger(["score_card"]);
  };

  const onSelectSourceInformation = (sourceInformationArray, initial = false) => {
    if (sourceInformationArray.length || initial) {
      if (initial) {
        setValue("show_general_information", !!event.show_general_information);
        if (event.show_general_information) {
          setValue("source_information", [
            sourceInformation.find((s) => !s.id).id,
            ...sourceInformationArray.filter((s) => s).map((s) => s._id),
          ]);
        } else {
          setValue(
            "source_information",
            sourceInformationArray.filter((s) => s).map((s) => s._id),
          );
        }
      } else {
        setValue("show_general_information", !!sourceInformationArray.find((s) => !s.id));
        setValue(
          "source_information",
          sourceInformationArray.filter((s) => s.id).map((s) => s.id),
        );
      }
    } else {
      setValue("source_information", []);
      setValue("show_general_information", false);
    }
    // setReset(true)
    trigger(["source_information", "show_general_information"]);
  };

  const hydrateForm = () => {
    setValue("name", event.name);
    if (event.score_card && scoreCards.length) {
      onSelectScoreCard(event.score_card._id);
    }
    setValue("show_general_information", !!event.show_general_information);
    if (event.source_information && sourceInformation.length) {
      onSelectSourceInformation(event.source_information, true);
    }
    trigger();
  };

  useEffect(() => {
    register("score_card");
    register("source_information");
    register("show_general_information");
  }, [register]);

  useEffect(() => {
    if (event) {
      hydrateForm();
    }
  }, [event, scoreCards, sourceInformation]);

  const getQuestionnaires = () => {
    return FormsService.getQuestionnairesForms({
      rowsPerPage: 99999999,
    })
      .then((result) => {
        setQuestionnaires(result.data.data.forms);
        setSourceInformation([
          { id: null, value: t("startup_profile") },
          ...result.data.data.forms.map((questionnaire) => {
            return { id: questionnaire._id, value: questionnaire.name };
          }),
        ]);
      })
      .catch((error) => {
        console.error(error);
        addToast(t("error_occurred"), {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([getScoreCards(), getQuestionnaires()]).finally(() => {
      setLoading(false);
    });
  }, []);

  const getScoreCardName = () => {
    if (!event.score_card) return "-";

    const scoreCard = scoreCards.find((sc) => sc._id === event.score_card._id);
    return scoreCard?.name || "-";
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitForm, onError)}>
        <h5 className={`text-main text-2xl font-bold border-b border-main mb-4`}>
          {t("general_information")}
        </h5>

        <Input
          id={"name"}
          reference={register}
          name="name"
          label={t("name")}
          error={errors.name}
          placeholder={t("name")}
          required={true}
        />

        <h5 className={`text-main text-2xl font-bold border-b border-main mt-6 mb-4`}>
          {t("evaluations")}
        </h5>
        <h3 className={`text-main mt-6`}>{t("evaluate_requests_description")}</h3>
        <div className={"text-xs mb-4"}>{t("select_score_card_description")}</div>
        {!evaluations ? (
          <Select
            name="score_card"
            label={t("score_card")}
            error={errors.score_card}
            initialValues={scoreCardInitialValues}
            onSelect={(selection) => onSelectScoreCard(selection[0]?.id)}
            items={scoreCards.map((scoreCard) => ({
              value: scoreCard.name,
              id: scoreCard._id,
            }))}
          />
        ) : (
          <h5 className={`uppercase font-bold text-sm leading-none text-main truncate mb-1`}>
            {getScoreCardName()}{" "}
            <FaInfoCircle
              className="inline ml-1 w-4"
              data-tip={t("score_card_not_editable_for_this_evaluation_process")}
            />
          </h5>
        )}
        <h5 className={`text-main text-2xl font-bold border-b border-main mt-10 mb-4`}>
          {t("information_source")}
        </h5>
        <div>
          <h3 className={`text-main mb-4`}>{t("information_source_description")}</h3>
        </div>
        <Select
          name="source_information"
          label={t("select_information")}
          multiSelect={true}
          required={true}
          // reset={reset}
          // setReset={setReset}
          placeholder={t("choose_multiple_options")}
          initialValues={
            watch("source_information") && sourceInformation.length
              ? watch("show_general_information")
                ? sourceInformation.filter(
                    (item) => !item.id || watch("source_information").includes(item.id),
                  )
                : sourceInformation.filter((item) => watch("source_information").includes(item.id))
              : []
          }
          onSelect={(value) => onSelectSourceInformation(value)}
          items={sourceInformation}
          isClearable={false}
          error={errors.source_information}
        />
        <div className="flex justify-end w-full my-6">
          <ButtonMain
            width="full md:w-1/2 lg:w-1/4"
            type="submit"
            text={isUpdate ? t("update") : t("create_event")}
          />
        </div>
      </form>
      {loading && <Loader />}
    </>
  );
};
