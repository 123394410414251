/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useToasts } from "react-toast-notifications";
import { DealflowService } from "services/dealflow-service";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { SidePanel } from "components/ui/objects/SidePanel";
import { Autocomplete } from "components/ui/atoms/Autocomplete";

export const DealflowSearcherPortfolio = ({ onFilter, onClose, filters = {}, investments }) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [startupsFromPool, setStartupsFromPool] = useState([]);

  const { register, handleSubmit, trigger, setValue, getValues, reset } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        deal_id: Yup.string(),
      }),
      { abortEarly: false },
    ),
    criteriaMode: "all",
    reValidateMode: "all",
    mode: "onChange",
  });

  const _setValue = (name, value, config = {}) => {
    setValue(name, value, config);
    trigger(name);
  };

  const filter = ({ clearFilters, ...filterValues }) => {
    let _filters = {};

    if (!clearFilters) {
      _filters = { deal_id: filterValues.deal_id };
    }

    onFilter && onFilter(_filters);
    onClose && onClose();
  };

  const resetFilters = () => {
    reset();
    filter({ clearFilters: true });
  };

  const onInvalid = () => {
    addToast(t("form_field_error"), { appearance: "error", autoDismiss: true });
  };

  const getStartupsFromPool = () => {
    DealflowService.getDealflow({
      rowsPerPage: 999999,
      pool: "true",
      populate: "false",
      boards: "false",
      milestones: "false",
      portfolio: "true",
      files: "false",
    })
      .then((response) => {
        // TODO: Make it a backend endpoint with mongodb quering

        const allStartUps = response.data.data.dealflow;
        let filteredStartUps = allStartUps;
        if (investments) {
          filteredStartUps = investments
            .map((i) => {
              return allStartUps.find((s) => s._id == i.deal._id);
            })
            .filter(Boolean);
        }

        setStartupsFromPool(filteredStartUps);
      })
      .catch(() => {
        addToast(t("error_occurred"), {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  useEffect(() => {
    if (filters) {
      Object.entries(filters).forEach(([key, value]) => {
        _setValue(key, value, { shouldValidate: true });
      });
    }
    getStartupsFromPool();
  }, [filters, register]);

  return (
    <SidePanel title={t("search_startup")} onClose={() => onClose && onClose()} width={"1/3"}>
      <form className="flex flex-col w-full" onSubmit={handleSubmit(filter, onInvalid)}>
        <div className="mt-2">
          <Autocomplete
            displayLogo={true}
            name="startup"
            placeholder={t("search_startup")}
            isClearable={false}
            label={t("search_startup")}
            reference={register("deal_id")}
            initialValues={getValues("deal_id")}
            required={true}
            options={startupsFromPool}
            error={
              getValues("deal_id") !== undefined && getValues("deal_id") === null
                ? { message: t("required_field") }
                : null
            }
            onSelect={(selected) => {
              setValue("deal_id", selected._id);
              // trigger()
            }}
          />
        </div>

        <div className={"flex justify-end mt-2 pt-2 border-t border-separator"}>
          <ButtonSecondary
            text={t("clear")}
            onClick={() => resetFilters()}
            type="button"
            marginRight={2}
          />
          <ButtonMain text={t("search_startup")} type="submit" />
        </div>
      </form>
    </SidePanel>
  );
};
