import { FaExpandAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Card } from "components/ui/atoms/Card";
import Avatar from "react-avatar";
import { ButtonCardMain } from "components/ui/atoms/ButtonCardMain";

export const MemberStartup = ({ dealflow, setDealToResume }) => {
  const { t } = useTranslation();

  return (
    <>
      <Card
        wrapperClassName="transition-transform"
        containerClassName="w-full flex flex-col h-full"
      >
        <div className="flex pb-2 mb-2 h-full">
          <div className="flex flex-row w-full justify-around">
            <div className="flex flex-col h-full text-sm min-w-0 w-3/5">
              <h5 className={"uppercase font-bold text-sm leading-none text-main truncate mb-1"}>
                {dealflow.name}
              </h5>
              <div className="flex flex-row line-clamp-3">{dealflow.brand_statement}</div>
            </div>
            <div className="flex items-center justify-end mr-5 w-2/5 ">
              <div className="info-card-container-img">
                <Avatar
                  src={dealflow.logo}
                  name={dealflow.name}
                  alt={dealflow.name}
                  maxInitials={3}
                  className={`h-full object-contain ${dealflow.logo ? "w-full" : ""}`}
                  color="#e0e6f2"
                  fgColor="#4d70b3"
                  round="5"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={"flex justify-end mt-2 pt-2 border-t border-separator"}>
          <ButtonCardMain
            onClick={() => setDealToResume(dealflow)}
            text={t("see_resume")}
            marginRight={2}
            iconComponent={<FaExpandAlt className="inline-block mr-2 w-5 h-4" />}
          />
        </div>
      </Card>
    </>
  );
};
