import {
  FaBell,
  FaChevronCircleDown,
  FaChevronCircleUp,
  FaColumns,
  FaExclamationTriangle,
  FaExpandAlt,
  FaEye,
  FaPaperPlane,
  FaShareAlt,
  FaStar,
  FaTags,
  FaThumbsUp,
  FaUsers,
  FaProjectDiagram,
} from "react-icons/fa";
import { BiTargetLock } from "react-icons/bi";
import { BsSpeedometer2 } from "react-icons/bs";
import { GiProgression } from "react-icons/gi";
import { HiAnnotation } from "react-icons/hi";

import { useTranslation } from "react-i18next";
import { Card } from "components/ui/atoms/Card";
import Avatar from "react-avatar";
import { useNavigate } from "react-router-dom";
import { RoutesLinks } from "../routes-links";
import { TagList } from "../tags/TagList";
import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { ProgressBar } from "components/ui/atoms/ProgressBar";

import { ButtonCardMain } from "components/ui/atoms/ButtonCardMain";
import { ButtonCardSecondary } from "components/ui/atoms/ButtonCardSecondary";
import { DealflowBoardsBar } from "./DealflowBoardsBar";
import { DealflowPrescoring } from "./DealflowPrescoring";
import { RemindersBar } from "./RemindersBar";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { DealflowInvestmentThesisMatch } from "./DealflowInvestmentThesisMatch";
import useUser from "hooks/useUser";
import Utils from "utils/utils";
import { ReferralsBar } from "./ReferralsBar";
import { DealflowOwners } from "./DealflowOwners";
import ExpandableAvatar from "components/ui/atoms/images/ExpandableImage";

export const DealflowPoolListItem = ({
  dealflow,
  setDealToResume,
  setDealToShare,
  setShowAdditionalInfo,
  showAdditionalInfo,
  interestedCount,
  markAsInteresting,
}) => {
  const { interesting } = dealflow;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { user, getHardFilters, checkIfLPhasAccessToSection, hasAccessToModule } = useUser();
  const getMilestonePercent = () => {
    if (!dealflow?.milestones_resume) {
      return 0;
    }

    const { objetives = 10, completed = 5 } = dealflow?.milestones_resume;
    return completed ? Math.round((completed / objetives) * 100) : 0;
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [showAdditionalInfo]);

  const [criteriaChecks, setCriteriaChecks] = useState({});
  const [hardFilters, setHardFilters] = useState([]);

  const [hasSomeHardFilterUnmatched, setHasSomeHardFilterUnmatched] = useState(false);

  useEffect(() => {
    setHardFilters(getHardFilters());
    setCriteriaChecks(
      Utils.checkInvestmentCriteriaMatch(dealflow, user.investor.investment_thesis),
    );
  }, []);

  const checkHardFilters = () => {
    if (hardFilters.length) {
      let unmatchedHardFilter = false;

      hardFilters.every((hardFilter) => {
        if (criteriaChecks.hasOwnProperty(hardFilter) && !criteriaChecks[hardFilter]) {
          unmatchedHardFilter = true;
          return false;
        }
        return true;
      });

      setHasSomeHardFilterUnmatched(unmatchedHardFilter);
    } else {
      setHasSomeHardFilterUnmatched(false);
    }
  };

  useEffect(() => {
    checkHardFilters();
  }, [criteriaChecks, hardFilters]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [hasSomeHardFilterUnmatched]);

  return (
    <>
      <Card
        wrapperClassName="transition-transform"
        containerClassName="w-full flex flex-col h-full"
        investor={dealflow.investor._id !== user.investor._id && dealflow.investor}
      >
        <div className="flex pb-2 mb-2 ">
          <div className="flex flex-row justify-around w-full">
            <div className="flex flex-col w-3/5 h-full min-w-0 text-sm">
              <div className="flex">
                <h5 className={"uppercase font-bold text-sm leading-none text-main truncate mb-1"}>
                  {dealflow.name}
                </h5>
                <div className="flex gap-2 mx-2">
                  {hasSomeHardFilterUnmatched && (
                    <FaExclamationTriangle
                      className="inline mr-1 text-red"
                      data-tip={t("deal_does_not_match_some_hard_filter")}
                    />
                  )}
                  {dealflow?.forms?.filter((form) => !form.automated).length > 1 ? (
                    <FaExclamationTriangle
                      className={"text-main"}
                      data-tip={t("see_forms_tooltip")}
                    />
                  ) : (
                    <FaExclamationTriangle
                      data-tip={t("no_duplicated_applications")}
                      className={"text-gray"}
                    />
                  )}
                  {dealflow?.reminders?.length > 0 ? (
                    <FaBell
                      onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}
                      className={"text-main cursor-pointer"}
                      data-tip={t("see_reminders_tooltip")}
                    />
                  ) : (
                    <FaBell
                      onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}
                      data-tip={t("no_reminders_tooltip")}
                      className={"text-gray cursor-pointer"}
                    />
                  )}
                  {dealflow?.milestones_resume.objetives > 0 ? (
                    <GiProgression
                      onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}
                      className={"text-main cursor-pointer"}
                      data-tip={t("see_milestones_tooltip")}
                    />
                  ) : (
                    <GiProgression
                      onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}
                      data-tip={t("no_milestones_tooltip")}
                      className={"text-gray cursor-pointer"}
                    />
                  )}
                  {dealflow?.pre_scorings?.length > 0 && (
                    <div className="relative inline-flex items-center mb-1 text-sm font-medium text-center text-white rounded-lg">
                      <BsSpeedometer2
                        onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}
                        className={"text-main cursor-pointer"}
                        data-tip={t("see_prescorings_tooltip")}
                      />
                    </div>
                  )}
                  {!dealflow?.pre_scorings?.length && (
                    <span
                      data-tip={t("no_prescorings_tooltip")}
                      className="flex items-center text-xs font-bold text-main"
                    >
                      <BsSpeedometer2
                        onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}
                        className={"text-gray cursor-pointer h-4 w-4 mb-2"}
                      />
                      {dealflow?.pre_scorings?.[0]?.score}
                    </span>
                  )}
                  {!dealflow?.referrals?.length > 0 ? (
                    <FaStar
                      data-tip={t("no_referrals_tooltip")}
                      onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}
                      className={"text-gray cursor-pointer h-4 w-4 mb-2"}
                    />
                  ) : (
                    <FaStar
                      data-tip={t("show_referrals_tooltip")}
                      onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}
                      className={"text-main cursor-pointer h-4 w-4 mb-2"}
                    />
                  )}
                  {dealflow?.tags?.length > 0 ? (
                    <FaTags
                      onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}
                      className={"text-main cursor-pointer"}
                      data-tip={t("see_tags_tooltip")}
                    />
                  ) : (
                    <FaTags
                      onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}
                      data-tip={t("no_tags_tooltip")}
                      className={
                        dealflow?.milestones_resume.objetives > 0 &&
                        dealflow?.milestones_resume.completes > 0
                          ? "text-main cursor-pointer"
                          : "text-gray cursor-pointer"
                      }
                    />
                  )}
                  {dealflow?.annotations ? (
                    <HiAnnotation
                      className={"text-main h-4 w-4"}
                      data-tip={t("deal_with_annotations")}
                    />
                  ) : (
                    <HiAnnotation
                      data-tip={t("deal_without_annotations")}
                      className={"text-gray h-4 w-4"}
                    />
                  )}
                  {hasAccessToModule("members") && (
                    <div className="flex">
                      <FaThumbsUp className={"h-3 w-3"} />
                      <span className="ml-1 h-3 w-3 text-xs">{interestedCount ?? 0}</span>
                    </div>
                  )}
                </div>
              </div>
              {/* <div className='flex flex-row'>
                <div className='w-auto'>
                  <span
                    className={'inline-block mr-2 mt-1 font-semibold text-xxs px-2 py-0.5 rounded-md border border-gray-lines text-text-buttons-secondary'}
                  >
                    Origen: IESE
                  </span>
                </div>
              </div> */}
            </div>

            <div className="flex items-center justify-end w-2/5 mr-5 ">
              <ExpandableAvatar src={dealflow.logo} name={dealflow.name}>
                <div className="info-card-container-img">
                  <Avatar
                    src={dealflow.logo}
                    name={dealflow.name}
                    alt={dealflow.name}
                    maxInitials={3}
                    className={`h-full object-contain ${dealflow.logo ? "w-full" : ""}`}
                    color="#e0e6f2"
                    fgColor="#4d70b3"
                    round="5"
                  />
                </div>
              </ExpandableAvatar>
            </div>
          </div>
        </div>

        <div className="flex items-center py-2 text-xs border-t border-gray-lines">
          <div className="flex-1 overflow-hidden font-semibold line-clamp-1">
            {dealflow.brand_statement || t("information_not_provided")}
          </div>
        </div>

        <div className="flex items-center justify-between py-2 border-t border-gray-lines">
          <div className="flex flex-col overflow-hidden">
            <label className="text-xs">{t("sector")}</label>
            <span className="text-xs font-bold">
              {dealflow.sector ? t(dealflow.sector.key) : "-"}
            </span>
          </div>
          <div className="flex flex-col overflow-hidden">
            <label className="text-xs">{t("business_model")}</label>
            <span className="text-xs font-bold">
              {dealflow.business_model ? t(dealflow.business_model.key) : "-"}
            </span>
          </div>
          <div className="flex flex-col overflow-hidden">
            <label className="text-xs">{t("investment_stage")}</label>
            <span className="text-xs font-bold">
              {dealflow.investment_stage ? t(dealflow.investment_stage.key) : "-"}
            </span>
          </div>
          <div className="flex flex-col overflow-hidden">
            <label className="text-xs">{t("valuation")}</label>
            <span className="text-xs font-bold">
              {dealflow.valuation !== null && dealflow.valuation_unit
                ? `${dealflow.valuation} ${dealflow.valuation_unit.toUpperCase()}`
                : "-"}
            </span>
          </div>
          <div className="flex flex-col overflow-hidden">
            <label className="text-xs">{t("investment_desired")}</label>
            <span className="text-xs font-bold">
              {dealflow.investment_desired !== null && dealflow.investment_desired_unit
                ? `${dealflow.investment_desired} ${dealflow.investment_desired_unit.toUpperCase()}`
                : "-"}
            </span>
          </div>
        </div>

        {(!user.lp || (user.lp && checkIfLPhasAccessToSection("pipeline_manager"))) && (
          <div className="flex items-center border-t border-gray-lines">
            <div className="mr-2" data-tip={t("boards")}>
              <FaColumns className={"text-main"} />
            </div>
            <div className="flex-1 overflow-hidden">
              <DealflowBoardsBar dealflow={dealflow} />
            </div>
          </div>
        )}
        {!user.lp && (
          <div className="flex items-center py-2 border-t border-gray-lines">
            <div className="mr-2" data-tip={t("investment_thesis")}>
              <BiTargetLock className={"text-main h-5 w-5"} />
            </div>
            <div className="flex-1">
              <DealflowInvestmentThesisMatch criteriaChecks={criteriaChecks} />
            </div>
          </div>
        )}
        <div
          onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}
          className={`cursor-pointer flex items-center ${showAdditionalInfo ? "border-b py-2" : "pt-2"} border-t border-secondary text-xs font-semibold justify-between`}
        >
          {t("additional_information")}
          {!showAdditionalInfo && <FaChevronCircleDown />}
          {showAdditionalInfo && <FaChevronCircleUp />}
        </div>

        {showAdditionalInfo && (
          <>
            {!user.lp && (
              <div className="flex items-center py-2 border-t border-gray-lines">
                <div className="mr-2" data-tip={t("reminders")}>
                  <FaBell className={"text-main"} />
                </div>
                <div className="flex-1 overflow-hidden">
                  <RemindersBar dealflow={dealflow} />
                </div>
              </div>
            )}
            <div className="flex items-center py-2 border-t border-gray-lines">
              <div className="mr-2" data-tip={t("milestones")}>
                <GiProgression className={"text-main"} />
              </div>
              <div className="flex-1">
                {getMilestonePercent() > 0 ? (
                  <div className="mt-1">
                    <ProgressBar value={getMilestonePercent()} textSize={"text-xs"} />
                  </div>
                ) : (
                  <span className="inline-block mr-3 mt-1 font-semibold text-xxs px-2 py-0.5 text-gray-300">
                    {t("no_milestones")}
                  </span>
                )}
              </div>
            </div>
            <div className="flex items-center py-2 border-t border-gray-lines">
              <div className="mr-2" data-tip={t("pre_scorings")}>
                <BsSpeedometer2 className={"text-main"} />
              </div>
              <div className="flex-1">
                <DealflowPrescoring dealflow={dealflow} />
              </div>
            </div>
            <div className="flex items-center py-2 border-t border-gray-lines">
              <div className="mr-2" data-tip={t("referrals")}>
                <FaStar className={"text-main"} />
              </div>
              <div className="flex-1">
                <ReferralsBar referrals={dealflow.referrals || []} />
              </div>
            </div>
            <div className="flex items-center py-2 border-t border-gray-lines">
              <div className="mr-2" data-tip={t("tags")}>
                <FaTags className={"text-main"} />
              </div>
              <div className="flex-1">
                <TagList tags={dealflow.tags || []} showEmptyState={true} />
              </div>
            </div>
            <div className="flex items-center py-2 border-t border-gray-lines">
              <div className="mr-2" data-tip={t("owners")}>
                <FaUsers className={"text-main"} />
              </div>
              <div className="flex-1">
                <DealflowOwners owners={dealflow.owners || []} />
              </div>
            </div>
            {dealflow.investor._id !== user.investor._id && (
              <div className="flex items-center py-2 border-t border-gray-lines">
                <div className="mr-2" data-tip={t("children_company")}>
                  <FaProjectDiagram className={"text-main"} />
                </div>
                <div className="flex-1">
                  <span className="inline-block mr-2 mt-1 font-semibold text-xxs bg-bg-buttons-secondary px-2 py-0.5 rounded-md border border-border-buttons-secondary text-text-buttons-secondary hover:shadow-inner">
                    {dealflow.investor.company_name}
                  </span>
                </div>
              </div>
            )}
          </>
        )}

        <div className={"flex justify-end mt-2 pt-2 border-t border-separator"}>
          {!user.lp && (
            <ButtonCardSecondary
              onClick={() => setDealToShare(dealflow)}
              text={t("share")}
              iconComponent={<FaShareAlt className="inline-block w-5 h-4 mr-2" />}
              marginRight="4"
            />
          )}
          <ButtonCardSecondary
            onClick={() => setDealToResume(dealflow)}
            text={t("see_resume")}
            iconComponent={<FaExpandAlt className="inline-block w-5 h-4 mr-2" />}
            marginRight="4"
          />
          {dealflow.contact_email && (
            <ButtonSecondary
              onClick={() => (window.location = `mailto:${dealflow.contact_email}`)}
              text={t("share")}
              verticalMargin="1"
              iconComponent={<FaPaperPlane className="inline-block w-5 h-4 mr-2" />}
              vertical="2"
              horizontal="4"
              marginRight="4"
              width={"auto"}
              className="text-xs bg-white"
            />
          )}
          <ButtonCardMain
            onClick={() => navigate(`${RoutesLinks.DEALFLOW_LINK}${dealflow._id}`)}
            text={t("see_profile")}
            iconComponent={<FaEye className="inline-block w-5 h-4 mr-2" />}
          />
        </div>
      </Card>
    </>
  );
};
