import { Route, Routes, Navigate, useLocation, useOutletContext } from "react-router-dom";
import { RoutesLinks } from "./routes-links";
import Login from "./login";
import Checks from "./checks";
import OnBoarding from "./on-boarding";
import Forgot from "./forgot";
import ForgotResend from "./forgot-resend";
import ForgotConfirm from "./forgot-confirm";
import PrivateRoute from "./private-route";
import ScoreCards from "./score-cards";
import ScoreCardsWizard from "./score-cards-wizard";
import ScoreCard from "./score-cards/components/score-card";
import Event from "./events/components/event";
import Application from "./events/components/event/components/application";
import Dashboard from "./dashboard";
// import { LinkedInPopUp } from 'react-linkedin-login-oauth2'
import Templates from "./qe-templates";
import Questions from "./qe-questions";
import QuestionForm from "./qe-questions/components/question-form";
import { Company } from "./company/Company";
import Boards from "./pipeline-manager/components/boards";
import Board from "./pipeline-manager/components/board";
import SharedEventJudge from "components/events/components/judge/SharedEventJudge";
import { PublicForm } from "components/public-form/PublicForm";
import useUser from "../hooks/useUser";
import Account from "./account/Account";
import { useAuth } from "../hooks/useAuth";
import DocumentationTags from "./documentation/tags";
import Documents from "./documentation/documents";
import { DealflowListView } from "./dealflow/DealflowListView";
import { DealflowView } from "./dealflow/DealflowView";
import { InvestorInvestmentThesis } from "./investment-thesis/InvestorInvestmentThesis";
import DeaflowForm from "./dealflow/DeaflowForm";
import { KmZeroReport } from "./KmZeroReport/KmZeroReport";
import { KmZeroReportVC } from "./KmZeroReport/KmZeroReportVC";
import { Reminders } from "./reminders/Reminders";
import { FirstDropReport } from "./FirstDropReport/FirstDropReport";

import EvaluationProcesses from "./evaluations/EvaluationProcesses";
import QuestionnairesForm from "./qe-forms/components/forms-form/QuestionnairesForm";
import IRM from "./irm/Irm";
import IRMInvestor from "./irm/IRMInvestor";

import Reports from "./reports/Reports";
import { RequestedInformation } from "./requested-information/RequestedInformation";
import { RequestedKPIsForm } from "./requested-information/RequestedKPIsForm";
import { RequestedForm } from "components/requested-information/RequestedForm";
import { Help } from "./help/Help";
import { DealflowPreferences } from "./dealflow-preferences/DealflowPreferences";
import Template from "./qe-templates/components/template/template-view";
import RegisterConfirm from "./register-confirm/register-confirm-view";
import Forms from "./qe-forms/forms-view";
import Web from "./qe-forms/components/Web";
import Members from "./members/Members";
import MemberDetails from "./members/MemberDetails";
import { Portfolio } from "./portfolio";
import AppLayout from "./layout/app-layout";
import FullPageLayout from "./layout/full-page-layout";
import { ArchivedStartups } from "./dealflow/ArchivedStartups";
import { PoolStartups } from "./dealflow/PoolStartups";
import { TagListView } from "./tags/TagListView";
import { CustomFieldsDealFlow } from "./dealflow/CustomFieldsDealFlow";
import DealflowMainForm from "./dealflow/DealflowMainForm";
import DealflowSharedWithNetwork from "./dealflow/DealflowSharedWithNetwork";
import DealflowReports from "./dealflow/DealflowReports";
import { StartupKPIs } from "./startup-profile/components/StartupKPIs";
import Milestones from "./milestones";
import EvaluationDealflow from "./evaluations/EvaluationDealflow";
import { InvestorsMatching } from "./startup-profile/components/InvestorsMatching";
import { KiotaGammaEvaluation } from "./startup-profile/components/KiotaGammaEvaluation";
import Tracking from "./tracking";
import DealflowInterestedView from "./dealflow/DealflowInterestedView";
import DealflowForms from "./dealflow/DealflowForms";
import { StartupGeneralInformation } from "./startup-profile/StartupGeneralInformation";
import QuestionnairesEngineView from "./qe-forms/qe-view";
import EvaluationsView from "./evaluations/evaluations-view";
import ContactsView from "./irm/contacts-view";
import { DocumentationView } from "./documentation/documentation-view";
import DealflowDocumentsView from "./dealflow/dealflow-documents-view";
import { CompanyGeneralInformation } from "./company/CompanyGeneralInformation";
import { CompanyLPs } from "./company/CompanyLPs";
import { CompanyTeam } from "./company/CompanyTeam";
import { CompanyInvestmentThesis } from "./company/CompanyInvestmentThesis";
import { CompanySettings } from "./company/CompanySettings";
import CompanyChildren from "./company/CompanyChildren";
import AccountPersonalInfo from "./account/components/personal-info/AccountPersonalInfo";
import Emails from "./account/components/emails";
import Settings from "./account/components/settings";
import AccountSecurity from "./account/components/security/AccountSecurity";
import MemberView from "./members/member-view";
import CompanyChildrenSettingsView from "./company/children/company-children-settings-view";
import ApplicationInformationView from "./events/components/event/components/application-information-view";
import ApplicationDocumentsView from "./events/components/event/components/application-documents-view";
import ApplicationEvaluationsView from "./events/components/event/components/application-evaluations-view";
import MemberStartups from "./members/MemberStartups";
import MemberSection from "./members/MemberSection";
import MemberDealflowPreferences from "./members/member-dealflow-preferences";
import RegisterView from "./authentication/register/register-view";

const AppRouter = () => {
  const location = useLocation();

  const isFullPage = () => {
    const fullPages = [
      RoutesLinks.SHARED_LINK,
      RoutesLinks.PUBLIC_EVENTS_LINK,
      RoutesLinks.PUBLIC_FORMS_LINK,
      RoutesLinks.PUBLIC_TEMPLATES_LINK,
      RoutesLinks.REQUESTED_INFORMATION_LINK,
    ];

    for (const page of fullPages) {
      if (location.pathname.includes(page)) {
        return true;
      }
    }

    return false;
  };

  const Layout = isFullPage() ? FullPageLayout : AppLayout;
  const { hasAccessToSection } = useUser();
  const { token } = useAuth();

  return (
      <Routes>
      <Route path="/" element={<Layout />}>
          <Route path="/" element={<PrivateRoute />}>
            <Route path={RoutesLinks.DASHBOARD_LINK} element={<Dashboard />} />
            <Route path={RoutesLinks.ACCOUNT_LINK} element={<Account />} >
              <Route path="personal-information" element={<AccountPersonalInfo />} />
              <Route path="security" element={<AccountSecurity />} />
              <Route path="settings" element={<Settings />} />
              <Route path="email" element={<Emails />} />
              <Route path="*" element={<Navigate to={"personal-information"} />} />
            </Route>
            <Route path="/investment-thesis" element={<InvestorInvestmentThesis />} />
            <Route path={RoutesLinks.WEB_LINK} element={<Web />} />
            <Route path={RoutesLinks.QE_LINK} element={<QuestionnairesEngineView />} >
              <Route path={"forms"} element={<Forms />} />
              <Route path={"questions"} element={<Questions />} />
              <Route path="templates" element={<Templates />} />
              <Route path="*" element={<Navigate to={""} />} />
            </Route>
            <Route path={`${RoutesLinks.QE_FORMS_LINK}/:formId`} element={<QuestionnairesForm />} />
            <Route path={`${RoutesLinks.QE_TEMPLATES_LINK}/:templateId`} element={<Template />} />
            <Route path={RoutesLinks.QE_QUESTIONS_WIZARD_LINK} element={<QuestionForm />} />
            <Route path={`${RoutesLinks.QE_QUESTIONS_WIZARD_LINK}/:questionId`} element={<QuestionForm />} />
            <Route path={RoutesLinks.DEALFLOW_LINK} element={<DealflowListView />}>
              <Route path="" element={<PoolStartups />} />
              <Route path="archived" element={<ArchivedStartups />} />
              <Route path="tags" element={<TagListView category="dealflow" />}/>
              <Route path="custom_fields" element={<CustomFieldsDealFlow />} />
              <Route path="main_form" element={<DealflowMainForm />} />
              {hasAccessToSection("members") && <Route path="shared_with_network" element={<DealflowSharedWithNetwork />}/>}
            </Route>
            <Route path={`${RoutesLinks.DEALFLOW_LINK}:dealId`} element={<DealflowView />} >
                <Route path="" element={<StartupGeneralInformation />} />
                <Route path="kpis" element={<StartupKPIs />}/>
                <Route path="reports" element={<DealflowReports />}/>
                <Route path="documents" element={<DealflowDocumentsView />} />
                <Route path="milestones" element={<Milestones />} />
                <Route path="evaluations" element={<EvaluationDealflow />} />
                <Route path="investors_matching" element={<InvestorsMatching />} />
                <Route path="our_analysis" element={<KiotaGammaEvaluation />} />
                <Route path="tracking" element={<Tracking />} />
                <Route path="members" element={<DealflowInterestedView />} />
                <Route path="questionnaires" element={<DealflowForms />} />
                <Route path="" element={<Navigate to={""} />} />
            </Route>
            <Route path={RoutesLinks.EVALUATIONS_LINK} element={<EvaluationsView />} >
              <Route path={"evaluation-processes"} element={<EvaluationProcesses />} />
              <Route path={"score-cards"} element={<ScoreCards />} />
            </Route>
            <Route path={`${RoutesLinks.SCORE_CARDS_LINK}wizard`} element={<ScoreCardsWizard />} />
            <Route path={`${RoutesLinks.SCORE_CARDS_LINK}:scoreCardId`} element={<ScoreCard />} />
            <Route path={`${RoutesLinks.EVALUATION_PROCESSES_LINK}:eventId`} element={<Event />} >
              <Route path="startups"></Route>
              <Route path="judges"></Route>
              <Route path="configuration"></Route>
              <Route path="*" element={<Navigate to={"startups"} />}></Route>
            </Route>
            <Route path={`${RoutesLinks.EVALUATION_PROCESSES_LINK}:eventId/startups/:startupId`} element={<Application />} >
              <Route path="information" element={<ApplicationInformationView />}/>
              <Route path="documents" element={<ApplicationDocumentsView />} />
              <Route path="evaluations" element={<ApplicationEvaluationsView />} />
              <Route path="" element={<Navigate to={"information"} />} />
            </Route>
            <Route path={`${RoutesLinks.SCORE_CARDS_LINK}:scoreCardId/events/:eventId`} element={<ScoreCard />} />
            <Route path={`${RoutesLinks.PIPELINE_MANAGER_LINK}boards`} element={<Boards />} />
            <Route path={`${RoutesLinks.REPORTS_LINK}`} element={<Reports />} />
            <Route path={`${RoutesLinks.PIPELINE_MANAGER_LINK}boards/:boardId`} element={<Board />} />
            <Route path={RoutesLinks.DOCUMENTATION_LINK} element={<DocumentationView />}>
              <Route path={"documents"} element={<Documents />} />
              <Route path={"tags"} element={<DocumentationTags />} />
            </Route>
            {/* <Route path={RoutesLinks.COMPANY_GENERAL_INFORMATION_LINK} element={<Company section="general_information" />} />
            <Route path={RoutesLinks.COMPANY_TEAM_LINK} element={<Company section="team" />} />
            <Route path={RoutesLinks.COMPANY_LPS_LINK} element={<Company section="lps" />} /> */}
            {hasAccessToSection("members") && (
              <>
              <Route path={RoutesLinks.MEMBERS_LINK} element={<Members />} />
              <Route path={`${RoutesLinks.MEMBERS_LINK}:memberId`} element={<MemberDetails />} >
                <Route path="general-information" element={<MemberView />} />
                <Route path="dealflow-preferences" element={<MemberDealflowPreferences />} />;
                <Route path="shared-startups"element={<MemberStartups />} />
              </Route>
              </>
            )}
            <Route path={RoutesLinks.COMPANY_LINK} element={<Company />} >
              <Route path="general-information" element={<CompanyGeneralInformation />} />
              <Route path="lps" element={<CompanyLPs />} />
              <Route path="team" element={<CompanyTeam />} />
              <Route path="investment-thesis" element={<CompanyInvestmentThesis />} />
              <Route path="settings" element={<CompanySettings />} />
              <Route path="children" element={<CompanyChildren />} />
              <Route path="children-settings" element={<CompanyChildrenSettingsView />} />
              <Route path="" element={<Navigate to={"general-information"} />} />
            </Route>
            {/* <Route path={RoutesLinks.COMPANY_ROLES_LINK} element={<Company section="roles" />} />
            <Route path={RoutesLinks.COMPANY_INVESTMENT_THESIS_LINK} element={<Company section="investment_thesis" />} />
            <Route path={RoutesLinks.COMPANY_SETTINGS_LINK} element={<Company section="settings" />} />
            <Route path={RoutesLinks.COMPANY_SETTINGS_LINK} element={<Company section="children" />} /> */}
            <Route path={RoutesLinks.RESOURCES_LINK} element={<Help section="help_menu_resources" />} />
            <Route path={RoutesLinks.FEEDBACK_LINK} element={<Help section="give_us_feedback" />} />
            <Route path={RoutesLinks.CHAT_LINK} element={<Help section="chat_with_us" />} />
            <Route path={RoutesLinks.SUPPORT_LINK} element={<Help section="support" />} />
            <Route path={RoutesLinks.CONTACTS_LINK} element={<ContactsView />}>
              <Route path={""} element={<IRM />} />
            </Route>
            <Route path={`${RoutesLinks.CONTACTS_LINK}:investorId`} element={<IRMInvestor />} />
            <Route path={`${RoutesLinks.DEALFLOW_LINK}:dealId/edit`} element={<DeaflowForm />} />
            <Route path={`${RoutesLinks.PORFOLIO}`} element={<Portfolio />} />
          </Route>
          {!token && (
            <>
              <Route path={RoutesLinks.REGISTER_CONFIRM} element={<RegisterConfirm />} />
              <Route path={RoutesLinks.FORGOT_LINK} element={<Forgot />} />
              <Route path={RoutesLinks.FORGOT_RESEND_LINK} element={<ForgotResend />} />
              <Route path={RoutesLinks.FORGOT_CONFIRM_LINK} element={<ForgotConfirm />} />
            </>
          )}
          <Route path={RoutesLinks.LOGIN_LINK} element={<Login />} />
          {/* <Route path={RoutesLinks.REGISTER_LINK} element={<RegisterView />} /> */}
          <Route path={RoutesLinks.CHECKS_LINK} element={<Checks />} />
          <Route path={RoutesLinks.ON_BOARDING_LINK} element={<OnBoarding />} />
          <Route path={`${RoutesLinks.DEALFLOW_PREFERENCES_LINK}/:contactId`} element={<DealflowPreferences />} />
          <Route path={`${RoutesLinks.REQUESTED_INFORMATION_LINK}/forms/:formId`} element={<RequestedForm />} />
          <Route path={RoutesLinks.REQUESTED_INFORMATION_LINK} element={<RequestedInformation />} />
          <Route path={`${RoutesLinks.REQUESTED_INFORMATION_LINK}/kpis`} element={<RequestedKPIsForm />} />
          <Route path={`${RoutesLinks.SHARED_LINK}events/:eventId`} element={<SharedEventJudge />} />
          {/* TODO: ELIMINAR ESTAS RUTAS CUANDO HABLEMOS CON LOS CLIENTES */}
          <Route path={`${RoutesLinks.PUBLIC_EVENTS_LINK}63db95e1e27335268d646a1d`} element={<Navigate to={`${RoutesLinks.PUBLIC_FORMS_LINK}6423021a10f9e261a95ec61f`} />} />
          <Route path={`${RoutesLinks.PUBLIC_EVENTS_LINK}63ff51a9536e671ec13031f1`} element={<Navigate to={`${RoutesLinks.PUBLIC_FORMS_LINK}63ff516559b78229fe30d534`} />} />
          <Route path={`${RoutesLinks.PUBLIC_EVENTS_LINK}63e2281336d75a6fae718aab`} element={<Navigate to={`${RoutesLinks.PUBLIC_FORMS_LINK}63d26a39c586122d338eae3e`} />} />
          <Route path={`${RoutesLinks.PUBLIC_EVENTS_LINK}630733873b9f651afc40132e`} element={<Navigate to={`${RoutesLinks.PUBLIC_FORMS_LINK}62d6695ea18c6e21b7460331`} />} />
          <Route path={`${RoutesLinks.PUBLIC_EVENTS_LINK}6343e1548430842f0a3487f9`} element={<Navigate to={`${RoutesLinks.PUBLIC_FORMS_LINK}6343e0f98430842f0a3487f3`} />} />
          <Route path={`${RoutesLinks.PUBLIC_EVENTS_LINK}6373a81d3c95b91672c1b045`} element={<Navigate to={`${RoutesLinks.PUBLIC_FORMS_LINK}63d30d5f2728db42bdfe2f92`} />} />
          <Route path={`${RoutesLinks.PUBLIC_FORMS_LINK}:formId`} element={<PublicForm />} />
          <Route path={`${RoutesLinks.PUBLIC_TEMPLATES_LINK}:templateId`} element={<PublicForm />} />
          <Route path={`${RoutesLinks.SHARED_REPORTS_LINK}firstdrop`} element={<FirstDropReport />} />
          <Route path={`${RoutesLinks.SHARED_REPORTS_LINK}kmzero`} element={<KmZeroReport />} />
          <Route path={`${RoutesLinks.SHARED_REPORTS_LINK}kmzero-vc`} element={<KmZeroReportVC />} />
          <Route path={RoutesLinks.REMINDERS_LINK} element={<Reminders />} />
        </Route>
        <Route path="*" element={<Navigate to={RoutesLinks.DASHBOARD_LINK} />} />
        {/* {!token && <Route exact path='/linkedin' component={LinkedInPopUp} />} */}
      </Routes>
  );
};

export default AppRouter;
