import { Card } from "../../../ui/atoms/Card";
import { NoDataInfo } from "../../../ui/atoms/NoDataInfo";
import {
  FaArrowLeft,
  FaDownload,
  FaExternalLinkAlt,
  FaEye,
  FaFolder,
  FaPen,
  FaTrashAlt,
} from "react-icons/fa";
import { DataGrid } from "../../../ui/objects/DataGrid";
import { DocumentIcon } from "./DocumentIcon";
import moment from "moment/moment";
import { Pagination } from "../../../ui/atoms/Pagination";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import ReactTooltip from "react-tooltip";
import DocumentsFilters from "./DocumentsFilters";
import { useSwal } from "../../../../hooks/useSwal";
import { isEqual } from "lodash";
import { useToasts } from "react-toast-notifications";
import { useTranslation, Trans } from "react-i18next";
import { DealflowService } from "../../../../services/dealflow-service";
import { Modal } from "../../../ui/molecules/Modal";
import { ProgressBar } from "../../../ui/atoms/ProgressBar";

import Config from "config";
import { UploadFileSection } from "./UploadFileSection";
import useUser from "hooks/useUser";
import { DocumentSection } from "./DocumentSection";
import DocumentsService from "services/documents-service";
import { FolderSection } from "./FolderSection";
import { MdFolderShared } from "react-icons/md";
import LogoDefault from "styles/images/logo_default.png";
import { countAppliedFilters } from "utils/countAppliedFilters";
import FoldersModal from "./FoldersModal";

// Necesario para renderizar correctamente documentos pdf
import "./pdf-css/TextLayer.css";
import "./pdf-css/AnnotationLayer.css";
import DocumentPreview from "./DocumentPreview";

const invalidPreviewExtensions = ["docx", "doc", "svg"];

export const DocumentList = forwardRef(
  (
    {
      dealId,
      shared = false,
      getDeal,
      setDocumentToMigrate,
      reloadDocuments,
      setReloadDocuments,
      setShowStatistics = null,
      setFiltersCount = () => null,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { confirm } = useSwal();
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState({ startup: dealId });
    const [dealflow, setDealflow] = useState([]);
    const [selectedFolder, setselectedFolder] = useState(null);
    const [showFolderSection, setShowFolderSection] = useState(false);
    const [showPrevisualizationModal, setShowPrevisualizationModal] = useState(false);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [showMoveDocumentFolder, setShowMoveDocumentFolder] = useState(false);
    const [previewModalExpanded, setPreviewModalExpanded] = useState(false);

    const handleChangePreviewSize = async() => {
      handleSelectedDocumentPreview(documentToEdit._id);
      setPreviewModalExpanded(!previewModalExpanded);
    };

    const docViewerRef = useRef(null);

    const { user, userLanguage, hasAccessToModule } = useUser();
    const [showUploadDocumentSection, setShowUploadDocumentSection] = useState(false);
    const [startup, setStartup] = useState(null);
    const [documents, setDocuments] = useState([]);
    const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState({
      page: 0,
      rowsPerPage: 12,
      totalPages: 0,
      totalDocs: 0,
    });
    const [showDocumentSection, setShowDocumentSection] = useState(false);
    const [documentToEdit, setDocumentToEdit] = useState(null);
    const [folderToEdit, setFolderToEdit] = useState(null);

    const [uploadingPercentage, setUploadingPercentage] = useState(0);
    const headerClass = "text-main font-bold text-sm border-b border-main text-left bg-white";

    const handleSetFilters = (_filters) => {
      setFiltersCount(countAppliedFilters(_filters, ["parent_folder"]));
      setFilters(_filters);
    };

    const uploadDocument = async(
      document,
      tags,
      selectedStartup,
      entityToAssignDocument,
      category,
      lpsShared,
      membersShared,
      indexDocument,
    ) => {
      const formData = new FormData();
      formData.append("document", document);
      formData.append("document_name", document.name);

      if (selectedFolder) {
        formData.append("parent_folder", selectedFolder._id);
      }

      formData.append("lps_shared", lpsShared);
      formData.append("members_shared", membersShared);

      formData.append("category", category);
      if (tags) {
        formData.append("tags", tags);
      }

      formData.append("index_document", indexDocument);

      if (selectedStartup) {
        setStartup(selectedStartup);
        formData.append("startup", selectedStartup);
      } else if (selectedFolder && selectedFolder.startup) {
        setStartup(selectedFolder.startup._id);
        formData.append("startup", selectedFolder.startup._id);
      }

      return DocumentsService.uploadDocument(formData)
        .then(() => setStartup(null))
        .catch((error) => {
          addToast(
            error?.response?.data?.msg_key
              ? t(error.response.data.msg_key)
              : t("error_occurred_uploading_document"),
            {
              appearance: "error",
              autoDismiss: true,
            },
          );
        });
    };

    const uploadDocuments = async(
      documentType,
      documents,
      tags,
      selectedStartup,
      link,
      name,
      entityToAssignDocument,
      category,
      lpsShared,
      membersShared,
      indexDocument,
    ) => {
      if (documentType === "link") {
        setLoading(true);
        const data = { name, link, tags, category };
        if (entityToAssignDocument === "startup") {
          data.startup = selectedStartup;
        } else if (selectedFolder && selectedFolder.startup) {
          data.startup = selectedFolder.startup._id;
        }

        if (selectedFolder) {
          data.parent_folder = selectedFolder._id;
        }

        data.lps_shared = lpsShared;
        data.members_shared = membersShared;

        DocumentsService.uploadLink(data)
          .then(() => {
            if (getDeal) {
              getDeal();
            }
            getDocuments();
          })
          .catch((error) =>
            addToast(
              error?.response?.data?.msg_key
                ? t(error.response.data.msg_key)
                : t("error_occurred_uploading_document"),
              {
                appearance: "error",
                autoDismiss: true,
              },
            ),
          )
          .finally(() => setLoading(false));
      } else {
        if (Array.isArray(documents) && documents.length > 1) {
          setUploadingPercentage(1);

          for (const document of documents) {
            await uploadDocument(
              document,
              tags,
              selectedStartup,
              entityToAssignDocument,
              category,
              lpsShared,
              membersShared,
            );
            setUploadingPercentage((prev) => Math.ceil(prev + 100 / documents.length + 1));
          }

          setUploadingPercentage(0);
          setLoading(true);
          getDocuments().finally(() => setLoading(false));
          if (getDeal) {
            getDeal();
          }
        } else {
          setLoading(true);
          const file = Array.isArray(documents) ? documents[documents.length - 1] : documents;
          uploadDocument(
            file,
            tags,
            selectedStartup,
            entityToAssignDocument,
            category,
            lpsShared,
            membersShared,
            indexDocument,
          )
            .then(() => {
              if (getDeal) {
                getDeal();
              }
              getDocuments();
            })
            .finally(() => setLoading(false));
        }
      }
    };

    const downloadDocument = async(documentId) => {
      setLoading(true);
      try {
        let response = null;
        if (shared) {
          response = await DocumentsService.getSharedDocument(documentId);
        } else {
          response = await DocumentsService.getDocument(documentId);
        }

        if (response?.data?.data?.url) {
          window.open(response.data.data.url, "_blank");
        }
      } catch (error) {
        addToast(
          error?.response?.data?.msg_key
            ? t(error.response.data.msg_key)
            : t("error_occurred_downloading_document"),
          {
            appearance: "error",
            autoDismiss: true,
          },
        );
      } finally {
        setLoading(false);
      }
    };

    const handleSelectedDocumentPreview = async(documentId) => {
      setLoading(true);
      setPreviewUrl(null);
      try {
        let response = null;
        if (shared) {
          response = await DocumentsService.getSharedDocument(documentId);
        } else {
          response = await DocumentsService.getDocumentPreview(documentId);
        }

        if (response?.data?.data?.url) {
          setPreviewUrl(response?.data?.data?.url);
        }
      } catch (error) {
        addToast(
          error?.response?.data?.msg_key
            ? t(error.response.data.msg_key)
            : t("error_occurred_downloading_document"),
          {
            appearance: "error",
            autoDismiss: true,
          },
        );
      } finally {
        setLoading(false);
      }
    };

    const deleteDocument = async(document) => {
      confirm({
        icon: "error",
      }).then(async(isConfirmed) => {
        if (isConfirmed) {
          setLoading(true);
          try {
            if (document.link) {
              await DocumentsService.deleteLink(document._id);
            } else {
              await DocumentsService.deleteDocument(document._id);
            }
            if (getDeal) {
              getDeal();
            }
            getDocuments();
          } catch (error) {
            addToast(
              error?.response?.data?.msg_key
                ? t(error.response.data.msg_key)
                : t("error_occurred_deleting_document"),
              {
                appearance: "error",
                autoDismiss: true,
              },
            );
          } finally {
            setLoading(false);
          }
        }
      });
    };

    const getDocuments = async() => {
      try {
        let result = null;
        if (shared) {
          result = await DocumentsService.getSharedDocuments(
            filters
              ? {
                  page: pagination.page,
                  rowsPerPage: pagination.rowsPerPage,
                  ...filters,
                }
              : {
                  page: pagination.page,
                  rowsPerPage: pagination.rowsPerPage,
                },
          );
        } else {
          result = await DocumentsService.getDocuments(
            filters
              ? {
                  page: pagination.page,
                  rowsPerPage: pagination.rowsPerPage,
                  ...filters,
                }
              : {
                  page: pagination.page,
                  rowsPerPage: pagination.rowsPerPage,
                },
          );
        }

        const { documents, pagination: responsePagination } = result.data.data;
        setDocuments(documents);

        if (responsePagination) {
          const newPagination = {
            ...pagination,
            page:
              responsePagination.current >= responsePagination.pages
                ? responsePagination.pages - 1
                : responsePagination.current - 1,
            totalPages: responsePagination.pages,
            totalDocs: responsePagination.totalDocs,
          };

          if (!isEqual(newPagination, pagination)) {
            setPagination(newPagination);
          }
        }
      } catch (error) {
        addToast(
          error?.response?.data?.msg_key
            ? t(error.response.data.msg_key)
            : t("error_occurred_retrieving_documents"),
          {
            appearance: "error",
            autoDismiss: true,
          },
        );
      } finally {
        setLoading(false);
      }
    };

    const getDealflow = () => DealflowService.getDealflow({
      rowsPerPage: 9999999,
      pool: "true",
      populate: "false",
      boards: "false",
      milestones: "false",
      files: "false",
    })
      .then((response) => {
        setDealflow(response.data.data.dealflow);
      })
      .catch((error) => {
        addToast(error?.response?.data?.msg_key || t("error_occurred_retrieving_dealflow"), {
          appearance: "error",
          autoDismiss: true,
        });
      });

    const editDocument = async(tags, category, lpsShared, membersShared) => {
      setShowDocumentSection(false);
      setLoading(true);
      try {
        if (documentToEdit.link) {
          await DocumentsService.updateLink(documentToEdit._id, {
            tags: tags.length ? tags : null,
            category,
            lps_shared: lpsShared,
            members_shared: membersShared,
          });
        } else {
          await DocumentsService.updateDocument(documentToEdit._id, {
            tags: tags.length ? tags : null,
            category,
            lps_shared: lpsShared,
            members_shared: membersShared,
          });
        }

        if (getDeal) {
          getDeal();
        }
        getDocuments();
      } catch (error) {
        addToast(
          error?.response?.data?.msg_key
            ? t(error.response.data.msg_key)
            : t("error_occurred_updating_document"),
          {
            appearance: "error",
            autoDismiss: true,
          },
        );
      } finally {
        setLoading(false);
        setDocumentToEdit(null);
      }
    };

    const moveDocument = async(newParentFolderId) => {
      setShowDocumentSection(false);
      setLoading(true);
      try {
        await DocumentsService.updateDocument(documentToEdit._id, {
          parentFolder: newParentFolderId,
        });

        getDocuments();
      } catch (error) {
        addToast(
          error?.response?.data?.msg_key
            ? t(error.response.data.msg_key)
            : t("error_occurred_updating_document"),
          {
            appearance: "error",
            autoDismiss: true,
          },
        );
      } finally {
        setLoading(false);
        setDocumentToEdit(null);
        setShowMoveDocumentFolder(false);
      }
    };

    const editFolder = async(data) => {
      setShowFolderSection(false);
      setLoading(true);
      try {
        await DocumentsService.updateFolder(folderToEdit._id, {
          ...data,
          startup: dealId || folderToEdit.startup,
        });
        getDocuments();
      } catch (error) {
        console.error(error);
        addToast(
          error?.response?.data?.msg_key
            ? t(error.response.data.msg_key)
            : t("error_occurred_updating_folder"),
          {
            appearance: "error",
            autoDismiss: true,
          },
        );
      } finally {
        setLoading(false);
        setFolderToEdit(null);
      }
    };

    const createFolder = async(data) => {
      confirm({
        title: t("are_you_sure"),
      }).then(async(isConfirmed) => {
        try {
          setLoading(true);
          if (isConfirmed) {
            const startup = selectedFolder ? selectedFolder.startup : dealId;
            await DocumentsService.createFolder({
              ...data,
              parent_folder: selectedFolder ? selectedFolder._id : null,
              startup,
            });
            addToast(t("folder_created_successfully"), {
              appearance: "success",
              autoDismiss: true,
            });
            setShowFolderSection(false);
            setReloadDocuments(true);
          }
        } catch (error) {
          console.error(error);
          addToast(
            error?.response?.data?.msg_key
              ? t(error.response.data.msg_key)
              : t("error_occurred_creating_folder"),
            {
              appearance: "error",
              autoDismiss: true,
            },
          );
        } finally {
          setLoading(false);
        }
      });
    };

    const deleteFolder = async(folderId) => {
      confirm({
        title: t("are_you_sure"),
        text: t("delete_folder_warning"),
      }).then(async(isConfirmed) => {
        try {
          setLoading(true);
          if (isConfirmed) {
            await DocumentsService.deleteFolder(folderId);
            addToast(t("folder_deleted_successfully"), {
              appearance: "success",
              autoDismiss: true,
            });
            setShowFolderSection(false);
            setReloadDocuments(true);
          }
        } catch (error) {
          addToast(t("error_occurred_deleting_folder"), { appearance: "error", autoDismiss: true });
        } finally {
          setLoading(false);
        }
      });
    };

    useImperativeHandle(ref, () => ({
      showFilters: () => setShowFilters(true),
      uploadDocument: () => setShowUploadDocumentSection(true),
      createFolder: () => setShowFolderSection(true),
    }));

    useEffect(() => {
      setLoading(true);
      getDocuments().finally(() => setLoading(false));
    }, [pagination.page, pagination.rowsPerPage, filters]);

    useEffect(() => {
      if (reloadDocuments) {
        setLoading(true);
        getDocuments().finally(() => setLoading(false));
        setReloadDocuments(false);
      }
    }, [reloadDocuments]);

    useEffect(() => {
      const init = async() => {
        setReloadDocuments(false);
        setLoading(true);
        if (!dealId && !shared) {
          await getDealflow();
        }
        /* if (!shared) {
        await getTags()
      } */
        await getDocuments();
        setLoading(false);
      };

      init();
    }, [dealId, shared]);

    useEffect(() => {
      ReactTooltip.rebuild();
    }, []);

    useEffect(() => {
      if (selectedFolder && setShowStatistics) {
        setShowStatistics(false);
      } else if (!selectedFolder && setShowStatistics) {
        setShowStatistics(true);
      }
      handleSetFilters({ ...filters, parent_folder: selectedFolder ? selectedFolder._id : null });
    }, [selectedFolder]);

    return (
      <>
        <div className="text-sm font-semibold flex mt-4">
          {selectedFolder && (
            <div className="cursor-pointer">
              <FaArrowLeft
                className="inline-block mr-3 cursor-pointer"
                onClick={() => setselectedFolder(selectedFolder.parent_folder || null)}
              />
            </div>
          )}
          <Trans
            i18nKey="viewing_current_folder"
            values={{ folder_name: selectedFolder ? selectedFolder.name : t("root_folder") }}
          />
        </div>
        {!loading && documents.length === 0 && (
          <>
            {!dealId && (
              <div className="my-10">
                <img
                  src={`${Config.GUIDE_STEPS_BACKGROUND_IMAGES_BUCKET}documentation_documents_${userLanguage}.png`}
                  className="w-auto"
                />
              </div>
            )}
            {dealId && (
              <div className="mt-6">
                <NoDataInfo title={t("documents_not_found")} />
              </div>
            )}
          </>
        )}
        {(loading || documents.length) !== 0 && (
          <>
            <div className="mt-6">
              <Card>
                <DataGrid
                  loading={loading}
                  actionsHeaderClassName={headerClass}
                  data={documents.map((d) => ({ ...d, id: d._id }))}
                  headers={
                    !user.lp
                      ? hasAccessToModule("members")
                        ? [
                            {
                              key: "name",
                              title: t("name"),
                              headerClassName: headerClass,
                              render: (key, doc) => (
                                <div
                                  className={`flex h-10 flex-col justify-center ${doc.is_folder && "cursor-pointer hover:font-semibold"}`}
                                  onClick={() => (doc.is_folder ? setselectedFolder(doc) : null)}
                                >
                                  <div className="flex flex-row items-center">
                                    {!doc.is_folder && (
                                      <DocumentIcon
                                        documentName={doc.name}
                                        size={20}
                                        link={!!doc.link}
                                      />
                                    )}
                                    {doc.is_folder && (doc.lps_shared || doc.members_shared) && (
                                      <MdFolderShared className="text-lg" />
                                    )}
                                    {doc.is_folder && !doc.lps_shared && !doc.members_shared && (
                                      <FaFolder className="text-lg" />
                                    )}

                                    <div className="ml-2">{doc.name}</div>
                                  </div>
                                </div>
                              ),
                            },
                            {
                              key: "startup",
                              title: t("startup"),
                              headerClassName: headerClass,
                              show: () => !dealId,
                              render: (key, doc) => (
                                <>
                                  {doc.startup ? (
                                    <div className="flex items-center mt-1">
                                      <img
                                        src={doc.startup.logo || LogoDefault}
                                        alt={doc.startup.name}
                                        className="h-8 w-10 object-contain cursor-pointer"
                                      />
                                      {doc.startup.name && (
                                        <div className="flex flex-col justify-center text-xs font-semibold">
                                          <span className="ml-2">{doc.startup.name}</span>
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div>-</div>
                                  )}
                                </>
                              ),
                            },
                            {
                              key: "category",
                              title: t("category"),
                              headerClassName: headerClass,
                              render: (key, document) => (
                                <>
                                  {!document.is_folder && document.category
                                    ? t(document.category)
                                    : "-"}
                                </>
                              ),
                            },
                            {
                              key: "createdAt",
                              title: t("date"),
                              headerClassName: headerClass,
                              render: (key, document) =>
                                moment(document.createdAt).format("DD/MM/YYYY"),
                            },
                            {
                              key: "lps_shared",
                              title: t("lps_shared"),
                              headerClassName: headerClass,
                              render: (key, document) => t(document.lps_shared ? "yes" : "no"),
                            },
                            {
                              key: "members_shared",
                              title: t("members_shared"),
                              headerClassName: headerClass,
                              render: (key, document) => t(document.members_shared ? "yes" : "no"),
                            },
                            (hasAccessToModule("subcompanies") && {
                              key: "subcompany",
                              title: t("subcompany"),
                              headerClassName: headerClass,
                              render: (key, document) => document.investor._id === user.investor._id ? "-" : document.investor.company_name,
                            }),
                          ]
                        : [
                            {
                              key: "name",
                              title: t("name"),
                              headerClassName: headerClass,
                              render: (key, doc) => (
                                <div
                                  className={`flex h-10 flex-col justify-center ${doc.is_folder && "cursor-pointer hover:font-semibold"}`}
                                  onClick={() => (doc.is_folder ? setselectedFolder(doc) : null)}
                                >
                                  <div className="flex flex-row">
                                    {!doc.is_folder && (
                                      <DocumentIcon
                                        documentName={doc.name}
                                        size={20}
                                        link={!!doc.link}
                                      />
                                    )}
                                    {doc.is_folder && (doc.lps_shared || doc.members_shared) && (
                                      <MdFolderShared className="text-lg" />
                                    )}
                                    {doc.is_folder && !doc.lps_shared && !doc.members_shared && (
                                      <FaFolder className="text-lg" />
                                    )}

                                    <div className="ml-2">{doc.name}</div>
                                  </div>
                                </div>
                              ),
                            },
                            {
                              key: "startup",
                              title: t("startup"),
                              headerClassName: headerClass,
                              show: () => !dealId,
                              render: (key, doc) => (
                                <>
                                  {doc.startup ? (
                                    <div className="flex">
                                      <img
                                        src={doc.startup.logo || LogoDefault}
                                        alt={doc.startup.name}
                                        className="h-10 w-14 object-contain cursor-pointer"
                                      />
                                      {doc.startup.name && (
                                        <div className="flex flex-col justify-center text-xs font-semibold">
                                          <span className="ml-2">{doc.startup.name}</span>
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div>-</div>
                                  )}
                                </>
                              ),
                            },
                            {
                              key: "category",
                              title: t("category"),
                              headerClassName: headerClass,
                              render: (key, document) => (
                                <>
                                  {!document.is_folder && document.category
                                    ? t(document.category)
                                    : "-"}
                                </>
                              ),
                            },
                            {
                              key: "createdAt",
                              title: t("date"),
                              headerClassName: headerClass,
                              render: (key, document) =>
                                moment(document.createdAt).format("DD/MM/YYYY"),
                            },
                            {
                              key: "lps_shared",
                              title: t("lps_shared"),
                              headerClassName: headerClass,
                              render: (key, document) => t(document.lps_shared ? "yes" : "no"),
                            },
                            (hasAccessToModule("subcompanies") && {
                              key: "subcompany",
                              title: t("subcompany"),
                              headerClassName: headerClass,
                              render: (key, document) => document.investor._id === user.investor._id ? "-" : document.investor.company_name,
                            }),
                          ]
                      : [
                          {
                            key: "name",
                            title: t("name"),
                            headerClassName: headerClass,
                            render: (key, doc) => (
                              <div
                                className={`flex h-10 flex-col justify-center ${doc.is_folder && "cursor-pointer hover:font-semibold"}`}
                                onClick={() => (doc.is_folder ? setselectedFolder(doc) : null)}
                              >
                                <div className="flex flex-row">
                                  {!doc.is_folder && (
                                    <DocumentIcon
                                      documentName={doc.name}
                                      size={20}
                                      link={!!doc.link}
                                    />
                                  )}
                                  {doc.is_folder && (doc.lps_shared || doc.members_shared) && (
                                    <MdFolderShared className="text-lg" />
                                  )}
                                  {doc.is_folder && !doc.lps_shared && !doc.members_shared && (
                                    <FaFolder className="text-lg" />
                                  )}

                                  <div className="ml-2">{doc.name}</div>
                                </div>
                              </div>
                            ),
                          },
                          {
                            key: "startup",
                            title: t("startup"),
                            headerClassName: headerClass,
                            show: () => !dealId,
                            render: (key, doc) => (
                              <>
                                {doc.startup ? (
                                  <div className="flex">
                                    <img
                                      src={doc.startup.logo || LogoDefault}
                                      alt={doc.startup.name}
                                      className="h-10 w-14 object-contain cursor-pointer"
                                    />
                                    {doc.startup.name && (
                                      <div className="flex flex-col justify-center text-xs font-semibold">
                                        <span className="ml-2">{doc.startup.name}</span>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div>-</div>
                                )}
                              </>
                            ),
                          },
                          {
                            key: "category",
                            title: t("category"),
                            headerClassName: headerClass,
                            render: (key, document) => (
                              <>
                                {!document.is_folder && document.category
                                  ? t(document.category)
                                  : "-"}
                              </>
                            ),
                          },
                        ]
                  }
                  actions={[
                    {
                      id: "details",
                      tip: t("see"),
                      onClick: (document) => {
                        if (document.is_folder) {
                          setselectedFolder(document);
                        } else {
                          // Selecciona el documento y lo muestra
                          setShowPrevisualizationModal(true);
                          handleSelectedDocumentPreview(document._id);
                          setDocumentToEdit(document);
                        }
                      },
                      show: (document) => !invalidPreviewExtensions.includes(document.extension) && !document.link,
                      buttonProps: {
                        iconComponent: <FaEye />,
                      },
                    },
                    // Desactivado por el momento
                    // {
                    //   id: 'migrate',
                    //   tip: t('migrate'),
                    //   onClick: (document) => {
                    //     setDocumentToMigrate(document)
                    //   },
                    //   show: (document) => !user.lp && !shared && !document.is_folder && document.startup,
                    //   buttonProps: {
                    //     iconComponent: <FaExchangeAlt />
                    //   }
                    // },
                    {
                      id: "edit",
                      tip: t("configure"),
                      onClick: (document) => {
                        if (document.is_folder) {
                          setShowFolderSection(true);
                          setFolderToEdit(document);
                        } else {
                          setShowDocumentSection(true);
                          setDocumentToEdit(document);
                        }
                      },
                      show: () => !user.lp && !shared,
                      buttonProps: {
                        iconComponent: <FaPen />,
                      },
                    },
                    {
                      id: "link",
                      tip: t("open_link"),
                      onClick: (document) => {
                        window.open(document.link, "_blank");
                      },
                      show: (document) => !document.is_folder && document.link,
                      buttonProps: {
                        iconComponent: <FaExternalLinkAlt />,
                      },
                    },
                    {
                      id: "download",
                      tip: t("download"),
                      onClick: (document) => downloadDocument(document._id),
                      show: (document) => !document.is_folder && !document.link,
                      buttonProps: {
                        iconComponent: <FaDownload />,
                      },
                    },
                    {
                      id: "delete",
                      tip: t("delete"),
                      show: () => !user.lp && !shared,
                      onClick: (document) =>
                        document.is_folder ? deleteFolder(document._id) : deleteDocument(document),
                      buttonProps: {
                        iconComponent: <FaTrashAlt />,
                      },
                    },
                  ]}
                />
                <Pagination
                  paginateOptions={[12, 24, 36]}
                  currentPage={pagination.page}
                  setCurrentPage={(page) => setPagination({ ...pagination, page })}
                  perPage={pagination.rowsPerPage}
                  pages={pagination.totalPages}
                  setPerPage={(value) =>
                    setPagination({ ...pagination, page: 0, rowsPerPage: value[0].id })
                  }
                />
              </Card>
            </div>
          </>
        )}

        {showFolderSection && (
          <FolderSection
            folder={folderToEdit}
            onClose={() => {
              setFolderToEdit(null);
              setShowFolderSection(false);
            }}
            onSubmit={(data) => {
              folderToEdit ? editFolder(data) : createFolder(data);
            }}
          />
        )}
        {showUploadDocumentSection && (
          <UploadFileSection
            onSubmit={uploadDocuments}
            onClose={() => setShowUploadDocumentSection(false)}
            startup={startup}
            tags={tags}
            dealflow={dealflow}
            dealId={dealId}
          />
        )}

        {showFilters && (
          <DocumentsFilters
            onClose={() => setShowFilters(false)}
            filters={filters}
            dealflow={dealflow}
            dealId={dealId}
            tags={tags}
            onSubmit={(filters) => {
              handleSetFilters(filters);
              setShowFilters(false);
            }}
          />
        )}

        {showDocumentSection && (
          <DocumentSection
            tags={tags}
            cat={documentToEdit.category}
            currentTags={documentToEdit?.tags || []}
            onSubmit={editDocument}
            onClose={() => {
              setShowDocumentSection(false);
              setDocumentToEdit(null);
              setShowMoveDocumentFolder(false);
            }}
            document={documentToEdit}
            handleShowMoveModal={() => setShowMoveDocumentFolder(true)}
            closeOnClickOutside={!showMoveDocumentFolder} // Prevents sidepanel to close when clicking the modal
          />
        )}

        {uploadingPercentage > 0 && uploadingPercentage < 100 && (
          <Modal showModal={true}>
            <div className="w-72">
              <ProgressBar
                percentage={uploadingPercentage}
                value={uploadingPercentage}
                valueText={t("uploading")}
              />
            </div>
          </Modal>
        )}

        {showPrevisualizationModal && (
          <DocumentPreview
            downloadDocument={() => downloadDocument(documentToEdit._id)}
            previewUrl={previewUrl}
            document={documentToEdit}
            handleClose={() => setShowPrevisualizationModal(false)}
            refetch={() => handleSelectedDocumentPreview(documentToEdit._id)}
          />
        )}

        {documentToEdit && (
          <FoldersModal
            open={showMoveDocumentFolder}
            handleClose={() => setShowMoveDocumentFolder(false)}
            documentToMove={documentToEdit}
            onSubmit={moveDocument}
            filters={filters}
          />
        )}
      </>
    );
  },
);
