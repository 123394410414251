import axios from "axios/index";
import Config from "../config";

export const DealflowService = {
  getDealflow: async(query) => axios.post(`${Config.DEALFLOW_API_URL}private/list`, query),

  getDeal: async(dealId) => axios.get(`${Config.DEALFLOW_API_URL}private/${dealId}`),

  getDealComplete: async(dealId) => axios.get(`${Config.DEALFLOW_API_URL}private/${dealId}/complete`),

  updateDealTags: async(dealId, tags) =>
    axios.put(`${Config.DEALFLOW_API_URL}private/${dealId}/tags`, { tags }),

  rejectDeal: async(dealId, data) =>
    axios.post(`${Config.DEALFLOW_API_URL}private/${dealId}/reject`, data),

  archiveDeal: async(dealId, data) =>
    axios.post(`${Config.DEALFLOW_API_URL}private/${dealId}/archive`, data),

  unarchiveDeal: async(dealId, data) =>
    axios.post(`${Config.DEALFLOW_API_URL}private/${dealId}/unarchive`, data),

  markAsFailed: async(dealId, data) =>
    axios.post(`${Config.DEALFLOW_API_URL}private/${dealId}/failure`, data),

  markAsInvested: async(dealId) =>
    axios.post(`${Config.DEALFLOW_API_URL}private/${dealId}/invested`),

  markAsInteresting: async(dealId, interesting) =>
    axios.post(`${Config.DEALFLOW_API_URL}private/${dealId}/interesting`, { interesting }),

  registerInvestment: async(dealId, data) =>
    axios.post(`${Config.DEALFLOW_API_URL}private/${dealId}/investments`, data),

  getInvestments: async(dealId) =>
    axios.get(`${Config.DEALFLOW_API_URL}private/${dealId}/investments`),

  registerExit: async(dealId, data) =>
    axios.post(`${Config.DEALFLOW_API_URL}private/${dealId}/register-exit`, data),

  deleteDeal: async(dealId, data) =>
    axios.post(`${Config.DEALFLOW_API_URL}private/${dealId}/delete`, data),

  addDeal: async(data) => axios.post(`${Config.DEALFLOW_API_URL}private/`, data),

  updateDeal: async(dealId, data) =>
    axios.put(`${Config.DEALFLOW_API_URL}private/${dealId}`, data),

  getDealMembers: async(dealId) =>
    axios.get(`${Config.DEALFLOW_API_URL}private/${dealId}/members`),

  updateMember: async(dealId, data, memberId) =>
    axios.patch(`${Config.DEALFLOW_API_URL}private/${dealId}/members/${memberId}`, data),

  addMember: async(dealId, data) =>
    axios.post(`${Config.DEALFLOW_API_URL}private/${dealId}/members`, data),

  deleteMember: async(dealId, memberId) =>
    axios.delete(`${Config.DEALFLOW_API_URL}private/${dealId}/members/${memberId}`),

  updateShareholder: async(dealId, data, shareholderId) =>
    axios.patch(`${Config.DEALFLOW_API_URL}private/${dealId}/shareholders/${shareholderId}`, data),

  addShareholder: async(dealId, data) =>
    axios.post(`${Config.DEALFLOW_API_URL}private/${dealId}/shareholders`, data),

  deleteShareholder: async(dealId, shareholderId) =>
    axios.delete(`${Config.DEALFLOW_API_URL}private/${dealId}/shareholders/${shareholderId}`),

  updateCompetitor: async(dealId, data, competitorId) =>
    axios.patch(`${Config.DEALFLOW_API_URL}private/${dealId}/competitors/${competitorId}`, data),

  addCompetitor: async(dealId, data) =>
    axios.post(`${Config.DEALFLOW_API_URL}private/${dealId}/competitors`, data),

  deleteCompetitor: async(dealId, competitorId) =>
    axios.delete(`${Config.DEALFLOW_API_URL}private/${dealId}/competitors/${competitorId}`),

  updateReferred: async(dealId, data, referredId) =>
    axios.patch(`${Config.DEALFLOW_API_URL}private/${dealId}/referrals/${referredId}`, data),

  addReferred: async(dealId, data) =>
    axios.post(`${Config.DEALFLOW_API_URL}private/${dealId}/referrals`, data),

  deleteReferred: async(dealId, referredId) =>
    axios.delete(`${Config.DEALFLOW_API_URL}private/${dealId}/referrals/${referredId}`),

  updateInvestment: async(dealId, data, investmentId) =>
    axios.patch(`${Config.DEALFLOW_API_URL}private/${dealId}/investments/${investmentId}`, data),

  deleteInvestment: async(dealId, investmentId) =>
    axios.delete(`${Config.DEALFLOW_API_URL}private/${dealId}/investments/${investmentId}`),

  shareDealflow: async(dealId, data) =>
    axios.post(`${Config.DEALFLOW_API_URL}private/${dealId}/share`, data),

  requestKPIs: async(dealId) =>
    axios.post(`${Config.DEALFLOW_API_URL}private/${dealId}/request-kpis`),

  getSharedDeal: async(dealId) => axios.get(`${Config.DEALFLOW_API_URL}shared/${dealId}`),

  saveRequestedKPIs: (data) =>
    axios.post(`${Config.DEALFLOW_API_URL}additional-information/kpis`, data),

  getRequestedAdditionalInformation: (dealId) =>
    axios.get(`${Config.DEALFLOW_API_URL}additional-information?deal=${dealId}`),

  checkAccessToRequestedKPIs: (dealId) =>
    axios.get(`${Config.DEALFLOW_API_URL}additional-information/kpis/check-access?deal=${dealId}`),

  checkAccessToRequestedAdditionalInformation: (formId, dealId) =>
    axios.get(
      `${Config.DEALFLOW_API_URL}additional-information/forms/check-access?deal=${dealId}&form=${formId}`,
    ),
  addToPortfolio: async(dealId) =>
    axios.post(`${Config.DEALFLOW_API_URL}private/${dealId}/portfolio`),
};
