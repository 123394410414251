import { useTranslation } from "react-i18next";
import { Input } from "../../ui/atoms/Input";
import { Loader } from "components/ui/molecules/Loader";
import Select from "components/ui/atoms/Select";
import { TextArea } from "components/ui/atoms/TextArea";
import { TrafficLight } from "components/ui/atoms/TrafficLight";

export const StartupEditCustomFields = ({
  dealflowCustomFields,
  customFields,
  onChange,
  loading,
  customFieldsRequired,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {customFields.map((customField) => (
          <div key={customField._id}>
            {(customField.type === "select" || customField.type === "multiselect") && (
              <Select
                error={
                  customFieldsRequired.current &&
                  !dealflowCustomFields[customField._id] && { message: t("required") }
                }
                className="questionnaire-select"
                id={customField._id}
                required={customField.required}
                name={customField._id}
                multiSelect={customField.type === "multiselect"}
                label={customField.name}
                isClearable={!customField.required}
                placeholder={t("select_answer")}
                onSelect={(opts) => {
                  onChange(customField._id, opts);
                }}
                initialValues={dealflowCustomFields[customField._id] || []}
                items={customField.options.map((option, index) => ({
                  id: option._id,
                  value: option.value,
                }))}
              />
            )}
            {customField.type === "short_answer" && (
              <Input
                error={
                  customFieldsRequired.current &&
                  !dealflowCustomFields[customField._id] && { message: t("required") }
                }
                value={dealflowCustomFields[customField._id]}
                name={customField.name}
                required={customField.required}
                placeholder={customField.name}
                label={customField.name}
                onChange={(e) => onChange(customField._id, e.target.value)}
              />
            )}
            {customField.type === "long_answer" && (
              <TextArea
                value={dealflowCustomFields[customField._id]}
                error={
                  customFieldsRequired.current &&
                  !dealflowCustomFields[customField._id] && { message: t("required") }
                }
                required={customField.required}
                label={customField.name}
                onChange={(e) => onChange(customField._id, e.target.value)}
                type="text"
                placeholder={t("type_answer")}
                row={8}
                cols={8}
                style={{ resize: "none" }}
              />
            )}
            {customField.type === "traffic_light" && (
              <>
                <label className="block mb-1 text-left text-xs font-medium text-black">
                  {customField.name}
                </label>
                <TrafficLight
                  identifier={customField._id}
                  onClick={(value) => onChange(customField._id, value)}
                  value={
                    dealflowCustomFields[customField._id] !== null
                      ? parseInt(dealflowCustomFields[customField._id])
                      : 0
                  }
                />
              </>
            )}
          </div>
        ))}
      </div>
      {loading && <Loader />}
    </>
  );
};
