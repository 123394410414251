import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { RoutesLinks } from "components/routes-links";
import { useToasts } from "react-toast-notifications";
import { Loader } from "components/ui/molecules/Loader";
import { SharedEventFooter } from "components/events/components/event/components/SharedEventFooter";
import { FaArrowLeft, FaExclamationCircle, FaFileDownload, FaPen } from "react-icons/fa";
import moment from "moment";
import { Card } from "components/ui/atoms/Card";
import Config from "config";

import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { useSwal } from "hooks/useSwal";
import FormsService from "services/forms-service";
import { PublicForm } from "components/public-form/PublicForm";
import FormUtils from "utils/form-utils";
import { PublicQuestionnaire } from "components/public-questionnaire/PublicQuestionnaire";
import { useAsyncState } from "hooks/useAsyncState";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { DealflowService } from "services/dealflow-service";

export const RequestedForm = () => {
  const queryParams = new URLSearchParams(location.search);
  const [dealId, setDealId] = useState(null);
  const [applicationId, setApplicationId] = useState(null);

  const wrapperElement = useRef(null);
  const { formId } = useParams();
  const { t } = useTranslation();
  const { addToast } = useToasts();
const navigate = useNavigate();
  // const [startups, setStartups] = useState([])
  const [loading, setLoading] = useState(false);
  const [deal, setDeal] = useState(null);

  const { confirm } = useSwal();
  const [authorized, setAuthorized] = useState(false);
  const [form, setForm] = useState(null);
  const [application, setApplication] = useState(null);
  const [errorRetrievingForm, setErrorRetrievingForm] = useState(false);
  const [formNotFound, setFormNotFound] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [questionErrors, setQuestionErrors] = useAsyncState({});
  const [cbFinish, setCbFinish] = useState(false);
  const [resultCbFinish, setResultCbFinish] = useState(false);
  const [confirmRetries, setConfirmRetries] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [openDate, setOpenDate] = useState(null);

  useEffect(() => {
    if (resultCbFinish !== null) {
      setCbFinish(false);
    }
  }, [resultCbFinish]);

  const [formInfo, setFormInfo] = useState(null);
  const checkAccess = async () => {
    try {
      setLoading(true);
      const result = await DealflowService.checkAccessToRequestedAdditionalInformation(
        formId,
        dealId,
        applicationId,
      );
      setAuthorized(true);
      setApplication(result.data.data.application);
      delete result.data.data.application;
      setFormInfo(result.data.data);
    } catch (error) {
      console.error(error);
      addToast(
        t(
          error?.response?.status === 403
            ? "forbidden_access_to_form"
            : "error_occurred_checking_access",
        ),
        {
          appearance: "error",
          autoDismiss: true,
        },
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!queryParams.get("deal")) {
      addToast(t("forbidden_access_to_form"), {
        appearance: "error",
        autoDismiss: true,
      });
      navigate(RoutesLinks.LOGIN_LINK);
    } else {
      setDealId(queryParams.get("deal"));
      setApplicationId(queryParams.get("application"));
    }
  }, [queryParams]);

  useEffect(() => {
    if (dealId) {
      checkAccess();
    }
  }, [dealId]);

  const getForm = () => {
    setLoading(true);

    FormsService.getPublishedForm(formId, true)
      .then((result) => {
        if (result.data.data) {
          setForm(result.data.data);
          setQuestions(result.data.data.questions);
        } else {
          setFormNotFound(true);
        }
      })
      .catch(() => {
        setErrorRetrievingForm(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (authorized) {
      setOpenDate(moment());
      getForm();
    }
  }, [authorized]);

  const confirmFinishForm = () => {
    const haveAnswers = Object.keys(answers).filter((key) => answers[key] !== null).length > 0;

    if (!haveAnswers) {
      alert({ text: t("form_no_answers"), icon: "error" });
      return;
    }

    confirm({ text: t("confirm_submit_shared_form_text") }).then((isConfirmed) => {
      if (isConfirmed) {
        setLoading(true);

        const parsedQuestions = [];

        questions.forEach((question) => {
          let answer = answers[question.question_id._id];

          if (question.question_id.type === "questions_group") {
            answer = answers[question.question_id._id]
              ? answers[question.question_id._id].join("|__|")
              : [].join("|__|");

            if (answers[question.question_id._id]) {
              answers[question.question_id._id].forEach((q, index) => {
                if (q && q.constructor.name === "Object") {
                  answers[question.question_id._id][index] = `${q._id}-|-${q.name}`;
                } else {
                  answers[question.question_id._id][index] = q;
                }
              });
              answer = answers[question.question_id._id].join("|__|");
            } else {
              answer = [].join("|__|");
            }
          }
          if (question.question_id.type.includes("file")) {
            answer = answers[question.question_id._id]
              ? answers[question.question_id._id]._id
              : null;
          }

          parsedQuestions.push({
            question: { ...question.question_id },
            answer: `${answer || ""}`.trim(),
          });
        });

        FormsService.saveAutomatedFormApplication(
          formId,
          {
            deal: dealId,
            questionnarieResponse: applicationId,
            application: {
              seconds_lapsed: openDate ? moment().diff(openDate, "seconds") : null,
              questions: parsedQuestions,
            },
          },
          true,
        )
          .then(() => {
            setIsSubmitted(true);
            localStorage.removeItem(`kiota-questionnaire-${formId}`);
            setLoading(false);
          })
          .catch(() => {
            addToast(t("error_occurred"), { appearance: "error", autoDismiss: true });
            setCbFinish(false);
          })
          .finally(() => {
            setLoading(false);
            setConfirmRetries(confirmRetries + 1);
          });
      } else {
        setCbFinish(false);
      }
    });
  };

  useEffect(() => {
    if (formInfo) {
      const localAnswers = JSON.parse(localStorage.getItem(`kiota-questionnaire-${formId}`));
      if (!localAnswers && application) {
        const previousAnswers = {};
        application.questions.forEach((question) => {
          previousAnswers[question.question._id] = question.answer;
        });
        setAnswers(
          FormUtils.getInitialAnswers(
            previousAnswers,
            application.questions.map((q) => {
              return { ...q, question_id: q.question };
            }),
          ),
        );
      }
    }
  }, [application, formInfo]);

  return (
    <div className="flex flex-col min-h-screen" ref={wrapperElement}>
      {loading && <Loader />}
      <section className="px-8 my-8 flex-1">
        {authorized && form && questions.length !== 0 && (
          <>
            <h1 className={`text-main text-4xl border-b border-gray-lines`}>
              <FaArrowLeft
                className="inline w-6 cursor-pointer mr-2"
                onClick={() =>
                  navigate(`${RoutesLinks.REQUESTED_INFORMATION_LINK}?deal=${dealId}`)
                }
              />
              {form?.name || t("loading")}
            </h1>
            {form && questions.length !== 0 && (
              <>
                <PublicQuestionnaire
                  isSubmitted={isSubmitted}
                  answers={answers}
                  setAnswers={setAnswers}
                  isDraft={false}
                  isPreview={false}
                  questionnaireId={formId}
                  questions={questions}
                  visibilityMode={form.visibility_mode || "single_page"}
                  languages={form.languages || ["en", "es"]}
                  questionErrors={questionErrors}
                  setQuestionErrors={setQuestionErrors}
                  setLoading={setLoading}
                  cbFinish={cbFinish}
                  setResultCbFinish={setResultCbFinish}
                  confirmFinishForm={confirmFinishForm}
                  investorId={form.investor._id}
                  shared={true}
                />
                {!isSubmitted && (
                  <div className="max-w-screen-sm mx-auto px-2 lg:px-8 flex flex-row mt-4 mb-6 justify-end">
                    <ButtonMain
                      width={"full lg:w-1/2"}
                      onClick={() => setCbFinish(true)}
                      text={t("submit")}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
        {(!authorized || errorRetrievingForm || formNotFound) && (
          <Card
            wrapperClassName={`bg-main mt-24 max-w-screen-sm mx-auto`}
            containerClassName={`text-center text-lg my-6 text-main`}
          >
            <div>
              <FaExclamationCircle className="inline-block mb-6" size={70} />
            </div>
            <p>
              {t(
                errorRetrievingForm
                  ? "error_occurred_retrieving_form"
                  : formNotFound
                    ? "form_not_found"
                    : "forbidden_access_to_form",
              )}
            </p>
          </Card>
        )}
      </section>
      <SharedEventFooter />
    </div>
  );
};
