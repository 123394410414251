import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { useSwal } from "hooks/useSwal";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import ReportsService from "services/reports-service";

const ApplicationActions = ({ event, deal, selectedJudges, setLoading, handleClose }) => {
  const { t } = useTranslation();

  const { addToast } = useToasts();

  const { confirm } = useSwal();

  const generateReport = async() => confirm({
    title: t("download_evaluation"),
  }).then(async(isConfirmed) => {
    if (isConfirmed) {
      try {
        setLoading(true);
        await ReportsService.generateEvaluationReport(selectedJudges, deal, event);
      } catch (error) {
        addToast(t("error_occurred_downloading_evaluation"), {
          appearance: "error",
          autoDismiss: true,
        });
      } finally {
        setLoading(false);
      }
    }
  });

  const sendEmail = async(template) => confirm({
    title: t("send_evaluation_email"),
  }).then(async(isConfirmed) => {
    if (isConfirmed) {
      try {
        setLoading(true);
        await ReportsService.sendEvaluationReport(selectedJudges, deal, event, template);
        addToast(t("success_sending_evaluation"), {
          appearance: "success",
          autoDismiss: true,
        });
        handleClose();
      } catch (error) {
        addToast(t("error_occurred_downloading_evaluation"), {
          appearance: "error",
          autoDismiss: true,
        });
      } finally {
        setLoading(false);
      }
    }
  });

  return (
        <div className="flex flex-col gap-y-3">
            <ButtonMain text={t("download_evaluation")} onClick={generateReport}/>
            <ButtonMain text={t("send_deal_rejected_email")} onClick={() => sendEmail("deal_rejected")}/>
            <ButtonMain text={t("send_deal_standby_email")} onClick={() => sendEmail("deal_standby")}/>
            <ButtonMain text={t("send_deal_continue_email")} onClick={() => sendEmail("deal_continue")}/>
        </div>
  );
};

export default ApplicationActions;
