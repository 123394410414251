import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import "./i18n";
import { createRoot } from "react-dom/client";
// import * as serviceWorker from "./serviceWorker";
import AppRouter from "./components/routes";
import PersistGateProvider from "utils/providers/persist-gate-provider";
import ReduxProvider from "utils/providers/redux-provider";
import MsalProviderWrapper from "utils/providers/msal-provider";
import HotjarProvider from "utils/providers/hotjar-provider";
import SentryProvider from "utils/providers/sentry-provider";
import ScrollbarProvider from "utils/providers/scrollbar-provider";
import { BrowserRouter } from "react-router-dom";
import StyleProvider from "utils/providers/style-provider";

library.add(fab, fas);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <SentryProvider>
    <HotjarProvider>
    <MsalProviderWrapper>
      <ReduxProvider>
        <PersistGateProvider>
          <BrowserRouter>
            <ScrollbarProvider>
              <StyleProvider>
                <AppRouter />
              </StyleProvider>
            </ScrollbarProvider>
          </BrowserRouter>
        </PersistGateProvider>
      </ReduxProvider>
    </MsalProviderWrapper>
    </HotjarProvider>
  </SentryProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
