import Avatar from "react-avatar";
import { useTranslation, Trans } from "react-i18next";

import moment from "moment";
import { BsArrowDownCircle, BsArrowUp } from "react-icons/bs";
import { CgArrowsExchangeV } from "react-icons/cg";
import { FaExchangeAlt, FaPlus, FaTrash } from "react-icons/fa";

import { SidePanel } from "components/ui/objects/SidePanel";
import { Card } from "components/ui/atoms/Card";

export const StageRecordSection = ({ onClose, record = [] }) => {
  const { t } = useTranslation();

  const getEvent = (event) => {
    return (
      <div className="w-full my-4">
        <Card borderColor={event.event === "delete_startup" ? "red" : "green"}>
          <div className="flex justify-between w-full">
            <div className="w-5/12">
              <div className="flex">
                <div>{`${t("startup")}: `}</div>
                <div className="ml-1 font-semibold">{event.dealflow.name}</div>
              </div>
              <div className="flex mt-2">
                <div>{`${t("date")}:`}</div>
                <div className="ml-1 font-semibold">
                  {moment(event.createdAt).format("YYYY-MM-DD HH:MM")}
                </div>
              </div>
            </div>
            <div className="w-5/12">
              <div className="flex">
                <div>
                  {event.event !== "delete_startup" ? `${t("added_by")}: ` : `${t("deleted_by")}: `}
                </div>
                <div className="ml-1 font-semibold">
                  {event.user ? `${event.user.name} ${event.user.last_name}` : t("automation")}
                </div>
              </div>
              {event.event !== "delete_startup" && (
                <div className="flex mt-2">
                  <div>{`${t("origin")}:`}</div>
                  <div className="ml-1 font-semibold">{event.origin_column_name || "-"}</div>
                </div>
              )}
            </div>
          </div>
        </Card>
      </div>
    );
    if (event.event === "add_startup") {
      if (event.user) {
        return (
          <div className={"flex justify-between"}>
            <div className="w-full pr-2 text-sm">
              <FaPlus className="inline w-10 h-10 p-2 mr-2 border rounded-full" />
              <Avatar
                className="mr-2"
                src={event.user.image}
                name={`${event.user.name} ${event.user.last_name}`}
                alt={`${event.user.name} ${event.user.last_name}`}
                maxInitials={3}
                color="#e0e6f2"
                size="20"
                fgColor="#4d70b3"
                round={true}
              />
              <Trans
                i18nKey="dealflow_stage_record_type_add"
                values={{
                  user: `${event.user.name} ${event.user.last_name}`,
                  stage: event.column_name,
                  deal: event.dealflow ? event.dealflow.name : event.dealflow_name,
                }}
                components={{ bold: <strong /> }}
              />
            </div>
            <div className="flex flex-row items-center justify-center w-4/12 px-2 text-xs border-l">
              {moment(event.createdAt).format("YYYY-MM-DD HH:MM")}
            </div>
          </div>
        );
      } else {
        return (
          <div className={"flex justify-between"}>
            <div className="w-full pr-2 text-sm">
              <FaPlus className="inline w-10 h-10 p-2 mr-2 border rounded-full" />
              <Trans
                i18nKey="dealflow_stage_record_type_add_automatically"
                values={{
                  stage: event.column_name,
                  deal: event.dealflow ? event.dealflow.name : event.dealflow_name,
                }}
                components={{ bold: <strong /> }}
              />
            </div>
            <div className="flex flex-row items-center justify-center w-4/12 px-2 text-xs border-l">
              {moment(event.createdAt).format("YYYY-MM-DD HH:MM")}
            </div>
          </div>
        );
      }
    } else if (event.event === "delete_startup") {
      return (
        <div className={"flex justify-between"}>
          <div className="w-full pr-2 text-sm">
            <FaTrash className="inline w-10 h-10 p-2 mr-2 border rounded-full" />
            <Avatar
              className="mr-2"
              src={event.user.image}
              name={`${event.user.name} ${event.user.last_name}`}
              alt={`${event.user.name} ${event.user.last_name}`}
              maxInitials={3}
              color="#e0e6f2"
              size="20"
              fgColor="#4d70b3"
              round={true}
            />
            <Trans
              i18nKey="dealflow_stage_record_type_delete"
              values={{
                user: `${event.user.name} ${event.user.last_name}`,
                stage: event.column_name,
                deal: event.dealflow ? event.dealflow.name : event.dealflow_name,
              }}
              components={{ bold: <strong /> }}
            />
          </div>
          <div className="flex flex-row items-center justify-center w-4/12 px-2 text-xs border-l">
            {moment(event.createdAt).format("YYYY-MM-DD HH:MM")}
          </div>
        </div>
      );
    } else if (event.event === "sort_startup") {
      return (
        <div className={"flex justify-between"}>
          <div className="w-full pr-2 text-sm">
            <CgArrowsExchangeV className="inline w-10 h-10 p-2 mr-2 border rounded-full" />
            <Avatar
              className="mr-2"
              src={event.user.image}
              name={`${event.user.name} ${event.user.last_name}`}
              alt={`${event.user.name} ${event.user.last_name}`}
              maxInitials={3}
              color="#e0e6f2"
              size="20"
              fgColor="#4d70b3"
              round={true}
            />
            <Trans
              i18nKey="dealflow_stage_record_type_sort"
              values={{
                user: `${event.user.name} ${event.user.last_name}`,
                stage: event.column_name,
                deal: event.dealflow ? event.dealflow.name : event.dealflow_name,
                index: event.index,
                old_index: event.old_index,
              }}
              components={{ bold: <strong /> }}
            />
          </div>
          <div className="flex flex-row items-center justify-center w-4/12 px-2 text-xs border-l">
            {moment(event.createdAt).format("YYYY-MM-DD HH:MM")}
          </div>
        </div>
      );
    } else {
      return (
        <div className={"flex justify-between"}>
          <div className="w-full pr-2 text-sm">
            <FaExchangeAlt className="inline w-10 h-10 p-2 mr-2 border rounded-full" />
            <Avatar
              className="mr-2"
              src={event.user.image}
              name={`${event.user.name} ${event.user.last_name}`}
              alt={`${event.user.name} ${event.user.last_name}`}
              maxInitials={3}
              color="#e0e6f2"
              size="20"
              fgColor="#4d70b3"
              round={true}
            />
            <Trans
              i18nKey="dealflow_stage_record_type_move"
              values={{
                user: `${event.user.name} ${event.user.last_name}`,
                origin: event.origin_column_name,
                destination: event.column_name,
                deal: event.dealflow ? event.dealflow.name : event.dealflow_name,
              }}
              components={{ bold: <strong /> }}
            />
          </div>
          <div className="flex flex-row items-center justify-center w-4/12 px-2 text-xs border-l">
            {moment(event.createdAt).format("YYYY-MM-DD HH:MM")}
          </div>
        </div>
      );
    }
  };

  return (
    <SidePanel width={"2/5"} onClose={onClose} title={t("board_record_section")}>
      {record
        .filter(
          (item) =>
            item.event === "add_startup" ||
            item.event === "move_startup" ||
            item.event === "delete_startup",
        )
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .map((r, i) => {
          return (
            <div key={r._id} className="">
              {getEvent(r)}
              {record.length > 1 &&
                i <
                  record.filter(
                    (item) =>
                      item.event === "add_startup" ||
                      item.event === "move_startup" ||
                      item.event === "delete_startup",
                  ).length -
                    1 && (
                  <div className="flex justify-between">
                    <div className="flex flex-row items-center justify-center w-5/12"></div>
                    <div className="flex justify-center w-2/12">
                      <BsArrowUp className="w-8 h-8" />
                    </div>
                    <div className="flex flex-row items-center justify-center w-5/12"></div>
                  </div>
                )}
            </div>
          );
        })}
    </SidePanel>
  );
};
