const DEFAULT_LANG = "en";

const TranslateUtils = {
  getTextByLang: (lang, obj) => {
    if (!obj) {
      return "";
    }
    if (obj[lang]) {
      return obj[lang];
    } else if (obj[DEFAULT_LANG]) {
      return obj[DEFAULT_LANG];
    } else {
      const langs = Object.keys(obj);

      for (const l of langs) {
        if (obj[l]) {
          return obj[l];
        }
      }
    }

    return "";
  },
};

export default TranslateUtils;
