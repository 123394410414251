import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import { PageTitle } from "components/ui/atoms/PageTitle";
import { SectionHeader } from "components/ui/molecules/SectionHeader";
import useUser from "hooks/useUser";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaFilter, FaPlus } from "react-icons/fa";
import { Outlet } from "react-router-dom";

const ContactsView = () => {
  const SECTIONS = [{ id: "my_contacts", title: "my_contacts" }];
  const [section, setSection] = useState("my_contacts");
  const [filtersCount, setFiltersCount] = useState(0);

  const { t } = useTranslation();

  const { user, checkIfLPhasAccessToSection } = useUser();

  const [pageTitle, setPageTitle] = useState(null);

  const pageRef = useRef();

  return (
    <>
        <div className="flex items-start">
        <div className="flex-1">
          {pageTitle && (<PageTitle title={pageTitle} removeMargin={true} />)}
        </div>
        {section === "my_contacts" && !user.lp && (
          <ButtonMain
            marginRight={2}
            iconComponent={<FaPlus className="inline-block mr-2" />}
            text={t("register_contact")}
            onClick={() => pageRef.current.showRegisterIRMInvestor()}
          />
        )}
        {section === "my_contacts" &&
          (!user.lp || (user.lp && checkIfLPhasAccessToSection("contacts"))) && (
            <div>
              <ButtonSecondary
                iconComponent={<FaFilter className="h-4" />}
                onClick={() => pageRef.current.showFilters()}
                badgeBgColor="red"
                badgeContent={filtersCount > 0 && filtersCount}
              />
            </div>
        )}
        </div>
        <SectionHeader
            sectionTitles={SECTIONS.map((sec) => t(sec))}
            sectionKeys={SECTIONS}
            setSection={setSection}
            section={section}
          />
        <Outlet context={{ setPageTitle, setFiltersCount, ref: pageRef }}/>
    </>
  );
};

export default ContactsView;
