import { useOutletContext } from "react-router-dom";

const CompanyChildrenSettingsView = () => {
  const { investor } = useOutletContext();

  return (
    <></>
  );
};

export default CompanyChildrenSettingsView;
