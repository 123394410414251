import { useTranslation } from "react-i18next";
import { Input } from "components/ui/atoms/Input";
import SelectCurrency from "components/ui/atoms/SelectCurrency";
import Config from "config";
import { useEffect, useState } from "react";

export const QuestionTypeFinancial = ({
  currentQuestion,
  onChange,
  validateQuestion,
  value,
  setFormStarted,
}) => {
  const { t } = useTranslation();

  const unitOptions = [
    { id: "k", value: "K" },
    { id: "mm", value: "MM" },
  ];
  const [unitSelected, setUnitSelected] = useState(null);
  const [parsedValue, setParsedValue] = useState("");

  useEffect(() => {
    if (value !== null) {
      setUnitSelected(value !== "" ? value.toString().split("-")[1] : "k");
    }
    setParsedValue(value !== null ? value.toString().split("-")[0] : "");
  }, [value]);

  return (
    <>
      <div className="w-full">
        <div className="flex items-start">
          <div className={`${!unitSelected ? "w-5/12" : "w-10/12"}`}>
            <input
              value={parsedValue}
              onChange={(e) => {
                if (e.target.value !== "") {
                  onChange(`${e.target.value}-${unitSelected}`);
                } else {
                  onChange(null);
                }
              }}
              name={currentQuestion.question_id._id}
              type={"number"}
              step="any"
              placeholder={t("type_answer")}
              required={false}
              className={
                "shadow-soft-white border border-gray-lines focus:border-main bg-input w-full py-3 px-7 mb-2 sm:mb-5 relative z-10 text-left text-xs font-normal rounded-l-2xl placeholder-gray cursor-pointer transition-all duration-200 outline-none hover:border-main hover:outline-none hover:shadow-inner focus:outline-none focus:shadow-focus active:outline-none"
              }
            />
          </div>
          <div className={`${unitSelected ? "w-2/12" : "w-7/12"}`}>
            {unitSelected && (
              <SelectCurrency
                name={currentQuestion.question_id._id}
                items={unitOptions}
                multiSelect={false}
                isClearable={false}
                showQuantity={false}
                initialValues={unitOptions.filter((i) => unitSelected === i.id) || []}
                onSelect={(selection) => {
                  if (parsedValue !== "") {
                    onChange(`${parsedValue}-${selection.length ? selection[0].id : "k"}`);
                  }
                  setUnitSelected(selection.length ? selection[0].id : "k");
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
