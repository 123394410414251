import { useTranslation } from "react-i18next";

export const DealflowOwners = ({ owners }) => {
  const { t } = useTranslation();

  if (!owners || !owners.length) {
    return (
      <span className="inline-block mr-3 mt-1 font-semibold text-xxs px-2 py-0.5 text-gray-300">
        {t("no_owners")}
      </span>
    );
  }

  return (
    <div className="flex gap-2">
      {owners.map((owner) => (
          <span
            key={owner._id}
            className="inline-block mr-2 mt-1 font-semibold text-xxs bg-bg-buttons-secondary px-2 py-0.5 rounded-md border border-border-buttons-secondary text-text-buttons-secondary hover:shadow-inner"
          >{`${owner.name} ${owner.last_name}`}</span>
      ))}
    </div>
  );
};
