import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FaCheckCircle, FaChevronLeft } from "react-icons/fa";

import { QuestionType } from "./components/QuestionType";
import { ButtonMain } from "components/ui/atoms/ButtonMain";

export const PublicQuestionnaireQuestion = ({
  currentQuestion,
  indexElement,
  currentIndex,
  goNext,
  goBack,
  answers,
  setAnswers,
  questionErrors,
  checkCurrentQuestionErrors,
  setLoading,
  setContainerHeight,
  questionsLength,
  visibilityMode,
  showInvestorsPercentage = false,
  language,
  someErrorId,
  expandedQuestionsErrors,
  sortedQuestions,
  formId,
  investorId,
  scoreCardId = null,
  shared,
}) => {
  const { t } = useTranslation();

  const [questionValidated, setQuestionValidated] = useState(false);
  const ref = useRef(null);

  const validateQuestion = async() => {
    setQuestionValidated(true);
    const value = answers[currentQuestion.question_id._id] || null;
    if (currentQuestion.question_id.type === "separator") {
      goNext();
    } else {
      const currentQuestionHasErrors = await checkCurrentQuestionErrors(currentQuestion, value);
      if (!currentQuestionHasErrors) {
        goNext();
      }
    }
  };

  const nextQuestion = () => {
    setQuestionValidated(false);

    setTimeout(() => {
      goNext();
    }, 100);
  };

  const previousQuestion = () => {
    setQuestionValidated(false);

    setTimeout(() => {
      goBack();
    }, 100);
  };

  const getClassNameByIndex = (indexElement) => {
    if (visibilityMode === "single_page") {
      return "";
    } else if (indexElement === currentIndex) {
      return "transition duration-1000 ease-in-out w-full absolute";
    } else if (indexElement < currentIndex) {
      return "transition duration-1000 ease-in-out w-full absolute -translate-x-full";
    } else {
      return "transition duration-1000 ease-in-out w-full absolute translate-x-full";
    }
  };

  useEffect(() => {
    setQuestionValidated(false);

    if (currentIndex === indexElement) {
      if (!answers[currentQuestion.question_id._id]) {
        setAnswers({ ...answers, [currentQuestion.question_id._id]: null });
      }

      setContainerHeight(ref.current.offsetHeight + 200);
      setTimeout(() => {
        document
          .querySelector(
            "input[id=\"" +
              currentQuestion.question_id._id +
              "\"], textarea[id=\"" +
              currentQuestion.question_id._id +
              "\"]",
          )
          ?.focus();
      }, 1000);
    }
  }, [currentIndex]);

  console.log(currentQuestion, indexElement, questionsLength);

  if (currentQuestion.next_question.length && questionsLength === indexElement) {
    return <></>;
  }

  return (
    <div
      ref={ref}
      className={`question-container ${getClassNameByIndex(indexElement)} ${questionErrors[currentQuestion?.question_id?._id] && questionValidated ? "animate-shake" : ""} pl-2 pr-8 md:pl-0 md:pr-0`}
    >
      <div className="max-w-screen-sm mx-auto">
        <div className="flex flex-row justify-center align-center">
          <div className="flex justify-center w-full">
            <div className="w-full">
              <QuestionType
                currentQuestion={currentQuestion}
                indexElement={indexElement}
                answers={answers}
                setAnswers={setAnswers}
                checkCurrentQuestionErrors={checkCurrentQuestionErrors}
                setLoading={setLoading}
                goNext={goNext}
                setQuestionValidated={setQuestionValidated}
                language={language}
                showInvestorsPercentage={showInvestorsPercentage}
                someErrorId={someErrorId}
                expandedQuestionsErrors={expandedQuestionsErrors}
                sortedQuestions={sortedQuestions}
                formId={formId}
                investorId={investorId}
                scoreCardId={scoreCardId}
                shared={shared}
              />

              {questionErrors[currentQuestion.question_id._id] && (
                <p className="text-xs text-red">
                  {questionErrors[currentQuestion.question_id._id]}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      {visibilityMode === "multiple_page" && (
        <div className="flex justify-between w-full max-w-screen-sm mx-auto">
          <div className="flex">
            {indexElement >= 1 && (
              <FaChevronLeft
                className={"w-8 h-8 hover:shadow-inner text-main rounded-lg p-1 cursor-pointer"}
                onClick={() => previousQuestion()}
              />
            )}
          </div>
          {currentQuestion && (
            <ButtonMain
              iconComponent={<FaCheckCircle className="inline-block mr-2" />}
              onClick={() => validateQuestion()}
              text={t("continue")}
              width="auto"
              verticalMargin="0"
            />
          )}
        </div>
      )}
    </div>
  );
};
