import React, { useMemo, useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { FaPlus } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Arrow, useLayer } from "react-laag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Skeleton } from "../atoms/skeletons/Skeleton";

const LoadingCellRenderer = () => (
  <div className="py-2">
    <Skeleton /> {/* Use your Skeleton component or any loading indicator */}
  </div>
);

function CustomHeader(props) {
  return props.header.title;
}

export const DataGrid = ({
  headers,
  data,
  actions = [],
  compact = false,
  bordered = false,
  stickyHeader = true,
  agPagination = false,
  stickyActions = true,
  wrapperClassName = "",
  actionsHeaderClassName = "",
  showAddColumn = false,
  flexColumn = true,
  loading = false,
  handleAddColumn = () => {},
}) => {
  const { t } = useTranslation();
  const [gridApi, setGridApi] = useState(null);

  const columnDefs = headers.map((header) => ({
    wrapHeaderText: true,
    filter: true,
    floatingFilter: true,
    suppressMenu: true,
    floatingFilterComponentParams: { suppressFilterButton: true },
    cellEditor: "agSelectCellEditor",
    autoHeaderHeight: true,
    headerComponent: (params) => <CustomHeader {...params} header={header} />,
    field: header.key,
    valueGetter: (params) => (header.render ? header.render(header.key, params.data) : params.data[header.key]),
    valueFormatter: (params) => {},
    cellRenderer: loading
      ? "loadingCellRenderer"
      : (props) => (header.render ? <div>{header.render(header.key, props.data)}</div> : <>{props.data[header.key]}</>),
    filterParams: {
      applyMiniFilterWhileTyping: true,
      textFormatter: (x) => {
        const value = JSON.stringify(x);
        const normalizedValue = value
          .toLowerCase()
          .replace(/\s/g, "")
          .replace(/[àáâãäå]/g, "a")
          .replace(/æ/g, "ae")
          .replace(/ç/g, "c")
          .replace(/[èéêë]/g, "e")
          .replace(/[ìíîï]/g, "i")
          .replace(/ñ/g, "n")
          .replace(/[òóôõö]/g, "o")
          .replace(/œ/g, "oe")
          .replace(/[ùúûü]/g, "u")
          .replace(/[ýÿ]/g, "y")
          .replace(/\W/g, "");
        return normalizedValue;
      },
    },
  }));

  const defaultColDef = useMemo(
    () => ({
      initialWidth: 200,
      wrapHeaderText: true,
      flex: flexColumn ? 1 : 0,
      autoHeaderHeight: true,
    }),
    []
  );

  if (actions && actions.length) {
    columnDefs.push({
      headerName: t("actions"),
      pinned: "right",
      width: 95,
      resizable: false,
      cellRenderer: (props) => {
        const row = props.data;

        return <ActionButtons row={row} actions={actions} />;
      },
    });
  }

  const gridOptions = {
    defaultColDef: {
      flex: 1,
      minWidth: 100,
      resizable: true,
    },
    wrapHeaderText: true,
    autoSizeStrategy: {
      type: "fitCellContents",
      autoSizeAllColumns: true,
    },
    frameworkComponents: {
      loadingCellRenderer: LoadingCellRenderer, // Register your custom loading cell renderer
    },
  };

  useEffect(() => {
    if (gridApi) {
      if (loading) {
        gridApi.showLoadingOverlay();
      } else {
        gridApi.hideOverlay();
      }
    }
  }, [loading, gridApi]);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  return (
    <div style={{ height: "100%", width: "100%" }} className="flex">
      <div className="ag-theme-alpine" style={{ height: 620, width: "100%" }}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={data}
          defaultColDef={defaultColDef}
          gridOptions={gridOptions}
          pagination={agPagination}
          paginationPageSize={12}
          onGridReady={onGridReady}
        />
      </div>

      {showAddColumn && (
        <div className="w-10 h-auto mr-4">
          <button
            onClick={handleAddColumn}
            className="border border-border-buttons-secondary text-xxs text-gray-dark flex items-center outline-none w-10 py-4 pl-4 rounded-r-2xl shadow-hover hover:shadow-inner bg-white focus:outline-none"
          >
            <FaPlus className="inline-block text-main" />
          </button>
        </div>
      )}
    </div>
  );
};

const ActionButtons = ({ row, actions }) => {
  const [isOpen, setOpen] = useState(false);

  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,
    onOutsideClick: () => setOpen(false),
    onDisappear: () => setOpen(false),
    overflowContainer: true,
    auto: true,
    placement: "bottom-end",
    triggerOffset: 12,
    containerOffset: 16,
    arrowOffset: 16,
  });

  return (
    <>
      <button
        {...triggerProps}
        onClick={() => setOpen(!isOpen)}
        className="bg-bg-buttons-secondary flex items-center justify-center border border-border-buttons-secondary focus:outline-none custom-circle hover:shadow-inner shadow-soft-white w-7 h-7 rounded-full ml-2 mt-2"
      >
        <FontAwesomeIcon className="text-text-buttons-secondary" icon={isOpen ? faMinus : faPlus} fontSize={8} />
      </button>

      {isOpen &&
        renderLayer(
          <div
            {...layerProps}
            style={{ ...layerProps.style, zIndex: 100 }}
            className="bg-white shadow-lg rounded-lg p-2 items-center"
          >
            {actions
              .filter((action) => (action.show ? action.show(row) : true))
              .map((action) => (
                <div key={action.id} className="p-1">
                  {action.id === "delete" ? (
                    <button
                      onClick={() => {
                        setOpen(false);
                        action.onClick && action.onClick(row);
                      }}
                      type="button"
                      className="py-2 px-4 text-xs font-semibold text-red placeholder-gray border-red rounded-2xl shadow-soft-white cursor-pointer transition-all duration-500 ease-in-out border hover:shadow-hover focus:outline-none hover:shadow-inner flex w-full gap-2 items-center justify-center"
                    >
                      {action.icon && <img src={action.icon} alt="Icon" className="inline mr-2 w-auto" />}
                      {action.buttonProps.iconComponent && action.buttonProps.iconComponent}
                      {action.tip}
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setOpen(false);
                        action.onClick && action.onClick(row);
                      }}
                      type="button"
                      className="py-2 px-4 text-xs font-semibold text-text-buttons-secondary placeholder-gray bg-bg-buttons-secondary rounded-2xl shadow-soft-white cursor-pointer transition-all duration-500 ease-in-out border border-border-buttons-secondary hover:bg-buttons-secondary-hover hover:text-buttons-secondary hover:shadow-hover focus:outline-none hover:shadow-inner flex w-full gap-2 items-center justify-center"
                    >
                      {action.icon && <img src={action.icon} alt="Icon" className="inline mr-2 w-auto" />}
                      {action.buttonProps.iconComponent && action.buttonProps.iconComponent}
                      {action.tip}
                    </button>
                  )}
                </div>
              ))}
            <Arrow {...arrowProps} />
          </div>
        )}
    </>
  );
};
