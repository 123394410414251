import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "./Input";
import { FaTimes } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import Avatar from "react-avatar";

export function Autocomplete({
  placeholder,
  inputStyle = null,
  isClearable = true,
  options,
  reference,
  error,
  label,
  onSelect,
  initialValues = null,
  reset,
  setReset,
  searchKey = "name",
  displayKey = "name",
  required = false,
  noOptionsText,
  displayLogo,
  ...inputProps
}) {
  const { t } = useTranslation();
  const [display, setDisplay] = useState(false);
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selected, setSelected] = useState(initialValues);
  const wrapperRef = useRef(null);

  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperRef;
    if (wrap && !wrap.contains(event.target)) {
      setDisplay(false);
    }
  };

  const setItem = (item) => {
    setDisplay(false);
    onSelect && onSelect(item);
    setSelected(item);
    setSearch("");
  };

  const filterOptions = () => (options || []).filter((item) => (item[searchKey] || "").toLowerCase().indexOf((search || "").toLowerCase()) > -1);

  const mustReplaceSelectionFromInitialValues = () => {
    // you must use reset and setReset to reset the selection
    if (!initialValues) {
      return false;
    }

    if (!selected) {
      return true;
    }

    return selected[searchKey] !== initialValues[searchKey];
  };

  const resetSelection = () => {
    setSelected(null);
    onSelect && onSelect(null);
    setSearch("");
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (mustReplaceSelectionFromInitialValues()) {
      setSelected(initialValues);
      onSelect && onSelect(initialValues);
      setSearch("");
    }
  }, [initialValues]);

  useEffect(() => {
    if (reset) {
      resetSelection();
      setReset && setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [selected, isClearable]);

  useEffect(() => {
    setSearchResults(search && search !== "" ? filterOptions() : options);
  }, [search, options]);

  return (
    <div ref={wrapperRef}>
      {(label || (selected && isClearable) || required) && (
      <label className="block mb-1 text-left text-xs font-medium text-black flex">
        {label}
        {required && <span className="text-red">&nbsp;*</span>}
        {selected && isClearable && (
          <span
            data-tip={t("reset")}
            className={"hover:underline text-main relative -top-[1px]"}
            onClick={(e) => {
              e.stopPropagation();
              resetSelection();
            }}
          >
            <FaTimes className="cursor-pointer inline-block ml-1" />
          </span>
        )}
      </label>
      )}

      <Input
        reference={reference}
        onClick={() => setDisplay(!display)}
        placeholder={placeholder}
        value={(!display && selected ? selected[displayKey] : search) || ""}
        autoComplete="off"
        onChange={(event) => setSearch(event.target.value)}
        className={
          "mb-0"
        }
        {...inputProps}
      />

      <div className="relative w-full">
        {display && (
          <div
            className={
              "w-full max-h-52 overflow-y-auto | -mt-4 py-3 px-3 | text-left text-xs font-normal absolute z-20 | rounded-2xl placeholder-gray shadow-hover bg-white | cursor-pointer transition-all duration-200 | outline-none hover:border-main hover:outline-none  active:outline-none focus:border-main focus:outline-none focus:shadow-focus"
            }
          >
            {searchResults.length > 0 &&
              searchResults.map((value, i) => (
                <ul key={i} tabIndex="0" onClick={() => setItem(value)}>
                  <li className={"py-1 px-2 font-normal hover:text-main flex gap-2 p-2 align-ce"}>
                    {displayLogo && (
                      <Avatar
                        src={value.logo}
                        name={value.name}
                        alt={value.name}
                        maxInitials={3}
                        className={"h-4 object-contain mb-2 max-w-10"}
                        // className={`h-full object-contain ${value.logo ? 'w-12' : ''}`}
                        color="#e0e6f2"
                        fgColor="#4d70b3"
                        round="5"
                      />
                    )}{" "}
                    <span>{displayKey ? value[displayKey] : value[searchKey]}</span>
                  </li>
                </ul>
              ))}

            {searchResults.length === 0 && (
              <ul tabIndex="0" onClick={() => setItem("")}>
                <li className="py-1 px-2 font-normal">{noOptionsText || t("no_options")}</li>
              </ul>
            )}
          </div>
        )}
      </div>

      {error && <div className="text-red relative -top-3 left-2 text-xxs">{error.message}</div>}
    </div>
  );
}
