import { useTranslation } from "react-i18next";
import { Modal } from "components/ui/molecules/Modal";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { ButtonSecondary } from "components/ui/atoms/ButtonSecondary";
import Config from "config";

export const FormSaveConfirmationModal = ({ show, onClose, onSave }) => {
  const { t } = useTranslation();

  return (
    <Modal showModal={show} onClose={onClose} showCloseModal={true} fixedWidth={"w-1/3"}>
      <h2>{t("save_draft")}</h2>
      <p className="mt-2">{t("save_draft_explanation")}</p>

      <div className={`flex justify-end mt-4 border-t pt-2 border-separator`}>
        <ButtonSecondary marginRight={"2"} text={t("save_draft")} onClick={() => onSave(false)} />
        <ButtonMain text={t("save_and_publish")} onClick={() => onSave(true)} />
      </div>
    </Modal>
  );
};
