import { useEffect, useState } from "react";

import { QuestionTypeGroup } from "./QuestionTypeGroup";
import { QuestionTypeSeparator } from "./QuestionTypeSeparator";
import { QuestionTypeInput } from "./QuestionTypeInput";
import { QuestionTypeTextArea } from "./QuestionTypeTextArea";
import { QuestionTypeSwitch } from "./QuestionTypeSwitch";
import { QuestionTypeSelect } from "./QuestionTypeSelect";
import { QuestionTypeFile } from "./QuestionTypeFile";
import { QuestionTypeLikert } from "./QuestionTypeLikert";
import { QuestionTypeImageSelector } from "./QuestionTypeImageSelector";
import { QuestionTypeFinancial } from "./QuestionTypeFinancial";
import TranslateUtils from "utils/translate-utils";
import { useTranslation } from "react-i18next";
import { Alert } from "components/ui/molecules/Alert";
import { QuestionTypeAutocomplete } from "./QuestionTypeAutocomplete";

export const QuestionType = ({
  showInvestorsPercentage = false,
  groupedQuestionId,
  groupedQuestionIndex,
  currentQuestion,
  indexElement,
  answers,
  setAnswers,
  checkCurrentQuestionErrors,
  setLoading,
  goNext,
  setQuestionValidated,
  language,
  someErrorId,
  expandedQuestionsErrors,
  sortedQuestions,
  formId,
  investorId,
  scoreCardId,
  shared,
}) => {
  const [value, setValue] = useState(null);

  const { t } = useTranslation();

  const validateQuestion = async() => {
    setQuestionValidated(true);
    const value = answers[currentQuestion.question_id._id] || null;
    if (currentQuestion.question_id.type === "separator") {
      goNext();
    } else {
      const currentQuestionHasErrors = await checkCurrentQuestionErrors(currentQuestion, value);
      if (!currentQuestionHasErrors) {
        goNext();
      }
    }
  };

  const onChange = (value) => {
    checkCurrentQuestionErrors(currentQuestion, value);
    setValue(value);
    const answeredQuestionId = groupedQuestionId || currentQuestion.question_id._id;

    let questionAnswer;

    if (groupedQuestionId) {
      questionAnswer = answers[answeredQuestionId] || null;

      if (!questionAnswer) {
        questionAnswer = [];
      }
      questionAnswer[groupedQuestionIndex] = value;
    } else {
      questionAnswer = value || null;
    }

    setAnswers({
      ...answers,
      [answeredQuestionId]: questionAnswer,
    });
  };

  const getFieldLabel = () => {
    let title = TranslateUtils.getTextByLang(
      language,
      currentQuestion.question_id.title,
    ).toString();
    const titleParametersRegex = /#answer_[a-zA-Z0-9]+#/g;

    if (titleParametersRegex.test(title)) {
      const found = title.match(titleParametersRegex);

      if (found) {
        found.forEach((item) => {
          const answerId = item.replace("#answer_", "").replace("#", "");
          title = title.replace(titleParametersRegex, answers[answerId]);
        });
      }
    }
    const questionsBeforeCurrentQuestion = sortedQuestions
      ? sortedQuestions.slice(0, indexElement)
      : [];
    const realQuestionsBeforeCurrentQuestion = questionsBeforeCurrentQuestion.filter(
      (q) => q.question_id.type !== "separator",
    ).length;
    return (
      <div
        className={`${expandedQuestionsErrors[currentQuestion.question_id._id] && "text-red"} w-full text-xl font-semibold ${currentQuestion.question_id.type === "questions_group" && "border-b border-gray-lines mb-4"}`}
      >
        {indexElement !== null &&
          !groupedQuestionId &&
          `${realQuestionsBeforeCurrentQuestion + 1} - `}
        {title}
        &nbsp;
        {currentQuestion.required ? <span className="text-red">*</span> : null}
      </div>
    );
  };

  useEffect(() => {
    if (groupedQuestionId && answers[groupedQuestionId]) {
      setValue(answers[groupedQuestionId][groupedQuestionIndex] || "");
    } else {
      setValue(answers[currentQuestion.question_id._id] || "");
    }
  }, [indexElement]);

  return (
    <div className="mb-6">
      {!currentQuestion.question_id.title[language] && (
        <Alert
          style={"info"}
          text={t(
            currentQuestion.question_id.type !== "separator"
              ? "question_not_configured_in_selected_language"
              : "section_not_configured_in_selected_language",
          )}
        />
      )}
      {currentQuestion.question_id.type !== "separator" && (
        <>
          {getFieldLabel()}

          <div
            className="mt-2 mb-6 text-xs font-regular text-gray"
            dangerouslySetInnerHTML={{
              __html: TranslateUtils.getTextByLang(
                language,
                currentQuestion.question_id.description,
              ),
            }}
          />
          {showInvestorsPercentage && currentQuestion.question_id.percentage && (
            <div className="mb-2 text-xxs text-gray">{`* ${t("percentage_investors", { percentage: currentQuestion.question_id.percentage })}`}</div>
          )}
        </>
      )}
      {currentQuestion.question_id.type === "questions_group" && (
        <QuestionTypeGroup
          showInvestorsPercentage={showInvestorsPercentage}
          indexElement={indexElement}
          currentQuestion={currentQuestion}
          answers={answers}
          setAnswers={setAnswers}
          checkCurrentQuestionErrors={checkCurrentQuestionErrors}
          setLoading={setLoading}
          goNext={goNext}
          setQuestionValidated={setQuestionValidated}
          language={language}
          expandedQuestionsErrors={expandedQuestionsErrors}
          investorId={investorId}
          formId={formId}
          scoreCardId={scoreCardId}
        />
      )}
      {currentQuestion.question_id.type === "separator" && (
        <QuestionTypeSeparator currentQuestion={currentQuestion} language={language} />
      )}

      {["short_answer", "number", "email", "phone", "url", "date"].includes(
        currentQuestion.question_id.type,
      ) && (
        <QuestionTypeInput
          currentQuestion={currentQuestion}
          onChange={onChange}
          value={value}
          validateQuestion={validateQuestion}
        />
      )}

      {["long_answer"].includes(currentQuestion.question_id.type) && (
        <QuestionTypeTextArea currentQuestion={currentQuestion} onChange={onChange} value={value} />
      )}

      {currentQuestion.question_id.type === "boolean" && (
        <QuestionTypeSwitch currentQuestion={currentQuestion} onChange={onChange} value={value} />
      )}

      {["select", "multiselect"].includes(currentQuestion.question_id.type) && (
        <QuestionTypeSelect
          currentQuestion={currentQuestion}
          onChange={onChange}
          value={value}
          language={language}
        />
      )}

      {["file_image", "file_pdf", "file_excel", "file"].includes(
        currentQuestion.question_id.type,
      ) && (
        <QuestionTypeFile
          currentQuestion={currentQuestion}
          onChange={onChange}
          value={value}
          setLoading={setLoading}
          formId={formId}
          investorId={investorId}
          scoreCardId={scoreCardId}
          shared={shared}
        />
      )}

      {currentQuestion.question_id.type === "likert_scale" && (
        <QuestionTypeLikert
          currentQuestion={currentQuestion}
          onChange={onChange}
          value={value}
          language={language}
        />
      )}

      {currentQuestion.question_id.type === "image_selector" && (
        <QuestionTypeImageSelector
          currentQuestion={currentQuestion}
          onChange={onChange}
          value={value}
        />
      )}

      {currentQuestion.question_id.type === "financial" && (
        <QuestionTypeFinancial
          currentQuestion={currentQuestion}
          onChange={onChange}
          value={value}
          language={language}
        />
      )}

      {currentQuestion.question_id.type === "autocomplete" && (
        <QuestionTypeAutocomplete
          currentQuestion={currentQuestion}
          onChange={onChange}
          value={value}
          language={language}
        />
      )}
    </div>
  );
};
