import React, { useEffect, useState } from "react";
import { Card } from "../../ui/atoms/Card";

import { DataGrid } from "../../ui/objects/DataGrid";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Input } from "../../ui/atoms/Input";
import { useToasts } from "react-toast-notifications";
import { useSwal } from "../../../hooks/useSwal";
import { Loader } from "../../ui/molecules/Loader";
import Roles from "../../../assets/json/roles.json";
import Select from "../../ui/atoms/Select";
import Avatar from "react-avatar";
import Linkedin from "assets/images/linkedin.png";
import FormUtils from "utils/form-utils";
import InputFile from "components/ui/atoms/input-files";
import { ButtonMain } from "components/ui/atoms/ButtonMain";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import { DealflowService } from "services/dealflow-service";
import moment from "moment";
import { SidePanel } from "components/ui/objects/SidePanel";
import { ButtonDanger } from "components/ui/atoms/ButtonDanger";

export const DealEditTeamMembers = ({ startup, onChange, loading, setLoading }) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { confirm } = useSwal();
  const [teamMembers, setTeamMembers] = useState([]);
  const [showTeamMemberSection, setShowTeamMemberSection] = useState(false);
  const [memberToEdit, setMemberToEdit] = useState(null);
  const [roles, setRoles] = useState([]);

  const { register, handleSubmit, errors, setValue, reset, trigger, getValues, watch } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string().required(t("required_field")),
        last_name: Yup.string().nullable(),
        role: Yup.string().nullable(),
        percentage: Yup.number()
          .typeError(t("incorrect_format"))
          .transform((_, val) => (val === `${Number(val)}` ? Number(val) : null))
          .nullable()
          .min(0)
          .max(100),
        image: Yup.string().nullable(),
        linkedin: Yup.string().nullable(),
        incorporation_date: Yup.string()
          .nullable()
          .transform((value) => FormUtils.parseDateFromString(value, "YYYY-MM-DD HH:mm")),
        email: Yup.string().notOneOf(
          teamMembers
            .filter((tm) => tm.email)
            .map((s) => s.email)
            .filter((email) => {
              if (!memberToEdit) { return true; }
              return !email || (email && email !== memberToEdit.email);
            }),
          t("startup_member_already_invited"),
        ),
      }),
      { abortEarly: false },
    ),
    criteriaMode: "all",
    reValidateMode: "all",
    mode: "all",
    shouldUnregister: false,
  });

  const showAddTeamMemberSection = () => {
    setShowTeamMemberSection(true);
  };

  const showEditTeamMemberSection = (member) => {
    setMemberToEdit(member);
    setShowTeamMemberSection(true);
    setValue("image", member.image);
    setValue("email", member.email);
    setValue("name", member.name);
    setValue("last_name", member.last_name);
    setValue("role", member.role?._id);
    setValue("percentage", member.percentage);
    setValue("linkedin", member.linkedin);
    if (member.incorporation_date) {
      setValue(
        "incorporation_date",
        FormUtils.getDateInputValueFromDate(new Date(member.incorporation_date)),
      );
    }
    trigger();
  };

  const closeTeamMemberSection = () => {
    setShowTeamMemberSection(false);
    setMemberToEdit(null);
    reset();
    trigger();
  };

  const onSelectImage = async(file) => {
    const fileSizeMb = (file?.size || 0) / 1024 / 1024;

    if (fileSizeMb > 5) {
      addToast(t("error_file_size", { max_size: 5 }), {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }

    setLoading(true);

    setValue("image", file ? await FormUtils.fileToBase64(file) : null, { shouldValidate: true });
    trigger("image");

    setTimeout(() => setLoading(false), 500);
  };

  const onError = (error) => {
    addToast(
      error.response && error.response.data.msg_key
        ? t(error.response.data.msg_key)
        : t("error_occurred"),
      {
        appearance: "error",
        autoDismiss: true,
      },
    );
  };

  const onSuccess = (messageKey) => {
    closeTeamMemberSection();
    onChange && onChange();
    addToast(t(messageKey), {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const onSubmit = () => {
    handleSubmit(
      (formData) => {
        const restOfPercentage = teamMembers
          .filter((member) => (memberToEdit ? member._id !== memberToEdit._id : true))
          .reduce((acc, member) => acc + (member.percentage || 0), 0);

        if (restOfPercentage + (formData.percentage || 0) > 100) {
          addToast(t("percentage_overload"), {
            appearance: "error",
            autoDismiss: true,
          });

          return;
        }

        if (!memberToEdit) {
          confirm().then((isConfirmed) => {
            if (isConfirmed) {
              setLoading(true);
              DealflowService.addMember(startup._id, formData)
                .then(() => onSuccess("member_added_successfully"))
                .catch((error) => onError(error))
                .finally(() => setLoading(false));
            }
          });
        } else {
          setLoading(true);
          DealflowService.updateMember(startup._id, formData, memberToEdit._id)
            .then(() => onSuccess("member_updated_successfully"))
            .catch((error) => onError(error))
            .finally(() => setLoading(false));
        }
      },
      () => {
        addToast(t("form_field_error"), { appearance: "error", autoDismiss: true });
      },
    )();
  };

  const deleteMember = (member) => {
    confirm().then((isConfirmed) => {
      if (isConfirmed) {
        setLoading(true);
        DealflowService.deleteMember(startup._id, member._id)
          .then(() => onSuccess("member_deleted_successfully"))
          .catch((error) => onError(error))
          .finally(() => setLoading(false));
      }
    });
  };

  const getRoles = () => {
    const _roles = Roles.map((item) => ({ value: t(item.key), id: item._id }));
    setRoles(_roles);
  };

  const getRoleInitialValue = () => {
    if (watch().role) {
      const _role = roles.find((role) => role.id === watch().role);
      return _role ? [_role] : [];
    }

    if (memberToEdit && memberToEdit.role) {
      const _role = roles.find((role) => role.id === memberToEdit.role?._id);
      return _role ? [_role] : [];
    }

    return [];
  };

  useEffect(() => {
    if (startup) {
      setTeamMembers(startup.founders || []);
    }
  }, [startup]);

  useEffect(() => {
    register("role");
  }, [register()]);

  useEffect(() => {
    getRoles();
  }, []);

  const parseMember = (member) => ({
    name: member.last_name ? `${member.name} ${member.last_name}` : `${member.name}`,
    role: member.role ? member.role : null,
    percentage:
        member.percentage !== null && member.percentage !== undefined ? member.percentage : null,
    email: member.email ? member.email : null,
    image: member.image ? member.image : null,
    linkedin: member.linkedin ? member.linkedin : null,
    incorporation_date: member.incorporation_date ? member.incorporation_date : null,
    _id: member._id,
  });

  return (
    <>
      <div className="w-full mt-6 mb-8">
        <Card containerClassName="flex flex-col lg:flex-row lg:justify-between p-6 text-2">
          <div className="md:w-full lg:w-10/12">
            <h3 className={"text-main"}>{t("would_you_like_to_add_startup_members")}</h3>
          </div>
          <div className="md:w-full lg:w-2/12 justify-end flex text-center">
            <ButtonMain
              width="1/2 lg:w-full"
              text={t("add_startup_members")}
              onClick={() => showAddTeamMemberSection()}
            />
          </div>
        </Card>
      </div>

      {teamMembers.length !== 0 && (
        <DataGrid
          bordered={true}
          data={teamMembers.map((m) => parseMember(m))}
          headers={[
            {
              title: t("name"),
              key: "name",
              render: (key, member) => (
                  <div className="flex">
                    <Avatar
                      src={member.image}
                      size="30"
                      round={true}
                      color="#e0e6f2"
                      fgColor="#4d70b3"
                      alt={member.name}
                    />
                    <span className="ml-2 flex items-center">{`${member.name}`.trim()}</span>
                  </div>
              ),
            },
            {
              title: t("email"),
              key: "email",
              render: (key, member) => member.email || t("no_data"),
            },
            {
              title: t("role"),
              key: "role",
              render: (key, member) => (member.role ? t(member.role.key) : t("no_data")),
            },
            {
              title: t("equity"),
              key: "percentage",
              render: (key, member) => member.percentage !== null && member.percentage >= 0
                ? `${member.percentage}%`
                : t("no_data"),
            },
            {
              title: t("linkedin"),
              key: "linkedin",
              render: (key, member) => (
                  <div className="flex justify-center flex-row">
                    <a
                      target="_blank"
                      href={member.linkedin}
                      rel="noreferrer"
                      data-tip={!member.linkedin || member.linkedin === "" ? null : t("linkedin")}
                      className={
                        !member.linkedin || member.linkedin === ""
                          ? "opacity-25 filter grayscale"
                          : ""
                      }
                    >
                      <img src={Linkedin} alt={t("linkedin")} className="w-8" />
                    </a>
                  </div>
              ),
            },
            {
              title: t("incorporation_date"),
              key: "incorporation_date",
              render: (key, member) => member.incorporation_date !== null
                ? moment(member.incorporation_date).format("YYYY-MM-DD")
                : t("no_data"),
            },
          ]}
          actions={[
            {
              id: "edit",
              tip: t("edit"),
              onClick: (member) => showEditTeamMemberSection(member),
              buttonProps: {
                iconComponent: <FaEdit className="inline-block" />,
                className: "w-12",
                horizontal: "1",
                vertical: "1",
                verticalMargin: "0",
                horizontalMargin: "0",
                textColor: "buttons-card-text",
                bgColor: "buttons-card",
                bgHoverColor: "buttons-hover",
              },
            },
            {
              id: "delete",
              tip: t("delete"),
              onClick: (member) => deleteMember(member),
              buttonProps: {
                iconComponent: <FaTrash className="inline-block" />,
                className: "text-red w-12",
                horizontal: "1",
                vertical: "1",
                verticalMargin: "0",
                horizontalMargin: "0",
              },
            },
          ]}
        />
      )}
      {teamMembers.length === 0 && (
        <div className="my-10">
          <NoDataInfo title={t("no_data_registered")} />
        </div>
      )}

      {showTeamMemberSection && (
        <SidePanel
          width={"1/4"}
          onClose={closeTeamMemberSection}
          title={t(memberToEdit ? "update_startup_member" : "add_startup_members")}
        >
          <div className="w-1/3 lg:w-1/6">
            {getValues().image ? (
              <>
                <div className="text-xs font-medium text-black">{t("photo")}</div>
                <div className="mt-2 flex flex-col justify-center w-32">
                  <Avatar
                    src={getValues().image}
                    size="80"
                    round={true}
                    color="#e0e6f2"
                    fgColor="#4d70b3"
                    className="mx-auto my-0"
                  />
                  <ButtonDanger
                    type="button"
                    iconComponent={<FaTrash className="inline-block mr-2" />}
                    text={t("remove")}
                    width="auto"
                    bgColor="red"
                    className="text-white max-h-[24px]"
                    verticalMargin="1"
                    vertical="1"
                    textSize="xs"
                    onClick={() => onSelectImage(null)}
                  />
                </div>
              </>
            ) : (
              <div className="w-40">
                <InputFile
                  label={t("image")}
                  placeholder={t("select_photo")}
                  error={errors.image}
                  setSelectedFile={(file) => onSelectImage(file)}
                  accept="image/*"
                  height="120px"
                  padding="18px"
                />
              </div>
            )}
          </div>
          <div className="grid grid-cols-2 gap-4 mt-4">
            <Input
              name="name"
              label={t("name")}
              placeholder={t("name")}
              error={errors.name}
              required={true}
              reference={register}
            />
            <Input
              name="last_name"
              label={t("last_name")}
              placeholder={t("last_name")}
              error={errors.last_name}
              reference={register}
            />
          </div>
          <Input
            name="email"
            label={t("email")}
            placeholder={t("email")}
            error={errors.email}
            required={false}
            reference={register}
          />
          <Select
            multiSelect
            name="role"
            label={t("role")}
            items={roles}
            error={errors.role}
            initialValues={getRoleInitialValue()}
            onSelect={(selected) => {
              setValue("role", selected.length ? selected.map((selectedItem) => selectedItem.id) : null);
              trigger();
            }}
          />
          <Input
            name="percentage"
            label={`${t("equity")} (%)`}
            placeholder={`${t("equity")} (%)`}
            error={errors.percentage}
            type={"number"}
            reference={register}
          />

          <Input
            name="linkedin"
            label={t("linkedin")}
            placeholder={t("linkedin")}
            error={errors.linkedin}
            required={false}
            reference={register}
          />

          <Input
            name="incorporation_date"
            label={t("incorporation_date")}
            placeholder={t("incorporation_date")}
            error={errors.incorporation_date}
            required={false}
            type={"date"}
            reference={register}
          />
          <div className={"flex justify-end mt-4 border-t pt-2 border-separator"}>
            <ButtonMain
              text={t(memberToEdit ? "update_startup_member" : "add_startup_members")}
              onClick={() => onSubmit()}
            />
          </div>
        </SidePanel>
      )}
      {loading && <Loader />}
    </>
  );
};
