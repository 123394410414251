import { Card } from "components/ui/atoms/Card";
import { useTranslation } from "react-i18next";
import Utils from "utils/utils";
import { ProgressBar } from "./ProgressBar";
import { DocumentIcon } from "../../documentation/documents/components/DocumentIcon";
import { Skeleton } from "./skeletons/Skeleton";
import { SkeletonList } from "./skeletons/SkeletonList";

export function DocumentationStatistics({ used, available, documents, loading }) {
  const { t } = useTranslation();
  const gbNumber = 1e9 * 100; // 1 GB = 1e+9 bytes

  // Convert bytes to GB for used and available
  const usedGB = used / gbNumber;
  const availableGB = available / gbNumber;
  const totalGB = usedGB + availableGB;

  const pieChartData = [
    {
      id: t("space_used"),
      value: ((used / gbNumber) * 100).toFixed(2),
    },
    {
      id: t("space_available"),
      value: ((available / gbNumber) * 100).toFixed(2),
    },
  ];
  const previewIcons = {
    others: "",
    pdf: "pdf",
    multimedia: "mp4",
    img: "png",
    excel: "xls",
    total: "",
  };

  return (
    <div className="flex flex-col">
      <div className="w-full">
        <div className="mt-8 px-4">
          {loading && (<div className="h-2"><Skeleton /></div>)}
          {!loading && (<ProgressBar
            valueText={`${Utils.humanFileSize(used)} / 100 GB`}
            value={usedGB * 100}
            percentage={false}
            inverted={true}
          />
          )}
        </div>
      </div>
      <div className="w-full">
        <div className="grid grid-cols-1 md:grid-cols-3 my-6 gap-4">
          {loading && (<SkeletonList length={6} style={"h-24"}/>)}
          {!loading && documents.map((document, index) => (
            <Card key={index}>
              <div className="flex">
                <div>
                  <DocumentIcon documentName={previewIcons[document.extension]} />
                </div>
                <div className="flex flex-col px-2">
                  <div className="font-semibold">
                    {t(`document_stats_section_${document.extension}`)}
                  </div>
                  <div>{`${document.documents} files (${Utils.humanFileSize(document.size)})`}</div>
                </div>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
}
