import { useTranslation } from "react-i18next";
import TranslateUtils from "utils/translate-utils";
import { Autocomplete } from "components/ui/atoms/Autocomplete";

export const QuestionTypeAutocomplete = ({ currentQuestion, onChange, value, language }) => {
  const { t } = useTranslation();

  const getSelectableInitialValues = (id, collection, lonely) => {
    if (!id) {
      return lonely ? null : [];
    }

    if (Array.isArray(id)) {
      return collection.filter((i) => id.includes(i.id));
    }

    const item = collection.find((i) => i.id === id);
    return item ? (lonely ? item : [item]) : lonely ? null : [];
  };

  return (
    <>
      <Autocomplete
        className="questionnaire-autocomplete"
        id={currentQuestion.question_id._id}
        name={currentQuestion.question_id._id}
        key={currentQuestion.question_id._id}
        label={t("select_answer")}
        isClearable={!currentQuestion.required}
        placeholder={t("select_answer")}
        onSelect={(opts) => {
          onChange(![null, undefined].includes(opts) ? [opts?.id] : null);
        }}
        initialValues={getSelectableInitialValues(
          value?._id,
          currentQuestion.question_id._id,
          true,
        )}
        options={currentQuestion.question_id.options.map((option, index) => ({
          id: index,
          name: TranslateUtils.getTextByLang(language, option),
        }))}
      />
    </>
  );
};
