import { useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "components/loader";
import Menu from "components/layout/menu";
import Header from "components/layout/header";
import PropTypes from "prop-types";
import { RoutesLinks } from "components/routes-links";
import { Chat } from "components/chat/Chat";
import { DemoAccountBanner } from "components/ui/atoms/DemoAccountBanner";
import { useTour } from "hooks/useTour";
import { OnboardingTasksPannel } from "components/ui/molecules/OnboardingTasksPannel";
import Lang from "lang";
import { mountScript } from "components/account/components/emails/scripts/mountGoogleScript";
import { ProductUpdatesModal } from "components/ui/molecules/ProductUpdatesModal";
import { SetupProcessModal } from "components/ui/molecules/SetupProcessModal";
import Config from "config";
import moment from "moment";
import NucliaChat from "components/layout/integrations/nuclia/NucliaChat";
import useUser from "hooks/useUser";

const AppLayout = () => {
  const { user, isDemoAccount, hasAccessToModule } = useUser();
  const appLoading = useSelector((state) => state.app.loading);
  const checked = useSelector((state) => state.checks && state.checks.checked);
  const showOnboardingTasksPannel = useSelector(
    (state) => state.onboarding && state.onboarding.show,
  );
  const platform = useSelector((state) => state.app.platform);

  const [showProductUpdates, setShowProductUpdates] = useState(false);
  const [showSetupProcess, setShowSetupProcess] = useState(false);

  const tour = useTour("quick_tour");

  const isFullPage = () => {
    const fullPages = [
      RoutesLinks.SHARED_LINK,
      RoutesLinks.PUBLIC_EVENTS_LINK,
      RoutesLinks.PUBLIC_FORMS_LINK,
      RoutesLinks.PUBLIC_TEMPLATES_LINK,
      RoutesLinks.REQUESTED_INFORMATION_LINK,
      RoutesLinks.DEALFLOW_PREFERENCES_LINK,
    ];

    for (const page of fullPages) {
      if (location.pathname.includes(page)) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    if (platform.favicon) {
      const favicon = document.querySelector("head link[rel=\"icon\"]");
      favicon.setAttribute("href", platform.favicon);
    }
    if (platform.title_page) {
      document.title = platform.title_page[Lang.getCurrentLang().code];
    }
    sessionStorage.setItem("platform_id", Math.random().toString(36).substring(2, 9));
  }, [platform]);

  useEffect(() => {
    // tour launch
    if (user && user._id && user.welcome_steps && !user.investor.setup_type && !user.lp) {
      setShowSetupProcess(true);
    } else if (user && user._id && user.welcome_steps && !user?.tours?.quick_tour && !user.lp) {
      setShowSetupProcess(false);
      tour.start();
    } else if (
      user &&
      user._id &&
      user.welcome_steps &&
      user.tours?.quick_tour &&
      user.show_product_updates &&
      !user.lp
    ) {
      setShowSetupProcess(false);
      setShowProductUpdates(true);
    } else if (
      user &&
      user._id &&
      user.welcome_steps &&
      user.tours?.quick_tour &&
      !user.show_product_updates &&
      !user.lp
    ) {
      setShowSetupProcess(false);
      setShowProductUpdates(false);
    }
  }, [user]);

  useEffect(() => {
    mountScript();
  }, []);

  if (appLoading) {
    return <Loader />;
  }

  const getReminingDays = () => Config.TRIAL_DAYS - moment().diff(moment(user.investor.activation_date), "days");

  return (
    <>
      {checked && (
        <div className="flex h-screen w-screen overflow-hidden">
          <Menu />
          <div className="flex flex-col w-full">
            <Header />
            {hasAccessToModule("nuclia") && <NucliaChat />}

            {showSetupProcess && <SetupProcessModal />}
            {showProductUpdates && <ProductUpdatesModal />}
            {showOnboardingTasksPannel && <OnboardingTasksPannel />}

            <main
              id="app-content-wrapper"
              className={`${isFullPage() ? "pl-0" : "pl-8"} flex-1 overflow-y-scroll overflow-x-auto`}
            >
              {!isFullPage() && isDemoAccount && <DemoAccountBanner />}

              {isDemoAccount ? (
                getReminingDays() >= 0 ? (
                  <section
                    className={`w-full ${!isFullPage() ? "pt-4 pr-0 sm:pr-10 pb-8" : ""}`}
                  >
                    <Outlet />
                  </section>
                ) : (
                  <>Error</>
                )
              ) : (
                <section
                  className={`w-full ${!isFullPage() ? "pt-4 pr-0 sm:pr-10 pb-8" : ""}`}
                >
                  <Outlet />
                </section>
              )}

              <Chat />
            </main>
          </div>
        </div>
      )}

      {!checked && <Outlet />}
    </>
  );
};

AppLayout.propTypes = {
  window: PropTypes.func,
};

export default AppLayout;
