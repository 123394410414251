import { Skeleton } from "./Skeleton";

export function SkeletonList({ length, style }) {
  return (
    <>
      {Array.from({ length }).map((_, rowIndex) => (
        <div className={style} key={rowIndex}>
          <Skeleton />
        </div>
      ))}
    </>
  );
}
