import { useEffect, useState } from "react";
import moment from "moment";
import { FaEye } from "react-icons/fa";
import { Card } from "components/ui/atoms/Card";
import { DataGrid } from "components/ui/objects/DataGrid";
import { useTranslation } from "react-i18next";

const EmailsList = ({ emails, getEmailContent, showEmailDetail }) => {
  const { t } = useTranslation();

  if (!emails || !emails.length) {
    return null;
  }

  const [syncrhonizedEmail, setSynchronizedEmail] = useState("");

  useEffect(() => {
    const handleLoadGoogleAuth = async () => {
      const authInstance = gapi.auth2.getAuthInstance();

      if (authInstance.isSignedIn.get()) {
        const basicProfile = authInstance.currentUser.get().getBasicProfile();
        const _email = basicProfile.getEmail();
        setSynchronizedEmail(_email);
      }
    };

    try {
      gapi.load("client:auth2", handleLoadGoogleAuth);
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <>
      <Card>
        <DataGrid
          data={emails.map((email) => {
            const fromRaw = email.payload.headers.find(
              (header) => header.name.toLowerCase() === "from",
            )?.value;
            const toRaw = email.payload.headers.find(
              (header) => header.name.toLowerCase() === "to",
            )?.value;
            const from = fromRaw.includes("<")
              ? fromRaw.substring(fromRaw.indexOf("<") + 1, fromRaw.lastIndexOf(">"))
              : fromRaw;
            const to = toRaw.includes("<")
              ? toRaw.substring(toRaw.indexOf("<") + 1, toRaw.lastIndexOf(">"))
              : toRaw;
            const subject = email.payload.headers.find(
              (header) => header.name.toLowerCase() === "subject",
            )?.value;

            return {
              id: email.id,
              email: email.labelIds
                ? email.labelIds.map((item) => item.toLowerCase()).includes("inbox")
                  ? from
                  : to
                : from !== syncrhonizedEmail
                  ? from
                  : to,
              labelTypeEmail: email.labelIds
                ? email.labelIds.map((item) => item.toLowerCase()).includes("inbox")
                  ? t("received_email")
                  : t("sent_email")
                : t("unclassified_email"),
              subject: subject || "-",
              date: moment(parseInt(email.internalDate)).format("DD/MM/YYYY"),
            };
          })}
          headers={[
            { key: "email", title: "" },
            { key: "labelTypeEmail", title: "" },
            { key: "subject", title: "" },
            { key: "date", title: "" },
          ]}
          actions={[
            {
              id: "see",
              buttonProps: {
                iconComponent: <FaEye className={"inline"} />,
              },
              onClick: (email) => {
                getEmailContent(email.id);
                showEmailDetail(true);
              },
            },
          ]}
        />
      </Card>
    </>
  );
};

export default EmailsList;
