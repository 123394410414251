export const QuestionTypeImageSelector = ({ currentQuestion, onChange, value }) => {
  const updateValues = (index) => {
    const localValues = value || [];
    localValues.push(index);
    onChange(localValues);
  };

  const removeValue = (index) => {
    const localValues = value || [];
    const indexInValue = localValues.findIndex((v) => v === index);
    localValues.splice(indexInValue, 1);
    onChange(localValues.length ? localValues : null);
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-6 gap-4">
      {currentQuestion.question_id.options.map((option, index) => (
        <div
          key={index}
          style={{ height: "200px" }}
          className="rounded-md p-1 hover:border-2 hover:border-gray-linescursor-pointer"
        >
          <label className="flex flex-col gap-2 cursor-pointer" style={{ height: "170px" }}>
            <input
              className="grow-0 cursor-pointer"
              type="radio"
              onClick={(e) => {
                if (value && value.includes(index)) {
                  removeValue(index);
                } else if (
                  currentQuestion.question_id.multiple_selection_enabled ||
                  (!currentQuestion.question_id.multiple_selection_enabled &&
                    (!value || value.length < 1))
                ) {
                  updateValues(index);
                }
              }}
              name={`${currentQuestion.question_id._id}_${index}`}
              value={option}
              checked={value ? value.includes(index) : false}
            />
            <div className="grow flex items-center justify-content justify-center">
              <img src={option} style={{ maxWidth: "100%", maxHeight: "150px" }} />
            </div>
          </label>
        </div>
      ))}
    </div>
  );
};
