import { useDispatch, useSelector } from "react-redux";
import UserOperations from "../redux/user/user-operations";
import { useEffect, useState } from "react";
import { get } from "lodash";
import OnboardingOperations from "redux/onboarding/onboarding-operations";
import AccountService from "services/account-service";
import Config from "config";

const useUser = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [startupId, setStartupId] = useState(user?.projects?.length ? user.projects[0]._id : null);
  const [userLanguage, setUserLanguage] = useState("en");
  const [isDemoAccount, setIsDemoAccount] = useState(false);
  const setUserInfo = (data) => dispatch(UserOperations.setUserInfo({ data }));
  const haveFunctionality = (functionality) =>
    user.investor?.permissions?.functionalities?.includes(functionality);
  const [investorId, setInvestorId] = useState(null);

  useEffect(() => {
    setStartupId(user?.projects?.length ? user.projects[0]._id : null);
    setUserLanguage(get(user, "preferences.language.code") || "en");
    // setUserLanguage(get(user, 'language.code') || 'en')
    setIsDemoAccount(user && user.investor && !!user.investor.demo);
    setInvestorId(user && user.investor && user.investor._id);
  }, [user]);

  return {
    user,
    startupId,
    userLanguage,
    isDemoAccount,
    setUserInfo,
    haveFunctionality,
    investorId,

    getHardFilters: () => {
      const hardFilters = [];

      if (
        user.investor.investment_thesis.business_models_hard_filter &&
        user.investor.investment_thesis.business_models !== null &&
        user.investor.investment_thesis.business_models.length
      ) {
        hardFilters.push("business_model");
      }
      if (
        user.investor.investment_thesis.countries_hard_filter &&
        user.investor.investment_thesis.countries !== null &&
        user.investor.investment_thesis.countries.length
      ) {
        hardFilters.push("country");
      }
      if (
        user.investor.investment_thesis.investment_stages_hard_filter &&
        user.investor.investment_thesis.investment_stages !== null &&
        user.investor.investment_thesis.investment_stages.length
      ) {
        hardFilters.push("investment_stage");
      }
      if (
        user.investor.investment_thesis.project_stages_hard_filter &&
        user.investor.investment_thesis.project_stages !== null &&
        user.investor.investment_thesis.project_stages.length
      ) {
        hardFilters.push("project_stage");
      }
      if (
        user.investor.investment_thesis.sectors_hard_filter &&
        user.investor.investment_thesis.sectors !== null &&
        user.investor.investment_thesis.sectors.length
      ) {
        hardFilters.push("sector");
      }
      /* if (user.investor.investment_thesis.minimum_ticket_hard_filter && user.investor.investment_thesis.minimum_ticket !== null) {
        hardFilters.push('minimum_ticket')
      } */
      if (
        user.investor.investment_thesis.maximum_ticket_hard_filter &&
        user.investor.investment_thesis.maximum_ticket !== null
      ) {
        hardFilters.push("maximum_ticket");
      }
      if (
        user.investor.investment_thesis.minimum_valuation_hard_filter &&
        user.investor.investment_thesis.minimum_valuation !== null
      ) {
        hardFilters.push("minimum_valuation");
      }
      if (
        user.investor.investment_thesis.maximum_valuation_hard_filter &&
        user.investor.investment_thesis.maximum_valuation !== null
      ) {
        hardFilters.push("maximum_valuation");
      }

      return hardFilters;
    },

    reloadUserInfo: () => {
      const previousUserOnboardingTasks = user?.onboarding_tasks ? [...user.onboarding_tasks] : [];
      const previousInvestorOnboardingTasks = user?.investor?.onboarding_tasks ? [...user.investor.onboarding_tasks] : [];
      const previousOnboardingTasks = [...previousUserOnboardingTasks, ...previousInvestorOnboardingTasks];

      return AccountService.getUser().then(({ data }) => {
        const currentUserOnboardingTasks = data?.data?.onboarding_tasks ? [...data?.data?.onboarding_tasks] : [];
        const currentInvestorOnboardingTasks = data?.data?.investor?.onboarding_tasks ? [...data?.data?.investor.onboarding_tasks] : [];
        const currentOnboardingTasks = [...currentUserOnboardingTasks, ...currentInvestorOnboardingTasks];

        if (currentOnboardingTasks.length !== previousOnboardingTasks.length && currentOnboardingTasks.length < 11 && user.welcome_steps) {
          dispatch(OnboardingOperations.handleOnboardingTasksPannel(true));
        }

        setUserInfo(data?.data);
      });
    },

    getUserFullName: () =>
      (user?.last_name ? `${user?.name} ${user?.last_name}` : `${user?.name}`) || "",

    hasAccessToSection: (section) => section,
    // if (section === "portfolio") {
    //   return true;
    // }
    // return user.investor.permissions.sections.includes(section);

    // Get boolean from config
    hasAccessToModule: (module) => Config.modules.hasOwnProperty(module) ? Config.modules[module] : false,

    checkIfLPhasAccessToSection: (section) => user.lp_permissions[section],
  };
};

export default useUser;
