import { useEffect, useImperativeHandle, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Loader } from "components/ui/molecules/Loader";

import { RoutesLinks } from "components/routes-links";
import IRMInvestorsFilters from "./IRMInvestorsFilters";
import InvestorsList from "./InvestorsList";
import RegisterIRMInvestorSection from "./RegisterIRMInvestorSection";
import ContactsService from "services/contacts-service";
import { useToasts } from "react-toast-notifications";
import { useSwal } from "hooks/useSwal";
import useUser from "hooks/useUser";
import { NoDataInfo } from "components/ui/atoms/NoDataInfo";
import { countAppliedFilters } from "../../utils/countAppliedFilters";

const IRM = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { confirm } = useSwal();
  const { user, checkIfLPhasAccessToSection } = useUser();

  const { search } = useLocation();

  // Get current page from url query to retrieve it when arrow back
  const kiota = search.split("kiota=")[1] ?? false;

  const handleRedirect = (url) => navigate(url);
  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [showRegisterIRMInvestor, setShowRegisterIRMInvestor] = useState(false);

  const { setPageTitle, setFiltersCount, ref } = useOutletContext();

  const filterInvestors = (filters) => {
    setCurrentPage(0);
    setFilters(filters);
    setFiltersCount(countAppliedFilters(filters));
  };

  useImperativeHandle(ref, () => ({
    showRegisterIRMInvestor: () => setShowRegisterIRMInvestor(true),
    showFilters: () => setShowFilters(true),
  }));

  const createInvestor = async(values) => {
    confirm().then(async(isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoading(true);
          const result = await ContactsService.registerContact(values);
          addToast(t("investor_registered_successfully"), {
            appearance: "success",
            autoDismiss: true,
          });
          handleRedirect(`${RoutesLinks.CONTACTS_LINK}${result.data.data._id}`);
        } catch (error) {
          console.error(error);
          addToast(t("error_occurred_registering_investor"));
        } finally {
          setLoading(false);
        }
      }
    });
  };

  useEffect(() => {
    setPageTitle(t("contacts"));
  }, []);

  return (
    <>
      {showRegisterIRMInvestor && (
        <RegisterIRMInvestorSection
          onClose={() => setShowRegisterIRMInvestor(false)}
          onSubmit={createInvestor}
        />
      )}
      {(!user.lp || (user.lp && checkIfLPhasAccessToSection("contacts"))) && (
        <div className="my-6">
          <InvestorsList
            filters={filters}
            filterInvestors={filterInvestors}
            loading={loading}
            setLoading={setLoading}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            kiota={kiota}
          />
        </div>
      )}
      {loading && <Loader />}

      {showFilters && (
        <IRMInvestorsFilters
          onClose={() => setShowFilters(false)}
          onFilter={filterInvestors}
          filters={filters}
        />
      )}
      {user.lp && !checkIfLPhasAccessToSection("contacts") && (
        <div className="my-6">
          <NoDataInfo title={t("access_forbidden")} />
        </div>
      )}
    </>
  );
};

export default IRM;
